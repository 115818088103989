import React, { useContext, useEffect, useRef, useState } from 'react'
import { Line } from '../styles';
import InputDocuments from './InputDocuments/InputDocuments';
import { ButtonSave } from './InputDocuments/style';
import { useNavigate } from 'react-router-dom';
import { useUpdateResource } from '../hooks/useUpdateResource';
import { EnumStepNewResource, ResourceContext } from '../../../../../Contexts/Form/ContextResource';
import { Form } from '../../../../Registration/UserRegistration/styles';
import { Controllers } from '../../../../../Components/Form/Controllers';

const FormDocuments = () => {
    const navigate = useNavigate()
    const { handleStep, idResource, resource } = useContext(ResourceContext);
    const arrayTexts = ["Storyboard (STB)/Roteiro", "Histórico de ajustes/correções."]

    const [onSave, setOnSave] = useState(false)

    const updateResource = useUpdateResource();


    function saveResource() {

        if (idResource === "") {
            alert("Preencha tudo do primeiro formulário.")
        } else {
            updateResource.mutate(resource);
            if(updateResource.isSuccess){
                alert("Recurso finalizado!")
                navigate("/recursos/list")
                window.location.reload()
            }
        }
    }


    return (
        <Form>
            <br />
            {arrayTexts.map((text) =>
                <InputDocuments
                    flex='fx60'
                    label={text}
                    setOnSave={setOnSave}
                />
            )}
            {/* <InputDocuments
                flex='fx60'
                label='Histórico de ajustes/correções.'
                setIsTrue={setIsTrue2}
                isTrue={isTrue2}
            /> */}
            {
                onSave ? (
                    <ButtonSave>
                        SALVAR
                    </ButtonSave>
                ) : <></>
            }
            <Line />

            <Controllers
                enableNext={true}
                enablePrev={true}
                functionNext={() => saveResource()}
                functionCancel={() => {
                    window.location.reload()
                }}
                functionPrev={() => handleStep(EnumStepNewResource.TUTORIAL)}
            />
        </Form>
    )
}

export default FormDocuments
