import { ChevronLeft, Edit, Plus } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Container } from './styles'

export function ProfileDatas(props: any) {
  return (
    <Container>
      <h1>{props.page}</h1>
      <div>
      <button className='list hvr-grow' onClick={() => props.onBack()}>
        <ChevronLeft size={19}/>
        Lista de {props.labelPlural}
      </button>
      <button className='hvr-grow' onClick={() => props.onEdit()}>
        <Edit size={16}/>
        Editar {props.labelSingle}</button>
        </div>
    </Container>
  )
}
