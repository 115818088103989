import { FileText } from "lucide-react";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CallRegister } from "../../../../Components/CallRegister";
import { ModalList } from "../../../../Components/Modals/ModalList";
import { ProfileDatas } from "../../../../Components/ProfileDatas";
import { Container, Sitemap, Datas, StyledTable, StyledTr } from "./styles";
import { api } from "../../../../Services/api";

export function UnitProfile(props: any) {
  const [datasProfile, setDatasProfile] = useState<any>();
  const navigate = useNavigate();
  const onEditUnidade = () => navigate("/unidade/edit", { state: datasProfile });
  useEffect(() => {
    const token = `bearer ${localStorage.getItem("token")}`;

    if (props.selected === "") {
      alert("Selecione um cliente");
      props.back();
    } else {
      api
        .get(`/admin/education-institutions/${props.selected}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          setDatasProfile(response.data.data);
          sessionStorage.setItem(
            "locationData",
            JSON.stringify(response.data.data)
          );
        });
    }
  }, []);

  function callUser(id: string) {
    navigate("/unidade/usuario/list");
    sessionStorage.setItem("location", id);
    sessionStorage.setItem("locationData", JSON.stringify(datasProfile));
  }

  function callClass() {
    navigate("/turma/list");
    sessionStorage.setItem("locationData", JSON.stringify(datasProfile));
  }
  console.log(datasProfile)

  return (
    <Container>
      <ProfileDatas
        page="Perfil da Unidade Educacional"
        onBack={() => props.back()}
        onEdit={onEditUnidade}
        labelSingle="Unidade"
        labelPlural="Unidades"
      />
      <Datas>
        <main>
          <p>Nome da Unidade Educacional: {datasProfile?.name}</p>
          <p>Telefone: {datasProfile?.phone}</p>
          <p>E-mail: {datasProfile?.email}</p>
          <span></span>
          {datasProfile?.address ? (
            <>
              <p>Rua: {datasProfile?.address.address}</p>
              <p>Número: {datasProfile?.address.number}</p>
              <p>Complemento: {datasProfile?.address.complement}</p>
              <p>Bairro: {datasProfile?.address.neighborhood}</p>
              <p>
                Cidade: {datasProfile?.address.city} -{" "}
                {datasProfile?.address.state}
              </p>
              <p>
                CEP:{" "}
                {datasProfile.address.zipCode
                  ? datasProfile.address.zipCode
                  : "Não cadastrado"}
              </p>
            </>
          ) : (
            <p>Endereço não cadastrado</p>
          )}
          <span></span>
          <p>
            Gestor da Unidade:{" "}
            <button onClick={() => callUser("2")} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
          <p>
            Diretor da Unidade:{" "}
            <button onClick={() => callUser("3")} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
          <p>
            Coordenador da Unidade:{" "}
            <button onClick={() => callUser("4")} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
          <p>
            Administrativo da Unidade:{" "}
            <button onClick={() => callUser("5")} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
          <p>
            Turmas:{" "}
            <button onClick={() => callClass()} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
          <p>
            Usuários professores:{" "}
            <button onClick={() => callUser("6")} className="callList">
              {" "}
              <FileText size={14} />{" "}
            </button>{" "}
          </p>
          <p>
            Usuários estudantes:{" "}
            <button onClick={() => callUser("7")} className="callList">
              {" "}
              <FileText size={14} />{" "}
            </button>{" "}
          </p>
          {/* <p>
            Usuários responsaveis:{" "}
            <button onClick={() => callUser("8")} className="callList">
              {" "}
              <FileText size={14} />{" "}
            </button>{" "}
          </p> */}
        </main>
      </Datas>
    </Container>
  );
}
