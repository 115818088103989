import React, { useContext } from "react";
import { ContainerLevelOne, ContainerLevels, ContainerMoreLevels } from "../style";
import LevelTwo from "./LevelTwo/LevelTwo";
import ComponentLevel2 from "../../ComponentSubcategory/ComponentLevel2/ComponentLevel2";
import { ResourceContext } from "../../../../../../../Contexts/Form/ContextResource";
import { IResourceCollection } from "../../../../../../../reducers/newResource/reducer";

export type Subcategory = {
  identifier: string;
  name: string;
  path: string;
  description: string;
  level: number;
  children: Subcategory[];
};

interface LevelOneProps {
  subcategory: Subcategory;
  collectionId: string;
  categoryId: string;
}

const LevelOne = ({ subcategory, collectionId, categoryId }: LevelOneProps) => {
  const {
    resource,
    removeCategoryCollectionSelects,
    addCategoryCollectionSelects,
  } = useContext(ResourceContext);

  const subcategorySelected = resource.collections.some(
    (collection) => collection.subcategory_id === subcategory.identifier
  );

  function handleCollectionSelected() {

    const subcategoryResource: IResourceCollection = {
      subcategory_id: subcategory.identifier,
      path: subcategory.path,
      collection_id: collectionId,
      category_id: categoryId,
    };


    if (subcategorySelected) {
      removeCategoryCollectionSelects(subcategoryResource);
    } else {
      addCategoryCollectionSelects(subcategoryResource);
    }
  }

  return (
    <ContainerLevelOne
      $back={
        subcategorySelected
          ? "#8ce7ea"
          : "#e9e9ee"
      }
    >
      {
        subcategory.children.length > 0
          ?
          <ComponentLevel2 
            subLevel2={subcategory.children}
            levelOneName={subcategory.name}
          />
          :
          <>

            <div className="container-name-level-one">
              <p>{subcategory.name}</p>
            </div>
            <div className="container-input">
              <input
                onChange={handleCollectionSelected}
                checked={subcategorySelected}
                type="checkbox"
              />
            </div>
          </>
      }
    </ContainerLevelOne>

  );
};

export default LevelOne;
