import { useState } from "react";
import { Cancel, Container, Next, Prev } from "./styles";
import { SpinnerIcon } from "../../../Pages/Registration/TagRegistration/styles";
interface ControllersProps {
  enableNext?: boolean;
  enablePrev?: boolean;
  save?: boolean;
  labelProx?: string;
  isRequisition?: boolean;
  functionNext?: () => void;
  functionSave?: () => void;
  functionPrev?: () => void;
  functionCancel?: () => void;
}

export function Controllers({
  enableNext = false,
  enablePrev = false,
  save = false,
  isRequisition = false,
  labelProx = "Avançar",
  functionCancel,
  functionNext,
  functionPrev,
  functionSave,
}: ControllersProps) {
  function handleNextButton() {
    if (enableNext) {
      if (functionNext) {
        functionNext();
      } else {
        alert("Passe a função next para o componente");
      }
    } else {
      return;
    }
  }
  function handleSaveButton() {
    if (save) {
      if (functionSave) {
        functionSave();
      } else {
        alert("Passe a função save para o componente");
      }
    } else {
      return;
    }
  }
  function handlePrevButton() {
    if (enablePrev) {
      if (functionPrev) {
        functionPrev();
      } else {
        alert("Passe a função prev para o componente");
      }
    } else {
      return;
    }
  }

  function handleCancelButton() {
    if (functionCancel) {
      functionCancel();
    } else {
      alert("Passe a função cancel para o componente");
    }
  }

  return (
    <Container>
      <Cancel
        onClick={(e) => {
          e.preventDefault();
          handleCancelButton();
        }}
        className="hvr-grow"
      >
        Cancelar
      </Cancel>
      <Prev
        onClick={(e) => {
          e.preventDefault();
          handlePrevButton();
        }}
        className={enablePrev ? "enable hvr-grow" : ""}
      >
        Voltar
      </Prev>
      {save ? (
        <Next
          onClick={(e) => {
            e.preventDefault();
            handleSaveButton();
          }}
          className={enableNext ? "enable hvr-grow" : ""}
        >
          {isRequisition ? <SpinnerIcon /> : "Salvar"}
        </Next>
      ) : (
        <Next
          onClick={(e) => {
            e.preventDefault();
            handleNextButton();
          }}
          className={enableNext ? "enable hvr-grow" : ""}
        >
          {labelProx}
        </Next>
      )}
    </Container>
  )
}
