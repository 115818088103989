import { DataManager } from "../Pages/Registration/UserRegistration/FormManager";
import { CreateSuccess, Forbidden, Success, Unauthorized, UnprocessableContent } from "../Utils/Responses";
import { TypeAddress, TypeTeacher } from "../Utils/Types";
import { api } from "./api";
const id = JSON.parse(sessionStorage.getItem("locationData")!);

type ClienteCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type TypeManager = {
  name: string;
  email: string;
  birth_date: Date | string;
  cpf: string;
  phone: string;
  address?: TypeAddress | undefined;
  role_id: string;
};

type ManagerRegistrationProps = {
  data: TypeManager,  //DataManager
  identifier: string
}
type ManagerRegistrationResponse = Success | Unauthorized | Forbidden | UnprocessableContent;

type ManagerlistProps = {
  identifier: string
}
type ManagerListResponse = Success | Unauthorized | Forbidden;

class ManagerService {

  static async getManager(idClient: string): Promise<ManagerListResponse> {

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/clients/${idClient}/managers`, config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "Forbidden" } as Forbidden
      }

    } catch (error: any) {
      throw new Error("Can not show list manager.")
    }
  }

  static async postManager(input: ManagerRegistrationProps): Promise<ManagerRegistrationResponse> {
    try {

      const { data, identifier } = input

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.post(
        `/admin/clients/${identifier}/managers`,
        data,
        config
      )
      return response.data

    } catch (error: any) {
      throw new Error("Can not registration Manager.")
    }
  }

  static async updateManager(data: TypeTeacher, idClient: string, idManger: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.put<ClienteCreateResponse>(
        `/admin/clients/${idClient}/managers/${idManger}`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default ManagerService;


