import styled from 'styled-components';

export const Container = styled.section`
display: flex;
width: 100%;
`;

export const Main = styled.main`

    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
