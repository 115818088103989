import styled from "styled-components";

interface ContainerItensProps {
    $border?: string
}

export const ContainerItens = styled.div<ContainerItensProps>`

display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
border-radius: 10px;
min-height: 50px;
border: ${({ $border }) => $border ?? "none"};
 :hover{
    cursor: pointer;
 }

.editor{
    width: 100%;
    background-color: red;
}


`
export const ContainerItensMain = styled.div`

display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
width: 100%;
border-radius: 10px;
min-height: 50px;
 :hover{
    cursor: pointer;
 }
`
export const ContainerStyles = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #EDEDED;
    border-radius: 10px 10px 0px 0px;
    width: 100%;
    padding: 10px;
    min-height: 35px;
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    gap: 0.5rem;


`

interface TextAreaProps {
    $fontSize?: string,
    $fontWeight?: string,
    $fontStyle?: string,
    $textDecoration?: string,
    $textAlign?: string
}

export const TextAreaInput = styled.textarea<TextAreaProps>`
width: 100%;
min-height: 100px;
border-radius: 0px 0px 10px 10px;
padding: 10px;
outline: none;
border-left: 1px solid black;
border-right: 1px solid black;
border-bottom: 1px solid black;
font-size: ${({ $fontSize }) => $fontSize || ""};
font-weight: ${({ $fontWeight }) => $fontWeight || "normal"};
font-style: ${({ $fontStyle }) => $fontStyle || "normal"};
text-decoration: ${({ $textDecoration }) => $textDecoration || "none"};
text-align: ${({ $textAlign }) => $textAlign || "left"};

`



export const ButtonSave = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #088A62;
    color: white;
    width: 70px;
    height: 30px;
    border-radius: 10px;
    border: none;

`
export const TextStylesOne = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 160px;
    height: 27px;
    border-left: 1px solid black;
    border-right: 1px solid black;


`

export const TextStylesTwo = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 60px;
    padding: 5px;
    height: 27px;


`
export const TextStylesTree = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100px;
    padding: 5px;
    height: 27px;
    border-left: 1px solid black;
    border-right: 1px solid black;


`

export const SelectStyles = styled.select`
    width: 45px;
    background-color: #FFFFFF;
    outline: none;
    border-radius: 3px;

`