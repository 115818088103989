import styled from "styled-components";

export const Container =  styled.div`
    display: flex;
    width: 800px;
    padding-top: 30px;
    flex-direction: column;
    gap: 30px;

    .text-area{
        display: flex;
        label{
            font-weight: 700;
            color: #646464;
        }
        .text{
            display: flex;
            width: 50%;
            flex-direction: column;
            color: #646464;
            gap: 10px;
        }
    }
    .div-Accesses{
        display: flex;
        width: 100%;
        flex-direction: column;
        .p-Accesses{
            font-size: 16px;
            color: #646464;
            font-weight: 900;
        }
    }

`;