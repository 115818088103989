import React, { useEffect, useState } from 'react'
import { DivItens, DivSelected } from './style'
import { useActionData } from 'react-router-dom'
import { CheckCircle2 } from 'lucide-react';

interface Props {
    basicCompetences: any[],
}

const SelectedFields = ({ basicCompetences }: Props) => {


    function handleLastPosition(indice: number) {
        return indice === basicCompetences.length - 1;
    }


    return (
        <DivItens>
            {basicCompetences.sort((a,b)=> a - b).map((item: any, index: number) => {
                return (
                    <DivSelected key={index}>
                        <p>{item}</p>
                        {handleLastPosition(index)
                            ? <CheckCircle2 color='white' />
                            : <div className="bar"></div>
                        }
                    </DivSelected>
                )
            })}
        </DivItens>
    )
}

export default SelectedFields
