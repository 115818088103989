import { ImportClass } from "../../types/ClassTypes/typeClass";
import { ImportStudent } from "../../types/StudentsTypes/typeStudents";
import Excel from "exceljs";

class ReportFormatters {


    static async students(file: FileList): Promise<ImportStudent[]> {
        const users: ImportStudent[] = []

        const workbook = new Excel.Workbook();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file[0]);

        return new Promise((resolve) => {
            reader.onload = () => {
                const buffer = reader.result as Buffer;

                workbook.xlsx.load(buffer).then((workbook) => {
                    workbook.eachSheet((sheet) => {
                        sheet.eachRow((row, index) => {
                            if (index > 1) {
                                const line = row.values as Array<any>;

                                

                                const student: ImportStudent = {
                                    enrollment: line[1],
                                    name: line[2],
                                    year_id: line[3],
                                    birth_date: line[4],
                                    cpf: line[5],
                                    special_need_id: line[6],
                                    other_special_needs: line[7],
                                    equivalent_age: Number(line[8])

                                };

                                users.push(student);
                            }
                            resolve(users);
                        })

                    })
                })
            }
        })
    }

    static async classes(file: FileList): Promise<ImportClass[]> {

        const classes: ImportClass[] = []

        const workbook = new Excel.Workbook()
        const reader = new FileReader()
        reader.readAsArrayBuffer(file[0])

        return new Promise((resolve) => {
            reader.onload = () => {
                const buffer = reader.result as Buffer

                workbook.xlsx.load(buffer).then((workbook) => {
                    workbook.eachSheet((sheet) => {
                        sheet.eachRow((row, index) => {
                            if (index > 1) {
                                const line = row.values as Array<any>;

                                const classObject: ImportClass = {
                                    name: line[1],
                                    academic_year: line[2],
                                    modality: [line[3]],
                                    stage_id: line[4],
                                    phase: line[5],
                                    year_id: line[6]
                                }
                                classes.push(classObject)
                            }
                            resolve(classes);

                        })
                    })
                })
            }
        })



    }

}

export { ReportFormatters }