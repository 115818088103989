import React, { useContext } from 'react'
import { ContainerReviewResource } from './styles'
import FooterReviewResource from './Footer'
import { Controllers } from '../../../../Components/Form/Controllers'
import { ContextResourceReview } from './Context/ContextReviewResource'
import BasicInformations from './BasicInformations/BasicInformations'
import { useCreateResource } from '../hooks/useCreateResource'
import { useUpdateResource } from '../hooks/useUpdateResource'
import { ResourceContext } from '../../../../Contexts/Form/ContextResource'
import BNCC from './BNCC/BNCC'
import ReviewTags from './TAGS/ReviewTags'
import ReviewPOP from './POP/POP'
import { useNavigate } from 'react-router'

const ReviewResource = () => {

  const navigate = useNavigate()

  const { stepReview } = useContext(ContextResourceReview)
  const { idResource, resource } = useContext(ResourceContext)

  const createResource = useCreateResource()
  const updateResource = useUpdateResource()

  function save(){
    if(idResource === ""){
      alert("Recurso finalizado!")
      navigate("/recursos/list")
      createResource.mutate(resource)
    }else{
      alert("Recurso finalizado!(Editado)")
      navigate("/recursos/list")
      updateResource.mutate(resource)
    }
  }

  return (
    <ContainerReviewResource>
      {stepReview === 1 && (
        <BasicInformations />
      )}
      {stepReview === 2 && (
        <BNCC/>
      )}
      {stepReview === 3 && (
        <ReviewTags/>
      )}
      {stepReview === 4 && (
        <ReviewPOP/>
      )}
      <FooterReviewResource />
      <div className="divControlers">
        <Controllers
          save
          enableNext
          enablePrev
          functionCancel={() => { window.location.reload() }}
          functionSave={() => save()}
          functionPrev={() => { }}
        />
      </div>
    </ContainerReviewResource>
  )
}

export default ReviewResource