import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  border: 1px solid #818181;
  border-radius: 10px;
  p {
    font-size: 16px;
    color: #646464;
    text-transform: uppercase;
    text-align: center;
    padding: 8px 0px;
  }
  > table {
    border-spacing: 0px;
    width: 100%;
    flex: 1;

    thead {
      td {
        border-bottom: 1px solid #818181;
        padding: 5px;
        text-align: center;
      }
    }
    tbody {
      tr {
        td {
          border-top: 1px solid #646464;
        }
        td {
          padding: 8px;
          font-size: 16px;
          color: #646464;
          button {
            background-color: #f8f8fa;
            color: #646464;
            border: 0.5px solid #646464;
            border-radius: 5px;
            padding: 5px;
            cursor: pointer;
          }
        }
      }
    }
  }
`
