import { ImportClass } from "../../types/ClassTypes/typeClass";
import { ImportStudent } from "../../types/StudentsTypes/typeStudents";
import { SheetFactory } from "./SheetFactory"

class GenerateReport {

    static async generateClassImport(classes: ImportClass[]): Promise<Blob> {
        const sheet = new SheetFactory()
        sheet.setColumnSizes([13, 13, 13, 13, 13, 13])
        sheet.addSubtitleRow(["name", "academic_year", "modality", "stage_id", "phase", "year"])

        const classRows = classes.map(({
            name,
            academic_year,
            modality,
            stage_id,
            phase,
            year_id
        }) => [
                name,
                academic_year,
                modality,
                stage_id,
                phase,
                year_id
            ])

        sheet.addRows(classRows);

        const buffer = await sheet.finishSheet();

        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        return new Blob([buffer], { type: fileType });
    }

    static async generateStudentsImport(students: ImportStudent[]): Promise<Blob> {

        // students.forEach((student) => {
        //     console.log("primeiro",student.year_id)
        //     if (student.year_id === "1° ano") {
        //         student.year_id = ""
        //         student.year_id = "EF01"
        //     }else if(student.year_id === "3º ano"){
        //         student.year_id = ""
        //         student.year_id = "EF03"
        //     } else {
        //         console.log(student.year_id)
        //         student.year_id = student.year_id
        //     }
        // })

        const sheet = new SheetFactory()


        sheet.setColumnSizes([13, 13, 13, 13, 13, 13, 13, 13,])
        sheet.addSubtitleRow(["enrollment", "name", "year_id", "birth_date", "cpf", "special_need_id", "other_special_needs", "equivalent_age"]);

        const studentsRows = students.map(({ birth_date, cpf, enrollment, equivalent_age, name, other_special_needs, special_need_id, year_id }) => [
            enrollment,
            name,
            year_id,
            birth_date,
            cpf,
            equivalent_age,
            other_special_needs,
            special_need_id,
        ])

        sheet.addRows(studentsRows)

        const buffer = await sheet.finishSheet();

        const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        return new Blob([buffer], { type: fileType });
    }

}

export { GenerateReport }