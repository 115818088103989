import { useQuery } from "react-query";
import { Queries } from "../../../../../types/queries";
import ResourceService from "../../../../../Services/Resouce.service";
import { minutesToMilliseconds } from "../../../../../Utils/Functions";


export function useListResource() {
  return useQuery(
    Queries.LIST_RESOURCES,
    () => ResourceService.listResource(),
    {
      cacheTime: minutesToMilliseconds(5),
      staleTime: minutesToMilliseconds(5),
      retry: false,
    }
  );
}
