import { AxiosError } from "axios";
import { CreateSuccessData, GetResponseData, Success, Unauthorized } from "../Utils/Responses";
import { IBNCC, IBNCCEf } from "../types/bncc";
import { api } from "./api";
import { AcademicYear } from "../Utils/Types";


type GetResponse = Success | Unauthorized

class BNCCService {

  static async listBNCCYears(stage_id?: string, phase?: string) {
    if (stage_id === "" && phase === "") {
      return
    } else {
      try {

        const response = await api.get<GetResponse>(
          `/bncc/years`,
          {
            params: {
              stage_id, phase
            },
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`
            }
          }
        )

        return response.data

      } catch (error) {
        if (error instanceof AxiosError) {
          alert(error.response?.data.message);
        } else {
          alert(error);
        }
      }
    }
  }

  static async listBNCCPhases(stage_id: string | undefined) {

    if (stage_id === "") {
      return
    } else {

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        };



        const response = await api.get<GetResponse>(
          `/bncc/phases`,
          {
            params: { stage_id },
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }

          },
        )

        return response.data
      } catch (error) {
        if (error instanceof AxiosError) {
          alert(error.response?.data.message);
        } else {
          alert(error);
        }
      }

    }
  }

  static async listBNCCStages() {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<GetResponse>(
        `/bncc/stages`,
        config
      )

      return response.data

    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }


  static async listBNCCEf() {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<CreateSuccessData<IBNCCEf>>(`/bncc/elementary-school`, config)
      if (response.data.success) {
        return response.data.data
      }


    } catch (error: any) {
      throw new Error("Can not list BNCC - EF")
    }

  }

  static async listBNCCEi() {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<GetResponse>(
        `/bncc/preschool`,
        config
      )

      if (response.data.success) {
        return response.data.data
      }


    } catch (error: any) {
      throw new Error("Can not list BNCC - EI")
    }
  }

  static async list() {
    // try {
    //   const config = {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     },
    //   };

    //   const response = await api.get<CreateSuccessData<IBNCC>>(`/bncc`, config);
    //   if (response.data.success) {
    //     return response.data.data;
    //   }

    // } catch (error: any) {
    //   throw new Error("Can not list BNCCs.");
    // }
  }
}

export default BNCCService;
