import { Header } from "../Header";
import { LeftMenu } from "../LeftMenu";
import { Container } from "./styles";

export function SuperBox(props: any){
    return(
        <Container>
            <LeftMenu switch={props.switch} page={props.page} />
            <div className="content">
                <Header path={props.path}/>
                {props.children}
            </div>
        </Container>
    )
}