import { useMutation, useQueryClient } from "react-query";
import { IAuthorshipResource, ILegalResource, IPedagogicalResource, IResourceCollection, IResourceState, ITagsResource } from "../../../../../reducers/newResource/reducer";
import { IBNCCResource } from "../../../../../types/bncc";
import { useContext } from "react";
import { ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import ResourceService from "../../../../../Services/Resouce.service";
import { Queries } from "../../../../../types/queries";


interface Resource {
    name: string;
    description?: string;
    url: string

    format: string;
    language: string;
    thumbnails: File[];

    active: boolean;
    published: boolean;
    test: boolean;
    authorshipYear?: number;
    authorship: IAuthorshipResource;

    origin_resource?: string;
    equivalent_resources?: string[];

    plataforms: string[];
    target_audience: any[];

    collectionDefault?: string;
    collections: IResourceCollection[];
    evaluated_content?: ILegalResource;

    bncc?: IBNCCResource;

    tags?: ITagsResource;

    pedagogical_guideline?: IPedagogicalResource;
}

export function useUpdateResource() {
    const queryClient = useQueryClient();
    const { idResource } = useContext(ResourceContext)



    return useMutation(
        (resource: IResourceState) => {

            const data = {
                ...resource,
                collections: resource.collections.map((collection) => {
                    return {
                        collection_id: collection.collection_id,
                        subcategory_id: collection.subcategory_id,
                        default: resource.collectionDefault === collection.collection_id ? "1" : "0",
                    }
                }),
             
                test: resource.test ? "1" : "0",
                target_audiences: resource.target_audience,
                active: resource.active ? "1" : "0",
                published: resource.published ? "1" : "0",
                pedagogical_guideline: resource.pedagogical_guideline.guideline
            }

            // const data = new FormData();

            // data.append("name", resource.name);
            // data.append("format", resource.format);
            // data.append("url", resource.url)

            // const bnccJSON = JSON.stringify(resource.bncc)
            // data.append("bncc",bnccJSON)

            // resource.thumbnails.forEach((thumbs) => {
            //     data.append("thumbnails[]", thumbs);
            // })


            // data.append("language", resource.language);

            // data.append("test", "0");
            // data.append("active", "0");
            // data.append("published", "0");

            // // data.append(
            // //   "authorship[year]",
            // //   resource.authorshipYear?.toString() ?? ""
            // // );
            // for (const role in resource.authorship) {
            //     // Verificar se a chave é própria do objeto (não herdada)
            //     if (resource.authorship.hasOwnProperty(role)) {
            //         resource.authorship[
            //             role as keyof IResourceState["authorship"]
            //         ].forEach((name, index) => {
            //             data.append(`${role}[${index}]`, name);
            //         });
            //     }
            // }

            // resource.target_audience.forEach((audience) => {
            //     data.append("target_audiences[]", audience);
            // });

            // resource.plataforms.forEach((plataform) => {
            //     data.append("plataforms[]", plataform);
            // });

            // resource.collections.forEach((collection, index) => {
            //     const dataCollection = {
            //         collection_id: collection.collection_id,
            //         subcategory_id: collection.subcategory_id,
            //         default: resource.collectionDefault === collection.collection_id ? "1" : "0",
            //     };

            //     data.append(`collections[${index}][collection_id]`, dataCollection.collection_id
            //     );
            //     data.append(`collections[${index}][default]`, dataCollection.default);

            //     data.append(
            //         `collections[${index}][subcategory_id]`,
            //         dataCollection.subcategory_id
            //     );

            // });




            return ResourceService.update({ resource: data, identifier: idResource })
        }, {
        onError: (error: any) => {
            console.log(error);
            console.log("Error")

        },
        onSuccess: (data: any) => {
            console.log(data);
            console.log("Dados")
            queryClient.invalidateQueries(Queries.LIST_RESOURCES)
        },
    }
    )
}