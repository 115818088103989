import { api } from "./api";
import { AxiosError, AxiosResponse } from "axios";
import {
  CreateSuccess,
  Forbidden,
  Success,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { Client } from "../Utils/Types";

type ClienteEditProps = {
  social_name: string; // Nome social
  cnpj: string; // CNPJ
  phone: string; // Telefone
  email: string; // E-mail
  active: boolean; // Ativo
  logo?: File; // Logo
  address: {
    address: string; // Endereço
    number: number; // Número
    complement?: string; // Complemento
    neighborhood: string; // Bairro
    zip_code: string; // CEP
    city: string; // Cidade
    state: string; // Estado
  };
};
type ClienteCreateProps = {
  data: ClienteEditProps;
};

type ClienteCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type ClienteGetReponse = any | Unauthorized;

type ClienteGetProps = {
  path: string; //Caminho da rota
};



type ClientShowProps = {
  client: Client;
}

type ClientShowResponse = Success | Unauthorized | Forbidden

class ClienteService {

  static async showClient(input: ClientShowProps): Promise<ClientShowResponse> {

    const {client} = input

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        }
      }

      const response = await api.get<ClientShowResponse>(
        `/admin/clients/${client.identifier}`,
        config
      )
      
      return response.data;
    } catch (error: any) {
        throw new Error("Can not")
    }

  }

  static async post(data: any) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        }
      }

      const response = await api.post<ClienteCreateResponse>(
        `/admin/clients`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      throw new Error("Can not create client!")
    }
  }

  static async put(data: ClienteEditProps, id: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      console.table(data);
      const response = await api.post<ClienteCreateResponse>(
        `/admin/clients/${id}`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async get(data: ClienteGetProps) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<ClienteGetReponse>(data.path, config);

      return response;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }
}

export default ClienteService;
