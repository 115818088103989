import { Container, ContainerData, Main, Upload } from './styles'
import { ContainerNew } from './styles'
import { useEffect, useRef, useState } from 'react'
import { PlusCircleIcon, Trash2Icon, Plus, Edit, ToggleLeft } from 'lucide-react'
import { useCheckTime } from '../../../Components/LoginFunctions/Refresh'
import { api } from '../../../Services/api'
import { ModalShort } from '../../../Components/Modals/ModalShort'
import PackageService from '../../../Services/Package.service'
import { Input } from '../../../Components/Form/Input'
import { ListDefault } from '../../../Components/ListDefault'
import Modal, { ModalRef } from '../../../Components/Modals'
import { Expanded } from './Expanded'
import { TreeItem } from './TreeItem'
import { transformUrlImage } from '../../../Utils/Functions'
import { LeftMenu } from '../../../Components/LeftMenu'
import { Sitemap, Sitemap2 } from '../ListCollections/styles'
import { RegistrationsPacoteAndCategory } from './Registrations'
import { PackageProfile } from './Profile'

export function ListPackages() {
  useCheckTime();

  const modalRef = useRef<ModalRef>(null)
  const modalRegisterPakage = useRef<ModalRef>(null)
  const modalRegisterCategory = useRef<ModalRef>(null)
  const modalLinkResource = useRef<ModalRef>(null)
  const [inputSearchView, setInputSearchView] = useState("")
  const [packageFiltereds, setPackageFiltereds] = useState<any[]>([])

  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState<any>("");
  const [packageData, setPackageData] = useState<any>([]);
  const [packageshow, setPackageshow] = useState(true);
  const [packageDataShow, setPackageDataShow] = useState(false);
  const [ModalShortShow, setModalShortShow] = useState(false);

  const [packageNameEdit, setPackageNameEdit] = useState("");
  const [packageDescriptionEdit, setPackageDescriptionEdit] = useState("");

  const [switchPageList, setSwitchPageList] = useState(
    "/admin/packages?limit=10&page=1");

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("1");
  const [metaTotal, setMetaTotal] = useState("1");

  const [imgEdit, setImgEdit] = useState<any>("")
  const [message, setMessage] = useState("")
  const [modalDelete, setModalDelete] = useState(false)
  const [forDelete, setForDelete] = useState("")
  const [active, setActive] = useState("")

  // upload img edit
  function handlePreviewEdit(e: any) {
    if (!e.target.files) return
    setImgEdit(e.target.files[0])
  }

  const [imgVieu, setImgVieu] = useState<any>("")
  function ImageSelect() {
    if (imgVieu === null && imgEdit === "") {
      return <p>Inserir logo</p>;
    }
    if (imgEdit === "") {
      return <img src={transformUrlImage(imgVieu)} />;
    } else {
      return <img src={URL.createObjectURL(imgEdit)} />;
    }
  }

  // fechar modal
  function closeModal() {
    setModalShortShow(false);
    window.location.reload();
  }

  // voltar
  function back() {
    setPackageDataShow(false)
    setPackageshow(true)
    setExpandedItem(null)
    window.location.reload();
  }

  // listar pacotes
  useEffect(() => {

    async function fetchData() {
      await api.get(`/admin/packages/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => { setOptions(response.data.data); })
    }
    fetchData();
  }, []);

  // modal cadastrar pacote
  const handleRegisterPakages = () => {
    setActive("package")
    modalRegisterPakage.current?.open()
  }

   // modal cadastrar categoria
  const handleRegisterCategory = () => {
    setActive("category")
    setPackageDataShow(true);
    modalRegisterCategory.current?.open()
  }

  // modal vincular recurso
  const handleRecourse = () => {
    modalLinkResource.current?.open()
  }


  const handleEditPakages = (option: any) => {
    setSelectedOption(option)
    setPackageNameEdit(option.name)
    setPackageDescriptionEdit(option.description)
    setImgVieu(option.logo)
    modalRef.current?.open()
  }

  function cancelEdit() {
    setExpandedItem(null)
    modalRef.current?.close()
  }

  function cancelRegiterPackage() {
    modalRegisterPakage.current?.close()
  }

  function cancelRegiterCategory() {
    modalRegisterCategory.current?.close()
  }

  // listar pacotes
  const handleChange = (event: any) => {
    setSelectedOption(event);

    api.get(`/admin/packages/${event}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(response => { setPackageData(response.data.data); })
    setPackageDataShow(true);
    setPackageshow(false);

    api.get(`/admin/packages/${event}/categories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(response => { setpackageCategories(response.data.data); })
  };

  const [packageCategories, setpackageCategories] = useState<any[]>([]);

  // editar pacote
  function updatePackage() {
    const packageId = selectedOption.identifier
    if (packageNameEdit !== "") {

      const data = new FormData();
      data.append("name", packageNameEdit)
      data.append("description", packageDescriptionEdit)
      data.append("logo", imgEdit)
      data.append("_method", "PUT");

      PackageService.update({ idPackage: packageId, data }).then((response) => {
        if (response.success) {
          setMessage("Coleção editada com sucesso!")
          setModalShortShow(true)
        }
      })

    } else {
      alert("Preencha o Nome")
    }
  }

  // deletar pacote
  function deletePackage(idPackage: string) {
    api.delete(`/admin/packages/${idPackage}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(response => {
      window.location.reload();
    })
  }

  // expandir listagem de pacote
  const [expandedItem, setExpandedItem] = useState(null);
  const handleExpand = (identifier: any) => {
    setExpandedItem((prevExpandedItem) =>
      prevExpandedItem === identifier ? null : identifier
    );
  };

  // Filtrar a lista de pacotes
  useEffect(() => {

    let CollectionForFilter = options.flat()
    let filteredCollection = CollectionForFilter.filter((item: any) => item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setPackageFiltereds(filteredCollection)

  }, [options, inputSearchView])

  return (
    <Container>
      <LeftMenu />
      <div className='box'>
        <Main>

          {packageshow && (
            <>
              <Sitemap>
                <p>Listagem de Pacotes</p>
              </Sitemap>

              <ListDefault
                pages={pages}
                changeGet={(v: any) => setSwitchPageList(v)}
                to={metaTo}
                total={metaTotal}
              >

                <thead>
                  <tr>
                    <td style={{ width: "460px" }}>
                      <Input
                        type="search"
                        placeHolder="Pesquise por um pacote"
                        value={inputSearchView}
                        onChange={setInputSearchView}
                        color="#039bc9"
                      />
                    </td>
                    <td>
                      <button className="bt-cadastr"
                        onClick={() => handleRegisterPakages()}
                      >
                        Criar novo pacote
                        <Plus size={16} />
                      </button>
                    </td>
                  </tr>
                </thead>

                <thead>
                  <tr>
                    <td className="wd25">Logo</td>
                    <td className="wd25">Nome</td>
                    <td className="wd25">Descrição</td>
                    <td className="wd10">Ação</td>
                  </tr>
                </thead>

                <tbody>
                  {packageFiltereds.sort((a, b) => a.name.localeCompare(b.name))
                    .map((option: any) => {
                      return (
                        <>
                          <tr key={option.identifier} onClick={() => handleExpand(option.identifier)}>
                            <td>
                              <div className="logo">
                                <img
                                  src={
                                    option.logo
                                      ? transformUrlImage(option.logo)
                                      : ""
                                  } alt="" />
                              </div>
                            </td>

                            <td>
                              <button
                                className="profile"
                                type="button"
                                value={selectedOption}
                                onClick={() => handleChange(option.identifier)}
                              >
                                {option.name}
                              </button>
                            </td>

                            <td>{option.description}</td>
                            <td>
                              <button
                                className="hvr-grow"
                                onClick={() => handleEditPakages(option)}
                              >
                                <Edit />
                              </button>

                              <button className="hvr-grow"
                                style={{ color: "green", marginLeft: "10px" }}
                                onClick={() => { }} // passe a função
                              >
                                <ToggleLeft />
                              </button>
                            </td>
                          </tr>
                          {expandedItem === option.identifier && (
                            <td colSpan={4}>
                              <Expanded package={option} />
                            </td>
                          )}
                        </>
                      )
                    })}
                </tbody>
              </ListDefault>
            </>
          )}

            <Modal styleDefault={false} ref={modalRegisterPakage}>
              <RegistrationsPacoteAndCategory
                active={active}
                cancelRegiterPackage={cancelRegiterPackage}
                handleChange={handleChange}
                cancelRegiterCategory={cancelRegiterCategory}
                setpackageCategories={setpackageCategories}
                selectedOption={selectedOption}
              />
            </Modal>

            <Modal styleDefault={false} ref={modalRegisterCategory}>
              <RegistrationsPacoteAndCategory
                active={active}
                cancelRegiterPackage={cancelRegiterPackage}
                handleChange={handleChange}
                cancelRegiterCategory={cancelRegiterCategory}
                setpackageCategories={setpackageCategories}
                selectedOption={selectedOption}
              />
            </Modal>


          {/* EDIÇÃO DE PACOTE */}
          <Modal styleDefault={false} ref={modalRef}>
            <ContainerNew>
              <header>
                <p>Editar pacote</p>
              </header>

              <section>
                <Upload>
                  <label htmlFor="uploadInput">
                    <ImageSelect />
                  </label>
                  <input
                    type="file"
                    name=""
                    id="uploadInput"
                    onChange={(e) => handlePreviewEdit(e)}
                  />
                  <div className="text-type">
                    <p>Formato JPG ou PNG, 160 x 40 px.</p>
                  </div>
                </Upload>
                <div className='flex-label'>
                  <label htmlFor="">Nome do Pacote</label>
                </div>
                <div className='flex-inputs'>
                  <input type="text" onChange={(e: any) => setPackageNameEdit(e.target.value)} value={packageNameEdit} />
                </div>
                <div className='space' />
                <label htmlFor="">Descrição</label>
                <textarea className="multi" onChange={(e: any) => setPackageDescriptionEdit(e.target.value)} value={packageDescriptionEdit} />
              </section>

              <footer>
                <button className="hvr-grow" onClick={cancelEdit}>
                  Cancelar
                </button>
                <button className="hvr-grow" onClick={updatePackage}>
                  Salvar
                </button>
              </footer>
            </ContainerNew>
          </Modal>

          {/* EXIBIR UM PACOTE */}
          {packageDataShow && (
            <PackageProfile 
              packageData={packageData}
              packageCategories={packageCategories}
              selectedOption={selectedOption}
              back={back}
              handleRegisterCategory={handleRegisterCategory}
              handleEditPakages={handleEditPakages}

            />

            // <>
            //   <Sitemap2>
            //     <p>Pacote</p> <span>&bull;</span> <p>{packageData.name}</p>
            //   </Sitemap2>

            //   <ContainerData>
            //     <header>
            //       <div className="flex-collection">
            //         <div className="logo">
            //           <img src={packageData.logo
            //             ? transformUrlImage(packageData.logo)
            //             : ""} alt=""
            //           />
            //         </div>
            //         <div className='container-package'>
            //           <div className="name">
            //             Pacote: {packageData.name}
            //           </div>
            //           <div className="description">
            //             Descrição: {packageData?.description}
            //           </div>
            //         </div>
            //         <div className="action-icons">
            //           <Edit size={14}
            //             onClick={() => handleEditPakages(packageData)}
            //           />
            //           <Trash2Icon size={14} className="first-child"
            //             onClick={() => { setForDelete(packageData.identifier); setModalDelete(true) }}
            //           />
            //         </div>
            //       </div>
            //     </header>

            //     <section>
            //       <div className='add-category'>
            //         <button onClick={() => handleRegisterCategory()}>
            //           <PlusCircleIcon size={16} />
            //           Nova Categoria
            //         </button>
            //       </div>
            //       <span className="line"></span>
            //       <body>
            //         <div>
            //           {packageCategories.map((category: any) => (
            //             <TreeItem item={category} level={0} idPackage={selectedOption} />
            //           ))}
            //         </div>
            //       </body>
            //     </section>

            //     <footer>
            //       <div className="div-footer">
            //         <button className="hvr-grow"
            //           onClick={() => back()}
            //         >
            //           Voltar</button>
            //       </div>
            //     </footer>
            //   </ContainerData>
            // </>
          )}

          {ModalShortShow && (
            <ModalShort
              type="success"
              text={message}
              isOpen={ModalShortShow}
              onRequestClose={closeModal}
              onConfirm={closeModal}
            />
          )}

          {/* {modalDelete && (
            <ModalShort
              type="delete"
              isOpen={modalDelete}
              text={"Tem certeza de que deseja excluir este pacote? Todas as categorias associadas serão perdidas."}
              onRequestClose={() => {
                setModalDelete(false)
              }}
              onConfirm={() => {
                deletePackage(forDelete);
                setModalDelete(false)
              }}
            />
          )} */}

        </Main>
      </div>
    </Container>
  )
}