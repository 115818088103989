import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 800px;
    padding-top: 30px;
    flex-direction: column;
    gap: 30px;
`;

export const Header = styled.div`
    display: flex;
    width: 100%;
    gap: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid rgba(0,0,0,0.5);

    .div-text-area{
        display: flex;
        flex-direction: column;

        textarea{
            width: 456px;
            height: 111px;
            border-radius: 15px;
            border: 1px solid #646464;
            padding: 10px;
        }
        label{
            color: #646464;
            font-size: 14px;
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    height: 100px;
    flex-direction: column;

    
    span{
        display: block;
        height: 0.5px;
        width: 100%;
        background-color: rgba(0,0,0,0.5);
    }
    
    footer{
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        padding-top: 25px;

        button{
            height: 40px;
            width: 110px;
            border-radius: 10px;
            border: none;
            background: #088A62;
            color: #fff;
            text-transform: uppercase;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: transform 0.3s ease;
        
            &:first-child{
                background: #BCBCBC;
            }
        }

        button:hover{
            transform: scale(1.1);
          }
    }
`;