import styled from "styled-components";

export const DividerNew = styled.div`
  display: flex;
  justify-content: start;
  border-bottom: 3px solid #646464;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
`;

interface SelectWidthProps {
  $width?: string;
  $alignItems?: string;
  $fontSize?: string;
}

export const Div = styled.div<SelectWidthProps>`
  display: flex;
  width: ${(props) => props.$width};
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 10px;
`;
export const DivFlex = styled.div<SelectWidthProps>`
  display: flex;
  width: ${(props) => props.$width};
  align-items: ${(props) => props.$alignItems || "flex-start"};
  margin-top: 10px;
  input {
    margin: 5px;
  }
  p {
    text-align: left;
  }
  label {
    margin-right: 15px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  .gap {
    gap: 0.5rem;
  }
  .title {
    color: #646464;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .title-list {
    margin-bottom: 0.75rem;
  }
  .paragrafo {
    margin-top: 5px;
    margin-left: 10px;
    margin-bottom: 10px;
  }

  .icon-arrow {
    cursor: pointer;
  }
  .up {
    transform: rotate(180deg);
  }

  .down {
    transform: rotate(0deg);
  }

  b {
    margin: 10px;
  }
`;

export const ContainerSelect = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const InputSearch = styled.div`
  height: 45px;
  border: 1px solid #646464;
  border-radius: 15px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  input {
    width: 100%;
    border: none;
    height: 100%;
    outline: none;
    font-size: 14px;
  }
`;
export const ContainerIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  margin-top: 10px;
  .icon {
    width: 30px;
    height: 30px;
    :hover {
      cursor: pointer;
    }
  }
`;
export const SearchIcon = styled(InputSearch)`
  flex: 1;
  .icon {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;

export const Sitemap = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #646464;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

interface GetWidthProps {
  $maxWidth?: string;
}

export const Main = styled.main<GetWidthProps>`
  max-width: ${(props) => props.$maxWidth || "800px"};
  width: 100%;
  .center {
    align-items: center;
    text-align: center;
  }
`;

export const FormContainer = styled.form`
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;

  .showImages{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    flex-direction: column;
  }
  
  .imageItem{
    display: flex;
    width: 230px;
    align-items: last baseline;
  }

  .img-preview {
    display: flex;
    align-items: start;
    gap: 0.5rem;
    margin-top: 0.5rem;
    max-height: 600px;
    img {
      border-radius: 8px;
      max-width: 500px;
      height: auto;
      object-fit: cover;
    }
    .icon-delete {
      color: inherit;
      &:hover {
        color: red;
        cursor: pointer;
      }
    }
  }
  .mt {
    margin-top: 0.5rem;
  }
  .empty-img {
    width: 192px;
    height: 192px;
    background-color: #d4d4de;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
  }
  .icon-delete {
    color: red;
    cursor: pointer;
  }
  .container-img {
    img {
      border-radius: 8px;
      max-height: 100%;
      max-width: 95%;
      object-fit: cover;
    }
  }
  .button-add-file {
    color: #fe8f2e;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-direction: row;
    font: 1.5rem;
    gap: 5px;
    text-transform: uppercase;
    font-weight: bold;
  }
  input[type="radio"] {
    cursor: pointer;
  }

  .icon-plus {
    color: #fe8f2e;
    cursor: pointer;
  }

  .span-delete {
    color: red;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  > div {
    width: 100%;
  }
  margin-top: 10px;


`;

export const Title = styled.p`
  background-color: #027294;
  color: #fff;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
`;

export const ButtonSavePOP = styled.button`

display: flex;
justify-content: center;
align-items: center;
background-color: #088a62;
width: 90px;
height: 40px;
border: none;
border-radius: 10px;
color: #fff;
text-transform: uppercase;
font-size: 16px;


`


interface LineProps {
  $margin?: string;
}
export const Line = styled.hr<LineProps>`
  width: 100%;
  border-bottom: 1px solid #646464;
  border-radius: 10px;
  margin: ${({ $margin }) => $margin ?? "0px"};
`;

export const DivNoSub = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  background-color: #e9e9ee;
`

interface SubcategoryProps {
  $flex?: string
}

export const OptionsCategories = styled.div<SubcategoryProps>`
  display: flex;
  flex-direction: ${({ $flex }) => $flex || "row-reverse"};
  position: relative;
  width: fit-content !important;
  height: auto;
  min-height: 200px;

  
  .option {
    cursor: pointer;
    transform: rotate(
      180deg
    ); /* Rotaciona o texto 270 graus no sentido horário */
    writing-mode: vertical-lr;
    white-space: nowrap;
    color: white;
    text-transform: uppercase;
    padding: 1rem;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    display: flex;

    justify-content: space-between;

    :hover {
      background-color: #646464;
    }
  }
  .option {
    margin-left: -6px;
  }

  .option > .icon {
    transform: rotate(-180deg);
  }

  .option:nth-of-type(odd) {
    background-color: #d4d4de;
  }
  .option:nth-of-type(even) {
    background-color: #a7a7a7;
  }

  .selected {
    background-color: #027294 !important;
  }
`;

interface RowProps {
  $align?: string;
  $justify?: string;
}
export const Row = styled.div<RowProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ $justify }) => $justify || "center"};
  gap: 10px;
  width: 100%;
  align-items: ${({ $align }) => $align || "center"};

  .info-message {
    color: #646464;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .button-span {
    color: #fe8f2e;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    gap: 0.25rem;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .input-style {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    input {
      cursor: pointer;
    }
  }
  .label {
    color: #646464;
    /* TXT campos */
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .line {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }

  .complete {
    flex: 1;
  }
  .item {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    select {
      width: 100%;
    }
  }
  .align-start {
    align-self: flex-start ;
  }

  .item-row {
    flex: 1;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
  }

  .no-gap {
    gap: 0px;
  }
`;

export const ListItensSelect = styled.ul`
  border-radius: 15px;
  border: 1px solid #646464;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    list-style: none;
    min-height: 40px;
    box-sizing: border-box;

    button {
      padding: 0 0.8rem;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #646464;
    }
  }

  li + li {
    border-top: 1px solid #646464;
  }
`;
