import { IResource } from "../../../../../../types/types";
import { useListEF } from "../../../../../Registration/ResourcesRegistration/hooks/bncc/useListEF";
import { KnowledgeArea } from "./Item/KnowledgeArea";
import { ListKnowledgeArea, TitleSection } from "./style";

interface ElementarySchoolProps {
  resource: IResource;
}

const ElementarySchool = ({ resource }: ElementarySchoolProps) => {
  const { bncc } = resource;
  const { data: efData } = useListEF();

  const knowledgeAreasSelected = efData?.knowledge_areas.filter((item) =>
    bncc?.ef.knowledge_areas?.includes(item.name)
  );
  const hasKnowledgeAreas = Boolean(
    knowledgeAreasSelected && knowledgeAreasSelected?.length > 0
  );

  //   const thematicUnitsNaturalScience = efData?.knowledge_areas[
  //     EnumCurricularComponentsIndex.NaturalSciences
  //   ].curricular_components.map((item) =>
  //     item.thematic_units.map((item) => item.name)
  //   );
  //   const thematicUnitsNaturalScienceSelected =
  //     bncc?.ef.thematic_units &&
  //     thematicUnitsNaturalScience?.includes(bncc?.ef.thematic_units);

  return (
    <section>
      <h4>ENSINO FUNDAMENTAL</h4>
      {/* <p>Fase:</p> */}
      <TitleSection>Áreas do Conhecimento</TitleSection>
      <ListKnowledgeArea>
        {hasKnowledgeAreas ? (
          knowledgeAreasSelected?.map((knowledgeArea) => (
            <>
              <KnowledgeArea
                key={knowledgeArea.name}
                knowledgeArea={knowledgeArea}
                resource={resource}
              />
              <br />
            </>
          ))
        ) : (
          <p>Nenhuma Área do conhecimento informada</p>
        )}
      </ListKnowledgeArea>

      {/* {bncc?.ef.knowledge_areas?.map((area) => (
        <>
          <p style={{ textTransform: "uppercase" }}>{area}</p>
          <p>Componentes curriculares:</p>
          {bncc.ef.curricular_components?.map((curricularComponent, index) => {
            return (
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p>- {curricularComponent}</p>
                </li>
              </ul>
            );
          })}
          <p></p>
        </>
      ))} */}
    </section>
  );
};

export default ElementarySchool;
