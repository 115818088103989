import styled from "styled-components";

export const Container = styled.div`
border-radius: 8px;
flex: 1;
padding: 10px;
>div.head{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    >p{
        font-weight: 700;
        font-size: 15px;
    }
}
>div.body{
    padding: 10px 0px;
    >p{
        font-weight: 700;
        font-size: 12px;
        padding: 5px 0px;
    }
}
`;