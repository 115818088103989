import { useContext } from 'react'
import { ContainerFooter, Footer, NavigateButton } from './styles'
import { ContextResourceReview } from '../Context/ContextReviewResource'
import { number } from 'zod'

const FooterReviewResource = () => {
  const { handleStepReview, stepReview } = useContext(ContextResourceReview)
  const buttons: number[] = [1, 2, 3, 4]
  // #027294
// #838383

  return (
    <ContainerFooter>
      <Footer>
        {buttons.map((button, index) => {
          
          const active = (button === stepReview)

          return (
            <NavigateButton
              $background={active === true ? "#027294" : "#838383"}
              onClick={() => handleStepReview(button)}
              key={index}
            >
              <p>{button}</p>
            </NavigateButton>
          )
        }
        )}
      </Footer>
    </ContainerFooter>
  )
}

export default FooterReviewResource