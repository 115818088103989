import React, { useContext } from 'react'
import { ContainerAnswer, ContainerPOPReview } from './styles'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { ResourceContext } from '../../../../../Contexts/Form/ContextResource'

const Image = (props: any) => {
    return <img src={URL.createObjectURL(props.image)} alt={props.description} />
}


const ReviewPOP = () => {

    const { resource } = useContext(ResourceContext)
    const { tips, guideline, similar_reference, answer_key } = resource.pedagogical_guideline
    return (
        <ContainerPOPReview>
            <h2>POP</h2>
            <BoxResume>
                <tr>
                    <td>
                        <span>
                            Fica a dica: {" "}
                            {tips ? tips : "Não informado."}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Orientações:</span>
                    </td>
                </tr>
                {guideline.length > 0 ?
                    guideline.map((guide) => {
                        return (
                            <tr><td><span>- {guide.description}</span></td></tr>
                        )
                    })
                    : "Não informado"
                }
                <tr>
                    <td>
                        <span>
                            Referências similares: {" "}
                            {similar_reference ? similar_reference : "Não informado."}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Gabaritos:</span>
                    </td>
                </tr>
                {answer_key.length > 0 ?
                    answer_key.map((answer) =>
                        <ContainerAnswer>
                            <Image image={answer.image}
                            description={answer.description}
                            />
                            <p>Descrição: {answer.description}</p>
                        </ContainerAnswer>
                    )
                    : "Não informado."
                }

            </BoxResume>
        </ContainerPOPReview>
    )
}

export default ReviewPOP
