import styled from "styled-components";


export const ContainerBNCCReview = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
flex-direction: column;
gap: 5px;
margin-top: 10px;


`