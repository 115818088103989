import { Edit, ToggleLeft, Trash } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CallRegister } from "../../../Components/CallRegister";
import { ListDefault } from "../../../Components/ListDefault";
import { SearchTable } from "../../../Components/SearchTable";
import { SuperBox } from "../../../Components/SuperBox";
import { Container, Main, Unidades } from "./styles";
import { FormTechEdit } from "./Edit";
import { UserProfile } from "./Profile";
import { api } from "../../../Services/api";
import { useEffect } from "react";
import { useCheckTime } from "../../../Components/LoginFunctions/Refresh";
import { LoadingScreen } from "../../../Components/Loading";
import TechService from "../../../Services/Tech.service";
import { TypeTech } from "../../../Utils/Types";
import { Input } from "../../../Components/Form/Input";

export function ListTech() {
  useCheckTime();

  const [list, setList] = useState("1");
  const [switchScreen, setSwitchScreen] = useState(1);
  const [typeProfile, setTypeProfile] = useState(1);
  const [options, setOptions] = useState<any>([]);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [techSelect, setTechSelect] = useState<TypeTech | null>(null);
  const [user, setUser] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchMeData() {
      await api
        .post(
          `/auth/me`,
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          localStorage.setItem(
            "uuid",
            response.data.data.roles.data[0].identifier
          );
          localStorage.setItem(
            "username",
            response.data.data.roles.data[0].name
          );
        });
    }

    TechService.getAll()
      .then((response) => {
          setOptions(response.data);
      })
      .finally(() => setIsLoading(false));
    // fetchMeData();
  }, []);



  const pathBad = switchScreen === 3 ? `Edição \u2022 Técnico` : `Técnico`;

  const [inputSearchView, setInputSearchView] = useState("")
  const [techFiltereds, setTechFiltereds] = useState<any[]>([])

  useEffect(() => {

    const filteredTech = options.filter((item: any) => item.user.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setTechFiltereds(filteredTech)

  }, [inputSearchView, options])

  console.log(techFiltereds)


  return (
    <LoadingScreen isLoading={isLoading}>
      <SuperBox switch={true} page={4} path={pathBad}>
        <Container>
          <div className="box">
            {switchScreen === 1 && (
              <Main>
                <CallRegister
                  page="Técnico"
                  link="/tech/registration"
                  value={list}
                  onChangj
                />
                <ListDefault
                  pages={[]}
                  changeGet={(v: any) => { }}
                  to={"1"}
                  total={""}
                >
                  <thead>
                    <tr>
                      <td colSpan={8}>
                        <Input
                          flex="fx30"
                          type="search"
                          placeHolderTeacher="Pesquise por um técnico"
                          color="#039bc9"
                          value={inputSearchView}
                          onChange={setInputSearchView}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="wd30">Nome</td>
                      <td className="wd15">Nível</td>
                      <td className="wd15">Cargo</td>
                      <td className="wd15">Empresa</td>
                      <td className="wd15">Email</td>
                      <td className="wd10">Ação</td>
                    </tr>
                  </thead>
                  <tbody>
                    {techFiltereds?.map((option: any) => {
                      return (
                        <tr key={option.identifier}>
                          <td>
                            <Link
                              to=""
                              onClick={() => {
                                setSelected(option);
                                setSwitchScreen(2);
                                setTypeProfile(1);
                                setTechSelect(option);

                              }}
                            >
                              {option?.user.name}
                            </Link>
                          </td>
                          <td>
                            {option?.user.roles 
                            ?
                            option?.user.roles[0].name
                            : "Não informado"
                           }
                          </td>
                          <td>{option.role ? option.role : "Não informado."}</td>
                          <td>{option.company}</td>
                          <td>{option.user.email}</td>
                          <td>
                            <button
                              className="hvr-grow"
                              onClick={() => {
                                setSelected(option);
                                setSwitchScreen(3);
                                setTypeProfile(1);
                                setTechSelect(option);
                              }}
                            >
                              <Edit />
                            </button>
                            <button
                              className="hvr-grow"
                              style={{ color: "green", marginLeft: "10px" }}
                            >
                              <ToggleLeft />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </ListDefault>
              </Main>
            )}
            {switchScreen === 2 && (
              <UserProfile
                type={typeProfile}
                back={() => {
                  setSwitchScreen(1);
                  setList("1");
                }}
                edit={() => {
                  setSwitchScreen(3)
                }}
                selected={selected}
              />
            )}
            {switchScreen === 3 && (
              <FormTechEdit
                type={typeProfile}
                back={() => setSwitchScreen(1)}

                selected={selected}
                techSelect={techSelect}
              />
            )}
          </div>
        </Container>
      </SuperBox>
    </LoadingScreen>
  );
}
function usseEffect(arg0: () => void) {
  throw new Error("Function not implemented.");
}
