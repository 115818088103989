import React, { useContext, useEffect, useRef, useState } from 'react'
import { Controllers } from '../../../../Components/Form/Controllers'
import { EnumStepNewResource, ResourceContext } from '../../../../Contexts/Form/ContextResource';
import { Form } from '../../UnitRegistration/styles';
import { Line } from '../styles';
import { useNavigate } from 'react-router-dom';
import ResourceService from '../../../../Services/Resouce.service';
import { IResourceState } from '../../../../reducers/newResource/reducer';
import { useUpdateResource } from '../hooks/useUpdateResource';
import InputEditor from './InputEditor/InputEditor';
import { set } from 'jodit/esm/core/helpers';

const FormDocuments = () => {
    const navigate = useNavigate()
    const { handleStep, idResource, resource } = useContext(ResourceContext);
    const arrayTexts = ["Storyboard (STB)/Roteiro", "Histórico de ajustes/correções"]

    const [onSave, setOnSave] = useState(false)
    const {setStoryBoard, content, setContent} = useContext(ResourceContext)



    function onSuccessfulSave() {
        alert("Recurso finalizado!")
        navigate("/recursos/list")
        window.location.reload()
    }


    function saveResource() {
        handleStep(EnumStepNewResource.REVIEW)
        console.log(content)
        setStoryBoard(content)
    }


    return (
        <Form>
            <br />
            <InputEditor
                text="Storyboard (STB)/Roteiro"
                placeholder='Digite...'
                content={content}
                setContent={setContent}
            />


            <Controllers
                enableNext={true}
                enablePrev={true}
                functionNext={() => saveResource()}
                functionCancel={() => {
                    window.location.reload()
                }}
                functionPrev={() => handleStep(EnumStepNewResource.TUTORIAL)}
            />
        </Form>
    )
}

export default FormDocuments
