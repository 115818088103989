import React from 'react'
import ReviewImportClass from './ReviewImportClass/ReviewImportClass'

interface ReviewImportsProps {
    itemsArray: any[],
    type: string
}


const ReviewImports = ({ itemsArray, type }: ReviewImportsProps) => {
    return (
        <div>

            {type === "class" && <ReviewImportClass arrayClass={itemsArray}/>}

        </div>
    )
}

export default ReviewImports
