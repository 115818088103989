import styled, { css } from "styled-components";

export const Line = styled.div`
width: 100%;
height: 1px;
background-color: black;
margin-bottom: 10px;
`

export const Container = styled.div`
  display: flex;
  width: 80%;
  margin-top: 10px;
`;

interface StepProps {
    $color: string;
    $center: boolean;
    $button: boolean;
    $active: boolean
  }
  export const Step = styled.div<StepProps>`
    ${({ $center }) =>
      $center
        ? css`
            padding: 0.5rem 1rem;
  
            justify-content: center;
          `
        : css`
            padding: 1rem;
            flex: 1;
          `}
  
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px solid black;
    border-bottom: none;
    border-radius: 10px 10px 0px 0px;
    
    color: #fff;
    text-align: center;
    height: 40px;
    /* TXT Botao Bold */
  
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    cursor: ${({ $button }) => ($button ? "pointer" : "default")};
    span {
      color: #646464;
      margin: 0;
      padding: 0; 
    }
  
    .icon {
      color: white;
    }
    &.active {
      background-color: ${({ $color }) => $color};
      span{
        color: #fff;
        margin: 0;
      padding: 0; 
      }
    }
  `;
  