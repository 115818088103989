import { Container } from './styles'
import DonutChart from 'react-donut-chart'

export function ChartDount(props: any) {
    const valueOff = 100 - props.qt;

  return (
    <Container>
      <p>USO TOTAL DE CADASTRO DE USUÁRIOS: FUNCIONÁRIOS E ESTUDANTES</p>
      <div>
        <p>{props.qt}%</p>
        <DonutChart
        
          data={[
            {
              label: '',
              value: props.qt,
            },
            {
              label: '',
              value: valueOff,
            },
          ]}
          emptyColor=""
          legend={false}
          height={180}
          width={180}
          colors={['#088A62', '#646464']}
          strokeColor="#fff"
          clickToggle={false}
          interactive={false}
        />
      </div>
    </Container>
  )
}
