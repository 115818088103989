import React, { ChangeEvent, useState } from "react";
import { Label } from "../../../../../Components/TextAreaHook/styles";
import DividerHook from "../../../../../Components/Form/DividerHook";
import { ArrowLeft, ArrowRight, Info, PlusCircle } from "lucide-react";
import { DivScreens } from "./style";
import Screen from "./Screen";
import { TextArea } from "../../../../../Components/TextAreaHook";
import { IGuidelineResource } from "../../../../../reducers/newResource/reducer";

interface GuidelanesProps {
  guidelanes: IGuidelineResource[];
  handleGuidelanes: (event: string, numberGuidelane: number) => void;
  addGuidelanes: () => void;
  label: string;
}

export default function Guidelines({
  guidelanes,
  handleGuidelanes,
  addGuidelanes,
  label,
}: GuidelanesProps) {
  const [guidelineSelected, setGuidelineSelected] = useState(0);

  function prevGuideLine() {
    if (guidelineSelected > 0) {
      setGuidelineSelected(guidelineSelected - 1);
    }
  }

  function proxGuideLine() {
    if (guidelineSelected < guidelanes.length - 1) {
      setGuidelineSelected(guidelineSelected + 1);
    }
  }

  function handleGuidelines(event: ChangeEvent<HTMLTextAreaElement>) {
    handleGuidelanes(event.target.value, guidelineSelected);
  }

  function createGuideline() {
    if (guidelanes[guidelineSelected].description.length > 0) {
      addGuidelanes();
      setGuidelineSelected(guidelanes.length);
    }
  }
  return (
    <>
      <DividerHook margintTop="5px" flexD="column">
        <DividerHook justifyContent="flex-start">
          <Label>{label}</Label>
          <Info fill="#fe8f2e" color="white" style={{ marginLeft: "5px" }} />
        </DividerHook>
      </DividerHook>
      <DivScreens>
        <ArrowLeft onClick={prevGuideLine} style={{ cursor: 'pointer' }} />
        <div className="add">
          <Screen
            quantityScreen={guidelanes.length}
            selectScreen={guidelineSelected}
          />
          <PlusCircle
            onClick={() => addGuidelanes()}
            size={20}
            style={{ margin: "5px",cursor: 'pointer' }}
          />
        </div>
        <ArrowRight onClick={proxGuideLine} style={{ cursor: 'pointer' }} />
      </DivScreens>
      <TextArea
        value={guidelanes[guidelineSelected].description}
        onChange={handleGuidelines}
        label=""
        required={false}
        type="type1"
        height="110px"
      />
    </>
  );
}
