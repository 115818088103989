import styled from 'styled-components'

export const Container = styled.div`
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    background-color: #fff;
    width: 300px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
    > p {
      text-align: center;
      font-size: 19px;
      color: #8d8d8d;
      &.red{
        color: #FF3737;
      }
    }
    > button {
      background-color: #088a62;
      color: #fff;
      font-size: 16px;
      text-transform: uppercase;
      height: 35px;
      width: 90px;
      border-radius: 5px;
      border: none;
      cursor: pointer;
    }
    > div.yes-no {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
      > button {
        background-color: #088a62;
        color: #fff;
        font-size: 16px;
        text-transform: uppercase;
        height: 35px;
        width: 90px;
        border-radius: 5px;
        border: none;
        cursor: pointer;

        &:first-child{
            background-color: #626262;
        }
      }
    }
  }
`
