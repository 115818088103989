import React from 'react'
import { ListDefault } from '../../../../Components/ListDefault';
import { SearchTable } from '../../../../Components/SearchTable';
import { Edit, ToggleLeft } from 'lucide-react';
import { useNavigate } from "react-router-dom";

interface Props {
    pages: any,
    setSwitchPageListManager: any,
    metaTo: any,
    metaTotal: any,
    manager: any,
    setSelected: any,
    setSwitchScreen: any,
    setTypeProfile: any
}


const ListManager = ({
    metaTo,
    metaTotal,
    pages,
    setSwitchPageListManager,
    manager,
    setSelected,
    setSwitchScreen,
    setTypeProfile
}: Props) => {
    const navigate = useNavigate();
    const onEditEmployee = (option: any) => 
        navigate("/unidade/usuario/edit", { state: option });

    return (
        <ListDefault
            pages={pages}
            changeGet={(v: any) => setSwitchPageListManager(v)}
            to={metaTo}
            total={metaTotal}
        >
            <thead>
                <tr>
                    <td colSpan={5}>
                        <SearchTable
                        />
                    </td>
                </tr>
                <tr>
                    <td className="wd30">Nome</td>
                    <td className="wd15">Cargo/Função</td>
                    <td className="wd25">Email</td>
                    <td className="wd20">Telefone</td>
                    <td className="wd10">Ação</td>
                </tr>
            </thead>
            <tbody>
                {manager?.map((option: any) => {
                    return (
                        <tr>
                            <td>
                                <button
                                    className="profile"
                                    type="button"
                                    onClick={() => {
                                        setSelected(option);
                                        setSwitchScreen(2);
                                        setTypeProfile(2);
                                    }}
                                >
                                    {option?.user.name}
                                </button>
                            </td>
                            <td>
                                {option.user.roles[0]
                                    ? option.user.roles[0].userType
                                    : "Não definido"}
                            </td>
                            <td>{option.user.email}</td>
                            <td>{option.user.phone}</td>
                            <td>
                                <button
                                    className="hvr-grow"
                                    onClick={() => {
                                        setSelected(option);
                                        onEditEmployee(option);
                                        //setSwitchScreen(3);
                                        setTypeProfile(2);
                                    }}
                                >
                                    <Edit />
                                </button>
                                <button
                                    className="hvr-grow"
                                    style={{ color: "green", marginLeft: "10px" }}
                                >
                                    <ToggleLeft />
                                </button>
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </ListDefault>
    )
}

export default ListManager
