import { useEffect, useState } from "react";
import { Abas, Container, Main } from "../../List/ListCollections/styles";
import { api } from "../../../Services/api";
import { SuperBox } from "../../../Components/SuperBox";
import { useNavigate } from "react-router-dom";
import { ContainerNew, Radio, Upload } from "./styles";

function CollectionRegistration() {

    const [collectionName, setCollectionName] = useState("");
    const [collectionDescription, setCollectionDescription] = useState("");
    const [defaultSelect, setDefaultSelect] = useState(false)
    const [createNew, setCreateNew] = useState(false)

    const navigate = useNavigate()
    const [img, setImg] = useState<any>("")
    console.log(img)

    function handlePreview(e: any) {
        if (!e.target.files) return
        setImg(e.target.files[0])
    }

    function handleChangeDefault(){
        setDefaultSelect(true)
        if(defaultSelect === true){
            setCreateNew(false)
            return
        }
        setCreateNew(false)
    }

    function handleChangeCreateNew(){
        setCreateNew(true)
        if(createNew === true){
            setDefaultSelect(false)
            return
        }
        setDefaultSelect(false)
    }


    function addNewCollection() {

        const data = new FormData();

        data.append("name", collectionName)
        data.append("description", collectionDescription)
        data.append("logo", img)

        if (collectionName !== "" || collectionDescription !== "") {
            api.post(`/admin/collections/`, data, {

                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'multipart/form-data'
                }

            }).then(response => {
                alert("Coleção salva com sucesso!")
                navigate('/collection/list')

            })
        } else {
            alert("Preencha o Nome e Descrição e a logo");
        }
    }

    return (
        <SuperBox
            path={`Cadastro Coleção`}
        >
            <Abas>
                <button>
                    Coleção
                </button>
            </Abas>
            <Container>
                <Main>
                    <ContainerNew>
                        <header>
                            <p>Cadastrar nova coleção</p>
                        </header>
                        <section>

                            <Upload>
                                <label htmlFor="uploadInput">
                                    {img === '' ? (
                                        <p>Clique aqui para fazer o Upload da Logo</p>
                                    ) : (
                                        <img src={URL.createObjectURL(img)} alt="" />
                                    )}
                                </label>
                                <input
                                    type="file"
                                    name=""
                                    id="uploadInput"
                                    onChange={(e) => handlePreview(e)}
                                />
                                <div className="text-type">
                                    <p>Formato JPG ou PNG, 160 x 40 px.</p>
                                </div>
                            </Upload>

                            <div className='flex-label'>
                                <label htmlFor="">Nome da coleção</label>
                            </div>
                            <div className='flex-inputs'>
                                <input type="text" onChange={(e: any) => setCollectionName(e.target.value)} value={collectionName} />
                            </div>
                            <div className='space' />
                            <label htmlFor="">Descrição</label>
                            <textarea className="multi" onChange={(e: any) => setCollectionDescription(e.target.value)} value={collectionDescription} />

                            <Radio>
                            <div className="">
                                <p>Qual modelo de categorização você deseja utilizar na coleção?</p>
                                <div className="radio-inputs">
                                    <label htmlFor="">
                                        <input
                                            type="checkbox"
                                            checked={defaultSelect}
                                            onChange={()=> handleChangeDefault()}
                                        />
                                        Padrão (<i>recomendado</i>)
                                    </label>
                                    <label htmlFor="">
                                        <input 
                                        type="checkbox"
                                        checked={createNew}
                                        onChange={()=> handleChangeCreateNew()} 
                                        
                                        />
                                        Criar uma nova
                                    </label>
                                </div>
                            </div>
                            </Radio>

                        </section>
                        <footer>
                            <button className="hvr-grow" onClick={() => { navigate('/collection/list') }}>
                                Cancelar
                            </button>
                            <button className="hvr-grow" onClick={addNewCollection}>
                                Salvar
                            </button>
                        </footer>
                    </ContainerNew>
                </Main>
            </Container>

        </SuperBox>
    )
}

export default CollectionRegistration;