import { useState } from "react";
import Switch from "react-switch";
import { Banner, BoxAccess, DivSelect, Section } from "./styles";
import { Select } from "../../../Components/Form/Select";

interface AccessProps {
    editViewMode: string
}

export function Access({ editViewMode }: AccessProps) {
    const [isOpenFirs, setIsOpenFirst] = useState(false);
    const [isOpenSecond, setIsOpenSecond] = useState(false);
    const [isOpenThird, setIsOpenThird] = useState(false);
    const [checked, setChecked] = useState(false);

    // verificar
    function handleChange() {
        if (checked === false) {
            setChecked(true)
        } else {
            setChecked(false)
        }
    }

    return (
        <BoxAccess>
            <p className="p-Accesses">Acessos</p>

            <Section>
                <Banner className={isOpenFirs ? 'opened' : ''}>
                    <div className="box-banner">
                        <div className="itens">
                            <input type="checkbox" />
                            <p>Portal Administrativo</p>
                            <div onClick={() => setIsOpenFirst(!isOpenFirs)}>
                                <span className="arrow-icon"></span>
                            </div>
                        </div>
                    </div>
                </Banner>

                {isOpenFirs && (
                    <DivSelect>
                        <div className="div-select">
                            <p className="text-title">Clientes</p>
                            <div className="div-switch">
                                <Switch className="react-switch"
                                    offColor="#BCBCBC"
                                    onColor="#1B73C3"
                                    width={25}
                                    height={13}
                                    checked={checked}
                                    onChange={handleChange}
                                />
                                <p>Selecionar</p>
                            </div>
                            

                            {checked === true ? editViewMode === "register" && (
                                <Select
                                    flex="fx100"
                                    value={""}
                                    onChange={""}
                                >
                                </Select>

                            ) :

                                <></>

                            }


                            <div className="line">
                                <span></span>
                            </div>
                        </div>
                    </DivSelect>

                )}

            </Section>


            <Section>
                <Banner className={isOpenSecond ? 'opened' : ''}>
                    <div className="box-banner" style={{ backgroundColor: "#6665AC", borderBottom: "border-bottom: 1px solid #6665AC" }}>
                        <div className="itens">
                            <input type="checkbox" />
                            <p>Portal do Professor</p>
                            <div onClick={() => { setIsOpenSecond(!isOpenSecond) }}>
                                <span className="arrow-icon"></span>
                            </div>
                        </div>
                    </div>
                </Banner>

                {isOpenSecond && (
                    <DivSelect style={{ border: "1px solid #6665AC" }}>
                    </DivSelect>
                )}

            </Section>


            <Section>
                <Banner className={isOpenThird ? 'opened' : ''} >
                    <div className="box-banner" style={{ backgroundColor: "#3EB8CC", borderBottom: "border-bottom: 1px solid #3EB8CC" }}>
                        <div className="itens">
                            <input type="checkbox" />
                            <p>Portal do Aluno</p>
                            <div onClick={() => { setIsOpenThird(!isOpenThird) }}>
                                <span className="arrow-icon"></span>
                            </div>
                        </div>
                    </div>
                </Banner>

                {isOpenThird && (
                    <DivSelect style={{ border: "1px solid #3EB8CC" }}>
                    </DivSelect>
                )}

            </Section>

        </BoxAccess>
    )
}