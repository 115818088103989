import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
gap: 5px;
&.fx10{
    width: 10%;
}
&.fx15{
    width: 15%;
}
&.fx20{
    width: 20%;
}
&.fx25{
    width: 25%;
}
&.fx30{
    width: 30%;
}
&.fx33{
    width: 33.33%;
}
&.fx35{
    width: 35%;
}
&.fx40{
    width: 40%;
}
&.fx45{
    width: 45%;
}
&.fx50{
    width: 50%;
}
&.fx55{
    width: 55%;
}
&.fx60{
    width: 60%;
}
&.fx65{
    width: 65%;
}
&.fx70{
    width: 70%;
}
&.fx75{
    width: 75%;
}
&.fx80{
    width: 80%;
}
&.fx85{
    width: 85%;
}
&.fx90{
    width: 90%;
}
&.fx95{
    width: 95%;
}
&.fx100{
    width: 100%;
}
&.fx10{
    width: 10%;
}


>input{
    height: 40px;
border: 1px solid #646464;
border-radius: 15px;
padding: 0px 15px;
font-size: 12px;
}

>div.consult{
    display: flex;
    position: relative;
    button{
        background-color: var(--yellow);
        color: #fff;
        width: 15px;
        height: 15px;
        border: none;
        border-radius: 50%;
        cursor: pointer;
    }
}
`;

export const Label = styled.label`
    color: #646464;
    font-size: 14px;
`;

export const Text = styled.input`
  height: 45px;
  border: 1px solid #646464;
  border-radius: 15px;
  padding: 0px 15px;
  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;