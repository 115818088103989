import { Button } from "./../../Form/ImportExcel/styles";
import styled from "styled-components";

export const Container = styled.div`
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > p {
    background-color: #fe8d28;
    text-transform: uppercase;
    font-size: 20px;
    width: 800px;
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    color: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    > button {
      position: absolute;
      top: 5px;
      right: 5px;
      height: 30px;
      border: none;
      background-color: transparent;
      color: #fffefd;
      cursor: pointer;
    }
  }
  > div {
    background-color: #fff;
    width: 800px;
    border-radius: 0px 0px 10px 10px;
    display: block;
    gap: 10px;
    padding: 20px;
    max-height: 500px;
    overflow-y: scroll;
    > div {
      width: 100%;
      margin-bottom: 10px;
    }
  }
`;

export const Controlls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  Button {
    height: 40px;
    border: none;
    border-radius: 5px;
    width: 100px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    background-color: #088a62;
    cursor: pointer;
    &:first-child {
      background-color: #666666;
    }
  }
`;

export const ButtonGreen = styled.button`
  height: 40px;
  width: 110px;
  border-radius: 10px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  align-self: flex-end;
  background-color: #088a62;
  cursor: pointer;
`;
