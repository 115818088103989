import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    width: 585px;
    height: 651px;
    align-items: center;
    flex-direction: column;

    .div-header{
        display: flex;
        width: 564px;
        height: 33px;
        background-color: #027294;
        border-radius: 15px 15px 0px 0px;
        align-items: center;
        justify-content: center;
        
        p{
            font-size: 16px;
            color: #FFFFFF;
        }
    }
`;

export const BoxAll = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5px 15px 0px 15px;
    border: 1px solid #646464;
    border-radius: 11px 11px 0px 0px;
    flex-direction: column;
`

export const Filter = styled.div`
    display: flex;
    width: 100%;
   
    flex-direction: column;
    
    .filter{
        display: flex;
        gap: 5px;
        align-items: center;
        padding-bottom: 5px;
    }
    .search{
        padding-top: 10px;
        padding-bottom: 20px;
    }

`