import React, { useContext, useEffect, useMemo, useState } from 'react'
import { ContainerBasicInformations } from './styles'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { ResourceContext } from '../../../../../Contexts/Form/ContextResource'
import CollectionService from '../../../../../Services/Collection.service'
import AuthorReview from './Author/AuthorReview'
import Thumbs from './Thumbs/Thumbs'
import Simlarity from './Similarity/Simlarity'
import TargetAudiences from './TargetAudiences/TargetAudiences'

const BasicInformations = () => {

    const { resource } = useContext(ResourceContext)
    const [collectionsNameArray, setCollectionsNameArray] = useState<string[]>([])
    const [isLoadingCollections, setIsLoadingCollections] = useState(false)
    useMemo(() => {
        if (resource.collections.length === 0) { return }
        Promise.all(resource.collections.map((item) =>
            CollectionService.showCollection({ identifier: item.collection_id }).then((res) => {
                if (res.success) {
                    setCollectionsNameArray((state) => {
                        return [...state, res.data.name]
                    })
                    setIsLoadingCollections(true)
                }
            })
        )).then(() => { })
    }, [resource.collections])



    return (
        <ContainerBasicInformations>
            <h2>Informações Básicas</h2>
            <BoxResume label={`Verifique se as informações estão corretas.`}>
                <tr>
                    <td>
                        <span>Nome do Recurso: {" "}
                            {resource.name ? resource.name : "Não informado."}
                        </span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Coleções: </span>
                    </td>
                </tr>
                {isLoadingCollections === false ?
                    "..."
                    :
                    collectionsNameArray.length > 0 ? collectionsNameArray.join(",") : "Não informado."
                }
                <tr>
                    <td>
                        <span>URL: {" "}{resource.url ? resource.url : "Não informado."}</span>
                    </td>
                </tr>
                <br />
                <tr>
                    <td>
                        <span>FORMATO</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Formato: {" "} {resource.format ? resource.format : "Não informado."}</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Linguagem: {" "} {resource.language ? resource.language : "Não informada."}</span>
                    </td>
                </tr>
                <br />
                <AuthorReview />
                <Thumbs/>
                <Simlarity/>
                <TargetAudiences/>
            </BoxResume>
        </ContainerBasicInformations>
    )
}

export default BasicInformations