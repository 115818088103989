import { useState } from 'react'
import { CallRegister } from '../../../../Components/CallRegister'
import { EditDatas } from '../../../../Components/EditDatas'
import { Divider } from '../../../../Components/Form/Divider'
import { Input } from '../../../../Components/Form/Input'
import { MultiSelect } from '../../../../Components/Form/MultiSelect'
import { ProfileDatas } from '../../../../Components/ProfileDatas'
import { Sitemap, Upload } from '../styles'
import { Container, Datas, EditForm, Header, ListConsult } from './styles'

export function ClassEdit(props: any) {
  const [switchPage, setSwitchPage] = useState(1)
  const [img, setImg] = useState<any>('')
  const [cepValue, setCepValue] = useState('')
  const [bairroValue, setBairroValue] = useState('')
  const [complementoValue, setComplementoValue] = useState('')
  const [cidadeValue, setCidadeValue] = useState('')
  const [ruaValue, setRuaValue] = useState('')
  const [ufValue, setUfValue] = useState('')

  function handlePreview(e: any) {
    if (!e.target.files) return
    setImg(e.target.files[0])
  }
  return (
    <Container>
      {switchPage === 1 && (
        <>
          <EditDatas
            page="Editar Turma"
            button="Lista de Turmas"
            onBack={() => props.back()}
          />
          <EditForm>
            <aside>
              <Upload>
                <label htmlFor="uploadInput">
                  {img === '' ? (
                    <p>Inserir logo</p>
                  ) : (
                    <img src={URL.createObjectURL(img)} alt="" />
                  )}
                </label>
                <input
                  type="file"
                  name=""
                  id="uploadInput"
                  onChange={(e) => handlePreview(e)}
                />
              </Upload>
            </aside>
            <main>
              <Divider>
                <Input flex="fx80" label="Razão Social" inputId="nomeid" />
                <Input flex="fx20" label="CNPJ" inputId="nomeid" type="cnpj" />
              </Divider>
              <Divider>
                <Input
                  flex="fx50"
                  label="Telefone"
                  inputId="telid"
                  type="tel"
                />
                <Input flex="fx50" label="Email" inputId="emailid" />
              </Divider>
              <span className="line"></span>
              <Divider>
                <Input flex="fx20" label="CEP" inputId="cepid" type="cep" />
                <Input flex="fx70" label="Rua/Avenida" inputId="ruaid" />
                <Input flex="fx10" label="Número" inputId="ruaid" />
              </Divider>
              <Divider>
                <Input
                  flex="fx60"
                  label="Complemento"
                  inputId="complementoid"
                />
                <Input flex="fx40" label="Bairro" inputId="bairroid" />
              </Divider>
              <Divider>
                <Input flex="fx60" label="Cidade" inputId="cidadeid" />
                <Input flex="fx40" label="Estado" inputId="estadoid" />
              </Divider>
              <span className="line"></span>
              <Divider>
                <MultiSelect
                  label="Coleções Cadastradas"
                  names="Nome das Coleções"
                />
                <MultiSelect
                  label="Pacotes Cadastrados"
                  names="Nome dos Pacotes"
                />
              </Divider>
              <Divider>
                <Input
                  label="Número de usuários docentes"
                  type="consult"
                  onClickConsult={() => {
                    setSwitchPage(2)
                  }}
                />
                <Input
                  label="Número de  Usuários Estudantes"
                  type="consult"
                  onClickConsult={() => {
                    setSwitchPage(2)
                  }}
                />
                <Input
                  label="Número de  Unidades Educacionais"
                  type="consult"
                  onClickConsult={() => {
                    setSwitchPage(2)
                  }}
                />
              </Divider>
            </main>
          </EditForm>
        </>
      )}
      {switchPage === 2 && (
        <>
          <EditDatas
            page="Lista de usuários docentes"
            button="Voltar"
            onBack={() => setSwitchPage(1)}
          />
          <ListConsult>
            <p>1 – Charlotte Gomes </p>
            <p>2 – Elizabeth Tayllor</p>
            <p>3 – Diana Reis</p>
            <p>4 – Mary Barbosa</p>
            <p>5 – Grace Costa</p>
            <p>6 – Alice Vieira</p>
            <p>7 – Alexia Santos</p>
            <p>8 – Sarah Silva</p>
            <p>9 – Anne Bertollo</p>
            <p>10 – Margaret Mendes</p>
            <p>11 – Alexandra Oliveira</p>
            <p>12 – Victoria Pádua</p>
            <p>13 – Louisa Vetton</p>
            <p>14 – Adelaide Mineratto</p>
            <p>15 – Helena Souza</p>
            <p>16 – Augusta Gomes</p>
            <p>17 – Louise Mernon</p>
            <p>18 – Carolina Dias</p>
            <p>19 – Alberta Guerreira</p>
            <p>20 – Beatrice Nascimento</p>
            <p>21 – Amelia Batista</p>
            <p>22 – Fedore Nickita</p>
            <p>23 – Anastasia Klint</p>
            <p>24 – Arabella Stark</p>
            <p>25 – Blanche Victória</p>
          </ListConsult>
        </>
      )}
    </Container>
  )
}
