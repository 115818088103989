import { ArrowDownCircle, ArrowUpCircle, FileText } from "lucide-react";
import { useContext, useState } from "react";
import CheckBoxBNCC from "../../../../../../Components/CheckBoxBNCC";
import { Divider } from "../../../../../../Components/Form/Divider";
import { Input } from "../../../../../../Components/Form/Input";
import InputExpirience from "../../../../../../Components/InputExperience";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";
import { useListBNCC } from "../../../hooks/useListBNCC";
import { ContainerSelect, DivFlex, Line, Row } from "../../../styles";
import { Column, DivColumnFlexStart, Title } from "../styles";
import { SearchSkills } from "../../components/SearchSkils";
import { IBNCCYear } from "../../../../../../types/bncc";
import { formatPeriod } from "../../../../../../Utils/Functions";
import { Div } from "../../style";
import SelectedFields from "../../components/SelectedFields";
import { useListEi } from "../../../hooks/bncc/useListEi";
import { SearchSkillsEi } from "../../components/SearchSkillsEi";
import { learningRightsBNCC } from "../../../../../../Utils/Const";

export default function FormInfantil() {
  const { resource, addFieldBNCCEiResource, removeFieldBNCCEiResource } =
    useContext(ResourceContext);
  const [isVisibleSkills, setIsVisibleSkill] = useState(false);
  // const { data, isLoading, isSuccess } = useListBNCC();
  const {data, isLoading, isSuccess} = useListEi();
  console.log(data)
  const [itensSelected, setItensSelected] = useState<string[]>([]);
  

  function handleYear(year: IBNCCYear, select: boolean) {
    if (!select) {
      addFieldBNCCEiResource(year.year_id, "years");
    } else {
      removeFieldBNCCEiResource(year.year_id, "years");
    }
  }

  function handleFieldExperience(e: string, checked: boolean) {
    if (!checked) {
      addFieldBNCCEiResource(e, "fields_experience");
    } else {
      removeFieldBNCCEiResource(e, "fields_experience");
    }
  }

  function handleLearningRights(e: string, checked: boolean) {
    if (!checked) {
      addFieldBNCCEiResource(e, "learning_rights");
    } else {
      removeFieldBNCCEiResource(e, "learning_rights");
    }
  }

  function handleVisibilitySkill() {
    setIsVisibleSkill((state) => !state);
  }

  function saveItensSelected(e: string) {
    setItensSelected((state) => [...state, e]);
  }
  return (
    <div>
      {isLoading ? (
        <h1>Carregando...</h1>
      ) : isSuccess ? (
        <>
          <Row>
            {data.phases.map((phase: any) => (
              <div className="item" key={phase.name}>
                <Column>
                  <span className="title">FASE: {phase.name}</span>
                  <span className="sub-title">ANO:</span>
                  <ul className="row gap mt">
                    {phase.years.map((year: any) => {
                      const select = resource.bncc.ei.years.some(
                        (yearSelected) => yearSelected === year.year_id
                      );
                      return (
                        <li key={year.year}>
                          <input
                            type="checkbox"
                            checked={select}
                            onChange={() => handleYear(year, select)}
                          />
                          <div className="flex-column">
                            <span>{year.year}</span>
                            <span>
                              {formatPeriod(year.age_start, year.age_end)}
                            </span>
                          </div>

                        </li>
                      );
                    })}
                  </ul>
                </Column>
              </div>
            ))}
          </Row>
          <Line $margin="0.5rem 0" />
          <Div>
            <Title>Direitos de aprendizagem</Title>

            <ArrowDownCircle
              color="white"
              fill="#fe8f2e"
              size={25}
              className={`icon-arrow ${isVisibleSkills ? "up" : "down"}`}
              onClick={handleVisibilitySkill}
            />
            {isVisibleSkills === false && (
              <SelectedFields
                basicCompetences={resource.bncc.ei.learning_rights}
              />
            )}
          </Div>
          {isVisibleSkills ? (
            <ContainerSelect>
              {learningRightsBNCC.map((item: any) => {
                const selected = resource.bncc.ei.learning_rights.includes(
                  item.number
                );
                return (

                  <CheckBoxBNCC
                    checked={selected}
                    onChange={() => {
                      handleLearningRights(item.number, selected);
                    }}
                    number={item.number}
                    text={item.text}
                    title={item.title}
                  />
                )
              }
              )}
            </ContainerSelect>
          ) : null}
          <Line $margin="0.5rem 0" />
          <Divider>
            <DivColumnFlexStart $width="40%">
              <Title>CAMPOS DE EXPERIÊNCIA</Title>
              {data.fields_experience.map((item: any, key: number) => {
                const color = key % 2 === 0 ? "#379d0e" : "#72b956";
                const selected = resource.bncc.ei.fields_experience.some(
                  (obj) => obj === item
                );
                return (
                  <InputExpirience
                    checked={selected}
                    onChange={() => handleFieldExperience(item, selected)}
                    color={color}
                    text={item}
                    key={key}
                  />
                );
              })}
            </DivColumnFlexStart>
            <DivColumnFlexStart $width="60%">
              <SearchSkillsEi type="ei" />
            </DivColumnFlexStart>
          </Divider>
        </>
      ) : (
        <h1>Não foi possível buscar os dados</h1>
      )}
    </div>
  );
}
