import React, { useContext, useState } from 'react'
import { ContextEditTeacher } from '../../Contexts/ContextEditTeacher'
import { Controllers } from '../../../../../../Components/Form/Controllers'
import { BoxResume } from '../../../../../../Components/Form/BoxResume'
import FuncionarioService from '../../../../../../Services/Funcionario.service'
import ModalNew from '../../../../../../Components/Modals/ModalNew/ModalNew'
import { useNavigate } from 'react-router-dom'
import { formatarFone } from '../../../../../../Utils/Functions'

const Review = () => {

  const { teacher, handleStep } = useContext(ContextEditTeacher)
  const unit = JSON.parse(sessionStorage.getItem("locationData")!);
  const navigate = useNavigate()
  const [modalSave, setModalSave] = useState(false)
  const phoneFormated = formatarFone(teacher.phone)
  

  const data = {
    ...teacher,
    role_id: "teacher-employee"
  }

  function save(){
    FuncionarioService.postTeacher(data, unit.identifier).then((res)=>{
      if(res?.success){
          setModalSave(true)
      }
    })
  }

  return (
    <>
      <BoxResume label="Verifique se seus dados estão corretos.">
        <tr>
          <td>
            <span>Nome: {teacher.name}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Email: {teacher.email}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>CPF: {teacher.cpf}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Data de nascimento: {teacher.birth_date}</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Telefone: {phoneFormated}</span>
          </td>
        </tr>
        
      </BoxResume>
      <br />
      <Controllers
        enableNext
        save
        enablePrev
        functionCancel={() => window.location.reload()}
        functionSave={() => save()}
        functionPrev={() => handleStep(1)}
      />
          <ModalNew
            type="success"
            text="Edição salva com sucesso"
            isOpen={modalSave}
            onRequestClose={() => navigate('/unidade/usuario/list')}
            onConfirm={() => {
             navigate('/unidade/usuario/list');
            }}
          />
    </>
  )
}

export default Review