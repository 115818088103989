import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { GlobalHover } from './Global/hover'
import { GlobalStyles } from './Global/styles'
import { DefaultRoutes } from './Routes'
import { ResourceContextProvider } from './Contexts/Form/ContextResource'
import { ContextResourceFormPOPProvider } from './Contexts/FormPOP/ContextResourceFormPOP'

const queryClient = new QueryClient()

function App() {

  return (
    <QueryClientProvider client={queryClient}>
      <GlobalStyles />
      <GlobalHover />
      <ResourceContextProvider>
        <ContextResourceFormPOPProvider>
          <DefaultRoutes />
        </ContextResourceFormPOPProvider>
      </ResourceContextProvider>


      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
