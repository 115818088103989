import { ClipboardType } from "lucide-react";
import { Container, Item } from "./styles";

export function Avisos(){
    return(
        <Container>
            <div className="head">
                <h3><ClipboardType /> Mural de Avisos</h3>
            </div>
            <div className="body">
                <Item>
                    <h4>Manutenção do portal</h4>
                    <p>Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção.</p>
                    <div className="foot">
                        <p>Márcio Pessoa</p>
                        <span>
                        15/03/2023 às 16:00
                        </span>
                    </div>
                </Item>
                <Item>
                    <h4>Manutenção do portal</h4>
                    <p>Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção.</p>
                    <div className="foot">
                        <p>Márcio Pessoa</p>
                        <span>
                        15/03/2023 às 16:00
                        </span>
                    </div>
                </Item>
                <Item>
                    <h4>Manutenção do portal</h4>
                    <p>Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção.</p>
                    <div className="foot">
                        <p>Márcio Pessoa</p>
                        <span>
                        15/03/2023 às 16:00
                        </span>
                    </div>
                </Item>
                <Item>
                    <h4>Manutenção do portal</h4>
                    <p>Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção.</p>
                    <div className="foot">
                        <p>Márcio Pessoa</p>
                        <span>
                        15/03/2023 às 16:00
                        </span>
                    </div>
                </Item>
                <Item>
                    <h4>Manutenção do portal</h4>
                    <p>Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção Comunicamos a todos que o portal entrará em manutenção.</p>
                    <div className="foot">
                        <p>Márcio Pessoa</p>
                        <span>
                        15/03/2023 às 16:00
                        </span>
                    </div>
                </Item>
            </div>
        </Container>
    )
}