import { FC, ReactNode } from 'react';
import { Spinner } from './styles';

interface LoadingScreenProps {
  isLoading: boolean;
  children?: ReactNode;
}

export const LoadingScreen: FC<LoadingScreenProps> = ({ isLoading, children }) => {
  return isLoading ? <Spinner /> : <>{children}</>;
};
