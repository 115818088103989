import { Dispatch, SetStateAction } from "react";
import { EnumEmployees, EnumEmployeesName } from "./Types";
import { DateTime } from "luxon";

// Função para converter a string em data
export const stringParaData = (dataStr: string): string => {
  // Utiliza a biblioteca luxon para fazer a conversão
  const data = DateTime.fromFormat(dataStr, 'dd/MM/yyyy');
  return data.toFormat('dd/MM/yyyy'); // Retorna a data no formato desejado
};




export function nextStepForm(setDefault: Dispatch<SetStateAction<number>>, number: number){
  setDefault(number);
}


export function createDate(dataString: string) {
  // Crie um novo objeto Date passando a string da data
  let data = new Date(dataString);
  
  // Verifique se a data é válida
  if (isNaN(data.getTime())) {
    return "Data inválida";
  } else {
    return data;
  }
}

//recebe um cpf como uma string para formatar a fim de visualização
export function formatarCpf(inputCpf: string) {
  if (inputCpf.length !== 11) {
    return "Formato de cpf inválido.";
  }
  const one = inputCpf.slice(0, 3);
  const two = inputCpf.slice(3, 6);
  const tree = inputCpf.slice(6, 9);
  const four = inputCpf.slice(9);

  return `${one}.${two}.${tree}.${four}`;
}

export function formDate2(date: any){
  console.log(date)

  const day = date.substring(0,2)
  const month = date.substring(3,5)
  const year = date.substring(6,11)


  return `${day}-${month}-${year}`;
}

export function formDate(date: any){

  const day = date.substring(8,10)
  const month = date.substring(5,7)
  const year = date.substring(0,4)


  return `${day}/${month}/${year}`;
}

export function formatedDateS(date: string | any) {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}

export function formatarFone(inputFone: string) {
  if (inputFone.length !== 11) {
    return "Formato de telefone inválido.";
  }

  const ddd = inputFone.slice(0, 2);
  const one = inputFone.slice(2, 7);
  const two = inputFone.slice(7);

  return `(${ddd}) ${one}-${two}`;
}

export function formatarCPF(inputCpf: string){
  if (inputCpf.length !== 11) {
    return "Formato de CPF inválido.";
  }

  const one = inputCpf.slice(0,2);
  const two = inputCpf.slice(3,5);
  const tree = inputCpf.slice(6,8);
  const four = inputCpf.slice(9,10);

  return `${one}.${two}.${tree}.${four}`


  
}

export function getCurrentYear() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return currentYear;
}

export function isCreatedAcademicNextYear() {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth(); // Obter o mês atual (0 a 11, onde janeiro é 0 e dezembro é 11)

  // Verificar se o mês atual está é novembro para cima
  return currentMonth >= 10;
}
//Recebe uma data no formato dd/MM/yyyy e retorna um Date
export function transformStringDateInDate(dateString: string) {
  const dates = dateString.split("/");
  return new Date(
    parseInt(dates[2]),
    parseInt(dates[1]) - 1,
    parseInt(dates[0])
  );
}

//Transforma uma string formatada em um número
export function removeCaracteresInString(word: string): string {
  return word.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
}
export function removeBarrasDaData(dateString: string) {
  // Divida a string em partes usando a barra como separador
  const parts = dateString.split("/");

  // Use o método join para unir as partes sem barras
  const dateWithoutSlashes = parts.join("");

  return dateWithoutSlashes;
}
export function formatedDate(date: Date): string {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();
  return `${day}/${month}/${year}`;
}
export function transformUrlImage(url: string): string {
  return `https://portal.arkadigital.dev.br/${url}`;
}

export function validateNameEmployee(name: string): boolean {
  return name.length >= 10;
}
export function validateEmail(email: string): boolean {
  const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return regex.test(email);
}

//Validar data no formato ddMMyyyy
export function validarData(dataStr: string) {
  const regex = /^(\d{2})(\d{2})(\d{4})$/;

  const match = regex.exec(dataStr);

  if (!match) {
    return false;
  }

  const [, dia, mes, ano] = match;

  const data = new Date(Date.UTC(Number(ano), Number(mes) - 1, Number(dia)));

  return (
    data.getUTCDate() === Number(dia) &&
    data.getUTCMonth() === Number(mes) - 1 &&
    data.getUTCFullYear() === Number(ano)
  );
}
//Função para validar cpf
export function validarCPF(cpf: string) {
  console.log(cpf);
  // Remove caracteres não numéricos
  cpf = cpf.replace(/[^\d]/g, "");

  // Verifica se o CPF tem 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais, o que não é válido
  const todosDigitosIguais = /^\d{11}(|-|\/|\.)?$/g.test(cpf);
  if (todosDigitosIguais) {
    return false;
  }

  // Calcula o primeiro dígito verificador
  let soma = 0;
  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let resto = 11 - (soma % 11);
  const digitoVerificador1 = resto === 10 || resto === 11 ? 0 : resto;

  // Verifica o primeiro dígito verificador
  if (digitoVerificador1 !== parseInt(cpf.charAt(9))) {
    return false;
  }

  // Calcula o segundo dígito verificador
  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }
  resto = 11 - (soma % 11);
  const digitoVerificador2 = resto === 10 || resto === 11 ? 0 : resto;

  // Verifica o segundo dígito verificador
  if (digitoVerificador2 !== parseInt(cpf.charAt(10))) {
    return false;
  }

  // CPF válido
  return true;
}

export function getTitleEmployee(type: EnumEmployees) {
  switch (type) {
    case EnumEmployees.GUARDIAN:
      return "Responsável";
    case EnumEmployees.DIRECTOR:
      return "Direção";
    case EnumEmployees.COORD:
      return "Coordenação";
    case EnumEmployees.TEACHER:
      return "Professor";
    case EnumEmployees.STUDENT:
      return "Aluno";
    case EnumEmployees.ADMIN_EMPLOYEE:
      return "Administrativo";
    case EnumEmployees.MANAGER_EMPLOYEE:
      return "Gestor de Unidade";
    case EnumEmployees.MANAGER:
      return "Gestor de Contrato";

    default:
      return "Adicionar Descrição";
  }
}
export const getNameEmployee = (type: EnumEmployees) => {
  switch (type) {
    case EnumEmployees.MANAGER:
      return "Gestor de Contrato";
    case EnumEmployees.MANAGER_EMPLOYEE:
      return "Gestor de Unidade";
    case EnumEmployees.DIRECTOR:
      return "Diretor";
    case EnumEmployees.COORD:
      return "Coordenador";
    case EnumEmployees.ADMIN_EMPLOYEE:
      return "Administrativo";
    case EnumEmployees.TEACHER:
      return "Professor";
    case EnumEmployees.STUDENT:
      return "Aluno";
    case EnumEmployees.GUARDIAN:
      return "Responsável";
    case EnumEmployees.EMPLOYEE:
      return "Funcionário";
  }
};

export const getNameEmployeeByType = (type: EnumEmployees) => {
  const typeToNameMap = {
    [EnumEmployees.MANAGER]: EnumEmployeesName.MANAGER,
    [EnumEmployees.MANAGER_EMPLOYEE]: EnumEmployeesName.MANAGER_EMPLOYEE,
    [EnumEmployees.DIRECTOR]: EnumEmployeesName.DIRECTOR,
    [EnumEmployees.COORD]: EnumEmployeesName.COORD,
    [EnumEmployees.ADMIN_EMPLOYEE]: EnumEmployeesName.ADMIN_EMPLOYEE,
    [EnumEmployees.TEACHER]: EnumEmployeesName.TEACHER,
    [EnumEmployees.STUDENT]: "",
    [EnumEmployees.GUARDIAN]: EnumEmployeesName.GUARDIAN,
    [EnumEmployees.EMPLOYEE]: EnumEmployees.EMPLOYEE,
  };

  return typeToNameMap[type] || "Tipo de funcionário desconhecido";
};

function formatMonthForYearAndMonths(meses: number) {
  if (meses < 0) {
    return "Entrada inválida";
  }
  if (meses === 0) {
    return "0";
  }

  const anos = Math.floor(meses / 12);
  const mesesRestantes = meses % 12;

  const anosTexto = anos > 0 ? `${anos} ano${anos === 1 ? "" : "s"}` : "";

  if (anos > 0 && mesesRestantes > 0) {
    const mesesTexto =
      mesesRestantes === 1
        ? `${mesesRestantes} mês`
        : `${mesesRestantes} meses`;
    return `${anosTexto} e ${mesesTexto}`;
  } else {
    return anosTexto;
  }
}

export const formatPeriod = (monthStart: number, monthEnd: number) => {
  return `${formatMonthForYearAndMonths(
    monthStart
  )} - ${formatMonthForYearAndMonths(monthEnd)}`;
};

export function minutesToMilliseconds(minutes: number) {
  return minutes * 60 * 1000;
}
