import { useEffect, useState } from "react";
import { Input } from "../../../Components/Form/Input";
import { LeftMenu } from "../../../Components/LeftMenu";
import { Banner, Container, ListReport, Main, Sitemap } from "./styles";
import { ExpandedClient } from "./Expanded";
import ClienteService from "../../../Services/Cliente.service";
import { api } from "../../../Services/api";
import ManagerService from "../../../Services/Manager.service";
import { OutputComponent } from "./Output";

export interface ClientData {
    socialName: string;
    phone: string;
    logo: string;
    identifier: string;
    email: string;
    cnpj: string;
    address: {
        address: string;
        city: string;
        complement: string;
        neighborhood: string;
        number: string;
        state: string;
        zipCode: string;
    };

    manager: [{
        identifier: string;
        user: [
            {
                name: string;
            }
        ]
    }
    ]
    plan: {
        collections: [
            {
                color: string;
                description: string;
                identifier: string;
                logo: string;
                name: string;
            }
        ];
        packages: [
            {
                color: string;
                description: string;
                identifier: string;
                logo: string;
                name: string;
            }
        ];
        maxEducationInstitutions: number;
        maxEmployees: number;
        maxStudents: number;
        maxGuardiansPerStudent: number;
    };
}

export function ReportClient() {

    const [clients, setClients] = useState<[]>([]);
    const [datasProfile, setDatasProfile] = useState<ClientData[]>([]);
    const [clientSelected, setClientSelected] = useState<any>([]);
    const [switchScreen, setSwitchScreen] = useState(1);

    // pegar os clientes
    useEffect(() => {
        ClienteService.get({ path: `/admin/clients` })
            .then((res) => {
                if (res?.status === 200) {
                    setClients(res.data.data);
                } else {
                    throw new Error(
                        "Erro ao carregar dados da página. Tente novamente mais tarde."
                    );
                }
            })
            .catch((err) => {
                alert(err.message);
                window.location.reload();
            });
    }, []);

    useEffect(() => {
        if (clients.length > 0) {
            Promise.all(clients.map((client: any) =>
                api.get(`/admin/clients/${client.identifier}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                })
                    .then(response => response.data.data)
                    .catch((err) => {
                        console.error("Houve um erro ao buscar os dados do cliente", err);
                        return null;
                    })
            ))
                .then((response: any) => {
                    const filteredResponse = response.filter((data: any) => data !== null);
                    setDatasProfile(filteredResponse);

                    Promise.all(filteredResponse.map((clientData: any) =>
                        ManagerService.getManager(clientData.identifier)
                            .then((managerResponse) => {
                                if (managerResponse.success) {
                                    return managerResponse.data;
                                } else {
                                    throw new Error(
                                        "Erro ao carregar dados do gerente. Tente novamente mais tarde."
                                    );
                                }
                            })
                            .catch((err) => {
                                console.error("Houve um erro ao buscar os dados do gerente", err);
                                return null;
                            })
                    ))
                        .then((managersData: any) => {
                            const updatedDatasProfile = filteredResponse.map((clientData: any, index: number) => {
                                return {
                                    ...clientData,
                                    manager: managersData[index]
                                };
                            });
                            setDatasProfile(updatedDatasProfile);
                        })
                        .catch((err) => {
                            alert(err.message);
                            window.location.reload();
                        });
                })
                .catch((err) => {
                    alert(err.message);
                    window.location.reload();
                });
        }
    }, [clients]);

    // Calcular os usuarios
    function calculateUsers(client: any) {
        const totalManagers = client.manager ? client.manager.length : 0;
        const totalEmployees = client.plan ? client.plan.maxEmployees : 0;
        const totalStudents = client.plan ? client.plan.maxStudents : 0;

        return totalManagers + totalEmployees + totalStudents;
    }

    // Filtrar os usuários
    const [clientsFiltereds, setClientsFiltereds] = useState<any[]>([]);
    const [inputSearchView, setInputSearchView] = useState("")
    useEffect(() => {

        let filteredClients = datasProfile.filter((item: any) => item.socialName.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
        setClientsFiltereds(filteredClients)

    }, [datasProfile, inputSearchView])

    // Chamar o outro componente
    const [funcExpandedClient, setFuncExpandedItemClient] = useState(null);
    const handleClientClick = (client: any) => {
        setFuncExpandedItemClient((prevExpandedItem) =>
            prevExpandedItem === client ? null : client);

    }

    // selecionar um cliente
    const toggleSelection = (cliente: any) => {
        setFuncExpandedItemClient(null);
        setClientSelected((prevSelected: any) => {
            if (prevSelected.includes(cliente)) {
                return prevSelected.filter(
                    (selectedClient: any) => selectedClient !== cliente
                );
            } else {
                return [...prevSelected, cliente];
            }
        });
    }

    return (
        <Container>
            <LeftMenu />
            <div className="box">
                <Main>
                    <Sitemap>
                        <p>Relatório</p> <span>&bull;</span> <p>Cliente</p>
                    </Sitemap>

                    {switchScreen === 1 && (
                        <>
                            <Banner>
                                <div className="box-banner">
                                    <p>Relatórios de Cliente</p>
                                </div>
                            </Banner>

                            <div className="div-search">
                                <Input
                                    flex="fx40"
                                    type="search"
                                    placeHolderTeacher="Pesquise por um cliente"
                                    color="#039bc9"
                                    value={inputSearchView}
                                    onChange={setInputSearchView}
                                />
                            </div>

                            <ListReport>
                                <table>
                                    <thead>
                                        <tr>
                                            <td className='header-cell'>Nome</td>
                                            <td className="wd20" colSpan={2}>Gestor de Contrato</td>
                                            <td className="wd10">Total de Unidades Escolares</td>
                                            <td className="wd10">Funcionários</td>
                                            <td className="wd10">Alunos</td>
                                            <td className="wd10">Total de Usuários</td>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {clientsFiltereds.sort((a, b) => a.socialName.localeCompare(b.socialName))
                                            .map((client) => {
                                                return (
                                                    <>
                                                        <tr key={client.identifier}
                                                            onClick={() => handleClientClick(client.identifier)}
                                                            className={funcExpandedClient === client.identifier || clientSelected.includes(client) ? "expanded" : ""}
                                                        >
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    name="client"
                                                                    checked={clientSelected.includes(client)}
                                                                    onChange={() => toggleSelection(client)}
                                                                />
                                                                {client.socialName}
                                                            </td>

                                                            <td colSpan={2}>
                                                                {!client.manager || client.manager.length === 0 ? (
                                                                    <div>Sem gestor de contrato</div>
                                                                ) : (
                                                                    client.manager.map((manager: any, index: number) => (
                                                                        <div key={index}>{manager.user.name}</div>
                                                                    ))
                                                                )}
                                                            </td>

                                                            <td>{client?.plan.maxEducationInstitutions}</td>
                                                            <td>{client?.plan.maxEmployees}</td>
                                                            <td>{client?.plan.maxStudents}</td>
                                                            <td>{calculateUsers(client)}</td>
                                                        </tr>

                                                        {funcExpandedClient === client.identifier && (
                                                            <td colSpan={7}>
                                                                <ExpandedClient
                                                                    dataClient={client}
                                                                />
                                                            </td>
                                                        )}

                                                    </>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </ListReport>

                            <OutputComponent
                                clientsFiltereds={clientsFiltereds}
                                setClientSelected={setClientSelected}
                                clientSelected={clientSelected}
                            />
                        </>
                    )}

                </Main>
            </div>
        </Container>
    )
}