import { ChevronLeft, ChevronRight } from 'lucide-react'
import { Container, Navigation } from './styles'

interface LabelType {
  url: String
  label: String
  active: Boolean
}

export function ListDefault(props: any) {

  return (
    <Container>
      <table>{props.children}</table>
      <Navigation>
        <div> 
          <p>{props.to} de {props.total} itens</p>
        </div>
        <div>
          {props.pages.map((page: LabelType, index: any) => {
            if (page.label === '&laquo; Anterior') {
              return(
              <button key={index} onClick={() => props.changeGet(page.url)}> <ChevronLeft size={16} /> </button>
              )
            } else if (page.label === 'Próximo &raquo;') {
              return(
              <button key={index} onClick={() => props.changeGet(page.url)}><ChevronRight size={16} /> </button>
              )
            } else {
              return (
                <button
                  onClick={() => props.changeGet(page.url)}
                  className={page.active ? 'active' : ''}
                  key={index}
                >
                  {page.label}
                </button>
              )
            }
          })}
        </div>
        <div>
          <p>10 de 350 itens</p>
        </div>
      </Navigation>
    </Container>
  )
}
