import styled from 'styled-components';
export const Container = styled.div`
display: flex;
width: 100%;
min-height: 60px;
margin-top: 10px;
`;

export const Step = styled.div`
flex: 1;
display: flex;
align-items: center;
justify-content: center;
height: 50px;
background-color: #838383;
border-radius: 10px;
text-transform: uppercase;
color: #fff;
&.active{
    background-color: #027294;
}
`;