import { type } from "os";
import { Forbidden, ServiceError, Success, Unauthorized, UnprocessableContent } from "../Utils/Responses";
import { CategoryEdit, Tag, tagEdit } from "../Utils/Types";
import { api } from "./api";


type TagListCategoryResponse =
    Success | Unauthorized | Forbidden

type TagShowCategoryResponse =
    Success | Unauthorized | Forbidden

type TagShowProps = {
    id: string
}

type CategoryShowProps = {
    id: string
}

type TagRegistrationResponse =
    Success | Unauthorized | Forbidden;

type TagRegistrationProps = {
    tag: Tag;
}

type TagSugestionProps = {
    name: string,
    justification?: string,
    tag_category_id: string | undefined,
}

type CategoryProps = {
    name: string,
    description?: string,
}

type TagApproveProps = {
    id: string,
    approved: boolean,
}

type TagSugestionResponse =
    Success | Unauthorized | Forbidden;

type TagSugestionListResponse =
    Success | Unauthorized | Forbidden;

type TagApproveResponse =
    Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;


type ShowDataTagProps = {
    tagCategory: string,
    identifier: string
}

type updateTagProps = {
    name: string
    tagCategory: string,
    identifier: string,

}

type ShowDataTagResponse =
    Success | Unauthorized | Forbidden | ServiceError;

type ShowCategorytagResponse = Success | Unauthorized | Forbidden | ServiceError;

type updateCategoryProps = {
    data: CategoryEdit,
    idCategory: string
}

type deletecategoryProps = {
    identifier: string
}


class TagService {

    static async deleteTag(input: ShowDataTagProps  ): Promise<ShowCategorytagResponse> {
        const {tagCategory, identifier} = input

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.delete(
                `/admin/tag-categories/${tagCategory}/tags/${identifier}`, config
            )

            return response.data
        } catch (error: any) {
            throw new Error("Can not delete tag.")
        }
    }

    static async deleteCategory(input: deletecategoryProps): Promise<ShowCategorytagResponse> {
        const { identifier } = input

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.delete(
                `/admin/tag-categories/${identifier}`, config
            )
            return response.data

        } catch (error: any) {
            throw new Error("Can not delete category.")
        }
    }

    static async updateCategory(input: updateCategoryProps): Promise<ShowCategorytagResponse> {
        const { data, idCategory } = input

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }

            const response = await api.put(
                `/admin/tag-categories/${idCategory}`, data, config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not edit category.")
        }
    }

    static async showCategorytag(input: CategoryShowProps): Promise<ShowCategorytagResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }
        const { id } = input

        try {
            const response = await api.get(
                `/admin/tag-categories/${id}`, config
            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not show category tag.")
        }

    }

    static async showSugestionTag(input: TagShowProps): Promise<TagShowCategoryResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        const { id } = input

        try {
            const response = await api.get(
                `/admin/tag-suggestions/${id}`,
                config
            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }
        } catch (error: any) {
            throw new Error("Can not show sugestion tag.")
        }
    }

    static async updateTag(input: updateTagProps): Promise<ShowDataTagResponse> {

        const { tagCategory, identifier } = input

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
            const response = await api.put(
                `/admin/tag-categories/${tagCategory}/tags/${identifier}`, input,
                config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not edit tag.")
        }
    }

    static async showDataTag({ identifier, tagCategory }: ShowDataTagProps): Promise<ShowDataTagResponse> {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            }
            const response = await api.get(
                `/admin/tag-categories/${tagCategory}/tags/${identifier}`,
                config
            )
            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }
        } catch (error: any) {
            throw new Error("Can not show tag.")
        }
    }

    static async listTagsSugestions(): Promise<TagSugestionListResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        try {
            const response = await api.get(
                `/admin/tag-suggestions`,
                config
            )
            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }
        } catch (error: any) {
            throw new Error("Can not list tags sugestions.")
        }
    }

    static async tagSugestion(input: TagSugestionProps): Promise<TagSugestionResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        try {
            const response = await api.post(
                `/admin/tag-suggestions`,
                input,
                config
            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not make a sugestion.")
        }

    }

    static async tagRegistration(input: TagRegistrationProps): Promise<TagRegistrationResponse> {

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        const { tag } = input;
        const idCategory = tag.idCategory;

        try {
            const response = await api.post(
                `/admin/tag-categories/${idCategory}/tags`, tag, config

            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not create tag.")
        }


    }

    static async showCategory(input: TagShowProps): Promise<TagShowCategoryResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        const { id } = input

        try {
            const response = await api.get(
                `/admin/tag-categories/${id}/tags`,
                config
            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }
        } catch (error: any) {
            throw new Error("Can not show Categorie.")
        }
    }

    static async listCategory(): Promise<TagListCategoryResponse> {

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        try {

            const response = await api.get(
                `/admin/tag-categories`,
                config
            ).catch((error) => {
                return error.response
            })

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        } catch (error: any) {
            throw new Error("Can not list Categories.")
        }
    }

    static async addCategory(input: CategoryProps): Promise<TagListCategoryResponse> {

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        }

        try {

            const response = await api.post(
                `/admin/tag-categories`,
                input,
                config
            ).catch((error) => {
                return error.response
            })

            return response.data

        } catch (error: any) {
            throw new Error("Can not list Categories.")
        }
    }


    static async approveTag(input: TagApproveProps): Promise<TagApproveResponse> {
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                'Content-Type': 'application/json',
            },
        };

        try {

            const response = await api.post(
                `/admin/tag-suggestions/${input.id}/approve`,
                { approved: input.approved },
                config
            ).catch((error) => {
                return error.response
            })

            console.log(response)

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                case 403:
                    return { ...response.data, success: false, message: "Forbidden" } as Forbidden;
                case 422:
                    return { ...response.data, success: false, message: "Unprocessable Content" } as UnprocessableContent;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }

        } catch (error: any) {
            throw new Error("Can not approve Tag.")
        }
    }

}

export default TagService;