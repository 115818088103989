import { Edit, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallRegister } from "../../../Components/CallRegister";
import { ListDefault } from "../../../Components/ListDefault";
import { SearchTable } from "../../../Components/SearchTable";
import { SuperBox } from "../../../Components/SuperBox";
import { api } from "../../../Services/api";
import { Abas, Container, Form, Form22, Main, Sitemap, Upload } from "./styles";
import { ClassProfile } from "./Profile";
import { ClassEdit } from "./Edit";
import { useCheckTime } from "../../../Components/LoginFunctions/Refresh";
import { useLocation } from "react-router-dom";
import TurmaService from "../../../Services/Turma.service";
import { Class, TypeClass } from "../../../Utils/Types";
import { Input } from "../../../Components/Form/Input";
import { ListViewClass } from "../../../types/ClassTypes/typeClass";



function codCategory(category: string | null) {
  if (category === "EAD") {
    return 1;
  } else if (category === "EE") {
    return 2;
  } else if (category === "EJA") {
    return 3;
  }
  return 4;
}
// Função para ordenar turmas por nome
function sortByTurmaName(a: TypeClass, b: TypeClass) {
  return a.name.localeCompare(b.name);
}

// Função para ordenar turmas por nome
function sortByCategory(a: TypeClass, b: TypeClass) {
  const typeA = codCategory(a.modality[0])
  const typeB = codCategory(b.modality[0])
  if (typeA < typeB) {
    return -1;
  }
  if (typeA > typeB) {
    return 1;
  }
  return 0;
}

export function ListClass() {
  useCheckTime();

  const location = useLocation();
  const [switchPage, setSwitchPage] = useState(1);
  const [classrooms, setClassrooms] = useState<TypeClass[]>();
  const [classes, setClasses] = useState<ListViewClass[]>([])
  const [isLoading, setIsLoading] = useState(true);

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");
  const [selectClass, setSelectClass] = useState<ListViewClass | null>(null);
  const idState = JSON.parse(sessionStorage.getItem("locationData")!);

  const role = JSON.parse(localStorage.getItem("roles")!);

  const [switchPageList, setSwitchPageList] = useState(
    "/client/" + idState.identifier + "/classrooms?limit=100&page=1"
  );
  const pathBed = idState.name + " " + String.fromCharCode(8226) + " " + role;
  useEffect(() => {
    // async function fetchClass() {
    //   TurmaService.get({ path: switchPageList })
    //     .then((response) => {
    //       if (response?.success) {
    //         setClassrooms(
    //           response.data!!.sort(sortByTurmaName).sort(sortByCategory)
    //         );
    //       } else {
    //         throw new Error(response?.message);
    //       }
    //     })
    //     .catch((e) => { });
    // }

    // fetchClass();

  TurmaService.list({idState: idState.identifier}).then((res)=>{
    if(res.success){
      console.log(res.data)
      setClasses(res.data)
    }
  })
    
  }, []);


  useEffect(() => {

    if (selectClass) {
      setSwitchPage(2);
    }

  }, [selectClass])


  const [classesFiltered, setClassesFiltered] = useState<ListViewClass[]>([])
  const [inputSearchView, setInputSearchView] = useState("")

  useEffect(() => {

    let filteredClassroons = classes?.filter((item: any) => item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setClassesFiltered(filteredClassroons)

  }, [inputSearchView, classes])

  const navigate = useNavigate();
  const onBack = () => navigate(-1);


  const onEdit = (classroom: any) =>
    navigate("/turma/edit", { state: classroom });
  return (
    <SuperBox switch={true} page={3} path={pathBed}>
      {switchPage === 1 && (
        <Container>
          <div className="box">
            <Main>
              <CallRegister
                onBack={onBack}
                link="/turma/registration"
                page="Turmas"
              />
              <ListDefault
                pages={pages}
                changeGet={(v: any) => setSwitchPageList(v)}
                to={metaTo}
                total={metaTotal}
              >
                <thead>
                  <tr>
                    <td colSpan={7}>
                      <Input
                        flex="fx30"
                        type="search"
                        placeHolderTeacher="Pesquise por uma turma"
                        color="#039bc9"
                        value={inputSearchView}
                        onChange={setInputSearchView}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="wd10">Ano Letivo</td>
                    <td className="wd15">Modalidade</td>
                    <td className="wd15">Etapa</td>
                    <td className="wd15">Fase</td>
                    <td className="wd15">Ano/Faixa Etária</td>
                    <td className="wd15">Nome da turma</td>
                    <td className="wd10">Ação</td>
                  </tr>
                </thead>
                <tbody>
                  {classesFiltered?.map((classroom) => (
                    <tr>
                      <td>
                        {classroom.academic_year
                          ? classroom.academic_year
                          : "Não informado"}
                      </td>
                      <td>
                        {classroom.modalities.length > 0 // classroom.modality
                          ? classroom.modalities.join(', ')
                          : "Não informado"}
                      </td>
                      <td>
                        {classroom.stage
                          ? classroom.stage.name
                          : "Não informado"}
                      </td>
                      <td>
                        {classroom.phase
                          ? classroom.phase
                          : "Não informado"}
                      </td>
                      <td>
                        {classroom.year
                          ? classroom.year.name
                          : "Não informado"}
                      </td>
                      <td>
                        <button
                          className="profile"
                          type="button"
                          onClick={() => {
                            setSelectClass(classroom);

                          }}
                        >
                          {classroom.name}
                        </button>
                      </td>
                      <td>
                        <button className="hvr-grow">
                          <Edit onClick={() => onEdit(classroom)} />
                        </button>
                        <button className="hvr-grow">
                          <Trash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </ListDefault>
            </Main>
          </div>
        </Container>
      )}
      {switchPage === 2 && (
        <ClassProfile
          classroom={selectClass}
          back={() => setSwitchPage(1)}
          edit={() => setSwitchPage(3)}
        />
      )}
      {switchPage === 3 && <ClassEdit back={() => setSwitchPage(1)} />}
    </SuperBox>
  );
}
