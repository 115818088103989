import { FieldError } from "react-hook-form";
import UnidadeService from "../../../Services/Unidade.service";
import { ZodIssue, ZodIssueCode } from "zod";

interface ErrorProps {
  error: FieldError | undefined;
}

function getMessageError(error: any) {
  if (!error) {
    return ""; // Sem erros
  }

  switch (error.type) {
    case ZodIssueCode.invalid_type:
      return error.message;
    case ZodIssueCode.too_small:
      return error.message;
    case ZodIssueCode.too_big:
      return error.message;
    default:
      return error.message;
  }
}
// function getMessageError(error: FieldError) {
//   switch (error.type) {
//     case "required":
//       return "Campo obrigatório";
//     case "minLength":
//       return `Mínimo de ${error.message} caracteres`;
//     case "maxLength":
//       return `Máximo de ${error.message} caracteres`;
//     case "pattern":
//       return error.message;
//     case "validate":
//       return error.message;
//     default:
//       return "";
//   }
// }

export function ErrorLabel({ error }: ErrorProps) {

  return error ? <span className="error">{getMessageError(error)}</span> : null;
}
