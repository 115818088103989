import styled from "styled-components";

export const ContainerTable = styled.table`
width: 75%;
height: auto;
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
border-radius: 15px 15px 0px 0px;
border: 1px solid black;
margin-top: 10px;

thead{
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid black;
}

tbody{
    width: 100%;
    tr{
        display: flex;
        td{
            display: flex;
            justify-content: center;
            align-items: center;
            border-left: 1px solid black;
            width: 50%;
            min-height: 35px;
        }
    }
}

`