import React, { useContext, useMemo, useState } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'
import ResourceService from '../../../../../../Services/Resouce.service'

const Simlarity = () => {

    const { resource } = useContext(ResourceContext)
    const [resources, setResources] = useState<string[]>([])
    const [resourceOrigin, setResourceOrigin] = useState("")

    useMemo(()=>{
        setResources([])
        setResourceOrigin("")
        if(resource.origin_resource){
            ResourceService.showResource({identifier: resource.origin_resource}).then((res)=>{
                if(res?.success){
                    setResourceOrigin(res.data.data.name)
                }
            })
        }else{

        }
        if(resource.equivalent_resources){
            if (resource.equivalent_resources.length === 0) { return }
            Promise.all(resource.equivalent_resources.map((resource)=>
                ResourceService.showResource({identifier: resource}).then((res)=>{
                    if(res?.success){
                        setResources((state)=>{
                            return[...state, res.data.data.name]
                        })
                    }
                })
            )).then(()=>{
            })

        }else{
            return
        }
    },[])


    return (
        <div style={{ marginTop: "20px" }}>
            <tr>
                <td>
                    <span>SIMILARIDADE</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Recursos Equivalentes:</span>
                </td>
            </tr>
            <ul>
                {resource.equivalent_resources ?
                    resource.equivalent_resources.length > 0 ?
                    resources.map((resource) => {
                        return (
                            <li style={{listStyle: "none"}}>
                                - {resource}
                            </li>   
                        )
                    })
                    : "Não informado."
                    : "Não informado."
                }
            </ul>

            <tr>
                <td>
                    <span>Recurso de origem: {" "} {resourceOrigin ? resourceOrigin : "Não informado."}</span>
                </td>
            </tr>

        </div>
    )
}

export default Simlarity