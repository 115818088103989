import { ChevronLeft, Edit, Plus } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Container } from './styles'

export function EditDatas(props: any) {
  return (
    <Container>
      <h1>{props.page}</h1>
      <div>
        <button className="list hvr-grow" onClick={() => props.onBack()}>
          <ChevronLeft size={19} />
          {props.button}
        </button>
      </div>
    </Container>
  )
}
