import { useQuery } from "react-query";
import { Queries } from "../../types/queries";
import BNCCService from "../../Services/BNCC.service";

export function useListBNCCPhases(idStage?: string | undefined){
    
    return useQuery(
        Queries.LIST_BNCC_PHASES,
        () =>
            BNCCService.listBNCCPhases(idStage).then((res) => {
                if (res?.success) {
                    return res.data;
                }
                throw new Error(res?.message);
            }),
        {

            retry: true,
            cacheTime: 5 * 60 * 1000,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        }
    )

}