import { Dispatch, SetStateAction, createContext, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AcademicYear,
  AgeRange,
  Class,
  EducationStage,
  TypeClass,
} from "../../Utils/Types";

export interface ClassFields {
  year: AcademicYear | null;
  step: EducationStage | null;
  phase: any | null;
  ageRange: AgeRange | null;
  modality: string[];
  name: string
}

export interface NewClassFields {
  year: string | null;
  step: EducationStage | null;
  phase: any | null;
  ageRange: AgeRange | null;
  modality: string[];
  name: string;
}

interface RegisterClassType {
  classFields: ClassFields;
  listClass: ClassFields[];
  title: string;
  switchForm: number;
  idClass: string | null;
  edit: boolean;
  setClassFields: (classFields: ClassFields) => void;
  setListClass: (list: ClassFields[]) => void;
  handleFieldClass: (key: keyof ClassFields, value: any) => void;
  clearClass: () => void;
  handleSwitchForm: (value: number) => void;
  listClasses: Class[],
  setListClasses: Dispatch<SetStateAction<Class[]>>
}

export const RegistrationContext = createContext<RegisterClassType>(
  {} as RegisterClassType
);

interface RegistrationClassProps {
  children: React.ReactNode;
  edit: boolean;
}

export function RegistrationClassProvider({
  children,
  edit,
}: RegistrationClassProps) {
  const location = useLocation();
  const itemData = (location.state as TypeClass) || undefined;

  const [classFields, setClassFields] = useState<ClassFields>({
    year: itemData?.academicYear,
    step: itemData?.educationStage,
    phase: itemData?.phase,
    ageRange: itemData?.ageRange,
    modality: itemData?.modality,
    name: itemData?.name,
  });

  const [listClasses, setListClasses] = useState<Class[]>([])
  const [listClass, setListClass] = useState<ClassFields[]>([]);
  const [switchForm, setSwitchForm] = useState(1);
  const title = edit ? "Alteração" : "Cadastro";
  const idClass = itemData?.identifier || null;

  function handleFieldClass(key: string, value: any) {
    setClassFields((classFields) => {
      return { ...classFields, [key]: value };
    });
  }

  function clearClass() {
    setListClass([]);
  }

  function handleSwitchForm(value: number) {
    setSwitchForm(value);
  }
  return (
    <RegistrationContext.Provider
      value={{
        classFields,
        setClassFields,
        handleFieldClass,
        listClass,
        setListClass,
        clearClass,
        title,
        switchForm,
        handleSwitchForm,
        idClass,
        edit,
        listClasses,
        setListClasses
      }}
    >
      {children}
    </RegistrationContext.Provider>
  );
}
