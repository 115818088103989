import { useRef, useState } from "react";
import { Container } from "../styles";
import { Edit2Icon, PlusCircleIcon, Trash2Icon } from "lucide-react";
import Modal, { ModalRef } from "../../../../Components/Modals";
import { api } from "../../../../Services/api";
import { ColorPicker } from "../../../../Components/Form/ColorPicker";
import { ContainerNewCategory, ContainerNewSubCategory, Main, TreeItemContainer, TreeItemWrapper } from "./styles";
import { ModalShort } from "../../../../Components/Modals/ModalShort";

export function TreeItem({ level, item, idCollection }: { level: number, item: any, idCollection: string }) {
    const [isOpen, setIsOpen] = useState(false);
    const modalEditNewCategory = useRef<ModalRef>(null)
    const modalEditSubcategory = useRef<ModalRef>(null)

    const [collectionName, setCollectionName] = useState("");
    const [collectionDescription, setCollectionDescription] = useState("");

    const [correntIdSubCategory, setCorrentIdSubCategory] = useState("");
    const [idCategoryOpen, setIdCategoryOpen] = useState("");

    const [categoryNameEdit, setCategoryNameEdit] = useState("");
    const [categoryDescriptionEdit, setCategoryDescriptionEdit] = useState("");
    const [backgroundColorEdit, setBackgroundColorEdit] = useState<string>("#000000");

    const [ModalShortShow, setModalShortShow] = useState(false);
    const [message, setMessage] = useState("")

    const [modalDeleteCategory, setModalDeleteCategory] = useState(false)
    const [modalDeleteSubcategory, setModalDeleteSubcategory] = useState(false)
    const [forDelete, setForDelete] = useState<any>("")

    function closeModal() {
        setIsOpen(false);
        window.location.reload();
    }

    const modalRegisterNewCategory = useRef<ModalRef>(null)
    const handleAddSubcategory = (id: string) => {
        setCorrentIdSubCategory(id);
        modalRegisterNewCategory.current?.open()
    };
    function cancelRegiter() {
        modalRegisterNewCategory.current?.close()
    }

    function cancelUpdate() {
        modalEditNewCategory.current?.close()
    }
    function cancelUpdateSub() {
        modalEditSubcategory.current?.close()
    }

    const handleEditCategory = (item: any) => {
        setCorrentIdSubCategory(item.identifier)
        setCategoryNameEdit(item.name)
        setCategoryDescriptionEdit(item.description)
        setBackgroundColorEdit(item.color)
        modalEditNewCategory.current?.open()
    };

    const handleEditSubcategory = (item: any) => {
        setCorrentIdSubCategory(item.identifier)
        setCategoryNameEdit(item.name)
        setCategoryDescriptionEdit(item.description)
        modalEditSubcategory.current?.open()
    }

    // cadastrar subcategoria

    console.log(idCategoryOpen)
    console.log(correntIdSubCategory)
    function addSubcategory() {
        if (collectionName !== "" || collectionDescription !== "") {

            const data = (idCategoryOpen && idCategoryOpen !== correntIdSubCategory) ? {
                name: collectionName,
                description: collectionDescription,
                subcategory_id: correntIdSubCategory

            } : {
                name: collectionName,
                description: collectionDescription,
            }

            const correntIdcategory = data.subcategory_id ?
                idCategoryOpen : correntIdSubCategory

            api.post(`/admin/categories/${correntIdcategory}/subcategories`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                }
            }).then(response => {
                setModalShortShow(true)
                setMessage("Subcategoria cadastrada!")
            })
        } else {
            alert("Preencha o Nome e Descrição");
        }
    }

    // atualizar categoria
    function updateCategory() {
        api.put(`/admin/collections/${idCollection}/categories/${correntIdSubCategory}`, {
            name: categoryNameEdit,
            description: categoryDescriptionEdit,
            color: backgroundColorEdit
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            setModalShortShow(true)
            setMessage("Categoria editada!")
        })
    }

    // atualizar subcategoria
    function updateSubcategory() {

        const data = {
            name: categoryNameEdit,
            description: categoryDescriptionEdit,
            subcategory_id: correntIdSubCategory
        }

        api.put(`/admin/categories/${idCategoryOpen}/subcategories/${correntIdSubCategory}`, data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            setModalShortShow(true)
            setMessage("Subcategoria editada!")
        })
    }

    // deletar categoria
    function deleteCategory(idCategory: string) {
        api.delete(`/admin/collections/${idCollection}/categories/${idCategory}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            setModalShortShow(true)
            setMessage("Categoria deletada!")
        })
    }

    // deletar subcategoria
    function deleteSubcategory(idSubcategory: string) {
        api.delete(`/admin/categories/${idCategoryOpen}/subcategories/${idSubcategory}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            setModalShortShow(true)
            setMessage("Subcategoria deletada!")
        })
    }


    function ItemSubCategoryChild({ level, item }: { level: number, item: any }) {
        const [isOpen, setIsOpen] = useState(false);

        return (
            <>
                <TreeItemWrapper>
                    <TreeItemContainer level={level} className={isOpen ? 'opened' : ''} onClick={() => { setIsOpen(!isOpen) }}>
                        <div className="item-content">
                            <div
                                className="circle"
                                style={{ backgroundColor: item.color, width: "20px", height: "20px", }}
                            />
                            <div className="item-name">{item.name}</div>
                            <div onClick={() => { setIsOpen(!isOpen) }}>
                                <span className="arrow-icon"></span>
                            </div>
                        </div>
                    </TreeItemContainer>
                    <div className="action-icons">
                        {level < 3 && <PlusCircleIcon onClick={() => { handleAddSubcategory(item.identifier) }} size={16} />}
                        <Edit2Icon onClick={() => handleEditSubcategory(item)} size={16} />
                        <Trash2Icon size={16} onClick={() => {
                            setForDelete(item.identifier);
                            setModalDeleteSubcategory(true);
                        }} />
                    </div>
                </TreeItemWrapper>

                {isOpen && item.subcategories && item.subcategories.map((sub: any) => (
                    <div><ItemSubCategoryChild item={sub} level={level + 1} /></div>
                ))}
                {isOpen && item.children && item.children.map((child: any) => (
                    <div><ItemSubCategoryChild item={child} level={level + 1} /></div>
                ))}
            </>
        )
    }

    return (
        <>
            <Container>
                <Main>
                    <TreeItemWrapper>
                        <TreeItemContainer level={level} className={isOpen ? 'opened' : ''}
                            onClick={() => {
                                setIsOpen(!isOpen);
                                setIdCategoryOpen(item.identifier);
                            }}>
                            <div className="item-content">
                                <div
                                    className="circle"
                                    style={{ backgroundColor: item.color, width: "20px", height: "20px", }}
                                />
                                <div className="item-name">{item.name}</div>
                                <div onClick={() => {
                                    setIsOpen(!isOpen);
                                    setIdCategoryOpen(item.identifier);
                                }}>
                                    <span className="arrow-icon"></span>
                                </div>
                            </div>
                        </TreeItemContainer>

                        <div className="action-icons">
                            <PlusCircleIcon onClick={() => { handleAddSubcategory(item.identifier) }} size={16} />
                            <Edit2Icon onClick={() => handleEditCategory(item)} size={16} />
                            <Trash2Icon onClick={() => { setForDelete(item.identifier); setModalDeleteCategory(true); setMessage("categoria") }} size={16} />
                        </div>
                    </TreeItemWrapper>

                    {isOpen && item.subcategories && item.subcategories.map((sub: any) => (
                        <div><ItemSubCategoryChild item={sub} level={level + 1} /></div>
                    ))}

                    <Modal styleDefault={false} ref={modalRegisterNewCategory}>
                        <ContainerNewSubCategory>
                            <header>
                                <p>Cadastrar nova Subcategoria</p>
                            </header>
                            <section>
                                <div className='flex-label'>
                                    <label htmlFor="">Nome da Categoria</label>
                                </div>
                                <div className='flex-inputs'>
                                    <input type="text" onChange={(e: any) => setCollectionName(e.target.value)} value={collectionName} />
                                </div>
                                <div className='space' />
                                <label htmlFor="">Descrição</label>
                                <textarea className="multi" onChange={(e: any) => setCollectionDescription(e.target.value)} value={collectionDescription} />
                            </section>
                            <footer>
                                <button className="hvr-grow" onClick={cancelRegiter} >
                                    Cancelar
                                </button>
                                <button className="hvr-grow" onClick={addSubcategory}>
                                    Salvar
                                </button>
                            </footer>
                        </ContainerNewSubCategory>
                    </Modal>

                    <Modal styleDefault={false} ref={modalEditNewCategory}>
                        <ContainerNewCategory>
                            <header>
                                <p>Editar Categoria</p>
                            </header>
                            <section>
                                <div className='flex-label'>
                                    <label htmlFor="">Nome da Categoria</label>
                                    <label htmlFor="">Definir cor</label>
                                </div>
                                <div className='flex-inputs'>
                                    <input type="text" onChange={(e: any) => setCategoryNameEdit(e.target.value)} value={categoryNameEdit} />
                                    <div className="colorPicker">
                                        <ColorPicker defaultColor={backgroundColorEdit || undefined} onChange={setBackgroundColorEdit} />
                                    </div>
                                </div>
                                <div className='space' />
                                <label htmlFor="">Descrição</label>
                                <textarea className="multi" onChange={(e: any) => setCategoryDescriptionEdit(e.target.value)} value={categoryDescriptionEdit} />
                            </section>
                            <footer>
                                <button className="hvr-grow" onClick={cancelUpdate} >
                                    Cancelar
                                </button>

                                <button className="hvr-grow" onClick={updateCategory}>
                                    Salvar
                                </button>
                            </footer>
                        </ContainerNewCategory>
                    </Modal>

                    <Modal styleDefault={false} ref={modalEditSubcategory}>
                        <ContainerNewSubCategory>
                            <header>
                                <p>Editar subcategoria</p>
                            </header>
                            <section>
                                <div className='flex-label'>
                                    <label htmlFor="">Nome da Categoria</label>
                                </div>
                                <div className='flex-inputs'>
                                    <input type="text" onChange={(e: any) => setCategoryNameEdit(e.target.value)} value={categoryNameEdit} />
                                </div>
                                <div className='space' />
                                <label htmlFor="">Descrição</label>
                                <textarea className="multi" onChange={(e: any) => setCategoryDescriptionEdit(e.target.value)} value={categoryDescriptionEdit} />
                            </section>
                            <footer>
                                <button className="hvr-grow" onClick={cancelUpdateSub} >
                                    Cancelar
                                </button>

                                <button className="hvr-grow" onClick={updateSubcategory}>
                                    Salvar
                                </button>
                            </footer>
                        </ContainerNewSubCategory>
                    </Modal>

                    {ModalShortShow && (
                        <ModalShort
                            type="success"
                            text={message}
                            isOpen={ModalShortShow}
                            onRequestClose={closeModal}
                            onConfirm={closeModal}
                        />
                    )}

                </Main>
            </Container>

            {modalDeleteCategory && (
                <ModalShort
                    type="delete"
                    isOpen={modalDeleteCategory}
                    text={`Tem certeza de que deseja excluir esta categoria?
                    Todas as subcategorias associadas serão perdidas.`}
                    onRequestClose={() => {
                        setModalDeleteCategory(false)
                    }}
                    onConfirm={() => {
                        setModalDeleteCategory(false);
                        deleteCategory(forDelete);
                    }}
                />
            )}

            {modalDeleteSubcategory && (
                <ModalShort
                    type="delete"
                    isOpen={modalDeleteSubcategory}
                    text={`Tem certeza de que deseja excluir esta subcategoria? 
                    Todas as subcategorias associadas serão perdidas.`}
                    onRequestClose={() => {
                        setModalDeleteSubcategory(false)
                    }}
                    onConfirm={() => {
                        setModalDeleteSubcategory(false);
                        deleteSubcategory(forDelete);
                    }}
                />
            )}
        </>
    );
}