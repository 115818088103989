import {
  Forbidden,
  NotFound,
  ServiceError,
  Success,
  SuccessData,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { TResource } from "../types/types";
import { isSuccessData } from "../types/verifications";
import { api } from "./api";

type ResourceGetResponse = SuccessData<TResource[]> | Unauthorized | Forbidden;

export interface IResource {
  identifier?: string;
  name?: string;
  description?: string;
  format?: string;
  language?: string;
  image?: string;
  plataforms?: {
    identifier?: string;
    name?: string;
    type?: string;
  };
  authorship?: {
    content_creator?: string;
    proofreader?: string;
    graphic_designer?: string;
    screenwriter?: string;
    illustrator?: string;
    programmer?: string;
  };
  similarities?: {
    origin?: string;
    equivalents?: [string];
  };
  bncc?: {};
  collections?: [
    {
      identifier?: string;
      name?: string;
      description?: string;
      color?: string;
      logo?: string;
      category?: {
        identifier?: string;
        name?: string;
        description?: string;
        color?: string;
        subcategories?: [
          {
            identifier?: string;
            name?: string;
            description?: string;
            level?: number;
            children?: [string];
          }
        ];
      };
    }
  ];
  evaluated_content?: {
    license?: string;
    intellectual_rights?: [string];
    parental_rating?: string;
    content_descritors?: [string];
  };
  tags?: [
    {
      identifier?: string;
      name?: string;
    }
  ];
  active?: boolean;
  published?: boolean;
  test?: boolean;
}

interface IGetResource {
  name?: string;
  active?: boolean;
  published?: boolean;
  test?: boolean;
  collectionResources?: {
    identifier?: string;
    subcategory?: {
      identifier?: string;
    };
  };
  authorship?: {
    content_creator?: string;
    proofreader?: string;
    graphic_designer?: string;
    screenwriter?: string;
    illustrator?: string;
    programmer?: string;
  };
  format?: string;
  language?: string;
  originResource?: {
    identifier?: string;
  };
  equivalentResources?: {
    identifier?: string;
  };
  targetAudiences?: {
    identifier?: string;
  };
  plataforms?: {
    identifier?: string;
  };
  evaluatedContent?: {
    license?: string;
    intellectual_rights?: [string];
    parental_rating?: string;
    content_descritors?: [string];
  };
  bncc?: {
    basic_competences?: [string];
    stages?: [string];
    years?: [string];
    fields_experience?: [string];
    skills?: [string];
    knowledge_areas?: [string];
    curricular_components?: [string];
    specific_competences?: [string];
    specific_competences_port?: [string];
    fields_action?: [string];
    thematic_units?: [string];
    knowledge_objects?: [string];
  };
  pedagogicalGuideline?: {
    tips?: [string];
    similar_reference?: [string];
    content_reference?: [string];
  };
}

interface ShowResourceProps {
  identifier: string | undefined
}

type GetResponse = Success | Unauthorized | Forbidden | NotFound | ServiceError

type PutResponse = Success | Unauthorized | Forbidden | NotFound

type PutResourceProps = {
  identifier?: string
  resource: any
}

type DeleteProps = {
  identifier?: string
}

type DeleteResponse = Success | Unauthorized | Forbidden | NotFound

class ResourceService {

  static async delete({ identifier }: DeleteProps) {
    try{

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await api.delete<DeleteResponse>(
        `/admin/resources/${identifier}`,
        config
      )

      return response.data
      
    }catch (error: any) {
      // throw new Error("Can not update Resorce", error)
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async update({ resource, identifier }: PutResourceProps) {
    console.log(resource);
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await api.post(`/admin/resources/${identifier}?_method=PUT`, resource, config);
      return response.data;
      
    } catch (error: any) {
      // throw new Error("Can not update Resorce", error)
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }



  static async showResource({ identifier }: ShowResourceProps) {

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<GetResponse>(
        `/admin/resources/${identifier}`,
        config
      )

      switch (response.status) {
        case 200:
          return { data: response.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }


    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        console.error(errorMessages);
      } else {
        console.error(errorResponse.message);
      }
    }

  }


  static async listResource(params?: IGetResource) {
    try {
      let query = "";

      if (params) {
        if (params.name) {
          query += `name=${params.name}&`;
        }
        if (params.active) {
          query += `active=${params.active}&`;
        }
        if (params.published) {
          query += `published=${params.published}&`;
        }
        if (params.test) {
          query += `test=${params.test}&`;
        }
        if (params.collectionResources) {
          query += `collectionResources=${params.collectionResources.identifier}&`;
        }
        if (params.authorship) {
          query += `authorship=${params.authorship.content_creator}&`;
        }
        if (params.format) {
          query += `format=${params.format}&`;
        }
        if (params.language) {
          query += `language=${params.language}&`;
        }
        if (params.originResource) {
          query += `originResource=${params.originResource.identifier}&`;
        }
        if (params.equivalentResources) {
          query += `equivalentResources=${params.equivalentResources.identifier}&`;
        }
        if (params.targetAudiences) {
          query += `targetAudiences=${params.targetAudiences.identifier}&`;
        }
        if (params.plataforms) {
          query += `plataforms=${params.plataforms.identifier}&`;
        }
        if (params.evaluatedContent) {
          query += `evaluatedContent=${params.evaluatedContent.license}&`;
        }
      }
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<ResourceGetResponse>(
        `/admin/resources?${query}`,
        config
      );

      if ("data" in response.data) {
        return response.data.data;
      }

      throw new Error(response.data.message);
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        console.error(errorMessages);
      } else {
        console.error(errorResponse.message);
      }
    }
  }

  static async create(data: any) {
    try {
      console.log(data);
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await api.post(`/admin/resources`, data, config);

      console.log(response);
      return response.data;
    } catch (error: any) {
      throw new Error("Can not create Resorce", error)
      // const errorResponse = error.response.data;
      // if (errorResponse.errors) {
      //   const errorMessages = [];

      //   for (const key in errorResponse.errors) {
      //     errorMessages.push(errorResponse.errors[key] + "\n");
      //   }
      //   alert(errorMessages);
      // } else {
      //   alert(errorResponse.message);
      // }
    }
  }
}

export default ResourceService;
