import { api } from "./api";
import { AxiosError } from "axios";
import {
  CreateSuccess,
  CreateSuccessData,
  Forbidden,
  GetPaginationSuccess,
  ServiceError,
  Success,
  SuccessData,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { AcademicYear, TypePhase } from "../Utils/Types";

type FaseCreateResponse = any | Unauthorized | Forbidden | UnprocessableContent;

type FaseGetResponse = GetPaginationSuccess<TypePhase[]> | Unauthorized;

interface FaseCreate {
  description: string;
}

type ShowFaseResponse = Success | Unauthorized | Forbidden | ServiceError;

type ShowFaseProps = {
  educationInstitution: string,
  identifier: string
}


class FasesService {

  static async show({ educationInstitution, identifier }: ShowFaseProps):Promise<ShowFaseResponse> {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/client/${educationInstitution}/phases/${identifier}`,
        config
      )
      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not Show Fase.")
    }
  }


  static async get(educationInstiutionId: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<FaseGetResponse>(
        `/client/${educationInstiutionId}/phases`,
        config
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async post(data: FaseCreate, educationInstiutionId: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<FaseCreateResponse>(
        `/client/${educationInstiutionId}/phases`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default FasesService;
