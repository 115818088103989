import styled from 'styled-components'

export const Container = styled.div`
  max-width: 250px;
  > p {
    font-size: 10px;
    font-weight: 400;
    color: #646464;
  }
  .donutchart {
    path {
      opacity: 1 !important;
    }
  }
  > div {
    position: relative;
    width: 180px;
    height: 180px;
    > p {
      position: absolute;
      top: 10;
      left: 10;
      width: 180px;
    height: 180px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 40px;
      font-weight: 900;
      color: #088A62;
    }
  }
`
