import styled from 'styled-components';
import bgImg from '../../Assets/background.png';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
`;

export const Aside = styled.aside`
background-color: blue;
content: '';
flex: 1;
height: 100%;
min-height: 100vh;
background-image: url(${bgImg});
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
`;
export const Main = styled.main`
flex: 1;
height: 100%;
min-height: 100vh;
display: flex;
align-items: center;
justify-content: center;
`;

export const Form = styled.div`
width: 360px;
/* background-color: red; */

>p{
font-weight: 700;
font-size: 30px;
}
>span{
color: #2D3748;
font-size: 14px;
}
>div.form{
display: flex;
align-items: flex-start;
justify-content: center;
flex-direction: column;
gap: 5px;
}
label{
color: #686868;
}
input{
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 0px 10px;
    border: 1px solid #E8E8E8;
}
>div.pass{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 0px;
}
a{
    color: #027294;
    text-decoration: none;
}
>button{
    background-color: #027294;
    height: 50px;
    border-radius: 5px;
    width: 100%;
    border: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    transition: 0.3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    &:hover{
        background-color: #0292BD;
    }

    &.back{
        background-color: transparent;
        color: #027294;
        border: 1px solid #027294;
        margin-top: 5px;
    }
}
`;
