import {
  ArrowDown,
  ArrowLeftRight,
  ArrowLeftRightIcon,
  ArrowUp,
  Backpack,
  ChevronDown,
  ChevronUp,
  FileText,
  Home,
  Inbox,
  Newspaper,
  Tag,
  User,
} from "lucide-react";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SelectClient } from "../../../Pages/Home/styles";
import { api } from "../../../Services/api";
import { Select } from "../../Form/Select";
import { Container, Text, Toggle } from "./styles";
import { Users } from "lucide-react";
export function Navigation(props: any) {
  const navigate = useNavigate();
  const [switchCadastro, setSwithCadastro] = useState(props.switch);

  const [switchTransferencia, setSwithTransferencia] = useState(props.switchT);
  const [switchReport, setSwitchReport] = useState(false)
  const [pageOn, setPageOn] = useState(props.page);
  const user = JSON.parse(String(localStorage.getItem("user")));

  function handleResources() {
    setSwithCadastro(false);
    navigate("/recursos/list");
  }

  function handleUnidade() {
    if (
      localStorage.getItem("client") === null &&
      JSON.parse(String(localStorage.getItem("roles"))) === "Master"
    ) {
      alert("Selecione um cliente");
      return;
    }
    setSwithCadastro(false);
    navigate("/unidade/list");
  }
  function handleEmployee() {
    if (
      localStorage.getItem("client") === null &&
      JSON.parse(String(localStorage.getItem("roles"))) === "Master"
    ) {
      alert("Selecione um cliente");
      return;
    }
    setSwithCadastro(false);
    navigate("/employees/listEmployees");
  }
  function handleTags() {
    // if (
    //   localStorage.getItem("client") === null &&
    //   JSON.parse(String(localStorage.getItem("roles"))) === "Master"
    // ) {
    //   alert("Selecione um cliente");
    //   return;
    // }
    setSwithCadastro(false);
    navigate("/tags/registration");
  }

  function handleToggleTransferencia() {
    if (switchTransferencia) {
      setSwithTransferencia(false);
    } else {
      setSwithTransferencia(true);
      setSwithCadastro(false);
    }
  }
  function handleToggleReport() {
    if (
      localStorage.getItem("client") === null &&
      JSON.parse(String(localStorage.getItem("roles"))) === "Master"
    ) {
      alert("Selecione um cliente");
      return;
    }
    if (switchReport) {
      setSwitchReport(false)
    } else {
      setSwitchReport(true)
      setSwithCadastro(false)
    }
  }

  function handleClick(e: any) {
    const client = props.clients.find((client: any) => client.identifier === e);
    api
      .post(
        "/admin/technicians/change-client",
        {
          client_id: e,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((response) => {
        localStorage.setItem("client", JSON.stringify(client));
      });
  }

  const [selectedItem, setSelectedItem] = useState("");
  const location = useLocation();
  const item = location.pathname.split("/");

  return (
    <Container>
      <SelectClient>
        {JSON.parse(String(localStorage.getItem("roles"))) === "Master" ? (
          <div>
            <Select onChange={handleClick}>
              <option value="">
                {localStorage.getItem("client")
                  ? JSON.parse(localStorage.getItem("client") || "").socialName
                  : "Selecione..."}
              </option>
              {props.clients.map((client: any, index: any) => (
                <option key={index} value={client.identifier}>
                  {client.socialName}
                </option>
              ))}
            </Select>
            <p>
              <Backpack
                size={22}
                onClick={(id: any) => {
                  localStorage.getItem("client")
                    ? navigate("/clientes", {
                      state: {
                        client: JSON.parse(
                          localStorage.getItem("client") || ""
                        ),
                      },
                    })
                    : alert("Selecione um cliente");
                }}
              />
              <ArrowLeftRightIcon size={22} />
            </p>
          </div>
        ) : (
          <Text>{user ? user.tenant?.socialName : ""}</Text>
        )}
      </SelectClient>

      <Toggle
        className={item[1] === "" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/");
          setSelectedItem("");
        }}
      >
        <div>
          <Home size={16} />
          <p>Home</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "clientes" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/clientes/list");
          setSelectedItem("clientes");
        }}
      >
        <div>
          <User size={16} />
          <p>Clientes</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "unidade" ? "selected" : ""}
        onClick={() => {
          handleUnidade();
          setSelectedItem("unidade");
        }}
      >
        <div>
          <User size={16} />
          <p>Unidade Educacional</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "employees" ? "selected" : ""}
        onClick={() => {
          handleEmployee();
          setSelectedItem("employees");
        }}
      >
        <div>
          <User size={16} />
          <p>Funcionário</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "tech" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/tech/list");
          setSelectedItem("tech");
        }}
      >
        <div>
          <User size={16} />
          <p>Técnico</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "collection" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/collection/list");
          setSelectedItem("colecao");
        }}
      >
        <div>
          <Inbox size={16} />
          <p>Coleção</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "pacotes" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/pacotes");
          setSelectedItem("pacotes");
        }}
      >
        <div>
          <Newspaper size={16} />
          <p>Pacotes</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "tags" ? "selected" : ""}
        onClick={() => {
          handleTags();
          setSelectedItem("tags");
        }}
      >
        <div>
          <Tag size={16} />
          <p>Tags</p>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "recursos" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          handleResources()
          setSelectedItem("recursos");
        }}
      >
        <div>
          <Inbox size={16} />
          <p>Recursos</p>
        </div>
      </Toggle>
      <Toggle
        className={switchTransferencia ? "active" : ""}
        onClick={() => handleToggleTransferencia()}
      >
        <div>
          <ArrowLeftRight size={16} />
          <p>Transferência</p>
        </div>
        <div className={switchTransferencia ? "active tr" : ""}>
          <Link
            className={pageOn === 7 ? "active" : ""}
            to={"/transferencia/estudante"}
          >
            Estudante
          </Link>
          <Link
            className={pageOn === 8 ? "active" : ""}
            to={"/transferencia/turma"}
          >
            Turma
          </Link>
          <Link
            className={pageOn === 9 ? "active" : ""}
            to={"/transferencia/solicitacoes"}
          >
            Solicitações
          </Link>
        </div>
      </Toggle>
      <Toggle
        className={switchReport ? "active" : ""}
        onClick={() => handleToggleReport()}
      >
        <div>
          {switchReport ?
            <ChevronUp size={16} />
            :
            <ChevronDown size={16} />
          }
          <p>Relatório</p>
        </div>
        <div className={switchReport ? "active tr" : ""}>
          <Link
            to={"/report/client"}
          >
            Cliente
          </Link>
          <Link
            to={"/report/users"}
          >
            Usuários
          </Link>
        </div>
      </Toggle>
      <Toggle
        className={item[1] === "users" ? "selected" : ""}
        onClick={() => {
          setSwithCadastro(false);
          navigate("/users/profiles");
          setSelectedItem("profiles");
        }}
      >
        <div>
          <Users size={16} />
          <p>Perfis de Usuário</p>
        </div>
      </Toggle>


    </Container>
  );
}
