import { useState } from 'react'
import { LeftMenu } from '../../../Components/LeftMenu'
import { SuperBox } from '../../../Components/SuperBox'
import { api } from '../../../Services/api'
import { Form1 } from './Form1'
import { Form2 } from './Form2'
import { Form3 } from './Form3'
import { Abas, Container, Form, Form22, Main, Sitemap, Upload } from './styles'

interface TypeCollection {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

interface TypePackage {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

export function ClientRegistration() {
  const [switchForm, setSwitchForm] = useState(1)
  const [socialName, setSocialName] = useState('')
  const [cnpj, setCnpj] = useState('')
  const [address, setAddress] = useState('')
  const [number, setNumber] = useState('')
  const [complement, setComplement] = useState('')
  const [neighborhood, setNeighborhood] = useState('')
  const [zipCode, setZipCode] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [email2, setEmail2] = useState('')
  const [teachersNumber , setTeachersNumber] = useState<any>(0);
  const [studentsNumber , setStudentsNumber] = useState<any>(0);
  const [educationNumber , setEducationNumber] = useState<any>(0)
  const [selectedCollection, setSelectedCollection] = useState<TypeCollection[]>([])
  const [selectedPackage, setSelectedPackage] = useState<TypePackage[]>([])
  const [img, setImg] = useState<any>('')

  return (
    <SuperBox switch={true} page={1} path="Cadastro &bull; Cliente">
      <Container>
        <div className="box">
          <Main>
            {switchForm !== 3 && (
            <Abas>
              <button className={switchForm === 1 ? 'active' : ''}>
                Cliente
              </button>
              <button className={switchForm === 2 ? 'active' : ''}>
                Plano
              </button>
            </Abas>
            )}
            {switchForm === 1 && (
              <Form1
                next={() => setSwitchForm(2)}
                socialName={socialName}
                changeSocialName={(v: any) => setSocialName(v)}
                cnpj={cnpj}
                changeCnpj={(v: any) => setCnpj(v)}
                phone={phone}
                changePhone={(v: any) => setPhone(v)}
                email={email}
                changeEmail={(v: any) => setEmail(v)}
                email2={email2}
                changeEmail2={(v: any) => setEmail2(v)}
                address={address}
                changeAddress={(v: any) => setAddress(v)}
                complement={complement}
                changeComplement={(v: any) => setComplement(v)}
                number={number}
                changeNumber={(v: any) => setNumber(v)}
                neighborhood={neighborhood}
                changeNeighborhood={(v: any) => setNeighborhood(v)}
                zipCode={zipCode}
                changeZipCode={(v: any) => setZipCode(v)}
                city={city}
                changeCity={(v: any) => setCity(v)}
                state={state}
                changeState={(v: any) => setState(v)}
                img={img}
                setImg={setImg}
              />
            )}
            {switchForm === 2 && (
              <Form2
                next={() => setSwitchForm(3)}
                prev={() => setSwitchForm(1)}
                changeTeachersNumber={(v: any) => setTeachersNumber(v)}
                changeStudentsNumber={(v: any) => setStudentsNumber(v)}
                changeEducationNumber={(v: any) => setEducationNumber(v)}
                teachersNumber={teachersNumber}
                studentsNumber={studentsNumber}
                educationNumber={educationNumber}
                selectedCollection={selectedCollection}
                selectedPackage={selectedPackage}
                changeSelectedCollection={(v: any) => setSelectedCollection(v)}
                changeSelectedPackage={(v: any) => setSelectedPackage(v)}

              />
            )}
            {switchForm === 3 && (
              <Form3
                next={() => setSwitchForm(4)}
                prev={() => setSwitchForm(2)}
                teachersNumber={teachersNumber}
                studentsNumber={studentsNumber}
                educationNumber={educationNumber}
                socialName={socialName}
                cnpj={cnpj}
                address={address}
                number={number}
                complement={complement}
                neighborhood={neighborhood}
                zipCode={zipCode}
                city={city}
                state={state}
                phone={phone}
                email={email}
                selectedCollection={selectedCollection}
                selectedPackage={selectedPackage}
                img={img}
              />
            )}
          </Main>
        </div>
      </Container>
    </SuperBox>
  )
}
