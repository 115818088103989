import styled from 'styled-components';

export const ResumeRequest = styled.div`
    margin-left: 15%;
    width: 70%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #F8F8FA;

    header {
            background-color: #FE8F2E;
            color: #F8F8FA;
            text-align: center;
            border-radius: 10px 10px 0px 0px;
            height: 82px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-transform: uppercase;
        }

        section{
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            width: 95%;

            .section-1{
                display: flex;
                flex-direction: column;
                gap: 5px;
                
                p{
                    text-transform: uppercase;
                }
                
                .area{
                    display: flex;
                    width: 730px;
                    height: 100px;
                    align-items: center;
                    border-radius: 15px;
                    border: solid 1px;
                    background-color: #FFFFFF;

                    tr{
                        padding-left: 10px;
                    }
                }
            }

            .section-2{
                display: flex;
                gap: 25px;
                
                .box-1{
                    display: flex;
                    gap: 5px;
                    width: 30%;
                    flex-direction: column;
                
                    .fx30{
                        display: flex;
                        background-color: #FFFFFF;
                        height: 45px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 15px;
                        border: solid 1px;
                    }
                }

                .box-2{
                    display: flex;
                    gap: 5px;
                    width: 70%;
                    flex-direction: column;
                 
                    .fx70{
                        display: flex;
                        background-color: #FFFFFF;
                        height: 45px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 15px;
                        border: solid 1px;
                    }
                }
            }

            .section-3{
                display: flex;
                flex-direction: column;
                gap: 5px;

                .area{
                    display: flex;
                    height: 76px;
                    padding: 10px;
                    border-radius: 15px;
                    border: solid 1px;
                    background-color: #FFFFFF;
                }
            }
        }

        footer{
            display: flex;
            justify-content: flex-end;
            width: 100%;
            padding-right: 20px;
            padding-bottom: 20px;

            button {
                width: 110px;
                height: 40px;
                text-transform: uppercase;
                color: #F8F8FA;
                background-color: #088A62;
                border-radius: 10px;
                cursor: pointer;
                border: none;
            }
        }
`;

