import React, { useContext, useEffect } from 'react'
import { ContextEditTeacher, ContextEditTeacherProvider, TeacherEdit } from '../Contexts/ContextEditTeacher'
import FormEdit from './Form/FormEdit'
import { Divider } from '../../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../../Components/Wizard'
import { Form } from '../../../../Registration/UserRegistration/styles'
import Review from './Review/Review'

interface Props {
    teacherEdit: TeacherEdit
}

const FormTeacherEdit = ({teacherEdit}:Props) => {

    const { step, setTeacher} = useContext(ContextEditTeacher)

    useEffect(()=>{
        setTeacher(teacherEdit)
    },[])


    return (
        <Form>
            <Divider>
                <Wizard>
                    <WizardStep active={step === 1 ? true : false} text="Professor" />
                    <WizardStep active={step === 2 ? true : false} text="Revisar" />
                </Wizard>
            </Divider>
            {step === 1 &&(
                <FormEdit/>
            )}
            {step === 2 &&(
                <Review/>
            )}
        </Form>
    )
}

export default FormTeacherEdit