import React, { useEffect, useState } from 'react'
import { Class, EducationStage, Student } from '../../../../../Utils/Types'
import { Divider } from '../../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../../Components/Wizard'
import { Form } from '../../../../Registration/UserRegistration/styles'
import { Input } from '../../../../../Components/Form/Input'
import { DivFlex } from '../../../../Registration/UserRegistration/FormStudent/styles'
import EspecialNeedComponent from '../../../../Registration/UserRegistration/NewFormStudent/EspecialNeedComponent/EspecialNeedComponent'
import ReactSelect from '../../../../../Components/Form/ReactSelect/ReactSelect'
import EtapaAcademicaService from '../../../../../Services/EtapaAcademica.service'
import TurmaService from '../../../../../Services/Turma.service'
import ComponentResponsible from '../../../../Registration/UserRegistration/FormStudent/ComponentResponsible/ComponentResponsible'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import ReviewStudentEdit from './ReviewStudentEdit/ReviewStudentEdit'
import { statSync } from 'fs'
import { useListBNCCYears } from '../../../../../hooks/ListClass/useListYears'
import StudentService from '../../../../../Services/Student.service'
import { useQueryClient } from 'react-query'
import { Queries } from '../../../../../types/queries'
import { StudentView } from '../../../../../types/StudentsTypes/typeStudents'

interface Props {
    studentSelected: Student
}

const NewFormStudentEdit = ({ studentSelected }: Props) => {



    const unit = JSON.parse(sessionStorage.getItem('locationData')!)
    const navigate = useNavigate()

    const newDate = studentSelected.birthDate?.toLocaleString().substring(0, 10).toString()

    const queryClient = useQueryClient();

    const [studentShow, setStudentShow] = useState<StudentView>()


    useEffect(()=>{
        StudentService.show({educationInstitution: unit.identifier, studentIdentifier: studentSelected.identifier }).then((res)=>{
            if(res.success){
                setStudentShow(res.data)
            }
        })

        queryClient.invalidateQueries(Queries.LIST_BNCC_YEARS)
    },[])

    function dateCorret(date: any) {

        // const day = date.substring(8, 10)
        // const mounth = date.substring(5, 7)
        // const year = date.substring(0, 4)

        // return day + mounth + year;
    }

    let curretDate = dateCorret(newDate)

    console.log(studentShow)
    const [switchForm, setSwitchForm] = useState(1)
    const [name, setName] = useState(studentSelected.name)
    console.log(name)
    const [enrollment, setEnrollment] = useState(studentShow ? studentShow.name : "")
    const [cpf, setCpf] = useState(studentShow ? studentShow.cpf: "")
    const [date, setDate] = useState(curretDate)
    const [stageId, setStageId] = useState("")
    const [stages, setStages] = useState<EducationStage[]>([])
    const [classes, setClasses] = useState<Class[]>([])
    const { data: listAcademicYears, isLoading: loadingAcademicYears } =
        useListBNCCYears("","");

    const preSelectedStage: any = stages.find((item: any) => item.identifier === studentSelected.educationStage?.identifier)

    


    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);

    const handleChange1 = () => {
        setCheck1(true)
        if (check1 === true) {
            setCheck1(false)
        }
        setCheck2(false)
    }
    const handleChange2 = () => {
        setCheck1(false)
        setCheck2(true)
        if (check2 === true) {
            setCheck2(false)
        }
    }

    function handleStageId(id: string) {
        setStageId(id)
    }



    

    const stagesOrdened = stages.slice().sort((a, b) => a.name.localeCompare(b.name))

    const classesOrdened = classes.slice().sort((a, b) => a.name.localeCompare(b.name))

    // const stageValue = stagesOrdened.filter((item: any)=> item.identifier === studentSelected.educationStage?.identifier)
    // console.log(stageValue)

    function formatarData(input: any) {
        // Verifica se a string de entrada possui o formato esperado
        if(!input){return}
        if (input.length !== 8 || isNaN(input)) {
            return null;
        }

        // Extrai os componentes da data (dia, mês, ano)
        var dia = input.substring(0, 2);
        var mes = input.substring(2, 4);
        var ano = input.substring(4, 8);

        // Cria a string formatada no novo formato
        var dataFormatada = ano + "/" + mes + "/" + dia;
        // var dataFormatada = ""

        return dataFormatada;
    }
    const dateForVerification = formatarData(date)

    function returnAgeStudent(input: any) {
        const age = new Date(new Date().getTime() - new Date(input).getTime()).getUTCFullYear() - 1970

        return age;

    }
    const age = returnAgeStudent(dateForVerification)


    const [nameResponsible, setNameResponsible] = useState<string>("")
    const [phoneResponsible, setPhoneResponsible] = useState<string>("")
    const [emailResponsible, setEmailResponsible] = useState<string>("")
    const [confirmEmailResponsible, setConfirmEmailResponsible] = useState<string>("")

    const dataStudent: any = {
        identifier: studentSelected.identifier,
        enrollment: enrollment,
        cpf: cpf,
        name: name,
        year_id: stageId,
        birth_date: dateForVerification
    }

    function verification(){
        if(name === ""){
            alert("Preencha o nome.")
        }else if(stageId === ""){
            alert("Selecione uma etapa.")
        }else{
            setSwitchForm(2)
        }
    }

    return (
        <Form>
            <Divider>
                <Wizard>
                    {switchForm === 1 ? (
                        <WizardStep text="Estudante" active />
                    ) : (
                        <WizardStep text="Estudante" />
                    )}

                    {switchForm === 2 ? (
                        <WizardStep text="Revisar" active />
                    ) : (
                        <WizardStep text="Revisar" />
                    )}
                </Wizard>
            </Divider>
            {switchForm === 1 && (
                <Form>
                    <Divider>
                        <Input
                            type="text"
                            label="Nome"
                            flex="fx100"
                            value={name}
                            onChange={setName}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            type="cpf"
                            label="CPF"
                            flex="fx33"
                            value={cpf}
                            onChange={setCpf}
                        />
                        <Input
                            type="date"
                            label="Data de nascimento"
                            flex="fx33"
                            value={date}
                            onChange={setDate}
                        />
                        <Input
                            type="text"
                            label="Matrícula"
                            flex="fx33"
                            value={enrollment}
                            onChange={setEnrollment}
                        />
                    </Divider>
                    <Divider>
                        <DivFlex>
                            <p>Possui alguma necessidade especial?</p>
                            <input
                                type="checkbox"
                                checked={check1}
                                onChange={handleChange1}
                                className='checks'
                            />
                            <label>Sim</label>
                            <input
                                type="checkbox"
                                checked={check2}
                                onChange={handleChange2}
                                className='checks'
                            />
                            <label>Não</label>
                        </DivFlex>
                    </Divider>
                    {/* {check1
                        ?
                        <EspecialNeedComponent />
                        :
                        <></>
                    } */}
                    <Divider>
                        <ReactSelect
                            flex='fx50'
                            options={listAcademicYears}
                            getOptionLabel={(e) => `${e.year} ${e.identifier.substring(0, 2) === "EM" ? "(Ensino Médio)" : " "}`}
                            label='Etapa'
                            noOptionsMessage='Não encontrado'
                            placeholder='Selecione uma etapa...'
                            onChange={(e: any) => handleStageId(e.identifier)}
                            isLoading={loadingAcademicYears}
                        />
                        {/* <ReactSelect
                            flex='fx50'
                            options={classesOrdened}
                            placeholder='Selecione uma turma...'
                            label=        
                            'Turmas'
                            noOptionsMessage='Não há turmas cadastradas'
                            getOptionLabel={(e: any) => e.name}
                            onChange={(e: any) => { }}
                        /> */}
                    </Divider>
                    <ComponentResponsible
                        idade={age}
                        confirmEmail={confirmEmailResponsible}
                        email={emailResponsible}
                        name={nameResponsible}
                        phone={phoneResponsible}
                        setConfirmEmail={setConfirmEmailResponsible}
                        setEmail={setEmailResponsible}
                        setName={setNameResponsible}
                        setPhone={setPhoneResponsible}
                    />
                    <br />
                    <Controllers
                        enableNext={true}
                        enablePrev={false}
                        functionCancel={() => window.location.reload()}
                        functionNext={() => verification()}
                    />
                </Form>
            )}

            {switchForm === 2 && (
                <ReviewStudentEdit
                    data={dataStudent}
                    setSwitchForm={setSwitchForm}
                    unit={unit}
                />
            )}
        </Form>
    )
}

export default NewFormStudentEdit