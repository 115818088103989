import styled from "styled-components";
import { string } from "zod";

interface GetBackgroundProps {
  $backgroundColor: string;
}

export const ContInputExpo = styled.div<GetBackgroundProps>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  height: auto;
  min-height: 40px;
  border-radius: 10px 0px 0px 10px;
  background-color: ${(props) => props.$backgroundColor};
  margin: 5px;

  padding: 0.5rem 0;
  gap: 0.5rem;
  input {
    margin-left: 10px;
    cursor: pointer;
  }

  p {
    text-align: left !important;
    color: white;
    margin: 0;
  }
`;
