import React, { useEffect, useState } from 'react'
import { Container, Datas } from '../styles'
import { ProfileDatas } from '../../../../../Components/ProfileDatas'
import StudentService from '../../../../../Services/Student.service'
import { StudentView } from '../../../../../types/StudentsTypes/typeStudents'

interface Props {
    back: () => void
    edit: () => void
    idStudent: string
    unit: any
}

const ProfileStudent = ({ back, edit, idStudent, unit }: Props) => {

    const [studentData, setStudentData] = useState<StudentView>()

    useEffect(()=>{

        StudentService.show({educationInstitution: unit.identifier, studentIdentifier: idStudent}).then((res)=>{
            if(res.success){
                setStudentData(res.data)
            }
        })


    },[])

    return (
        <Container>
            <ProfileDatas
                page="Estudante"
                onBack={() => back()}
                onEdit={() => edit()}
                labelSingle="Usuário"
                labelPlural="Usuários"
            />
            <Datas>
                <main>
                    <p>Cliente: Arka Digital S/A</p>
                    <p>Unidade Educacional: {unit.name}</p>
                    <p>Turma: A15</p>
                    <span></span>
                    <p>Matrícula: {studentData?.enrollment}</p>
                    <p>Nome: {studentData?.name}</p>
                    <p>Data de Nascimento: {studentData?.birth_date}</p>
                    <span></span>
                    <p>Necessidades Especiais: Nenhuma</p>
                    <p>Idade equivalente: 00</p>
                    <p>Nível de Ensino: </p>
                    <p>Etapa: {studentData?.year_id}</p>
                    <p>Fase: 7</p>
                    <p>Ano/Faixa etária: 14</p>
                    {/* <span></span>
              <p>Responsável(eis):</p>
              {
                studentsData.guardians.length !== 0
                  ?
                  studentsData.guardians.map((guardian: any) => {
                    return (
                      <>
                        <p>{guardian.user.name}</p>
                      </>
                    )
                  })
                  : <></>
              } */}
                </main>
            </Datas>
        </Container>
    )
}

export default ProfileStudent
