import { ArrowDownCircle, CheckCircle2 } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useListBNCC } from "../hooks/useListBNCC";
import { Container, ContainerSelect, DividerNew, Line, Row } from "../styles";
import { FormElementarySchool } from "./Subforms/FormElementarySchool";
import { FormHighSchool } from "./Subforms/FormHighSchool";
import { Column, DivColumnFlexStart, Title } from "./Subforms/styles";
import FormInfantil from "./Subforms/FormInfantil";
import SelectedFields from "./components/SelectedFields";
import { Div } from "./style";
import { ShimmerLine } from "../../../../../Components/Shimmers";
import { Divider } from "../../../../../Components/Form/Divider";
import { EnumStepNewResource, ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import { basicCompetencesBNCC } from "../../../../../Utils/Const";
import CheckBoxBNCC from "../../../../../Components/CheckBoxBNCC";
import { WizardStepNew } from "../../../../../Components/WizardStepNew";
import { Controllers } from "../../../../../Components/Form/Controllers";


const enum OptionsStep {
  EDUCACAO_INFANTIL,
  FUNDAMENTAL,
  MEDIO,
}
function ShimmerContainerForm2() {
  return (
    <div>
      <Row>
        <div className="item">
          <Column>
            <span className="title">
              FASE: <ShimmerLine />
            </span>
            <span className="sub-title">
              ANO:
              <ShimmerLine />
            </span>
            <ul className="row gap mt">
              <li>
                <ShimmerLine />
                <div className="flex-column">
                  <ShimmerLine />
                  <ShimmerLine />
                </div>
              </li>
            </ul>
          </Column>
        </div>
        <div className="item">
          <Column>
            <span className="title">
              FASE: <ShimmerLine />
            </span>
            <span className="sub-title">
              ANO:
              <ShimmerLine />
            </span>
            <ul className="row gap mt">
              <li>
                <ShimmerLine />
                <div className="flex-column">
                  <ShimmerLine />
                  <ShimmerLine />
                </div>
              </li>
            </ul>
          </Column>
        </div>
      </Row>
      <Line $margin="0.5rem 0" />
      <Divider>
        <ShimmerLine />
      </Divider>
      <ShimmerLine /> <ShimmerLine /> <ShimmerLine />
      <Line $margin="0.5rem 0" />
      <Divider>
        <DivColumnFlexStart $width="40%">
          <ShimmerLine />
          <ShimmerLine />
          <ShimmerLine />
        </DivColumnFlexStart>
        <DivColumnFlexStart $width="60%">
          <ShimmerLine />
        </DivColumnFlexStart>
      </Divider>
      <div />
    </div>
  );
}
interface FactoryFormProps {
  switchForm: OptionsStep;
  isLoading: boolean;
}
function FactoryForm({ switchForm, isLoading }: FactoryFormProps) {
  if (isLoading) {
    return <ShimmerContainerForm2 />;
  }
  switch (switchForm) {
    case OptionsStep.EDUCACAO_INFANTIL:
      return <FormInfantil />;
    case OptionsStep.FUNDAMENTAL:
      return <FormElementarySchool />;
    case OptionsStep.MEDIO:
      return <FormHighSchool />;
    default:
      return null;
  }
}
export default function Form2() {
  const {
    handleStep,
    addBNCCBasicComponentResource,
    removeBNCCBasicComponentResource,
    resource,
  } = useContext(ResourceContext);
  const { data, isLoading } = useListBNCC();
  const navigate = useNavigate();
  const [isVisibleSkills, setIsVisibleSkill] = useState(false);
  const [switchForm, setSwitchForm] = useState<OptionsStep>(
    OptionsStep.EDUCACAO_INFANTIL
  );


  function nextStep() {
    handleStep(EnumStepNewResource.TAGS);
  }
  function previousStep() {
    handleStep(EnumStepNewResource.INFORMACAO);
  }

  function cancel() {
    navigate(-1);
  }

  function handleVisibilitySkill() {
    setIsVisibleSkill((state) => !state);
  }

  function handleBasicComponentResource(id: number, selected: boolean) {
    if (selected) {
      addBNCCBasicComponentResource(id);
    } else {
      removeBNCCBasicComponentResource(id);
    }
  }

  return (
    <Container>
      <Title>NÍVEL: Educação Básica</Title>
      <Line $margin="0.5rem 0 0 0" />
      <Div>
        <Title>Competências Gerais da Educação Básica</Title>

        <ArrowDownCircle
          color="white"
          fill="#fe8f2e"
          size={25}
          className={`icon-arrow ${isVisibleSkills ? "up" : "down"}`}
          onClick={handleVisibilitySkill}
        />
        {isVisibleSkills === false && (
          <SelectedFields basicCompetences={resource.bncc.basic_competences} />
        )}
      </Div>
      {isVisibleSkills ? (
        <ContainerSelect>
          {basicCompetencesBNCC.map((item) => {
            const selected = resource.bncc.basic_competences.includes(
              item.number
            );
            return (
              <CheckBoxBNCC
                checked={selected}
                onChange={() => {
                  handleBasicComponentResource(item.number, !selected);
                }}
                number={item.number}
                text={item.text}
                title={item.title}
              />
            );
          })}
        </ContainerSelect>
      ) : null}
      <Line $margin="0 0 0.5rem 0" />
      <DividerNew>
        <b>ETAPA:</b>

        <WizardStepNew
          button={true}
          text="EDUCAÇÃO INFANTIL"
          active={switchForm >= OptionsStep.EDUCACAO_INFANTIL}
          Icon={
            switchForm > OptionsStep.EDUCACAO_INFANTIL ? (
              <CheckCircle2 color="white" size={25} />
            ) : undefined
          }
          onClick={() => {
            setSwitchForm(OptionsStep.EDUCACAO_INFANTIL);
          }}
        />

        <WizardStepNew
          button={true}
          color="#1B73C3"
          text="ENSINO FUNDAMENTAL"
          Icon={
            switchForm > OptionsStep.FUNDAMENTAL ? (
              <CheckCircle2 color="white" size={25} />
            ) : undefined
          }
          active={switchForm >= OptionsStep.FUNDAMENTAL}
          onClick={() => {
            setSwitchForm(OptionsStep.FUNDAMENTAL);
          }}
        />

        <WizardStepNew
          button={true}
          color="#DBA418"
          text="ENSINO MÉDIO"
          active={switchForm >= OptionsStep.MEDIO}
          onClick={() => {
            setSwitchForm(OptionsStep.MEDIO);
          }}
        />
      </DividerNew>
      <br />
      <FactoryForm switchForm={switchForm} isLoading={isLoading} />

      <Controllers
        functionNext={nextStep}
        functionPrev={previousStep}
        functionCancel={cancel}
        enableNext={true}
        enablePrev={true}
      />
    </Container>
  );
}
