import { Footer, Header } from "../styles";
import { Container } from "./styles";
import { Access } from "../../Access";

interface ViewProps {
    back: any,
    editViewMode: string,
}

export function ViewCustomFunctions({ back, editViewMode }: ViewProps) {
    return (
        <Container>
            <Header>
                <div className="text-area">
                    <div className="text">
                        <label>Nome (Função / Cargo)</label>
                        <p>Loren Ipsum</p>
                    </div>
                    <div className="text">
                        <label htmlFor="">Descrição</label>
                        <p>Lorem Ipsum is simply dummy text of the printing and
                            typesetting industry. Lorem Ipsum has been the industry's
                            standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy
                            text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s.
                        </p>
                    </div>
                </div>
            </Header>

            <Access
                editViewMode={editViewMode}
            />

            <Footer>
                <span></span>
                <footer>
                    <button style={{ backgroundColor: "#088A62" }} onClick={back}>Voltar</button>
                </footer>
            </Footer>

        </Container>
    )
}