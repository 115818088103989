import axios from 'axios'
import { useState } from 'react'
import { Controllers } from '../../../../Components/Form/Controllers'
import { Divider } from '../../../../Components/Form/Divider'
import { Input } from '../../../../Components/Form/Input'
import { Form, Upload } from '../styles'
import { useNavigate } from 'react-router-dom'

export function Form1(props: any) {
  const [cepValue, setCepValue] = useState('')
  const [bairroValue, setBairroValue] = useState('')
  const [complementoValue, setComplementoValue] = useState('')
  const [cidadeValue, setCidadeValue] = useState('')
  const [ruaValue, setRuaValue] = useState('')
  const [ufValue, setUfValue] = useState('')

  const navigate = useNavigate()

  function handlePreview(e: any) {
    if (!e.target.files) return
    props.setImg(e.target.files[0])
  }

  async function handleCep(n: any) {
    props.changeZipCode(n)
    const number = n.replace(/\D/g, '')
    if (number.length === 8) {
      axios.get(`https://viacep.com.br/ws/${n}/json/`).then((response) => {
        props.changeNeighborhood(response.data.bairro)
        props.changeComplement(response.data.complemento)
        props.changeCity(response.data.localidade)
        props.changeAddress(response.data.logradouro)
        props.changeState(response.data.uf)
      })
    }
  }

  function handleVerifyNext() {
    if (
      props.socialName === '' ||
      props.cnpj === '' ||
      props.phone === '' ||
      props.email === '' ||
      props.address === '' ||
      props.neighborhood === '' ||
      props.city === '' ||
      props.state === '' ||
      props.number === ''
    ) {
      alert("Preencha os campos obrigatórios marcados com '*'")
      return
    }
    if (props.email !== props.email2) {
      alert('Os e-mails não são iguais')
      return
    }
    props.next()
  }

  return (
    <Form>
      <Input
        flex="fx100"
        label="Razão Social*"
        type="text"
        inputId="razaosocial"
        value={props.socialName}
        onChange={(e: any) => props.changeSocialName(e)}
      />
      <Divider>
        <Input
          flex="fx50"
          type="cnpj"
          label="CNPJ*"
          inputId="cnpjid"
          value={props.cnpj}
          onChange={(v: any) => props.changeCnpj(v)}
        />
        <Input
          flex="fx50"
          type="tel"
          label="Telefone*"
          inputId="telefoneid"
          value={props.phone}
          onChange={(v: any) => props.changePhone(v)}
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="E-mail*"
          inputId="emailid"
          type="email"
          value={props.email}
          onChange={(v: any) => props.changeEmail(v)}
        />
        <Input
          flex="fx50"
          label="Confirmar e-mail*"
          inputId="confirmemailid"
          type="email"
          value={props.email2}
          onChange={(v: any) => props.changeEmail2(v)}
        />
      </Divider>
      <Divider>
        <Input
          flex="fx15"
          type="cep"
          label="CEP"
          inputId="cepid"
          value={props.zipCode}
          onChange={(v: any) => handleCep(v)}
        />
        <Input
          flex="fx80"
          label="Rua/Avenida*"
          inputId="ruaavenidaid"
          value={props.address}
          type="text"
          onChange={(v: any) => props.changeAddress(v)}
        />
        <Input
          required
          flex="fx10"
          label="Número"
          type="quantity"
          inputId="numeroid"
          value={props.number}
          onChange={(v: any) => props.changeNumber(v)}
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Complemento"
          inputId="complementoid"
          type="text"
          value={props.complement}
          onChange={(v: any) => props.changeComplement(v)}
        />
        <Input
          flex="fx50"
          label="Bairro*"
          type="text"
          inputId="bairroid"
          value={props.neighborhood}
          onChange={(v: any) => props.changeNeighborhood(v)}
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Cidade*"
          inputId="cidadeid"
          type="text"
          value={props.city}
          onChange={(v: any) => props.changeCity(v)}
        /> 
        <Input
          flex="fx50"
          label="Estado*"
          inputId="estadoid"
          type="text"
          value={props.state}
          onChange={(v: any) => props.changeState(v)}
        />
      </Divider>
      <Controllers
        functionNext={() => 
          handleVerifyNext()
        }
        functionCancel={()=>{
          navigate('/clientes/list')
        }}
        enableNext={true}
        enablePrev={false}
      />
      <Upload>
        <label htmlFor="uploadInput">
          {props.img === '' ? (
            <p>Inserir logo</p>
          ) : (
            <img src={URL.createObjectURL(props.img)} alt="" />
          )}
        </label>
        <input
          type="file"
          name=""
          id="uploadInput"
          onChange={(e) => handlePreview(e)}
        />
      </Upload>
    </Form>
  )
}
