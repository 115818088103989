import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;

  &:before {
    content: "";
    border: 32px solid #f3f3f3;  // Ajuste esses valores para mudar o tamanho do spinner
    border-top: 32px solid #3498db;
    border-radius: 50%;
    width: 240px;   // Ajuste esses valores para mudar o tamanho do spinner
    height: 240px;
    animation: ${spin} 2s linear infinite;
  }
`;
