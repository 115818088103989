import styled from "styled-components";

export const ContainerCategories = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 5px;
    width: 800px;
    min-height: 70px;
    color: #646464;
`

export const ContainerCategorie = styled.div`
    display: flex;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    min-height: 70px;

    .container-title{
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        min-height: 70px;
        min-width: 40%;
        background-color: #D4D4DE;
        border-radius: 15px;
    }

    .cont-sub{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        flex-wrap: nowrap;
        width: 100%;
        gap: 2px; 
    }

    .div-level {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1;
        height: 100%;
        gap: 2px; 
    }
`
export const ContainerLevelOne = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 70px;
    flex: 1;
    background-color: #E9E9E9;
    border-radius: 15px;
    gap: 10px;
    align-self: normal;
`