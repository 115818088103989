import {
  CreateSuccess,
  Forbidden,
  ServiceError,
  Success,
  SuccessData,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { Class } from "../Utils/Types";
import { api } from "./api";

type UnidadeEditProps = {
  name: string;
  phone: string;
  email: string;
  zip_code: string;
  address: string;
  number: string;
  complement?: string;
  neighborhood: string;
  city: string;
  state: string;
};



type ClienteCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type UnitImportResponse =
  CreateSuccess |
  Unauthorized |
  Forbidden |
  UnprocessableContent;

type UnidadeListResponse =
  Success |
  Unauthorized |
  ServiceError;

type UnitShowProps = {
  idUnit: string,
}

type UnitShowResponse = SuccessData<any> | Unauthorized | Forbidden;

type ListEmployeesByUnitProps = {
  idUnit: string
}

type ListEmployeesBYUnitResponse =
  Success | Unauthorized | Forbidden | ServiceError;

type ListAllUnitResponse = Success | Unauthorized | Forbidden | ServiceError;

class UnidadeService {

  static async listAllUnit(): Promise<ListAllUnitResponse> {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<ListAllUnitResponse>(`/client/education-institutions`, config)

      switch (response.status) {
        case 200:
          return { data: response.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not list all units.", error)
    }
  }


  static async listEmploeesByUnit(input: ListEmployeesByUnitProps): Promise<ListEmployeesBYUnitResponse> {

    const { idUnit } = input

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/education-institutions/${idUnit}/employees`,
        config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not list employees.")
    }
  }

  static async showUnit(input: UnitShowProps): Promise<UnitShowResponse> {
    const { idUnit } = input;

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<UnitShowResponse>(`/admin/education-institutions/${idUnit}`,
        config
      )

      return response.data;

    } catch (error: any) {
      throw new Error("Can not show Unit.");
    }
  }


  static async put(data: UnidadeEditProps, idSchool: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.put<ClienteCreateResponse>(
        `/admin/education-institutions/${idSchool}`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async post(data: UnidadeEditProps) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<ClienteCreateResponse>(
        `/admin/education-institutions`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async postImport(data: FormData) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.post<UnitImportResponse>(
        `/admin/education-institutions/import`,
        data,
        config
      )

      return response.data;

    } catch (error: any) {
      const errorResponse = error.response.data;
      const errorMessages = [];
      for (const key in errorResponse.errors) {
        errorMessages.push(errorResponse.errors[key] + "\n");
      }
      alert(errorMessages);
    }
  }



  static async list(): Promise<UnidadeListResponse> {

    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    }

    try {
      const response = await api.get(
        `/admin/education-institutions`,
        config
      )
      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }
    } catch (error: any) {
      throw new Error("Can not List Units.")
    }

  }
}

export default UnidadeService;
