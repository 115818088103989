import { useQuery } from "react-query";
import { Queries } from "../../../../types/queries";
import CollectionService, {
  ICollection,
} from "../../../../Services/Collection.service";

/**
 * 
 * @returns Lista Coleções
 */
export function useListSignatures() {
  return useQuery<ICollection[]>(
    Queries.LIST_COLLECTION,
    () => CollectionService.listCollection(),
    {
      cacheTime: 5,
      retry: false,
    }
  );
}
