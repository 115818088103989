import styled from 'styled-components'


export const DivTag = styled.div`
display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
height: 40px;
border-bottom: 1px solid black;
margin-bottom: 10px;

input{
    margin: 10px;
}

.div-tag{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 50%;
    height: 100%;
    background-color: ${props => props.color || 'white'};
    border-top: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-radius: 8px 8px 0px 0px;
    cursor: pointer;
    button{
        background: none;
        border: none;
        margin: 10px;
        cursor: pointer;
    }
}

.icon-action {
    display: flex;
    width: 50%;
    justify-content: flex-end;
    gap: 5px;
    button{
        cursor: pointer;
        background: none;
        border: none;
    }
}


button.callList2{
      background-color: var(--yellow);
      color: #fff;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

.p-text{
    margin: 10px;
    text-transform: uppercase;
    color: black;
}

`

export const DivShowSeach = styled.div`

display: flex;
justify-content: space-evenly;
align-items: center;

button{
    border: none;
    background: none;
    margin-top: 25px;
    &:hover{
        cursor: pointer;
        transform: scale(1.2);
    }
}
`

export const DivListCategory = styled.div`
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
overflow: scroll;
width: 100%;
height: 460px;

    ul{
        display: flex;
        width: 100%;
        li{
            list-style: none;
        }
        .li-action{
            display: flex;
            flex: 1;
            width: 100%;
            justify-content: flex-end;
            margin-right: 10px;
            gap: 5px;
            cursor: pointer;
        }
    }
`

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 5px;

    p{
        color: rgba(0, 0, 0, 0.6);
    }

    textarea{
        border-radius: 15px;
        border: 1px solid black;
        width: 100%;
        padding-left: 10px;
        padding-top:10px;
        height: 90px;
    }
`

export const IconAtion = styled.div`
`