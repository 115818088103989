import React, { Dispatch, SetStateAction } from 'react'
import { Divider } from '../../../../../Components/Form/Divider'
import { Input } from '../../../../../Components/Form/Input'
import { Select } from '../../../../../Components/Form/Select'
import { SpecialNeedType } from '../NewFormStudent'

interface Props {
    visualImpairment?: SpecialNeedType[],
    hearingImpairment?: SpecialNeedType[],
    setSpecialNeeds: Dispatch<SetStateAction<string[]>>
    specialNeeds: string[]
    otherNeeds: string,
    setOtherNeeds: Dispatch<SetStateAction<string>>
    equivalentAge?: number,
    setEquivalentAge: Dispatch<SetStateAction<number>>
}


const EspecialNeedComponent = ({ hearingImpairment, setSpecialNeeds, visualImpairment, specialNeeds, otherNeeds, setOtherNeeds, equivalentAge, setEquivalentAge }: Props) => {

    function handleIds(id: any) {
        if (specialNeeds.length >= 2) {
            setSpecialNeeds([])
            setSpecialNeeds((state) => {
                return [...state, id]
            })
        } else if (id === "") {
            return
        } else {
            setSpecialNeeds((state) => {
                return [...state, id]
            })
        }
    }



    return (
        <>
            <Divider>
                <Select
                    flex="fx50"
                    label="Deficiência Visual"
                    onChange={handleIds}
                >
                    <option value="">Nenhuma</option>
                    {visualImpairment?.map((impairment) => {
                        return (
                            <option value={impairment.identifier}>{impairment.description}</option>
                        )
                    })}
                </Select>
                <Select
                    flex="fx50"
                    label="Deficiência Auditiva"
                    onChange={handleIds}
                >
                    <option value="">Nenhuma</option>
                    {hearingImpairment?.map((impairment) => {
                        return (
                            <option value={impairment.identifier}>{impairment.description}</option>
                        )
                    })}
                </Select>
            </Divider>
            <Divider>
                <Input
                    flex="fx65"
                    type="text"
                    label="Se possui outra deficiência, digite no campo abaixo"
                    value={otherNeeds}
                    onChange={setOtherNeeds}
                />
                <Input
                    flex="fx35"
                    label="Idade Equivalente"
                    type="quantity"
                    onChange={setEquivalentAge}
                    value={equivalentAge}
                />
            </Divider>
        </>
    )
}

export default EspecialNeedComponent
