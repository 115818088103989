import { useState } from "react";
import { BoxResume } from "../../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../../Components/Form/Controllers";
import { ModalShort } from "../../../../../Components/Modals/ModalShort";
import { Form } from "../../../../Registration/ClientRegistration/styles";
import { api } from "../../../../../Services/api";
import ClienteService from "../../../../../Services/Cliente.service";
import PlanoService from "../../../../../Services/Planos.service";
import { Tables } from "../SelectTable/styles";

export function Form3Edit(props: any) {
  const [modalSave, setModalSave] = useState(false);

  const saveClient = () => {
    let packages: string[] = props.selectedPackage.map((item: any) => {
      return item[0].identifier;
    });
    let collections: string[] = props.selectedCollection.map((item: any) => {
      return item[0].identifier;
    });
    const token = `bearer ${localStorage.getItem("token")}`;

    const initial = {
      _method: "PUT",

      social_name: props.socialName,
      cnpj: props.cnpj,
      phone: props.phone,
      email: props.email,
      address: {
        address: props.address,
        number: props.number,
        complement: props.complement,
        neighborhood: props.neighborhood,
        city: props.city,
        state: props.state,
        zip_code: props.zipCode,
      },

      active: 1,
    };
    const data = props.img !== "" ? { ...initial, logo: props.img } : initial;

    const dataPlan = {
      max_students: props.studentsNumber,
      max_employees: props.teachersNumber,
      max_education_institutions: props.educationNumber,
      packages: packages,
      collections: collections,
    };
    console.table(dataPlan);
    PlanoService.put(dataPlan, props.selectedOne.identifier)
      .then((res) => {
        if (!res?.success) {
          throw new Error(res?.message);
        }
      })
      .catch();

    api
      .post(`/admin/clients/${props.selectedOne.identifier}`, data, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setModalSave(true);
        }
      })
      .catch((error) => {
        const errorResponse = error.response.data;
        const errorMessages = [];
        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      });
  };

  return (
    <Form>
      <ModalShort
        type="success"
        text="Cadastro salvo com sucesso"
        isOpen={modalSave}
        onRequestClose={() => props.next()}
        onConfirm={() => {
          props.next();
        }}
      />
      <BoxResume label="Verifique se os dados que você cadastrou estão corretos. Se estiver tudo ok clique no botão salvar ou então, clique no botão anterior para revisar os dados.">
        <tr className="divider">
          <td>
            <span>Cliente</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Razão Social:</span>
            <p>{props.socialName}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>CNPJ:</span>
            <p>{props.cnpj}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Telefone:</span>
            <p>{props.phone}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Email:</span>
            <p>{props.email}</p>
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Endereço</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>CEP:</span>
            <p>{props.zipCode}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Rua/Av:</span>
            <p>{props.address}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Número:</span>
            <p>{props.number}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Complemento:</span>
            <p>{props.complement}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Bairro:</span>
            <p>{props.neighborhood}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Cidade:</span>
            <p>{props.city}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Estado:</span>
            <p>{props.state}</p>
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Plano</span>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <Tables>
            <table>
          <thead>
            <td>Coleções</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedCollection.length>0? props.selectedCollection.map((col:any) => {
              return(
                <tr>
                  <td>{col[0].name}</td>
                </tr>
              )
            }): <tr><td>Nenhuma coleção selecionada</td></tr>}
            
          </tbody>
        </table>
        <table>
          <thead>
            <td>Pacotes</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedPackage.length>0? props.selectedPackage.map((pack:any) => {
              return(
                <tr>
                  <td>{pack[0].name}</td>
                </tr>
              )
            }): <tr><td>Nenhum pacote selecionado</td></tr>}
          </tbody>
        </table>
            </Tables>
          </td>
        </tr>
        <tr>
          <td>
            <span>Número de Usuários Docentes:</span>
            {<p>{props.teachersNumber}</p>}
          </td>
        </tr>
        <tr>
          <td>
            <span>Número de Usuários Estudantes:</span>
            <p>{props.studentsNumber}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Número de Unidades Educacionais:</span>
            <p>{props.educationNumber}</p>
          </td>
        </tr>
      </BoxResume>
      <Controllers
        save={true}
        functionCancel={props.onBack}
        functionPrev={() => props.prev()}
        functionSave={saveClient}
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  );
}
