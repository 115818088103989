import { useState } from 'react'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { Divider } from '../../../../../Components/Form/Divider'
import { Select } from '../../../../../Components/Form/Select'
import { ModalShort } from '../../../../../Components/Modals/ModalShort'
import { Form } from '../../../../Registration/UnitRegistration/styles'
import { api } from '../../../../../Services/api'
import Papa from 'papaparse'
import { useNavigate } from 'react-router-dom'


export function Form2(props: any) {
  const [modalSave, setModalSave] = useState(false);
  const [dataSendList, setDataSendList] = useState<any[]>([])
  const navigate = useNavigate();

  const [unidade, setUnidade] = useState([
    {
      Nome: '',
      Telefone: '',
      Email: '',
      Cep: '',
      Rua: '',
      Numero: '',
      Complemento: '',
      Bairro: '',
      Cidade: '',
      Estado: '',
    },
  ])


  const saveUnit = () => {
    const dataSend = {
      name: props.nameValue,
      phone: props.telValue,
      email: props.emailValue,
      zip_code: props.cepValue,
      address: props.ruaValue,
      number: props.numberValue,
      complement: props.complementoValue,
      neighborhood: props.bairroValue,
      city: props.cidadeValue,
      state: props.ufValue,
    }

    api.put('/admin/education-institutions/' + props.selected, dataSend, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }
    }).then(response => {
      if(response.status === 200) {
        setModalSave(true)
      }
    }).catch(error => {
      const errorResponse = error.response.data
      const errorMessages = [];
      for (const key in errorResponse.errors) {
        errorMessages.push(errorResponse.errors[key] + '\n')
      }
      alert(errorMessages)
    })
  }
  
  

  return (
    <Form>
      <ModalShort
        type="success"
        text="Edição salva com sucesso"
        isOpen={modalSave}
        onRequestClose={() => navigate('/unidade')}
        onConfirm={() => {
          navigate('/unidade')
        }}
      />
        <BoxResume>
          <tr>
            <td>
              <span>Nome da Unidade Educacional:</span>
              <p>{props.nameValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Telefone:</span>
              <p>{props.telValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Email:</span>
              <p>{props.emailValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>CEP:</span>
              <p>{props.cepValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Rua/Av:</span>
              <p>{props.ruaValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Número:</span>
              <p>{props.numberValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Complemento:</span>
              <p>{props.complementoValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Bairro:</span>
              <p>{props.bairroValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Cidade:</span>
              <p>{props.cidadeValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Estado:</span>
              <p>{props.ufValue}</p>
            </td>
          </tr>
        </BoxResume>
      <Controllers
        save={true}
        functionSave={() => saveUnit()}
        functionPrev={() => props.prev(1)}
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  )
}
