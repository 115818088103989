import styled from "styled-components";

interface StyledProps {
    $width?: string
    $color?: string
}

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 3px;
`

export const CheckboxContainer = styled.div<StyledProps>`
  position: relative;
  margin-bottom: -5px;
  .checked {
    ${Icon} {
      visibility: 'visible';
    }
    background: ${({ $color }) => $color ?? "blue"};
  }

  .noChecked {
    ${Icon} {
      visibility: 'hidden';
    }
    background: white;
  }
`

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  opacity: 0;
  position: absolute;
  width: 18px;
  height: 18px;
  left: -5px;
  cursor: pointer;
  top: -5px;
`

export const StyledCheckbox = styled.div<StyledProps>`
  display: inline-block;
  width: 14px;
  height: 14px;

  border-radius: 3px;
  margin-right: 0.5rem;
  transition: all 150ms;
  cursor: pointer;
  border: solid 1px ${({ $color }) => $color ?? "blue"};
  ${HiddenCheckbox}:checked + & {
    /* box-shadow: 0 0 0 3px ${({ $color }) => $color ?? "blue"};
     */
    border: solid 1px white;
  }
`


