import { useContext, useState } from "react";
import { Form1 } from "./Form1";
import Form2 from "./Form2/Form2";
// import Form3 from "./Form3/Form3";
import Form4 from "./Form4/Form4";
import { FormTutorial } from "./FormTutorial";
import { Container, Main } from "./styles";

// import { useBlocker } from "react-router-dom";
import { boolean } from "zod";

import FormDocuments from "./FormDocuments";
import { EnumStepNewResource, ResourceContext } from "../../../../Contexts/Form/ContextResource";
import { ResourceContextForm1Provider } from "../../../../Contexts/Form/ContextResouceForm1";
import { useCheckTime } from "../../../../Components/LoginFunctions/Refresh";
import { Wizard } from "../../../../Components/Wizard";
import { WizardClick } from "../../../../Components/WizardClick";


interface IFactory {
  stepActual: EnumStepNewResource;
}

function ResourceFactory({ stepActual }: IFactory) {
  switch (stepActual) {
    case EnumStepNewResource.INFORMACAO:
      return (
        <ResourceContextForm1Provider>
          <Form1 />
        </ResourceContextForm1Provider>
      );
    case EnumStepNewResource.BNCC:
      return <ResourceContextForm1Provider>
        <Form2 />
      </ResourceContextForm1Provider>
    case EnumStepNewResource.TAGS:
      return null;
    case EnumStepNewResource.POP:
      return (
        <Form4 />
      );
    case EnumStepNewResource.TUTORIAL:
      return <FormTutorial />;
    case EnumStepNewResource.DOCUMENTS:
      return <FormDocuments />;
    default:
      return <Form1 />;
  }
}
function ResourcesEdit(props: any) {
  useCheckTime();
  const { stepActual, handleStep } = useContext(ResourceContext);
  let [value, setValue] = useState(true);

  // Block navigating elsewhere when data has been entered into the input
  // let blocker = useBlocker(({ currentLocation, nextLocation }) => {
  //   const currentPath = currentLocation.pathname.split("/");
  //   const pathNameCorrect = currentPath.length >=2 && currentPath[1] === "recursos" && currentPath[2] === "registration";
  //   return value && currentLocation.pathname !== nextLocation.pathname && pathNameCorrect
  // });
  return (
      <Main $maxWidth="1000px">
        <Container>
          <Wizard className="center">
            <WizardClick
              button={true}
              color="#027294"
              text="Informações básicas
              "
              active={stepActual === EnumStepNewResource.INFORMACAO}
              onClick={() => {
                handleStep(EnumStepNewResource.INFORMACAO);
              }}
            />
            <WizardClick
              button={true}
              text="BNCC"
              active={stepActual === EnumStepNewResource.BNCC}
              color="#027294"
              onClick={() => {
                handleStep(EnumStepNewResource.BNCC);
              }}
            />
            <WizardClick
              button={true}
              color="#027294"
              text="Tags"
              active={stepActual === EnumStepNewResource.TAGS}
              onClick={() => {
                handleStep(EnumStepNewResource.TAGS);
              }}
            />
            <WizardClick
              button={true}
              color="#027294"
              text="POP"
              active={stepActual === EnumStepNewResource.POP}
              onClick={() => {
                handleStep(EnumStepNewResource.POP);
              }}
            />
            <WizardClick
              button={true}
              color="#027294"
              text="Anexos"
              active={stepActual === EnumStepNewResource.TUTORIAL}
              onClick={() => {
                handleStep(EnumStepNewResource.TUTORIAL);
              }}
            />
            <WizardClick
              button={true}
              color="#027294"
              text="Documentos"
              active={stepActual === EnumStepNewResource.DOCUMENTS}
              onClick={() => {
                handleStep(EnumStepNewResource.DOCUMENTS);
              }}
            />
          </Wizard>
          <div className="box">
            <ResourceFactory stepActual={stepActual} />
          </div>
        </Container>
      </Main>
 
  )
}

export default function ResourceEdit() {
  return (
    <ResourcesEdit />

  );
}
