import React from 'react'
import { Datas } from '../styles'

const TemplateResource = (props: any) => {
  return (
    <Datas>
      <main>
        <b>GABARITO</b>
      </main>
    </Datas>
  )
}

export default TemplateResource
