import { useEffect, useState } from "react";
import { Controllers } from "../../../../../Components/Form/Controllers";
import { Divider } from "../../../../../Components/Form/Divider";
import { Input } from "../../../../../Components/Form/Input";
import { api } from "../../../../../Services/api";
import { Form22 } from "../../../../Registration/ClientRegistration/styles";
import { SelectTableEdit } from "../SelectTable";

export function Form2Edit(props: any) {
  const handleTeachersNumber = (v: any) => {
    if (isNaN(v)) return;
    props.changeTeachersNumber(v);
  };

  const handleStudentsNumber = (v: any) => {
    if (isNaN(v)) return;
    props.changeStudentsNumber(v);
  };

  const handleEducationNumber = (v: any) => {
    if (isNaN(v)) return;
    props.changeEducationNumber(v);
  };

  const handleNext = () => {
    if (
      props.teachersNumber === 0 ||
      props.studentsNumber === 0 ||
      props.educationNumber === 0
    )
      alert("Preencha todos os campos");
    else props.next();
  };

  return (
    <Form22>
      <SelectTableEdit
        changeCollection={(v: any) => props.changeSelectedCollection(v)}
        changePackage={(v: any) => props.changeSelectedPackage(v)}
        selectedCollection={props.selectedCollection}
        selectedPackage={props.selectedPackage}
      />
      <Divider>
        <Input
          value={props.teachersNumber === 0 ? "" : props.teachersNumber}
          type="quantity"
          flex="fx50"
          label="Número de Usuários Docentes"
          inputId="euserdocentesid"
          onChange={(v: any) => handleTeachersNumber(v)}
        />
        <Input
          value={props.studentsNumber === 0 ? "" : props.studentsNumber}
          type="quantity"
          flex="fx50"
          label="Número de Usuários Estudantes"
          inputId="userestudantesid"
          onChange={(v: any) => handleStudentsNumber(v)}
        />
        <Input
          value={props.educationNumber === 0 ? "" : props.educationNumber}
          type="quantity"
          flex="fx50"
          label="Número de Unidades Educacionais"
          inputId="usereducacionaisid"
          onChange={(v: any) => handleEducationNumber(v)}
        />
      </Divider>
      <Controllers
        functionPrev={() => props.prev()}
        functionNext={() => handleNext()}
        enableNext={true}
        enablePrev={true}
        functionCancel={props.onBack}
      />
    </Form22>
  );
}
