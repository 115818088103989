import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
display: flex;
align-items: center;
justify-content: flex-end;
gap: 10px;

button{
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    text-transform: uppercase;
    font-weight: 700;
    color: #088A62;
    cursor: pointer;
    &:first-child{
        color: #FE8F2E;
    }
}
`;