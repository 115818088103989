import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 46px;
  align-items: center;
  margin-top: 40px;
  gap: 30px;

  .select-all{
    display: flex;
    width: 100%;
    gap: 10px;
    margin-left: 20px;

    p{
        font-size: 14px;
        color: #646464;
    }
  }

  .num-itens{
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;

    p{
        font-size: 14px;
        color: #646464;
    }
    .number{
        display: flex;
        width: 28px;
        height: 27px;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        color: #FFFFFF;
        background-color: #646464;
    }
}

.vertical-line{
    border-left: 1px solid #646464;
    height: 100%;
}

  footer{
    display: flex;
    justify-content: flex-end;
    gap: 20px;
          
        > button {
            height: 27px;
            width: 130px;
            gap: 2px;
            border-radius: 5px;
            border: none;
            background: #088A62;
            color: #fff;
            text-transform: uppercase;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
                &:first-child{
                    background-color: #027294;
                    width: 90px;
                }
                
                &:hover {
                    transform: scale(1.1);
                    transition: 0.3s;
                }
        }
    }
`

export const PrintTable = styled.div`
  position: absolute;
  top: -9999px;
  left: -9999px;
`
export const Datas = styled.section`
    display: flex;
    padding: 20px 40px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    
    h1{
        font-weight: 700;
        font-size: 20px;
    }

    .div-main{
        display: flex;
        gap: 20px;
    }

    aside {
        width: 120px;
        div{
        width: 120px;
        height: 120px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            height: 100%;
        }
        }
    }
    main {
        display: flex;
        flex-direction: column;
        gap: 3px;
        p{
        font-size: 16px;
        font-weight: 400;
        color: #646464;
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: flex-start;
        }
        span{
        display: block;
        height: 0.5px;
        width: 100%;
        background-color: #000000;
        }
        button.callList{
        background-color: var(--yellow);
        color: #fff;
        width: 18px;
        height: 18px;
        border: none;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        }
    }
`