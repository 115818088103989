import React from 'react'
import { Container } from '../ModalShort/styles'
import { CheckCircle } from 'lucide-react'

interface Props {
    isOpen: boolean
    type: string
    text?: string
    onRequestClose?: () => void
    onConfirm?: () => void
}


const ModalNew = ({ isOpen, type, onRequestClose, text, onConfirm }: Props) => {
    return (
        <>
            {isOpen && (
                <Container>
                    {type === 'success' && (
                        <div>
                            <CheckCircle size={40} color="#00CAD1" />
                            <p>{text}</p>
                            <button className="hvr-grow" onClick={onRequestClose}>
                                OK
                            </button>
                        </div>
                    )}
                    {type === 'confirmSuccess' && (
                        <div>
                            <CheckCircle size={40} color="#00CAD1" />
                            <p>{text}</p>
                            <div className='yes-no'>
                                <button className="hvr-grow" onClick={onRequestClose}>
                                    Não
                                </button>
                                <button className="hvr-grow" onClick={onConfirm}>
                                    Sim
                                </button>
                            </div>
                        </div>
                    )}
                    {type === "delete" && (
                        <div>
                            <CheckCircle size={40} color="#FF3737" />
                            <p className='red'>{text}</p>
                            <div className='yes-no'>
                                <button className="hvr-grow" onClick={onRequestClose}>
                                    Não
                                </button>
                                <button className="hvr-grow" onClick={onConfirm}>
                                    Sim
                                </button>
                            </div>
                        </div>
                    )}
                </Container>
            )}
        </>
    )
}

export default ModalNew
