import { PlusCircle } from 'lucide-react'
import { Input } from '../../../Components/Form/Input'
import { LeftMenu } from '../../../Components/LeftMenu'
import {
  Abas, Box, Container,
  ContainerNew,
  Controls,
  DividerNew,
  Main, Sitemap, Table,
} from './styles'
import { Fragment, useEffect, useState } from 'react'
import UnidadeService from '../../../Services/Unidade.service'
import StudentService from '../../../Services/Student.service'
import TurmaService from '../../../Services/Turma.service'
import { Select } from '../../../Components/Form/Select'
import { Divider } from '../../../Components/Form/Divider'
import { EtapaCreate } from '../../../Services/EtapaAcademica.service'
import { FormSearch } from './FormSearch'
import BNCCService from '../../../Services/BNCC.service'
import { Resume } from './Resume'
import { Controllers } from '../../../Components/Form/Controllers'

export function StudentTransfer() {
  const [switchForm, setSwitchForm] = useState(1);
  const [advancedSearch, setAdvancedSearch] = useState(true);
  const [inputSearch, setInputSearch] = useState(false);

  const [units, setUnits] = useState<any>([]);
  const [listStudents, setListStudents] = useState<any[]>([]);
  const [classes, setClasses] = useState<any>([]);

  const [studentsSelected, setStudentsSelected] = useState<any>([]);
  const [unitSelected, setUnitSelected] = useState<any>([]);
  const [classSelected, setClassSelected] = useState<any>();
  const [stageSelected, setStageSelected] = useState<any>([]);

  const [academicStage, setAcademicStage] = useState<EtapaCreate[]>([])
  const [inputSearchView, setInputSearchView] = useState("")
  const [studentFiltereds, setStudentFiltereds] = useState<any[]>([])

  const [error1, setError1] = useState(false)
  const [error2, setError2] = useState(false)
  const [error3, setError3] = useState(false)

  //listar unidades
  useEffect(() => {
    UnidadeService.list().then((response) => {
      if (response.success) {
        setUnits(response.data)
      }
    })

  }, [])

  // etapa
  useEffect(() => {
    BNCCService.listBNCCStages().then((response) => {
      if (response?.success) {
        setAcademicStage(response.data)
      }
    })
  }, [])

  //listar estudantes das unidades
  useEffect(() => {
    units.map((unit: any) =>
      StudentService.list({ unitId: unit.identifier }).then((response) => {
        if (response.success) {
          const { name, identifier } = unit;
          setListStudents((state: any) => {
            return [...state, { name, identifier, data: response.data }]
          })
        }
      })
    )
  }, [units])

  // Pesquisar estudante, filtro.
  useEffect(() => {
    let studentsForFilter = listStudents.flat()
    let filteredStudents = studentsForFilter.filter((item: any) => item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setStudentFiltereds(filteredStudents)
  }, [listStudents, inputSearchView])

  // listar turmas da unidade selecionada
  useEffect(() => {
    TurmaService.list({ idState: unitSelected.identifier }).then((response) => {
      if (response.success) {
        setClasses(response.data);
      }
    }).catch((e) => { });
  }, [unitSelected]);

  // filtrar turmas
  const [classFiltered, setClassFiltered] = useState([])
  useEffect(() => {
    let filteredClass = classes.filter((item: any) => item.stage_id === stageSelected)
    setClassFiltered(filteredClass)
  }, [stageSelected])

  // selecionar os alunos
  const toggleSelection = (student: any) => {
    setStudentsSelected((prevSelected: any) => {
      if (prevSelected.includes(student)) {
        return prevSelected.filter(
          (selelected: any) => selelected !== student
        );
      } else {
        return [...prevSelected, student];
      }
    });
  };

  // verificação
  const verifier = () => {
    switch (true) {
      case unitSelected == "":
        setError1(true)
        break;
      case stageSelected == "":
        setError2(true)
        break;
      case classSelected == undefined:
        setError3(true)
        break;
      default:
        setSwitchForm(2);
        break;
    }
  };

  // botão transfer
  const handleButtonClick = () => {
    verifier();
  };

  //modal search
  const handleButtonSearch = () => {
    setAdvancedSearch(false);
    setInputSearch(true);
  }

  // cancelar
  function back() {
    setSwitchForm(1)
  }

  return (
    <Container>
      <LeftMenu switchT={true} page={7} />
      <div className='box'>
        <Main>
          <Sitemap>
            <p>Transferencia</p> <span>&bull;</span> <p>Aluno</p>
          </Sitemap>
          <Abas>
            <button>
              Transferir Estudante
            </button>
          </Abas>

          {switchForm === 1 && (
            <>
              <Input
                type="search"
                label="Buscar Estudante"
                color="#039bc9"
                value={inputSearchView}
                onChange={setInputSearchView}
              />

              {advancedSearch && (
                <Controls>
                  <button className="hvr-icon-pop"
                    onClick={(e) => handleButtonSearch()}>
                    <PlusCircle className="hvr-icon" size={16} /> Busca Avançada
                  </button>
                </Controls>
              )}

              {inputSearch && (
                <FormSearch
                  setStudentFiltereds={setStudentFiltereds}
                  studentFiltereds={studentFiltereds}
                  setAdvancedSearch={setAdvancedSearch}
                  setInputSearch={setInputSearch}
                />
              )}

              <Box>
                <Table>
                  <p>LISTA DE ESTUDANTE(S) ENCONTRADO(S)</p>
                  <table>

                    <thead>
                      <tr>
                        <td className='header-cell'>Nome</td>
                        <td>Usuário</td>
                        <td>Unidade Educacional</td>
                        <td>Etapa</td>
                        <td>Nome da Turma</td>
                        <td>Status</td>
                      </tr>
                    </thead>

                    <tbody>
                      {studentFiltereds.map((unit: any, unitIndex: number) => (
                        <Fragment key={unitIndex}>
                          {unit.data.map((student: any, studentIndex: number) => (
                            <tr key={studentIndex}>
                              <td>
                                <input
                                  type="checkbox"
                                  name="student"
                                  id={`student-${studentIndex}`}
                                  checked={studentsSelected.includes(student)}
                                  onChange={() => toggleSelection(student)}
                                />
                                {student.name}
                              </td>
                              <td>{student.username ? student.username : "indefinido"}</td>
                              <td>{unit.name}</td>
                              <td>{student.educationStage ? student.educationStage.name : "indefinido"}</td>
                              <td>{student.classroom.length > 0 ? student.classroom : "indefinido."}</td>
                              <td>{student.active === 1 ? "Ativo" : "Inativo"}</td>
                            </tr>
                          ))}
                        </Fragment>
                      )
                      )}
                    </tbody>

                  </table>
                </Table>
              </Box>


              {studentsSelected.length > 0 && (

                <ContainerNew>
                  <div className='text'>
                    <p>Definir unidade educacional e turma</p>
                  </div>
                  <section>
                    <Select
                      error={error1}
                      flex=""
                      label="Nome da Unidade Educacional"
                      value={unitSelected ? unitSelected.identifier : ""}
                      onChange={(value: any) => {
                        const selectedUnit = units.find((item: any) => item.identifier === value);
                        setUnitSelected(selectedUnit)
                      }}
                    >
                      <option value="">Selecione a unidade</option>
                      {units.map((unit: any) => {
                        return (
                          <option key={unit.identifier} value={unit.identifier}>{unit.name}</option>
                        )
                      })}
                    </Select>

                    <DividerNew>
                      <Divider>
                        <Select
                          error={error2}
                          flex="fx50"
                          label="Etapa"
                          value={stageSelected}
                          onChange={(value: any) => setStageSelected(value)}
                        >
                          <option value="">Selecione uma Etapa</option>
                          {academicStage.map((stage) => {
                            return (
                              <option key={stage.identifier} value={stage.identifier}>{stage.name}</option>
                            )
                          })}
                        </Select>

                        <Select
                          error={error3}
                          flex="fx50"
                          label="Nome da Turma"
                          value={classSelected ? classSelected.identifier : ""}
                          onChange={(value: any) => {
                            const selectedClass = classFiltered.find((item: any) => item.identifier === value);
                            setClassSelected(selectedClass);
                          }}
                        >
                          <option value="">Selecione a turma</option>
                          {classFiltered.map((classe: any) => (
                            <option key={classe.identifier} value={classe.identifier}>
                              {classe.name}
                            </option>
                          ))}
                        </Select>

                      </Divider>
                    </DividerNew>
                  </section>
                </ContainerNew>

              )}

              <footer>
                <Controllers
                  functionCancel={() => window.location.reload()}
                  functionNext={() => handleButtonClick()}
                  enableNext={true}
                  enablePrev={false}
                />
              </footer>
            </>
          )}

          {switchForm === 2 && (
            <Resume
              studentsSelected={studentsSelected}
              studentFiltereds={studentFiltereds}
              classSelected={classSelected}
              stageSelected={stageSelected}
              unitSelected={unitSelected}
              back={back}
            />
          )}

        </Main>
      </div>
    </Container>
  )
}
