import { basicCompetencesBNCC } from "../../../../../Utils/Const";
import { IResource } from "../../../../../types/types";
import { Datas } from "../styles";
import ElementarySchool from "./EF/ElementaySchool";
import ChildEducation from "./EI/ChildEducation";

interface BnccProps {
  resource?: any;
}

const Bncc = ({ resource }: BnccProps) => {
  if (!resource) {
    return (
      <main>
        <span>Sem informações cadastradas</span>
      </main>
    );
  }

  const arraynull: any[] = []

  return (
    <Datas>
      <main>
        <b>BNCC</b>
        <p>Competências Gerais da Educação Básica:</p>
        {resource.bncc === arraynull ? 
        <>
        {resource.bncc?.basic_competences?.map((competence: any) => {
          const competenceObject = basicCompetencesBNCC[Number(competence) - 1];
          const name = `${competenceObject.title}: ${competenceObject.text}`;
          return (
            <ul style={{ listStyle: "none" }}>
              <li>
                <p>- {name}</p>
              </li>
            </ul>
          );
        })}
        <br />
        <ChildEducation resource={resource} />
        <br />
        <ElementarySchool resource={resource} />
        </>

        : "BNCC não informada."
      }
        </main>
    </Datas>
  );
};

export default Bncc;
