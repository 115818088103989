import React, { forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { Container, Label, TextAreaInput } from "./styles";

interface TextAreaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {

    type:
    | "type1";

    flex?: string;
    required: boolean;
    label: string;
    error?: FieldError | undefined;
    height?: string;
}

export const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (({ label, required, flex, error, height, type, ...props }, ref) => {
        return (
            <Container error={!!error} className={flex}>
                <Label error={!!error} htmlFor={props.id}>
                    {label}
                    {required ? "*" : ""}
                </Label>
                {type === "type1" && (
                    <TextAreaInput
                        $height={height}
                        {...props}
                    />
                )}
            </Container>
        )
    })
)