import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from "./style"

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    checked: boolean
    line?: boolean
    color?: string
  }
  
  const Checkbox =
    ({ checked, line = false, color, ...rest }: CheckboxProps) => {
      return (
        <CheckboxContainer $color={color}>
          <HiddenCheckbox {...rest} type="checkbox" checked={checked} />
          <StyledCheckbox
            $color={color}
            className={`${checked ? `checked` : `noChecked`}`}
          >
            <Icon viewBox={line ? '-2 -7 28 35' : '-2 0 28 35'}>
              <polyline points={line ? '20 6 3 6 ' : '20 6 9 17 4 12'} />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
      )
    }

export default Checkbox
