import styled from 'styled-components';

export const Controls = styled.div`
display: flex;
gap: 20px;
flex-direction: column;
align-items: flex-start;

width: 100%;

div {
    width: 100%;
}

button{
    font-size: 16px;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 700;
    color: #FE8F2E;
    cursor: pointer;
    &:last-child{
        color: #FE8F2E;
    }
}
`;