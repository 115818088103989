import { CheckCircle2 } from "lucide-react";
import { Container, Step } from "./style";

interface IWizardStep {
  text: string;
  active?: boolean;
  color?: string;
  center?: boolean;
  button?: boolean;
  onClick?: () => void;
  Icon?: JSX.Element;
}

export function WizardClick({
  text,
  active = false,
  onClick,
  color = "#088a62",
  center = false,
  button = false,
  Icon,
}: IWizardStep) {
  return (
    <Step
      $button={button}
      $center={center}
      $color={color}
      className={active ? "active" : ""}
      onClick={onClick}
    >
      <span>
        {Icon && Icon}
        {text}
      </span>
    </Step>
  );
}

export function WizardClickDiv(props: any) {
  return <Container>{props.children}</Container>;
}
