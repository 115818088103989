import styled from 'styled-components';


export const StyledColor = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  cursor: pointer;
  position: relative;
`;