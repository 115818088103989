import { Button } from './../../Pages/Registration/UserRegistration/FormTeacher/ImportExcel/styles';
import styled from 'styled-components';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;

h1{
    font-weight: 700;
    font-size: 20px;
}

a{
    background-color: #027294;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    border-radius: 5px;
    width: 176px;
    border: none;
    cursor: pointer;
    text-decoration: none;
}
button{
    background-color: #027294;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    border-radius: 5px;
    width: 176px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    &.list{
        background-color: transparent;
        color: #027294;
        border: 2px solid #027294;
    }
}
>div{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;

}
`;