import { api } from "./api";
import { AxiosError } from "axios";
import {
  CreateSuccessData,
  Forbidden,
  ServiceError,
  Success,
  SuccessData,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { EducationStage } from "../Utils/Types";

type EtapaCreateResponse =
  | CreateSuccessData<EducationStage>
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type EtapaGetResponse = SuccessData<EducationStage[]> | Unauthorized;

export interface EtapaCreate {
  identifier?: string;
  name?: string;
  description: string;
}

type StageShowProps = {
  identifier: string | undefined;
}
type StageShowResponse = Success | Unauthorized | Forbidden | ServiceError

class EtapaAcademicaService {

  static async show({ identifier }: StageShowProps): Promise<StageShowResponse> {
    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/education-stages/${identifier}`,
        config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not show Academic Stage.")
    }
  }


  static async get() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<EtapaGetResponse>(
        `/admin/education-stages`,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async post(data: EtapaCreate) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<EtapaCreateResponse>(
        `/admin/education-stages`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default EtapaAcademicaService;
