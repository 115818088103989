import React from 'react'
import { SubCategorysContainer } from './styles'
import LevelOne from '../SubcategoryComponent/LevelOne';
import { ICategoriesCollection } from '../../../../../../Services/Collection.service';

interface Props {
    category: ICategoriesCollection;
    collectionId: string;
}

const ComponentSubcategory = ({ category, collectionId }: Props) => {
    return (
        <SubCategorysContainer>
            {category.subcategories.map((sub) => {
                return (
                    <LevelOne
                        categoryId={category.identifier}
                        collectionId={collectionId}
                        subcategory={sub}
                    />
                )
            }
            )}
        </SubCategorysContainer>
    )
}

export default ComponentSubcategory