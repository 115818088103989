import { IBNCCCurricularComponent } from "../../../../../../../types/bncc";
import { IResource } from "../../../../../../../types/types";
import { ThematicUnit } from "./ThematicUnit";

interface CurricularComponentItemProps {
  resource: IResource;
  curricularComponent: IBNCCCurricularComponent;
}

export function CurricularComponent({
  resource,
  curricularComponent,
}: CurricularComponentItemProps) {
  console.log(resource);
  const thematicsUnits = curricularComponent.thematic_units.filter(
    (thematicUnit) =>
      resource.bncc?.ef.thematic_units?.includes(thematicUnit.name)
  );
  const hasThematicUnitComponents = Boolean(
    thematicsUnits && thematicsUnits.length > 0
  );
  return (
    <ul>
      <p>Componentes curriculares:</p>
      <h4> - {curricularComponent.name}</h4>
      {hasThematicUnitComponents
        ? thematicsUnits.map((thematicUnit) => (
            <ThematicUnit
              key={thematicUnit.name}
              resource={resource}
              thematicUnit={thematicUnit}
            />
          ))
        : null}
    </ul>
  );
}
