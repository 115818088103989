import React, { useContext, useEffect, useState } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'
import TargetAudienceService from '../../../../../../Services/TargetAudience.service'

interface ITargetAudience{
    identifier: string
    name: string
}


const TargetAudiences = () => {

    const { resource } = useContext(ResourceContext)
    const [target_audiences, setTarget_audiences] = useState<ITargetAudience[]>([])

    useEffect(()=>{
        TargetAudienceService.listTargets().then((res)=>{
            setTarget_audiences(res)
        })
    },[])



    return (
        <div style={{ marginTop: "20px" }}>
            <tr>
                <td>
                    <span>PÚBLICO(S) ALVO(S)</span>
                </td>
            </tr>


        </div>
    )
}

export default TargetAudiences