import React from 'react'
import { useQuery } from 'react-query'
import { Queries } from '../../../../../types/queries'
import BNCCService from '../../../../../Services/BNCC.service'
import { minutesToMilliseconds } from '../../../../../Utils/Functions'

export function useListEi () {
  return useQuery(Queries.LIST_BNCC_EI, BNCCService.listBNCCEi, {
    cacheTime: minutesToMilliseconds(5),
    staleTime: minutesToMilliseconds(5),
    retry: false,
    retryDelay(failureCount, error) {
    
      return 1000;
    },
  });
}
