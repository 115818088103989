import { forwardRef, useImperativeHandle, useState } from "react";
import { createPortal } from "react-dom";
import { ModalBackdrop, ModalBox, ModalBoxStyle, ModalWrapper } from "./style";

const modalRoot: any = document.getElementById("modal-root");
export type ModalRef = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
};

export type ModalProps = {
  // outros props do componente
  styleDefault?: boolean;
};
const Modal = forwardRef<ModalRef, any>((props: any, ref) => {
  const {
    title,
    subTitle,
    none,
    children,
    openDefault = false,
    mobile = false,
    styleDefault = true
  } = props;
  const [display, setDisplay] = useState(openDefault);

  useImperativeHandle(
    ref,
    () => ({
      open: () => setDisplay(true),
      close: () => setDisplay(false),
      isOpen: display,
    }),
    [display]
  );

  const myStopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
    e.stopPropagation();
  return createPortal(
    display ? (
      !none ? (
        <ModalWrapper onClick={myStopPropagation}>
          <ModalBackdrop onClick={() => setDisplay(false)} />
         
         {styleDefault ? <ModalBoxStyle >{children}</ModalBoxStyle> :<ModalBox>{children}</ModalBox> }
        </ModalWrapper>
      ) : null
    ) : null,
    modalRoot
  );
});

Modal.displayName = "Modal";
export default Modal;
