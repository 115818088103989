import React from 'react'
import { ProfileDatas } from '../../../../Components/ProfileDatas'
import { Container, Datas } from '../../ListUser/Profile/styles'
import { formDate, formatarCpf, formatarFone, formatedDateS } from '../../../../Utils/Functions'

interface Props {
    back?: any,
    edit?: any,
    title?: string,
    dataEmployee?: any
}

const ProfileEmployees = ({ back, edit, dataEmployee, title }: Props) => {


    return (
        <Container>
            <ProfileDatas
                page={title}
                onBack={() => back()}
                onEdit={() => edit()}
                labelSingle="Usuário"
                labelPlural="Usuários"
            />
            <Datas>
                <main>
                    <b>Unidade educacional: {dataEmployee.educationInstitution.name}</b>
                    <br />
                    <b>Dados do funcionário</b>
                    <p>Nome do Funcionário: {" "}{dataEmployee.user.name}</p>
                    <p>Email: {" "}{dataEmployee.user.email}</p>
                    <p>Data de nascimento: {" "}{formDate(dataEmployee.user.birth_date
                    )}</p>
                    <p>CPF: {" "} {formatarCpf(dataEmployee.user.cpf)}</p>
                    <p>Telefone: {""}{formatarFone(dataEmployee.user.phone)}</p>
                    <br />
                    <b>Perfil(s):</b>
                    {dataEmployee.user.roles.map((role: any) =>
                        <>
                            <b key={role.name}>{role.name}</b>
                            <p>Descrição: {" "}{role.description}</p>
                        </>
                    )}
                </main>
            </Datas>
        </Container>
    )
}

export default ProfileEmployees
