import React, { useState } from 'react'
import { DivFooter } from './styles'
import { ChevronLeft, ChevronRight } from 'lucide-react'


interface Props {
  page: number,
  setPage?: any
}

const Footer = (props: Props) => {

  const { page, setPage } = props

  const [active, setActive] = useState(false)

  return (
    <DivFooter>
      <div className='arrow'
        onClick={() => {
          if(page === 1){
            return
          } else {
            setPage(page - 1)
          }
        }}
      ><ChevronLeft color='#027294' /></div>
      <div className="numbersDiv">
        <div
          className={page === 1 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(1)}
        >
          <p>1</p>
        </div>
        <div
          className={page === 2 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(2)}
        >
          <p>2</p>
        </div>
        <div
          className={page === 3 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(3)}
        >
          <p>3</p>
        </div>
        <div
          className={page === 4 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(4)}
        >
          <p>4</p>
        </div>
        <div
          className={page === 5 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(5)}
        >
          <p>5</p>
        </div>
        {/* <div
          className={page === 6 ? 'numbersActive' : 'numbersInactive'}
          onClick={() => setPage(6)}
        >
          <p>6</p>
        </div> */}
      </div>
      <div className='arrow'
          onClick={() => {
            if(page === 6){
              return
            } else {
              setPage(page + 1)
            }
          }}
      ><ChevronRight color='#027294' /></div>
    </DivFooter>
  )
}

export default Footer
