import styled from "styled-components";


export const ContainerComponentResponsible = styled.div`

display: flex;
flex-direction: column;
width: 100%;
height: 200px;
border-top: 1px solid black;
border-bottom: 1px solid black;
gap: 10px;

.title{
    font-size: 16px;
    color: #646464;
    font-weight: bold;
    margin-top: 10px;
}

`

export const DivInputs = styled.div`
display: flex;
align-items: flex-start;
gap: 20px;
`