import React, { useEffect, useState } from "react";
import { LoadingScreen } from "../../../Components/Loading";
import { Container, Main } from "../ListTech/styles";
import { SuperBox } from "../../../Components/SuperBox";
import { CallRegister } from "../../../Components/CallRegister";
import { ListDefault } from "../../../Components/ListDefault";
import { SearchTable } from "../../../Components/SearchTable";
import { Input } from "../../../Components/Form/Input";
import UnidadeService from "../../../Services/Unidade.service";
import { Link } from "react-router-dom";
import { Edit, ToggleLeft, Trash } from "lucide-react";
import ProfileEmployees from "./Profile";
import EditEmployee from "./Edit";

export default function ListEmployees(props: any) {


    const [isLoading, setIsLoading] = useState(true);
    const [switchScreen, setSwitchScreen] = useState(1);
    const [list, setList] = useState("1");
    const [inputSearchView, setInputSearchView] = useState("")
    const [employeesFiltereds, setEmployeesFiltereds] = useState<any[]>([])
    const [unitsArray, setUnitsArray] = useState<any[]>([])
    const [listEmployees, setListEmployees] = useState<any[]>([])
    const [dataEditEmployee, setDataEditEmployee] = useState<any>([])

    useEffect(() => {
        setIsLoading(false)

        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnitsArray([])
                response.data.map((data: any) => {
                    setUnitsArray((state: any) => {
                        return [...state, data.identifier]
                    })
                })
            }
        })



    }, [])

    useEffect(() => {
        unitsArray.map((item: any) =>
            UnidadeService.listEmploeesByUnit({ idUnit: item }).then((response) => {
                if (response.success) {
                    setListEmployees((state: any) => {
                        return [...state, response.data]
                    })
                }
            })
        )


    }, [unitsArray])



    useEffect(() => {

        let employeesForFilter = listEmployees.flat()
        let filteredEmployees = employeesForFilter.filter((item: any) => item.user.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
        setEmployeesFiltereds(filteredEmployees)

    }, [listEmployees, inputSearchView])


    return (
        <LoadingScreen isLoading={isLoading}>
            <SuperBox switch={true} path="Funcionários">
                <Container>
                    <div className="box">
                        {switchScreen === 1 && (
                            <Main>
                                <CallRegister
                                    page="Funcionário"
                                    link="/employees/registration"
                                    value={list}
                                />
                                <ListDefault
                                    pages={[]}
                                    changeGet={(v: any) => { }}
                                    to={"1"}
                                    total={'1'}
                                >
                                    <thead>
                                        <tr>
                                            <td colSpan={7}>
                                                <Input
                                                    flex="fx30"
                                                    type="search"
                                                    value={inputSearchView}
                                                    onChange={setInputSearchView}
                                                    color="#039bc9"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="wd30">Nome</td>
                                            <td className="wd15">E-mail</td>
                                            <td className="wd20">Perfil</td>
                                            <td className="wd25">Unidade(s) Educacional(is)</td>
                                            <td className="wd20">Ação</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            employeesFiltereds.map((item: any, index: any) => {
                                                return (
                                                    <>
                                                        <tr
                                                            key={index}>
                                                            <td >
                                                                <Link to={``}
                                                                    onClick={() => {
                                                                        setDataEditEmployee(item);
                                                                        setSwitchScreen(2);
                                                                    }}
                                                                >
                                                                    {item.user.name}
                                                                </Link>
                                                            </td>
                                                            <td >
                                                                {item.user.email}
                                                            </td>
                                                            {item.user.roles.map((role: any) =>
                                                                <td >
                                                                    {role.name}
                                                                </td>
                                                            )}
                                                            <td >
                                                                {item.educationInstitution.name}
                                                            </td>
                                                            <td>
                                                                <div>

                                                                    <button
                                                                        className="hvr-grow"
                                                                        onClick={() => {
                                                                            setDataEditEmployee(item);
                                                                            setSwitchScreen(3)
                                                                        }}
                                                                    >
                                                                        <Edit />
                                                                    </button>
                                                                    <button
                                                                        className="hvr-grow"
                                                                    >
                                                                        <Trash />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>
                                                )
                                            })


                                        }
                                    </tbody>
                                </ListDefault>
                            </Main>
                        )}
                        {switchScreen === 2 && (
                            <>
                                <ProfileEmployees
                                    back={() => setSwitchScreen(1)}
                                    edit={() => setSwitchScreen(3)}
                                    title="Funcionário"
                                    dataEmployee={dataEditEmployee}
                                />
                            </>
                        )}
                        {switchScreen === 3 && (
                            <EditEmployee
                                employee={dataEditEmployee}
                                setForm={setSwitchScreen}
                            />
                        )}
                    </div>
                </Container>
            </SuperBox>
        </LoadingScreen>
    )
}