import { PlusCircle, Save } from "lucide-react";
import { Container } from "./styles";

export function NewSave(props: any){
    return(
        <Container>
            <button title="Novo" onClick={v => props.onClickNew(v)} className="hvr-icon-pop"> <PlusCircle size={16} className="hvr-icon"/> Novo</button>
            <button title="Salvar" onClick={v => props.onClickSave(v)} className="hvr-icon-pop"> <Save size={16} className="hvr-icon"/> Salvar</button>
        </Container>
    )
}