import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
}
`;

export const ClientName = styled.p`
    font-size: 24px;
    color: #646464;
    text-transform: uppercase;
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`
    max-width: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Abas = styled.div`
    button{
        height: 40px;
        width: 50%;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background-color: #646464;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        &:hover{
            background-color: #606060;
        }
        &.active{
            background-color: #027294;
            &:hover{
            background-color: #097999;
        }
        }
    }
`;

export const Form = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
    
    .item-select {
        width: calc(33% - 10px);
    }
`;

export const Radio = styled.div`
border: 1px solid #656565;
border-radius: 15px;
padding: 10px 20px;
>p{
    font-size: 14px;
    color: #656565;
}
>div{
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    label{
        color: #656565;
    }
}
`;

export const MoreStudent = styled.div`
background-color: red;
position: relative;
button{
    color: #FE8F2E;
background-color: transparent;
display: flex;
align-items: center;
justify-content: center;
height: 16px;
width: 16px;
position: absolute;
border: none;
bottom: 15px;
cursor: pointer;
}
`;