import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../Components/Form/Input'
import { Aside, Container, Form, Main } from './styles'

export function RecoverPass() {
  const navigate = useNavigate();
  const [switchStep, setSwitchStep] = useState(1)

  return (
    <Container>
      <Aside />
      <Main>
        {switchStep === 1 && (
          <Form>
            {/* <span>Bem vindo de volta</span> */}
            <p>Recuperar minha senha</p>
            <div className="form">
              <label htmlFor="">Nova senha</label>
              <input type="password" placeholder="*****" />
              <label htmlFor="">Confirmar nova senha</label>
               <input type="password" placeholder="*****" />
            </div>
            <div className="pass">{/* <a href="">Esqueceu a senha?</a> */}</div>
            <button onClick={() => setSwitchStep(2)}>Confirmar</button>
          </Form>
        )}
        {switchStep === 2 && (
          <Form>
            <p>Sucesso!</p>
            <span>Sua nova senha está pronta para ser usada.</span>
            <div className="form">
              {/* <label htmlFor="">Senha</label>
          <input type="password" placeholder="*****" /> */}
            </div>
            <div className="pass">{/* <a href="">Esqueceu a senha?</a> */}</div>
            <button onClick={() => navigate('/login')} >Fazer Login</button>
          </Form>
        )}
      </Main>
    </Container>
  )
}
