import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ContainerImportTags } from '../styles'
import { Upload } from 'lucide-react'
import { ButtonImport } from './styles'
import { EnumRoles, Tag } from '../../../../Utils/Types'
import { read, utils } from 'xlsx'
import TagService from '../../../../Services/Tag.service'
import { ModalShort } from '../../../../Components/Modals/ModalShort'


interface ImportTagsProps {
    listTags: Tag[],
    setListTags: Dispatch<SetStateAction<Tag[]>>,
    resetTags: any
}

const ImportTags = ({ listTags, setListTags, resetTags }: ImportTagsProps) => {

    const user = JSON.parse(String(localStorage.getItem('roles')))
    const [isOpen, setIsOpen] = useState(false)
    const [modalIsError, setModalIsError] = useState(false)
    const [message, setMessage] = useState("");

    useEffect(() => {
        setPres([])
    }, [])

    async function sugestImport() {
        await Promise.all(listTags.map((tag) => {
            TagService.tagSugestion({
                name: tag.name,
                tag_category_id: tag.idCategory,
                justification: tag.justification
            })
        })).then(() => {
            resetTags();
            setIsOpen(true)
        })
    }

    async function addImport() {
        if (listTags.length > 0 && listTags.every(tag => tag.name && tag.idCategory)) {
            await Promise.all(listTags.map((tag) => {
                TagService.tagRegistration({ tag: tag })
            })).then(() => {
                setIsOpen(true)
            })
        } else {
            setModalIsError(true)
            setMessage("Os dados do arquivo não correspondem aos dados de cadastro.")
           
        }
    }


    useEffect(() => {

        if (listTags.length <= 0) { return };

        switch (user) {
            case EnumRoles.MASTER:
                addImport()
                break;
            default:
                sugestImport()
                break;
        }

    }, [listTags])

    const [pres, setPres] = useState<Tag[]>([
        {
            name: "",
            idCategory: "",
            category: "",
            id: "",
            justification: "",
        },
    ])

    async function handleImport(e: any) {
        if (!e.target.files || !e.target.files[0]) return;
        const acceptedFormats = ['.csv', '.xlsx', '.xls'];

        const file = e.target.files[0];
        const fileExtension = file.name.split('.').pop();

        console.log(fileExtension)

        if (!acceptedFormats.includes('.' + fileExtension)) {
            setModalIsError(true)
            setMessage("Formato não aceito. Por favor, selecione um arquivo nos formatos .xls, .csv ou .xlsx.")
            return;
        }

        const f = await e.target.files[0].arrayBuffer();
        const wb = read(f);
        const data = utils.sheet_to_json<Tag>(wb.Sheets[wb.SheetNames[0]]);
        setPres([...pres, ...data]);
        setListTags([...pres, ...data]);
    }



    return (
        <ContainerImportTags>
            <ModalShort
                type="success"
                text={
                    user === EnumRoles.MASTER
                        ? "Tags cadastradas. Lista atualizada!"
                        : "Tags sugeridas"
                }
                isOpen={isOpen}
                onRequestClose={() => {
                    if (user === EnumRoles.MASTER) {
                        window.location.reload()
                    } else {
                        setIsOpen(false)
                    }
                }}
            />
            <ModalShort
                isOpen={modalIsError}
                type="infoError"
                text={message}
                onConfirm={() => {
                    setModalIsError(false)
                }}
            />
            <ButtonImport>
                <label htmlFor="uploadid">
                    <Upload className="hvr-icon" size={16} />
                    Importar Tags
                </label>
                <input
                    type="file"
                    name=""
                    id="uploadid"
                    onChange={(e) => handleImport(e)}
                />
            </ButtonImport>
        </ContainerImportTags>
    )
}

export default ImportTags
