import { PlusCircle } from "lucide-react";
import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormContainer, Line, Row } from "../styles";
import { TutorialItem } from "./TutorialItem";
import { EnumStepNewResource, ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import { Controllers } from "../../../../../Components/Form/Controllers";

enum TypeDocument {
  TUTORIAL,
  DESCRIPTION,
}

export interface ITutorialFields {
  type?: TypeDocument;
  title: string;
  description: string;
  image?: File;
}

export function FormTutorial() {
  const navigate = useNavigate();

  const [tutorialFields, setTutorialFields] = useState<ITutorialFields[]>([
    {
      title: "",
      description: "",
    },
  ]);

  const { handleStep, resource } = useContext(ResourceContext);

  function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  function onCancel() {
    navigate(-1);
  }

  function onNext() {
    handleStep(EnumStepNewResource.DOCUMENTS)
  }

  function onPrev() {
    handleStep(EnumStepNewResource.POP)

  }

  function addTutorial() {
    const tutorial = [...tutorialFields];
    tutorial.push({
      title: "",
      description: "",
    });
    setTutorialFields(tutorial);
  }

  function deleteTutorial(index: number) {
    const tutorial = [...tutorialFields];
    tutorial.splice(index, 1);
    if (tutorial.length === 0)
      tutorial.push({
        title: "",
        description: "",
      });
    setTutorialFields(tutorial);
  }

  function handleTemplate(template: ITutorialFields, position: number) {
    const tutorial = [...tutorialFields];
    tutorial[position] = template;
    setTutorialFields(tutorial);
  }
  return (
    <FormContainer onSubmit={onSubmit}>
      {tutorialFields.map((tutorial, index) =>
        index === 0 ? (
          <TutorialItem
            key={index}
            handleTemplate={handleTemplate}
            position={index}
            handleDelete={deleteTutorial}

            tutorial={tutorial}
          />
        ) : (
          <div key={index}>
            <Line $margin="0.5rem" />
            <TutorialItem
              handleDelete={deleteTutorial}
              handleTemplate={handleTemplate}
              position={index}
              tutorial={tutorial}
            />
          </div>
        )
      )}
      <Row $justify="flex-start" onClick={addTutorial}>
        <span className="button-span">
          <PlusCircle color="#fe8f2e" /> Novo Documento
        </span>
      </Row>
      <Controllers
        functionCancel={onCancel}
        functionNext={onNext}
        functionPrev={onPrev}
        enableNext={true}
        enablePrev={true}
        labelProx="Avançar"
      />
    </FormContainer>
  );
}
