import { Container, Tables } from './styles'

export function Table(props: any) {
  return (
    <Container>
      <label htmlFor="">{props.label}</label>
      <Tables>
        <table>
          <thead>
            <td>Coleções</td>
            <td></td>
          </thead>
          <tbody>
            <tr>
              <td>Plano de Teste 1 para adicionar clique</td>
              <td>
                <button>Remover</button>
              </td>
            </tr>
            <tr>
              <td>Plano de Teste 1 para adicionar clique</td>
              <td>
                <button>Remover</button>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <td>Pacotes</td>
            <td></td>
          </thead>
          <tbody>
            <tr>
              <td>Plano de Teste 1 para adicionar clique</td>
              <td>
                <button>Remover</button>
              </td>
            </tr>
            <tr>
              <td>Plano de Teste 1 para adicionar clique</td>
              <td>
                <button>Remover</button>
              </td>
            </tr>
          </tbody>
        </table>
      </Tables>
    </Container>
  )
}
