import React, { useContext } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'

const AuthorReview = () => {

    const { resource } = useContext(ResourceContext)

    return (
        <>
            <tr>
                <td>
                    <span>AUTORIA</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Conteudistas:</span>
                </td>
            </tr>
            <ul>
                {resource.authorship.content_creator.length > 0 ?
                    resource.authorship.content_creator.map((content) => {
                        return (
                            <li style={{ listStyle: "none" }}>
                                - {content}
                            </li>
                        )
                    })
                    : "Não informado."}
            </ul>
            <tr>
                <td>
                    <span>Revisores: </span>
                </td>
            </tr>
            <ul>
                {resource.authorship.proofreader.length > 0 ?
                    resource.authorship.proofreader.map((proofreader) => {
                        return (
                            <li style={{ listStyle: "none" }}>
                                - {proofreader}
                            </li>
                        )
                    })
                    : "Não informado."}
            </ul>
            <tr>
                <td>
                    <span>Designers Gráficos:</span>
                </td>
            </tr>
            {resource.authorship.graphic_designer.length > 0 ?
                resource.authorship.graphic_designer.map((designer) => {
                    return (
                        <li style={{ listStyle: "none" }}>
                            - {designer}
                        </li>
                    )
                })
                : "Não informado."}
            <tr>
                <td>
                    <span>Roteiristas: </span>
                </td>
            </tr>
            {resource.authorship.screenwriter.length > 0 ?
                resource.authorship.screenwriter.map((screenwriter) => {
                    return (
                        <li style={{ listStyle: "none" }}>
                            - {screenwriter}
                        </li>
                    )
                })
                : "Não informado."}
            <tr>
                <td>
                    <span>Ilustradores:</span>
                </td>
            </tr>
            {resource.authorship.illustrator.length > 0 ?
                resource.authorship.illustrator.map((illustrator) => {
                    return (
                        <li style={{ listStyle: "none" }}>
                            - {illustrator}
                        </li>
                    )
                })
                : "Não informado."}
            <tr>
                <td>
                    <span>Programadores:</span>
                </td>
            </tr>
            {resource.authorship.programmer.length > 0 ?
                resource.authorship.programmer.map((programmer) => {
                    return (
                        <li style={{ listStyle: "none" }}>
                            - {programmer}
                        </li>
                    )
                })
                : "Não informado."}
        </>
    )
}

export default AuthorReview