import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
padding: 5px 30px;
}
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;
`;

export const Abas = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    button{
        height: 40px;
        width: 100%;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background-color: #027294;
        border: none;
        border-radius: 10px;
    }
`

export const BoxSelect = styled.div`
    margin-top: 10px;
`

export const ButtonAdd = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-bottom: 25px;
    button{
        display: flex;
        padding-top: 20px;
        font-size: 16px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        align-items: center;
        gap: 5px;
        font-weight: 700;
        color: #FE8F2E;
        cursor: pointer;
        &:last-child{
            color: #FE8F2E;
        }
        .search-icon {
            display: flex;
            justify-content: center; 
            align-items: center;
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 15px;
            color: #fff;
            background: #FE8F2E;
        }
    }
`

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
        .bnt-mark {
            display: flex;
            margin-left: 15px;
            gap: 10px;
                button{
                    cursor: pointer;
                    background-color: white;
                    border: none;
                }
        }
`;

export const Table = styled.div`
    border: 1px solid #646464;
    border-radius: 15px;

    >p{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    }
    
    table{
        width: 100%;
        border-spacing: 0px;
        thead{
            tr{
                td{
                    text-align: center;
                }
                .header-cell{
                    justify-content: center;
                }
            }
        }
    tbody{
        tr{
            td{
                padding: 0;
                height: auto;
                text-align: center;
                min-height: 25px;
            }
        }
    }
    td{
        padding: 5px;
        font-size: 14px;
        color: #646464;
        color: black;
        border-top: 1px solid #646464;
        border-left: 1px solid #646464;
            &:first-child{
                display: flex;
                border-left: none;
                align-items: center;
                input{
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
    }
}
`;

export const ContainerNew = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;

    .text{
        text-transform: uppercase;
        color: #818181;
        size: 24px;
    }
    section{
        margin-top: 20px;
    }
`;

export const AddStudentContainer = styled.div`
    margin-left: 15%;
    width: 70%;
    border: 1px solid #BCBCBC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #F8F8FA;

        header {
            background-color: #FE8F2E;
            color: #F8F8FA;
            text-align: center;
            border-radius: 10px 10px 0px 0px;
            height: 82px;
            font-size: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-transform: uppercase;
        }

        section{
            padding: 20px 0px;
            width: 95%;
        }

        footer{
            display: flex;
            gap: 10px;
            padding-bottom: 30px;
            justify-content: flex-end;
            width: 100%;
            padding: 20px;

            button {
                width: 110px;
                height: 40px;
                color: #F8F8FA;
                background-color: #088A62;
                border-radius: 10px;
                cursor: pointer;
                border: none;
                    &:first-child{
                        background: #BCBCBC;
                    }
            }
        }
`;

export const ContainerResume = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    
        textarea{
        height: 40px;
        border-radius: 15px;
        border: 1px solid black;
        width: 100%;
        padding-left: 10px;
        padding-top:10px;
        height: 100px;
    }
`