import React, { useEffect, useState } from 'react'
import { ButtonImport, ContainerImportStudents } from '../../FormStudent/ImportStudents/styles'
import { Upload } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import StudentService from '../../../../../Services/Student.service'
import { ReportFormatters } from '../../../../../Components/ExcelImports/ReportFormatters'
import { GenerateReport } from '../../../../../Components/ExcelImports/GenerateReport'
import ModalNew from '../../../../../Components/Modals/ModalNew/ModalNew'

interface Props {
    unitId: string
}

type InputData = {
    studentsFile: FileList
}

const StudentImport = ({ unitId }: Props) => {

    const navigate = useNavigate()
    const [file, setFile] = useState<Blob>();
    const [isOpen, setIsOpen] = useState(false)


    const handleImport = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const studentsFiles: any = e.target.files;
        const students = await ReportFormatters.students(studentsFiles)
        const FileGenerated = await GenerateReport.generateStudentsImport(students)
        setFile(FileGenerated)

        
        //setIsOpen(true)
    };

    async function importSave(){
        const data = new FormData()
        let successFile: any = file
        data.append("file",successFile,"students.xlsx")

        StudentService.import({unitId: unitId, file: data}).then((res)=>{
            if(res.success){
                alert("funcionou!")
                setIsOpen(false)
            }
        })
    }

    async function downloadFile() {
        let fileSuccess: any = file
        let url = window.URL.createObjectURL(fileSuccess);
        let a = document.createElement("a");
        a.href = url;
        a.download = "importStudents.xlsx";
        a.click();
      }

    useEffect(() => {
        const formData = new FormData()
        // formData.append('file', file)

        // console.log(file)

        // if (file && file.size > 0) {

        //     StudentService.import({ file: formData, unitId: unitId }).then((response) => {
        //         if (response.success) {
        //             alert("cadastrou!")
        //             navigate("/unidade/usuario/list")
        //         }
        //     })

        // } else {
        //     return;
        // }
    }, [file])

    return (
        <>
            <ContainerImportStudents>
                <ButtonImport>
                    <label htmlFor="uploadid">
                        <Upload className="hvr-icon" size={16} />
                        Importar Estudante(s)
                    </label>
                    <input
                        type="file"
                        name=""
                        id="uploadid"
                        onChange={(e) => handleImport(e)}
                        accept='.xlsx, .xls'
                    />
                </ButtonImport>
            </ContainerImportStudents>
            {file && (
                <div>
                    <button type='submit' onClick={downloadFile}>
                        baixar
                    </button>
                </div>
            )}
            <ModalNew
                type="confirmSuccess"
                isOpen={isOpen}
                onRequestClose={()=>setIsOpen(false)}
                text='Deseja importar estes estudantes?'
                onConfirm={()=>{
                    importSave()
                }}
            />
        </>
    )
}

export default StudentImport
