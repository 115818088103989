import { api } from "./api";
import { AxiosError, AxiosResponse } from "axios";
import {
  CreateSuccess,
  Forbidden,
  NotFound,
  ServiceError,
  Success,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { TypeTeacher } from "../Utils/Types";

type TypeTechCreate = {
  name: string;
  company: string;
  role?: string;
  phone: string;
  responsible_name?: string;
  login: string;
  email: string;
  cpf?: string;
  role_id: string;
};
type TechCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type TechGetReponse = Success | Unauthorized | Forbidden | NotFound | ServiceError;

type GetResponse = any | Unauthorized

class TechService {
  static async getById(idTech: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.get<TechGetReponse>(
        `/admin/technicians/${idTech}`,
        config
      );
      switch (response.status) {
        case 200:
            return { data: response.data, success: true, message: "ok" } as Success;
        case 401:
            return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
            return { success: false, message: "ServiceError" } as ServiceError
    }
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async getAll() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.get<GetResponse>(
        `/admin/technicians`,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async put(data: TypeTechCreate, idTech: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.put<TechCreateResponse>(
        `/admin/technicians/${idTech}`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async create(data: TypeTechCreate) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<TechCreateResponse>(
        `/admin/technicians`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default TechService;
