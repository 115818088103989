import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input } from "../../../Components/Form/Input";
import { LoadingScreen } from "../../../Components/Loading";
import { SuperBox } from "../../../Components/SuperBox";
import { Container, HeaderContainer } from "./styles";
import { Edit, PlusCircle, Trash } from "lucide-react";
import { ListDefault } from "../../../Components/ListDefault";
import { useListResource } from "../../Registration/ResourcesRegistration/hooks/useListResource";
import { Link } from "react-router-dom";
import TagService from "../../../Services/Tag.service";
import { ResorceProfile } from "./Profile";
import ResourceService, { IResource } from "../../../Services/Resouce.service";
import { TResource } from "../../../types/types";
import { Queries } from "../../../types/queries";
import { useQueryClient } from "react-query";
import ModalNew from "../../../Components/Modals/ModalNew/ModalNew";
import ResourceEdit from "./Edit";

export function ListResource() {
  const navigate = useNavigate();
  const listResorces = useListResource();
  const [screen, setScreen] = useState(1);
  const [resourceSelected, setResourceSelected] = useState<TResource>();
  const queryClient = useQueryClient();

  const sortedItems = listResorces.data
    ?.slice()
    .sort((a, b) => a.name.localeCompare(b.name));
  const [isOpen, setIsOpen] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [nameToDelete, setNameToDelete] = useState("");

  const onEdit = (resource: any) =>
    navigate("/recursos/edit", { state: resource });

  function navigateToNewRegistration() {
    navigate("/recursos/registration");
  }

  function deleteResource(resource: TResource) {
    console.log(resource);
    setIsOpen(true);
    setIdToDelete(resource.identifier);
    setNameToDelete(resource.name);
  }

  return (
    <LoadingScreen isLoading={false}>
      <SuperBox switch={true} page={4} path={`Recursos`}>
        {screen === 1 && (
          <Container>
            <ModalNew
              isOpen={isOpen}
              type="confirmSuccess"
              text={`Deseja excluir o recurso: ${nameToDelete} ?`}
              onRequestClose={() => setIsOpen(false)}
              onConfirm={() => {
                ResourceService.delete({ identifier: idToDelete }).then(
                  (res) => {
                    setIsOpen(false);
                    queryClient.invalidateQueries(Queries.LIST_RESOURCES);
                  }
                );
              }}
            />
            <HeaderContainer>
              <Input
                label={"Recursos"}
                flex="fx100"
                type="searchIcon"
                placeHolder="Digite o nome do recurso ou faça uma busca avançada para encontrar um recurso existente"
                color="#039bc9"
              >
                <PlusCircle
                  className="icon"
                  onClick={navigateToNewRegistration}
                  id="plusIcon"
                  color="#fe8f2e"
                />
              </Input>
            </HeaderContainer>
            <br />
            <ListDefault
              pages={[2]}
              changeGet={(v: any) => {}}
              to={"1"}
              total={"2"}
            >
              <thead>
                <tr>
                  <td className="wd20">Nome</td>
                  <td className="wd20">Caminho</td>
                  <td className="wd20">Coleção principal</td>
                  <td className="wd15">Coleção Correlata(s)</td>
                  <td className="wd10">Status</td>
                  <td className="wd10">Ação</td>
                </tr>
              </thead>
              <tbody>
                {sortedItems?.map((resorce) => {
                  return (
                    <tr key={resorce.identifier}>
                      <td>
                        <Link
                          to={``}
                          onClick={() => {
                            setScreen(2);
                            setResourceSelected(resorce);
                          }}
                        >
                          {resorce.name}
                        </Link>
                      </td>
                      <td>
                        {resorce.collections?.identifier
                          ? resorce.collections?.subcategory?.identifier
                          : "Não informado"}
                      </td>
                      <td>
                        {resorce.collections?.identifier
                          ? resorce.collections?.identifier
                          : "Não informado"}
                      </td>
                      <td>Não informado</td>
                      <td>{resorce.active == true ? "Sim" : "Não"}</td>
                      <td>
                        <button
                          className="hvr-grow"
                          onClick={() => onEdit(resorce)}
                        >
                          <Edit />
                        </button>
                        <button
                          className="hvr-grow"
                          onClick={() => deleteResource(resorce)}
                        >
                          <Trash />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </ListDefault>
          </Container>
        )}
        {screen === 2 && (
          <ResorceProfile
            back={() => setScreen(1)}
            edit={() => {}}
            resorce={resourceSelected}
            resourceId={resourceSelected?.identifier}
          />
        )}

          {screen === 3 && (
            <ResourceEdit />
          )}

      </SuperBox>
    </LoadingScreen>
  );
}
