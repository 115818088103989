import React from "react";
import { ContInputExpo } from "./styles";

export default function InputExperience(props: any) {
  return (
    <ContInputExpo $backgroundColor={props.color}>
      <input checked={props.checked} onChange={props.onChange} type="checkbox" />
      <p>{props.text}</p>
    </ContInputExpo>
  );
}
