import styled from 'styled-components'
export const Container = styled.div`
min-block-size: 100%;

  table {
    min-width: 100%;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    padding: 15px 0px;
    border-spacing: 0px 5px;
    thead {
      tr{
        width: 100%;
      }
      td {
        font-weight: 700;
        font-size: 13px;
        color: #838383;
      }
    }
    tbody {
      div{
        display: flex;
      }
      tr {
        height: 50px;

        border-radius: 30px;
        &:hover {
          td {
            background-color: #e5e5e5;
          }
        }
        td {
          font-size: 12px;
          font-weight: 400;
          height: 50px;
          background-color: #eee;
          vertical-align: middle;

          &:first-child {
            border-radius: 10px 0px 0px 10px;
            padding-left: 10px;
          }
          &:last-child {
            border-radius: 0px 10px 10px 0px;
            text-align: left;
          }

          a {
            color: #027294;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }

          button {
            background-color: transparent;
            border: none;
            color: #027294;
            cursor: pointer;

            &:last-child {
              color: #fa5050;
            }
            &.profile {
              color: #027294;
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        img {
          border-radius: 50%;
          width: 35px;
        }
      }
    }
  }
  .wd10{
    width: 10%; 
  }
  .wd15{
    width: 15%;  
  }
  .wd20{
    width: 20%;  
  }
  .wd25{
    width: 25%;  
  }
  .wd30{
    width: 30%;  
  }
  .wd35{
    width: 35%;  
  }
  .wd40{
    width: 40%;  
  }
  .wd45{
    width: 45%;  
  }
  .wd50{
    width: 50%;  
  }
  .wd55{
    width: 55%;  
  }
  .wd60{
    width: 60%;  
  }
  .wd65{
    width: 65%;  
  }
  .wd70{
    width: 70%;  
  }
  .wd75{
    width: 75%;  
  }
  .wd80{
    width: 80%;  
  }
  .wd85{
    width: 85%;  
  }  
`

export const Navigation = styled.nav`
display: flex;
align-items: center;
justify-content: space-between;
margin-top: 10px;
  div:nth-of-type(1) {
    font-size: 14px;
    color: #747474;
  }
  div:nth-of-type(2) {
    > button {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      border: none;
      background-color: #fff;
      color: #027294;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
      &.active {
        background-color: #027294;
        color: #fff;
      }
    }
  }
  div:nth-of-type(3) {
    visibility: hidden;
    font-size: 14px;
  }
`
