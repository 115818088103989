import React, { useContext, useEffect, useState } from 'react'
import { ContainerTagsReview } from './styles'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { ResourceContext } from '../../../../../Contexts/Form/ContextResource'
import TagService from '../../../../../Services/Tag.service'

const ReviewTags = () => {

    const { resource } = useContext(ResourceContext)
    const { itens, categorys } = resource.tags

    const [namesCategorys, setNamesCategorys] = useState<string[]>([])

    function addNameCategory(name: string){

        const exist = namesCategorys.some((nameC)=> name === nameC);
        if(exist === true){
            return
        }
        setNamesCategorys([...namesCategorys, name])
    }

    useEffect(()=>{

        Promise.all(categorys.map((category)=>
            itens.map((tag)=>
                TagService.showDataTag({identifier: tag, tagCategory: category}).then((res)=>{
                    if(res.success){
                        addNameCategory(res.data.name)
                    }
                })
            )
        )).then(()=>{
        })

    },[])





    return (
        <ContainerTagsReview>
            <h2>Tags</h2>
            <BoxResume>
                <tr>
                    <td>
                        <span>TAGS SELECIONADAS</span>
                    </td>
                </tr>
                {
                   namesCategorys.length > 0
                   ?
                   namesCategorys.map((name)=>
                   <tr><td><span>- {name}</span></td></tr>
                   )
                   : "Não informado."
                }
            </BoxResume>
        </ContainerTagsReview>
    )
}

export default ReviewTags