import styled from 'styled-components';

export const Container = styled.section`
display: flex;


>div.box{
width: 100%;
padding: 5px 30px;
}
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;

    .div-search{
        padding: 20px 0px;
    }
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Banner = styled.div`
    margin-top: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.5);

    .box-banner{
        display: flex;
        width: 279px;
        height: 45px;
        border-radius: 15px 15px 0px 0px;
        background-color: #027294;
        align-items: center;
        justify-content: center;

        p{
            color: #FFFFFF;
        }
    }
`