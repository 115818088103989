import styled from "styled-components";

export const Container = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
export const HeaderContainer = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  input {
    flex: 1;
  }
`;
export const Main = styled.main`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
