import { useEffect, useState } from "react";
import { CallRegister } from "../../../../Components/CallRegister";
import { ProfileDatas } from "../../../../Components/ProfileDatas";
import { api } from "../../../../Services/api";
import { Sitemap } from "../styles";
import { Container, Datas, Header } from "./styles";
import { FileText } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface ClientData {
  socialName: string;
  phone: string;
  logo: string;
  identifier: string;
  email: string;
  cnpj: string;
  address: {
    address: string;
    city: string;
    complement: string;
    neighborhood: string;
    number: string;
    state: string;
    zipCode: string;
  };
  plan: {
    collections: [
      {
        color: string;
        description: string;
        identifier: string;
        logo: string;
        name: string;
      }
    ];
    packages: [
      {
        color: string;
        description: string;
        identifier: string;
        logo: string;
        name: string;
      }
    ];
    maxEducationInstitutions: number;
    maxEmployees: number;
    maxStudents: number;
    maxGuardiansPerStudent: number;
  };
}

export function ClientProfile(props: any) {
  const [datasProfile, setDatasProfile] = useState<ClientData>();
  const navigate = useNavigate();

    function callUser(id: string) {
        navigate('/unidade/usuario/list')
        sessionStorage.setItem('location', id);
        sessionStorage.setItem('locationData', JSON.stringify(datasProfile));
      }

  useEffect(() => {
    if (props.selected === "") {
      alert("Selecione um cliente");
      props.back();
    } else {
      api
        .get(`/admin/clients/${props.selected}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setDatasProfile(response.data.data);
        });
    }
  }, []);


  const telefone =
    datasProfile?.phone.length === 11
      ? `(${datasProfile?.phone.substring(
          0,
          2
        )}) ${datasProfile?.phone.substring(
          2,
          7
        )}-${datasProfile?.phone.substring(7, 11)}`
      : `(${datasProfile?.phone.substring(
          0,
          2
        )}) ${datasProfile?.phone.substring(
          2,
          6
        )}-${datasProfile?.phone.substring(6, 10)}`;

  return (
    <Container>
      <ProfileDatas
        page="Perfil do cliente"
        onBack={() => props.back()}
        onEdit={() => props.edit()}
        labelSingle="Cliente"
        labelPlural="Clientes"
      />
      <Datas>
        <aside>
          <div>
            <img
              src={"https://portal.arkadigital.dev.br/" + datasProfile?.logo}
              alt=""
            />
          </div>
        </aside>
        <main>
          <p>Razão Social: {datasProfile?.socialName}</p>
          <p>CNPJ: {datasProfile?.cnpj}</p>
          <p>E-mail: {datasProfile?.email}</p>
          <p>Telefone: {telefone}</p>
          <span></span>
          <p>Rua: {datasProfile?.address.address}</p>
          <p>Complemento: {datasProfile?.address.complement}</p>
          <p>Bairro: {datasProfile?.address.neighborhood}</p>
          <p>
            Cidade: {datasProfile?.address.city} - {datasProfile?.address.state}
          </p>
          <p>CEP: {datasProfile?.address.zipCode}</p>
          <span></span>
          <p>Coleções Cadastradas:</p>
          {datasProfile?.plan.collections.map((collection, index) => (
            <p key={index}>• {collection.name}</p>
          ))}
          <p>Pacotes Cadastrados: </p>
          {datasProfile?.plan.packages.map((packagee, index) => (
            <p key={index}>• {packagee.name}</p>
          ))}
          <p>Número de Usuários Docentes: {datasProfile?.plan.maxEmployees}</p>
          <p>Número de Usuários Estudantes: {datasProfile?.plan.maxStudents}</p>
          <p>
            Número de Unidades Educacionais:{" "}
            {datasProfile?.plan.maxEducationInstitutions}
          </p>
          <span></span>
          <p>
            Gestor de Contrato:{" "}
            <button onClick={() => callUser("1")} className="callList">
              <FileText size={14} />
            </button>{" "}
          </p>
        </main>
      </Datas>
    </Container>
  );
}
