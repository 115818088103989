import { useContext, useEffect, useState } from "react";
import { EnumColumnSelected } from ".";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";
import { ItemKnowledge, RowKnowledge, TableKnowledge } from "../styles";
import { ArrowDown, FileText } from "lucide-react";
import {
  IBNCCFieldAction,
  IBNCCKnowledge,
  IBNCCKnowledgeObject,
  IBNCCThematicUnit,
} from "../../../../../../types/bncc";
import InputCustomized from "./InputCustumized";
import { components } from "react-select";
import Checkbox from "./InputCustumized";

interface ItemKnowledgeProps {
  knowledgeArea: IBNCCKnowledge;
  index: number;
  openModal?: () => void;
  handleColumnSelected: (column: EnumColumnSelected) => void;
  handleComponentCurricularSelected: (component: string) => void;
}
const knowledgeAreas =
  [
    "#7FAD41",
    "#6A2C91",
    "#F47721",
    "#18A6B7",
    "#919AC1"
  ];

export function ItemKnowledgeComponent({
  knowledgeArea,
  index,
  openModal,
  handleColumnSelected,
  handleComponentCurricularSelected,
}: ItemKnowledgeProps) {
  const { resource, addFieldBNCCEfResource, removeFieldBNCCEfResource } =
    useContext(ResourceContext);
  const { bncc } = resource;
  console.log(bncc)
  const color = index > 4 ? knowledgeAreas[index - 5] : knowledgeAreas[index];
  const isSelectedKnowledgeArea = bncc.ef.knowledge_areas.includes(
    knowledgeArea.name
  );
  // const array1 = [1,2,3,4,5,6,7,8, 1]
  // const array2 = [1,4,5]

  // array1.filter((iten) => array2.includes(iten)).filter((item, index, arr) => arr.indexOf(item) === index)
  const [isOpen, setIsOpen] = useState(false);
  function handleVisibility() {
    setIsOpen((prevValue) => !prevValue);
  }


  function openOptions(materia: string, column: string) {
    if (column === "object_knowledge") {
      handleColumnSelected(EnumColumnSelected.OBJETO_CONHECIMENTO);
    } else if (column === "thematic_units") {
      handleColumnSelected(EnumColumnSelected.UNIDADE_TEMATICA);
    } else if (column === "fields_actions") {
      handleColumnSelected(EnumColumnSelected.CAMPOS_ESPECIFICOS);
    }

    handleComponentCurricularSelected(materia);

    openModal && openModal();
  }

  function handleKnowledgeArea(knowledgeArea: string, select: boolean) {
    if (select) {
      removeFieldBNCCEfResource(knowledgeArea, "knowledge_areas");
    } else {
      addFieldBNCCEfResource(knowledgeArea, "knowledge_areas");
    }
  }

  function handleComponentCurricular(component: string, select: boolean) {
    if (select) {
      removeFieldBNCCEfResource(component, "curricular_components");
    } else {
      addFieldBNCCEfResource(component, "curricular_components");
    }
  }


  const curricularComponentsSeleteds = knowledgeArea.curricular_components.filter((component) => bncc.ef.curricular_components.find((componentSelected) => componentSelected === component.name))
  const anyCurricularComponentSelected = curricularComponentsSeleteds.length > 0
  const allCurricularComponentSelected = curricularComponentsSeleteds.length === knowledgeArea.curricular_components.length


  function handleAllCurricularComponents() {
    if (allCurricularComponentSelected) {
      bncc.ef.curricular_components.forEach((component) => {
        handleComponentCurricular(component, true)
      })
    } else {
      knowledgeArea.curricular_components.filter((components) => !curricularComponentsSeleteds.includes(components)).forEach((component) => {
        handleComponentCurricular(component.name, false)
      })
    }
  }

  useEffect(() => {
    const isSelected = bncc.ef.knowledge_areas.includes(knowledgeArea.name)
    
    if (anyCurricularComponentSelected ) {
      if(isSelected) return
      console.log("Adicionando")
      handleKnowledgeArea(knowledgeArea.name, false)
      return
    }

    handleKnowledgeArea(knowledgeArea.name, true)
  }, [bncc.ef.curricular_components])

  return (
    <div>
      <RowKnowledge $color={color}>
        <ItemKnowledge $color={color} key={knowledgeArea.name}>
          <Checkbox
            checked={anyCurricularComponentSelected}
            line={!allCurricularComponentSelected}
            onChange={handleAllCurricularComponents}
            type="checkbox"
            color={color}
          />
          <span className="name"> {knowledgeArea.name}</span>

          <ArrowDown
            onClick={handleVisibility}
            className={`arrow ${isOpen ? "open" : "close"}`}
            size={15}
          />
        </ItemKnowledge>
        <span className="title info-knowledge">{`Competências Específicas de ${knowledgeArea.name}`}</span>
        <div className="icon">
          <FileText size={15} />
        </div>
      </RowKnowledge>
      <TableKnowledge>
        {isOpen
          ? knowledgeArea.curricular_components.map(
            (component, indexCurricular: number) => {
              const componentSelect = bncc.ef.curricular_components.includes(
                knowledgeArea.curricular_components[indexCurricular].name
              );

              const knowledgeObjectsGeneral = knowledgeArea.curricular_components[
                indexCurricular
              ]
              const knowledgeObjectsThematicsUnits = knowledgeObjectsGeneral.fields_action ? knowledgeObjectsGeneral.fields_action.reduce((acc: IBNCCThematicUnit[], item) => [...acc, ...item.thematic_units], []) : knowledgeObjectsGeneral.thematic_units ?? [] as IBNCCThematicUnit[]


              const knowledgeObjects = knowledgeObjectsThematicsUnits.reduce(
                (acc: string[], value) => [
                  ...acc,
                  ...value.knowledge_objects,
                ],
                []
              )
                .reduce((acc: string[], item) => {
                  return [...acc, item];
                }, [])
                .filter((item: any) =>
                  bncc.ef.knowledge_objects.some((object) => object === item)
                )
                .filter(
                  (value: any, index: any, self: any) => self.indexOf(value) === index
                );

              const columnExtra =
                knowledgeArea.curricular_components[indexCurricular].name ===
                  "Língua Portuguesa"
                  ? "Campos de Atuação"
                  : knowledgeArea.curricular_components[indexCurricular]
                    .name === "Língua Inglesa"
                    ? "Eixos"
                    : undefined;

              const labelUnitThematic =
                knowledgeArea.curricular_components[indexCurricular].name ===
                  "Língua Portuguesa"
                  ? "Práticas de Leitura"
                  : "Unidades Temáticas";
              const existColumnExtra = knowledgeArea.name === "Linguagens";

              // const fieldAction = existColumnExtra
              //   ? knowledgeArea.curricular_components[
              //     indexCurricular
              //   ].thematic_units
              //     .map((action) => action.name)
              //     .filter((item) =>
              //       bncc.ef.fields_action.some(
              //         (selected) => selected === item
              //       )
              //     ).filter(
              //       (value, index, self) => self.indexOf(value) === index
              //     )
              //   : [];

              const thematicUnits = knowledgeObjectsThematicsUnits
                .reduce(
                  (acc: string[], value) => [
                    ...acc,
                    ...value.knowledge_objects,
                  ],
                  []
                )
                .map((item) => item)
                .filter((item) =>
                  bncc.ef.thematic_units.some((object) => object === item)
                ).filter(
                  (value, index, self) => self.indexOf(value) === index
                );

              console.log(thematicUnits)


              return (
                <>
                  <thead>
                    <tr>
                      <td style={{ width: "205px" }}>
                        Componentes Curriculares
                      </td>
                      {existColumnExtra ? (
                        columnExtra ? (
                          <td style={{ width: "200px" }}>
                            <div className="cell-row">
                              {columnExtra}
                              <div
                                className="icon"
                                onClick={() => {
                                  openOptions(
                                    component.name,
                                    "fields_actions"
                                  );
                                }}
                              >
                                <FileText size={15} />
                              </div>
                            </div>
                          </td>
                        ) : (
                          <td></td>
                        )
                      ) : null}
                      <td style={{ width: "200px" }}>
                        <div className="cell-row">
                          {labelUnitThematic}
                          <div
                            className="icon"
                            onClick={() => {
                              openOptions(component.name, "thematic_units");
                            }}
                          >
                            <FileText size={15} />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="cell-row">
                          Objetos do conhecimento{" "}
                          <div
                            className="icon"
                            onClick={() => {
                              openOptions(component.name, "object_knowledge");
                            }}
                          >
                            <FileText size={15} />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <span className="option-table">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleComponentCurricular(
                                component.name,
                                componentSelect
                              )
                            }
                            checked={
                              componentSelect
                            }
                          />
                          {component.name}
                        </span>
                      </td>
                      {existColumnExtra ? (
                        <td>
                          {/* <div className="td-item">
                            {fieldAction.map((item) => {
                              return (
                                <span
                                  className="item-select"
                                  key={`${item}_field`}
                                >
                                  {item}
                                </span>
                              );
                            })}
                          </div> */}
                        </td>
                      ) : null}
                      <td>
                        <div className="td-item">
                          {thematicUnits.map((item: any) => {
                            console.log(item)
                            return (
                              <span
                                className="item-select"
                                key={`${item}_thematic`}
                              >
                                {item}
                              </span>
                            );
                          })}
                        </div>
                      </td>
                      <td>
                        {knowledgeObjects.map((item: any) => {
                          return (
                            <div
                              key={`${item}_object`}
                              className="item-select"
                            >
                              <span>{item}</span>
                            </div>
                          );
                        })}
                      </td>
                    </tr>
                  </tbody>
                </>
              );
            }
          )
          : null}
      </TableKnowledge>
    </div>
  );
}
