import React, { useEffect, useState } from "react";
import { Box, Table } from "../../UserRegistration/FormTeacher/ImportExcel/styles";
import UnidadeService from "../../../../Services/Unidade.service";

import makeAnimated from 'react-select/animated';
import { Class, Unit, UnitSelected } from "../../../../Utils/Types";
import { string } from "yargs";
import TurmaService from "../../../../Services/Turma.service";
import { useQuery } from "react-query";
import { minutesToMilliseconds } from "../../../../Utils/Functions";
import Row from "./Row/Row";
import Select from 'react-select'


interface Props {
    tableName?: string,
    units: Unit[],
    unitSelected?: string,
    setUnitSelected?: any,
    listClass: Class[],
    classSelected?: string[],
    setClassSelected?: any,
    selectedUnits: UnitSelected[],
    setSelectedUnits?: any,
}

export function TableExcel({
    tableName,
    units,
    unitSelected,
    classSelected,
    listClass,
    setClassSelected,
    setUnitSelected,
    selectedUnits,
    setSelectedUnits,
    ...props
}: Props) {



    const [unitsArray, setUnitsArray] = useState([
        { value: '', label: '' }
    ]);


    const [classArray, setClassArray] = useState([
        { value: '', label: 'Todas as turmas' }
    ])

    const animatedComponents = makeAnimated();

    useEffect(() => {
        setUnitsArray([])
        units?.map((unit) => {
            setUnitsArray((state) => {
                return [...state, { label: unit.name, value: unit.identifier }]
            })
        })



    }, [])

    useEffect(() => {

        if (unitSelected === "") {
            return
        }

        TurmaService.list({ idState: unitSelected }).then((response) => {
            if (response.success) {
                response.data.map((item: any) => {
                    setClassArray((state) => {
                        return [...state, { label: item.name, value: item.identifier }]
                    })
                    setUnitSelected("")
                })
            }
        })
        setClassArray([])
    }, [unitSelected])



    const addNewUnit = (id: string) => {
        const findUnit = unitsArray.find((item) => item.value === id);
        setSelectedUnits((state: UnitSelected[]) =>
            [...state, {
                unit: findUnit,
                classrooms: []
            }]
        )
    }

    function handleUnit(idNew: any, idAge: string) {
        const findId = unitsArray.find((item) => item.value === idNew);
        const index = selectedUnits.findIndex((obj) => obj.unit.value === idAge);

        if (!findId || index === -1) {
            return
        }
        selectedUnits[index] = {
            unit: findId,
            classrooms: []
        }
        setSelectedUnits([...selectedUnits])
    }

    function addClassArray(idUnit: any, classe: Class) {
        const index = selectedUnits.findIndex((obj) => obj.unit.value === idUnit);

        if (index === -1) {
            return
        }
        selectedUnits[index] = {
            ...selectedUnits[index],
            classrooms: [...selectedUnits[index].classrooms, classe]
        }
        setSelectedUnits([...selectedUnits])
    }

    function setClass(idUnit: any, classe: Class[]) {
        const index = selectedUnits.findIndex((obj) => obj.unit.value === idUnit);
        
        if (index === -1) {
            return
        }
        selectedUnits[index] = {
            ...selectedUnits[index],
            classrooms: classe
        }
        setSelectedUnits([...selectedUnits])
    }

    function removeClass(idUnit: any, idClass: string) {
        const index = selectedUnits.findIndex((obj) => obj.unit.value === idUnit);

        if (index === -1) {
            return
        }
        selectedUnits[index] = {
            ...selectedUnits[index],
            classrooms: selectedUnits[index].classrooms.filter((item) => item.identifier !== idClass)
        }
        setSelectedUnits([...selectedUnits])
    }


    const filteredUnits = unitsArray.filter((opt) => !selectedUnits.some((item) => item.unit.value === opt.value));

    function renderNewUnit() {
        if (selectedUnits) {
            return (
                <>
                    {
                        selectedUnits.map((unit) =>
                            <Row
                                filteredUnits={filteredUnits}
                                unit={unit}
                                handleUnit={handleUnit}
                                setUnitSelected={setUnitSelected}
                                animated={animatedComponents}
                                addClass={addClassArray}
                                removeClass={removeClass}
                                setClass={setClass}
                            />
                        )
                    }
                </>

            )
            return null
        }
    }



    return (
        <>
            <Box>
                <Select
                    className="select"
                    options={filteredUnits}
                    value={null}
                    placeholder="Selecione uma unidade..."
                    
                    onChange={(e: any) => {
                        setUnitSelected(e.value)
                        addNewUnit(e.value)
                    }}

                />
                <br />
                <Table>
                    <p>{tableName}</p>
                    <table>
                        <thead>
                            <tr>
                                <td>Unidade(s) Educacional(is)</td>
                                <td>Turma(s)</td>
                            </tr>
                        </thead>
                        <tbody>
                            {renderNewUnit()}
                        </tbody>
                    </table>
                </Table>
            </Box>
        </>
    )
}