import styled from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  border: 1px solid #bcbcbc;
  border-radius: 8px;
  padding: 21px;
  color: #646464;
  > div.head {
    padding-bottom: 10px;
    border-bottom: 1px solid #bcbcbc;
    h3 {
      color: var(--blue);
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
    }
  }
  > div.body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
    height: 300px;
    overflow-y: scroll;
    padding-right: 5px;

    &::-webkit-scrollbar {
      width: 10px; /* width of the entire scrollbar */
    }

    &::-webkit-scrollbar-track {
      background: #e9e9e9; /* color of the tracking area */
      border-radius: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #027294; /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }
  }
`

export const Item = styled.div`
  background-color: #e9e9e9;
  border: 1px solid #bcbcbc;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25);
  padding: 12px;
  color: #646464;
  > h4 {
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
  }
  > p {
    font-weight: 400;
    max-width: 45ch;
    font-size: 12px;
    white-space: nowrap; // Removendo quebra de linha
    overflow: hidden; // Removendo a barra de rolagem
    text-overflow: ellipsis; // Adicionando "..." ao final do texto
  }
  div.foot {
    border-top: 1px solid #646464;
    margin-top: 8px;
    padding-top: 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > p {
      font-weight: 700;
      font-size: 11px;
    }
    span {
      font-size: 12px;
    }
  }
`
