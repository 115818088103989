import { FileText } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalList } from "../../../../Components/Modals/ModalList";
import { ProfileDatas } from "../../../../Components/ProfileDatas";
import { Container, Datas } from "./styles";
import { TypeClass } from "../../../../Utils/Types";
import { useDetailsClass } from "../../../../hooks/details/useDetailsClass";
import { ListViewClass } from "../../../../types/ClassTypes/typeClass";

interface ClassProps {
    back: () => void;
    edit: () => void;
    classroom: ListViewClass | null;
}

export function ClassProfile({ back, edit, classroom }: ClassProps) {
    const [switchModal, setSwitchModal] = useState(false);
    const [modalSelected, setModalSelected] = useState("");
    const schoolSelect = JSON.parse(sessionStorage.getItem("locationData")!);
    console.log(schoolSelect)
    const navigate = useNavigate();
    const onEdit = () => navigate('/turma/edit', { state: classroom });

    console.log(classroom)

    const {
        data: detailsClass, isLoading: isLoadingClass,
        isSuccess: isSuccessClass, isError: isErrorClass
    } = useDetailsClass(schoolSelect.identifier, classroom?.identifier ?? '')


    const renderStudents = () => {
        return (
            <ul>
                {
                    isLoadingClass ?
                        <td>Carregando...</td>
                        : isErrorClass ?
                            <td>Erro ao carregar os dados</td>
                            :
                            isSuccessClass && detailsClass?.students?.length ?
                                detailsClass?.students.map((student: any) => (
                                    <li key={student.identifier}>-- {student.name}</li>
                                ))
                                : <td>A turma não tem aluno cadastrado.</td>
                }

            </ul>
        )
    }

    const renderEmployees = () => {
        return (
            <ul>
                {
                    isLoadingClass ?
                        <td>Carregando...</td>
                        : isErrorClass ?
                            <td>Erro ao carregar os dados</td>
                            :
                            isSuccessClass && detailsClass?.employees?.length ?
                                detailsClass?.employees.map((employee: any) => (
                                    <li key={employee.identifier}>{employee.name}</li>
                                ))
                                : <td>A turma não tem professor cadastrado.</td>
                }
            </ul>
        )
    }

    const educationalStageLabel = classroom?.stage ?
        `${classroom.stage.name}, ${classroom.stage.name}`
        : "Não informado";
    const phaseLabel = classroom?.phase ? classroom.phase : "Não informado";
    const ageRangeLabel = classroom?.name ? classroom.year.name : "Não informado";
    const visibleAgeRange = classroom?.modalities.length !== 0; //const visibleAgeRange = classroom?.modality !== "EJA";

    return (
        <Container>
            <ModalList
                onRequestClose={() => setSwitchModal(false)}
                isOpen={switchModal}
                title={modalSelected}
            >
                <tfoot className="render-list">
                    {modalSelected === "Professores" ? renderEmployees() : renderStudents()}
                </tfoot>

            </ModalList>
            <ProfileDatas
                page="Perfil da Turma"
                onBack={back}
                onEdit={onEdit}
                labelPlural="turmas"
                labelSingle="turma"
            />
            <Datas>
                <main>
                    <p>Nome da Unidade Educacional: {schoolSelect.name ?? ""}</p>
                    <p>Nível de ensino</p>
                    <p>Etapa de Ensino: {educationalStageLabel}</p>
                    <p>Fase: {phaseLabel}</p>
                    {visibleAgeRange ? <p>Ano/faixa etária: {ageRangeLabel}</p> : null}
                    <p>
                        Modalidades: {classroom && classroom.modalities?.length
                            ? classroom.modalities.join(', ')
                            : "Não informado"}
                    </p>
                    {/* <p>Modalidades: {classroom?.modality??"Não informado"}</p> */}
                    <span></span>
                    <p>Nome da turma: {classroom?.name ?? ""}</p>
                    <p>Ano letivo: {classroom?.year.name ?? ""}</p>
                    <span></span>
                    <p>
                        Professores:{" "}
                        <button
                            onClick={() => {
                                setSwitchModal(true);
                                setModalSelected("Professores");
                            }}
                            className="callList"
                        >
                            {" "}
                            <FileText size={14} />{" "}
                        </button>
                        {" "}
                    </p>
                    <p>
                        Estudantes:{" "}
                        <button
                            onClick={() => {
                                setSwitchModal(true);
                                setModalSelected("Estudantes");
                            }}
                            className="callList"
                        >
                            {" "}
                            <FileText size={14} />{" "}
                        </button>
                        {" "}
                    </p>
                </main>
            </Datas>
        </Container>
    );
}