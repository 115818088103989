import { Trash2 } from "lucide-react";
import { useContext } from "react";
import { Table } from "../../../../../../Components/Form/Table";
import { IResourceCollection } from "../../../../../../reducers/newResource/reducer";
import { useListSignatures } from "../../hooks/useListSignatures";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";


export function TableCollection() {
  const { resource, handleFieldResource, removeCategoryCollectionSelects } =
    useContext(ResourceContext);
  const { collections, collectionDefault } = resource;
  const { data: listCollections } = useListSignatures();
  function handleCollectionDefault(idCollection: string) {
    handleFieldResource("collectionDefault", idCollection);
  }

  function deleteRow(collection: IResourceCollection) {
    removeCategoryCollectionSelects(collection);
  }

  return (
    <Table>
      <p>Vínculo com as coleções</p>
      <table>
        <thead>
          <td className="fx10">Coleção principal</td>
          <td className="fx15">Nome da Coleção</td>
          <td className="fx75">Caminho</td>
          <td></td>
        </thead>
        <tbody>
          {collections &&
            collections.map((collection) => {
              const nameCollection = listCollections?.find(
                (collectionR) =>
                  collectionR.identifier === collection.collection_id
              )?.name;
              const path = collection.path;
              const isCollectionDefault = collectionDefault === collection.collection_id
              
              function handleDelete() {
                deleteRow(collection);
              }

              function selectCollectionDefault() {
                handleCollectionDefault(collection.collection_id);
              }

              return (
                <tr key={collection.subcategory_id}>
                  {/* Botão para deixar coleção como padrão */}
                  <td>
                    <input
                      onChange={selectCollectionDefault}
                      type="radio"
                      checked={isCollectionDefault}
                    />
                  </td>
                  {/* Nome da Coleção */}
                  <td>{nameCollection}</td>
                  {/* Coluna para caminho de coleção */}
                  <td className="text-left">{path}</td>
                  {/* Coluna para exclusão de coleção selecionada */}
                  <td>
                    <button title="Excluir" onClick={handleDelete}>
                      <Trash2 size={16} className="hvr-grow" />
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </Table>
  );
}
