import React, { useContext, useEffect, useState } from 'react'
import { ContextUserGeneral, UnitReport } from '../../UserGeneral/ContextUserGeneral/ContextUserGeneral'
import { ListViewClass } from '../../../../../types/ClassTypes/typeClass'
import UnidadeService from '../../../../../Services/Unidade.service'
import TurmaService from '../../../../../Services/Turma.service'
import { ContainerTable } from '../../UserGeneral/BondTable/styles'
import ReactSelect from '../../../../../Components/Form/ReactSelect/ReactSelect'

const BontTableTeachers = () => {

  const { handleUnits, units } = useContext(ContextUserGeneral)
  const [unitSelected, setUnitSelected] = useState("")
  const [classes, setClasses] = useState<ListViewClass[]>([])


  useEffect(() => {

    UnidadeService.listAllUnit().then((res) => {
      if (res.success) {
        handleUnits(res.data.data)
      }
    })

  }, [])

  function handleUnit(e: UnitReport) {
    setUnitSelected(e.id)
  }

  useEffect(() => {

    if (!unitSelected) {
      return
    }

    TurmaService.list({ idState: unitSelected }).then((res) => {
      if (res.success) {
        setClasses(res.data)
      }
    })

  }, [unitSelected])

  return (
    <ContainerTable>
      <thead>
        Vinculos
      </thead>
      <tbody>
        <tr>
          <td>
            <ReactSelect
              flex='fx100'
              label=''
              placeholder='Selecione uma unidade'
              options={units}
              getOptionLabel={(e) => e.name}
              onChange={(e) => handleUnit(e)}
            />
          </td>
          <td>
            <ReactSelect
              flex='fx100'
              label=''
              placeholder='Selecione uma turma'
              disable={!unitSelected}
              options={classes}
              getOptionLabel={(e) => e.name}
            />
          </td>
        </tr>
      </tbody>
    </ContainerTable>
  )
}

export default BontTableTeachers