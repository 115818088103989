import React, { Dispatch, SetStateAction, useContext, useMemo, useRef, useState } from 'react'
import { ContainerEditor } from './styles'
import { Label } from '../../../../../Components/Form/Input/styles'
import JoditEditor from 'jodit-react'
import { IViewOptions } from 'jodit/esm/types'
import { ResourceContext } from '../../../../../Contexts/Form/ContextResource'

interface Props {
    text: string
    placeholder: string
    content: string,
    setContent: Dispatch<SetStateAction<string>>
}

const InputEditor = ({ text, placeholder,content,setContent }: Props) => {

    const editor = useRef(null)


    const editorConfig: any = {
        buttons: [
          'source', 'bold', 'italic', 'underline', 'strikethrough', '|',
          'superscript', 'subscript', '|', 'ul', 'ol', '|', 'outdent', 'indent', '|',
          'font', 'fontsize', 'brush', 'paragraph', '|', 'image', 'video', 'table', 'link', '|',
          'align', 'undo', 'redo', '|', 'hr', 'eraser', 'fullsize', 'print', 'about',
        ],
        placeholder: placeholder || "",
        readonly: false,
        toolbarAdaptive: false

      };

    function handleStoryBoard(value: string){
        console.log(content)
    }


    return (
        <ContainerEditor>
            <Label>
                {text}
            </Label>
            <JoditEditor
                ref={editor}
                value={content}
                config={editorConfig}
                onChange={handleStoryBoard}
                
            />

        </ContainerEditor>
    )
}

export default InputEditor