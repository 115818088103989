import { Button } from "./../../Form/ImportExcel/styles";
import styled from "styled-components";

export const Container = styled.div`
  border-radius: 15px;
  height: 100%;
  width: 100%;
  z-index: 100;
  max-width: 800px;
`;

export const Controlls = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  Button {
    height: 40px;
    border: none;
    border-radius: 5px;
    width: 100px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fff;
    background-color: #088a62;
    cursor: pointer;
    &:first-child {
      background-color: #666666;
    }
  }
`;

export const HeaderContainer = styled.header`
  background-color: #fe8d28;
  text-transform: uppercase;
  display: flex;
  position: relative;
  .title {
    color: #fff;
    padding: 2rem;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .icon-close {
    position: absolute;
    right: 1rem;
    top: 1rem;
    color: #fff;
    cursor: pointer;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const ButtonGreen = styled.button`
  margin-top: 1rem;
  height: 40px;
  width: 110px;
  border-radius: 10px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  align-self: flex-end;
  background-color: #088a62;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  :hover {
    background-color: #07a373;
  }
`;

export const ContainerList = styled.div`
  max-height: 70vh;
  overflow-y: auto;
  border: 1px solid #818181;
  padding: 1rem;

  color: #646464;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
