import { FileText } from "lucide-react";
import ReactInputMask from "react-input-mask";
import { Container, Label, Text } from "./styles";
import { Search } from "lucide-react";
import {
  ContainerIcon,
  InputSearch,
  SearchIcon,
} from "../../../Pages/Registration/UserRegistration/styles";

export function Input(props: any) {
  const isText =
    !(props.type === "cpf") &&
    !(props.type === "cnpj") &&
    !(props.type === "cep") &&
    !(props.type === "date") &&
    !(props.type === "tel") &&
    !(props.type === "email") &&
    !(props.type === "consult") &&
    !(props.type === "quantity") &&
    !(props.type === "tel2") &&
    !(props.type === "text") &&
    !(props.type === "editModi") &&
    !(props.type === "search") &&
    !(props.type === "searchIcon") &&
    !(props.type === "textIcon");

  return (
    <Container className={props.flex}>
      <Label htmlFor={props.inputId}>
        {props.label}
        {props.required ? "*" : ""}
      </Label>
      {props.type === "cpf" && (
        <ReactInputMask
          required={props.required ? true : false}
          mask={"999.999.999-99"}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value.replace(/\D/g, ""))}
        />
      )}
      {props.type === "cnpj" && (
        <ReactInputMask
          required={props.required ? true : false}
          mask={"99.999.999/9999-99"}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value.replace(/\D/g, ""))}
        />
      )}
      {props.type === "cep" && (
        <ReactInputMask
          required={props.required ? true : false}
          mask={"99999-999"}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value.replace(/\D/g, ""))}
        />
      )}
      {props.type === "date" && (
        <ReactInputMask
          required={props.required ? true : false}
          mask={"99/99/9999"}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value.replace(/\D/g, ""))}
        />
      )}
      {props.type === "tel" && (
        <ReactInputMask
          required={props.required ? true : false}
          mask="(99) 99999-9999"
          value={props.value}
          onChange={(re) => props.onChange(re.target.value.replace(/\D/g, ""))}
        />
      )}
      {props.type === "tel2" && (
        <Text
          required={props.required ? true : false}
          type="number"
          id={props.inputId}
          value={props.value}
          onChange={(re) => {
            let val = re.target.value.slice(0, 11);
            props.onChange(val);
          }}
          style={{ appearance: "textfield" }}
        />
      )}
      {props.type === "email" && (
        <Text
          required={props.required ? true : false}
          type="email"
          id={props.inputId}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value)}
        />
      )}
      {props.type === "quantity" && (
        <Text
          required={props.required ? true : false}
          type="number"
          id={props.inputId}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value)}
        />
      )}
      {(props.type === "text" || isText) && (
        <Text
          required={props.required ? true : false}
          id={props.inputId}
          value={props.value}
          onChange={(re) => props.onChange(re.target.value)}
          placeholder={props.placeHolder}
          disabled={props.disable}
          style={{ backgroundColor: props.disable ? '#9DDBF0' : 'inherit' }}
        />
      )}
      {(props.type === "editModi" || isText) && (
        <Text
          required={props.required ? true : false}
          id={props.inputId}
          value={props.value}
          name={props.name}
          onChange={props.onChange}
          placeholder={props.placeHolder}
        />
      )}
      {props.type === "consult" && (
        <div className="consult">
          <Text
            required={props.required ? true : false}
            id={props.inputId}
            value={props.value}
            onChange={(re) => props.onChange(re.target.value)}
          />
          <button
            onClick={() => props.onClickConsult()}
            className="hvr-pop"
            title="Consultar"
          >
            <FileText size={12} />
          </button>
        </div>
      )}
      {props.type === "search" && (
        <InputSearch>
          <input
            type="text"
            required={props.required ? true : false}
            id={props.inputId}
            value={props.value}
            onChange={(re) => props.onChange(re.target.value)}
            placeholder={props.placeHolder || props.placeHolderTeacher}
            // onKeyDown={props.onKeyDown()}
          />
          <Search color={props.color || "#000000"} />
        </InputSearch>
      )}
      {props.type === "searchIcon" && (
        <ContainerIcon>
          <SearchIcon>
            <input
              type="text"
              required={props.required ? true : false}
              id={props.inputId}
              value={props.value}
              onChange={(re) => props.onChange(re.target.value)}
              placeholder={props.placeHolder || props.placeHolderTeacher}
            />
            <Search color={props.color || "#000000"} />
          </SearchIcon>
          {props.children}
        </ContainerIcon>
      )}
      {props.type === "textIcon" && (
        <ContainerIcon>
          <Text
            required={props.required ? true : false}
            id={props.inputId}
            value={props.value}
            onChange={(re) => props.onChange(re.target.value)}
            placeholder={props.placeHolder}
          />
          {props.children}
        </ContainerIcon>
      )}
    </Container>
  );
}
