import styled from 'styled-components'
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 50%;
`

export const Search = styled.div`
  height: 30px;
  position: relative;
  input {
    height: 30px;
    width: 100%;
    border: 1px solid #646464;
    border-radius: 15px;
    padding: 0px 35px 0px 10px;
  }
  button {
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
`

export const List = styled.table`
  border: 1px solid #646464;
  width: 100%;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 20px;
  thead {
    td {
      text-align: center;
      border-bottom: 1px solid #646464;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  tbody {
    tr {
      background-color: #fff;
      &:hover {
        background-color: #eee;
      }
      td {
        padding: 5px 0px;
        font-size: 16px;
        color: #262626;
        vertical-align: baseline;
        &:first-child {
          width: 30px;
        }
      }
    }
  }
`

export const Show = styled.div`
width: 100%;
border: 1px solid #646464;
border-radius: 15px;
height: 40px;
display: flex;
align-items: center;
justify-content: flex-start;
padding: 0px 15px;
  p {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 3px;
  > button.showList {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    color: #fff;
    cursor: pointer;
    background-color: #fe8f2e;
  }
  > button.delete {
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    color: #000;
    cursor: pointer;
  }
`
