import React, { useEffect, useState } from 'react'
import Select from 'react-select';
import UnidadeService from '../../../../../Services/Unidade.service';
import { useQuery } from 'react-query';
import { minutesToMilliseconds } from '../../../../../Utils/Functions';
import { Class } from '../../../../../Utils/Types';
const Row = (props: any) => {

    const { data, isLoading, isError, isSuccess } = useQuery(["class", props.unit.unit.value],
        () => UnidadeService.showUnit({ idUnit: props.unit.unit.value }), {
        cacheTime: minutesToMilliseconds(2),
        staleTime: minutesToMilliseconds(10),
    }
    )

    // const optionsClass = isSuccess && data.success && data.data.classrooms.map((item: Class) => {

    //     return (
    //         {

    //             value: item.identifier,
    //             label: item.name
    //         }
    //     )
    // })

    let optionsClass = [];

    if (isSuccess && data.success) {
        optionsClass = data.data.classrooms.map((item: Class) => ({
            value: item.identifier,
            label: item.name
        }));

        optionsClass.unshift({
            value: 'allOptions',
            label: 'Selecionar todas as turmas',
        });
    }

    const optionsSelect = props.unit.classrooms.map((classe: any) => {
        return {
            value: classe.identifier,
            label: classe.name
        }
    })

    function handleClass(value: any) {

        const selectAllOptionSelected = value.some((valueItem: any) => valueItem.value === 'allOptions');

        const classes = isSuccess && data.success ? (selectAllOptionSelected ? data.data.classrooms : data.data.classrooms.filter((item: any) =>
            value.some((valueItem: any) => valueItem.value === item.identifier)
        )) : [];

        props.setClass(props.unit.unit.value, classes);
    }




    return (
        <>
            <tr>
                <td>
                    <Select
                        options={props.filteredUnits}
                        className="select"
                        value={props.unit.unit}
                        placeholder="Selecione uma unidade..."
                        onChange={(e: any) => {
                            props.handleUnit(e.value, props.unit.unit.value);
                            props.setUnitSelected(e.value)
                        }}
                    />
                </td>
                <td>
                    {
                        optionsClass.length !== 1
                            ?
                            <Select
                                components={props.animated}
                                isMulti
                                value={optionsSelect}
                                options={optionsClass}
                                placeholder="Selecione as turmas..."
                                isLoading={isLoading}
                                onChange={handleClass}
                                closeMenuOnSelect={false}
                            />
                            :
                            <Select
                                components={props.animated}
                                isMulti
                                value={optionsSelect}
                                options={optionsClass}
                                placeholder="Não há turmas nesta unidade"
                                isLoading={isLoading}
                                onChange={handleClass}
                                isDisabled={true}
                                closeMenuOnSelect={false}
                            />
                    }
                </td>
            </tr>
        </>
    )
}

export default Row
