import { useEffect, useState } from 'react'
import { SuperBox } from '../../../../Components/SuperBox'
import { Form1 } from './Form1'
import { Form2 } from './Form2'
import {
  Container,
  Main,
  Sitemap,
  Abas,
  Form,
  ClientName,
  Radio,
  MoreStudent,
} from '../../../Registration/UnitRegistration/styles'
import { api } from '../../../../Services/api'

export function UnitRegistrationEdit(props: any) {
  const [switchForm, setSwitchForm] = useState(1)
  const [modalSuccess, setModalSuccess] = useState(false)
  const [nameValue, setNameValue] = useState('')
  const [telValue, setTelValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [cepValue, setCepValue] = useState('')
  const [bairroValue, setBairroValue] = useState('')
  const [complementoValue, setComplementoValue] = useState('')
  const [cidadeValue, setCidadeValue] = useState('')
  const [ruaValue, setRuaValue] = useState('')
  const [ufValue, setUfValue] = useState('')
  const [numberValue, setNumberValue] = useState('')
  const identifier = props.selected

  useEffect(() => {
    const token = `bearer ${localStorage.getItem('token')}`

    if (props.selected === '') {
      alert('Selecione um cliente')
      props.back()
    } else {
      api
        .get(`/admin/education-institutions/${props.selected}`, {
          headers: {
            Authorization: token,
          },
        })
        .then((response) => {
          sessionStorage.setItem('locationData', JSON.stringify(response.data.data));
          setNameValue(response.data.data.name)
          setTelValue(response.data.data.phone)
          setEmailValue(response.data.data.email)
          setCepValue(response.data.data.address.zipCode)
          setBairroValue(response.data.data.address.neighborhood)
          setComplementoValue(response.data.data.address.complement)
          setCidadeValue(response.data.data.address.city)
          setRuaValue(response.data.data.address.address)
          setUfValue(response.data.data.address.state)
          setNumberValue(response.data.data.address.number)


        })

    }
  }, [])

  return (
      <Container>
        <div className="box">
          <Main>
            <ClientName>{localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client') || '').socialName : ''}</ClientName>
            {switchForm === 1 && <Form1 next={() => setSwitchForm(2)} 
              nameValue={nameValue}
              setNameValue={setNameValue}
              telValue={telValue}
              setTelValue={setTelValue}
              emailValue={emailValue}
              setEmailValue={setEmailValue}
              cepValue={cepValue}
              setCepValue={setCepValue}
              bairroValue={bairroValue}
              setBairroValue={setBairroValue}
              complementoValue={complementoValue}
              setComplementoValue={setComplementoValue}
              cidadeValue={cidadeValue}
              setCidadeValue={setCidadeValue}
              ruaValue={ruaValue}
              setRuaValue={setRuaValue}
              ufValue={ufValue}
              setUfValue={setUfValue}
              numberValue={numberValue}
              setNumberValue={setNumberValue}
              selected={props.selected}
            />}
            {switchForm === 2 && (
              <Form2
                next={() => setSwitchForm(2)}
                prev={() => setSwitchForm(1)}
                nameValue={nameValue}
                telValue={telValue}
                emailValue={emailValue}
                cepValue={cepValue}
                bairroValue={bairroValue}
                complementoValue={complementoValue}
                cidadeValue={cidadeValue}
                ruaValue={ruaValue}
                ufValue={ufValue}
                numberValue={numberValue}
                selected={props.selected}
              />
            )}
          </Main>
        </div>
      </Container>
  )
}
