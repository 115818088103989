import { api } from "./api";
import { CreateSuccess, Forbidden, ServiceError, Success, Unauthorized } from "../Utils/Responses";
import { Guardian } from "../Utils/Types";

type GuardianCreateProps = {
    educationInstitution: string,
    guardian: Guardian
}

type GuardianListProps = {
    educationInstitution: string,

}
type GuardianUpdateProps = {
    educationInstitution: string,
    guardian: Guardian
}

type GuandianCreateResponse =
    CreateSuccess |
    Unauthorized;

type GuardianListResponse =
    Success |
    Unauthorized |
    ServiceError;

type GuardianUpdateResponse =
    Success |
    Unauthorized;


type GuardianDeleteProps = {
    educationInstitution: string;
    idGuardian: string;
}

type GuardianDeleteResponse =
    Success | Unauthorized | Forbidden;

type GuardianShowProps = {
    idUnit: string,
    idGuardian: string
}

type GuardianShowResponse = Success | Unauthorized | ServiceError;



class GuardianService {

    static async showGuardian(props: GuardianShowProps): Promise<GuardianShowResponse> {
        try {
            const { idGuardian, idUnit } = props

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.get(
                `/admin/education-institutions/${idUnit}/guardians/${idGuardian}`,
                config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }


        } catch (error: any) {
            throw new Error("Can show guardian.")
        }
    }

    static async delete(input: GuardianDeleteProps): Promise<GuardianDeleteResponse> {
        const { educationInstitution, idGuardian } = input

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.delete(
                `/admin/education-institutions/${educationInstitution}/guardians/${idGuardian}`,
                config
            )

            return response.data;
        } catch (error: any) {
            throw new Error("Can not delete Guardian.")
        }

    }


    static async create(input: GuardianCreateProps): Promise<GuandianCreateResponse> {
        // TODO: create 

        const { educationInstitution, guardian } = input

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }

        try {
            const response = await api
                .post<GuandianCreateResponse>(
                    `/admin/education-institutions/${educationInstitution}/guardians`,
                    {
                        ...guardian,
                        address: guardian.name !== "" ? null : guardian.address
                    },
                    config
                )
            return response.data;
        } catch (error: any) {
            alert(error)
            throw new Error("Can not create Guardian.")
        }

    }

    static async list(input: GuardianListProps): Promise<GuardianListResponse> {

        const { educationInstitution } = input

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }

        try {
            const response = await api.get(
                `/admin/education-institutions/${educationInstitution}/guardians`,
                config
            )
            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }
        } catch (error) {
            throw new Error("Can not List Guardians.")
        }
    }
    static async update(input: GuardianUpdateProps): Promise<GuardianUpdateResponse> {

        const { educationInstitution, guardian } = input

        const identifier = guardian.identifier;

        delete guardian.identifier;



        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }

        try {
            const response = await api.put<GuardianUpdateResponse>(
                `/admin/education-institutions/${educationInstitution}/guardians/${identifier}`,
                {
                    ...guardian
                },
                config
            )
            return response.data;
        } catch (error: any) {
            console.log("Error: ", error.response.data);
            throw new Error("Can not update Guardian.")
        }

    }

}


export default GuardianService; 
