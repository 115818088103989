import { useMutation, useQueryClient } from "react-query";
import ResourceService from "../../../../Services/Resouce.service";
import { IResourceState } from "../../../../reducers/newResource/reducer";
import { Queries } from "../../../../types/queries";
import { useContext } from "react";
import { ResourceContext } from "../../../../Contexts/Form/ContextResource";
import { basicCompetencesBNCC, learningRightsBNCC } from "../../../../Utils/Const";

export function useCreateResource() {
  const queryClient = useQueryClient();

  const {idResource, setIdResource} = useContext(ResourceContext)

  

  return useMutation(
    (resource: IResourceState) => {
      const data = {
        ...resource,

        active: resource.active ? "1" : "0",
        
        collections: resource.collections.map((collection) => {
            return {
                collection_id: collection.collection_id,
                subcategory_id: collection.subcategory_id,
                default: resource.collectionDefault === collection.collection_id ? "1" : "0",
            }
        }),
        test: resource.test ? "1" : "0",
        target_audiences: resource.target_audience,
        published: resource.published ? "1" : "0",
        pedagogical_guideline: resource.pedagogical_guideline.guideline
    }


      // const data = new FormData();
      // console.log(resource)
      // data.append("name", resource.name);
      // data.append("format", resource.format);
      // data.append("url", resource.url)

      // resource.thumbnails.forEach((thumbs) => {
      //   data.append("thumbnails[]", thumbs);
      // })


      // data.append("language", resource.language);

      // data.append("test", "0");
      // data.append("active", "0");
      // data.append("published", "0");

      // // data.append(
      // //   "authorship[year]",
      // //   resource.authorshipYear?.toString() ?? ""
      // // );
      // for (const role in resource.authorship) {
      //   // Verificar se a chave é própria do objeto (não herdada)
      //   if (resource.authorship.hasOwnProperty(role)) {
      //     resource.authorship[
      //       role as keyof IResourceState["authorship"]
      //     ].forEach((name, index) => {
      //       data.append(`${role}[${index}]`, name);
      //     });
      //   }
      // }

      // resource.target_audience.forEach((audience) => {
      //   data.append("target_audiences[]", audience);
      // });

      // resource.plataforms.forEach((plataform) => {
      //   data.append("plataforms[]", plataform);
      // });

      // resource.collections.forEach((collection, index) => {
      //   const dataCollection = {
      //     collection_id: collection.collection_id,
      //     subcategory_id: collection.subcategory_id,
      //     default: resource.collectionDefault === collection.collection_id ? "1" : "0",
      //   };

      //   data.append(`collections[${index}][collection_id]`, dataCollection.collection_id
      //   );
      //   // data.append(
      //   //   `collections[${index}][subcategory_id]`,
      //   //   dataCollection.subcategory_id
      //   // );
      //   data.append(`collections[${index}][default]`, dataCollection.default);

      //   data.append(
      //     `collections[${index}][subcategory_id]`,
      //     dataCollection.subcategory_id
      //   );

      //   // data.append(
      //   //   `collections["collection_id"]`,
      //   //   dataCollection["collection_id"]
      //   // );
      //   // data.append(
      //   //   `collections["subcategory_id"]`,
      //   //   dataCollection["subcategory_id"]
      //   // );
      //   // data.append(
      //   //   `collections["default"]`,
      //   //   dataCollection["default"]
      //   // );
      // });

      // data.append("bncc[basic_competences]", "");
      // data.append("bncc[basic_competences]", "2");

      // const dataObject = {
      //   name: resource.name,
      //   format: resource.format,
      //   language: resource.language,
      //   image: resource.image,
      //   test: "0",
      //   active: "0",
      //   published: "0",
      //   authorship: resource.authorship,
      //   target_audience: resource.target_audience,
      //   plataforms: resource.plataforms,
      //   collections: resource.collections,
      // }
      return ResourceService.create(data).then((res)=> {
        setIdResource(res.data.id)
      });
    },
    {
      onError: (error: any) => {
        console.log(error);
        console.log("Error")

      },
      onSuccess: (data: any) => {
        console.log(data);
        console.log("Dados")
        queryClient.invalidateQueries(Queries.LIST_RESOURCES)
      },
    }
  );
}
