import styled from 'styled-components';

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: flex-end;
gap: 20px;

h1{
    font-weight: 700;
    font-size: 20px;
}

a{
    background-color: #027294;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    border-radius: 5px;
    width: 176px;
    border: none;
    cursor: pointer;
    text-decoration: none;
}

button{
    width: 110px;
    height: 40px;
    top: 229px;
    left: 637px;
    border-radius: 10px;
    border: none;
    gap: 10px;
    color: #ffffff;
    background-color: #646464;
}    

`;