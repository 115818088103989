import styled from "styled-components";

export const ContainerSubcategorys = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
width: 600px;
height: auto;
min-height: 400px;
flex-direction: column;

.div-name-category{

    display: flex;
    justify-content: center;

}
`

interface BackgroundProps {
    $back?: string
}

export const ContainerLevels = styled.div`

display: flex;
flex-direction: column;
width: 100%;
height: auto;
min-height: 100%;

`

export const ContainerLevelOne = styled.div<BackgroundProps>`

display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
height: auto;
min-height: 100px;

.container-name-level-one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 90%;
    height: 100%;
    /* background-color: #e9e9ee; */
    background-color: ${({ $back }) => $back || "#e9e9ee"};
}
.container-input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
}

`


export const ContainerMoreLevels = styled.div`
display: flex;
align-items: center;
width: 100%;
height: auto;
min-height: 50%;
background-color: #e9e9ee;

`
