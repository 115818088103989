import { Edit, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { CallRegister } from "../../../Components/CallRegister";
import { Divider } from "../../../Components/Form/Divider";
import { Input } from "../../../Components/Form/Input";
import { ListWithLogo } from "../../../Components/ListWithLogo";
import { ModalEdit } from "../../../Components/Modals/ModalEdit";
import { SearchTable } from "../../../Components/SearchTable";
import { SuperBox } from "../../../Components/SuperBox";
import { api } from "../../../Services/api";
import { ClientEdit } from "./Edit";
import { ClientProfile } from "./Profile";
import { Container, Main, Upload } from "./styles";
import { LoadingScreen } from "../../../Components/Loading";
import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../../../Assets/not-auth.svg";
import ClienteService from "../../../Services/Cliente.service";
import { transformUrlImage } from "../../../Utils/Functions";


interface TypeClient {
  identifier: string;
  socialName: string;
  phone: string;
  email: string;
  logo: string;
  address: {
    address: string;
    number: string;
    complement: string;
    zipCode: string;
    city: string;
    state: string;
  };
}

export function ListClient(id: any) {
  const [modalEdit, setModalEdit] = useState(false);
  const [switchScreen, setSwitchScreen] = useState(1);
  const [switchPageList, setSwitchPageList] = useState(
    "/admin/clients?limit=10&page=1"
  );

  const [clients, setClients] = useState<TypeClient[]>([]);

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [inputSearchView, setInputSearchView] = useState("")

  const [img, setImg] = useState<any>("");

  const navigate = useNavigate();
  const location = useLocation();

  const { client } = location.state ? location.state : { client: null };

  const [text, setText] = useState("")

  function handlePreview(e: any) {
    if (!e.target.files) return;
    setImg(e.target.files[0]);
  }

  useEffect(() => {
    if (client) {
      setSwitchScreen(2);
      setSelectedProfile(client.identifier);
    }
  }, [client]);

  useEffect(() => {
    ClienteService.get({ path: switchPageList })
      .then((res) => {
        if (res?.status === 200) {
          setClients(res.data!!.data!!);
          setPages(res.data.meta.links);
          setMetaTo(res.data.meta.to);
          setMetaTotal(res.data.meta.total);
          setIsLoading(false);
        } else {
          throw new Error(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
        }
      })
      .catch((err) => {
        alert(err.message);
        setIsLoading(false);
        window.location.reload();
      });
  }, [switchPageList]);
  useEffect(() => {
    if (switchScreen === 1) {
      ClienteService.get({ path: switchPageList })
      .then((res) => {
        if (res?.status === 200) {
          setClients(res.data!!.data!!);
          setPages(res.data.meta.links);
          setMetaTo(res.data.meta.to);
          setMetaTotal(res.data.meta.total);
          setIsLoading(false);
        } else {
          throw new Error(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
        }
      })
      .catch((err) => {
        alert(err.message);
        setIsLoading(false);
        window.location.reload();
      });
    }

    if(switchScreen === 1){
      setText("Listagem de Clientes")
    }else if(switchScreen === 2){
      setText("Visualizar Cliente")
    }else if(switchScreen === 3){
      setText("Editar Cliente")
    }
    
  }, [switchScreen]);

  console.log(clients)


  const [clientsFiltereds, setClientsFiltereds] = useState<any[]>([]);

  useEffect(()=>{

    let filteredClients = clients.filter((item: any) => item.socialName.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setClientsFiltereds(filteredClients)


  },[clients,inputSearchView])


  return (
    <LoadingScreen isLoading={isLoading}>
      <SuperBox switch={true} page={1} path={text}>
        <Container>
          <ModalEdit
            isOpen={modalEdit}
            onRequestClose={() => setModalEdit(false)}
            title="Cliente"
          >
            <Divider>
              <Input flex="fx80" label="Razão Social" inputId="nomeid" />
              <Input flex="fx20" label="CNPJ" inputId="nomeid" type="cnpj" />
            </Divider>
            <Divider>
              <Input flex="fx50" label="Telefone" inputId="telid" type="tel" />
              <Input flex="fx50" label="Email" inputId="emailid" />
            </Divider>
            <Divider>
              <Input flex="fx20" label="CEP" inputId="cepid" type="cep" />
              <Input flex="fx70" label="Rua/Avenida" inputId="ruaid" />
              <Input flex="fx10" label="Número" inputId="ruaid" />
            </Divider>
            <Divider>
              <Input flex="fx60" label="Complemento" inputId="complementoid" />
              <Input flex="fx40" label="Bairro" inputId="bairroid" />
            </Divider>
            <Divider>
              <Input flex="fx60" label="Cidade" inputId="cidadeid" />
              <Input flex="fx40" label="Estado" inputId="estadoid" />
            </Divider>
            <Upload>
              <label htmlFor="uploadInput">
                {img === "" ? (
                  <p>Inserir logo</p>
                ) : (
                  <img src={URL.createObjectURL(img)} alt="" />
                )}
              </label>
              <input
                type="file"
                name=""
                id="uploadInput"
                onChange={(e) => handlePreview(e)}
              />
            </Upload>
          </ModalEdit>
          <div className="box">
            {switchScreen === 1 && (
              <Main>
                <CallRegister
                  page="Cliente"
                  link="/clientes/registration"
                  onBack={() => {
                    navigate("/");
                  }}
                />
                <ListWithLogo
                  pages={pages}
                  changeGet={(v: any) => setSwitchPageList(v)}
                  to={metaTo}
                  total={metaTotal}
                >
                  <thead>
                    <tr>
                      <td colSpan={6}>
                        <Input
                          flex="fx30"
                          type="search"
                          placeHolderTeacher="Pesquise por um cliente"
                          color="#039bc9"
                          value={inputSearchView}
                          onChange={setInputSearchView}
                        />
                      </td>
                    </tr>
                    <tr >
                      <td className="wd10" colSpan={1}>Logo</td>
                      <td className="wd10">Razão Social</td>
                      <td className="wd10">Telefone</td>
                      <td className="wd10">E-mail</td>
                      <td className="wd10">Localidade</td>
                      <td className="wd20">Ação</td>
                    </tr>
                  </thead>
                  <tbody>
                    {clientsFiltereds.map((client) => {
                      return (
                        <tr key={client.identifier}>
                          <td>
                            <div className="logo">
                              <img
                                src={
                                  client.logo
                                    ? transformUrlImage(client.logo)
                                    : ""
                                }
                                alt=""
                              />
                            </div>
                          </td>
                          <td>
                            <button
                              className="profile"
                              type="button"
                              onClick={() => {
                                setSwitchScreen(2);
                                setSelectedProfile(client.identifier);
                              }}
                            >
                              {client.socialName}
                            </button>
                          </td>
                          <td>
                            {client.phone}
                          </td>
                          <td>
                            {client.email}
                          </td>
                          <td>
                            {client.address
                              ? `${client.address.city} - ${client.address.state}`
                              : "Não informado"}
                          </td>
                          <td>
                            <button
                              onClick={() => {
                                setSwitchScreen(3);
                                setSelectedProfile(client.identifier);
                              }}
                              className="hvr-grow"
                            >
                              <Edit />
                            </button>
                            <button className="hvr-grow">
                              <Trash />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </ListWithLogo>
              </Main>
            )}
            {switchScreen === 2 && (
              <ClientProfile
                back={() => setSwitchScreen(1)}
                edit={() => {
                  setSwitchScreen(3);
                  setSelectedProfile(selectedProfile);
                }}
                selected={selectedProfile}
              />
            )}
            {switchScreen === 3 && (
              <ClientEdit
                back={() => setSwitchScreen(2)}
                selected={selectedProfile}
              />
            )}
          </div>
        </Container>
      </SuperBox>
    </LoadingScreen>
  );
}
