import { useRef, useState } from "react";
import { ITutorialFields } from ".";
import { Input } from "../../../../Components/Form/InputHook";
import { TextArea } from "../../../../Components/TextAreaHook";
import { Title } from "../Form2/Subforms/styles";
import { Row } from "../styles";
import { Trash2 } from "lucide-react";

interface TutorialFieldProps {
  tutorial: ITutorialFields;
  position: number;
  handleTemplate: (template: ITutorialFields, position: number) => void;
  handleDelete: (position: number) => void;
}

export function TutorialItem({
  tutorial,
  position,
  handleTemplate,
  handleDelete,
}: TutorialFieldProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  function handleImage(event: React.ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;
    const image = event.target.files[0];
    const tutorialPayload = { ...tutorial, image };
    handleTemplate(tutorialPayload, position);
  }

  function deleteImage() {
    const tutorialPayload = { ...tutorial, image: undefined };
    handleTemplate(tutorialPayload, position);
  }

  function onDelete() {
    handleDelete(position);
  }

  const [radio1Checked, setRadio1Checked] = useState(false);
  const [radio2Checked, setRadio2Checked] = useState(false);

  const handleRadio1Change = () => {
    setRadio1Checked(true);
    setRadio2Checked(false);
  };

  const handleRadio2Change = () => {
    setRadio1Checked(false);
    setRadio2Checked(true);
  };
  return (
    <div>
      <input
        onChange={handleImage}
        type="file"
        ref={inputRef}
        accept="image/*"
        style={{ display: "none" }}
      />
      <Row $justify="flex-start">
        <div className="item-row">
          <Title>Tipo de anexo</Title>
          <div className="input-style">
            <input type="radio"  checked={radio1Checked} onChange={handleRadio1Change}/>
            <span className="label">Tutorial</span>
          </div>
          <div className="input-style">
            <input type="radio" checked={radio2Checked} onChange={handleRadio2Change}/>
            <span className="label">Versão para impressão</span>
          </div>
        </div>
        <div className="item-row">
          <span onClick={onDelete} className="span-delete">
            <Trash2 />
            Deletar anexo
          </span>
        </div>
      </Row>
      <Input type="text" label="Titulo" required={false} />
      <TextArea
        label="Descrição"
        flex="fx100"
        required={false}
        height="80px"
        type="type1"
      />

      {!tutorial.image ? (
        <div className="empty-img mt" onClick={() => inputRef.current?.click()}>
          <span>Clique aqui para inserir o arquivo.</span>
        </div>
      ) : (
        <div className="img-preview">
          <img
            onClick={() => inputRef.current?.click()}
            src={URL.createObjectURL(tutorial!.image!)}
            alt=""
          />
          <Trash2 onClick={deleteImage} className="icon-delete" />
        </div>
      )}
    </div>
  );
}
