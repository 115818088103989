import { useState } from "react";
import { Input } from "../../../Components/Form/Input";
import { Container, Footer, Header } from "./styles";
import { ModalShort } from "../../../Components/Modals/ModalShort";
import { Access } from "../Access";

interface ProfileProps {
    back: any,
    editViewMode: string
}

export function CustomFunctions({ back, editViewMode }: ProfileProps) {
    const [modalConfirm, setModalConfirm] = useState(false);


    return (
        <Container>

            <Header>
                <Input
                    label="Nome (Função / Cargo)"
                    type="text"
                    flex="fx40"
                    value={""}
                    onChange={""}
                >
                </Input>

                <div className="div-text-area">
                    <label htmlFor="">Descrição</label>
                    <textarea
                        value={""}
                    >
                    </textarea>
                </div>
            </Header>

            {/* acessos */}
            <Access 
                editViewMode={editViewMode}
            />

            <Footer>
                <span></span>
                <footer>
                    <button onClick={() => setModalConfirm(true)}>Cancelar</button>
                    <button>Salvar</button>
                </footer>
            </Footer>

            {modalConfirm && (
                <ModalShort
                    type="confirmSuccess"
                    isOpen={modalConfirm}
                    text={"TEM CERTEZA QUE DESEJA SAIR?"}
                    onRequestClose={() => {
                        setModalConfirm(false)
                    }}
                    onConfirm={back}
                />
            )}

        </Container>
    )
}