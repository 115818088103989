import styled from "styled-components";

interface DivProps{
    $alignsItems?: string;
    $marginTop?: string;
    $justifyContent?: string;
    $flexDirection?:string
}

export const Container = styled.div<DivProps>`
display: flex;
align-items: ${(props)=>props.$alignsItems || "center"};
margin-top: ${(props)=>props.$marginTop || "0px"};
`