import { Container, Item, Item2 } from './styles'

export function Atividade() {
  return (
    <Container>
      <div>
        <div className="head">
          <p>ATIVIDADES RECENTES</p>
        </div>
        <div className="body">
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
          <Item>
            <span>16/03/2023 às 22:00</span>
            <p>Transferência Turma A para Turma B</p>
          </Item>
        </div>
      </div>
      <div className='second'>
        <div className="head">
          <p>PENDÊNCIAS</p>
          <br />
        </div>
        <div className="body">
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
          <Item2>
            <span>CADASTRO INCOMPLETO</span>
            <p>- Cadastro de Usuário: Estudante</p>
          </Item2>
        </div>
      </div>
    </Container>
  )
}
