import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
}
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

export const Upload = styled.div`
>label{
    background-color: #BCBCBC;
    height: 115px;
    width: 115px;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;

    p{
        text-align: center;
    }
    img{
        height: 100%;
    }
}
>input{
    display: none;
}
`;

export const Unidades = styled.p`
    max-width: 150px; /* Tamanho */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
`;