import { useEffect, useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { ContainerResume } from "../styles";
import { api } from "../../../../Services/api";

export function FormSave(props: any) {
    const [modalSave, setModalSave] = useState(false);
    const [reason, setReason] = useState("");
    const [error, setError] = useState("")
    const [nameStudentsArray, setNameStudentsArray] = useState<any>([])

    // verificar motivo da transferência
    function isError() {
        if (reason === "") {
            setError("#ff0000")
        }
    }

    // pegar todos os ids dos estudantes
    useEffect(() => {
        const names = props.studentsSelected.map((item: any) => item.identifier);
        setNameStudentsArray(names);
    }, [props.studentsSelected]);


    // função save
    const saveTranfer = () => {
        const data = {
            students: nameStudentsArray,
            classroomOrigin: props.classSelected.identifier,
            classroomDestiny: props.classSelectedTranfer.identifier,
            reason: reason,
        }

        if (reason !== "") {
            api.post(`/client/transfers/classrooms`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }).then((respose) => {
                if (respose.status === 200) {
                    setModalSave(true);
                }
            })
            // .catch((error) => {
            //     const errorResponse = error.response.data;
            //alert(errorResponse.message);
            // })
        } else {
            isError();
        }
    }

    return (
        <ContainerResume>
            <ModalShort
                type="success"
                text="Tranferido com sucesso"
                isOpen={modalSave}
                onRequestClose={() => window.location.reload()}
                onConfirm={() => window.location.reload()}
            />
            <BoxResume
                label="Verifique se os dados da tranferência estão corretos."
            >
                <b>ORIGEM</b>
                <tr><td><b>Unidade Educacional: </b>{props.unitSelected.name}</td></tr>
                <tr><td><b>Turma: </b>{props.classSelected.name}</td></tr>
                <br />
                <b>DESTINO</b>
                <tr><td><b>Unidade Educacional: </b>{props.unitSelectedTranfer.name}</td></tr>
                <tr><td><b>Turma: </b>{props.classSelectedTranfer.name}</td></tr>
                <br />
                <b>LISTA DE ESTUDANTES</b>
                <tr>
                    {props.studentsSelected.map((student: any) => (
                        <td key={student.identifier}>
                            <p><span>&bull;</span> {student.name}</p>
                        </td>
                    ))}
                </tr>
                <br />
                <tr><td><p style={{ color: error }}>Razão da Transferência:</p></td>
                    <textarea
                        style={{ border: `1px solid ${error}` }}
                        value={reason}
                        onChange={(e: any) => setReason(e.target.value)}
                    />
                </tr>
            </BoxResume>
            <Controllers
                save={true}
                functionCancel={() => window.location.reload()}
                functionPrev={props.onBack}
                functionSave={saveTranfer}
                enableNext={true}
                enablePrev={true}
            />
        </ContainerResume>
    )
}