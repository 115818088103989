import { useQuery } from "react-query";
import ResourceService from "../../../../Services/Resouce.service";
import { Queries } from "../../../../types/queries";
import { minutesToMilliseconds } from "../../../../Utils/Functions";

export function useListResource() {
  return useQuery(
    Queries.LIST_RESOURCES,
    () => ResourceService.listResource(),
    {
      cacheTime: minutesToMilliseconds(5),
      staleTime: minutesToMilliseconds(5),
      retry: false,
    }
  );
}
