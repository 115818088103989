import styled from "styled-components";

interface IContScreen {
  $selected: boolean;
}
export const ContScreen = styled.div<IContScreen>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ $selected }) => ($selected ? "#646464" : "#BCBCBC")};
  width: 70px;
  border-radius: 15px 15px 0px 0px;
  height: 100%;

  label {
    color: white;
  }
`;
