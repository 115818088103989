import styled from "styled-components";

export const Sitemap = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #646464;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const ContainerData = styled.div`
margin-top: 30px;

.flex-collection{
  display: flex;
  gap: 10px;

  .action-icons{
      cursor: pointer;
      display: flex;
      gap: 5px;
      margin-left: 20%;
      color: #646464;
      .first-child{
        color: #FF0000;
      }
    }
}

header{
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
}

    .div-all {
        display: flex;
        justify-content: flex-end;
        button{
            display: flex;
            width: 195px;
            height: 30px;
            background-color: #FE8F2E;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 0px 0px 8px 8px;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;
            padding: 0;
        }
    }


.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
}

.container-package{
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.name{
  font-size: 24px;
  line-height: 33px;

}

.description{
  font-size: 16px;
  line-height: 22px;
}

body{
  display: flex;
  margin-top: 20px;
  gap: 80px;

  .div-treeItem{
    text-transform: uppercase;
   
  }
}

.container{
  display: flex;
  flex-direction: row;
}

.category{
  background-color: #ccc;
}


    button {
      width: 30px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #FE8F2E;
      padding-top: 10px;
    }



.add-category{
  color: #FE8F2E;
  cursor: pointer;

    button{
      display: flex;
      align-items: center;
      width: 15%;
      font-size: 16px;
      gap: 5px;
      text-transform: uppercase;
    }
}

.line{
  display: inline-block;
  width: 300px;
  border-top: 1px solid black;
}

footer {
    display: flex;
    padding-top: 50px;
    
    .div-footer {
      display: flex;
      width: 100%;
      //justify-content: flex-end;
      margin-left: 70%;
      gap: 15px;
      margin-bottom: 10px;
    }
    
    button {
      display: flex;
      height: 40px;
      width: 110px;
      border-radius: 10px;
      border: none;
      background: #088A62;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      cursor: pointer;
        &:first-child{
          background: #BCBCBC;
        }
    }
  }
`