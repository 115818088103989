import React, { useContext, useEffect, useState } from 'react'
import { Container, Main } from '../../UserRegistration/styles'
import { Divider } from '../../../../Components/Form/Divider'
import TagService from '../../../../Services/Tag.service';
import { useNavigate } from 'react-router-dom';
import InputTag from '../../../../Components/InputTag';
import { SpinnerIcon, Table, TableActionOptions } from '../../TagRegistration/styles';
import { EnumRoles, TagListed } from '../../../../Utils/Types';
import { Controllers } from '../../../../Components/Form/Controllers';
import { EnumStepNewResource, ResourceContext } from '../../../../Contexts/Form/ContextResource';
import { Trash2 } from 'lucide-react';
import { ITagsResource } from '../../../../reducers/newResource/reducer';

export interface Tags extends TagListed {
  category: {
    name: string,
    identifier: string
  }
}

const Form3 = () => {

  const navigate = useNavigate()
  const [table, setTable] = useState([])
  const [categories, setCategories] = useState<any>([])
  const user = JSON.parse(String(localStorage.getItem('roles')))
  const [tagSugestions, setTagSugestions, ] = useState<any>([])
  const { handleStep, setTagsResource, tagsListed, setTagsListed } = useContext(ResourceContext)

  useEffect(() => {


    TagService.listCategory().then((response) => {
      if (response.success) {
        setCategories(response.data)
      }
    })

    updateListTagsSuggestions()

  }, [])

  const updateListTagsSuggestions = () => {
    TagService.listTagsSugestions().then((response) => {
      if (response.success) {
        setTagSugestions(response.data)
      }
    })
  }

  const nextStep = () => {
    const payload: ITagsResource = {
      categorys: tagsListed.map((item)=> item.category.identifier),
      itens: tagsListed.map((item) => item.identifier),
      suggested: null
    }

    setTagsResource(payload)
    handleStep(EnumStepNewResource.POP);
  }
  const prevStep = () => {
    handleStep(EnumStepNewResource.BNCC);
  }

 

  function addTagList(tag: Tags) {

    if (tagsListed.some(tagl => tag.identifier === tagl.identifier)) {
      return
    }
    setTagsListed((state) => {
      return [...state, tag]
    })

    const payload: ITagsResource = {
      categorys: tagsListed.map((item)=> item.category.identifier),
      itens: tagsListed.map((item) => item.identifier),
      suggested: null
    }

    setTagsResource(payload)

  }

  function removeTagList(id: string) {
    setTagsListed((state) => state.filter((tag) => tag.identifier !== id))
  }

  return (
    <Container>
      <div className="box">
        <Main>

          <br />
          <>
            <Divider>
              <label>CATEGORIAS DE TAGS</label>
            </Divider>
            <br />
            {categories.map((item: any) =>
              <InputTag key={item.identifier}
                text={item.name}
                value={item.name}
                selectedOption={item.identifier}
                table={table}
                setTable={setTable}
                color="#9DDBF0"
                user={user}
                isResource={true}
                addTagList={addTagList}
                removeTagList={removeTagList}
                idCategory={item.identifier}
              />
            )}

            <br />
            <Table >
              <table>
                <thead>

                  <tr>
                    <th colSpan={4}>LISTA DE TAGS DO RECURSO</th>
                  </tr>

                  <tr>
                    <td>Categoria</td>
                    <td>Nome</td>
                  </tr>
                </thead>
                <tbody>

                  {tagsListed.map((item, index: number) => {
                    return (
                      <tr key={index}>
                        <td>{item.category.name}</td>
                        <td>{item.name}
                          <button onClick={() => removeTagList(item.identifier)}>
                            <Trash2 size={13} />
                          </button>
                        </td>

                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </Table>
            <br />

            <Controllers
              functionCancel={() => {
                navigate('/')
              }}
              functionNext={nextStep}
              functionPrev={prevStep}
              enableNext={true}
              enablePrev={true}
            />
          </>


        </Main>
      </div>
    </Container>
  )
}

export default Form3;
