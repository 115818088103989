import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  > div {
    flex: 1;
    border-radius: 8px;
    padding: 10px;

    &:first-child {
      background-color: #fcbf1e;
      > div.body::-webkit-scrollbar {
        width: 10px; /* width of the entire scrollbar */
      }

      > div.body::-webkit-scrollbar-track {
        background: #E9E9E9; /* color of the tracking area */
        border-radius: 20px;
      }

      > div.body::-webkit-scrollbar-thumb {
        background-color: #18A6B7; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
      }
    }
    &:last-child {
      background-color: #e50943;
      > div.body::-webkit-scrollbar {
        width: 10px; /* width of the entire scrollbar */
      }

      > div.body::-webkit-scrollbar-track {
        background: #E9E9E9; /* color of the tracking area */
        border-radius: 20px;
      }

      > div.body::-webkit-scrollbar-thumb {
        background-color: #DBA418; /* color of the scroll thumb */
        border-radius: 20px; /* roundness of the scroll thumb */
      }
    }

    > div.head {
      font-size: 15px;
      font-family: 700;
      color: var(--blue);
      > p {
        font-weight: 700;
      }
    }
    > div.body {
      height: 320px;
      overflow-y: scroll;
      padding-right: 5px;
    }
  }
`

export const Item = styled.div`
  border-top: 1px solid #fff;
  span {
    font-weight: 400;
    font-size: 10px;
  }
  p {
    font-weight: 700;
    font-size: 12px;
    color: var(--blue);
  }
`

export const Item2 = styled.div`
  border-top: 1px solid #fff;
  span {
    font-weight: 700;
    font-size: 13px;
    text-transform: uppercase;
  }
  p {
    font-weight: 500;
    font-size: 12px;
  }
`
