import { api } from "./api";
import { AxiosError, AxiosResponse } from "axios";
import {
  CreateSuccess,
  Forbidden,
  ServiceError,
  Success,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { TypeTeacher } from "../Utils/Types";

type RoleGetResponse = CreateSuccess | Unauthorized;

type ClienteGetReponse = any | Unauthorized;

type ClienteGetProps = {
  path: string; //Caminho da rota
};

type ShowRoleProps = {
  identifier: string
}

type ShowRoleResponse = Success | Unauthorized | ServiceError;

class RoleService {

  static async showRole(input: ShowRoleProps){
    try{

      const {identifier} = input;
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<ShowRoleResponse>(
        `/admin/roles/${identifier}`,
        config
      )
      
      return response.data

    }catch(error: any){
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async get() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.get<RoleGetResponse>(`/admin/roles`, config);
      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default RoleService;
