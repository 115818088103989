import styled, { keyframes } from 'styled-components'
interface BaseProps {
  $duration?: string
  $timingFunction?: string
  $delay?: string
  $iterationCount?: string
  $direction?: string
  $fillMode?: string
  $playState?: string
  $display?: string
}

const BaseAnimation = styled.div<BaseProps>`
  animation-duration: ${(props) => props.$duration};
  animation-timing-function: ${(props) => props.$timingFunction};
  animation-delay: ${(props) => props.$delay};
  animation-iteration-count: ${(props) => props.$iterationCount};
  animation-direction: ${(props) => props.$direction};
  animation-fill-mode: ${(props) => props.$fillMode};
  animation-play-state: ${(props) => props.$playState};
  display: ${(props) => props.$display};
`
BaseAnimation.defaultProps = {
  $duration: '1s',
  $delay: '0s',
  $timingFunction: 'linear',
  $iterationCount: 'infinite',
  $direction: 'normal',
  $fillMode: 'forwards',
  $playState: 'running',
  $display: 'block',
}

const ShimmerAnimation = keyframes`
    0% {
    background-position: -468px 0;
  }
  
  100% {
    background-position: 468px 0; 
  }

`

const Shimmer = styled(BaseAnimation)`
  background: #f6f7f8;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 100%;
  display: inline-block;
  position: relative;

  animation-name: ${ShimmerAnimation};
`

interface CardProps {
  $height?: string
  $borderRadius?: string
  $margin?: string
  $width?: string
  $marginBottom?: string
}

const ShimmerCard = styled(Shimmer)<CardProps>`
  position: relative;
  box-sizing: border-box;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  border-radius: ${(props) => props.$borderRadius};
  margin: ${(props) => props.$margin};
  margin-bottom: ${(props) => props.$marginBottom};
`
ShimmerCard.defaultProps = {
  $width: '100%',
  $height: '110px',
  $borderRadius: '5px',
  $margin: '0',
  $marginBottom: '0',
}

interface CircleProps {
  $size?: string
  $margin?: string
}
const ShimmerCircle = styled(Shimmer)<CircleProps>`
  width: ${(props) => props.$size};
  height: ${(props) => props.$size};
  border-radius: 50%;
  margin: ${(props) => props.$margin};
`
ShimmerCircle.defaultProps = {
  $size: '50px',
  $margin: '0',
}

interface LineProps {
  $width?: string
  $margin?: string
}

const ShimmerLine = styled(Shimmer)<LineProps>`
  width: ${(props) => props.$width};
  height: 12px;
  margin: ${(props) => props.$margin};
  border-radius: 4px;
`
ShimmerLine.defaultProps = {
  $width: '100%',
  $margin: '0',
}

export { Shimmer, ShimmerCard, ShimmerCircle, ShimmerLine }
