import { useQuery } from "react-query";
import BNCCService from "../../../../Services/BNCC.service";
import { minutesToMilliseconds } from "../../../../Utils/Functions";
import { Queries } from "../../../../types/queries";

export function useListBNCC() {
  return useQuery(Queries.LIST_BNCC, () => BNCCService.list(), {
    cacheTime: minutesToMilliseconds(5),
    staleTime: minutesToMilliseconds(5),
    retry: false,
    retryDelay(failureCount, error) {
    
      return 1000;
    },
  });
}
