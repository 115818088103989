import axios from "axios";
import { Upload } from "lucide-react";
import { useEffect, useState } from "react";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { Input } from "../../../../Components/Form/Input";
import { Form } from "../styles";
import { useNavigate } from "react-router-dom";
import { ImportExcelUnidade, Unidade } from "../../../../Components/Form/ImportExcelUnidade";

export function Form1(props: any) {
  const navigate = useNavigate();
  const [dataSend, setDataSend] = useState({
    Cep: "",
    Cidade: "",
    Email: "",
    Estado: "",
    Nome: "",
    Telefone: "",
  });


  useEffect(() => {
    setDataSend({
      Cep: props.cepValue,
      Cidade: props.cidadeValue,
      Email: props.emailValue,
      Estado: props.ufValue,
      Nome: props.nameValue,
      Telefone: props.telValue,
    });
  }, [
    props.cepValue,
    props.cidadeValue,
    props.emailValue,
    props.ufValue,
    props.nameValue,
    props.telValue,
  ]);

  async function handleCep(n: any) {
    props.setCepValue(n);
    const number = n.replace(/\D/g, "");
    if (number.length === 8) {
      axios.get(`https://viacep.com.br/ws/${number}/json/`).then((response) => {
        props.setBairroValue(response.data.bairro);
        props.setComplementoValue(response.data.complemento);
        props.setCidadeValue(response.data.localidade);
        props.setRuaValue(response.data.logradouro);
        props.setUfValue(response.data.uf);
      });
    }
  }

  const checkNextStep = () => {
    if (props.unidadeList.length > 0) {
      props.next(2);
      return;
    }
    if (
      props.nameValue === "" ||
      props.telValue === "" ||
      props.emailValue === "" ||
      props.cepValue === "" ||
      props.ruaValue === "" ||
      props.bairroValue === "" ||
      props.cidadeValue === "" ||
      props.ufValue === "" ||
      props.numberValue === ""
    ) {
      alert("Preencha todos os campos obrigatórios");
      return;
    }
    props.next(2);
  };

  const dataUnit: Unidade = {
    address: props.ruaValue,
    city: props.cidadeValue,
    email: props.emailValue,
    name: props.nameValue,
    neighborhood: props.bairroValue,
    number: props.numberValue,
    phone: props.telValue,
    state: props.ufValue,
    zip_code: props.cepValue,
  }

  function clearFields() {
    props.setNameValue("");
    props.setTelValue("");
    props.setEmailValue("");
    props.setCepValue("");
    props.setRuaValue("");
    props.setNumberValue("");
    props.setComplementoValue("");
    props.setBairroValue("");
    props.setCidadeValue("");
    props.setUfValue("");
  }
  
  return (
    <Form>
      <Input
        flex="fx100"
        label="Nome da Unidade Educacional"
        inputId="nomeUnidadeEducacionalId"
        value={props.nameValue}
        onChange={(e: any) => props.setNameValue(e)}
        type="text"
        required
      />
      <Divider>
        <Input
          flex="fx30"
          type="tel"
          label="Telefone"
          inputId="telefoneid"
          value={props.telValue}
          onChange={(e: any) => props.setTelValue(e)}
          required
        />
        <Input
          flex="fx80"
          label="E-mail"
          type="email"
          inputId="emailid"
          value={props.emailValue}
          onChange={(e: any) => props.setEmailValue(e)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx15"
          type="cep"
          label="CEP"
          inputId="cepid"
          value={props.cepValue}
          onChange={(v: any) => handleCep(v)}
          required
        />
        <Input
          flex="fx80"
          label="Rua/Avenida"
          type="text"
          inputId="ruaavenidaid"
          value={props.ruaValue}
          onChange={(v: any) => props.setRuaValue(v)}
          required
        />
        <Input
          flex="fx10"
          label="Número"
          type="quantity"
          inputId="numeroid"
          value={props.numberValue}
          onChange={(v: any) => props.setNumberValue(v)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Complemento"
          inputId="complementoid"
          type="text"
          value={props.complementoValue}
          onChange={(v: any) => props.setComplementoValue(v)}
        />
        <Input
          flex="fx50"
          label="Bairro"
          type="text"
          inputId="bairroid"
          value={props.bairroValue}
          onChange={(v: any) => props.setBairroValue(v)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Cidade"
          type="text"
          inputId="cidadeid"
          value={props.cidadeValue}
          onChange={(v: any) => props.setCidadeValue(v)}
          required
        />
        <Input
          flex="fx50"
          label="Estado"
          type="text"
          inputId="estadoid"
          value={props.ufValue}
          onChange={(v: any) => props.setUfValue(v)}
          required
        />
      </Divider>
      {props.edit ? null : (
        <ImportExcelUnidade
          unidadeList={props.unidadeList}
          setUnidadeList={props.setUnidadeList}
          tableName="Informações da Unidades Importadas"
          label="Importar Unidade(s)"
          inputId="importarplanilha"
          icon={<Upload size={20} />}
          sendData={dataUnit}
          clearFields={clearFields}
        />
      )}

      <Controllers
        functionNext={() => checkNextStep()}
        functionCancel={() => navigate("/unidade/list")}
        enableNext={true}
        enablePrev={false}
      />
    </Form>
  );
}
