import { Container, ContainerNew, Main, Upload, Radio, ContainerCategory, Abas, Sitemap, Sitemap2, } from "./styles";
import { useEffect, useRef, useState } from "react";
import { PlusCircleIcon, Trash2Icon, Plus, Edit, ToggleLeft, } from "lucide-react";
import { useCheckTime } from "../../../Components/LoginFunctions/Refresh";
import { api } from "../../../Services/api";
import { SuperBox } from "../../../Components/SuperBox";
import { ColorPicker } from "../../../Components/Form/ColorPicker";
import { ListDefault } from "../../../Components/ListDefault";
import { Input } from "../../../Components/Form/Input";
import { Expanded } from "./Expanded";
import { transformUrlImage } from "../../../Utils/Functions";
import CollectionService from "../../../Services/Collection.service";
import Modal, { ModalRef } from "../../../Components/Modals";
import { ModalShort } from "../../../Components/Modals/ModalShort";
import { TreeItem } from "./TreeItem";
import { ContainerData } from "../ListPackages/styles";
import { LeftMenu } from "../../../Components/LeftMenu";

export function ListCollections() {
  const [modalShortShow, setModalShortShow] = useState(false);
  const [modalShortCategory, setModalShortShowCategory] = useState(false);
  const [message, setMessage] = useState("")
  const [inputSearchView, setInputSearchView] = useState("")
  const [collectionFiltereds, setCollectionFiltereds] = useState<any[]>([])

  const [modalDelete, setModalDelete] = useState(false)
  const [forDelete, setForDelete] = useState("")

  const ModalRegisterCollection = useRef<ModalRef>(null)
  function cancelRegisterCollection() {
    ModalRegisterCollection.current?.close()
  }

  const ModalEditCollection = useRef<ModalRef>(null)
  function cancelEditCollection() {
    setExpandedItem(null)
    ModalEditCollection.current?.close()
  }

  const modalRegisterCategory = useRef<ModalRef>(null)
  const handleRegisterCategory = () => {
    setCollectionDataShow(true);
    modalRegisterCategory.current?.open()
  }
  function cancelRegiterCategory() {
    modalRegisterCategory.current?.close()
  }

  const [options, setOptions] = useState([]);
  const [img, setImg] = useState<any>("")
  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");

  const [imgEdit, setImgEdit] = useState<any>("")
  const [collectionNameEdit, setCollectionNameEdit] = useState("");
  const [collectionDescriptionEdit, setCollectionDescriptionEdit] = useState("");

  const [defaultSelect, setDefaultSelect] = useState(false)
  const [createNew, setCreateNew] = useState(false)

  useCheckTime();
  const [selectedOption, setSelectedOption] = useState<any>([]);
  const [backgroundColor, setBackgroundColor] = useState<string>("#000000");
  const [collectionData, setCollectionData] = useState<any>([]);
  const [collectionShow, setCollectionShow] = useState(true);
  const [collectionDataShow, setCollectionDataShow] = useState(false);
  const [showNewCollection, setShowNewCollection] = useState(false);
  const [switchPageList, setSwitchPageList] = useState(
    "/admin/collections?limit=10&page=1"
  );

  const [collectionCategories, setCollectionCategories] = useState<any[]>([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryDescription, setCategotyDescription] = useState("");

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("1");
  const [metaTotal, setMetaTotal] = useState("1");

  function handlePreview(e: any) {
    if (!e.target.files) return
    setImg(e.target.files[0])
  }

  function handlePreviewEdit(e: any) {
    if (!e.target.files) return
    setImgEdit(e.target.files[0])
  }
  const [imgVieu, setImgVieu] = useState<any>("")
  function ImageSelect() {
    if (!imgVieu) {
      return <p>Inserir logo</p>;
    }
    if (typeof imgVieu === 'string') {
      return <img src={transformUrlImage(imgVieu)} />;
    } else {
      return <img src={URL.createObjectURL(imgEdit)} />;
    }
  }

  function handleChangeDefault() {
    setDefaultSelect(true)
    if (defaultSelect === true) {
      setCreateNew(false)
      return
    }
    setCreateNew(false)
  }

  function handleChangeCreateNew() {
    setCreateNew(true)
    if (createNew === true) {
      setDefaultSelect(false)
      return
    }
    setDefaultSelect(false)
  }

  const handleRegisterCollection = () => {
    setShowNewCollection(true)
    ModalRegisterCollection.current?.open()
  }

  // fechar modal 
  function closeModal() {
    setModalShortShow(false);
    window.location.reload();
  }

  // fechar modal de categoria
  function closeModalCategory() {
    setModalShortShow(false);
  }

  // voltar
  function back() {
    setCollectionDataShow(false)
    setCollectionShow(true)
    setExpandedItem(null)
  }

  // listar coleção
  useEffect(() => {
    async function fetchData() {
      await api
        .get(`/admin/collections/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setOptions(response.data.data);
        });
    }
    fetchData();
  }, []);

  // adicionar categoria
  async function addCollection() {
    try {
      const data = new FormData();
      data.append("name", collectionName);
      data.append("description", collectionDescription);
      data.append("logo", img);

      const response = await api.post(`/admin/collections/`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      });

      const newCollectionId = response.data.data.identifier;
      handleChange(newCollectionId);
      ModalRegisterCollection.current?.close()
    } catch (error) {
      throw new Error("Can not create collection.")
    }
  }

  // Editar coleção
  const handleEditCollection = (option: any) => {
    setSelectedOption(option);
    setCollectionNameEdit(option.name)
    setCollectionDescriptionEdit(option.description)
    setImgVieu(option.logo)
    ModalEditCollection.current?.open()
  };

  function updateCollection() {
    const collectionId = selectedOption.identifier

    const data = new FormData();
    data.append("name", collectionNameEdit)
    data.append("description", collectionDescriptionEdit)
    data.append("logo", imgEdit)
    data.append("_method", "PUT");

    CollectionService.upadateCollection({ idCollection: collectionId, data }).then((response) => {
      if (response.success) {
        setMessage("Coleção edidata com sucesso!")
        setModalShortShow(true)
        setExpandedItem(null)
      }
    })
  }

  // adicionar categoria
  function addCategory() {
    if (categoryName !== "" || categoryDescription !== "") {
      api
        .post(
          `/admin/collections/${selectedOption}/categories`,
          {
            name: categoryName,
            description: categoryDescription,
            color: backgroundColor,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          setModalShortShow(true)
          setMessage("Categoria cadastrado com sucesso!")
          setCollectionCategories((prevCategories) => [...prevCategories, response.data.data]);
          modalRegisterCategory.current?.close();

        });
    } else {
      alert("Preencha o Nome e Descrição");
    }
  }

  // exibir uma coleçõa
  const handleChange = (event: any) => {
    setSelectedOption(event);
    api
      .get(`/admin/collections/${event}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCollectionData(response.data.data);
      });
    setCollectionDataShow(true);
    setShowNewCollection(false);
    setCollectionShow(false);

    api
      .get(`/admin/collections/${event}/categories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCollectionCategories(response.data.data);
      });
  };

  // deletar coleção
  function deleteCollection(idCollection: string) {
    api.delete(`/admin/collections/${idCollection}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(response => {
      setModalShortShow(true)
      setMessage("Coleção deletada com sucesso!")
    })
  }

  // expandir listagem de coleção
  const [expandedItem, setExpandedItem] = useState(null);
  const handleExpand = (identifier: any) => {
    setExpandedItem((prevExpandedItem) =>
      prevExpandedItem === identifier ? null : identifier
    );
  };

  // filtro
  useEffect(() => {

    let CollectionForFilter = options.flat()
    let filteredCollection = CollectionForFilter.filter((item: any) => item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setCollectionFiltereds(filteredCollection)

  }, [options, inputSearchView])

  return (
    <Container>
      <LeftMenu />
      <div className="box">
        <Main>

          {collectionShow && (
            <>
              <Sitemap>
                <p>Listagem de coleções</p>
              </Sitemap>

              <ListDefault
                pages={pages}
                changeGet={(v: any) => setSwitchPageList(v)}
                to={metaTo}
                total={metaTotal}
              >

                <thead>
                  <tr >
                    <td style={{ width: "460px" }}>
                      <Input
                        type="search"
                        placeHolder="Pesquise por uma coleção"
                        value={inputSearchView}
                        onChange={setInputSearchView}
                        color="#039bc9"
                      />
                    </td>
                    <td>
                      <button className="bt-cadastr"
                        onClick={() => handleRegisterCollection()}
                      >
                        Criar nova coleção
                        <Plus size={16} />
                      </button>
                    </td>
                  </tr>
                </thead>

                <thead>
                  <tr>
                    <td className="wd25">Logo</td>
                    <td className="wd25">Nome</td>
                    <td className="wd25">Descrição</td>
                    <td className="wd10">Ação</td>
                  </tr>
                </thead>

                <tbody >
                  {collectionFiltereds.sort((a, b) => a.name.localeCompare(b.name))
                    .map((option: any) => {
                      return (
                        <>
                          <tr key={option.identifier} onClick={() => handleExpand(option.identifier)} >
                            <td>
                              <div className="logo">
                                <img
                                  src={
                                    option.logo
                                      ? transformUrlImage(option.logo)
                                      : ""
                                  } alt="" />
                              </div>
                            </td>

                            <td>
                              <button
                                className="profile"
                                type="button"
                                value={selectedOption}
                                onClick={() => { handleChange(option.identifier) }}
                              >
                                {option.name}
                              </button>
                            </td>

                            <td>
                              {option.description}
                            </td>
                            <td>
                              <button
                                className="hvr-grow"
                                onClick={() => handleEditCollection(option)}
                              >
                                <Edit />
                              </button>
                              <button className="hvr-grow"
                                style={{ color: "green", marginLeft: "10px" }}
                              >
                                <ToggleLeft />
                              </button>
                            </td>
                          </tr>
                          {expandedItem === option.identifier && (
                            <td colSpan={4}>
                              <Expanded collection={option} />
                            </td>
                          )}
                        </>
                      );
                    })}
                </tbody>
              </ListDefault>
            </>
          )}

          {/* CADASTRO DE COLEÇÃO */}
          <Modal styleDefault={false} ref={ModalRegisterCollection}>
            <ContainerNew>
              <header>
                <p>Cadastrar nova coleção</p>
              </header>
              <section>

                <Upload>
                  <label htmlFor="uploadInput">
                    {img === '' ? (
                      <p>Clique aqui para fazer o Upload da Logo</p>
                    ) : (
                      <img src={URL.createObjectURL(img)} alt="" />
                    )}
                  </label>
                  <input
                    type="file"
                    name=""
                    id="uploadInput"
                    onChange={(e) => handlePreview(e)}
                  />
                  <div className="text-type">
                    <p>Formato JPG ou PNG, 160 x 40 px.</p>
                  </div>
                </Upload>

                <div className='flex-label'>
                  <label htmlFor="">Nome da coleção</label>
                </div>
                <div className='flex-inputs'>
                  <input type="text" onChange={(e: any) => setCollectionName(e.target.value)} value={collectionName} />
                </div>
                <div className='space' />
                <label htmlFor="">Descrição</label>
                <textarea className="multi" onChange={(e: any) => setCollectionDescription(e.target.value)} value={collectionDescription} />

                <Radio>
                  <div className="">
                    <p>Qual modelo de categorização você deseja utilizar na coleção?</p>
                    <div className="radio-inputs">
                      <label htmlFor="">
                        <input
                          type="checkbox"
                          checked={defaultSelect}
                          onChange={() => handleChangeDefault()}
                        />
                        Padrão (<i>recomendado</i>)
                      </label>
                      <label htmlFor="">
                        <input
                          type="checkbox"
                          checked={createNew}
                          onChange={() => handleChangeCreateNew()}

                        />
                        Criar uma nova
                      </label>
                    </div>
                  </div>
                </Radio>

              </section>
              <footer>
                <button className="hvr-grow" onClick={cancelRegisterCollection}>
                  Cancelar
                </button>
                <button className="hvr-grow" onClick={() => addCollection()}>
                  Salvar
                </button>
              </footer>
            </ContainerNew>
          </Modal>

          {/* EDIÇÃO DE COLEÇÃO */}
          <Modal styleDefault={false} ref={ModalEditCollection}>
            <ContainerNew>
              <header>
                <p>Editar coleção</p>
              </header>
              <section>

                <Upload>
                  <label htmlFor="uploadInput">
                    <ImageSelect />
                  </label>
                  <input
                    type="file"
                    name=""
                    id="uploadInput"
                    onChange={(e) => handlePreviewEdit(e)}
                  />
                  <div className="text-type">
                    <p>Formato JPG ou PNG, 160 x 40 px.</p>
                  </div>
                </Upload>

                <div className='flex-label'>
                  <label htmlFor="">Nome da coleção</label>
                </div>
                <div className='flex-inputs'>
                  <input
                    type="text"
                    onChange={(e: any) => setCollectionNameEdit(e.target.value)}
                    value={collectionNameEdit}
                  />
                </div>
                <div className='space' />
                <label htmlFor="">Descrição</label>
                <textarea className="multi"
                  onChange={(e: any) => setCollectionDescriptionEdit(e.target.value)}
                  value={collectionDescriptionEdit}
                />

              </section>
              <footer>
                <button className="hvr-grow" onClick={cancelEditCollection}>
                  Cancelar
                </button>
                <button className="hvr-grow" onClick={updateCollection}>
                  Salvar
                </button>
              </footer>
            </ContainerNew>
          </Modal>

          {/* EXIBIÇÃO DE UMA COLEÇÃO */}
          {collectionDataShow && (
            <>
              <Sitemap2>
                <p>Coleção</p> <span>&bull;</span> <p>{collectionData.name}</p>
              </Sitemap2>

              <ContainerData>
                <header>
                  <div className="flex-collection">
                    <div className="logo">
                      <img
                        src={
                          collectionData.logo
                            ? transformUrlImage(collectionData.logo)
                            : ""
                        } alt="" />
                    </div>

                    <div className="container-package">
                      <div className="name">Coleção: {collectionData.name}</div>
                      <div className="description">
                        Descrição: {collectionData?.description}
                      </div>
                    </div>
                    <div className="action-icons">
                      <Edit size={14} onClick={() => handleEditCollection(collectionData)} />
                      <Trash2Icon size={14} className="first-child"
                        onClick={() => { setForDelete(collectionData.identifier); setModalDelete(true) }}
                      />
                    </div>
                  </div>
                </header>

                <section>
                  <div className="add-category">
                    <button onClick={() => handleRegisterCategory()}>
                      <PlusCircleIcon size={16} /> Nova Categoria</button>
                  </div>
                  <span className="line"></span>
                  <body>
                    <div>
                      {collectionCategories.map((category: any) => (
                        <TreeItem item={category} level={0} idCollection={selectedOption} />
                      ))}
                    </div>
                  </body>
                </section>

                <footer>
                  <div className="div-footer">
                    <button className="hvr-grow" onClick={() => back()}>
                      Voltar
                    </button>
                  </div>
                </footer>
              </ContainerData>
            </>
          )}

          {/* CADASTRAR CATEGORIA */}
          <Modal styleDefault={false} ref={modalRegisterCategory}>
            <ContainerCategory>
              <header>
                <p>Cadastrar nova Categoria</p>
              </header>
              <section>
                <div className='flex-label'>
                  <label htmlFor="">Nome da Categoria</label>
                  <label htmlFor="">Definir cor</label>
                </div>
                <div className='flex-inputs'>
                  <input type="text" onChange={(e: any) => setCategoryName(e.target.value)} value={categoryName} />
                  <div className="colorPicker"> <ColorPicker onChange={setBackgroundColor} />
                  </div>
                </div>
                <div className='space' />
                <label htmlFor="">Descrição</label>
                <textarea className="multi" onChange={(e: any) => setCategotyDescription(e.target.value)} value={categoryDescription} />
              </section>
              <footer>
                <button className="hvr-grow" onClick={cancelRegiterCategory}>
                  Cancelar
                </button>
                <button className="hvr-grow" onClick={() => addCategory()}>
                  Salvar
                </button>
              </footer>
            </ContainerCategory>
          </Modal>

          {modalShortShow && (
            <ModalShort
              type="success"
              text={message}
              isOpen={modalShortShow}
              onRequestClose={closeModal}
              onConfirm={closeModal}
            />
          )}

          {modalShortCategory && (
            <ModalShort
              type="success"
              text={message}
              isOpen={modalShortCategory}
              onRequestClose={closeModalCategory}
              onConfirm={closeModalCategory}
            />
          )}

          {modalDelete && (
            <ModalShort
              type="delete"
              isOpen={modalDelete}
              text={"Tem certeza de que deseja excluir esta coleção? Todas as categorias associadas serão perdidas."}
              onRequestClose={() => {
                setModalDelete(false)
              }}
              onConfirm={() => {
                deleteCollection(forDelete);
                setModalDelete(false)
              }}
            />
          )}
          
        </Main>
      </div>
    </Container >
  );
}