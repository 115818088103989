import { useEffect } from 'react'
import { api } from '../../../Services/api'

const UPDATE_TOKEN_TIME_LIMIT_LOW = 3500 * 1000
const UPDATE_TOKEN_TIME_LIMIT_HIGH = 1.5 * UPDATE_TOKEN_TIME_LIMIT_LOW

export function useCheckTime() {
  useEffect(() => {
    async function updateToken() {
      await api.post(`/auth/refresh/`, {
        data: ""
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        localStorage.setItem('token', response.data.access_token)
      })
    }

    const time_passed = Date.now()- Number(localStorage.getItem('time'))

    if ( time_passed > UPDATE_TOKEN_TIME_LIMIT_LOW && time_passed < UPDATE_TOKEN_TIME_LIMIT_HIGH) {
      updateToken();
    } else if (time_passed > UPDATE_TOKEN_TIME_LIMIT_HIGH){
      localStorage.clear()
      window.location.href = '/login'
    }
  }, [])
}
