import { CreateSuccess, Forbidden, ServiceError, Success, Unauthorized, UnprocessableContent } from "../Utils/Responses";
import { Package } from "../Utils/Types";
import { api } from "./api";

type EditPackageProps = {
    idPackage: string;
    data: FormData
}

type EditPackageResponse =
    Success |
    Unauthorized |
    Forbidden

export interface IPackage {
    identifier: string | undefined,
    name: string
    color: string;
    description: string,
    logo: string
}

export interface ICategoriesPakage {
    identifier: string;
    name: string;
    color?: string;
    description?: string;
    subcategories: any[];
}

type ShowPakageProps = {
    identifier: string | undefined;
}

type ShowPakageResponse = Success | Unauthorized | Forbidden | ServiceError;


type PackageResgistrationProps = {
    data: FormData
}
type PackageRegistationResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;

type CategorieRegistrationProps = {
    idpackage: string,
    name: string,
    description: string,
    color: string
}

type CategorieRegistationResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;

class PackageService {

    static async listPakage(): Promise<IPackage[]> {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };
            const response = await api.get(
                `/admin/packages/`, config);

            if (response.status === 200) {
                return response.data.data
            }
            throw new Error("Can not list Categories.");
        } catch (error: any) {
            throw new Error("Can not list Categories.");
        }
    }

    static async showPakage(data: ShowPakageProps): Promise<ShowPakageResponse> {
        try {
            const { identifier } = data;

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            const response = await api.get(
                `/admin/packages/${identifier}/categories`, config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }

        } catch (error: any) {
            throw new Error("Can not show pakage")
        }
    }

    static async packageRegistation(input: PackageResgistrationProps): Promise<PackageRegistationResponse> {

        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "multipart/form-data",
                }
            }

            const response = await api.post(
                `/admin/packages`, input, config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                case 403:
                    return { ...response.data, success: false, message: "Forbidden" } as Forbidden;
                case 422:
                    return { ...response.data, success: false, message: "Unprocessable Content" } as UnprocessableContent;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }

        } catch (error: any) {
            throw new Error("Can not create package.")
        }

    }


    static async update({data, idPackage}: EditPackageProps): Promise<EditPackageResponse> {

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "multipart/form-data"
            }
        }

        try {
            const response = await api.post<EditPackageResponse>(
                `/admin/packages/${idPackage}?_method=PUT`,
                data,
                config
            )
            console.log("Response: ", response)
            console.log("Response Data: ", response.data)
            return response.data;
        } catch (error: any) {
            throw new Error("Can not update package.")
        }

    }


    static async listCategoriesByIdPakage(idPakage: string | undefined ): Promise<ICategoriesPakage[]> {
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            };

            const response = await api.get(
                `/admin/packages/${idPakage}/categories` , config
            )

            if (response.status === 200) {
                return response.data.data
            }

            throw new Error("Can not list Categories.");

        } catch (error: any) {
            throw new Error("Can not list Categories.");
        }
    }

    static async addcategory(input: CategorieRegistrationProps): Promise<CategorieRegistationResponse> {

        const idPackage = input.idpackage
        
        try {
            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                }
            }

            const response = await api.post(
                `/admin/packages/${idPackage}/categories`, input, config
            )

            switch (response.status) {
                case 200:
                    return { data: response.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "Forbidden" } as Forbidden
            }

        }catch (error: any) {
            throw new Error("Can not create Categories.")
        }
    }


}

export default PackageService;