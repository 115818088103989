import styled from 'styled-components';

export const Container = styled.section`
display: flex;
font-family: 'Nunito Sans';
font-style: normal;
font-weight: 400;

>div.box{
  width: 100%;
  padding: 5px 30px;
}

.bt-cadastr {
    position: relative;
    top: 2px;
    margin-left: 25px;
    background-color: #027294;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    border-radius: 10px;
    width: 176px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease;
  }
  .bt-cadastr:hover {
    transform: scale(1.1);
    color: #ffffff;
  }
  div.logo{
          width: 160px;
          height: 40px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ccc;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
        }

    tr{
      cursor: pointer;
    }
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;
`;

export const TreeItemWrapper = styled.div`
display: flex;
align-items: center;
gap: 10px;

.action-icons {
  display: flex;
  gap: 5px;
  align-items: center;
  svg {
    cursor: pointer;
    transition: color 0.2s;
    
    &:hover {
      color: #027294;
    }
  }
}
`;


export const TreeItemContainer = styled.div<{ level: number }>`
  padding: ${props => 10 + (props.level * 5)}px;
  border: 1px solid #ccc;
  width: ${props => 300 + (props.level * 10)}px;
  min-height: 35px;
  margin-left: ${props => 0 + (props.level * 20)}px;
  border-radius: 15px;
  margin-top: 10px;
  cursor: pointer;
  font-family: Nunito Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  opacity: 0.8;
  background-color: ${props => props.level === 0 ? '#027294' : props.level === 1 ? '#D4D4DE' : 'rgba(212, 212, 222, 0.6)'};

  &:hover {
    background-color: #f0f0f0;
  }

  > div {
    margin-left: ${props => props.level * 20}px;
    //border-left: 1px dashed #aaa;
    position: relative;
  }

  .arrow-icon {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border-top: 2px solid black;
    border-right: 2px solid black;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
  
  .item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-name {
        margin-right: auto;
      }

    }

  &.opened .arrow-icon {
    transform: rotate(-45deg);
  }

  &.opened {
    height: auto;
  }

  .action-icons {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
    margin-top: -5%;
    svg {
      cursor: pointer;
      transition: color 0.2s;
      
      &:hover {
        color: #027294;
      }
    }
  }
`;



export const Item = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  &:last-child {
    border-bottom: none;
  }
`;


export const Abas = styled.div`
    button{
        height: 40px;
        width: 100%;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background-color: #027294;
        border: none;
        border-radius: 10px;
    }
`;

export const SelectCollection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  > div:first-child {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 10px;
    > div {
      width: 100%;
    }
    label {
      color: #646464;
    }
    p {
      margin-top: 15px;
    }
  }
  button {
    width: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #FE8F2E;
    padding-bottom: 6px;
  }

  .grow { 
    transition: all .2s ease-in-out; 
    }
    
  .grow:hover { 
  transform: scale(1.5); 
  }
`

export const ContainerData = styled.div`
margin-top: 30px;

.flex-collection{
  display: flex;
  gap: 10px;

  .action-icons{
      cursor: pointer;
      display: flex;
      gap: 5px;
      margin-left: 20%;
      color: #646464;
      .first-child{
        color: #FF0000;
      }
    }
}

header{
  border-bottom: 1px solid #000;
  padding-bottom: 20px;
}


.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  //background-color: #FF0000;
}

.container-package{
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}

.name{
  font-size: 24px;
  line-height: 33px;

}

.description{
  font-size: 16px;
  line-height: 22px;
}

body{
  margin-top: 20px;
  text-transform: uppercase;
}

.container{
  display: flex;
  flex-direction: row;
}

.category{
  background-color: #ccc;
}

button {
  width: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #FE8F2E;
  padding-top: 10px;
}

.add-category{
  color: #FE8F2E;
  cursor: pointer;

    button{
      display: flex;
      align-items: center;
      width: 15%;
      font-size: 16px;
      gap: 5px;
      text-transform: uppercase;
    }
}

.line{
  display: inline-block;
  width: 35%;
  border-top: 1px solid black;
}

footer {
    display: flex;
    
    .div-footer {
      display: flex;
      width: 100%;
      margin-left: 70%;
      gap: 15px;
      margin-bottom: 10px;
    }
    
    button {
      display: flex;
      height: 40px;
      width: 110px;
      border-radius: 10px;
      border: none;
      background: #088A62;
      color: #fff;
      text-transform: uppercase;
      font-size: 16px;
      text-align: center;
      justify-content: center;
      cursor: pointer;
        &:first-child{
          background: #BCBCBC;
        }
    }
  }
`

export const ContainerNew = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
background-color: #FE8F2E;
color: #F8F8FA;
text-align: center;
border-radius: 10px 10px 0px 0px;
height: 82px;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
text-transform: uppercase;
}

section {
padding: 20px 0px;
width: 90%;

}

input{
height: 40px;
border-radius: 15px;
border: 1px solid black;
width: 100%;
padding-left: 10px;

}

.flex-label {
display: flex;
justify-content: space-between;
}

.flex-inputs {
display: flex;
justify-content: space-between;
}


textarea{
height: 40px;
border-radius: 15px;
border: 1px solid black;
width: 100%;
padding-left: 10px;
padding-top:10px;
height: 100px;
}

label{
font-size: 14px;
line-height: 19px;
color: #646464;
}

.space{
margin-top: 10px;
}

.colorPicker {
position: relative;
left: 10px;
}

footer {
height: 90px;
display: flex;
align-items: center;
position: relative;
left: 40%;
gap: 10px;
width: 420px;
> button {
  height: 40px;
  width: 110px;
  border-radius: 5px;
  border: none;
  background: #088A62;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:first-child{
    background: #838383;
  }
}
}
`

export const ContainerCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 80%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

.colorPicker {
  position: relative;
  left: -10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`

export const Upload = styled.div`
    display: flex;
    margin-bottom: 20px; 

>label{
    margin-right: 10px;
    background-color: #646464;
    display: flex;
    height: 39px;
    width: 160px;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;

    p{
        text-align: center;
        font-size: 11px;
        color: #fff;
    }
}

>input{
    display: none;
    }

    .text-type{
        height: 39px;
        width: 160px;

        p{
            font-size: 14px;
        }
    }
`;