import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Input } from '../../Components/Form/Input'
import { useAuth } from '../../Contexts/store'
import { api } from '../../Services/api'
import { Aside, Container, Form, Main, SpinnerIcon } from './styles'

export function Login() {
  const [auth] = useAuth();
  const navigate = useNavigate();
  const [switchStep, setSwitchStep] = useState(1)
  const [reSent, setReSent] = useState(false)

  const [emailLogin, setEmailLogin] = useState('');
  const [passLogin, setPassLogin] = useState('');

  const [emailToRecover, setEmailToRecover] = useState('');

  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    if(auth === false){
      return
    } else {
      navigate('/')
    }
  },[])

  async function handleVerifyLogin(){
    if(isLoading){
      return
    }

    setIsLoading(true);

    const data = {
      "username": emailLogin,
      "password": passLogin
    };

    let response = await api
      .post('/auth/login',data)
      .catch(r => alert(r.response.data.message))


    if(response && response.status === 200 && response.data){
      localStorage.setItem('token', response.data.access_token)
      localStorage.setItem('expires', response.data.expires_in)
      localStorage.setItem('time', Date.now().toString())
    }

    const token = localStorage.getItem('token')

    if(!token){
      return
    }

    response = await api.post('/auth/me',{},{
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    })
    .catch((err)=>{
      console.log(err)
    })

    setIsLoading(false);

    if (response && response.status === 200 && response.data){
      console.log(response.data.data)
      localStorage.setItem('roles', JSON.stringify(response.data.data.roles[0].name))
      localStorage.setItem('user', JSON.stringify(response.data.data));
      window.location.href = '/';
    }


  }

  function handleVerifyRecover(){
    const data = {
      "email": emailToRecover
    }
    
    api.post('/auth/forget-password',data)
    .then(response => {setSwitchStep(3)})
    .catch(r => alert(r.response.data.message))
  }

  return (
    <Container>
      <Aside />
      <Main>
        {switchStep === 1 && (
          <Form onSubmit={e => {e.preventDefault() ; handleVerifyLogin()}}>
            <span>Bem vindo de volta</span>
            <p>Entre com sua conta</p>
            <div className="form">
              <label htmlFor="">Email</label>
              <input type="email" placeholder="exemplo@gmail.com" value={emailLogin} onChange={e => setEmailLogin(e.target.value)} required />
              <label htmlFor="">Senha</label>
              <input type="password" placeholder="*****" value={passLogin} onChange={e => setPassLogin(e.target.value)} required />
            </div>
            <div className="pass">
              <a href="" onClick={(e) => { e.preventDefault(); setSwitchStep(2)}}>
                Esqueceu a senha?
              </a>
            </div>
            <button type='submit'>
              { isLoading ? 
                (<SpinnerIcon/>) :
                "Entrar"
              }
            </button>
          </Form>
        )}
        {switchStep === 2 && (
          <Form onSubmit={e => {e.preventDefault(); handleVerifyRecover()}}>
            {/* <span>Bem vindo de volta</span> */}
            <p>Recuperar minha senha</p>
            <div className="form">
              <label htmlFor="">Email</label>
              <input type="email" required placeholder="exemplo@gmail.com" value={emailToRecover} onChange={e => setEmailToRecover(e.target.value)} />
              {/* <label htmlFor="">Senha</label>
        <input type="password" placeholder="*****" /> */}
            </div>
            <div className="pass"></div>
            <button type='submit'>Enviar</button>
            <button
              className="back hvr-icon-back"
              onClick={() => setSwitchStep(1)}
            >
              {' '}
              <ArrowLeft size={16} className="hvr-icon" /> Voltar ao login
            </button>
          </Form>
        )}
        {switchStep === 3 && (
          <Form>
            <p>Verifique seu email</p>
            <span className="text">
              Enviamos seu link de redefinição de senha por e-mail!
            </span>
            {reSent && (
              <>
                <br /> <br />
                <span className="text">
                  Confirmação enviada novamente, não esqueça de verificar o lixo
                  eletronico e a caixa de spam.
                </span>
              </>
            )}
            <div className="form">
              {/* <label htmlFor="">Email</label>
              <input type="text" placeholder="exemplo@gmail.com" /> */}
              {/* <label htmlFor="">Senha</label>
          <input type="password" placeholder="*****" /> */}
            </div>
            <div className="pass">{/* <a href="">Esqueceu a senha?</a> */}</div>
            <button onClick={() => setReSent(true)}>
              Reenviar confirmação
            </button>
            <button
              className="back hvr-icon-back"
              onClick={() => {setSwitchStep(1); setReSent(false)}}
            >
              {' '}
              <ArrowLeft size={16} className="hvr-icon" /> Voltar ao login
            </button>
          </Form>
        )}
      </Main>
    </Container>
  )
}
