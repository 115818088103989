import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-start;
    gap: 20px;
    .fx33 + .fx33 {
        padding-left: 0;
    }

    .selectStyles{
        width: 50%;
    }

    .buttonEmployeeDelete{
        background: none;
        border: none;
        transition: 0.9s;
        :hover{
            cursor: pointer;
            transform: scale(1.4);
        }
    }
`;