import { IBNCCKnowledge } from "../../../../../../../types/bncc";
import { IResource } from "../../../../../../../types/types";
import { CurricularComponent } from "./CurricularComponent";
import { TitleKnowledgeArea, ListItem } from "./style";

interface KnowledgeAreaItemProps {
  resource: IResource;
  knowledgeArea: IBNCCKnowledge;
}

export function KnowledgeArea({
  resource,
  knowledgeArea,
}: KnowledgeAreaItemProps) {
  const curricularComponents = knowledgeArea.curricular_components.filter(
    (curricularComponent) =>
      resource.bncc?.ef.curricular_components?.includes(
        curricularComponent.name
      )
  );
  const hasCurricularComponents = Boolean(
    curricularComponents && curricularComponents.length > 0
  );
  return (
    <li>
      {" "}
      <TitleKnowledgeArea> - {knowledgeArea.name}</TitleKnowledgeArea>
      <ListItem>
        {hasCurricularComponents
          ? curricularComponents.map((curricularComponent) => (
              <CurricularComponent
                key={curricularComponent.name}
                curricularComponent={curricularComponent}
                resource={resource}
              />
            ))
          : null}
      </ListItem>
    </li>
  );
}
