import styled from 'styled-components'
export const Container = styled.table`
  border: 1px solid #626262;
  border-radius: 10px;
  padding: 10px;
  width: 100%;

  .span{
    color: black;
  }

  td {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 7px;
    span {
      color: #646464;
      font-size: 14;
      font-weight: 400;
    }
    p {
      font-size: 14px;
      font-weight: 400;
    }
  }

  tr.divider{
    td{
      text-transform: uppercase;
      margin-top: 15px;
    }
    &:first-child{
      td{
        margin-top: 0px;
      }
    }
  }
`

export const Label = styled.p`
font-size: 14px;
color: #646464;
`;
