import styled from "styled-components";

export const ContainerFooter = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 50px;
border-bottom: 1px solid #027294;

`

export const Footer = styled.div`
display: flex;
justify-content: space-evenly;
align-items: center;
width: 40%;
height: 100%;


`

interface NavigateButtonProps{
    $background: string
}



export const NavigateButton = styled.div<NavigateButtonProps>`
background-color: ${({$background})=> $background};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px 500px 500px 500px;
    height: 30px;
    width: 30px;
    font-size: 16px;
    color: white;
    transition: 1.2s;
    &:hover{
        cursor: pointer;
        transform: scale(1.2);
    }
`