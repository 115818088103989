import { SetStateAction } from "react";
import { Success } from "../Utils/Responses";
import { api } from "./api";

// data.ts

export interface User {
    Nome: string;
    Email: string;
    Perfil: string;
    Cliente: string;
    "Unidade(s) Educacional(is)": string;
    Pontuação: string;
    Acessos: string;
}

export const userData: User[] = [
    {
        Nome: "João Silva",
        Email: "joao@example.com",
        Perfil: "Administrador",
        Cliente: "Empresa A",
        "Unidade(s) Educacional(is)": "Escola X",
        Pontuação: "100",
        Acessos: "500",
    },
    {
        Nome: "Maria Oliveira",
        Email: "maria@example.com",
        Perfil: "Usuário",
        Cliente: "Empresa B",
        "Unidade(s) Educacional(is)": "Colégio Y",
        Pontuação: "80",
        Acessos: "300",
    },
    {
        Nome: "José Santos",
        Email: "jose@example.com",
        Perfil: "Gestor de unidade",
        Cliente: "Empresa C",
        "Unidade(s) Educacional(is)": "Faculdade Z",
        Pontuação: "120",
        Acessos: "700",
    },
    // Adicione mais objetos conforme necessário
];


type GetResponse = Success | undefined;

type ListAllProps = {
    idClient?: string
    dryRun?: boolean
}
export class ReportService {

    static async listAllUsers({ dryRun, idClient }: ListAllProps) {
        try {

            if (dryRun) {
                return userData;
            } else {
                const response = await api.get<GetResponse>(``)
                return response.data
            }

        } catch (error: any) {
            const errorResponse = error.response.data;
            if (errorResponse.errors) {
                const errorMessages = [];

                for (const key in errorResponse.errors) {
                    errorMessages.push(errorResponse.errors[key] + "\n");
                }
                alert(errorMessages);
            } else {
                alert(errorResponse.message);
            }
        }
    }
}