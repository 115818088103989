import { Plus } from 'lucide-react'
import { Link } from 'react-router-dom'
import { Select } from '../Form/Select'
import { Container } from './styles'
import { SearchTable } from '../SearchTable'
import { Divider } from '../Form/Divider'
import { Input } from '../Form/Input'

export function CallRegister(props: any) {
  return (
    <Container>
      <button onClick={() => props.onBack()} disabled={props.disabled}>
          Voltar
      </button>
      <Link to={props.link} className="hvr-grow">
        Cadastrar
        <Plus size={16} />
      </Link>
    </Container>
  )
}

