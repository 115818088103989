import { useState } from 'react'
import { LeftMenu } from '../../../Components/LeftMenu'
import { Abas, Container, Main, Sitemap, TreeItemContainer } from './styles'
import { ListDefault } from '../../../Components/ListDefault'
import { RequestResume } from './Resume'
import { CheckCircle, CheckSquare, CheckSquareIcon, Send, Verified, XIcon } from 'lucide-react'
import { XSquare } from 'lucide-react'
import { LucideView } from 'lucide-react'

export function RequestTransfer() {
  const [switchForm, setSwitchForm] = useState(1)
  const [isOpen, setIsOpen] = useState(false);

  const handleSelected = () => {
    setSwitchForm(3)
  }

  //Button Cancelar
  const onBack = () => {
    setSwitchForm(1);
  }

  return (
    <Container>
      <LeftMenu switchT={true} page={9} />
      <div className='box'>
        <Main>
          <Sitemap>
            <p>Transferência</p> <span>&bull;</span> <p>Solicitações</p>
          </Sitemap>

          <Abas>
            <button className={switchForm === 1 ? 'active' : ''}
              onClick={() => setSwitchForm(1)}
            >
              Solicitações Enviadas
            </button>
            <button className={switchForm === 2 ? 'active' : ''}
              onClick={() => setSwitchForm(2)}
            >
              Solicitações Recebidas
            </button>
          </Abas>

          {switchForm === 1 && (
            <ListDefault
              pages={[]}
              changeGet={(v: any) => { }}
              to={"1"}
              total={'1'}
            >
              <thead>
                <tr>
                  <td>Nome</td>
                  <td>Status</td>
                  <td>Ações</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <button
                      className="profile"
                      type="button"
                      onClick={() => handleSelected()}
                    >
                      Fulano de tal
                    </button>
                  </td>
                  <td>Em análise</td>

                  <td>
                    <button title="Reenviar" className="tooltip">
                      <Send/>
                    </button>
                    <button title="Cancelar solicitação" className="tooltip">

                      <XSquare/>
                    </button>
                  </td>

                </tr>
              </tbody>
            </ListDefault>
          )}


          {switchForm === 2 && (
             <ListDefault
             pages={[]}
             changeGet={(v: any) => { }}
             to={"1"}
             total={'1'}
           >
             <thead>
               <tr>
                 <td>Nome</td>
                 <td>Ações</td>
               </tr>
             </thead>
             <tbody>
               <tr>
                 <td>
                   <button
                     className="profile"
                     type="button"
                     onClick={() => handleSelected()}
                   >
                     Fulano de tal
                   </button>
                 </td>

                 <td>
                   <button title="Aprovar" className="tooltip">
                     <CheckSquare/>
                   </button>
                   <button title="Reprovar" className="tooltip">
                     <XSquare/>
                   </button>
                 </td>

               </tr>
             </tbody>
           </ListDefault>
          )}




          {switchForm === 3 && (
            <RequestResume
              onBack={onBack}
            />
          )}


        </Main>
      </div>
    </Container>
  )
}
