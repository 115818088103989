import React, { useEffect, useState } from 'react'
import { Datas } from '../styles'
import { TResource } from '../../../../../types/types'
import { useShowResource } from '../../../../../hooks/ResourceHooks/useShowResource'
import ResourceService from '../../../../../Services/Resouce.service'
import { IResource } from './types'



interface Props {
    resourceId?: string
    resource?: TResource
}


const BasicInformations = ({ resourceId }: Props) => {

    const [resource, setResource] = useState<IResource>()
    const isThumb = (resource && resource.thumbnails.length > 0)

    useEffect(() => {

        ResourceService.showResource({ identifier: resourceId }).then((res) => {
            if (res?.success) {
                console.log(res.data.data)
                setResource(res.data.data)
            }
        })

    }, [])




    return (
        <Datas>
            <main>
                <b>INFORMAÇÕES BÁSICAS</b>
                <p>Nome do recurso: {" "}{resource?.name}</p>
                <p> Coleção principal: {" "}
                    {resource?.collections?.map((collection, index) => {
                        return (
                            <p key={index}>
                                {collection.name}
                            </p>
                        )
                    })}
                </p>
                <p>URL: {" "}{resource?.url}</p>
                <p>Formato: {" "} {resource?.format}</p>
                <p>Linguagem: {" "} {resource?.language}</p>
                <p>Plataformas: {" "}
                    {resource?.plataforms?.map((plataform) => {
                        return (
                            <p>- {plataform.name}</p>
                        )
                    })}
                </p>
                <p>
                    Público Alvo {" "}
                    {resource?.target_audiences?.map((targetAudience) => {
                        return (
                            <p>
                                - {targetAudience.name}
                            </p>
                        )
                    })}
                </p>
                <p>
                    Thumbnails:
                </p>
                {
                    isThumb ?
                        resource.thumbnails.map((thumb, index) => {
                            console.log(thumb)
                            return (
                                <tr key={index}>
                                    <td>
                                        <img src={thumb} alt={`imagem ${index + 1}`} />
                                    </td>
                                </tr>
                            )
                        })
                        : <p>Não informado.</p>
                }

            </main>
        </Datas>
    )
}

export default BasicInformations
