import { useEffect, useState } from "react";
import { Controllers } from "../../../Components/Form/Controllers";
import { Divider } from "../../../Components/Form/Divider";
import { Input } from "../../../Components/Form/Input";
import { LeftMenu } from "../../../Components/LeftMenu";
import { SuperBox } from "../../../Components/SuperBox";
import { FormDefault } from "./FormDefault";
import { FormStudent } from "./FormStudent";
import { FormTech } from "./FormTech";
import { ModalSelect } from "./ModalSelect";
import { Container, Form, Main, Sitemap, Title } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumEmployees } from "../../../Utils/Types";
import { getNameEmployee } from "../../../Utils/Functions";
import { FormEmployee } from "./FormEmployee";
import { EmployeeRegistration } from "../EmployeeRegistragion";
import FormGuardian from "./FormGuardian";
import FormManager from "./FormManager";
import FormTeacher from "./FormTeacher";
import NewFormStudent from "./NewFormStudent/NewFormStudent";

export function UserRegistration() {
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [date, setDate] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const idUser = sessionStorage.getItem("location") || "";
  const [student, setStudent] = useState("");
  const [employeeList, setEmployeeList] = useState<any[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const edit = location.state;
  const title = edit ? "Edição" : "Cadastro";
  const onBack = () => navigate(-1);
  return (
    <SuperBox
      switch={true}
      page={4}
      path={`${title} \u2022 ${getNameEmployee(idUser as EnumEmployees)}`}
    >
      <Container>
        <div className="box">
          <Main>
            {(idUser === EnumEmployees.MANAGER ||
              idUser === EnumEmployees.MANAGER_EMPLOYEE ||
              idUser === EnumEmployees.ADMIN_EMPLOYEE ||
              idUser === EnumEmployees.DIRECTOR ||
              idUser === EnumEmployees.COORD) && (
                <FormEmployee typeEmployee={idUser} />
              )}

            {/* {idUser === EnumEmployees.MANAGER && (
              <FormManager />
            )} */}
            {idUser === EnumEmployees.GUARDIAN && (
              <FormGuardian user={idUser} />
            )}
            {/* Formulário de Professor */}
            {idUser === EnumEmployees.TEACHER && (
              <FormTeacher />
            )}
            {idUser === EnumEmployees.STUDENT && (
              <NewFormStudent
              
              />
              // <FormStudent
              //   name={name}
              //   cpf={cpf}
              //   date={date}
              //   phone={phone}
              //   email={email}
              //   confirmEmail={confirmEmail}
              //   setName={setName}
              //   setCpf={setCpf}
              //   setDate={setDate}
              //   setPhone={setPhone}
              //   setEmail={setEmail}
              //   setConfirmEmail={setConfirmEmail}
              // />
            )}
          </Main>
        </div>
      </Container>
    </SuperBox>
  );
}