import React, { Dispatch, SetStateAction } from 'react'
import { ListDefault } from '../../../../Components/ListDefault';
import { SearchTable } from '../../../../Components/SearchTable';
import { Edit, Trash } from 'lucide-react';
import { ModalShort } from '../../../../Components/Modals/ModalShort';
import { Link } from 'react-router-dom';
import { Input } from '../../../../Components/Form/Input';

interface Props {
    guardians: any[],
    setIsOpen: any,
    typeModal: any,
    setSelected: any,
    setSwitchScreen: any,
    setTypeProfile: any,
    setTextModal: any,
    isOpen: boolean,
    setTypeModal: any,
    setIdUserForShow: any,
    inputSearchView: string,
    setInputSearchView: Dispatch<SetStateAction<string>>,
    unit: any
}

const ListGuardian = ({
    guardians,
    setIsOpen,
    setSelected,
    setSwitchScreen,
    setTextModal,
    setTypeProfile,
    typeModal,
    isOpen,
    setTypeModal,
    setIdUserForShow,
    inputSearchView,
    setInputSearchView,
    unit

}: Props) => {

    console.log(guardians)

    return (
        <ListDefault
            onBack={() => {
                window.history.back();
            }}
            pages={[]}
            changeGet={(v: any) => { }}
            to={"1"}
            total={""}
        >
            <thead>
                <tr>
                    <td colSpan={7}>
                        <Input
                            flex="fx30"
                            type="search"
                            placeHolderTeacher="Pesquise por um responsável"
                            color="#039bc9"
                            value={inputSearchView}
                            onChange={setInputSearchView}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="wd20">Nome</td>
                    <td className="wd15">CPF</td>
                    <td className="wd10">Email</td>
                    <td className="wd20">Unidade Educacional</td>
                    <td className="wd10">Ação</td>
                </tr>
            </thead>
            <tbody>
                {guardians.map((option: any, index: any) => {
                    return (
                        <>
                            <ModalShort
                                isOpen={isOpen}
                                type={typeModal}
                                onRequestClose={() => { setIsOpen(false) }}
                                text={`Deseja excluir o responsável ${option.name}`}

                            />
                            <tr key={index}>
                                <td>
                                    <Link
                                        to=""
                                        onClick={() => {
                                            setSelected(option);

                                            setSwitchScreen(2);
                                            setTypeProfile(9);
                                        }}
                                    >
                                        {option?.user.name}
                                    </Link>
                                </td>
                                <td>
                                    {option.user.cpf ? option.user.cpf : "Não definido"}
                                </td>
                                <td>{option.user.email}</td>
                                <td>
                                    {unit
                                        ? unit.name
                                        : "Não definido"
                                    }
                                </td>
                                <td>
                                    <button
                                        onClick={() => {
                                            setSelected(option);
                                            setIdUserForShow(option.identifier)
                                            setSwitchScreen(3);
                                        }}
                                        className="hvr-grow"
                                    >
                                        <Edit />
                                    </button>

                                    <button
                                        className="hvr-grow"
                                        onClick={() => {
                                            setIsOpen(true)
                                            setTypeModal("delete")
                                            setTextModal("Deseja excluir este resposnável?")
                                        }}
                                    >
                                        <Trash />
                                    </button>
                                </td>
                            </tr>
                        </>
                    );
                })}
            </tbody>
        </ListDefault>
    )
}

export default ListGuardian
