import { StudentData } from "../Pages/Registration/UserRegistration/NewFormStudent/NewFormStudent";
import { CreateSuccess, Forbidden, NotFound, ServiceError, Success, Success2, Unauthorized, UnprocessableContent } from "../Utils/Responses"
import { Student } from "../Utils/Types";
import { api } from "./api";

type StudentCreateProps = {
    educationInstitution: string,
    student: Student
}
type StudentDeleteProps = {
    educationInstitution: string,
    student: string
}
type StudentUpdateProps = {
    educationInstitution: string,
    student: Student
}
type StudentListProps = {
    educationInstitution: string,
    student: Student
}

type StudentShowProps = {
    educationInstitution: string,
    studentIdentifier?: string
}

type StudentCreateResponse =
    CreateSuccess |
    Unauthorized;

type StudentListResponse =
    Success |
    Unauthorized |
    ServiceError |
    Forbidden;

type StudentDeleteResponse =
    Success2 |
    Unauthorized |
    Forbidden;

type StudentListingPros = {
    unitId: String
}


type StudentImportProps = {
    unitId: string
    file: FormData
}

type StudentImportResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;

type StudentPostProps = {
    idUnit: string,
    data: Student
}

type StudentPostResponse = Success | Unauthorized | Forbidden | UnprocessableContent

type StudentPutProps = {
    educationInstitution: string,
    student: Student
}

type StudentPutResponse = Success | Unauthorized | Forbidden | NotFound



class StudentService {

    static async put({ educationInstitution, student }: StudentPutProps): Promise<StudentPutResponse> {
        
        const identifier = student.identifier;

        delete student.identifier;


        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.put<StudentPutResponse>(
                `/admin/education-institutions/${educationInstitution}/students/${identifier}`,
                student,
                config
            )

            return response.data;

        } catch (error: any) {
            throw new Error("Can not update student.")
        }

    }

    static async post({ data, idUnit }: StudentPostProps): Promise<StudentPostResponse> {
        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.post(
                `/admin/education-institutions/${idUnit}/students`,
                {...data,  equivalent_age: data.special_need_id.length > 0 ? data.equivalent_age : null},
                config
            )

            return response.data


        } catch (error: any) {
            throw new Error("Can not create Student");
        }
    }

    static async import({ file, unitId }: StudentImportProps): Promise<StudentImportResponse> {
        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    "Content-Type": "multipart/form-data",
                }
            }

            const response = await api.post(
                `/admin/education-institutions/${unitId}/students/import`,
                file,
                config
            )

            return response.data;
            ;
        } catch (error: any) {
            throw new Error("Can not import Students", error.response.data)
        }
    }

    static async list(input: StudentListingPros): Promise<StudentListResponse> {

        const { unitId } = input;

        try {

            const config = {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }

            const response = await api.get(
                `/admin/education-institutions/${unitId}/students`,
                config
            )
            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }

        } catch (erro: any) {
            throw new Error("Can not list Students.")
        }
    }

    static async delete(input: StudentDeleteProps): Promise<StudentDeleteResponse> {
        const { educationInstitution, student } = input


        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }

        try {
            const response = await api.delete<StudentDeleteResponse>(
                `/admin/education-institutions/${educationInstitution}/students/${student}`,
                config
            )
            return response.data;
        } catch (error: any) {
            throw new Error("Can not delete student.")
        }
    }

    static async create(input: StudentCreateProps): Promise<StudentCreateResponse> {
        const { educationInstitution, student } = input


        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }


        try {
            const response = await api.post<StudentCreateResponse>(
                `/client/${educationInstitution}/students`,
                {
                    ...student,
                    equivalent_age: student.special_need_id.length > 0 ? student.equivalent_age : null
                },
                config,
            )
            return response.data;
        } catch (error: any) {
            throw new Error("Can not create Student.")
        }
    }

    static async uptade(input: StudentUpdateProps): Promise<StudentCreateResponse> {
        const { educationInstitution, student } = input

        const identifier = student.identifier;

        delete student.identifier;

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }
        try {
            const response = await api.put<StudentCreateResponse>(
                `/admin/education-institutions/${educationInstitution}/students/${identifier}`,
                {
                    ...student,
                    equivalent_age: student.special_need_id.length > 0 ? student.equivalent_age : null
                },
                config
            )
            return response.data;
        } catch (error: any) {
            console.log("Error: ", error.response.data);
            throw new Error("Can not update Student.")
        }
    }

    static async show(input: StudentShowProps): Promise<StudentListResponse> {

        const { educationInstitution, studentIdentifier } = input

        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        }

        try {
            const response = await api.get(
                `/admin/education-institutions/${educationInstitution}/students/${studentIdentifier}`,
                config
            )
            switch (response.status) {
                case 200:
                    return { data: response.data.data, success: true, message: "ok" } as Success;
                case 401:
                    return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
                default:
                    return { success: false, message: "ServiceError" } as ServiceError
            }
        } catch (error: any) {
            throw new Error("Can not show Student.")
        }
    }

}

export default StudentService

