import { api } from "./api";
import { AxiosError } from "axios";
import {
  CreateSuccess,
  Forbidden,
  Success,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { Class } from "../Utils/Types";

type TurmaCreateProps = {
  name: string; // Nome da turma
  academic_year: number | null; // Ano letivo
  initials?: string; // Sigla
  modality: string[]; // Modalidade
  phase_id?: string; // Fase
  academic_stage_id?: string; // Etapa
  age_range_id?: string; // Faixa etária
};

type TurmaGetProps = {
  path: string; //Caminho da rota
};

type TurmaListProps = {
  idState: string | undefined
}

type TurmaListResponse =
  Success | Unauthorized;

type TurmaCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type TurmaGetReponse = CreateSuccess | Unauthorized;

type TurmaShowProps = {
  idInstitution: string;
  classID: string
}

type TurmaShowResponse =
  Success | Unauthorized | Forbidden;


type ClassroomAddStudentResponse =
  Success | Unauthorized | Forbidden | UnprocessableContent;

type ClassroomAddStudentProps = {
  idInstitution: string;
  classID: string;
  students: FormData;
}

class TurmaService {

  static async create2(data: Class) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const locationData = JSON.parse(
        sessionStorage.getItem("locationData") || "{}"
      );

      const response = await api.post<TurmaCreateResponse>(
        `/client/${locationData.identifier}/classrooms`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async classAddStudent(input: ClassroomAddStudentProps){
    const { idInstitution, classID, students } = input;
    

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.post<ClassroomAddStudentResponse> (
        `/admin/education-institutions/${idInstitution}/classrooms/${classID}/students`,
          students,
        config
      )
      
      return response.data;

    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async showClass(input: TurmaShowProps): Promise<TurmaShowResponse> {
    const { idInstitution, classID } = input

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(`/admin/education-institutions/${idInstitution}/classrooms/${classID}`,
        config
      )

      return response.data;

    } catch (error: any) {
      throw new Error("Can not show Classroom.")
    }
  }


  static async list(input: TurmaListProps): Promise<TurmaListResponse> {

    const { idState } = input

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.get<TurmaListResponse>(`/admin/education-institutions/${idState}/classrooms`,
        config
      )

      return response.data;
    } catch (error: any) {
      throw new Error("Can not list Classrooms.")
    }
  }

  static async create(data: TurmaCreateProps) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const locationData = JSON.parse(
        sessionStorage.getItem("locationData") || "{}"
      );

      const response = await api.post<TurmaCreateResponse>(
        `/admin/education-institutions/${locationData.identifier}/classrooms`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async update(data: TurmaCreateProps, id: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const locationData = JSON.parse(
        sessionStorage.getItem("locationData") || "{}"
      );

      const response = await api.put<TurmaCreateResponse>(
        `/client/${locationData.identifier}/classrooms/${id}`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async get(data: TurmaGetProps) {

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<TurmaGetReponse>(data.path, config);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }
}

export default TurmaService;
