import React, { useState } from 'react'
import { Controllers } from '../../../../../../Components/Form/Controllers';
import { ModalShort } from '../../../../../../Components/Modals/ModalShort';
import { useNavigate } from 'react-router-dom';
import { BoxResume } from '../../../../../../Components/Form/BoxResume';
import GuardianService from '../../../../../../Services/Guardians.service';


export type GuardianData = {
    identifier: string;
    name: string;
    cpf: string;
    birth_date: string;
    phone: string;
    email: string;
    students: string[];
}

interface Props {
    stepForm: number,
    setStepForm: any,
    dataGuardian: GuardianData,
    studentsSelecteds: any[],
    unit: any
}

const ReviewGuardianEdit = ({
    dataGuardian,
    setStepForm,
    stepForm,
    studentsSelecteds,
    unit
}: Props) => {

    const navigate = useNavigate()
    const [modalSave, setModalSave] = useState(false)

    function saveGuardian() {

        GuardianService.update({ educationInstitution: unit.identifier, guardian: dataGuardian }).then((response) => {
            if (response.success) {
                setModalSave(true)
            }
        })

    }

    return (
        <div>
            <br />
            <ModalShort
                type="success"
                text="Cadastro salvo com sucesso"
                isOpen={modalSave}
                onRequestClose={() => window.location.reload()}
                onConfirm={() => {
                    window.location.reload()
                }}
            />
            <BoxResume
                label="Verifique se os dados que você cadastrou estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir."
            >
                <tr className="divider">
                    <td>
                        <span>Responsável</span>
                    </td>
                </tr>
                <tr>
                    <td>
                        <>
                            <span>Aluno(s):</span>
                            {
                                studentsSelecteds.map((student) => {
                                    return (
                                        <p>{student.name}</p>
                                    )
                                })
                            }
                        </>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Nome:</span>
                        <p>{dataGuardian.name}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>CPF:</span>
                        <p>{dataGuardian.cpf}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Data de Nascimento:</span>
                        <p>{dataGuardian.birth_date}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Telefone:</span>
                        <p>{dataGuardian.phone}</p>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Email:</span>
                        <p>{dataGuardian.email}</p>
                    </td>
                </tr>
            </BoxResume>
            <br />
            <Controllers
                save={true}
                functionPrev={() => { setStepForm(1) }}
                functionSave={() => { saveGuardian() }}
                functionCancel={() => navigate(-1)}
                enableNext={true}
                enablePrev={true}
            />
        </div>
    )
}

export default ReviewGuardianEdit
