import { useEffect, useState } from 'react'
import { LeftMenu } from '../../../Components/LeftMenu'
import { Abas, Box, BoxSelect, ButtonAdd, Container, ContainerNew, Main, Sitemap, Table, } from './styles'
import { Select } from '../../../Components/Form/Select'
import { Divider } from '../../../Components/Form/Divider'
import { FileDown } from 'lucide-react'
import UnidadeService from '../../../Services/Unidade.service'
import TurmaService from '../../../Services/Turma.service'
import { FormSave } from './Form3'
import { FormSeachStudents } from './Form2'
import { Controllers } from '../../../Components/Form/Controllers'
import { EtapaCreate } from '../../../Services/EtapaAcademica.service'
import BNCCService from '../../../Services/BNCC.service'
import { Input } from '../../../Components/Form/InputHook'
import { useListAcademicYears } from '../../../hooks/list/useListAcademicYears'
import { getCurrentYear } from '../../../Utils/Functions'

export function ClassTransfer() {

  const [switchForm, setSwitchForm] = useState(1);
  const [studentsSelected, setStudentsSelected] = useState<any>([]);
  const [academicStage, setAcademicStage] = useState<EtapaCreate[]>([])

  const [units, setUnits] = useState<any>([]);
  const [classes, setClasses] = useState<any>([]);
  const [students, setStudents] = useState<any>([]);

  const [classFiltered, setClassFiltered] = useState<any>([]);
  const [unitSelected, setUnitSelected] = useState<any>([]);
  const [classSelected, setClassSelected] = useState<any>([]);
  const [idStageSelected, setIdStageSelected] = useState("")

  const [unitSelectedTranfer, setUnitSelectedTranfer] = useState<any>([]);
  const [stageSelectedTranfer, setStageSelectedTranfer] = useState("");
  const [classSelectedTranfer, setClassSelectedTranfer] = useState<any>([]);
  const [classFilterTransfer, setClassFilterTransfer] = useState<any>([]);
  const [classesTransfer, setClassesTransfer] = useState<any>([]);

  const [error1, setError1] = useState(false)
  const [error2, setError2] = useState(false)
  const [error3, setError3] = useState(false)

  const data  = getCurrentYear().toString();

  // listar unidade
  useEffect(() => {
    UnidadeService.list().then((response) => {
      if (response.success) {
        setUnits(response.data)
      }
    })

    // listar etapa
    BNCCService.listBNCCStages().then((response) => {
      if (response?.success) {
        setAcademicStage(response.data)
      }
    })

  }, [])

  //listar turma da unidade selecionada
  useEffect(() => {

    TurmaService.list({ idState: unitSelected.identifier }).then((response) => {
      if (response.success) {
        setClasses(response.data);
      }
    }).catch((e) => { });

  }, [unitSelected]);

  // filtrar as turmas pela etapa
  useEffect(() => {
    const filteredClass = classes.filter((classe: any) => classe.stage_id === idStageSelected);
    setClassFiltered(filteredClass)
  }, [idStageSelected, classes])

  // selecionar todos os alunos
  const handleSelectAll = () => {
    setStudentsSelected(students.students);
  };

  // desmarmar todos os alunos
  const handleDeselectAll = () => {
    setStudentsSelected([]);
  };

  // listar estudantes da turma
  useEffect(() => {

    if (unitSelected !== "" && classSelected !== "") {

      const input = {
        idInstitution: unitSelected.identifier,
        classID: classSelected.identifier
      }

      TurmaService.showClass(input).then((response) => {
        if (response.success) {
          setStudents(response.data)
        }
      }).catch((e) => { });
    }

  }, [unitSelected, classSelected]);


  // listar turma pra tranferencia
  useEffect(() => {

    TurmaService.list({ idState: unitSelectedTranfer.identifier }).then((response) => {
      if (response.success) {
        setClassesTransfer(response.data);
      }
    }).catch((e) => { });

  }, [unitSelectedTranfer]);

  // filtrar turma pra tranferência
  useEffect(() => {
    const filteredClass = classesTransfer.filter((classe: any) => classe.stage_id === stageSelectedTranfer);
    setClassFilterTransfer(filteredClass)
  }, [classesTransfer, stageSelectedTranfer])

  // verificar os dados da transferência
  const verifier = () => {
    if (unitSelectedTranfer == "") {
      setError1(true)
    } else if (stageSelectedTranfer == "") {
      setError2(true)
    } else if (classSelectedTranfer == "") {
      setError3(true)
    } else if (studentsSelected.length === 0) {
      alert("Selecione o(s) aluno(s)")
    } else {
      setSwitchForm(3)
    }
  }

  console.log("stu: ", studentsSelected)

  // selecionar os estudantes
  const toggleSelection = (student: any) => {
    setStudentsSelected((prevSelected: any) => {
      if (prevSelected.includes(student)) {
        return prevSelected.filter(
          (selectedStudent: any) => selectedStudent !== student
        );
      } else {
        return [...prevSelected, student];
      }
    });
  }

  // voltar
  const onBack = () => {
    setSwitchForm(1);
  }

  return (
    <Container>
      <LeftMenu switchT={true} page={8} />
      <div className='box'>
        <Main>
          <Sitemap>
            <p>Transferencia</p> <span>&bull;</span> <p>Turma</p>
          </Sitemap>
          <Abas>
            <button>
              Transferir Turma
            </button>
          </Abas>

          {switchForm === 1 && (
            <>
              <Select
                flex=""
                label="Nome da Unidade Educacional"
                value={unitSelected ? unitSelected.identifier : ""}
                onChange={(value: any) => {
                  const selectedUnit = units.find((item: any) => item.identifier === value);
                  setUnitSelected(selectedUnit)
                }}
              >
                <option value="">Selecione a unidade</option>
                {units.map((unit: any) => {
                  return (
                    <option key={unit.identifier} value={unit.identifier}>{unit.name}</option>
                  )
                })}
              </Select>
              <BoxSelect>
                <Divider>
                  <Input
                    type="text"
                    flex="fx15"
                    label="Ano Letivo"
                    value={data}
                    required={false}
                    disabled={true}
                  />
                  <Select
                    flex="fx50"
                    label="Etapa"
                    value={idStageSelected}
                    onChange={setIdStageSelected}
                  >
                    <option value="">Selecione uma Etapa</option>
                    {academicStage.map((stage) => {
                      return (
                        <option key={stage.identifier} value={stage.identifier}>{stage.name}</option>
                      )
                    })}
                  </Select>
                  <Select
                    flex="fx50"
                    label="Nome da Turma"
                    value={classSelected ? classSelected.identifier : ""}
                    onChange={(value: any) => setClassSelected(classFiltered.find((classe: any) => classe.identifier === value))}
                  >
                    <option value="">Selecione a turma</option>
                    {classFiltered.map((classe: any) => {
                      return (
                        <option key={classe.identifier} value={classe.identifier}>{classe.name}</option>
                      )
                    })}
                  </Select>

                </Divider>
              </BoxSelect>

              <ButtonAdd>
                <button className="" hvr-icon-pop>
                  <FileDown className="hvr-icon" size={20} /> Adicionar Turma a Lista
                </button>
              </ButtonAdd>


              {classSelected.length !== 0 && (
                <>
                  <Box>
                    <div className='bnt-mark'>
                      <button onClick={handleSelectAll}>Marcar Todos</button>
                      <button onClick={handleDeselectAll}>Desmarcar Todos</button>
                    </div>
                    <Table>
                      <p>LISTA DE ESTUDANTE(S) ENCONTRADO(S)</p>
                      <table>
                        <thead>
                          <tr>
                            <td className='header-cell'>Nome</td>
                            <td>Usuário</td>
                            <td>Unidade Educacional</td>
                            <td>Etapa</td>
                            <td>Nome da Turma</td>
                            <td>Status</td>
                          </tr>
                        </thead>
                        <tbody>
                          {students.students && (
                            students.students.map((student: any) => (
                              <tr key={student.identifier}>
                                <td>
                                  <input
                                    type="checkbox"
                                    name="student"
                                    checked={studentsSelected.includes(student)}
                                    onChange={() => toggleSelection(student)}
                                  />
                                  {student.name}
                                </td>
                                <td>{"indefinido"}</td>
                                <td>{unitSelected.name}</td>
                                <td>{students.stage.name}</td>
                                <td>{students.name}</td>
                                <td>{"indefinido"}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </Table>
                    <ButtonAdd>
                      <button className="" hvr-icon-pop
                        onClick={() => setSwitchForm(2)}
                      >
                        <FileDown className="hvr-icon" size={20} /> Adicionar Estudante a Lista
                      </button>
                    </ButtonAdd>
                  </Box>

                  <ContainerNew>
                    <div className='text'>
                      <p>Definir Unidade Educacional e Turma</p>
                    </div>
                    <section>
                      <Select
                        error={error1}
                        flex=""
                        label="Nome da Unidade Educacional"
                        value={unitSelectedTranfer.identifier}
                        onChange={(value: any) => setUnitSelectedTranfer(units.find((unit: any) => unit.identifier === value))}
                      >
                        <option value="">Selecione uma unidade</option>
                        {units.map((unit: any) => {
                          return (
                            <option key={unit.identifier} value={unit.identifier}>{unit.name}</option>
                          )
                        })}
                      </Select>
                      <BoxSelect>
                        <Divider>
                          <Input
                            type="text"
                            flex="fx15"
                            label="Ano Letivo"
                            value={data}
                            required={false}
                            disabled={true}
                          />
                          <Select
                            error={error2}
                            flex="fx50"
                            label="Etapa"
                            value={stageSelectedTranfer}
                            onChange={setStageSelectedTranfer}
                          >
                            <option value="">Selecione uma Etapa</option>
                            {academicStage.map((stage: any) => {
                              return (
                                <option key={stage.identifier} value={stage.identifier}>{stage.name}</option>
                              )
                            })}
                          </Select>
                          <Select
                            error={error3}
                            flex="fx50"
                            label="Nome da Turma"
                            value={classSelectedTranfer.identifier}
                            onChange={(value: any) => setClassSelectedTranfer(classFilterTransfer.find((classe: any) => classe.identifier === value))}
                          >
                            <option value="">Selecione a turma</option>
                            {classFilterTransfer.map((classe: any) => {
                              return (
                                <option key={classe.identifier} value={classe.identifier}>{classe.name}</option>
                              )
                            })}
                          </Select>
                        </Divider>
                      </BoxSelect>
                    </section>
                    {/* <ButtonAdd>
                      <button className="" hvr-icon-pop
                      >
                        <PlusCircleIcon className="hvr-icon" size={20} /> Nova Turma
                      </button>
                    </ButtonAdd> */}
                  </ContainerNew>

                  <Controllers
                    functionNext={() => verifier()}
                    functionCancel={() => window.location.reload()}
                    enableNext={true}
                    enablePrev={false}
                  />

                </>
              )}
            </>
          )}

          {switchForm === 2 && (
            <FormSeachStudents
              onBack={onBack}
              setStudentsSelected={setStudentsSelected}
            />
          )}

          {switchForm === 3 && (
            <FormSave
              onBack={onBack}
              studentsSelected={studentsSelected}
              unitSelected={unitSelected}
              classSelected={classSelected}
              unitSelectedTranfer={unitSelectedTranfer}
              stageSelectedTranfer={stageSelectedTranfer}
              classSelectedTranfer={classSelectedTranfer}
            />
          )}

        </Main>
      </div>
    </Container>
  )
}
