import { Access } from "../../Access";
import { Container, Footer, Header } from "./styles";

interface ViewProps {
    back: any,
    editViewMode: string,
}

export function ViewSystemProfiles({ back, editViewMode }: ViewProps) {
    return (
        <Container>

            <Header>
                <div className="information">
                    <label>Perfil de usuário</label>
                    <p>Loren Ipsum</p>
                    <label>Descrição</label>
                    <p>Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s.
                    </p>
                </div>
                <div className="information">
                    <label>Nível de Permissão</label>
                    <p>Loren Ipsum</p>
                    <label>Descrição</label>
                    <p>Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the industry's
                        standard dummy text ever since the 1500s. Lorem Ipsum is simply dummy
                        text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s.
                    </p>
                </div>
            </Header>

            <Access
                editViewMode={editViewMode}
            />

            <Footer>
                <span></span>
                <footer>
                    <button onClick={back}>Voltar</button>
                </footer>
            </Footer>

        </Container>
    )
}