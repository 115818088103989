import axios from 'axios'
import { useState } from 'react'
import { BoxResume } from '../../../../Components/Form/BoxResume'
import { Controllers } from '../../../../Components/Form/Controllers'
import { Divider } from '../../../../Components/Form/Divider'
import { Input } from '../../../../Components/Form/Input'
import { ModalShort } from '../../../../Components/Modals/ModalShort'
import { Form, Upload } from '../styles'
import { api } from '../../../../Services/api'
import { useNavigate } from 'react-router-dom'
import ClienteService from '../../../../Services/Cliente.service'

export function Form3(props: any) {
  const [modalSave, setModalSave] = useState(false);
  const navigate = useNavigate();
  let isCreated = false;

  async function saveClient() {
    const token = `bearer ${localStorage.getItem('token')}`
    let packages: any[] = [];
    let collections: any[] = [];
    props.selectedPackage.map((pack: any) => {
      packages.push(pack[0].identifier)
    })
    props.selectedCollection.map((col: any) => {
      collections.push(col[0].identifier)
    })

    const data = new FormData();
    data.append("social_name", props.socialName);
    data.append("cnpj", props.cnpj);
    data.append("phone", props.phone);
    data.append("email", props.email);
    // data.append("logo", props.img, 'logo.jpg');
    data.append("address[address]", props.address);
    data.append("address[number]", props.number);
    data.append("address[complement]", props.complement);
    data.append("address[neighborhood]", props.neighborhood);
    data.append("address[city]", props.city);
    data.append("address[state]", props.state);
    data.append("address[zip_code]", props.zipCode);
    data.append("plan[max_students]", props.studentsNumber);
    data.append("plan[max_employees]", props.teachersNumber);
    data.append("plan[max_education_institutions]", props.educationNumber);

    let infos = data.get("plan[max_students]")
    packages.forEach((pkg) => {
      data.append("plan[packages][]", pkg);
    });

    collections.forEach((col) => {
      data.append("plan[collections][]", col);
    });

    ClienteService.post(data).then((response) => {
      if (response.success) {
        setModalSave(true)
      }
    })

    // try{
    //   api.post('/admin/clients',data,
    //   {
    //     headers: {
    //       Authorization : token,
    //       'Content-Type': 'multipart/form-data',
    //     },
    //   }).then(response => {
    //     if(response.status === 201) {
    //       setModalSave(true)
    //     }
    //   }).catch(error => {
    //     const errorResponse = error.response.data
    //     const errorMessages = [];
    //     for (const key in errorResponse.errors) {
    //       errorMessages.push(errorResponse.errors[key] + '\n')
    //     }
    //     alert(errorMessages)
    //   }
    //   )
    // } catch(error) {
    //   alert(error)
    // }

  }

  props.selectedCollection.map((item: any) =>
    item.map((item2: any) => {
      return (
        <p>{item2.name}</p>
      )
    })
  )




  return (
    <Form>
      <ModalShort
        type="success"
        text="Cliente cadastrado com sucesso"
        isOpen={modalSave}
        onRequestClose={() => navigate('/clientes/list')}
        onConfirm={() => {
          return
        }}
      />
      <BoxResume label="Verifique se os dados que você cadastrou estão corretos. Se estiver tudo ok clique no botão salvar ou então, clique no botão anterior para revisar os dados.">
        <tr className="divider">
          <td>
            <span>Cliente</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>Razão Social:</span>
            <p>{props.socialName}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>CNPJ:</span>
            <p>{props.cnpj}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Telefone:</span>
            <p>{props.phone}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Email:</span>
            <p>{props.email}</p>
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Endereço</span>
          </td>
        </tr>
        <tr>
          <td>
            <span>CEP:</span>
            <p>{props.zipCode}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Rua/Av:</span>
            <p>{props.address}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Número:</span>
            <p>{props.number}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Complemento:</span>
            <p>{props.complement}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Bairro:</span>
            <p>{props.neighborhood}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Cidade:</span>
            <p>{props.city}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Estado:</span>
            <p>{props.state}</p>
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Coleções</span>
          </td>
        </tr>
        <tr>
          <td>
            {props.selectedCollection.map((item: any) =>
              item.map((item2: any, key: any) => {
                return (
                  <p key={key}>{item2.name}</p>
                )
              })
            )}
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Pacotes</span>
          </td>
        </tr>
        <tr>
          <td>
            {props.selectedPackage.map((item: any)=>
              item.map((item2: any)=>
                <p>{item2.name}</p>
              )
            )}
          </td>
        </tr>
        <br />
        <tr>
          <td>
            <span>Número de Usuários Docentes:</span>
            {
              <p>{props.teachersNumber}</p>
            }
          </td>
        </tr>
        <tr>
          <td>
            <span>Número de Usuários Estudantes:</span>
            <p>{props.studentsNumber}</p>
          </td>
        </tr>
        <tr>
          <td>
            <span>Número de Unidades Educacionais:</span>
            <p>{props.educationNumber}</p>
          </td>
        </tr>
      </BoxResume>
      <Controllers
        save={true}
        functionPrev={() => props.prev()}
        functionSave={() => {
          saveClient()
        }}
        functionCancel={()=>{
          navigate('/clientes/list')
        }}
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  )
}
