import { useEffect, useState } from "react";
import CollectionService, { ICollection } from "../../../../Services/Collection.service";
import { ContainerCategorie, ContainerCategories, ContainerLevelOne } from "./styles";

interface Props {
    collection?: ICollection
}

interface TypeSubCategory {
    children: TypeSubCategory[]
    description: string | null
    identifier: string
    level: number
    name: string
    path: string
}

export function Expanded({
    collection
}: Props) {

    const [categoriesArray, setCategoriesArray] = useState<any[]>([])

    useEffect(() => {
        CollectionService.listCategoriesByIdCollection(collection?.identifier).then((response) => {
            setCategoriesArray(response)
        })
    }, [])

    function Childrens({ info }: { info: TypeSubCategory }) {
        return <div className="cont-sub">
            <ContainerLevelOne>
                <p>{info.name}</p>
            </ContainerLevelOne>

            {info.children.length ?
                <div className="div-level">
                    {info.children.map((cat) => <Childrens info={cat} />)}
                </div>
                : null}
        </div>
    }

    return (
        <ContainerCategories>
            {categoriesArray.map((category: any) =>
                <ContainerCategorie>
                    <div className="container-title">
                        <p>{category.name}</p>
                    </div>

                    <div className="div-level">
                        {category.subcategories.map((sub: TypeSubCategory) => {
                            return (
                                <Childrens info={sub}/>
                            )
                        })}
                    </div>
                </ContainerCategorie>
            )}
        </ContainerCategories>
    )
}