import { useState } from 'react'
import { Divider } from '../../../../Components/Form/Divider'
// import { Select } from '../../../../Components/Form/Select'
import { Container, Tables } from './styles'
import { api } from '../../../../Services/api';
import { useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface TypeCollection {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

interface TypePackage {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

export function SelectTable(props: any) {
  const [disponibleCollections, setDisponibleCollections] = useState<TypeCollection[]>([])
  const [disponiblePackages, setDisponiblePackages] = useState<TypePackage[]>([])

  useEffect(() => {

    async function getCollections() {
      api.get('/admin/collections', {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        setDisponibleCollections(response.data.data);
      })
    }

    async function getPackages() {
      api.get('/admin/packages', {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        setDisponiblePackages(response.data.data);
      })
    }

    getCollections();
    getPackages();

  }, []);

  function handleSelectCollection(v: any) {
    const col = disponibleCollections.filter(col => col.identifier === v);
    props.changeCollection([...props.selectedCollection, col]);
  }

  function handleSelectPackage(v: any) {
    const pack = disponiblePackages.filter(pack => pack.identifier === v);
    props.changePackage([...props.selectedPackage, pack]);
  }

  const animatedComponents = makeAnimated();

  return (
    <Container>
      <Divider>

        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={disponibleCollections}
          onChange={(e: any) => handleSelectCollection(e.identifier)}
          getOptionLabel={(e: any) => e.name}
          placeholder="Selecione uma coleção"
          className='selectStyles'
        />

        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={disponiblePackages}
          getOptionLabel={(e: any) => e.name}
          onChange={(e: any) => handleSelectPackage(e.identifier)}
          placeholder="Selecione uma coleção"
          className='selectStyles'
        />

        {/* <Select flex="fx50" label="Coleções" inputId="colecoesid" onChange={(v:any) => handleSelectCollection(v)}>
          <option value="">Selecione uma coleção</option>
          {disponibleCollections.map(cole => {
            return(
                <option value={cole.identifier} key={cole.identifier}>{cole.name}</option>
            )
          })}
        </Select>
        <Select flex="fx50" label="Pacotes" inputId="pacotesid" onChange={(v:any) => handleSelectPackage(v)}>
          <option value="">Selecione um pacote</option>
          {disponiblePackages.map(pack => {
            return(
                <option value={pack.identifier} key={pack.identifier}>{pack.name}</option>
            )
          })}
        </Select> */}
      </Divider>
      <label htmlFor="">{props.label}</label>
      <Tables>
        <table>
          <thead>
            <td>Coleções</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedCollection.map((col: any) => {
              return (
                <tr>
                  <td>{col[0].name}</td>
                  <td>
                    <button
                      onClick={() => {
                        const newSelectedCollection = props.selectedCollection.filter((c: any) => c[0].identifier !== col[0].identifier);
                        props.changeCollection(newSelectedCollection);
                      }}
                    >Remover</button>
                  </td>
                </tr>
              )
            })}

          </tbody>
        </table>
        <table>
          <thead>
            <td>Pacotes</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedPackage.map((pack: any) => {
              return (
                <tr>
                  <td>{pack[0].name}</td>
                  <td>
                    <button
                      onClick={() => {
                        const newSelectedPackage = props.selectedPackage.filter((p: any) => p[0].identifier !== pack[0].identifier);
                        props.changePackage(newSelectedPackage);
                      }}
                    >Remover</button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Tables>
    </Container>
  )
}
