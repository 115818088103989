import { ArrowLeftRightIcon, Backpack, Bell, Settings, X } from 'lucide-react'
import { Container, Icons } from './styles'
import { useState } from 'react'
import { SelectClientUnit } from '../../Pages/Home/styles';
import { Select } from '../Form/Select';
import { Atividade } from '../../Pages/Home/Atividade';

export function Header(props: any) {
  const [switchNotifications, setSwitchNotiications] = useState(false);

  function handleToggleNotifications(e: boolean){
    if(e){
      setSwitchNotiications(false)
    } else {
      setSwitchNotiications(true)
    }
  }

  return (
    <Container>
      <p>{props.path}</p>
      {
      <SelectClientUnit className='unit'>
        <div>
          <Select>
            <option value="">Selecione...</option>
          </Select>
          <p>
            <Backpack size={22} />
            <ArrowLeftRightIcon size={22} />
          </p>
        </div>
      </SelectClientUnit>
      }
      <Icons className='icons'>
        <button className='hvr-icon-spin'>
            <Settings className='hvr-icon' size={17}/>
        </button>
        <button className='hvr-icon-buzz-out' onClick={() => handleToggleNotifications(switchNotifications)}>
          {switchNotifications ? <X className='hvr-grow' size={17}/> : <Bell className='hvr-icon' size={17}/>}
          <span></span>
        </button>
        <div className={switchNotifications ? "notiications active" : "notiications"}>
          <button>
          <p>Assunto</p>
          <span>Resumo do que está sendo notificado para o usuário mo do que está sendo notificado para o usuário </span>
          </button>
          <button>
          <p>Assunto</p>
          <span>Resumo do que está sendo notificado para o usuário mo do que está sendo notificado para o usuário </span>
          </button>
          <button>
          <p>Assunto</p>
          <span>Resumo do que está sendo notificado para o usuário mo do que está sendo notificado para o usuário </span>
          </button>
          <button>
          <p>Assunto</p>
          <span>Resumo do que está sendo notificado para o usuário mo do que está sendo notificado para o usuário </span>
          </button>
          
        </div>
      </Icons>
    </Container>
  )
}
