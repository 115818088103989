import styled from "styled-components";


export const ContainerSelectSearch = styled.div`

width: 100%;
height: 80px;
display: flex;
justify-content: center;
align-items: center;

.select-search{
    width: 80%;
    height: 20px;
}

`