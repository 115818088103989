import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
`
export const Header = styled.div``

export const DivList = styled.div`
    display: flex;
    width: 45%;
    border: 1px solid #ccc;
    flex-direction: column;
  
    .text{
      display: flex;
      width: 100%;
      height: 40px;
      align-items: center;
      border: 1px solid #ccc;
      padding-left: 10px;
    }

    .scrollable-content {
      flex: 1;
      overflow-y: auto;
      flex-direction: column;
      height: 200px;
      padding: 10px;
    }
`

export const Datas = styled.section`
  display: flex;
  width: 100%;
  height: 200px;
  border: 1px solid #ccc;

  .div-aside{
    display: flex;
    width:200px;
    align-items: center;
    gap: 8px;
    flex-direction: column;
    border: 1px solid #ccc;
    padding-bottom: 10px;

    .div-info{
      width: 170px;
      flex-direction: column;
      font-size: 14px;
    }

    aside {
      width: 170px;
      margin-top: 10px;
    div{
      width: 170px;
      height: 115px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #666666;
      color: #FFFFFF;
      img{
        height: 100%;
      }
    }
  }
}
`