import React from 'react'
import { ShimmerCard } from '../Shimmers';

const LoadingBox = () => {
    const unit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
        <div>
            {unit.map((item) => (
                <ShimmerCard key={item} $height="25px" />
            ))}
        </div>
    );
}

export default LoadingBox
