import { FileText } from "lucide-react";
import { useContext, useEffect, useRef, useState } from "react";
import { ListItensSelect } from "../../styles";
import { Column, ListItem, RowItem, Title } from "../Subforms/styles";
import { useListEi } from "../../hooks/bncc/useListEi";
import { useListEF } from "../../hooks/bncc/useListEF";

import { SelectSearchSkills } from "./SelectedFields/InputSearchSkills/InputSearchSkills";
import { IBNCCSkills } from "../../../../../../types/bncc";
import { ModalRef } from "../../../../../../Components/Modals";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";
import { Input } from "../../../../../../Components/Form/InputHook";
import { ModalListNew } from "../../../../../../Components/Modals/ModalListNew/ModalListNew";

interface ISearchProps {
  type: "ef" | "em" | "ei";
}
interface IListItens {
  type: "ef" | "em" | "ei";
  itensSelecteds: string[];
  addIten: (item: string) => void;
  removeIten: (item: string) => void;
  filterAges: string[]
}

/*
  Componente para a listagem de itens de um compo BNCC, 
  de acordo com o tipo de informação e qual componente curricular esta selecionado no momento
  */

function ListItens({ type, itensSelecteds, addIten, removeIten, filterAges }: IListItens) {
  const { data, isSuccess } = useListEi();

  console.log(data)

  let itens: IBNCCSkills[] = [];
  if (isSuccess && data) {
    // const codSkilss = type === "ei" ? 0 : type === "ef" ? 1 : 2;
    itens = data.skills;
  }

  function handleItenSelected(item: IBNCCSkills, selected: boolean) {
    const itemSelect = `${item.skills_id} ${item.skills}`;
    // const itemSelect = `${item.skills_id} `;
    if (!selected) {
      addIten(itemSelect);
    } else {
      removeIten(itemSelect);
    }
  }

  return (
    <ListItem>
      {isSuccess &&
        data &&
        itens.filter((item) => {
          if (filterAges.length) {
            return filterAges.some((age) => age === item.skills_id.substring(0, 4))
          }
          return true
        }).map((skill) => {
          const selected = itensSelecteds.includes(
            `${skill.skills_id} ${skill.skills}`
          );
          const className = selected ? "select" : "";
          return (
            <RowItem
              className={className}
              key={skill.skills_id}
              onClick={() => handleItenSelected(skill, selected)}
            >{`${skill.skills_id} ${skill.skills}`}</RowItem>
          );
        })}
    </ListItem>
  );
}

export function SearchSkillsEi({ type }: ISearchProps) {
  const modalRef = useRef<ModalRef>(null);

  const {
    resource,
    setBNCCEfFieldResource,
    setBNCCEiFieldResource,
    setBNCCEmFieldResource,
    removeFieldBNCCEfResource,
    removeFieldBNCCEiResource,
    removeFieldBNCCEmResource,
  } = useContext(ResourceContext);

  const { data, isSuccess } = useListEi();

  const [itensSelecteds, setItensSelecteds] = useState<string[]>(() => {
    return resource.bncc[type].skills;
  });

  const yearsSelected = resource.bncc[type].years
  const title = "Lista Objetos de aprendizagem";

  const functionSave =
    type === "ei"
      ? setBNCCEiFieldResource
      : type === "ef"
        ? setBNCCEfFieldResource
        : setBNCCEmFieldResource;

  const functionRemove =
    type === "ei"
      ? removeFieldBNCCEiResource
      : type === "ef"
        ? removeFieldBNCCEfResource
        : removeFieldBNCCEmResource;

  function openOptions() {
    modalRef.current?.open();
  }

  function saveItensSelecteds() {
    functionSave(itensSelecteds, "skills");
    modalRef.current?.close();
    setItensSelecteds([]);
  }

  function addItensSelecteds(item: string) {
    setItensSelecteds((prevValue) => [...prevValue, item]);
  }

  function removeItensSelecteds(item: string) {
    setItensSelecteds((prevValue) => prevValue.filter((it) => it !== item));
  }

  useEffect(() => {
    if (modalRef.current?.open) {
      setItensSelecteds(resource.bncc[type].skills);
    }
  }, [modalRef.current?.open, resource, type]);

  const [skillsFiltered, setSkillsFiltered] = useState<string[]>([])
  const [valueInput, setValueInput] = useState("")


  useEffect(() => {
    let filteredSkills = resource.bncc[type].skills.filter((item) => item.toLocaleLowerCase().includes(valueInput.toLocaleLowerCase()))
    setSkillsFiltered(filteredSkills)
  }, [valueInput, resource.bncc[type].skills])

  let itens: IBNCCSkills[] = [];
  if (isSuccess && data) {
    // const codSkilss = type === "ei" ? 0 : type === "ef" ? 1 : 2;
    itens = data.skills;
  }


 
  return (
    <Column className="item">
      <Title>HABILIDADES</Title>
      <Input
        flex="width-full"
        required={false}
        className="width-full"
        type="text"
        placeholder="Clique aqui e pesquise o objetivo de aprendizagem"
        label=""
        value={valueInput}
        onChange={(e: any) => setValueInput(e.target.value)}
      >
        <div className="icon-modal">
          <FileText onClick={openOptions} size={20} />
        </div>
      </Input>

      {/* <SelectSearchSkills
        options={itens}
        width="100%"
        placeholder="Clique aqui e pesquise o objetivo de aprendizagem"
        getOpt={(e)=> e.skills}
      >
         <div className="icon-modal">
          <FileText onClick={openOptions} size={20} />
        </div>
      </SelectSearchSkills> */}
      
      {resource.bncc[type].skills.length > 0 && (
        <ListItensSelect className="mt">
          {skillsFiltered.map((item) => (
            <li key={item}>
              {item}
              <button
                type="button"
                onClick={() => functionRemove(item, "skills")}
              >
                Remover
              </button>
            </li>
          ))}
        </ListItensSelect>
      )}
      <ModalListNew
        modalRef={modalRef}
        title={title}
        onSave={saveItensSelecteds}
      >
        <ListItens
          type={type}
          itensSelecteds={itensSelecteds}
          addIten={addItensSelecteds}
          removeIten={removeItensSelecteds}
          filterAges={yearsSelected}
        />
      </ModalListNew>
    </Column>
  );
}
