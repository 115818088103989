import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Divider } from "../../../../Components/Form/Divider"
import { Select } from "../../../../Components/Form/Select"
import { ContainerNew, Controls, DividerNew } from "../styles"
import UnidadeService from "../../../../Services/Unidade.service"
import { EtapaCreate } from "../../../../Services/EtapaAcademica.service"
import TurmaService from "../../../../Services/Turma.service"
import { SearchIcon } from "lucide-react"
import BNCCService from "../../../../Services/BNCC.service"
interface FormSearchProps {
    setStudentFiltereds: Dispatch<SetStateAction<any>>
    setAdvancedSearch: Dispatch<SetStateAction<boolean>>
    setInputSearch: Dispatch<SetStateAction<boolean>>
    studentFiltereds: any
}

export function FormSearch({ studentFiltereds, setStudentFiltereds, setAdvancedSearch, setInputSearch }: FormSearchProps) {
    const [units, setUnits] = useState<any>([]);
    const [academicStage, setAcademicStage] = useState<EtapaCreate[]>([])
    const [classes, setClasses] = useState<any>([])
    const [unitsSelected, setUnitsSelectd] = useState("");
    const [academicStageSelected, setAcademicStageSelected] = useState<EtapaCreate>()
    const [classeSelected, setClasseSelected] = useState("")
    const [classFiltered, setClassFiltered] = useState([])
    const [error1, setError1] = useState(false)
    const [error2, setError2] = useState(false)
    const [error3, setError3] = useState(false)

    //listar unidades e etapa
    useEffect(() => {
        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnits(response.data)
            }
        })

        BNCCService.listBNCCStages().then((response) => {
            if (response?.success) {
                setAcademicStage(response.data)
            }
        })

    }, [])

    //buscar turma
    useEffect(() => {

        TurmaService.list({ idState: unitsSelected }).then((response) => {
            if (response.success) {
                setClasses(response.data);
            } else {
                throw new Error(response?.message);
            }
        }).catch((e) => { });

    }, [unitsSelected]);

    // filtrar turmas
    useEffect(() => {
        let filteredClass = classes.filter((item: any) => item.stage_id === academicStageSelected)
        setClassFiltered(filteredClass)
    }, [academicStageSelected])

    //Procurar um aluno com base nas informações selecionadas 
    const handleAdvancedSearch = () => {

        const filteredResults = studentFiltereds.filter((student: any) =>
            student.identifier === unitsSelected && student.data?.classroom !== ""
            && student.data.classroom?.identifier === classeSelected)

        if (filteredResults.length === 0) {
            alert("Nenhum aluno encontrado com essa essas informações.");
        } else {
            setStudentFiltereds(filteredResults);
        }
        setAdvancedSearch(true)
        setInputSearch(false)

    }

    // verificar os select de etapa e turma
    function verifier() {
        if (unitsSelected === "") {
            setError1(true)
        } else if (academicStageSelected === undefined) {
            setError2(true)
        } else if (classeSelected === "") {
            setError3(true)
        } else {
            handleAdvancedSearch ();
        }
    }

    return (
        <>
            <ContainerNew>
                <Select
                    error={error1}
                    label="Unidade Educacional"
                    value={unitsSelected}
                    onChange={(value: any) => setUnitsSelectd(value)}
                >
                    <option value="">Selecione a unidade educacional</option>
                    {units.map((unit: any) => (
                        <option key={unit.identifier} value={unit.identifier}>{unit.name}</option>
                    ))}
                </Select>

                <DividerNew>
                    <Divider>
                        <Select
                            error={error2}
                            flex="fx50"
                            label="Etapa"
                            value={academicStageSelected}
                            onChange={(value: any) => setAcademicStageSelected(value)}
                        >
                            <option value="">Selecione uma Etapa</option>
                            {academicStage.map((stage) => {
                                return (
                                    <option key={stage.identifier} value={stage.identifier}>{stage.name}</option>
                                )
                            })}
                        </Select>
                        <Select
                            error={error3}
                            flex="fx50"
                            label="Nome da Turma"
                            value={classeSelected}
                            onChange={(value: any) => setClasseSelected(value)}
                        >
                            <option value="">Selecione a turma</option>
                            {classFiltered.map((classe: any) => {
                                return (
                                    <option key={classe.identifier} value={classe.identifier}>{classe.name}</option>
                                )
                            })}
                        </Select>
                    </Divider>

                    <Controls>
                        <button className="hvr-icon-pop"
                            onClick={(e) => verifier()}>
                            <div className="search-icon">
                                <SearchIcon className="hvr-icon" />
                            </div>
                        </button>
                    </Controls>

                </DividerNew>
            </ContainerNew>
        </>
    )
}