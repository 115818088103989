import styled from "styled-components";

export const ContainerReviewResource = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
flex-direction: column;
gap: 5px;
width: 100%;
height: 100%;

.divControlers{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
}

`