import { Box, Button, Container, Controls, Table } from "./styles";

import { Upload, PlusCircle, Save, Edit, Trash2 } from "lucide-react";
import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { ModalShort } from "../../Modals/ModalShort";
import { read, utils } from "xlsx";
import { ClassFields } from "../../../Pages/Registration/ClassRegistration";
import { RegistrationContext } from "../../../Contexts/Form/RegistrationClassContext";
import { useListAcademicYears } from "../../../hooks/list/useListAcademicYears";
import { getCurrentYear } from "../../../Utils/Functions";
import { Class } from "../../../Utils/Types";

interface Unidade {
  Ano_Letivo: string;
  Modalidade: string;
  Etapa: string;
  Fase: string;
  Ano_Faixa_Etaria: string;
  Nome_da_Turma: string;
}

interface ImportExcelClassProps {
  label: string;
  listClasses: Class[];
  setListClasses: Dispatch<SetStateAction<Class[]>>;
}

export function ImportExcelClass({ label, listClasses, setListClasses }: ImportExcelClassProps) {
  const { setListClass, listClass, classFields, setClassFields } =
    useContext(RegistrationContext);
  const [modalDelete, setModalDelete] = useState(false);
  const { data } = useListAcademicYears(true);





  const [pres, setPres] = useState<Class[]>([
    {
      name: "",
      academic_stage_id: "",
      academic_year: null,
      age_range_id: "",
      initials: "",
      modality: "",
      phase_id: "",

    },
  ]);

  async function handleImport(e: any) {
    if (!e.target.files) return;
    const f = await e.target.files[0].arrayBuffer();
    const wb = read(f);
    const data = utils.sheet_to_json<Class>(wb.Sheets[wb.SheetNames[0]]);
    setPres([...pres, ...data]);
    setListClasses([...pres, ...data])
    //setClassList([...pres, ...data]);
  }
  useEffect(() => {
    setPres([]);
  }, []);

  const [forEdit, setForEdit] = useState<any>("");

  function clear() {
    setListClass([]);
  }

  // //async function handleAddLine(e: Unidade) {
  // //    if (
  // //      e.Ano_Letivo === "" ||
  // //      e.Modalidade === "" ||
  // //      e.Etapa === "" ||
  // //      e.Fase === "" ||
  // //      e.Ano_Faixa_Etaria === "" ||
  // //      e.Nome_da_Turma === ""
  // //    ) {
  // //      alert("Preencha os campos obrigatórios");
  // //    } else {
  // //      if (forEdit !== "") {
  // //        var array = [...pres];
  // //        array[forEdit] = e;
  // //        setPres(array);
  // //        setForEdit("");
  // //        clear();
  // //      } else {
  // //        setPres([...pres, e]);
  // //        clear();
  // //        setForEdit("");
  // //      }
  // //    }
  // //
  //   }

  const [forDelete, setForDelete] = useState<ClassFields | null>(null);

  function addClass() {
    let messageAlert: string[] = [];
    const { name, phase, step, year, modality, ageRange } = classFields
    if (!name || name?.trim().length === 0) {
      messageAlert.push("Preencha o Nome da Turma");
    }
    if (!year) {
      messageAlert.push("Selecione o Ano Letivo");
    }
    if (!step) {
      messageAlert.push("Selecione uma etapa");
    }
    if (!phase) {
      messageAlert.push("Selecione uma fase");
    }
    if (!ageRange && modality.length > 0) { //(!ageRange && modality !== "EJA")
      messageAlert.push("Selecione uma faixa etária");
    }

    if (messageAlert.length > 0) {
      alert(messageAlert.join("\n"));
    } else {
      setListClass([...listClass, classFields]);
      //setListClasses((prevList: any) => [...prevList, classFields]);

      setClassFields({
        year:
          data?.find((year) => year.year === getCurrentYear().toString()) ??
          null,
        step: null,
        phase: null,
        ageRange: null,
        modality: [],
        name: "",
      });
    }
  }

  function removeClass(classRemove: ClassFields | null) {
    if (classRemove === null) return;
    setListClass(listClass.filter((item) => item !== classRemove));
  }

  return (
    <Box>
      <ModalShort
        type="delete"
        text="Tem certeza que deseja excluir este item?"
        isOpen={modalDelete}
        onRequestClose={() => setModalDelete(false)}
        onConfirm={() => {
          setModalDelete(false);
          removeClass(forDelete);
        }}
      />
      <Container>
        <Button className="hvr-icon-up">
          <label htmlFor="uploadid">
            {label} <Upload className="hvr-icon" size={16} />
          </label>
          <input
            type="file"
            name=""
            id="uploadid"
            onChange={(e) => handleImport(e)}
          />
        </Button>
        <Controls>
          <button onClick={addClass} className="hvr-icon-pop">
            <PlusCircle className="hvr-icon" size={16} /> Adicionar turma à
            lista
          </button>
        </Controls>
      </Container>
      <Table>
        <p>{label}</p>
        <table>
          <thead>
            <td>Ano Letivo</td>
            <td>Modalidade</td>
            <td>Etapa</td>
            <td>Fase</td>
            <td>Ano/Faixa Etária</td>
            <td>Nome da Turma</td>
            <td>{/*Ações*/}</td>
          </thead>
          <tbody>

            {listClasses.map((classe, index: number) => {
              return (
                <tr key={index}>
                  <td>{classe.academic_year}</td>
                  <td>
                    {classe.modality
                      ? classe.modality
                      : "Não informado"}
                  </td>
                  <td>
                    Disponível na visualização
                  </td>
                  <td>Disponível na visualização</td>
                  <td>Disponível na visualização</td>
                  <td>{classe.name}</td>
                  <td>
                    <Trash2 size={16} color="red" />
                  </td>
                </tr>
              )
            })}

            {listClass.map((classe, index: number) => {
              return (
                <tr key={index}>
                  <td>{classe.year?.year}</td>
                  <td>
                    {classe.modality
                      ? classe.modality
                      : "Não informado"}
                  </td>
                  <td>
                    {classe.step?.name}
                  </td>
                  <td>{classe.phase?.description}</td>
                  <td>Disponível na visualização</td>
                  <td>{classe.name}</td>
                  <td>
                    <Trash2 size={16} color="red" />
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </Table>
    </Box>
  );
}
