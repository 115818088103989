import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import { Class, TypeAddress } from '../../../../../Utils/Types';
import FuncionarioService from '../../../../../Services/Funcionario.service';
import { ModalShort } from '../../../../../Components/Modals/ModalShort';

export type TypeTeacherNew = {
    name: string;
    email: string;
    birth_date: string | undefined;
    cpf: string;
    phone: string;
    address?: TypeAddress;
    role_id?: string;
    slug?: string
};

interface ReviewDataTeacherProps {
    unit: any,
    dataTeacher?: TypeTeacherNew
    setStepForm: Dispatch<SetStateAction<number>>
    listClassesSelecteds: Class[]
}

const ReviewDataTeacher = ({ dataTeacher, setStepForm, unit, listClassesSelecteds }: ReviewDataTeacherProps) => {


    const navigate = useNavigate();
    const [modalSave, setModalSave] = useState(false)
    function save() {
        FuncionarioService.postTeacher(dataTeacher, unit.identifier).then((response) => {
            if (response?.success) {
                setModalSave(true)
            }
        })
    }



    return (
        <>
            <ModalShort
                type="success"
                text={"Professor cadastrado!"}
                isOpen={modalSave}
                onRequestClose={() => navigate("/unidade/usuario/list")}
                onConfirm={() => {
                    navigate("/unidade/usuario/list");
                }}
            />
            <br />
            <BoxResume label={`Verifique se os dados estão corretos.`}>
                <tr>
                    <td>
                        <span>Nome:</span>
                        {dataTeacher?.name}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>CPF:</span>
                        {dataTeacher?.cpf}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Data de Nascimento:</span>
                        {dataTeacher?.birth_date}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Telefone:</span>
                        {dataTeacher?.phone}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Email:</span>
                        {dataTeacher?.email}
                    </td>
                </tr>
            </BoxResume>
            <br />
            <BoxResume>

                <tr>
                    <td>
                        <b>Turmas:</b>
                    </td>
                </tr>
                {listClassesSelecteds.map((classe) => {
                    return (
                        <tr>
                            <td>
                                <span>Nome:</span>
                                {classe.name}
                            </td>
                        </tr>
                    )
                })}

            </BoxResume>
            <br />
            <Controllers
                enableNext={true}
                enablePrev={true}
                save={true}
                functionCancel={() => navigate(-1)}
                functionPrev={() => setStepForm(1)}
                functionSave={() => save()}
            />
        </>
    )
}

export default ReviewDataTeacher
