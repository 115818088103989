import styled from 'styled-components'

export const Text = styled.p`
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  padding-left: 15%;
  padding-bottom: 10px;
`

export const Container = styled.nav`
  padding: 0px 5px;
`

export const Toggle = styled.div`
  &.active{
      div:first-child{
        color: #fff;
        background-color: #00cad1;
      }
  }
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  &:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
  }
  > div:first-child {
    font-size: 16px;
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    cursor: pointer;
    padding: 7px;
    transition: 0.3s;
    &:hover {
      color: #fff;
      background-color: #00cad1;
    }
  }

  &.selected > div:first-child {
    background-color: #00cad1;
    color: #fff;
  }

  > div:nth-child(2) {
    height: 0px;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    transition: 0.3s;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    padding: 0px 7px;
    a {
      width: 100%;
      font-size: 16px;
      text-transform: uppercase;
      color: rgba(255, 255, 255, 0.9);
      text-decoration: none;
      &:hover {
        transition: 0.3s;
        color: #00cad1;
      }
      &.active{
        color: #fff;
        background-color: #28a5a9;
      }
    }
    &.active{
      height: 170px;
      padding: 7px 0px 7px 0px;
        /* display: flex; */
        &.tr{
          height: 100px;
          > * {
            padding-left: 7px;
          }
        }
    }
  }
`