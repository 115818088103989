import axios from 'axios'
import { PlusCircle, Save, Upload } from 'lucide-react'
import { useEffect, useState } from 'react'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { Divider } from '../../../../../Components/Form/Divider'
import { Input } from '../../../../../Components/Form/Input'
import { Button, ContainerButtons, Controls, Form } from '../../../../Registration/UnitRegistration/styles'
import { api } from '../../../../../Services/api'
import { useNavigate } from 'react-router-dom'


export function Form1(props: any) {
  const navigate = useNavigate()
  const [dataSend, setDataSend] = useState({
    Cep: '',
    Cidade: '',
    Email: '',
    Estado: '',
    Nome: '',
    Telefone: '',
  })

  useEffect(() => {
    setDataSend({
      Cep: props.cepValue,
      Cidade: props.cidadeValue,
      Email: props.emailValue,
      Estado: props.ufValue,
      Nome: props.nameValue,
      Telefone: props.telValue,
    })
  }, [props.cepValue, props.cidadeValue, props.emailValue, props.ufValue, props.nameValue, props.telValue])

  async function handleCep(n: any) {
    props.setCepValue(n)
    const number = n.replace(/\D/g, '')
    if (number.length === 8) {
      axios.get(`https://viacep.com.br/ws/${number}/json/`).then((response) => {
        props.setBairroValue(response.data.bairro)
        props.setComplementoValue(response.data.complemento)
        props.setCidadeValue(response.data.localidade)
        props.setRuaValue(response.data.logradouro)
        props.setUfValue(response.data.uf)
      })
    }
  }

  const checkNextStep = () => {
    if (props.nameValue === '' || props.telValue === '' || props.emailValue === '' || props.cepValue === '' || props.ruaValue === '' || props.bairroValue === '' || props.cidadeValue === '' || props.ufValue === '' || props.numberValue === '') {
      alert('Preencha todos os campos obrigatórios')
      return
    }
    props.next(2)

  }


  return (
    <Form>
      <Input
        flex="fx100"
        label="Nome da Unidade Educacional"
        inputId="nomeUnidadeEducacionalId"
        value={props.nameValue}
        onChange={(e: any) => props.setNameValue(e)}
        required
      />
      <Divider>
        <Input
          flex="fx30"
          type="tel"
          label="Telefone"
          inputId="telefoneid"
          value={props.telValue}
          onChange={(e: any) => props.setTelValue(e)}
          required
        />
        <Input
          flex="fx80"
          label="E-mail"
          inputId="emailid"
          value={props.emailValue}
          onChange={(e: any) => props.setEmailValue(e)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx15"
          type="cep"
          label="CEP"
          inputId="cepid"
          value={props.cepValue}
          onChange={(v: any) => handleCep(v)}
          required
        />
        <Input
          flex="fx80"
          label="Rua/Avenida"
          inputId="ruaavenidaid"
          value={props.ruaValue}
          onChange={(v: any) => props.setRuaValue(v)}
          required
        />
        <Input
          flex="fx10"
          label="Número"
          inputId="numeroid"
          value={props.numberValue}
          onChange={(v: any) => props.setNumberValue(v)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Complemento"
          inputId="complementoid"
          value={props.complementoValue}
          onChange={(v: any) => props.setComplementoValue(v)}
        />
        <Input
          flex="fx50"
          label="Bairro"
          inputId="bairroid"
          value={props.bairroValue}
          onChange={(v: any) => props.setBairroValue(v)}
          required
        />
      </Divider>
      <Divider>
        <Input
          flex="fx50"
          label="Cidade"
          inputId="cidadeid"
          value={props.cidadeValue}
          onChange={(v: any) => props.setCidadeValue(v)}
          required
        />
        <Input
          flex="fx50"
          label="Estado"
          inputId="estadoid"
          value={props.ufValue}
          onChange={(v: any) => props.setUfValue(v)}
          required
        />
      </Divider>
      <Controllers
        functionNext={() => checkNextStep()}
        functionCancel={() => navigate('/unidade')}
        enableNext={true}
        enablePrev={false}
      />
    </Form>
  )
}
