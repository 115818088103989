import { useEffect, useState } from "react";
import { CallRegister } from "../../../../Components/CallRegister";
import { ProfileDatas } from "../../../../Components/ProfileDatas";
import { Sitemap } from "./styles";
import { Container, Datas, Header } from "./styles";
import { api } from "../../../../Services/api";
import { useNavigate } from "react-router-dom";
import { EnumEmployees } from "../../../../Utils/Types";
import StudentService from "../../../../Services/Student.service";
import ProfileStudent from "./ProfileStudent/ProfileStudent";

function formatPhone(phone: string) {
  const cleaned = ("" + phone).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}

export function UserProfile(props: any) {
  const [user, setUser] = useState<any>();
  const [students, setStudents] = useState<any>([]);
  const id = JSON.parse(sessionStorage.getItem("locationData")!);
  const navigate = useNavigate();
  const [date, setDate] = useState("")
  const onEditEmployee = () => navigate("/unidade/usuario/edit", { state: props.selected });
  const [studentsData, setStudentsData] = useState<any>(props.selected);

  console.log(studentsData)



  useEffect(() => {
    if (props.type === 1) {
      try {
        api
          .get(`/admin/technicians/${props.selected.identifier}`, {
            headers: {
              Authorization: `bearer ${localStorage.getItem("token")}`,
            },
          })
          .then((response) => {
            setUser(response.data.data);
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        alert(error);
      }
    } else if (props.type === 6) {
      try {
        api
          .get(
            `/admin/education-institutions/${props.selected.identifier}/students`,
            {
              headers: {
                Authorization: `bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            setUser(response.data.data);
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        alert(error);
      }
    }


    if (props.type === 9) {
      try {
        api
          .get(
            `/admin/education-institutions/${id.identifier}/guardians/${props.selected.identifier}`,
            {
              headers: {
                Authorization: `bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((response) => {
            console.log(response.data.data);
            setUser(response.data.data);
            setStudents(response.data.data.students)
          })
          .catch((error) => {
            alert(error);
          });
      } catch (error) {
        alert(error);
      }
    }
    // if (props.idUser === EnumEmployees.STUDENT) {
    //   const yearStu = props.selected.birthDate.substring(0, 4)
    //   const mounthStu =
    //     props.selected.birthDate.substring(5, 7)
    //   const dayStu =
    //     props.selected.birthDate.substring(8, 10)
    //   const dataStu = dayStu + "/" + mounthStu + "/" + yearStu;
    //   return setDate(dataStu);

    // } else if (props.idUser === EnumEmployees.GUARDIAN) {
    //   const yearRes = props.selected.user.birth_date.substring(0, 4)
    //   const mounthRes =
    //     props.selected.user.birth_date.substring(8, 10)
    //   const dayRes =
    //     props.selected.user.birth_date.substring(5, 7)
    //   const dataRes = mounthRes + "/" + dayRes + "/" + yearRes;
    //   return setDate(dataRes);
    // }




  }, [props.selected, props.type]);

  useEffect(() => {
    if (props.idUser === "7") {
      StudentService.show({ educationInstitution: props.unitId, studentIdentifier: props.selected.identifier }).then((response) => {
        if (response.success) {
          console.log(response.data)
          setStudentsData(response.data)
        }
      })
    } else {
      return
    }

    console.log(props.type)

  }, [])


  return (
    <>
      {props.type === 1 && (
        <Container>
          <ProfileDatas
            page="Técnico"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              <p>Nível: {user?.user.roles[0].name}</p>
              <span></span>
              <p>Nome: {props.selected.user.name}</p>
              <p>Empresa: {props.selected.company}</p>
              <p>Cargo: {props.selected.role}</p>
              <p>E-mail: {props.selected.user.email}</p>
              <p>
                Telefone:{" "}
                {user?.user.phone
                  ? formatPhone(user?.user.phone)
                  : "Não informado"}
              </p>
              <span></span>
              <p>
                Permissões:{" "}
                {user?.user.roles.map((role: any) => role.name).join(", ")}
              </p>
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 2 && (
        <Container>
          <ProfileDatas
            page="Perfil: Gestão de Contrato"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {/* {JSON.parse(localStorage.getItem("client")!).socialName} */}
                    {id.socialName}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone:{" "}
                    {props.selected.user.phone
                      ? formatPhone(props.selected.user.phone)
                      : "Não informado"}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 3 && (
        <Container>
          <ProfileDatas
            page="Perfil: Gestão de Unidade"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <p>
                    Unidade Educacional:{" "}
                    {props.selected.educationInstitution.name}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>
                    Telefone:{" "}
                    {props.selected.user.phone
                      ? formatPhone(props.selected.user.phone)
                      : "Não informado"}
                  </p>

                  <p>CPF: {props.selected.user.cpf ?? "Não informado"}</p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 4 && (
        <Container>
          <ProfileDatas
            page="Direção"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <p>
                    Unidade Educacional:{" "}
                    {props.selected.educationInstitution.name}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone: {props.selected.user.phone ?? "Não informado"}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 5 && (
        <Container>
          <ProfileDatas
            page="Coordenação"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <p>
                    Unidade Educacional:{" "}
                    {props.selected.educationInstitution.name}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone: {props.selected.user.phone ?? "Não informado"}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 6 && (
        <Container>
          <ProfileDatas
            page="Admnistrativo"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <p>
                    Unidade Educacional:{" "}
                    {props.selected.educationInstitution.name}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone: {props.selected.user.phone ?? "Não informado"}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 7 && (
        <Container>
          <ProfileDatas
            page="Professor"
            onBack={() => props.back()}
            onEdit={onEditEmployee}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <p>
                    Unidade Educacional:{" "}
                    {props.selected.educationInstitution.name}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {props.selected.user.birth_date
                      ? props.selected.user.birth_date
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/")
                      : "Não informado"}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone: {props.selected.user.phone ?? "Não informado"}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 8 && (
        <ProfileStudent
            back={props.back}       
            edit={props.edit} 
            idStudent={studentsData.identifier}
            unit={id}
        />
      )}
      {props.type === 9 && (
        <Container>
          <ProfileDatas
            page="Responsável"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {props.selected && (
                <>
                  <p>
                    Cliente:{" "}
                    {JSON.parse(localStorage.getItem("client")!).socialName}
                  </p>
                  <span></span>
                  <p>Nome: {props.selected.user.name}</p>
                  <p>CPF: {props.selected.user.cpf ?? "Não informado"}</p>
                  <p>
                    Data de Nascimento:{" "}
                    {date}
                  </p>
                  <p>E-mail: {props.selected.user.email ?? "Não informado"}</p>
                  <p>
                    Telefone: {props.selected.user.phone ?? "Não informado"}
                  </p>
                  <p>
                    Responsavel por:{" "}
                    {students.map((student: any) => student.name).join(",")}
                  </p>
                </>
              )}
            </main>
          </Datas>
        </Container>
      )}
    </>
  );
}
