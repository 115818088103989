import { useContext, useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { ImportExcelClass } from "../../../../Components/Form/ImportExcelClass";
import { Input } from "../../../../Components/Form/Input";
import { Select } from "../../../../Components/Form/Select";
import { RegistrationContext } from "../../../../Contexts/Form/RegistrationClassContext";
import AnoLetivoService from "../../../../Services/AnoLetivo.service";
import EtapaAcademicaService from "../../../../Services/EtapaAcademica.service";
import FaixaEtaria from "../../../../Services/FaixaEtaria.service";
import FasesService from "../../../../Services/Fases.service";
import {
  getCurrentYear,
  isCreatedAcademicNextYear,
} from "../../../../Utils/Functions";
import {
  Class,
  EducationStage,
  TypeAgeRange,
  TypePhase,
} from "../../../../Utils/Types";
import { useListAcademicYears } from "../../../../hooks/list/useListAcademicYears";
import { Queries } from "../../../../types/queries";
import { Form, Radio } from "../styles";
import {
  checkAgeRangeState,
  checkEducationState,
  checkPhasesState,
} from "./scriptsChecks";
import { useListAcademicStage } from "../../../../hooks/list/useListAcademicStage";
import { useListPhases } from "../../../../hooks/list/useListPhases";
import { useListAcademicRange } from "../../../../hooks/list/useListAcademicRange";
import TurmaService from "../../../../Services/Turma.service";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { useListStage } from "../../../../hooks/ListClass/useListStage";
import { useListBNCCPhases } from "../../../../hooks/ListClass/useListPhases";
import { useListBNCCYears } from "../../../../hooks/ListClass/useListYears";

interface Form1Props {
  next: () => void;
  nextReviewImport: () => void;
}

export function Form1({ next, nextReviewImport }: Form1Props) {
  const { edit, classFields, handleFieldClass, listClass, listClasses, setListClasses } =
    useContext(RegistrationContext);
  const { name, year, step, ageRange, modality, phase } = classFields;
  const locationData = JSON.parse(
    sessionStorage.getItem("locationData") || "{}"
  );
  const [educacionalStage, setEducacionalStage] = useState("");
  const [educacionalPhase, setEducacionalPhase] = useState("");
  const navigate = useNavigate();
  const { data: listYears, isLoading: loadingYears } =
    useListAcademicYears(true);
  const { data: listAcademicStages, isLoading: loadingAcademicStages } =
    useListStage();
  // const { data: listPhases, isLoading: loadingPhases } = useListPhases(
  //   locationData.identifier
  const { data: listPhases, isLoading: loadingPhases } = useListBNCCPhases(

  );
  const { data: listAcademicYears, isLoading: loadingAcademicYears } =
    useListBNCCYears();

  const queryClient = useQueryClient();




  // const [educacionalAgeRanges, setEducacionalAgeRanges] = useState<any[]>([]);
  const labelDefaultPhase = "Escolha uma fase";
  const labelDefaultAgeRange = "Escolha uma faixa etária";
  function onBack() {
    navigate(-1);
  }

  const [isOpen, setIsOpen] = useState(false)



  function check() {
    let messageAlert: string[] = [];

    if (!name || name?.trim().length === 0) {
      messageAlert.push("Preencha o Nome da Turma");
    }
    if (!year) {
      messageAlert.push("Selecione o Ano Letivo");
    } if (!step) {
      messageAlert.push("Selecione uma etapa");
    }
    if (!phase) {
      messageAlert.push("Selecione uma fase");
    }
    if (!ageRange && modality && !modality.includes("EJA")) {
      messageAlert.push("Selecione uma faixa etária");
    } if (messageAlert.length > 0) {
    }
    if (messageAlert.length > 0) {
      alert(messageAlert.join("\n"));
    } else {
      next();
    }
  }

  function checkNext() {

    if (listClasses.length > 0) {
      nextReviewImport();
    } else {
      check();
    }

  }


  useEffect(() => {
    const getData = () => {
      // EtapaAcademicaService.get().then((res) => {
      //   if (res?.success) {
      //     setEducacionalStages([]);
      //     checkEducationState(res.data, addEducationStage);
      //   }
      // });
      // FaixaEtaria.get().then((res) => {
      //   if (res && !("success" in res)) {
      //     setEducacionalAgeRanges([]);
      //     // checkAgeRangeState(res.data, addAgeRange);
      //   }
      // });
      // FasesService.get(locationData.identifier).then((res) => {
      //   if (res && !("success" in res)) {
      //     setEducacionalPhases([]);
      //     checkPhasesState(res.data, addPhase, locationData.identifier);
      //   }
      // });
    };

    getData();
  }, []);


  function handleEtapa(id: string) {
    const stageSelected = listAcademicStages?.find(
      (v: any) => v.identifier === id
    );

    handleFieldClass("step", stageSelected);
    setEducacionalStage(id)
    // handleFieldClass("phase", null);
    // handleFieldClass("ageRange", null);
  }

  function handleFase(id: string) {
    const stageSelected = listPhases?.find((v: any) => v.identifier === id);
    handleFieldClass("phase", stageSelected);

    setEducacionalPhase(id)
    // handleFieldClass("ageRange", null);
  }
  // function filterFases(fase: TypePhase) {
  //   if (!step) return false;
  //   if (step.description === "kindergarten") {
  //     return fase.description === "Creche" || fase.description === "Pré-escola";
  //   } else if (step.description === "elementary_school") {
  //     return (
  //       fase.description === "Ensino Fundamental I" ||
  //       fase.description === "Ensino Fundamental II"
  //     );
  //   } else {
  //     return fase.description === "Ensino Médio";
  //   }
  // }

  // function filterFaixa(faixa: TypeAgeRange) {
  //   if (!phase) return false;
  //   if (phase.description === "Creche") {
  //     return (
  //       faixa.description === "Bebê" ||
  //       faixa.description === "Crianças bem pequenas"
  //     );
  //   }
  //   if (phase.description === "Pré-escola") {
  //     return faixa.description === "Crianças pequenas";
  //   }
  //   if (phase.description === "Ensino Fundamental I") {
  //     return (
  //       faixa.description === "1° ano" ||
  //       faixa.description === "2° ano" ||
  //       faixa.description === "3° ano" ||
  //       faixa.description === "4° ano" ||
  //       faixa.description === "5° ano"
  //     );
  //   }
  //   if (phase.description === "Ensino Fundamental II") {
  //     return (
  //       faixa.description === "6° ano" ||
  //       faixa.description === "7° ano" ||
  //       faixa.description === "8° ano" ||
  //       faixa.description === "9° ano"
  //     );
  //   }
  //   if (phase.description === "Ensino Médio") {
  //     return (
  //       faixa.description === "1° ano" ||
  //       faixa.description === "2° ano" ||
  //       faixa.description === "3° ano"
  //     );
  //   }
  // }

  return (
    <Form>

      <ModalShort
        type="success"
        text="Turmas Importadas e Cadastradas!"
        onRequestClose={() => {
          navigate("/turma/list")
        }}
        isOpen={isOpen}
      />
      <Divider>
        <Select
          flex="fx80"
          label="Nome da unidade educacional"
          inputId="selectunidadeid"
          disabled={true}
        >
          <option value={locationData.identifier}>{locationData.name}</option>
        </Select>
        <Select
          value={year ? year.year : "2024"}
          flex="fx20"
          label="Ano Letivo*"
          inputId="anoletivoid"
          onChange={(e: any) => {
            listYears?.forEach((v: any) => {
              if (v.year === e.toString()) {
                handleFieldClass("year", v);
              }
            });
          }}
        >
          {loadingYears ? (
            <option value="">Carregando...</option>
          ) : (
            <>
              <option value="">Escolha um ano</option>
              {listYears?.map((v: any) => (
                <option key={v.year} value={v.year}>
                  {v.year}
                </option>
              ))}
            </>
          )}
        </Select>
      </Divider>
      <Radio>
        <p>Modalidades</p>
        <div>
          <label htmlFor="eadid">
            <input
              type="checkbox"
              name="modalidades"
              id="eadid"
              onChange={(e) => {
                if (e.target.checked) {
                  handleFieldClass("modality", [
                    ...(modality || []),
                    e.target.value,
                  ]);
                } else {
                  handleFieldClass(
                    "modality",
                    modality.filter((m) => m !== e.target.value)
                  );
                }
              }}
              value="EAD"
              checked={modality && modality.includes("EAD")}
            />
            Educação a Distância - EAD
          </label>
          <label htmlFor="specialid">
            <input
              type="checkbox"
              name="modalidades"
              id="specialid"
              onChange={(e) => {
                if (e.target.checked) {
                  handleFieldClass("modality", [
                    ...(modality || []),
                    e.target.value,
                  ]);
                } else {
                  handleFieldClass(
                    "modality",
                    modality.filter((m) => m !== e.target.value)
                  );
                }
              }}
              value="EE"
              checked={modality && modality.includes("EE")}
            />
            Educação Especial
          </label>
          <label htmlFor="ejaid">
            <input
              type="checkbox"
              name="modalidades"
              id="ejaid"
              onChange={(e) => {
                if (e.target.checked) {
                  handleFieldClass("modality", [
                    ...(modality || []),
                    e.target.value,
                  ]);
                } else {
                  handleFieldClass(
                    "modality",
                    modality.filter((m) => m !== e.target.value)
                  );
                }
              }}
              value="EJA"
              checked={modality && modality.includes("EJA")}
            />
            Educação de Jovens e Adultos - EJA
          </label>
        </div>
      </Radio>

      {/* <Radio>
        <p>Modalidade</p>
        <div>
          <label htmlFor="eadid">
            <input
              type="checkbox"
              name="modalidade"
              id="eadid"
              onClick={(e: any) => {
                if (modality !== "EAD") {
                  handleFieldClass("modality", e.target.value);
                } else {
                  handleFieldClass("modality", null);
                }
              }}
              value="EAD"
              checked={modality === "EAD"}
            />
            Educação a Distância - EAD
          </label>
          <label htmlFor="specialid">
            <input
              type="checkbox"
              name="modalidade"
              id="specialid"
              onClick={(e: any) => {
                if (modality !== "EE") {
                  handleFieldClass("modality", e.target.value);
                } else {
                  handleFieldClass("modality", null);
                }
              }}
              value="EE"
              checked={modality === "EE"}
            />
            Educação Especial
          </label>
          <label htmlFor="ejaid">
            <input
              type="checkbox"
              name="modalidade"
              id="ejaid"
              onClick={(e: any) => {
                if (modality !== "EJA") {
                  handleFieldClass("modality", e.target.value);
                  handleFieldClass("ageRange", null);

                } else {
y
                  handleFieldClass("modality", null);
                }
              }}
              checked={modality === "EJA"}
              value="EJA"y
            />
            Educação de Jovens e Adultos - EJA
          </label>
        </div>
      </Radio> */}
      <Divider>
        <Select
          value={step ? step.identifier : ""}
          flex="item-select"
          label="Etapa*"
          inputId="etapaid"
          onChange={handleEtapa}
        >
          {loadingAcademicStages ? (
            <option value="">Carregando...</option>
          ) : (
            <>
              <option value="">Escolha uma etapa</option>

              {listAcademicStages?.map((v: any) => (
                <option key={v.identifier} value={v.identifier}>
                  {v.name}
                </option>
              ))}
            </>
          )}
        </Select>
        <Select
          value={phase ? phase.identifier : ""}
          flex="item-select"
          label="Fase*"
          inputId="faseid"
          onChange={handleFase}
        >
          {loadingPhases ? (
            <option value="">Carregando...</option>
          ) : (
            <>
              <option value="">{labelDefaultPhase}</option>
              {listPhases?.map((v: any) => (
                <option key={v.identifier} value={v.identifier}>
                  {v.name}
                </option>
              ))}
            </>
          )}
        </Select>
        <Select
          value={ageRange ? ageRange.identifier : ""}
          flex="item-select"
          disabled={modality && modality.includes("EJA")} //disabled={modality === "EJA"}
          label={`Ano/Faixa Etária ${modality && modality.includes("EJA") ? "" : "*"}`} //label={`Ano/Faixa Etária*`} 
          inputId="faixaetariaid"
          onChange={(e: any) => {
            listAcademicYears?.forEach((v: any) => {
              if (v.identifier === e) {
                handleFieldClass("ageRange", v);
              }
            });
          }}
        >
          {loadingAcademicYears ? (
            <option value="">Carregando...</option>
          ) : (
            <>
              <option value="">{labelDefaultAgeRange}</option>
              {listAcademicYears?.map((v: any) => (
                <option key={v.identifier} value={v.identifier}>
                  {v.year} {v.identifier.substring(0, 2) === "EM" && ("(Médio)")}
                </option>
              ))}
            </>
          )}
        </Select>
      </Divider>
      <Input
        type="text"
        required
        flex="fx100"
        label="Nome da Turma"
        inputId="nomedaturmaid"
        onChange={(e: any) => {
          handleFieldClass("name", e);
        }}
        value={name}
      />
      {!edit
        ? <ImportExcelClass label="Importar Turmas" listClasses={listClasses} setListClasses={setListClasses} />
        : null}

      <Controllers
        functionNext={() => checkNext()}
        functionPrev={onBack}
        functionCancel={onBack}
        labelProx="Avançar"
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  );
}
