import { Subcategory } from "../../Pages/Registration/ResourcesRegistration/Form1/SubcategoryComponent/LevelOne";
import { ICategoriesCollection } from "../../Services/Collection.service";

import { ActionTypes } from "./actions";

export interface ILegalResource {
  license: string;
  intellectual_rights: string[];
  parental_rating: string | null;
  content_descritors: string[];
}

export interface IAuthorshipResource {
  content_creator: string[];
  proofreader: string[];
  graphic_designer: string[];
  screenwriter: string[];
  illustrator: string[];
  programmer: string[];
}

export interface IResourceCollection {
  collection_id: string;
  category_id: string;
  subcategory_id: string;
  subcategory?: Subcategory
  path: string;
}

export interface IBNCCResource {
  basic_competences: number[];
  ei: {
    years: string[];
    learning_rights: string[];
    fields_experience: string[];
    skills: string[];
  };
  ef: {
    years: string[];
    knowledge_areas: string[];
    curricular_components: string[];
    fields_action: string[];
    thematic_units: string[];
    knowledge_objects: string[];
    skills: string[];
  };
  em: {
    years: string[];
    knowledge_areas: string[];
    specific_competences_port: string[];
    fields_action: string[];
    skills: string[];
  };
}

export interface ITagsResource {
  categorys: string[];
  itens: string[];
  suggested: {
    tag_category_id: string;
    name: string;
  } | null;
}

export interface IAnswerResource {
  image?: File;
  description: string;
}

export interface IGuidelineResource {
  description: string;
}
export interface IPedagogicalResource {
  tips: string;
  similar_reference: string;
  content_reference: string;
  answer_key: IAnswerResource[];
  guideline: IGuidelineResource[];
}

export interface IResourceState {
  name: string;
  description?: string;
  url: string

  format: string;
  language: string;
  thumbnails: File[];

  active: boolean;
  published: boolean;
  test: boolean;
  authorshipYear?: number;
  authorship: IAuthorshipResource;
  storyboard?: string;
  correction_history?: string

  origin_resource?: string;
  equivalent_resources?: string[];

  plataforms: string[];
  target_audience: any[];

  collectionDefault?: string;
  collections: IResourceCollection[];
  evaluated_content: ILegalResource;

  bncc: IBNCCResource;

  tags: ITagsResource;

  pedagogical_guideline: IPedagogicalResource;
}

export type TFieldsBNCCEF = keyof IBNCCResource["ef"];
export type TFieldsBNCCEI = keyof IBNCCResource["ei"];
export type TFieldsBNCCEM = keyof IBNCCResource["em"];

export const initialStateResource: IResourceState = {
  name: "",
  format: "",
  language: "",
  url: "",
  active: false,
  published: false,
  test: false,
  storyboard: "",
  thumbnails: [],
  authorship: {
    content_creator: [],
    proofreader: [],
    graphic_designer: [],
    screenwriter: [],
    illustrator: [],
    programmer: [],
  },
  evaluated_content: {
    license: "",
    intellectual_rights: [],
    parental_rating: null,
    content_descritors: [],
  },
  collections: [],
  target_audience: [],
  plataforms: [],

  bncc: {
    basic_competences: [],
    ei: {
      years: [],
      learning_rights: [],
      fields_experience: [],
      skills: [],
    },
    ef: {
      years: [],
      knowledge_areas: [],
      curricular_components: [],
      fields_action: [],
      thematic_units: [],
      knowledge_objects: [],
      skills: [],
    },
    em: {
      years: [],
      knowledge_areas: [],
      specific_competences_port: [],
      fields_action: [],
      skills: [],
    },
  },
  pedagogical_guideline: {
    answer_key: [{ description: "" }],
    tips: "",
    content_reference: "",
    similar_reference: "",
    guideline: [{ description: "" }],
  },
  tags: {
    categorys: [],
    itens: [],
    suggested: null,
  },
};

export function resourceReducer(state: IResourceState, action: any) {
  console.log(action)
  switch (action.type) {

   
    
    case ActionTypes.HANDLE_RESOURCE:
      return { ...state, ...action.payload };
    case ActionTypes.HANDLE_FIELD_STRING:
      return { ...state, [action.payload.field]: action.payload.value };

    case ActionTypes.ADD_AUTHORSHIP:
      return {
        ...state,
        authorship: {
          ...state.authorship,
          [action.payload.key]: [
            ...state.authorship[
              action.payload.key as keyof IAuthorshipResource
            ],
            action.payload.author,
          ],
        },
      };
    case ActionTypes.REMOVE_AUTHORSHIP:
      return {
        ...state,
        authorship: {
          ...state.authorship,
          [action.payload.key]: [
            ...state.authorship[
              action.payload.key as keyof IAuthorshipResource
            ].filter((author) => author !== action.payload.author),
          ],
        },
      };
    case ActionTypes.ADD_CONTENT_DESCRITORS:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          content_descritors: [
            ...state.evaluated_content.content_descritors,
            action.payload.descritor,
          ],
        },
      };
    case ActionTypes.REMOVE_CONTENT_DESCRITORS:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          content_descritors: [
            ...state.evaluated_content.content_descritors.filter(
              (descritor) => descritor !== action.payload.descritor
            ),
          ],
        },
      };

    case ActionTypes.ADD_CATEGORY_COLLECTION:
      const collectionSelect = state.collections.find(
        (categoryCollection) =>
          categoryCollection.collection_id ===
          action.payload.category.collection_id
      );

      const collectionsList = state.collections.map((categoryCollection) =>
        categoryCollection.collection_id ===
        action.payload.category.collection_id
          ? action.payload.category
          : categoryCollection
      );

      if (collectionSelect) {
        const collection_id =
          collectionsList.length <= 1
            ? action.payload.category.collection_id
            : state.collectionDefault;
        return {
          ...state,
          collectionDefault: collection_id,
          collections: collectionsList,
        };
      }

      const collection_id =
        [...state.collections, action.payload.category].length <= 1
          ? action.payload.category.collection_id
          : state.collectionDefault;
      return {
        ...state,
        collectionDefault: collection_id,
        collections: [...state.collections, action.payload.category],
      };
    case ActionTypes.REMOVE_CATEGORY_COLLECTION:
      const collectionDefault =
        state.collectionDefault === action.payload.category.collection_id
          ? null
          : state.collectionDefault;
      return {
        ...state,
        collectionDefault: collectionDefault,
        collections: state.collections.filter(
          (categoryCollection) =>
            categoryCollection.collection_id !==
            action.payload.category.collection_id
        ),
      };
    case ActionTypes.HANDLE_THUMBNAILS:
      return { ...state, thumbnails: action.payload.thumbnails };
    case ActionTypes.HANDLE_LICENSE:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          license: action.payload.license,
        },
      };
    case ActionTypes.ADD_INTELLECTUAL_RIGHTS:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          intellectual_rights: [
            ...state.evaluated_content.intellectual_rights,
            action.payload.name,
          ],
        },
      };
    case ActionTypes.REMOVE_INTELLECTUAL_RIGHTS:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          intellectual_rights: [
            ...state.evaluated_content.intellectual_rights.filter(
              (right) => right !== action.payload.name
            ),
          ],
        },
      };
    case ActionTypes.HANDLE_PARENTAL_RATING:
      return {
        ...state,
        evaluated_content: {
          ...state.evaluated_content,
          parental_rating: action.payload.value,
        },
      };
    case ActionTypes.ADD_BNCC_EF_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ef: {
            ...state.bncc.ef,
            [action.payload.key]: [
              ...state.bncc.ef[
                action.payload.key as keyof typeof state.bncc.ef
              ],
              action.payload.value,
            ],
          },
        },
      };
    case ActionTypes.REMOVE_BNCC_EF_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ef: {
            ...state.bncc.ef,
            [action.payload.key]: [
              ...state.bncc.ef[
                action.payload.key as keyof typeof state.bncc.ef
              ].filter((value) => value !== action.payload.value),
            ],
          },
        },
      };
    case ActionTypes.ADD_BNCC_EI_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ei: {
            ...state.bncc.ei,
            [action.payload.key]: [
              ...state.bncc.ei[
                action.payload.key as keyof typeof state.bncc.ei
              ],
              action.payload.value,
            ],
          },
        },
      };
    case ActionTypes.REMOVE_BNCC_EI_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ei: {
            ...state.bncc.ei,
            [action.payload.key]: [
              ...state.bncc.ei[
                action.payload.key as keyof typeof state.bncc.ei
              ].filter((value) => value !== action.payload.value),
            ],
          },
        },
      };
    case ActionTypes.ADD_BNCC_EM_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          em: {
            ...state.bncc.em,
            [action.payload.key]: [
              ...state.bncc.em[
                action.payload.key as keyof typeof state.bncc.em
              ],
              action.payload.value,
            ],
          },
        },
      };
    case ActionTypes.REMOVE_BNCC_EM_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          em: {
            ...state.bncc.em,
            [action.payload.key]: [
              ...state.bncc.em[
                action.payload.key as keyof typeof state.bncc.em
              ].filter((value) => value !== action.payload.value),
            ],
          },
        },
      };
    case ActionTypes.ADD_BNCC_BASIC_COMPONENT:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          basic_competences: [
            ...state.bncc.basic_competences,
            action.payload.value,
          ],
        },
      };
    case ActionTypes.REMOVE_BNCC_BASIC_COMPONENT:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          basic_competences: [
            ...state.bncc.basic_competences.filter(
              (value) => value !== action.payload.value
            ),
          ],
        },
      };
    case ActionTypes.SET_BNCC_EI_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ei: {
            ...state.bncc.ei,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case ActionTypes.SET_BNCC_EM_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          em: {
            ...state.bncc.em,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case ActionTypes.SET_BNCC_EF_FIELDS:
      return {
        ...state,
        bncc: {
          ...state.bncc,
          ef: {
            ...state.bncc.ef,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    case ActionTypes.SET_PEDAGOGICAL:
      return {
        ...state,
        pedagogical_guideline: action.payload.value,
      };
    case ActionTypes.SET_TAGS:
      return {
        ...state,
        tags: action.payload.value,
      };
    case ActionTypes.SET_FIELDS_ARRAY:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    default:
      return state;
  }
}
