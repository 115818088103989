import { useQuery } from "react-query";
import { Queries } from "../../../../../types/queries";
import TargetAudienceService from "../../../../../Services/TargetAudience.service";
import { minutesToMilliseconds } from "../../../../../Utils/Functions";


export function useListTargetAudience() {
  return useQuery(
    Queries.LIST_TARGETS_AUDIENCE,
    () => TargetAudienceService.listTargets(),
    {
      cacheTime: minutesToMilliseconds(5),
      staleTime: minutesToMilliseconds(5),
    }
  );
}
