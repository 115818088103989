import styled from "styled-components";

export const TitleKnowledgeArea = styled.p`
  margin: 0;
  text-transform: uppercase;
`;

export const TitleThematicUnit = styled.p`
  margin-left: 1rem;
`;

export const ListItem = styled.ul`
  list-style: none;
`;

export const KnowledgeObject = styled.p`
  list-style: none;
  margin-left: 2rem;
  color: #000;
`;
