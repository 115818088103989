import { Edit, ToggleLeft, Trash } from "lucide-react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CallRegister } from "../../../Components/CallRegister";
import { ListDefault } from "../../../Components/ListDefault";
import { SuperBox } from "../../../Components/SuperBox";
import { Container, Main } from "./styles";
import { EditUser } from "./Edit";
import { UserProfile } from "./Profile";
import { api } from "../../../Services/api";
import { useEffect } from "react";
import { LoadingScreen } from "../../../Components/Loading";
import { EnumEmployees } from "../../../Utils/Types";
import { getNameEmployee } from "../../../Utils/Functions";
import { ModalShort } from "../../../Components/Modals/ModalShort";
import StudentService from "../../../Services/Student.service";
import { Input } from "../../../Components/Form/Input";
import ListGuardian from "./ListGuardian";
import ListManager from "./ListManager";
import ListManagerEmployee from "./ListManagerEmployee";
import ListDirector from "./ListDirector";
import ListCoord from "./ListCoord";
import UnidadeService from "../../../Services/Unidade.service";
import ModalNew from "../../../Components/Modals/ModalNew/ModalNew";

export function ListUser() {
  const [list, setList] = useState("");
  const [switchScreen, setSwitchScreen] = useState(1);
  const [typeProfile, setTypeProfile] = useState(1);
  const [selected, setSelected] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [manager, setManager] = useState<any>([]);
  const [employee, setEmployee] = useState<any>([]);
  const [student, setStudent] = useState<any>([]);
  const [guardian, setGuardian] = useState<any>([]);
  const [user, setUser] = useState("");

  const idUser = sessionStorage.getItem('location') || ''

  const urlState = JSON.parse(sessionStorage.getItem("location")!);
  const idState = JSON.parse(sessionStorage.getItem("locationData")!);
  const navigate = useNavigate();
  const [idUserForShow, setIdUserForShow] = useState<string>("")

  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");
  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [switchPageListEmployee, setSwitchPageListEmployee] = useState(
    `/admin/education-institutions/${idState.identifier}/employees?limit=10&page=1`
  );

  const [switchPageListManager, setSwitchPageListManager] = useState(
    `/admin/clients/${idState.identifier}/managers?limit=10&page=1`
  );

  const [employeesByUnit, setEmployeesByUnit] = useState<any[]>([])

  // useEffect(() => {

  //   UnidadeService.listEmploeesByUnit({ idUnit: idState.identifier }).then((response) => {
  //     if (response.success) {
  //       setEmployeesByUnit(response.data)
  //       console.log(response.data)
  //     }
  //   })

  // }, [])

  useEffect(() => {
    setList(urlState.toString());
    setUser(getNameEmployee(urlState.toString()));




    async function fetchManagers() {
      await api
        .get(switchPageListManager, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setManager(response.data.data);
          setIsLoading(false);
          setMetaTo(response.data.meta.to);
          setMetaTotal(response.data.meta.total);
          setPages(response.data.meta.links);
        })
        .catch((error) => {
          alert(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
          setIsLoading(false);
          window.location.reload();
        });
    }

    async function fetcEmployee() {
      await api
        .get(switchPageListEmployee, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setEmployee(response.data.data);
          setIsLoading(false);
          setMetaTo(response.data.meta.to);
          setMetaTotal(response.data.meta.total);
          setPages(response.data.meta.links);
        })
        .catch((error) => {
          alert(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
          setIsLoading(false);
          window.location.reload();
        });



      UnidadeService.listEmploeesByUnit({ idUnit: idState.identifier }).then((response) => {
        if (response.success) {
          setEmployeesByUnit(response.data)
          console.log(response.data)
        }
      })

    }

    async function fetchStudent() {
      await api
        .get(`/admin/education-institutions/${idState.identifier}/students`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setStudent(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
          setIsLoading(false);
          window.location.reload();
        });
    }

    async function fetchGuardian() {
      await api
        .get(`/admin/education-institutions/${idState.identifier}/guardians`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          setGuardian(response.data.data);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(
            "Erro ao carregar dados da página. Tente novamente mais tarde."
          );
          setIsLoading(false);
          window.location.reload();
        });
    }

    if (urlState.toString() === EnumEmployees.MANAGER) {
      fetchManagers();
    }

    if (
      urlState === 2 ||
      urlState === 3 ||
      urlState === 4 ||
      urlState === 5 ||
      urlState === 6
    ) {
      fetcEmployee();
      UnidadeService.listEmploeesByUnit({ idUnit: idState.identifier }).then((response) => {
        if (response.success) {
          setEmployeesByUnit(response.data)
        }
      })
    }

    if (urlState === 7) {
      fetchStudent();
    }

    if (urlState === 8) {
      fetchGuardian();
    }
  }, [switchPageListEmployee, switchPageListManager]);

  const onEditTeacher = (option: any) => navigate("/unidade/usuario/edit", { state: option });
  const onEditEmployee = (option: any) => navigate("/unidade/usuario/edit", { state: option });


  const [isOpen, setIsOpen] = useState(false);
  const [typeModal, setTypeModal] = useState("");
  const [textModal, setTextModal] = useState("");



  //Alterar dados o responsável

  const [inputSearchView, setInputSearchView] = useState("")
  const [employeesFiltered, setEmployeesFiltered] = useState<any[]>([])

  useEffect(() => {

    const filteredEmployees = employeesByUnit.filter((item: any) => item.user.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setEmployeesFiltered(filteredEmployees)

  }, [inputSearchView, employeesByUnit])

  const [studentsFiltereds, setStudentsFiltereds] = useState<any[]>([])

  useEffect(() => {

    let filteredStudents = student.filter((item: any) => item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setStudentsFiltereds(filteredStudents)

  }, [inputSearchView, student])


  const [guardiansFiltered, setGuardiansFiltered] = useState<any[]>([])

  useEffect(() => {

    let filteredGuardians = guardian.filter((item: any) => item.user.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()))
    setGuardiansFiltered(filteredGuardians)

  }, [inputSearchView, guardian])

  const [objectToDelete, setObjectToDelete] = useState({
    idUnit: idState.identifier,
    name: "",
    identifierStudent: ""
  })

  function deleteStudent() {
    StudentService.delete({ educationInstitution: objectToDelete.idUnit, student: objectToDelete.identifierStudent }).then((res) => {
      window.location.reload()
    })
  }


  return (
    <LoadingScreen isLoading={isLoading}>
      <SuperBox switch={true} page={4} path={`${idState.socialName} \u2022 ${user}`}>
        <Container>
          {switchScreen === 1 && (
            <Main>
              <CallRegister
                onBack={() => {
                  navigate("/unidade/list");
                }}
                link="/unidade/usuario/registration"
                value={list}
              />
              {list === EnumEmployees.MANAGER && (
                <ListManager
                  manager={manager}
                  metaTo={metaTo}
                  metaTotal={metaTotal}
                  pages={pages}
                  setSelected={setSelected}
                  setSwitchPageListManager={setSwitchPageListManager}
                  setSwitchScreen={setSwitchScreen}
                  setTypeProfile={setTypeProfile}
                />
              )}
              {list === EnumEmployees.MANAGER_EMPLOYEE && (
                <ListManagerEmployee
                  employees={employeesFiltered}
                  metaTo={metaTo}
                  metaTotal={metaTotal}
                  onEditEmployee={onEditEmployee}
                  pages={pages}
                  setSelected={setSelected}
                  setSwitchPageListEmployee={setSwitchPageListEmployee}
                  setSwitchScreen={setSwitchScreen}
                  setTypeProfile={setTypeProfile}
                  inputSearchView={inputSearchView}
                  setInputSearchView={setInputSearchView}
                />
              )}
              {/* LISTAGEM DOS DIRETORES */}
              {list === EnumEmployees.DIRECTOR && (
                <ListDirector
                  employees={employeesFiltered}
                  metaTo={metaTo}
                  metaTotal={metaTotal}
                  onEditEmployee={onEditEmployee}
                  pages={pages}
                  setSelected={setSelected}
                  setSwitchPageListEmployee={setSwitchPageListEmployee}
                  setSwitchScreen={setSwitchScreen}
                  setTypeProfile={setTypeProfile}
                  inputSearchView={inputSearchView}
                  setInputSearchView={setInputSearchView}
                />
              )}
              {/* LISTAGEM DE COORDENADORES */}
              {list === EnumEmployees.COORD && (
                <ListCoord
                  employees={employeesFiltered}
                  metaTo={metaTo}
                  metaTotal={metaTotal}
                  onEditEmployee={onEditEmployee}
                  pages={pages}
                  setSelected={setSelected}
                  setSwitchPageListEmployee={setSwitchPageListEmployee}
                  setSwitchScreen={setSwitchScreen}
                  setTypeProfile={setTypeProfile}
                  inputSearchView={inputSearchView}
                  setInputSearchView={setInputSearchView}
                />
              )}
              {list === EnumEmployees.ADMIN_EMPLOYEE && (
                <ListDefault
                  onBack={() => {
                    window.history.back();
                  }}
                  pages={[]}
                  changeGet={(v: any) => { }}
                  to={"1"}
                  total={""}
                >
                  <thead>
                    <tr>
                      <td colSpan={7}>
                        <Input
                          flex="fx30"
                          type="search"
                          placeHolderTeacher="Pesquise por um administrador"
                          color="#039bc9"
                          value={inputSearchView}
                          onChange={setInputSearchView}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="wd30">Nome</td>
                      <td className="wd15">Perfil/Função</td>
                      <td className="wd20">Email</td>
                      <td className="wd25">Unidade(s) de Ensino</td>
                      <td className="wd10">Ação</td>
                    </tr>
                  </thead>
                  <tbody>
                    {employeesFiltered
                      ?.filter(
                        (option: any) =>
                          option.user.roles[0].slug ===
                          "administrative-employee"
                      )
                      .map((option: any) => {
                        console.log(option)
                        return (
                          <tr>
                            <td>
                              <Link
                                to=""
                                onClick={() => onEditEmployee(option)}

                              >
                                {option?.user.name}
                              </Link>
                            </td>
                            <td>
                              {option.user.roles[0]
                                ? option.user.roles[0].name
                                : "Não definido"}
                            </td>
                            <td>{option.user.email}</td>
                            <td>
                              {option.educationInstitution
                                ? option.educationInstitution.name
                                : "Não definido"}
                            </td>
                            <td>
                              <button
                                className="hvr-grow"
                                onClick={() => onEditEmployee(option)}

                              >
                                <Edit />
                              </button>
                              <button
                                className="hvr-grow"
                                style={{ color: "green", marginLeft: "10px" }}
                              >
                                <ToggleLeft />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </ListDefault>
              )}
              {/* LISTAGEM DE PROFESSORES */}
              {list === EnumEmployees.TEACHER && (
                <ListDefault
                  onBack={() => {
                    window.history.back();
                  }}
                  pages={[]}
                  changeGet={(v: any) => { }}
                  to={"1"}
                  total={""}
                >
                  <thead>
                    <tr>
                      <td colSpan={7}>
                        <Input
                          flex="fx30"
                          type="search"
                          placeHolderTeacher="Pesquise por um professor"
                          color="#039bc9"
                          value={inputSearchView}
                          onChange={setInputSearchView}
                        />
                      </td>
                    </tr>
                    <tr></tr>
                    <tr>
                      <td className="wd30">Nome</td>
                      <td className="wd15">Perfil/Função</td>
                      <td className="wd20">Email</td>
                      <td className="wd25">Unidade(s) de Ensino</td>
                      <td className="wd10">Ação</td>
                    </tr>
                  </thead>
                  <tbody>
                    {employeesFiltered
                      ?.filter(
                        (option: any) =>
                          option.user.roles[0].slug === "teacher-employee"
                      )
                      .map((option: any) => {
                        return (
                          <tr>
                            <td>
                              <Link
                                to=""
                                onClick={() => {
                                  setSelected(option);
                                  setSwitchScreen(2);
                                  setTypeProfile(7);
                                }}
                              >
                                {option?.user.name}
                              </Link>
                            </td>
                            <td>
                              {option.user.roles[0]
                                ? option.user.roles[0].name
                                : "Não definido"}
                            </td>
                            <td>{option.user.email}</td>
                            <td>
                              {option.educationInstitution
                                ? option.educationInstitution.name
                                : "Não definido"}
                            </td>
                            <td>
                              <button
                                className="hvr-grow"
                                onClick={() => {
                                  setSelected(option);
                                  setSwitchScreen(3);
                                }}
                              >
                                <Edit />
                              </button>
                              <button
                                className="hvr-grow"
                                style={{ color: "green", marginLeft: "10px" }}
                              >
                                <ToggleLeft />
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </ListDefault>
              )}
              {list === EnumEmployees.STUDENT && (
                <>
                  <ModalNew
                    isOpen={isOpen}
                    type="delete"
                    text={`Deseja excluir o aluno ${objectToDelete.name}`}
                    onConfirm={() => {
                      deleteStudent()
                    }}
                    onRequestClose={() => setIsOpen(false)}
                  />
                  <ListDefault
                    onBack={() => {
                      window.history.back();
                    }}
                    pages={[]}
                    changeGet={(v: any) => { }}
                    to={"1"}
                    total={""}
                  >
                    <thead>
                      <tr>
                        <td colSpan={7}>
                          <Input
                            flex="fx30"
                            type="search"
                            placeHolderTeacher="Pesquise por um estudante"
                            color="#039bc9"
                            value={inputSearchView}
                            onChange={setInputSearchView}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td className="wd20">Nome</td>
                        <td className="wd10">Matrícula</td>
                        <td className="wd10">Cliente</td>
                        {/* <td className="wd20">Email</td> */}
                        <td className="wd15">Etapa</td>
                        <td className="wd10">Turma</td>
                        <td className="wd10">Ação</td>
                      </tr>
                    </thead>
                    <tbody>
                      {studentsFiltereds.map((option: any, index: any) => {
                        return (
                          <>
                            <tr key={index}>
                              <td>
                                <Link
                                  to=""
                                  onClick={() => {
                                    setSelected(option);
                                    setSwitchScreen(2);
                                    setTypeProfile(8);
                                  }}
                                >
                                  {option?.name}
                                </Link>
                              </td>
                              <td>{option.enrollment}</td>
                              <td>
                                {JSON.parse(localStorage.getItem("client")!)
                                  .socialName
                                  ? JSON.parse(localStorage.getItem("client")!)
                                    .socialName
                                  : "Não definido"}
                              </td>
                              {/* <td>{option.email ? option.email : "Não informado"}</td> */}
                              <td>
                                {option.educationStage
                                  ? option.educationStage.description +
                                  " - " +
                                  option.educationStage.name
                                  : "Não definido"}
                              </td>
                              <td>
                                {option.classroom.length > 0
                                  ? "teste"
                                  : "Não definido"}
                              </td>
                              <td>
                                <button
                                  className="hvr-grow"
                                  onClick={() => {
                                    setSelected(option);
                                    setSwitchScreen(3);
                                    setTypeProfile(8);
                                  }}
                                >
                                  <Edit />
                                </button>
                                <button
                                  className="hvr-grow"
                                  onClick={() => {
                                    setIsOpen(true)
                                    setTypeModal('delete')
                                    setTextModal("Você deseja excluir esse aluno?")
                                    setObjectToDelete({ ...objectToDelete, name: option.name, identifierStudent: option.identifier })
                                  }}
                                >
                                  <Trash />
                                </button>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </ListDefault>
                </>
              )}
              {list === EnumEmployees.GUARDIAN && (
                <ListGuardian
                  guardians={guardiansFiltered}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  setSelected={setSelected}
                  setSwitchScreen={setSwitchScreen}
                  setTextModal={setTextModal}
                  setTypeModal={setTypeModal}
                  setTypeProfile={setTypeProfile}
                  typeModal={typeModal}
                  setIdUserForShow={setIdUserForShow}
                  inputSearchView={inputSearchView}
                  setInputSearchView={setInputSearchView}
                  unit={idState}
                />
              )}
            </Main>
          )}
          {switchScreen === 2 && (
            <UserProfile
              type={typeProfile}
              back={() => {
                setSwitchScreen(1);
              }}
              edit={() => setSwitchScreen(3)}
              selected={selected}
              idUser={idUser}
              unitId={idState.identifier}
            />
          )}
          {switchScreen === 3 && (
            <EditUser
              back={() => setSwitchScreen(2)}
              selected={selected}
              type={typeProfile}
              stage={idState}
              idUserForShow={idUserForShow}
            />
          )}
        </Container>
      </SuperBox>
    </LoadingScreen>
  );
}