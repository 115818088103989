import { useState } from 'react'
import { Divider } from '../../../../../Components/Form/Divider'
// import { Select } from '../../../../../Components/Form/Select'
import { Container, Tables } from './styles'
import { api } from '../../../../../Services/api'
import { useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

interface TypeCollection {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

interface TypePackage {
  identifier: string
  name: string
  color: string
  description: string
  logo: string
}

export function SelectTableEdit(props: any) {
  const [disponibleCollections, setDisponibleCollections] = useState<TypeCollection[]>([])
  const [disponiblePackages, setDisponiblePackages] = useState<TypePackage[]>([])
  const animatedComponents = makeAnimated();

  useEffect(() => {
    async function getCollections() {
      api.get('/admin/collections', {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        setDisponibleCollections(response.data.data);
      })
    }

    async function getPackages() {
      api.get('/admin/packages', {
        headers: {
          Authorization: `bearer ${localStorage.getItem('token')}`
        }
      }).then(response => {
        setDisponiblePackages(response.data.data);
      })
    }

    getCollections();
    getPackages();

  }, []);

  function handleSelectCollection(v: any) {
    const col = disponibleCollections.filter(col => col.identifier === v);
    if (props.selectedCollection.find((c: any) => c[0].identifier === col[0].identifier)) {
      alert('Coleção já selecionada');
      return;
    }
    props.changeCollection([...props.selectedCollection, col]);
  }

  function handleSelectPackage(v: any) {
    const pack = disponiblePackages.filter(pack => pack.identifier === v);
    if (props.selectedPackage.find((p: any) => p[0].identifier === pack[0].identifier)) {
      alert('Pacote já selecionado');
      return;
    }
    props.changePackage([...props.selectedPackage, pack]);
  }

  return (
    <Container>
      <Divider>

        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={disponibleCollections}
          getOptionLabel={(e: any)=> e.name}
          onChange={(e: any)=> handleSelectCollection(e.identifier)}
          className='selectStyles'
          placeholder="Selecione uma(as) coleção(ões)"
        />

        <Select
          closeMenuOnSelect={false}
          components={animatedComponents}
          options={disponiblePackages}
          getOptionLabel={(e: any)=> e.name}
          onChange={(e: any)=> handleSelectPackage(e.identifier)}
          getOptionValue={(e: any)=> e.identifier}
          className='selectStyles'
          placeholder="Selecione um(os) pacote(s)"
        />


        {/* <Select flex="fx50" label="Coleções" inputId="colecoesid" value='' onChange={(v:any) => handleSelectCollection(v)}>
          <option value="">Selecione</option>
          {disponibleCollections.map(cole => {
            return(
                <option value={cole.identifier} key={cole.identifier}>{cole.name}</option>
            )
          })}
        </Select>
        <Select flex="fx50" label="Pacotes" inputId="pacotesid" value='' onChange={(v:any) => handleSelectPackage(v)}>
          <option value="">Selecione</option>
          {disponiblePackages.map(pack => {
            return(
                <option value={pack.identifier} key={pack.identifier}>{pack.name}</option>
            )
          })}
        </Select> */}
      </Divider>
      <label htmlFor="">{props.label}</label>
      <Tables>
        <table>
          <thead>
            <td>Coleções</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedCollection.length > 0 ? props.selectedCollection.map((col: any) => {
              return (
                <tr>
                  <td>{col[0].name}</td>
                  <td>
                    <button onClick={() => {
                      const newSelectedCollection = props.selectedCollection.filter((c: any) => c[0].identifier !== col[0].identifier);
                      props.changeCollection(newSelectedCollection);
                    }}>
                      Remover
                    </button>
                  </td>
                </tr>
              )
            }) : <tr><td>Nenhuma coleção selecionada</td></tr>}

          </tbody>
        </table>
        <table>
          <thead>
            <td>Pacotes</td>
            <td></td>
          </thead>
          <tbody>
            {props.selectedPackage.length > 0 ? props.selectedPackage.map((pack: any) => {
              return (
                <tr>
                  <td>{pack[0].name}</td>
                  <td>
                    <button onClick={() => {
                      const newSelectedPackage = props.selectedPackage.filter((p: any) => p[0].identifier !== pack[0].identifier);
                      props.changePackage(newSelectedPackage);
                    }}>
                      Remover
                    </button>
                  </td>
                </tr>
              )
            }) : <tr><td>Nenhum pacote selecionado</td></tr>}
          </tbody>
        </table>
      </Tables>
    </Container>
  )
}
