import { api } from "./api";
import { AxiosError } from "axios";
import {
  CreateSuccess,
  CreateSuccessData,
  Forbidden,
  ServiceError,
  Success,
  SuccessData,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { AcademicYear } from "../Utils/Types";

type AnoLetivoCreateResponse =
  | CreateSuccessData<AcademicYear>
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type AnoLetivoGetResponse = SuccessData<AcademicYear[]> | Unauthorized;

interface AnoLetivoCreate {
  year: number;
  actived: boolean;
}

type ShowAgeRangeProps = {
  identifier: string
}

type ShowAgeRangeResponse = Success | Unauthorized | Forbidden | ServiceError

class AnoLetivoService {

  static async show({identifier}: ShowAgeRangeProps):Promise<ShowAgeRangeResponse>{
    try{

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/academic-years/${identifier}`,
        config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    }catch(error: any){
      throw new Error("Can not Show Age Range.")
    }
  }

  static async get() {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<AnoLetivoGetResponse>(
        `/admin/academic-years`,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async post(data: AnoLetivoCreate) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<AnoLetivoCreateResponse>(
        `/admin/academic-years`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}

export default AnoLetivoService;
