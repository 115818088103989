import styled from "styled-components";

export const Container = styled.div`
display: flex;
padding: 10px 0px;
>p{
flex: 2;
font-size: 12px;
font-weight: 300;
color: #646464;
display: flex;
align-items: center;
justify-content: flex-start;
}
>div{
    flex: 5;
    position: relative;
    height: 20px;
    background-color: #E9E9E9;
    border-radius: 100px;
    span{
        float: right;
        font-weight: 300;
        font-size: 12px;
        height: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 10px;
    }
    >div.qt{
        height: 20px;
        position: absolute;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0px 10px;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
    }
}
`;