import React, { ChangeEvent, useRef, useState } from "react";
import { Div } from "../style";
import { PlusCircle, Trash2 } from "lucide-react";
import { IAnswerResource } from "../../../../../../../reducers/newResource/reducer";
import { TextArea } from "../../../../../../../Components/TextAreaHook";


interface TemplateProps {
  answer: IAnswerResource;
  onDelete?: () => void;
  handleImage: (event: File) => void;
  handleDescription: (event: string) => void;
}

export default function Template({
  answer,
  onDelete,
  handleDescription,
  handleImage,
}: TemplateProps) {
  const [image, setImage] = useState(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleFilesChosen = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) {
      return;
    }
    const selectedImage = event.target.files[0];
    handleImage(selectedImage);
  };

  return (
    <Div>
      <div className="addImage" onClick={() => inputRef.current?.click()}>
        {!answer?.image ? (
          <>
            <label onClick={() => inputRef.current?.click()}>
              Clique aqui para inserir uma imagem
            </label>
            <input
              type="file"
              ref={inputRef}
              onChange={(e) => handleFilesChosen(e)}
              accept="image/*"
              style={{ display: "none" }}
            />
          </>
        ) : (
          <img
            src={URL.createObjectURL(answer!.image!)}
            style={{ maxWidth: "100%", maxHeight: "100%" }}
            alt=""
          />
        )}
      </div>
      <div className="desc">
        <TextArea
          label="Descrição"
          required={false}
          type="type1"
          flex="100%"
          height="125px"
          value={answer?.description}
          onChange={(event) => handleDescription(event.currentTarget.value)}
        />
        <div className="items">
          <Trash2 size={18} className="icon" onClick={onDelete} />
          <PlusCircle size={18} className="icon" />
        </div>
      </div>
    </Div>
  );
}
