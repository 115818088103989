import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
}
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`
max-width: 800px;
    width: 100%;
`;

export const Form = styled.form`
width: 800px;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10px;
>div{
    width: 100%;
}
margin-top: 10px;
`;

export const Title = styled.p`
background-color: #027294;
color: #fff;
height: 50px;
border-radius: 10px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
text-transform: uppercase;
font-size: 20px;
`;