import React, { useContext, useState } from 'react'
import { Container, Main, Sitemap } from './styles'
import { LeftMenu } from '../../../Components/LeftMenu'
import { WizardDiv } from '../../../Components/ReportComponents/Wizards/WizardDiv'
import { WizardClick } from '../../../Components/ReportComponents/Wizards/WizardClick'
import { Line } from '../../../Components/ReportComponents/Wizards/styles'
import UserGeneralReport from './UserGeneral/UserGeneral'
import ReportTeachers from './ReportTeachers/ReportTeachers'
import ReportStudents from './ReportStudents/ReportStudents'
import { ContextUserGeneralProvider } from './UserGeneral/ContextUserGeneral/ContextUserGeneral'

export enum EnumStepReports {
    REPORT_USER_GENERAL,
    REPORT_TEACHERS,
    REPORT_STUDENTS
}


interface IRenderReports {
    stepReport: number
}

function RenderReports({ stepReport }: IRenderReports) {
    switch (stepReport) {
        case EnumStepReports.REPORT_USER_GENERAL:
            return <UserGeneralReport />

        case EnumStepReports.REPORT_TEACHERS:
            return <ReportTeachers />
        case EnumStepReports.REPORT_STUDENTS:
            return <ReportStudents />
        default:
            return null
    }
}
const ReportUsers = () => {


    const [stepReport, setStepReport] = useState(0)


    return (
        <Container>
            <LeftMenu />
            <div className="box">
                <Main>
                    <Sitemap>
                        <p>Relatório</p> <span>&bull;</span> <p>Usuários</p>
                    </Sitemap>
                    <WizardDiv>
                        <WizardClick
                            text='Relatórios Gerais de Usuários'
                            active={stepReport === EnumStepReports.REPORT_USER_GENERAL ? true : false}
                            onClick={() => setStepReport(EnumStepReports.REPORT_USER_GENERAL)}
                        />
                        <WizardClick
                            text='Relatórios de Uso dos Professores'
                            active={stepReport === EnumStepReports.REPORT_TEACHERS ? true : false}
                            onClick={() => setStepReport(EnumStepReports.REPORT_TEACHERS)}
                        />
                        <WizardClick
                            text='Relatórios de Uso dos Estudantes'
                            active={stepReport === EnumStepReports.REPORT_STUDENTS ? true : false}
                            onClick={() => setStepReport(EnumStepReports.REPORT_STUDENTS)}

                        />
                    </WizardDiv>
                </Main>
                <Line />
                <ContextUserGeneralProvider>
                    <RenderReports stepReport={stepReport} />
                </ContextUserGeneralProvider>
            </div>
        </Container>
    )
}

export default ReportUsers