import styled from "styled-components";

export const BoxAccess = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;

    .p-Accesses{
    font-size: 16px;
    color: #646464;
    font-weight: 900;
    }

`

export const Section = styled.div`
    display: flex;
    flex-direction: column;
`;

export const DivSelect = styled.div`
    display: flex;
    width: 665px;
    height: 297px;
    border: 1px solid #027294;
    border-radius: 0px 0px 15px 15px;

    .div-select{
        display: flex;
        width: 50%;
        padding: 10px 30px;
        flex-direction: column;
        
        .text-title{
            font-size: 14px;
            color: #646464;
            font-weight: 700;
        }
        p{
            font-size: 14px;
            color: #646464;
        }
        .line{
            display: flex;
            width: 100%;
            justify-content: flex-end;
            padding-top: 7px;
            span{
                display: block;
                height: 0.5px;
                width: 240px;
                background-color: #BCBCBC;
            }
        }
        .div-switch{
            display: flex;
            align-items: center;
            padding-top: 10px;
            gap: 5px;
        }
    }  
`;


















export const Banner = styled.div`
    display: flex;
    margin-top: 40px;
    border-bottom: 1px solid #027294;
    align-items: center;

    .box-banner{
        display: flex;
        width: 279px;
        height: 45px;
        border-radius: 15px 15px 0px 0px;
        background-color: #027294;
        align-items: center;
        justify-content: center;
    }

    .itens{
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;
        
        p{
            color: #FFFFFF;
        }
        
        .arrow-icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 10px;
            border-top: 2px solid #FFFFFF;
            border-right: 2px solid #FFFFFF;
            transform: rotate(45deg);
            transition: transform 0.3s ease;
            cursor: pointer;
      }
    }

    &.opened .arrow-icon {
      transform: rotate(-45deg);
    }

    &.opened {
      height: auto;
    }
`



