import React, { useContext } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'

const ReviewEF = () => {

    const { resource } = useContext(ResourceContext)
    const { ef } = resource.bncc

    return (
        <div style={{ marginTop: "10px" }}>
            <tr>
                <td>
                    <span>ENSINO FUNDAMENTAL</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Anos: {" "}{ef.years.length > 0 ? ef.years.join(", ") : "Não informado."}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>
                        Áreas do conhecimento:
                    </span>
                </td>
            </tr>
            {ef.knowledge_areas.length > 0 ?
                ef.knowledge_areas.map((knowledge) => {
                    return (
                        <tr><td><span>- {knowledge}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Componentes curriculares:</span>
                </td>
            </tr>
            {ef.curricular_components.length > 0 ?
                ef.curricular_components.map((curricular) => {
                    return (
                        <tr><td><span>- {curricular}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Campos de atuação:</span>
                </td>
            </tr>
            {ef.fields_action.length > 0 ?
                ef.fields_action.map((field) => {
                    return (
                        <tr><td><span>- {field}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Unidades Temáticas:</span>
                </td>
            </tr>
            {ef.thematic_units.length > 0 ?
                ef.thematic_units.map((thematic) => {
                    return (
                        <tr><td><span>- {thematic}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Objetos do Conhecimento:</span>
                </td>
            </tr>
            {ef.knowledge_objects.length > 0 ?
                ef.knowledge_objects.map((knowledge) => {
                    return (
                        <tr><td><span>- {knowledge}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Habilidades:</span>
                </td>
            </tr>
            {ef.skills.length > 0 ?
                ef.skills.map((skill) => {
                    return (
                        <tr><td><span>- {skill}</span></td></tr>
                    )
                }) : "Não informado."
            }

        </div>
    )
}

export default ReviewEF