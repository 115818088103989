import { useState } from 'react'
import { SuperBox } from '../../../Components/SuperBox'
import { FormTech } from './FormTech'
import { Container, Form, Main, Sitemap, Title } from './styles'
import { ModalSelect } from './ModalSelect'

export function TechRegistration() {
  const [wasSelected, setWasSelected] = useState(false)
  const [selectedTwo, setSelectedTwo] = useState<any>('0')
  return (
    <SuperBox switch={true} page={4} path="Cadastro &bull; Usuário">
      <Container>
        <div className="box">
          <Main>
            <FormTech
              two={selectedTwo}
              onPrev={() => setWasSelected(false)}
            />
          </Main>
        </div>
      </Container>
    </SuperBox>
  )
}
