import { Container, Datas, DivList } from "./styles";

export function ExpandedClient(props: any) {
    const client = props.dataClient

    return (
        <Container>
            <Datas>

                <div className="div-aside">
                    <aside>
                        <div className="div-img">
                            {client.logo !== "path/to/logo.jpg" ? (
                                <img
                                    src={"https://portal.arkadigital.dev.br/" + client.logo}
                                    alt=""
                                />
                            ) : (
                                <p>Sem logo</p>
                            )}
                        </div>
                    </aside>

                    <div className="div-info">
                        <p>CNPJ: {client?.cnpj}</p>
                        <p>Turmas: 10?</p>
                        <p>Recursos disponíveis: 10?</p>
                    </div>
                </div>

                <DivList>
                    <div className="text">
                        <p>Coleções:</p>
                    </div>

                    <div className="scrollable-content">
                        {client?.plan.collections.map((collection: any, index: number) => (
                            <p key={index}>{collection.name}</p>
                        ))}
                    </div>
                </DivList>

                <DivList>
                    <div className="text">
                        <p>Pacotes:</p>
                    </div>
                    <div className="scrollable-content">
                        {client?.plan.packages.map((packagee: any, index: number) => (
                            <p key={index}>{packagee.name}</p>
                        ))}
                    </div>
                </DivList>
            </Datas>
        </Container>
    )
}