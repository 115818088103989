import React from "react";
import { Divider } from "../Form/Divider";
import { DivCheck } from "./style";

export default function CheckBoxBNCC(props: any) {
  return (
    <DivCheck>
      <div className="line">
        <input type="checkbox" {...props}/>
        <label className="number">{props.number}-</label>
        <p className="info">
          <b>{props.title}</b>
          {props.text}
        </p>
      </div>
    </DivCheck>
  );
}
