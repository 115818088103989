import { useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { Select } from "../../../../Components/Form/Select";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { Form } from "../styles";
import { api } from "../../../../Services/api";
import Papa from "papaparse";
import { useNavigate } from "react-router-dom";
import UnidadeService from "../../../../Services/Unidade.service";

export function Form2(props: any) {
  const [modalSave, setModalSave] = useState(false);
  const [dataSendList, setDataSendList] = useState<any[]>([]);
  const navigate = useNavigate();
  const messageSucess = props.edit
    ? "Edição realizada com sucesso"
    : "Cadastro salvo com sucesso";
  const [unidade, setUnidade] = useState([
    {
      name: "",
      phone: "",
      email: "",
      zip_code: "",
      address: "",
      number: "",
      complement: "",
      neighborhood: "",
      city: "",
      state: "",
    },
  ]);
  const [unitSelected, setUnitSelected] = useState("");


  const changeUnit = (e: any) => {
    setUnidade([props.unidadeList[e]]);
  };


  const saveUnit = () => {
    if (props.unidadeList.length > 0) {
      
      props.unidadeList.map((item: any, index: any) => {
        dataSendList.push({
          name: item.name,
          phone: item.phone,
          email: item.email,
          zip_code: item.zip_code,
          address: item.address,
          number: item.number,
          complement: item.complement,
          neighborhood: item.neighborhood,
          city: item.city,
          state: item.state,
        });
      });

      // Convert data to CSV
      
      const csv = Papa.unparse(dataSendList);
      console.log(csv)

      // Create a Blob object from the data

      const blob = new Blob([csv], { type: "text/csv" });

      const file = new File([blob], "unidades.csv", { type: "text/csv" });

      // Create a FormData object

      const formData = new FormData();

      // Add the file to the FormData object

      formData.append("file", file);

      // Send the file to the API

      UnidadeService.postImport(formData).then((response)=>{
        if(response?.success){
          setModalSave(true);
        }
      });


    } else {
      const dataSend = {
        name: props.nameValue,
        phone: props.telValue,
        email: props.emailValue,
        zip_code: props.cepValue,
        address: props.ruaValue,
        number: props.numberValue,
        complement: props.complementoValue,
        neighborhood: props.bairroValue,
        city: props.cidadeValue,
        state: props.ufValue,
      };
      if (props.edit) {
        UnidadeService.put(dataSend, props.edit.identifier).then((res) => {
          if (res?.success) {
            setModalSave(true);
          }
        });
      } else {
        UnidadeService.post(dataSend).then((res) => {
          if (res?.success) {
            setModalSave(true);
          }
        });
      }
    }
  };

  return (
    <Form>
      <ModalShort
        type="success"
        text={messageSucess}
        isOpen={modalSave}
        onRequestClose={() => navigate("/unidade/list")}
        onConfirm={() => {
          navigate("/unidade/list");
        }}
      />
      {props.unidadeList.length > 0 && (

        <>
          <Divider>
            <p>Verifique se os dados que você cadastrou estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.</p>
          </Divider>
          <Select
            inputId="unidade4id"
            label="Selecione a Unidade Educacional"
            onChange={(e: any) => {
              changeUnit(e)
              setUnitSelected(e)
            }
            }
          >
            <option value="">Selecione</option>
            {props.unidadeList.map((item: any, index: any) => (
              <option key={index} value={index}>
                {item.name}
              </option>
            ))}
          </Select>
          {unitSelected === "" ? <></>
            :
            <>
              <BoxResume>
                <tr>
                  <td>
                    <span>Telefone:</span>
                    <p>{unidade[0].phone ? unidade[0].phone : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Email:</span>
                    <p>{unidade[0].email ? unidade[0].email : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>CEP:</span>
                    <p>{unidade[0].zip_code ? unidade[0].zip_code : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Rua/Av:</span>
                    <p>{unidade[0].address ? unidade[0].address : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Número:</span>
                    <p>{unidade[0].number ? unidade[0].number : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Complemento:</span>
                    <p>{unidade[0].complement ? unidade[0].complement : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Bairro:</span>
                    <p>{unidade[0].neighborhood ? unidade[0].neighborhood : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Cidade:</span>
                    <p>{unidade[0].city ? unidade[0].city : "Não informado"}</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span>Estado:</span>
                    <p>{unidade[0].state ? unidade[0].state : "Não informado"}</p>
                  </td>
                </tr>
              </BoxResume>
            </>}
        </>
      )}
      {props.nameValue !== "" && (
        <BoxResume>
          <tr>
            <td>
              <span>Nome da Unidade Educacional:</span>
              <p>{props.nameValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Telefone:</span>
              <p>{props.telValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Email:</span>
              <p>{props.emailValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>CEP:</span>
              <p>{props.cepValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Rua/Av:</span>
              <p>{props.ruaValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Número:</span>
              <p>{props.numberValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Complemento:</span>
              <p>{props.complementoValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Bairro:</span>
              <p>{props.bairroValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Cidade:</span>
              <p>{props.cidadeValue}</p>
            </td>
          </tr>
          <tr>
            <td>
              <span>Estado:</span>
              <p>{props.ufValue}</p>
            </td>
          </tr>
        </BoxResume>
      )}
      <Controllers
        save={true}
        functionSave={() => saveUnit()}
        functionPrev={() => props.prev(1)}
        functionCancel={() => navigate('/unidade/list')}
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  );
}
