import styled from "styled-components";

export const DivCheck = styled.div`
  display: flex;

  

  input[type="checkbox"] {
    cursor: pointer;
    flex: 0;
  }
  .line {
    display: flex;
    align-items: baseline;
  }
  .number {
    color: #646464;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-right: 10px;
  }
  .info {
    color: #646464;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    b{
      margin: 0%;
      color: black;
    }
  }
  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  input {
    width: 15px;
    margin: 10px;
  }
  p {
    text-align: left;
  }
`;
