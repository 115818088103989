import styled from "styled-components";

export const ContainerImportEmployees = styled.div`

display: flex;
align-items: center;
justify-content: flex-start;
width: 100%;
height: 50px;
margin-top: 20px;


`

export const ButtonImport = styled.div`
label{
font-size: 16px;
background-color: #646464;
border-radius: 15px;
height: 45px;
display: flex;
align-items: center;
justify-content: center;
width: 255px;
color: #fff;
text-transform: uppercase;
gap: 5px;
cursor: pointer;

}
input{
display: none;
}
`;