import { Container } from './styles'

export function QtGraph(props: any) {
  return (
    <Container className='itemChart'>
      <p>{props.label}</p>
      <div className="">
        <div className="qt" style={{width: `${props.qt}%`}}>{props.qt}%</div>
        <span>{props.max}</span>
      </div>
    </Container>
  )
}
