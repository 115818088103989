import { PlusCircle } from "lucide-react";
import { FormContainer } from "../../styles";
import { Input } from "../../../../../Components/Form/InputHook";
import { TableAuthor } from "../Tables/TableAuthor";
import { useContext } from "react";
import { ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import * as zod from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { IAuthorshipResource } from "../../../../../reducers/newResource/reducer";
import { Select } from "../../../../../Components/Form/Select";

const fieldsSchema = zod.object({
  content_creator: zod
    .string()
    .min(10, "Nome precisa de no mínimo 10 caracteres"),
  proofreader: zod.string().min(10, "Nome precisa de no mínimo 10 caracteres"),
  graphic_designer: zod
    .string()
    .min(10, "Nome precisa de no mínimo 10 caracteres"),
  screenwriter: zod.string().min(10, "Nome precisa de no mínimo 10 caracteres"),
  illustrator: zod.string().min(10, "Nome precisa de no mínimo 10 caracteres"),
  programmer: zod.string().min(10, "Nome precisa de no mínimo 10 caracteres"),
});

const ListaDeAnos = () => {
  const anoAtual = new Date().getFullYear();
  const anos = [];

  // Preenche a lista de anos, indo de hoje até 40 anos atrás
  for (let ano = anoAtual; ano >= anoAtual - 40; ano--) {
    anos.push(ano);
  }

  return (
    <>
      {anos.map((ano) => (
        <option key={ano} value={ano}>
          {ano}
        </option>
      ))}
    </>
  );
};

type FormData = zod.infer<typeof fieldsSchema>;

export function FormAuthor() {
  const { resource, addAuthorShip, removeAuthorShip, handleFieldResource } =
    useContext(ResourceContext);

  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(fieldsSchema),
    defaultValues: {
      content_creator: "",
      proofreader: "",
      graphic_designer: "",
      screenwriter: "",
      illustrator: "",
      programmer: "",
    },
  });

  const formValues = watch();
  const clearErrorField = (field: keyof FormData) => {
    if (errors[field]) {
      trigger(field);
    }
  };
  const fields = [
    { key: "content_creator", label: "Conteudista" },
    { key: "proofreader", label: "Revisor(a)" },
    { key: "graphic_designer", label: "Designer Gráfico" },
    { key: "screenwriter", label: "Roteirista" },
    { key: "illustrator", label: "Ilustrador(a)" },
    { key: "programmer", label: "Programador(a)" },
  ];
  

  async function addNewField(key: string) {
    const inputElement = document.getElementById(key) as HTMLInputElement;

    if (inputElement) {
      const validate = await trigger(key as keyof FormData);
      if (validate) {
        const valorDoInput = inputElement.value;

        inputElement.value = "";
        addAuthorShip(valorDoInput, key as keyof IAuthorshipResource);
      }
    }
  }

  function handleYearResource(value: number) {
    handleFieldResource("authorshipYear", value);
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }
  return (
    <FormContainer onSubmit={onSubmit} id="formAuthor">
      {fields.map((field) => (
        <Input
          id={field.key}
          required={false}
          className="fx35"
          label={field.label}
          
          type="text"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              addNewField(field.key);
            }
          }}
          error={errors[field.key as keyof FormData]}
          {...register(field.key as keyof FormData, {
            onChange: () => clearErrorField(field.key as keyof FormData),
          })}
        >
          <PlusCircle
            onClick={() => addNewField(field.key)}
            className="icon-plus"
          />
        </Input>
      ))}

      <TableAuthor
        deleteAction={removeAuthorShip}
        authors={resource.authorship}
      />

      <Select
        onChange={handleYearResource}
        value={resource.authorshipYear}
        label="Ano de criação"
        flex="fx33"
      >
        <option value={undefined}></option>
        <ListaDeAnos />
      </Select>
    </FormContainer>
  );
}
