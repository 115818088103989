import React, { useEffect, useState } from 'react'
import { ContainerTeachers } from './styles'
import { Client, Role } from '../../../../Utils/Types'
import ClienteService from '../../../../Services/Cliente.service'
import RoleService from '../../../../Services/Role.service'
import { Input } from '../../../../Components/Form/Input'
import { SlidersHorizontal } from 'lucide-react'
import { Divider } from '../../../../Components/Form/Divider'
import ReactSelect from '../../../../Components/Form/ReactSelect/ReactSelect'
import { ListDefault } from '../../../../Components/ListDefault'
import BontTableTeachers from './BondTableTeachers/BontTableTeachers'

const ReportTeachers = () => {

  const [roles, setRoles] = useState<Role[]>([])
  const [clients, setClients] = useState<Client[]>([])

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");
  const [switchPageList, setSwitchPageList] = useState(
    "/admin/clients?limit=10&page=1"
  );

  useEffect(() => {
    RoleService.get().then((res) => {
      if (res?.success) {
        setRoles(res.data)
      }
    })

    ClienteService.get({ path: `/admin/clients` }).then((res) => {
      if (res?.status === 200) {
        setClients(res.data.data)
      }
    })


  }, [])


  return (
    <ContainerTeachers>
      <Input
        flex="fx40"
        type="search"
        color="#039bc9"
      // value={inputSearchView}
      // onChange={setInputSearchView}
      />
      <div className="message">
        <span>FILTROS</span>
        <SlidersHorizontal size={14} />
      </div>

      <Divider>
        <ReactSelect
          label="Perfil de Usuário/Função"
          placeholder="Selecione..."
          flex="fx33"
          options={roles}
          getOptionLabel={(e) => e.name}
        />
        <ReactSelect
          label="Clientes"
          placeholder="Selecione..."
          flex="fx40"
          options={clients}
          getOptionLabel={(e) => e.socialName}
        />
      </Divider>
      <Divider>
        <BontTableTeachers/>
      </Divider>
      <div className="list">
        <ListDefault
          pages={pages}
          changeGet={(v: any) => setSwitchPageList(v)}
          to={metaTo}
          total={metaTotal}
        >
          <thead>
            <tr key={""}>
              <td className="wd10">Nome</td>
              <td className="wd10">Usuário / Email</td>
              <td className="wd10">Função / Perfil</td>
              <td className="wd10">Documento de Planejamento Criados</td>
              <td className="wd10">Agendamentos de Aula</td>
              <td className="wd10">Agendamentos Rota de Aprendizagem</td>
              <td className="wd10">Conquistas</td>
            </tr>
          </thead>

        </ListDefault>
      </div>
    </ContainerTeachers>
  )
}

export default ReportTeachers