import { type } from "os";
import { Forbidden, ServiceError, Success, Unauthorized, UnprocessableContent } from "../Utils/Responses";
import { api } from "./api";
import { Subcategory } from "../Pages/Registration/ResourcesRegistration/Form1/SubcategoryComponent/LevelOne";

export interface ICollection {
  identifier: string | undefined;
  name: string;
  color: string;
  description: string;
  logo: string;
}

type CollectionResgistrationProps = {
  name: string,
  description: string,
  logo: string
}
type CollectionRegistationResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;

type EditCollectionProps = {
  idCollection: string,
  data: FormData
}
type CollectionEditResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;

type CategorieRegistrationProps = {
  idCollection: string,
  name: string,
  description: string,
  color: string
}
type CategorieRegistationResponse = Success | Unauthorized | Forbidden | UnprocessableContent | ServiceError;


export interface ISubCategoryCollection {
  identifier: string
  name: string
  description: string
  path: string
  level: number
}

export interface ICategoriesCollection {
  identifier: string;
  name: string;
  color?: string;
  description?: string;
  subcategories: Subcategory[];
}

type ShowCollectionProps = {
  identifier: string | undefined;
}
type ShowCollectionResponse = Success | Unauthorized | Forbidden | ServiceError;


class CollectionService {

  static async showCollection(data: ShowCollectionProps): Promise<ShowCollectionResponse> {
    try {

      const { identifier } = data;

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/collections/${identifier}`, config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not show collection")
    }
  }

  static async listCollection(): Promise<ICollection[]> {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/collections`,
        config
      );
      if (response.status === 200) {
        return response.data.data
      }

      throw new Error("Can not list Categories.");

    } catch (error: any) {
      throw new Error("Can not list Categories.");
    }
  }

  static async listCategoriesByIdCollection(idCollection: string | undefined): Promise<ICategoriesCollection[]> {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/admin/collections/${idCollection}/categories`,
        config
      );
      if (response.status === 200) {
        return response.data.data
      }

      throw new Error("Can not list Categories.");

    } catch (error: any) {
      throw new Error("Can not list Categories.");
    }
  }

  static async collectionRegister(input: CollectionResgistrationProps): Promise<CollectionRegistationResponse> {

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data"
        }
      }

      const response = await api.post(
        `/admin/collections/`, input, config
      )

      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        case 403:
          return { ...response.data, success: false, message: "Forbidden" } as Forbidden;
        case 422:
          return { ...response.data, success: false, message: "Unprocessable Content" } as UnprocessableContent;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }

    } catch (error: any) {
      throw new Error("Can not create collection.")
    }
  }

  static async upadateCollection({ data, idCollection }: EditCollectionProps): Promise<CollectionEditResponse> {

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          "Content-Type": "multipart/form-data"
        }
      }

      const response = await api.post(
        `/admin/collections/${idCollection}?_method=PUT`, data, config
      )

      return response.data
    } catch (error: any) {
      throw new Error("Can not update collection.")
    }
  }


  static async addcategory(input: CategorieRegistrationProps): Promise<CategorieRegistationResponse> {

    const idPackage = input.idCollection

    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        }
      }

      const response = await api.post(
        `/admin/packages/${idPackage}/categories`, input, config
      )

      switch (response.status) {
        case 200:
          return { data: response.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "Forbidden" } as Forbidden
      }

    } catch (error: any) {
      throw new Error("Can not create Categories.")
    }
  }



}
export default CollectionService;
