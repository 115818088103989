import axios from "axios";
import { PlusCircle } from "lucide-react";
import { useEffect, useState } from "react";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { ImportExcel } from "../../../../Components/Form/ImportExcel";
import { Input } from "../../../../Components/Form/Input";
import { NewSave } from "../../../../Components/Form/NewSave";
import { Select } from "../../../../Components/Form/Select";
import { TableUniqueRemove } from "../../../../Components/Form/TableUniqueRemove";
import { ModalForm } from "../../../../Components/Modals/ModalForm";
import { Form, MoreStudent, Radio } from "../styles";
import { useNavigate } from "react-router-dom";

interface Unidade {
  Nome: string;
  Telefone: string;
  Email: string;
  Cep: string;
  Cidade: string;
  Estado: string;
  Rua: string;
  Complemento: string;
  Numero: string;
  Bairro: string;
}

interface Turma {
  AnoLetivo: string;
  Modalidade: string;
  Etapa: string;
  Fase: string;
  Cidade: string;
  Estado: string;
  Rua: string;
  Complemento: string;
  Numero: string;
  Bairro: string;
}

export function Form2(props: any) {
  const [modalForm, setModalForm] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [telValue, setTelValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [cepValue, setCepValue] = useState("");
  const [bairroValue, setBairroValue] = useState("");
  const [complementoValue, setComplementoValue] = useState("");
  const [cidadeValue, setCidadeValue] = useState("");
  const [ruaValue, setRuaValue] = useState("");
  const [ufValue, setUfValue] = useState("");
  const [numberValue, setNumberValue] = useState("");

  const [dataSend, setDataSend] = useState({
    Cep: "",
    Cidade: "",
    Email: "",
    Estado: "",
    Nome: "",
    Telefone: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    setDataSend({
      Cep: cepValue,
      Cidade: cidadeValue,
      Email: emailValue,
      Estado: ufValue,
      Nome: nameValue,
      Telefone: telValue,
    });
  }, [cepValue, cidadeValue, emailValue, ufValue, nameValue, telValue]);

  async function handleCep(n: any) {
    setCepValue(n);
    const number = n.replace(/\D/g, "");
    if (number.length === 8) {
      axios.get(`https://viacep.com.br/ws/${number}/json/`).then((response) => {
        setBairroValue(response.data.bairro);
        setComplementoValue(response.data.complemento);
        setCidadeValue(response.data.localidade);
        setRuaValue(response.data.logradouro);
        setUfValue(response.data.uf);
      });
    }
  }

  const onBack = () => navigate(-1);

  return (
    <Form>
      <ModalForm
        title="Cadastrar novo Estudante"
        isOpen={modalForm}
        onRequestClose={() => setModalForm(false)}
      >
        <Divider>
          <Input flex="fx20" label="Matrícula" inputId="matriculalabelid" />
          <Input flex="fx80" label="Nome" inputId="labelnomeid" />
        </Divider>
        <Divider>
          <Input
            flex="fx20"
            label="Data de Nascimento"
            inputId="matriculalabelid"
          />
          <Input flex="fx30" label="Telefone" inputId="labeltelefonelabelid" />
          <Input flex="fx50" label="E-mail" inputId="labelemailid" />
        </Divider>
        <Divider>
          <Input
            flex="fx50"
            label="Confirmar Email"
            inputId="labelconfirmemailid"
          />
          <Select
            flex="fx50"
            label="Necessidades especiais"
            inputId="labelnecessidadesid"
          >
            <option value=""></option>
            <option value="">Teste</option>
          </Select>
        </Divider>
        <Divider>
          <Select
            flex="fx100"
            label="Nome da Unidade Educacional"
            inputId="labelnomeunidadeeducacionalid"
          >
            <option value=""></option>
            <option value="">Teste</option>
          </Select>
        </Divider>
        <Divider>
          <Select flex="fx50" label="Etapa" inputId="labeletapaid">
            <option value=""></option>
            <option value="">Teste</option>
          </Select>
          <Select flex="fx50" label="Nome da turma" inputId="labnomedaturmaid">
            <option value=""></option>
            <option value="">Teste</option>
          </Select>
        </Divider>
        <Divider>
          <Input
            flex="fx100"
            label="Nome do Responsável"
            inputId="labelresponsavelid"
          />
        </Divider>
        <Divider>
          <Input
            flex="fx50"
            label="Data de nascimento"
            inputId="labelresponsavelnascimentoid"
          />
          <Input
            flex="fx50"
            label="Telefone"
            inputId="labelresponsaveltelefoneid"
          />
        </Divider>
        <Divider>
          <Input flex="fx50" label="Email" inputId="labelresponsavelemailid" />
          <Input
            flex="fx50"
            label="Confirmar Email"
            inputId="labelresponsavelconfirmaremailid"
          />
        </Divider>
      </ModalForm>
      <Select
        flex="fx100"
        label="Nome da unidade educacional"
        inputId="selectunidadeid"
      >
        <option value=""></option>
        <option value="">Teste</option>
      </Select>
      <Divider>
        <Select flex="fx50" label="Etapa" inputId="etapaid">
          <option value=""></option>
          <option value="">Teste</option>
        </Select>
        <Select flex="fx50" label="Nome da turma" inputId="nomedaturma">
          <option value=""></option>
          <option value="">Teste</option>
        </Select>
      </Divider>
      <Select flex="fx100" label="Professores" inputId="professoresid">
        <option value=""></option>
        <option value="">Teste</option>
      </Select>
      <TableUniqueRemove label="Relação de Professores" />
      <Divider>
        <Select flex="fx95" label="Estudantes" inputId="estudantesid">
          <option value=""></option>
          <option value="">Teste</option>
        </Select>
        <MoreStudent>
          <button className="hvr-grow" onClick={() => setModalForm(true)}>
            <PlusCircle size={16} />
          </button>
        </MoreStudent>
      </Divider>
      <TableUniqueRemove label="Relação de Estudantes" />
      <NewSave />
      <Controllers
        functionNext={() => props.next(4)}
        functionPrev={() => props.prev(2)}
        functionCancel={onBack}
        labelProx="Avançar"
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  );
}
