import { useQuery } from "react-query";
import { Queries } from "../../../../../types/queries";
import PlataformService from "../../../../../Services/Plataform.service";
import { minutesToMilliseconds } from "../../../../../Utils/Functions";

// const mocks = [
//   {
//     id: "1",
//     name: "Firefox",
//     type: "browser"
//   },
//   {
//     id: "2",
//     name: "Chrome",
//     type: "browser"
//   },
//   {
//     id:
//   }
// ]
export function useListPlataforms() {
  return useQuery(
    Queries.LIST_PLATAFORMS,
    () => PlataformService.listPlataforms(),
    {
      cacheTime: minutesToMilliseconds(5),
      staleTime: minutesToMilliseconds(5),
      retry: false,
    }
  );
}
