import { useContext, useRef, useState } from "react";

import { useListBNCC } from "../../../hooks/useListBNCC";
import { EnumColumnSelected } from "../FormElementarySchool";
import { Line, Row } from "../../../styles";
import { Column, ListKnoledge } from "../styles";
import { ItemKnowledgeComponent } from "../FormElementarySchool/ListKnoledge";
import { SearchSkills } from "../../components/SearchSkils";
import { ListItens } from "../../components/ListIItens";
import { ResourceContext } from "../../../../../../../Contexts/Form/ContextResource";
import { ModalRef } from "../../../../../../../Components/Modals";
import { IBNCCYear } from "../../../../../../../types/bncc";
import { ModalListNew } from "../../../../../../../Components/Modals/ModalListNew/ModalListNew";


export function FormHighSchool() {
  const { resource, addFieldBNCCEmResource, removeFieldBNCCEmResource } =
    useContext(ResourceContext);
  const { isLoading, data, isSuccess } = useListBNCC();
  const [componentCurricularSerlected, setComponentCurricularSerlected] =
    useState<string | null>(null);

  const [columnSelected, setColumnSelected] =
    useState<EnumColumnSelected | null>(null);

  const title =
    columnSelected === EnumColumnSelected.OBJETO_CONHECIMENTO
      ? "Lista Objetos de conhecimento"
      : "Lista Unidades temática";

  const modalRef = useRef<ModalRef>(null);

  function openModal() {
    modalRef.current?.open();
  }

  function handleColumnSelected(column: EnumColumnSelected) {
    setColumnSelected(column);
  }

  function handleComponentCurricularSelected(component: string) {
    setComponentCurricularSerlected(component);
  }

  function handleYear(year: IBNCCYear, select: boolean) {
    if (!select) {
      addFieldBNCCEmResource(year.year_id, "years");
    } else {
      removeFieldBNCCEmResource(year.year_id, "years");
    }
  }

  return (
    <div>
      {isLoading ? (
        <h2>Carregando ...</h2>
      ) : isSuccess ? (
        <div>
          {/*<Row>
             {data.stages[2].phases.map((phase) => (
              <div className="item" key={phase.name}>
                <Column>
                  <span className="sub-title">ANO:</span>
                  <ul className="row gap mt">
                    {phase.years.map((year) => {
                      const select = resource.bncc.em.years.some(
                        (yearSelected) => yearSelected === year.year_code
                      );
                      return (
                        <li key={year.year}>
                          <input
                            type="checkbox"
                            checked={select}
                            onChange={() => handleYear(year, select)}
                          />
                          <span>{year.year}</span>
                        </li>
                      );
                    })}
                  </ul>
                </Column>
              </div>
            ))}
          </Row> */}
          <Line $margin="0.5rem 0" />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1rem",
            }}
          >
            <span className="title title-list">Áreas do conhecimento</span>
            {/* <ListKnoledge>
              {data.knowledge_areas.map( 
                (knowledgeArea: any, index: number) => (
                  <ItemKnowledgeComponent
                    handleComponentCurricularSelected={
                      handleComponentCurricularSelected
                    }
                    handleColumnSelected={handleColumnSelected}
                    openModal={openModal}
                    index={index}
                    knowledgeArea={knowledgeArea}
                    key={knowledgeArea.name}
                  />
                )
              )}
            </ListKnoledge> */}
          </div>
          <SearchSkills type="em" />
          <Line $margin="1.5rem 0" />
        </div>
      ) : (
        <h2>Não foi possível solicitar os BNCCs</h2>
      )}
      <ModalListNew modalRef={modalRef} title={title} >
        <ListItens
          columnSelected={columnSelected}
          curricularSelected={componentCurricularSerlected}
        />
      </ModalListNew>
    </div>
  );
}
