import { useState, useEffect } from "react";
import { LeftMenu } from "../../../../Components/LeftMenu";
import { SuperBox } from "../../../../Components/SuperBox";
import { api } from "../../../../Services/api";
import { Form1Edit } from "./Form1";
import { Form2Edit } from "./Form2";
import { Form3Edit } from "./Form3";
import {
  Abas,
  Container,
  Form,
  Form22,
  Main,
  Sitemap,
  Upload,
} from "../../../Registration/ClientRegistration/styles";

export function ClientEdit(props: any) {
  const [switchForm, setSwitchForm] = useState(1);
  const [img, setImg] = useState<any>("");
  const [imgSave, setImgSave] = useState<string | null>(null);

  const [selectedOne, setSelectedOne] = useState<any>(props.one);
  const [isFirstRequestComplete, setIsFirstRequestComplete] = useState(false);
  const [socialName, setSocialName] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [address, setAddress] = useState("");
  const [number, setNumber] = useState("");
  const [complement, setComplement] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [teachersNumber, setTeachersNumber] = useState<any>(0);
  const [studentsNumber, setStudentsNumber] = useState<any>(0);
  const [educationNumber, setEducationNumber] = useState<any>(0);
  const [selectedCollection, setSelectedCollection] = useState<any[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<any[]>([]);

  useEffect(() => {
    api
      .get(`admin/clients/${props.selected}`, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setSelectedOne(response.data.data);
        setIsFirstRequestComplete(true);
      });
  }, []);

  useEffect(() => {
    if (!isFirstRequestComplete) return;
    setSocialName(selectedOne.socialName);
    setCnpj(selectedOne.cnpj);
    setAddress(selectedOne.address.address);
    setNumber(selectedOne.address.number);
    setComplement(selectedOne.address.complement);
    setNeighborhood(selectedOne.address.neighborhood);
    setZipCode(selectedOne.address.zipCode);
    setCity(selectedOne.address.city);
    setState(selectedOne.address.state);
    setPhone(selectedOne.phone);
    setEmail(selectedOne.email);
    setEmail2(selectedOne.email);
    setTeachersNumber(selectedOne.plan.maxEmployees);
    setStudentsNumber(selectedOne.plan.maxStudents);
    setEducationNumber(selectedOne.plan.maxEducationInstitutions);
    setImgSave(selectedOne.logo);
    setSelectedCollection(
      Object.values(selectedOne.plan.collections).map((value) => [value])
    );
    setSelectedPackage(
      Object.values(selectedOne.plan.packages).map((value) => [value])
    );
  }, [isFirstRequestComplete]);

  return (
    <Container>
      <div className="box">
        <Main>
          {switchForm !== 3 && (
            <Abas>
              <button className={switchForm === 1 ? "active" : ""}>
                Cliente
              </button>
              <button className={switchForm === 2 ? "active" : ""}>
                Plano
              </button>
            </Abas>
          )}
          {switchForm === 1 && (
            <Form1Edit
              next={() => setSwitchForm(2)}
              onBack={props.back}
              socialName={socialName}
              changeSocialName={(v: any) => setSocialName(v)}
              cnpj={cnpj}
              changeCnpj={(v: any) => setCnpj(v)}
              phone={phone}
              changePhone={(v: any) => setPhone(v)}
              email={email}
              changeEmail={(v: any) => setEmail(v)}
              email2={email2}
              changeEmail2={(v: any) => setEmail2(v)}
              address={address}
              changeAddress={(v: any) => setAddress(v)}
              complement={complement}
              changeComplement={(v: any) => setComplement(v)}
              number={number}
              changeNumber={(v: any) => setNumber(v)}
              neighborhood={neighborhood}
              changeNeighborhood={(v: any) => setNeighborhood(v)}
              zipCode={zipCode}
              changeZipCode={(v: any) => setZipCode(v)}
              city={city}
              changeCity={(v: any) => setCity(v)}
              state={state}
              changeState={(v: any) => setState(v)}
              img={img}
              setImg={setImg}
              imgSave={imgSave}
            />
          )}
          {switchForm === 2 && (
            <Form2Edit
              next={() => setSwitchForm(3)}
              prev={() => setSwitchForm(1)}
              onBack={props.back}
              changeTeachersNumber={(v: any) => setTeachersNumber(v)}
              changeStudentsNumber={(v: any) => setStudentsNumber(v)}
              changeEducationNumber={(v: any) => setEducationNumber(v)}
              teachersNumber={teachersNumber}
              studentsNumber={studentsNumber}
              educationNumber={educationNumber}
              selectedCollection={selectedCollection}
              selectedPackage={selectedPackage}
              changeSelectedCollection={(v: any) => setSelectedCollection(v)}
              changeSelectedPackage={(v: any) => setSelectedPackage(v)}
            />
          )}
          {switchForm === 3 && (
            <Form3Edit
              next={() => props.back()}
              prev={() => setSwitchForm(2)}
              teachersNumber={teachersNumber}
              studentsNumber={studentsNumber}
              educationNumber={educationNumber}
              socialName={socialName}
              cnpj={cnpj}
              onBack={props.back}
              address={address}
              number={number}
              complement={complement}
              neighborhood={neighborhood}
              zipCode={zipCode}
              city={city}
              state={state}
              phone={phone}
              email={email}
              selectedCollection={selectedCollection}
              selectedPackage={selectedPackage}
              img={img}
              selectedOne={selectedOne}
            />
          )}
        </Main>
      </div>
    </Container>
  );
}
