import { Edit } from "lucide-react";
import { ContainerData, Sitemap } from "./styles";
import { Trash2Icon } from "lucide-react";
import { PlusCircleIcon } from "lucide-react";
import { TreeItem } from "../TreeItem";
import { transformUrlImage } from "../../../../Utils/Functions";
import { useState } from "react";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { api } from "../../../../Services/api";
import { LinkResource } from "../LinkResource";

interface ProfileProps {
    packageData: any
    packageCategories: any
    selectedOption: any
    back: () => void
    handleRegisterCategory: () => void
    handleEditPakages: (event: any) => void
}

export function PackageProfile({ packageData, packageCategories, selectedOption, back, handleEditPakages, handleRegisterCategory }: ProfileProps) {
    const [modalDelete, setModalDelete] = useState(false)
    const [forDelete, setForDelete] = useState("")
    const [openResource, setOpenResource] = useState();

    // deletar pacote
    function deletePackage(idPackage: string) {
        api.delete(`/admin/packages/${idPackage}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }).then(response => {
            window.location.reload();
        })
    }

    return (
        <>
            <Sitemap>
                <p>Pacote</p> <span>&bull;</span> <p>{packageData.name}</p>
            </Sitemap>

            <ContainerData>
                <header>
                    <div className="flex-collection">
                        <div className="logo">
                            <img src={packageData.logo
                                ? transformUrlImage(packageData.logo)
                                : ""} alt=""
                            />
                        </div>
                        <div className='container-package'>
                            <div className="name">
                                Pacote: {packageData.name}
                            </div>
                            <div className="description">
                                Descrição: {packageData?.description}
                            </div>
                        </div>
                        <div className="action-icons">
                            <Edit size={14}
                                onClick={() => handleEditPakages(packageData)}
                            />
                            <Trash2Icon size={14} className="first-child"
                                onClick={() => { setForDelete(packageData.identifier); setModalDelete(true) }}
                            />
                        </div>
                    </div>
                </header>

                {openResource && (

                    <div className="div-all">
                        <button>
                            Vincular Recursos ao Pacote
                        </button>
                    </div>
                )}

                <section>
                    <div className='add-category'>
                        <button onClick={() => handleRegisterCategory()}>
                            <PlusCircleIcon size={16} />
                            Nova Categoria
                        </button>
                    </div>
                    <span className="line"></span>
                    <body>
                        <div className="div-treeItem">
                            {packageCategories.map((category: any) => (
                                <TreeItem
                                    item={category}
                                    level={0} idPackage={selectedOption}
                                    setOpenResource={setOpenResource}
                                />
                            ))}
                        </div>
                        
                        {/* link recurso */}
                       {openResource && (
                        <LinkResource/>
                       )}

                    </body>
                </section>

                <footer>
                    <div className="div-footer">
                        <button className="hvr-grow"
                            onClick={() => back()}
                        >
                            Voltar</button>
                    </div>
                </footer>
            </ContainerData>

            {modalDelete && (
                <ModalShort
                    type="delete"
                    isOpen={modalDelete}
                    text={"Tem certeza de que deseja excluir este pacote? Todas as categorias associadas serão perdidas."}
                    onRequestClose={() => {
                        setModalDelete(false)
                    }}
                    onConfirm={() => {
                        deletePackage(forDelete);
                        setModalDelete(false)
                    }}
                />
            )}
        </>
    )
}