import styled from "styled-components";

export const Container = styled.div`
display: flex;

    >div.box{
        width: 100%;
        padding: 5px 30px;
    }
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Banner = styled.div`
    display: flex;
    margin-top: 40px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
    align-items: center;

    .box-banner{
        display: flex;
        width: 279px;
        height: 45px;
        border-radius: 15px 15px 0px 0px;
        background-color: #027294;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .box-button{
        display: flex;
        width: 75%;
        justify-content: flex-end;
        
        button {
            display: flex;
            width: 90px;
            height: 27px;
            border: none;
            border-radius: 6px;
            background-color: #027294;
            text-transform: uppercase;
            color: #FFFFFF;
            align-items: center;
            justify-content: center;  
            text-decoration: none;
            gap: 5px;  
            cursor: pointer;
            transition: transform 0.3s ease;

          }
          button:hover{
            transform: scale(1.1);
          }
    }

    .itens{
        display: flex;
        width: 90%;
        align-items: center;
        justify-content: space-between;

        p{
           margin-left: 20px;
            color: #FFFFFF;
        }
    
        .arrow-icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-top: 2px solid #FFFFFF;
        border-right: 2px solid #FFFFFF;
        transform: rotate(45deg);
        transition: transform 0.3s ease;
      }
    }

    &.opened .arrow-icon {
      transform: rotate(-45deg);
    }

    &.opened {
      height: auto;
    }
`

export const ListProfile = styled.div`
    min-block-size: 100%;

table {
  min-width: 100%;
  padding: 15px 0px;
  border-spacing: 0px 5px;
  thead {
    tr{
      width: 100%;
    }
    td {
      font-weight: 700;
      font-size: 13px;
      color: #838383;
    }
  }
  tbody {
    div{
      display: flex;
    }
    tr {
      height: 50px;

      border-radius: 30px;
      &:hover {
        td {
          background-color: #e5e5e5;
        }
      }
      td {
        font-size: 12px;
        font-weight: 400;
        height: 50px;
        background-color: #eee;
        vertical-align: middle;
        &:first-child {
          border-radius: 10px 0px 0px 10px;
          padding-left: 10px;
        }
        &:last-child {
          border-radius: 0px 10px 10px 0px;
          text-align: left;
        }

        a {
          color: #027294;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }

        button {
          background-color: transparent;
          border: none;
          color: #027294;
          cursor: pointer;

          &:last-child {
            color: #646464;
          }
          &.profile {
            color: #027294;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      img {
        border-radius: 50%;
        width: 35px;
      }
    }
  }
}
.wd5{
  width: 5%; 
}
.wd10{
  width: 10%; 
}
.wd15{
  width: 15%;  
}
.wd20{
  width: 20%;  
}
.wd25{
  width: 25%;  
}
.wd30{
  width: 30%;  
}
.wd35{
  width: 35%;  
}
.wd34{
  width: 34%;  
}
.wd33{
  width: 33%;  
}
.wd40{
  width: 40%;  
}
.wd45{
  width: 45%;  
}
.wd50{
  width: 50%;  
}
.wd55{
  width: 55%;  
}
.wd60{
  width: 60%;  
}
.wd65{
  width: 65%;  
}
.wd70{
  width: 70%;  
}
.wd75{
  width: 75%;  
}
.wd80{
  width: 80%;  
}
.wd85{
  width: 85%;  
}  
`