import { learningRightsBNCC } from "../../../../../../Utils/Const";
import { IResource } from "../../../../../../types/types";

interface ChildEducationProps {
  resource: IResource;
}

const ChildEducation = ({ resource }: ChildEducationProps) => {
  const { bncc } = resource;

  const hasInformationChildEducation = Boolean(bncc?.ei);
  const phases = bncc?.ei?.years?.join(", ");

  return (
    <section>
      <h4>EDUCAÇÃO INFANTIL</h4>
      {hasInformationChildEducation ? (
        <>
          <p>Fase: {phases}</p>
          {/* <p>Fase: {bncc?.ei.years ? bncc?.ei.years?.join(","):"Não informado"}</p> */}
          <p>Direitos de aprendizagem: </p>
          {bncc?.ei.learning_rights
            ?.slice()
            .sort((item) => item.localeCompare(item))
            .map((learning) => {
              const learningObject = learningRightsBNCC[Number(learning) - 1];
              const name = `${learningObject.title}: ${learningObject.text}`;
             return <ul style={{ listStyle: "none" }}>
                <li>
                  <p>- {name}</p>
                </li>
              </ul>;
            })}
          <p>Objetivos de Aprendizagem: </p>
          {bncc?.skills ? (
            bncc?.skills?.map((skill) => (
              <ul style={{ listStyle: "none" }}>
                <li>
                  <p>- {skill}</p>
                </li>
              </ul>
            ))
          ) : (
            <p>Não informado</p>
          )}
          <br />
        </>
      ) : (
        <p>Educação infantil não informada.</p>
      )}
    </section>
  );
};

export default ChildEducation;
