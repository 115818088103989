import React from 'react'
import { Datas } from '../styles'
import { TResource } from '../../../../../types/types'

const Pop = (props: any) => {

    const resource:TResource = props.resource; 

  return (
    <Datas>
        <main>
            <b>POP</b>
            <p>FIca a dica:</p>
            <br />
            <p>Orintações:</p>
            <div></div>
            <p>Referências Similares:</p>
            <p>Referências de Conteúdo</p>
        </main>
    </Datas>
  )
}

export default Pop
