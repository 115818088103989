import styled from 'styled-components';

export const ContainerNewCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 80%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`

export const ContainerNewSubCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`