import React, { useContext, useRef, useState } from 'react'
import { FormContainer } from '../../styles'
import { FileDown, Trash2 } from 'lucide-react';
import DefaultImage from "./Images/images.png"
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource';

const FormThumbnails = () => {
    const {
        resource,
        handleFieldResource,
        fieldsRequireds,
        handleThumbnailsResource,
    } = useContext(ResourceContext);
    const { thumbnails } = resource;
    // const textButton = thumbnails.length ? "Alterar arquivo" : "Adicionar arquivo";
    const textButton =  "Adicionar arquivo(s)";

    const inputRef = useRef<HTMLInputElement>(null);
    // const urlImg = image ? URL.createObjectURL(image) : "";


    function onSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
    }
    function handleImg(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return;
        const selectedImages = e.target.files;

        if (selectedImages) {
            const imagesArray = Array.from(selectedImages);
            handleThumbnailsResource([...thumbnails, ...imagesArray]);
        }

    }

    // function handleDeleteImg() {
    //     deleteImgResource();
    // }

    const handleRemoveImage = (index: number) => {

        const newImages = [...thumbnails];
        newImages.splice(index, 1);

        handleThumbnailsResource(newImages)
    };


    return (
        <FormContainer onSubmit={onSubmit} id="formAuthor">

            <div className="item container-img">
                <input
                    ref={inputRef}
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={handleImg}
                    multiple
                />
                <span className="info-message">Selecione uma imagem ou ícone para miniatura do recurso.</span>
                <span
                    className="button-add-file"
                    onClick={() => inputRef.current?.click()}
                >
                    <FileDown className="icon" />
                    {textButton}
                </span>

                {/* {urlImg ? (
                    <div className="line">
                        <img src={urlImg} alt="" />
                        <Trash2 onClick={handleDeleteImg} className="icon-delete" />
                    </div>
                ) : (
                    <div className="empty-img">
                        <img src={DefaultImage} alt='imagem padrao' style={{ width: '90%', height: '90%' }} />
                    </div>
                )} */}
                {
                    thumbnails.length > 0

                        ?

                        <div className="showImages">
                            {thumbnails.map((image, index) =>
                                <div className="imageItem">
                                    <img
                                        key={index}
                                        src={URL.createObjectURL(image)}
                                        alt={`Image ${index}`}
                                        style={{ width: '200px', height: '200px', margin: '5px' }}
                                    />
                                    <Trash2
                                        size={16}
                                        color='red'
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleRemoveImage(index)}
                                    />
                                </div>
                            )}
                        </div>
                        :
                        <div className="empty-img">
                            <img src={DefaultImage} alt='imagem padrao' style={{ width: '90%', height: '90%' }} />
                        </div>
                }
            </div>
        </FormContainer>
    )
}

export default FormThumbnails
