import styled from 'styled-components'

export const Container = styled.div`
max-width: 950px;
margin: 0 auto;
`

export const Notice = styled.div`
  position: relative;
  > span.bg {
    background-color: var(--blue);
    width: 100%;
    height: 350px;
    border-radius: 8px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  section {
    /* background-color: var(--blue); */
    color: #fff;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 13px;
    gap: 20px;
    > div:first-child {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      > div:first-child {
        p {
          font-size: 13px;
        }
        h1 {
          font-size: 32px;
          text-transform: uppercase;
          font-weight: 400;
        }
      }
      > div:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;
        p {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          font-size: 13px;
        }
      }
    }
    > div:last-child {
      display: flex;
      gap: 20px;
      > div:first-child {
        flex: 55;
      }
      > div:last-child {
        flex: 40;
      }
    }
  }
`
export const Slide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  > div.image {
    width: 100%;

    img {
      width: 100%;
    }
  }
`

export const SelectClient = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 5px;
  > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    > div {
      flex: 1;
    }
    label {
      color: #fff;
    }
    p {
      margin-top: 15px;
      color: #fff;
    }
  }
  
  select {
    background-color: #0E3048;
    border: none;
    color: #fff;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 18px;
  }
`

export const SelectClientUnit = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 5px;
  > div:first-child {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 10px;
    > div {
      flex: 1;
    }
    label {
      color: #fff;
    }
    p {
      margin-top: 15px;
      color: #0E3048;
    }
  }
  
  select {
    background-color: #fff;
    color: #0E3048;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 18px;
  }
`

export const UsersOnline = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
`

export const UserChart = styled.div`
  flex: 1;
  > div.head {
    /* background-color: #00CAD1; */
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      background-color: #00cad1;
      width: 250px;
      padding: 0px 10px;
      border-radius: 30px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > span {
      content: '';
      background-color: #00cad1;
      height: 3px;
      border-radius: 100px;
      width: 100%;
      display: block;
      z-index: 2;
      top: 0;
      right: 0;
    }
  }
  > span.total {
    font-size: 13px;
    font-weight: 700;
    color: #646464;
    text-transform: uppercase;
  }
  > div.body {
    div.itemChart:nth-of-type(1) {
      border-bottom: 1px solid #ccc;
      div.qt {
        background-color: #6a2c91;
      }
    }
    div.itemChart:nth-of-type(2) {
      border-bottom: 1px solid #ccc;
      div.qt {
        background-color: #088a62;
      }
    }
    div.itemChart:nth-of-type(3) {
      div.qt {
        background-color: #fe8f2e;
      }
    }
  }
`

export const UserEngage = styled.div`
  flex: 1;
  display: flex;
  gap: 10px;
  > div:first-child {
    background-color: #6a2c91;
    color: #fff;
    > div.body {
      border-bottom: 3px solid #fff;
      p {
        border-top: 1px solid #fff;
      }
    }
  }
  > div:last-child {
    background-color: #fcbf1e;
    color: #6a2c91;
    > div.body {
      border-bottom: 3px solid #6a2c91;
      p {
        border-top: 1px solid #6a2c91;
      }
    }
  }
`

export const Use = styled.div`
  > div.head {
    /* background-color: #00CAD1; */
    display: flex;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 14px;
      color: #fff;
      background-color: #088a62;
      width: 470px;
      padding: 0px 10px;
      border-radius: 30px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > span {
      content: '';
      background-color: #088a62;
      height: 3px;
      border-radius: 100px;
      width: 100%;
      display: block;
      z-index: 2;
      top: 0;
      right: 0;
    }
  }
  > div.body {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
