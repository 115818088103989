import { useQueries, useQuery } from "react-query";
import { Queries } from "../../types/queries";
import AnoLetivoService from "../../Services/AnoLetivo.service";
import {
  getCurrentYear,
  isCreatedAcademicNextYear,
} from "../../Utils/Functions";

export function useListAcademicYears(filterActualYears: boolean = false) {
  return useQuery(
    Queries.LIST_YEARS,
    () =>
      AnoLetivoService.get().then((res) => {
        if (res?.success) {
          return res.data;
        }
        throw new Error(res?.message);
      }),
    {
      select(data) {
        if (filterActualYears) {
          if (isCreatedAcademicNextYear()) {
            return data.filter(
              (year) =>
                (getCurrentYear() + 1).toString() === year.year ||
                getCurrentYear().toString() === year.year
            );
          }
          return data.filter(
            (year) => getCurrentYear().toString() === year.year
          );
        }
        return data;
      },
      retry: true,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );
}
