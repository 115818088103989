import { FileDown, Trash2 } from "lucide-react";
import { useContext, useEffect, useRef } from "react";
import { Select } from "../../../../../Components/Form/Select";
import {
  OptionFormartResource,
  OptionLanguageResource,
  ResourceContext,
} from "../../../../../Contexts/Form/ContextResource";
import { FormContainer, Row } from "../../styles";

export function FormFormat() {
  const {
    resource,
    handleFieldResource,
    fieldsRequireds,
    removeFieldRequired,
  } = useContext(ResourceContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const errorFormat = fieldsRequireds.some((field) => field === "format")
    ? "Campo obrigatório"
    : null;
  const errorLanguage = fieldsRequireds.some((field) => field === "language")
    ? "Campo obrigatório"
    : null;

  function handleFormatResource(e: string) {
    if (errorFormat) removeFieldRequired("format");
    handleFieldResource("format", e);
  }

  useEffect(()=>{
    handleFieldResource("language", Object.keys(OptionLanguageResource)[0]);

  },[])

  function handleLanguageResource(e: string) {
    if (errorLanguage) removeFieldRequired("language");
    handleFieldResource("language", e);
  }
  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }


  return (
    <FormContainer onSubmit={onSubmit} id="formAuthor">
      <Row>
        <div className="item">
          <Select
            error={errorFormat}
            value={resource.format}
            onChange={handleFormatResource}
            flex="fx40"
            label="Formato*"
            inputId="formatRexource"
          >
            <option value=""></option>
            {Object.keys(OptionFormartResource).map((format) => (
              <option key={format} value={format}>
                {OptionFormartResource[format]}
              </option>
            ))}
          </Select>
          <Select
            error={errorLanguage}
            value={resource.language}
            onChange={handleLanguageResource}
            flex="fx40"
            label="Idioma do Recurso*"
            inputId="languageResource"
          >
            <option value=""></option>
            {Object.keys(OptionLanguageResource).map((format) => (
              <option key={format} value={format}>
                {OptionLanguageResource[format]}
              </option>
            ))}
          </Select>
        </div>
{/* 
        <div className="item container-img">
          <input
            ref={inputRef}
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleImg}
          />
          <span className="info-message">Selecione uma imagem ou ícone para miniatura do recurso.</span>
          <span
            className="button-add-file"
            onClick={() => inputRef.current?.click()}
          >
            <FileDown className="icon" />
            {textButton}
          </span>

          {urlImg ? (
            <div className="line">
              <img src={urlImg} alt="" />
              <Trash2 onClick={handleDeleteImg} className="icon-delete" />
            </div>
          ) : (
            <div className="empty-img"></div>
          )}
        </div> */}
      </Row>
    </FormContainer>
  );
}
