import React from 'react'
import { ListItem, RowItem } from '../../../Pages/Registration/ResourcesRegistration/Form2/Subforms/styles'
import { TagListed } from '../../../Utils/Types'
import LoadingBox from '../../LoadingBox'

interface Props {
    categories: TagListed[],
    isLoading: boolean
}



const ListTags = (props: Props) => {

    const { categories, isLoading } = props;

    return (
        <>
            {isLoading ? (<LoadingBox />)
                :
                <ListItem>
                    {categories.map((item)=>
                        <RowItem>
                            {item.name}
                        </RowItem>
                    )}
                </ListItem>
            }
        </>
    )
}

export default ListTags
