import { SearchIcon } from "lucide-react";
import { Divider } from "../../../../Components/Form/Divider";
import { Input } from "../../../../Components/Form/Input";
import { Select } from "../../../../Components/Form/Select";
import { AddStudentContainer, Box, ButtonAdd, Table } from "../styles";
import { useEffect, useState } from "react";
import UnidadeService from "../../../../Services/Unidade.service";
import TurmaService from "../../../../Services/Turma.service";

export function FormSeachStudents(props: any) {
    const [resultSearch, setResultSearch] = useState(false)
    const [units, setUnits] = useState<any>([]);
    const [listStudents, setListStudents] = useState<any>([]);
    const [classes, setClasses] = useState<any>([]);
    const [stagesClass, setStagesClass] = useState<any>([]);
    const [ListClassStageSelected, setListClassStageSelected] = useState<any>([]);
    const [unitSelected, setUnitSelected] = useState<any>([]);
    const [classSelected, setClassSelected] = useState<any>([]);
    const [stageSelected, setStageSelected] = useState<any>([]);
    const [studentsFilterSelected, setStudentsFilterSelected] = useState<any>([]);

    useEffect(() => {
        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnits(response.data)
            }
        })
    }, [])

    useEffect(() => {
        const input = {
            idInstitution: unitSelected.identifier,
            classID: classSelected.identifier
        }

        TurmaService.showClass(input).then((response) => {
            if (response.success) {
                setListStudents(response.data)
            } else {
                throw new Error(response?.message);
            }
        }).catch((e) => { });

    }, [unitSelected, classSelected]);

    useEffect(() => {
        const unitId = unitSelected.identifier;

        if (unitId !== "") {
            TurmaService.list({ idState: unitId }).then((response) => {
                if (response.success) {
                    setClasses(response.data);
                } else {
                    throw new Error(response?.message);
                }
            }).catch((e) => { });
        }

    }, [unitSelected]);

    useEffect(() => {
        const stagesArray = classes.map((classe: any) => classe.educationStage);
        setStagesClass(stagesArray);
    }, [classes]);

    useEffect(() => {
        const filteredClasses = classes.filter((classe: any) => {
            return classe.educationStage.identifier === stageSelected.identifier;
        });
        setListClassStageSelected(filteredClasses);
    }, [classes, stageSelected]);


    const toggleSelection = (student: any) => {
        setStudentsFilterSelected((prevSelected: any) => {
            if (prevSelected.includes(student)) {
                return prevSelected.filter(
                    (selectedStudent: any) => selectedStudent !== student
                );
            } else {
                return [...prevSelected, student];
            }
        });
    }

    const toggleSearch = () => {
        if (listStudents.students.length === 0) {
            alert("Turma sem estudante")
        } else {
            setResultSearch(true)
        }
    }

    const toggleConfirm = () => {
        if (studentsFilterSelected.length === 0) {
            alert("Nenhum estudante encontrado com essas informações.")
        } else {
            props.setStudentsSelected((prevStudentsSelected: any) => [...prevStudentsSelected, ...studentsFilterSelected]);
            props.onBack()
        }
    }

    return (
        <AddStudentContainer>
            <header>
                <p>Buscar Estudante</p>
            </header>
            <section>
                <Input
                    type="search"
                    label="Buscar Estudante"
                    color="#039bc9"
                    value={""} // função pra implementar
                    onChange={""} // função pra implementar
                />
                <Select
                    label="Unidade Educacional"
                    value={unitSelected.identifier}
                    onChange={(value: any) => setUnitSelected(units.find((unit: any) => unit.identifier === value))}
                >
                    <option value="">Selecione a unidade educacional</option>
                    {units.map((unit: any) => (
                        <option key={unit.identifier} value={unit.identifier}>{unit.name}</option>
                    ))}
                </Select>
                <Divider>
                    <Select
                        flex="fx50"
                        label="Etapa"
                        value={stageSelected.identifier}
                        onChange={(value: any) => setStageSelected(stagesClass.find((stage: any) => stage.identifier === value))}
                    >
                        <option value="">Selecione uma etapa</option>
                        {stagesClass.map((stage: any) => {
                            return (
                                <option key={stage.identifier} value={stage.identifier}>{stage.name}</option>
                            )
                        })}
                    </Select>
                    <Select
                        flex="fx50"
                        label="Nome da Turma"
                        value={classSelected.identifier}
                        onChange={(value: any) => setClassSelected(classes.find((classe: any) => classe.identifier === value))}
                    >
                        <option value="">Selecione uma turma</option>
                        {ListClassStageSelected.map((classe: any) => {
                            return (
                                <option key={classe.identifier} value={classe.identifier}>{classe.name}</option>
                            )
                        })}
                    </Select>
                </Divider>
                <ButtonAdd>
                    <button className="" hvr-icon-pop
                        onClick={() => toggleSearch()}
                    >
                        <div className="search-icon">
                            <SearchIcon className="hvr-icon" size={20} />
                        </div>
                    </button>
                </ButtonAdd>

                {resultSearch && (
                    <Box>
                        <Table>
                            <p>LISTA DE ESTUDANTE(S) ENCONTRADO(S)</p>
                            <table>
                                <thead>
                                    <tr>
                                        <td className='header-cell'>Nome</td>
                                        <td>Usuário</td>
                                        <td>Unidade Educacional</td>
                                        <td>Etapa</td>
                                        <td>Nome da Turma</td>
                                        <td>Status</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listStudents.students && (
                                        listStudents.students.map((student: any) => (
                                            <tr key={student.identifier}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        name="student"
                                                        checked={studentsFilterSelected.includes(student)}
                                                        onChange={() => toggleSelection(student)}
                                                    />
                                                    {student.name}
                                                </td>
                                                <td>{student.username}</td>
                                                <td>{unitSelected.name}</td>
                                                <td>{listStudents.educationStage.name}</td>
                                                <td>{listStudents.name}</td>
                                                <td>{student.active === 1 ? "ativo" : "inativo"}</td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </Table>
                    </Box>
                )}
            </section>

            <footer>
                <button onClick={props.onBack}>Cancelar</button>
                <button onClick={() => toggleConfirm()}>Ok</button>
            </footer>
        </AddStudentContainer>
    )
}