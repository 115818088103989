import React, { useEffect, useState } from 'react'
import { SuperBox } from '../../../../Components/SuperBox'
import { useQuery } from 'react-query'
import FuncionarioService from '../../../../Services/Funcionario.service'
import { formDate, formDate2, formatarCpf, formatarFone, formatedDate, formatedDateS, minutesToMilliseconds } from '../../../../Utils/Functions'
import { Employee, EmployeeEdit, Role } from '../../../../Utils/Types'
import { SpinnerIcon } from '../../../Login/styles'
import { Divider } from '../../../../Components/Form/Divider'
import { WizardStep } from '../../../../Components/Wizard'
import { Container, Main } from '../../../Registration/ClientRegistration/styles'
import { Input } from '../../../../Components/Form/Input'
import { Controller } from 'react-hook-form'
import { Controllers } from '../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import { Select } from '../../../../Components/Form/Select'
import RoleService from '../../../../Services/Role.service'
import UnidadeService from '../../../../Services/Unidade.service'
import { BoxResume } from '../../../../Components/Form/BoxResume'
import { ModalShort } from '../../../../Components/Modals/ModalShort'

interface Props {
    employee?: Employee,
    setForm?: any
}


const EditEmployee = ({ employee, setForm }: Props) => {

    const [name, setName] = useState<any>()
    const [roles, setRoles] = useState<any>([])
    const [roleSelected, setRoleSelected] = useState<any>()
    const [units, setUnits] = useState<any>([])
    const [unitSelected, setUnitSelected] = useState<any>()
    const [cpf, setCpf] = useState<any>()
    const [date, setDate] = useState<any>()
    const [phone, setPhone] = useState<any>()
    const [email, setEmail] = useState<any>()
    const [confirmEmail, setConfirmEmail] = useState<any>()
    const [title, setTitle] = useState("Funcionário")
    const [roleName, setRoleName] = useState<any>()
    const [openModal, setOpenModal] = useState(false)


    useEffect(() => {
        RoleService.get().then((response) => {
            if (response?.success) {
                setRoles(response.data)
            }
        })

        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnits(response.data)
            }
        })

        if (employee) {
            employee.user.roles?.map((item: Role) => {
                setRoleName(item.name)
                setRoleSelected(item.identifier)
            }
            )
            setName(employee.user.name)
            setCpf(employee.user.cpf)
            setDate(formDate(employee.user.birth_date))
            setPhone(employee.user.phone)
            setEmail(employee.user.email)
            setUnitSelected(employee.educationInstitution.identifier)
        }
    }, [])

    function verification() {
        if (
            name == "" ||
            cpf === "" ||
            date === "" ||
            phone === "" ||
            email === "" ||
            confirmEmail === ""
        ) {
            alert("Preencha todas as informações.")
        } else if (email !== confirmEmail) {
            alert("Os emails não correspondem.")
        } else {
            setSwitchForm(2)
        }
    }


    const [switchForm, setSwitchForm] = useState(1)

    function saveEmployee() {
        const data: EmployeeEdit = {
            name: name,
            cpf: cpf,
            birth_date: formDate2(date),
            email: email,
            phone: phone,
            role_id: roleSelected
        }

        FuncionarioService.editEmployee({
            data: data,
            idEmployee: employee?.identifier,
            idUnit: employee?.educationInstitution.identifier
        }).then((response) => {

            if (response.success) {
                setOpenModal(true)
            }
        })
    }
    return (
        <>
            <Container>
                <div className="box">
                    <Main>
                        <Divider>
                            {switchForm === 1 ? (
                                <WizardStep text="Funcionário" active />
                            ) : (
                                <WizardStep text="Funcionário" />
                            )}
                            {switchForm === 2 ? (
                                <WizardStep text="Revisar" active />
                            ) : (
                                <WizardStep text="Revisar" />
                            )}
                        </Divider>
                        {switchForm === 1 && (
                            <>
                                <Input
                                    label="Nome"
                                    type="text"
                                    flex="fx100"
                                    value={name}
                                    onChange={setName}
                                />
                                <Divider>
                                    <Input
                                        label="CPF"
                                        type="cpf"
                                        flex="fx33"
                                        value={cpf}
                                        onChange={setCpf}
                                    />
                                    <Input
                                        label="Data de nascimento"
                                        type="date"
                                        flex="fx33"
                                        value={date}
                                        onChange={setDate}
                                    />
                                    <Input
                                        label="Telefone"
                                        type="tel"
                                        flex="fx33"
                                        value={phone}
                                        onChange={setPhone}
                                    />
                                </Divider>
                                <Divider>
                                    <Input
                                        label="E-mail"
                                        type="email"
                                        flex="fx50"
                                        value={email}
                                        onChange={setEmail}
                                    />
                                    <Input
                                        label="Confirmar E-mail"
                                        type="email"
                                        flex="fx50"
                                        value={confirmEmail}
                                        onChange={setConfirmEmail}
                                    />
                                </Divider>
                                <Divider>
                                    <Select
                                        label="Perfil"
                                        flex="fx33"
                                        value={roleSelected}
                                        onChange={setRoleSelected}
                                        disabled={true}
                                    >
                                        {roles.map((item: any, index: number) =>
                                            <option
                                                value={item.identifier}
                                                key={index}
                                            >
                                                {item.name}
                                            </option>
                                        )}
                                    </Select>
                                    <Select
                                        label="Unidade"
                                        flex="fx40"
                                        value={unitSelected}
                                        onChange={setUnitSelected}
                                        disabled={true}
                                    >
                                        {units.map((item: any, index: number) =>
                                            <option
                                                value={item.identifier}
                                                key={index}
                                            >
                                                {item.name}
                                            </option>
                                        )}
                                    </Select>
                                </Divider>
                                <Controllers
                                    enablePrev={false}
                                    enableNext={true}
                                    functionCancel={() => setForm(1)}
                                    functionNext={() => verification()}
                                />
                            </>
                        )}
                        {switchForm === 2 && (
                            <>
                                <ModalShort
                                    type="success"
                                    isOpen={openModal}
                                    text="Funcionário alterado!"
                                    onRequestClose={() => {
                                        setOpenModal(!openModal)
                                        window.location.reload()
                                    }}
                                />
                                <BoxResume label={`Verifique se os dados do ${title} estão corretos.`}>
                                    <>
                                        <tr className="divider">
                                            <td>
                                                <span>Perfil: </span>
                                                <span className="span">{roleName}</span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>Nome:</span>
                                                {name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>CPF:</span>
                                                {formatarCpf(cpf)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>Data de Nascimento:</span>
                                                {formDate2(date)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>Telefone:</span>
                                                {formatarFone(phone)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span>Email:</span>
                                                {email}
                                            </td>
                                        </tr>
                                    </>
                                </BoxResume>
                                <Controllers
                                    enablePrev={true}
                                    enableNext={true}
                                    save={true}
                                    functionCancel={() => setForm(1)}
                                    functionPrev={() => setSwitchForm(1)}
                                    functionSave={() => saveEmployee()}
                                />
                            </>
                        )}
                    </Main>
                </div>
            </Container>
        </>
    )
}

export default EditEmployee
