import { CheckCircle, XIcon } from 'lucide-react'
import { Container, Controlls } from './styles'

export function ModalEdit(props: any) {
  return (
    <>
      {props.isOpen && (
        <Container>
          <p>Editar {props.title} <button className='hvr-pulse' onClick={() => props.onRequestClose()}> <XIcon size={30}/> </button></p>
          <div>
            {props.children}
            <Controlls>
              <button className='hvr-grow' onClick={() => props.onRequestClose()}>Cancelar</button>
              <button className='hvr-grow' onClick={() => props.onSave()}>Salvar Edição</button>
            </Controlls>
          </div>
        </Container>
      )}
    </>
  )
}
