import { SlidersHorizontal } from "lucide-react";
import { Divider } from "../../../../Components/Form/Divider";
import { Select } from "../../../../Components/Form/Select";
import { BoxAll, Container, Filter } from "./styles";
import { Input } from "../../../../Components/Form/Input";
import { ListReport } from "../../../Reports/ReportClient/styles";
import { useListResource } from "../../../Registration/ResourcesRegistration/hooks/useListResource";
import { useEffect } from "react";

export function LinkResource() {

    return (
        <Container>
            <div className="div-header">
                <p>Lista de recursos</p>
            </div>

            <BoxAll>

                <Filter>

                    <div className="filter">
                        <SlidersHorizontal size={14} />
                        <p>Filtros</p>
                    </div>

                    <Divider>
                        <Select
                            flex="fx33"
                            label="Etapa"
                        >
                        </Select>

                        <Select
                            flex="fx33"
                            label="Faixa etária ou ano"
                        >
                        </Select>

                        <Select
                            flex="fx33"
                            label="Componente Curricular"
                        >
                        </Select>
                    </Divider>

                    <div className="search">
                        <Input
                            type="search"
                            flex="fx65"
                            placeHolder="Pesquise por uma coleção"
                            // value={inputSearchView}
                            // onChange={setInputSearchView}
                            color="#039bc9"
                        />
                    </div>


                </Filter>


                <ListReport>

                    <table>

                        <thead>
                            <tr>
                                <td className="">Nome do recurso</td>
                                <td className="">Etapa</td>
                                <td className="">Faixa Etária</td>
                                <td className="">Componente Curricular</td>
                                <td className="">Caminho na Coleção atual</td>
                            </tr>

                        </thead>

                        <tbody>
                            <tr>
                                <td>Lorem Ipsum</td>
                                <td>Educação infantil</td>
                                <td>Bebês</td>
                                <td>O eu, o outro e nós</td>
                                <td>Categoria\sub1\sub2</td>
                            </tr>
                        </tbody>

                    </table>


                </ListReport>








            </BoxAll>


        </Container>
    )
}