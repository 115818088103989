import { Edit, ToggleLeft, Trash } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CallRegister } from "../../../Components/CallRegister";
import { Divider } from "../../../Components/Form/Divider";
import { Input } from "../../../Components/Form/Input";
import { ListDefault } from "../../../Components/ListDefault";
import { ModalEdit } from "../../../Components/Modals/ModalEdit";
import { SearchTable } from "../../../Components/SearchTable";
import { SuperBox } from "../../../Components/SuperBox";
import { api } from "../../../Services/api";
import { UnitRegistrationEdit } from "./Edit";
import { UnitProfile } from "./Profile";
import { Container, Main } from "./styles";
import { useCheckTime } from "../../../Components/LoginFunctions/Refresh";
import { LoadingScreen } from "../../../Components/Loading";

interface TypeUnit {
  email: string;
  identifier: string;
  name: string;
  phone: string;
  address: {
    address: string;
    number: string;
    complement: string;
    zipCode: string;
    neighborhood: string;
    city: string;
    state: string;
  };
}

export function ListUnit() {
  useCheckTime();

  const [modalEdit, setModalEdit] = useState(false);
  const [switchPage, setSwitchPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const [units, setUnits] = useState<TypeUnit[]>([]);
  const [switchPageList, setSwitchPageList] = useState(
    "/admin/education-institutions?limit=10&page=1"
  );

  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");
  const [selectedProfile, setSelectedProfile] = useState("");
  const navigate = useNavigate();


  const [inputSearchView, setInputSearchView] = useState("");
  const [unitsFiltered, setUnitsFiltered] = useState<any[]>([]);

  console.log(unitsFiltered)
  
  useEffect(()=>{

      const filteredUnits = units.filter((item: any)=> item.name.toLocaleLowerCase().includes(inputSearchView.toLocaleLowerCase()));

      setUnitsFiltered(filteredUnits);

  },[inputSearchView, units])


  useEffect(() => {
    api
      .get(switchPageList, {
        headers: {
          Authorization: `bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUnits(response.data.data);
        setPages(response.data.meta.links);
        setMetaTo(response.data.meta.to);
        setMetaTotal(response.data.meta.total);
        setIsLoading(false);
      })
      .catch((error) => {
        alert("Erro ao carregar os dados, tente novamente mais tarde");
        setIsLoading(false);
        window.location.reload();
      });
  }, [switchPageList]);


  const onEditUnit = (option: any) => navigate("/unidade/edit", { state: option });



  return (
    <LoadingScreen isLoading={isLoading}>
      <SuperBox
        switch={true}
        page={2}
        path="Cadastro &bull; Unidade Educacional"
      >
        <Container>
          <ModalEdit
            isOpen={modalEdit}
            onRequestClose={() => setModalEdit(false)}
            title="Unidade Educacional"
          >
            <Divider>
              <Input flex="fx100" label="Nome" inputId="nomeid" />
            </Divider>
            <Divider>
              <Input flex="fx50" label="Telefone" inputId="telid" type="tel" />
              <Input flex="fx50" label="Email" inputId="emailid" />
            </Divider>
            <Divider>
              <Input flex="fx20" label="CEP" inputId="cepid" type="cep" />
              <Input flex="fx70" label="Rua/Avenida" inputId="ruaid" />
              <Input flex="fx10" label="Número" inputId="ruaid" />
            </Divider>
            <Divider>
              <Input flex="fx60" label="Complemento" inputId="complementoid" />
              <Input flex="fx40" label="Bairro" inputId="bairroid" />
            </Divider>
            <Divider>
              <Input flex="fx60" label="Cidade" inputId="cidadeid" />
              <Input flex="fx40" label="Estado" inputId="estadoid" />
            </Divider>
          </ModalEdit>
          {switchPage === 1 && (
            <Main>
              <CallRegister
                page="Unidade Educacional"
                link="/unidade/registration"
                onBack={() => {
                  navigate("/");
                }}
              />
              <ListDefault
                pages={pages}
                changeGet={(v: any) => setSwitchPageList(v)}
                to={metaTo}
                total={metaTotal}
              >
                <thead>
                  <tr>
                    <td colSpan={6}>
                      <Input
                        flex="fx30"
                        type="search"
                        placeHolder="Pesquise por uma unidade"
                        color="#039bc9"
                        value={inputSearchView}
                        onChange={setInputSearchView}
                      />
                      <br />
                    </td>
                  </tr>
                  <tr>
                    <td className="wd25">Nome</td>
                    <td className="wd15">Telefone</td>
                    <td className="wd20">Email</td>
                    <td className="wd15">CEP</td>
                    <td className="wd15">Localidade</td>
                    <td className="wd10">Ação</td>
                  </tr>
                </thead>
                <tbody>
                  {unitsFiltered.map((unit) => {
                    return (
                      <tr key={unit.identifier}>
                        <td>
                          <button
                            className="profile"
                            type="button"
                            onClick={() => {
                              setSwitchPage(2);
                              setSelectedProfile(unit.identifier);
                            }}
                          >
                            {unit.name}
                          </button>
                        </td>
                        <td>{unit.phone}</td>
                        <td>{unit.email}</td>
                        <td>
                          {unit.address ? unit.address.zipCode : "Não definido"}
                        </td>
                        <td>
                          {unit.address
                            ? `${unit.address.city} - ${unit.address.state}`
                            : "Não definido"}
                        </td>
                        <td>
                          <button
                            onClick={() => onEditUnit(unit)}
                            className="hvr-grow"
                          >
                            <Edit />
                          </button>
                          <button
                            className="hvr-grow"
                            style={{ color: "green", marginLeft: "10px" }}
                          >
                            <ToggleLeft />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </ListDefault>
            </Main>
          )}
          {switchPage === 2 && (
            <UnitProfile
              back={() => setSwitchPage(1)}
              edit={() => setSwitchPage(3)}
              selected={selectedProfile}
            />
          )}
          {switchPage === 3 && (
            <UnitRegistrationEdit
              back={() => setSwitchPage(1)}
              selected={selectedProfile}
            />
          )}
        </Container>
      </SuperBox>
    </LoadingScreen>
  );
}
