import styled from "styled-components";


export const LinkView = styled.p`

color: #247292;

:hover{
    text-decoration: underline;
}


`