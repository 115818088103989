import React, { useContext } from 'react'
import { Subcategory } from '../../SubcategoryComponent/LevelOne'
import { ContainerLevel2, ContainerMain, ContainerNameLevel1, Level2 } from './styles'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'


interface Props {
    levelOneName: string
    subLevel2: Subcategory[]
}

const ComponentLevel2 = ({ levelOneName, subLevel2 }: Props) => {

    const {
        resource,
        removeCategoryCollectionSelects,
        addCategoryCollectionSelects,
    } = useContext(ResourceContext);

    const subcategorySelected = resource.collections.some(
        (collection) => subLevel2.some((sub2)=> collection.subcategory_id === sub2.identifier)
    );



    return (
        <ContainerMain>
            <ContainerNameLevel1>
                <p>{levelOneName}</p>
            </ContainerNameLevel1>
            <ContainerLevel2>
                {subLevel2.map((sub2) => {




                    return (
                        <Level2>
                            <div className="container-name-level-one">
                                <p>{sub2.name}</p>
                            </div>
                            <div className="container-input">
                                <input
                                    // onChange={handleCollectionSelected}
                                    checked={subcategorySelected}
                                    type="checkbox"
                                />
                            </div>
                        </Level2>
                    )
                }
                )}
            </ContainerLevel2>
        </ContainerMain>
    )
}

export default ComponentLevel2