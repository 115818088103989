import { Dispatch, SetStateAction, useState } from "react";
import { Upload } from "../styles";
import { Container, ContainerCategory, Radio } from "./styles";
import { api } from "../../../../Services/api";
import { ColorPicker } from "../../../../Components/Form/ColorPicker";
interface RegistrationProps {
    active: string;
    cancelRegiterPackage: () => void;
    handleChange: (event: any) => void
    cancelRegiterCategory: () => void;
    setpackageCategories: Dispatch<SetStateAction<any[]>>
    selectedOption: any;
}

export function RegistrationsPacoteAndCategory(
    { active, cancelRegiterPackage, handleChange, cancelRegiterCategory, setpackageCategories, selectedOption }: RegistrationProps) {

    const [img, setImg] = useState<any>("")
    const [packageName, setPackageName] = useState("");
    const [packageDescription, setPackageDescription] = useState("");
    const [categoryName, setCategoryName] = useState("")
    const [categoryDescription, setCategoryDescription] = useState("")
    const [backgroundColor, setBackgroundColor] = useState<string>("#000000");

    const [defaultSelect, setDefaultSelect] = useState(false)
    const [createNewCateg, setCreateNewCateg] = useState(false)

    const [error, setError] = useState("")

    // pacote padrão ou não
    function handleChangeDefault() {
        setDefaultSelect(true)
        if (defaultSelect === true) {
            setCreateNewCateg(false)
            return
        }
        setCreateNewCateg(false)
    }
    function handleChangeCreateNew() {
        setCreateNewCateg(true)
        if (createNewCateg === true) {
            setDefaultSelect(false)
            return
        }
        setDefaultSelect(false)
    }

    // imagem
    function handlePreview(e: any) {
        if (!e.target.files) return
        setImg(e.target.files[0])
    }

    // salvar pacote
    async function addPackage() {
        if (packageName !== "") {
            try {
                const data = new FormData();
                data.append("name", packageName);
                data.append("description", packageDescription);
                data.append("logo", img);

                const response = await api.post(`/admin/packages`, data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                        "Content-Type": "multipart/form-data",
                    },
                });

                const newCollectionId = response.data.data.identifier;
                handleChange(newCollectionId);
                cancelRegiterPackage()


            } catch (error) {
                throw new Error("Can not create collection.")
            }

        } else {
            setError("#ff0000")
        }
    }

    // salvar categoria
    const addCategory = () => {
        if (categoryName !== "") {
            api
                .post(
                    `/admin/packages/${selectedOption}/categories`,
                    {
                        name: categoryName,
                        description: categoryDescription,
                        color: backgroundColor,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("token")}`,
                        },
                    }
                ).then(response => {
                    setpackageCategories((prevCategories) => [...prevCategories, response.data.data]);
                    cancelRegiterCategory();
                })
        } else {
            setError("#ff0000")
        }
    }

    return (
        <>
            {active === "package" && (
                <Container>
                    <header>
                        <p>Cadastrar Novo Pacote</p>
                    </header>

                    <section>
                        <Upload>
                            <label htmlFor="uploadInput">
                                {img === "" ? (
                                    <p >Clique aqui para fazer o Upload da Logo</p>
                                ) : (
                                    <img src={URL.createObjectURL(img)} alt="" />
                                )}
                            </label>
                            <input
                                type="file"
                                name=""
                                id="uploadInput"
                                onChange={(e) => handlePreview(e)}
                            />
                            <div className="text-type">
                                <p>Formato JPG ou PNG, 160 x 40 px.</p>
                            </div>
                        </Upload>

                        <div className='flex-label'>
                            <label htmlFor="" style={{ color: error }}>Nome do Pacote</label>
                        </div>
                        <div className='flex-inputs'>
                            <input type="text" style={{ border: `1px solid ${error}` }} onChange={(e: any) => setPackageName(e.target.value)} value={packageName} />
                        </div>
                        <div className='space' />
                        <label htmlFor="">Descrição</label>
                        <textarea className="multi" onChange={(e: any) => setPackageDescription(e.target.value)} value={packageDescription} />

                        <Radio>
                            <div className="">
                                <p>Qual modelo de categorização você deseja utilizar na coleção?</p>
                                <div className="radio-inputs">
                                    <label htmlFor="">
                                        <input
                                            type="checkbox"
                                            checked={defaultSelect}
                                            onChange={() => handleChangeDefault()}
                                        />
                                        Padrão (<i>recomendado</i>)
                                    </label>
                                    <label htmlFor="">
                                        <input
                                            type="checkbox"
                                            checked={createNewCateg}
                                            onChange={() => handleChangeCreateNew()}
                                        />
                                        Criar uma nova
                                    </label>
                                </div>
                            </div>
                        </Radio>
                    </section>
                    <footer>
                        <button className="hvr-grow" onClick={() => cancelRegiterPackage()}>
                            Cancelar
                        </button>
                        <button className="hvr-grow" onClick={() => addPackage()}>
                            Salvar
                        </button>
                    </footer>
                </Container>
            )}

            {active === "category" && (
                <ContainerCategory>
                    <header>
                        <p>Cadastrar nova Categoria</p>
                    </header>
                    <section>
                        <div className='flex-label'>
                            <label style={{ color: error }}>Nome da Categoria</label>
                            <label htmlFor="" >Definir cor</label>
                        </div>
                        <div className='flex-inputs'>
                            <input type="text" style={{ border: `1px solid ${error}` }} onChange={(e: any) => setCategoryName(e.target.value)} value={categoryName} />
                            <div className="colorPicker"> <ColorPicker onChange={setBackgroundColor} />
                            </div>
                        </div>
                        <div className='space' />
                        <label htmlFor="">Descrição</label>
                        <textarea className="multi" onChange={(e: any) => setCategoryDescription(e.target.value)} value={categoryDescription} />
                    </section>
                    <footer>
                        <button className="hvr-grow" onClick={() => cancelRegiterCategory()}>
                            Cancelar
                        </button>
                        <button className="hvr-grow" onClick={() => addCategory()}>
                            Salvar
                        </button>
                    </footer>
                </ContainerCategory>
            )}
        </>
    )
}