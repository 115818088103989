import React from 'react';
import ReactDOM from 'react-dom/client'
import App from './App'
import { StoreProvider } from './Contexts/store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  setupStrictMode(
    <StoreProvider>
      <App />
    </StoreProvider>
  )
)

function setupStrictMode(children: React.ReactNode): React.ReactNode {
  if (process.env.NODE_ENV === 'development') {
    console.log('StrictMode disabled')
    return children
  }
  return <React.StrictMode>{children}</React.StrictMode>
}
