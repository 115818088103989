import styled from "styled-components";

export const ContainerEditor = styled.div`

width: 70%;
height: auto;
display: flex;
justify-content: center;
align-items: flex-start;
gap: 5px;
flex-direction: column;

`