import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileDatas } from "../../../../Components/ProfileDatas";
import ResourceService from "../../../../Services/Resouce.service";
import { IResource, TResource } from "../../../../types/types";
import Bncc from "./BNCC";
import BasicInformations from "./BasicInformations";
import Pop from "./POP";
import TemplateResource from "./TemplateResource";
import Footer from "./footer";
import { Container, Datas } from "./styles";

interface ResorceProps {
  resorce: TResource | undefined;
  back: any;
  edit: any;
  resourceId?: string;
}

export function ResorceProfile(props: ResorceProps) {
  const { back, edit, resorce, resourceId } = props;

  const navigate = useNavigate();
  const [resource, setResource] = useState<IResource>();

  useEffect(() => {
    ResourceService.showResource({ identifier: resourceId }).then((res) => {
      if (res?.success) {
        console.log(res.data.data);
        setResource(res.data.data);
      }
    });
  }, []);


  const [page, setPage] = useState<number>(1);

  const Tags = () => {
    return (
      <Datas>
        <main>
          <b>TAGS</b>
          <p>Categoria:</p>
          <p>Nome:</p>
          <p>Sugestão:</p>
        </main>
      </Datas>
    );
  };

  return (
    <>
      <Container>
        <ProfileDatas
          page="Recursos"
          onBack={() => back()}
          onEdit={() => edit()}
          labelSingle="Recurso"
          labelPlural="Recursos"
        />

        {page === 1 && (
          <BasicInformations resource={resorce} resourceId={resourceId} />
        )}
        {page === 2 && <Bncc resource={resource} />}
        {/* {page === 3 && (
          <Bncc2 resource={resource}/>
        )} */}

        {page === 3 && <Tags />}

        {page === 4 && <Pop resource={resorce} />}

        {page === 5 && <TemplateResource />}

        <Footer page={page} setPage={setPage} />
      </Container>
    </>
  );
}
