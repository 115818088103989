import { useQuery } from "react-query";
import RoleService from "../../Services/Role.service";
import { Queries } from "../../types/queries";

export function useListRoles() {
  return useQuery(
    Queries.LIST_ROLES,
    () =>
      RoleService.get(),
    {
      retry: true,
      cacheTime: 5 * 60 * 1000,
      refetchInterval: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 5 * 60 * 1000,
    }
  );
}
