import { SlidersHorizontal } from "lucide-react"
import { Input } from "../../../../Components/Form/Input"
import {  Container, Divider } from "./styles"
import ReactSelect from "../../../../Components/Form/ReactSelect/ReactSelect"
import { useEffect, useState } from "react"
import RoleService from "../../../../Services/Role.service"
import { Client, Role } from "../../../../Utils/Types"
import ClienteService from "../../../../Services/Cliente.service"
import { ListDefault } from "../../../../Components/ListDefault"
import { ReportService, User, userData } from "../../../../Services/Report.service"
import BondTable from "./BondTable/BondTable"

const UserGeneralReport = () => {

  const [roles, setRoles] = useState<Role[]>([])
  const [clients, setClients] = useState<Client[]>([])

  const [switchPageList, setSwitchPageList] = useState(
    "/admin/clients?limit=10&page=1"
  );
  const [pages, setPages] = useState([{ url: "", label: "1", active: true }]);
  const [metaTo, setMetaTo] = useState("0");
  const [metaTotal, setMetaTotal] = useState("0");

  const [allUsers, setAllUsers] = useState<User[]>(userData)

  useEffect(() => {
    RoleService.get().then((res) => {
      if (res?.success) {
        setRoles(res.data)
      }
    })

    ClienteService.get({ path: `/admin/clients` }).then((res) => {
      if (res?.status === 200) {
        setClients(res.data.data)
      }
    })

    // ReportService.listAllUsers({ dryRun: true }).then((res) => {
    //   if (res) {
    //     setAllUsers((state: any) => {
    //       return [...state, res]
    //     })
    //   }
    // })
  }, [])


  return (
    <Container>
      <Input
        flex="fx40"
        type="search"
        color="#039bc9"
      // value={inputSearchView}
      // onChange={setInputSearchView}
      />
      <div className="message">
        <span>FILTROS</span>
        <SlidersHorizontal size={14} />
      </div>

      <Divider>
        <ReactSelect
          label="Perfil de Usuário/Função"
          placeholder="Selecione..."
          flex="fx33"
          options={roles}
          getOptionLabel={(e) => e.name}
        />
        <ReactSelect
          label="Clientes"
          placeholder="Selecione..."
          flex="fx40"
          options={clients}
          getOptionLabel={(e) => e.socialName}
        />
      </Divider>
      <Divider>
        <BondTable/>
      </Divider>
      <div className="list">

        <ListDefault
          pages={pages}
          changeGet={(v: any) => setSwitchPageList(v)}
          to={metaTo}
          total={metaTotal}
        >
          <thead>
            <tr key={""}>
              <td className="wd10">Nome</td>
              <td className="wd10">Usuário / Email</td>
              <td className="wd10">Função / Perfil</td>
              <td className="wd10">Cliente</td>
              <td className="wd10">Unidade(s) Educacional(is)</td>
              <td className="wd10">Pontuação</td>
              <td className="wd10">Acessos</td>
            </tr>
          </thead>
          <tbody>
            {
              allUsers.map((user)=>{
                return(
                  <tr key={user.Nome}>
                    <td>{user.Nome}</td>
                    <td>{user.Email}</td>
                    <td>{user.Perfil}</td>
                    <td>{user.Cliente}</td>
                    <td>{user["Unidade(s) Educacional(is)"]}</td>
                    <td>{user.Pontuação}</td>
                    <td>{user.Acessos}</td>
                  </tr>
                )
              })
            }
          </tbody>
        </ListDefault>
      </div>
    </Container>
  )
}

export default UserGeneralReport