import styled from "styled-components";

export const Container = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
flex-direction: column;
width: 100%;
gap: 5px;
.message{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
    margin-top: 5px;
    span{
        font-size: 14px;
        line-height: 19.1px;
        font-weight: bold;
        color: #646464;
    }
}

.list{
    width: 100%;
    margin-top: 10px;
}
`

export const Divider = styled.div`
display: flex;
align-items: flex-start;
gap: 20px;
width: 100%;

`