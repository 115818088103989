import styled from 'styled-components';

export const Box = styled.div`
display: flex;
flex-direction: column;
gap: 10px;
`;

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
`;

export const Button = styled.div`
label{
font-size: 16px;
background-color: #646464;
border-radius: 15px;
height: 45px;
display: flex;
align-items: center;
justify-content: center;
width: 235px;
color: #fff;
text-transform: uppercase;
gap: 5px;
cursor: pointer;

}
input{
display: none;
}
`;

export const Controls = styled.div`
display: flex;
gap: 20px;
button{
    font-size: 16px;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-weight: 700;
    color: #FE8F2E;
    cursor: pointer;
    &:last-child{
        color: #088A62;
    }
}
`;

export const Table = styled.div`
border: 1px solid #646464;
border-radius: 15px;
>p{
width: 100%;
text-align: center;
padding: 10px 0px;
}
table{
    width: 100%;
    border-spacing: 0px;
    thead{
        td{
            text-align: center;
            &.fx10{
                width: 10%;
            }
            &.fx15{
                width: 15%;
            }
            &.fx20{
                width: 20%;
            }
            &.fx60{
                width: 60%;
            }
            &.fx70{
                width: 70%;
            }
            &.fx75{
                width: 75%;
            }
        }
    }

    tbody{
        td{
            text-align: center;
            &.text-left{
                text-align: left;
                padding-left: 25px;
            }
        }
    }

    td{
        padding: 5px;
        font-size: 14px;
        color: #646464;
        border-top: 1px solid #646464;
        border-left: 1px solid #646464;
        &:first-child{
            border-left: none;
        }
        &:last-child{
            button{
                color: #027294;
                background-color: transparent;
                border: none;
                cursor: pointer;
                &:last-child{
                    color: red;
                    margin-left: 5px;
                }
            }
        }
    }
}
`;