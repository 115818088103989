import styled from 'styled-components';

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
`;

export const Sitemap2 = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #646464;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Container = styled.section`
  display: flex;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;

  >div.box{
  width: 100%;
  padding: 5px 30px;
  }

  .bt-cadastr {
    position: relative;
    top: 2px;
    margin-left: 25px;
    background-color: #027294;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 40px;
    border-radius: 10px;
    width: 176px;
    border: none;
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.3s ease;
  }
  .bt-cadastr:hover {
    transform: scale(1.1);
    color: #ffffff;
  }

  div.logo{
          width: 160px;
          height: 40px;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #ccc;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
        }

        tr{
          cursor: pointer;
        }
  `;

export const Main = styled.main`
      width: 100%;
      margin: 0 auto;
  `;

export const SelectCollection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    > div:first-child {
      display: flex;
      width: 100%;
      align-items: flex-end;
      justify-content: flex-start;
      gap: 10px;
      > div {
        width: 100%;
      }
      label {
        color: #646464;
      }
      p {
        margin-top: 15px;
      }
    }
    button {
      width: 30px;
      background-color: transparent;
      border: none;
      cursor: pointer;
      color: #FE8F2E;
      padding-bottom: 6px;
    }

    .grow { 
      transition: all .2s ease-in-out; 
      }
      
    .grow:hover { 
    transform: scale(1.5); 
    }
  `;

export const Abas = styled.div`
      button{
          height: 40px;
          width: 100%;
          font-size: 20px;
          text-transform: uppercase;
          color: #fff;
          background-color: #027294;
          border: none;
          border-radius: 10px;
      }
  `;

export const ContainerNew = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
background-color: #FE8F2E;
color: #F8F8FA;
text-align: center;
border-radius: 10px 10px 0px 0px;
height: 82px;
font-size: 20px;
display: flex;
align-items: center;
justify-content: center;
width: 100%;
text-transform: uppercase;
}

section {
padding: 20px 0px;
width: 90%;

}

input{
height: 40px;
border-radius: 15px;
border: 1px solid black;
width: 100%;
padding-left: 10px;

}

.flex-label {
display: flex;
justify-content: space-between;
}

.flex-inputs {
display: flex;
justify-content: space-between;
}


textarea{
height: 40px;
border-radius: 15px;
border: 1px solid black;
width: 100%;
padding-left: 10px;
padding-top:10px;
height: 100px;
}

label{
font-size: 14px;
line-height: 19px;
color: #646464;
}

.space{
margin-top: 10px;
}

.colorPicker {
position: relative;
left: 10px;
}

footer {
height: 90px;
display: flex;
align-items: center;
position: relative;
left: 40%;
gap: 10px;
width: 420px;
> button {
  height: 40px;
  width: 110px;
  border-radius: 5px;
  border: none;
  background: #088A62;
  color: #fff;
  text-transform: uppercase;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:first-child{
    background: #838383;
  }
}
}
`

export const ContainerNewCategory = styled.div`
  margin-left: 25%;
 `;

export const Upload = styled.div`
display: flex;
margin-bottom: 20px; 

>label{
margin-right: 10px;
background-color: #646464;
display: flex;
height: 39px;
width: 160px;
align-items: center;
justify-content: center;
color: #fff;
font-weight: 700;
font-size: 16px;
cursor: pointer;
overflow: hidden;

p{
    text-align: center;
    font-size: 11px;
}
}

>input{
display: none;
}

.text-type{
    height: 39px;
    width: 160px;

    p{
        font-size: 14px;
    }
}
`;

export const Radio = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #646464;
    margin-top: 25px;

    .radio-inputs {
        display: flex;
        
        label{
            align-items: center;
            margin-right: auto;

            input{
                margin-right: 5px;
                vertical-align: middle;
                width: 17px;
            }
        }
    }
`;

export const ContainerCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 80%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

.colorPicker {
  position: relative;
  left: -10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`
