import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import {
  ClassFields,
  RegistrationContext,
} from "../../../../Contexts/Form/RegistrationClassContext";
import TurmaService from "../../../../Services/Turma.service";
import { Form } from "../styles";

interface ResumeProps {
  prev: () => void;
}

function DetailsClass({
  ageRange,
  modality,
  name,
  phase,
  step,
  year,
}: ClassFields) {

  return (
    <>
      <tr className="divider">
        <td>
          <span>Turma:</span>
          {name}
        </td>
      </tr>
      <tr>
        <td>
          <span>Ano Letivo:</span>
          {year?.year ?? "Não informado"}
        </td>
      </tr>
      <tr>
        <td>
          <span>Modalidade:</span>
          {modality ? modality.join(", " ) : "Não informada"}
        </td>
      </tr>
      <tr>
        <td>
          <span>Etapa:</span>
          {step ? step.name : "Não informada"}
        </td>
      </tr>
      <tr>
        <td>
          <span>Fase:</span>
          {phase ? phase.name : "Não informada"}
        </td>
      </tr>
      <tr>
        <td>
          <span>Ano/Faixa etária:</span>
          {ageRange ? ageRange.year : "Não informada"}
        </td>
      </tr>
    </>
  );
}

export function Resume({ prev }: ResumeProps) {
  const [modalSave, setModalSave] = useState(false);
  const navigate = useNavigate();
  const { edit, classFields, idClass, listClass } =
    useContext(RegistrationContext);

    console.log(classFields)


  const onBack = () => navigate(-1);
  const [isLoading, setIsLoading] = useState(false);
  const message = edit ? "editou" : "cadastrou";
  const messageSucess = edit
    ? "Edição realizada com sucesso"
    : "Cadastro salvo com sucesso";

  function parseData(data: ClassFields ) {

    const {
      name: classNameValue,
      year: yearValue,
      modality: modalityValue,
      phase: phaseValue,
      step: stepValue,
      ageRange: ageRangeValue,
    } = data;

    return {
      name: classNameValue,
      academic_year: parseInt(yearValue!.year),
      modality: modalityValue , // modality: modalityValue ? modalityValue : "",
      phase: phaseValue ? phaseValue.identifier : "",
      stage_id: stepValue ? stepValue.identifier : "",
      year_id: ageRangeValue ? ageRangeValue.identifier : "",
    };
  }
  async function saveClass() {
    if (isLoading) return;
    setIsLoading(true);
    if (edit && idClass) {
      const data = parseData(classFields);
      if (!data) return;
      TurmaService.update(data, idClass)
        .then((response) => {
          if (response?.success) {
            setModalSave(true);
          } else {
            throw response?.message;
          }
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    } else {
      if (listClass.length > 0) {
        Promise.all(
          listClass.map((item) => {
            const itemData = parseData(item);
            return TurmaService.create(itemData!);
          })
        )
          .then(() => {
            setModalSave(true);
          })
          .catch(() => {
            alert("Erro ao cadastrar turma");
          })
          .finally(() => setIsLoading(false));
      } else {
        const data = parseData(classFields);
        if (!data) return;
        TurmaService.create(data)
          .then((response) => {
            if (response?.success) {
              setModalSave(true);
            } else {
              throw response?.message;
            }
          })
          .catch(() => {})
          .finally(() => setIsLoading(false));
      }
    }
  }

  return (
    <Form>
      <ModalShort
        type="success"
        text={messageSucess}
        isOpen={modalSave}
        onRequestClose={() => navigate("/turma/list")}
        onConfirm={() => {
          return;
        }}
      />
      <BoxResume
        label={`Verifique se os dados que você ${message} estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.`}
      >
        {listClass.length > 0 ? (
          listClass.map((item, index) => <DetailsClass key={index} {...item} />)
        ) : (
          <DetailsClass {...classFields} />
        )}

        {/* <tr className="divider">
          <td>
            <span>Relação de estudantes da turma</span>
          </td>
        </tr>
        <tr>
          <td>
            <p>Teste</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Teste</p>
          </td>
        </tr>
        <tr className="divider">
          <td>
            <span>Professor(es) da turma</span>
          </td>
        </tr>
        <tr>
          <td>
            <p>Teste</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>Teste</p>
          </td>
        </tr> */}
      </BoxResume>
      <Controllers
        save={true}
        functionPrev={() => prev()}
        functionSave={() => saveClass()}
        functionCancel={onBack}
        isRequisition={isLoading}
        enableNext={true}
        enablePrev={true}
      />
    </Form>
  );
}
