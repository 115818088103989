import styled from 'styled-components';

import bgImg from '../../../Assets/not-auth.svg';

export const Container = styled.div`
background-image: url(${bgImg});
min-height: 100vh;
height: 100%;
background-size: cover;
background-position: center center;

display: flex;
align-items: center;
justify-content: center;
>div{
    width: 360px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
p{
text-align: center;
font-size: 25px;
}
button{
height: 50px;
background-color: transparent;
color: #000;
width: 100%;
text-transform: uppercase;
font-size: 15px;
border: 3px solid #000;
cursor: pointer;
transition: 0.4s;
font-weight: 500;
&:hover{
    background-color: #000;
    color: #fff;
}
}
}
`;