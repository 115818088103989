import styled from "styled-components";


export const DivFlex = styled.div`
display: flex;

p{
    margin-right: 10px;
}
label{
    margin-left: 10px;
    margin-right: 10px;
}
`