export const enum Queries {
    LIST_COLLECTION = "LIST_COLLECTION",
    LIST_CATEGORIES_COLLECTION = "LIST_CATEGORIES_COLLECTION",
    LIST_RESOURCES = "LIST_RESOURCES",
    LIST_PLATAFORMS = "LIST_PLATAFORMS",
    LIST_TARGETS_AUDIENCE = "LIST_TARGETS_AUDIENCE",
    LIST_BNCC = "LIST_BNCC",
    LIST_YEARS = "LIST_YEARS",

    LIST_ACADEMIC_STAGE = "LIST_ACADEMIC_STAGE",
    LIST_ACADEMIC_RANGE = "LIST_ACADEMIC_RANGE",

    LIST_PHASES = "LIST_PHASES",

    LIST_ROLES = "LIST_ROLES",

    LIST_STUDENTS = "LIST_STUDENTS",

    DETAILS_CLASS = "DETAILS_CLASS",

    LIST_CATEGORIES_TAG = "LIST_CATEGORIES_TAG",

    LIST_BNCC_EI = "LIST_BNCC_EI",

    LIST_BNCC_EF = "LIST_BNCC_EF",

    LIST_BNCC_STAGES = "LIST_BNCC_STAGES",

    LIST_BNCC_PHASES = "LIST_BNCC_PHASES",
    
    LIST_BNCC_YEARS = "LIST_BNCC_YEARS"
}

export const enum QueriesResource {
    SHOW_RESOURCE = "SHOW_RESOURCE"
}