import styled from "styled-components";

interface GetWidthProps {
  $width?: string;
}

export const DivColumnFlexStart = styled.div<GetWidthProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${(props) => props.$width};

  p {
    text-align: center;
    font-size: 18px;
  }
`;
interface ItemProps {
  $color: string;
}

export const ListKnoledge = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;
export const RowKnowledge = styled.div<ItemProps>`
  border-bottom: 1px solid #646464;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1rem;
  .info-knowledge {
    flex-basis: 375px;
  }
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    background-color: ${({ $color }) => $color};
    border-radius: 100%;
    color: white;
    border: none;
    cursor: pointer;
  }
`;
export const ItemKnowledge = styled.div<ItemProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.$color};
  text-transform: uppercase;
  color: white;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 8px 8px 0px 0px;
  padding: 0.5rem 10px 0.25rem 10px;
  border-radius: 8px 8px 0px 0px;
  border: 1px solid #646464;
  border-bottom: none;
  flex-basis: 264px;
  max-width: 265px;
  gap: 0.5rem;

  input[type="checkbox"] {
    cursor: pointer;
  }

  .name {
    flex: 1;
    text-align: start;
  }

  .arrow {
    cursor: pointer;
  }

  .close {
    transform: rotate(0);
  }

  .open {
    transform: rotate(180deg);
  }
`;

export const TableKnowledge = styled.table`
  width: 100%;
  border-spacing: 0px;
  color: #646464;

  tr td:first-child {
    border-left: none;
  }
  tr td:last-child {
    border-right: none;
  }

  tbody:last-child tr td {
    border-bottom: 1px solid #646464;
  }

  td {
    border: 1px solid #646464;
    border-right: none;
    border-bottom: none;
    vertical-align: top;
  }
  thead td {
    vertical-align: middle;
    text-align: center;
  }
  .td-item {
    width: 100%;
    display: flex;
    justify-content: start;
    flex-direction: column;
  }

  .option-table {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.75rem;
    margin: 0.25rem 0;

    input[type="checkbox"] {
      cursor: pointer;
    }
  }

  .item-select {
    border-bottom: 1px solid #646464;
  }
  .cell-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
  }
  thead {
    .cell-row {
      justify-content: center;
    }
    background-color: #e9e9e9;
    tr {
      height: 30px;
    }
  }

  th {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  tr + tr,
  th + th {
    border-left: 1px solid #646464;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    background-color: #fe9d48;
    cursor: pointer;
  }
`;

export const ListItem = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
  .select {
    background-color: #c6eff2 !important;
  }
`;

export const RowItem = styled.li`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  gap: 1rem;

  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  width: 100%;

  :hover {
    cursor: pointer;
    background-color: #e9e9e9;
  }
`;

interface ColumnProps {
  $gap?: string;
}
export const Column = styled.div<ColumnProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #646464;
  width: 100%;
  gap: ${({ $gap }) => $gap || "0"};
  input[type="checkbox"] {
    cursor: pointer;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .width-full {
    width: 100%;
  }

  .title {
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .sub-title {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .mt {
    margin-top: 0.5rem;
  }
  .gap {
    gap: 0.5rem;
  }
  .row {
    display: flex;
  }

  li {
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    list-style: none;
  }

  .icon-modal {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    height: 30px;
    width: 30px;
    border-radius: 100%;
    background-color: #fe9d48;
    cursor: pointer;
    :hover {
      background-color: #f7a863;
    }
  }
`;

export const Title = styled.span`
  color: #646464;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
