import { Dispatch, SetStateAction } from "react";
import { Input } from "../../../../../Components/Form/Input";
import { ContainerComponentResponsible, DivInputs } from "./styles";

interface ComponentResponsibleProps {
    idade: number
    name: string
    setName: Dispatch<SetStateAction<string>>
    email: string
    setEmail: Dispatch<SetStateAction<string>>
    confirmEmail: string
    setConfirmEmail: Dispatch<SetStateAction<string>>
    phone: string
    setPhone: Dispatch<SetStateAction<string>>
}

const ComponentResponsible = ({ idade, confirmEmail, email, name, phone, setConfirmEmail, setEmail, setName, setPhone }: ComponentResponsibleProps) => {
    return (
        <>
            {
                idade < 18 ? (
                    <ContainerComponentResponsible>
                        <p className="title">Responsável</p>
                        <DivInputs>
                            <Input
                                value={name}
                                onChange={setName}
                                type="text"
                                flex="fx60"
                                label="Nome do responsável"
                            />

                            <Input
                                value={phone}
                                onChange={setPhone}
                                type="tel"
                                flex="fx40"
                                label="Telefone"
                            />
                        </DivInputs>

                        <DivInputs>
                            <Input
                                value={email}
                                onChange={setEmail}
                                type="email"
                                flex="fx50"
                                label="E-mail"
                            />
                            <Input
                                value={confirmEmail}
                                onChange={setConfirmEmail}
                                type="email"
                                flex="fx50"
                                label="Confirmar E-mail"
                            />
                        </DivInputs>
                    </ContainerComponentResponsible>
                ) : (
                    null
                )
            }
        </>
    )
}

export default ComponentResponsible;
