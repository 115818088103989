import styled from 'styled-components'
export const Container = styled.header`
  display: flex;
  font-size: 14px;
  color: #646464;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  height: 60px;
  align-items: center;

  .unit {
    order: 2;
    margin-left: auto;
    margin-right: 10px;
  }

  .icons {
    order: 3;
  }
`

export const Icons = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  > button {
    width: 30px;
    height: 30px;
    border: none;
    background-color: var(--blue);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    transition: 0.3s;
    cursor: pointer;
    span {
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: red;
      border-radius: 50%;
      top: 0;
      right: 0;
    }
  }

  > div.notiications {
    width: 210px;
    background-color: #e9e9e9;
    position: absolute;
    top: 105%;
    right: 0;
    z-index: 1;
    height: 200px;
    overflow-y: scroll;
    padding: 12px;
    border-radius: 10px;
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.72);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.72);
    display: none;
    transition: 0.3s;

    > button {
      border: none;
      background-color: transparent;
      padding: 3px 0px;
      border-bottom: 0.5px solid #646464;
      cursor: pointer;
      p {
        font-size: 12px;
        font-weight: 700;
        color: var(--blue);
        text-align: left;
      }
      span {
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 2; // Quantidade de linhas
        -webkit-box-orient: vertical;
        font-size: 10px;
        font-weight: 400;
        text-align: left;
        color: var(--blue);
        max-lines: 2;
      }
      &:hover {
        text-decoration: underline;
      }
    }
    &.active{
        display: block;
    }
  }
`
