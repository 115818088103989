import React, { useContext, useState } from 'react'
import { Divider } from '../../../../../../Components/Form/Divider'
import { ContextEditTeacher, TeacherEdit } from '../../Contexts/ContextEditTeacher'
import { Form } from '../../../../../Registration/UserRegistration/styles'
import { Input } from '../../../../../../Components/Form/Input'
import { Controllers } from '../../../../../../Components/Form/Controllers'




const FormEdit = () => {

    const { handleStep,teacher,setTeacher } = useContext(ContextEditTeacher)
    const [confirmEmail, setconfirmEmail] = useState("")

    function verification(){
        switch (true) {
            case teacher.name === "":
                alert("Preencha o nome.");
                break;
            case teacher.cpf === "":
                alert("Preencha o cpf.");
                break;
            case teacher.birth_date === "":
                alert("Preencha a data de nascimento.");
                break;
            case teacher.phone === "":
                alert("Preencha o telefone.");
                break;
            case teacher.email === "":
                alert("Preencha o email.");
                break;
            case confirmEmail === "":
                alert("Confirme seu e-mail.");
                break;
            default:
                handleStep(2);
        }
    }

    return (
        <Form>
            <Divider>
                <Input
                    label="Nome"
                    type="text"
                    flex="fx100"
                    value={teacher.name}
                    onChange={(e: any) => setTeacher({...teacher, name: e})}
                />
            </Divider>
            <Divider>
                <Input
                    label="CPF"
                    type="cpf"
                    flex="fx33"
                    value={teacher.cpf}
                    onChange={(e: any) => setTeacher({...teacher, cpf: e})}
                />
                <Input
                    label="Data de nascimento"
                    type="date"
                    flex="fx33"
                    value={teacher.birth_date}
                    onChange={(e: any) => setTeacher({...teacher, birth_date: e})}
                />
                <Input
                    label="Telefone"
                    type="tel"
                    flex="fx33"
                    value={teacher.phone}
                    onChange={(e: any) => setTeacher({...teacher, phone: e})}
                />
            </Divider>
            <Divider>
                <Input
                    label="E-mail"
                    type="email"
                    flex="fx50"
                    value={teacher.email}
                    onChange={(e: any) => setTeacher({...teacher, email: e})}
                />
                <Input
                    label="Confirmar E-mail"
                    type="email"
                    flex="fx50"
                    value={confirmEmail}
                    onChange={setconfirmEmail}
                />
            </Divider>
            <Controllers
                enableNext
                enablePrev={false}
                functionNext={() => verification()}
                functionCancel={() => window.location.reload()}
            />
        </Form>
    )
}

export default FormEdit