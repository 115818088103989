import {
  AlarmClock,
  ArrowLeftRightIcon,
  Backpack,
  Calendar,
  Contact,
} from 'lucide-react'
import { LeftMenu } from '../../Components/LeftMenu'
import { SuperBox } from '../../Components/SuperBox'
import { useAuth } from '../../Contexts/store'
import {
  Container,
  Notice,
  SelectClient,
  Slide,
  Use,
  UserChart,
  UserEngage,
  UsersOnline,
} from './styles'

import slideImg from '../../Assets/slide.png'
import { Select } from '../../Components/Form/Select'
import { Avisos } from './Avisos'
import { Atividade } from './Atividade'
import { QtGraph } from './QtGraph'
import { Ranking } from './Ranking'
import { ChartDount } from './ChartDount'
import { useEffect, useState } from 'react'
import { api } from '../../Services/api'
import { useCheckTime } from '../../Components/LoginFunctions/Refresh'

export function Home() {
  useCheckTime();

  const [auth] = useAuth()
  const [clients, setClients] = useState<any>([])



  function handleClick(e: any) {
    const client = clients.find((client: any) => client.identifier === e)
    api.post('/admin/technicians/change-client', {
      client_id: e
    }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
    }).then(response => {
      localStorage.setItem('client', JSON.stringify(client))
    }
    )
  }

  
  return (
    <SuperBox path="Dashboard">
      <Container>
        <Notice>
          <section>
            <div>
              <div>
                <h1>Seja bem vindo!</h1>
                <p>Seu último acesso foi em 01/03/2023 às 21:06</p>
              </div>
              <div>
                <p>
                  {' '}
                  <AlarmClock size={15} /> 15:41
                </p>
                <p>
                  {' '}
                  <Calendar size={15} /> 02/05/2023
                </p>
              </div>
            </div>
            <div>
              <Slide>
                <div className="image">
                  <img src={slideImg} alt="" />
                </div>
                <Avisos />
              </Slide>
              {
                <Atividade />
              }
            </div>
          </section>
          <span className="bg"></span>
        </Notice>
        <UsersOnline>
          <UserChart>
            <div className="head">
              <p>% Usuários online</p>
              <span></span>
            </div>
            <span className="total">Total de usuários cadastrados: 1000</span>
            <div className="body">
              <QtGraph
                className="itemChart"
                label="ADMINISTRATIVO"
                max="100"
                qt="50"
              />
              <QtGraph
                className="itemChart"
                label="DOCENTES"
                max="200"
                qt="80"
              />
              <QtGraph
                className="itemChart"
                label="ESTUDANTES"
                max="800"
                qt="30"
              />
            </div>
          </UserChart>
          <UserEngage>
            <Ranking label="USUÁRIOS MAIS ENGAJADOS" />
            <Ranking label="RECUROS MAIS USADOS" />
          </UserEngage>
        </UsersOnline>
        <Use>
          <div className="head">
            <p>% APROVEITAMENTO DE CONTRATO</p>
            <span></span>
          </div>
          <div className="body">
            <ChartDount
              qt={15}
              label="USO TOTAL DE CADASTRO DE USUÁRIOS:  FUNCIONÁRIOS E ESTUDANTES"
            />
            <ChartDount qt={85} label="USO TOTAL DE UNIDADES CADASTRADAS" />
            <ChartDount qt={33} label="USO TOTAL DE RECURSOS UTILIZADOS" />
          </div>
        </Use>
      </Container>
    </SuperBox>
  )
}
