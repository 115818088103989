import { useNavigate } from "react-router-dom";
import { Container } from "./styles";

export function ErrorAuth(){
    const navigate = useNavigate();

    return(
        <Container>
            <div>
                <p>Você precisa estar logado para acessar este conteúdo.</p>
                <button onClick={() => navigate('/login')}>Fazer login</button>
            </div>
        </Container>
    )
}