import styled from "styled-components";


export const ContainerPOPReview = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
width: 100%;
flex-direction: column;
gap: 5px;
margin-top: 10px;
`

export const ContainerAnswer = styled.div`
display: flex;
justify-content: flex-start;
align-items: flex-start;
width: 100%;
gap: 5px;
margin-top: 10px;

`