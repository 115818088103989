import { CheckCircle, XCircle } from 'lucide-react'
import { Container } from './styles'

export function ModalShort(props: any) {
  return (
    <>
      {props.isOpen && (
        <Container>
          {props.type === 'success' && (
            <div>
              <CheckCircle size={40} color="#00CAD1" />
              <p>{props.text}</p>
              <button className="hvr-grow" onClick={props.onRequestClose}>
                OK
              </button>
            </div>
          )}
          {props.type === 'confirmSuccess' && (
            <div>
              <CheckCircle size={40} color="#00CAD1" />
              <p>{props.text}</p>
              <div className='yes-no'>
                <button className="hvr-grow" onClick={() => props.onRequestClose()}>
                  Não
                </button>
                <button className="hvr-grow" onClick={() => props.onConfirm()}>
                  Sim
                </button>
              </div>
            </div>
          )}
          {props.type === 'delete' && (
            <div>
              <CheckCircle size={40} color="#FF3737" />
              <p className='red'>{props.text}</p>
              <div className='yes-no'>
                <button className="hvr-grow" onClick={() => props.onRequestClose()}>
                  Não
                </button>
                <button className="hvr-grow" onClick={() => props.onConfirm()}>
                  Sim
                </button>
              </div>
            </div>
          )}
          {props.type === 'infoError' && (
            <div>
              <XCircle size={40} color="#FF3737" />
              <p className='red'>{props.text}</p>
              <div className='yes-no'>
                <button type='button' className="hvr-grow" onClick={() => props.onConfirm()}>
                  Voltar
                </button>
              </div>
            </div>
          )}
        </Container>
      )}
    </>
  )
}
