import { Forbidden, Success, Unauthorized } from "../Utils/Responses";
import { api } from "./api";

type TagListCategoryResponse = Success | Unauthorized | Forbidden;

export interface IPlataform {
  identifier: string;
  name: string;
  type: string;
}

class PlataformService {
  static async listPlataforms(): Promise<IPlataform[]> {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(`/admin/plataforms`, config);
      if (response.status === 200) {
        return response.data.data;
      }

      throw new Error("Can not list Categories.");
    } catch (error: any) {
      throw new Error("Can not list Categories.");
    }
  }
}

export default PlataformService;
