import { api } from "./api";
import { AxiosError, AxiosResponse } from "axios";
import {
  CreateSuccess,
  Forbidden,
  ServiceError,
  Success,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";
import { EmployeeEdit, NewEmployee, TypeTeacher, UserEmployee2 } from "../Utils/Types";
import { type } from "os";
import { TypeTeacherNew } from "../Pages/Registration/UserRegistration/FormTeacher/Review";

type ClienteCreateResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type ClienteGetReponse = any | Unauthorized;

type ClienteGetProps = {
  path: string; //Caminho da rota
};

type ShowEmployeeProps = {
  idUnit: any,
  idEmployee: any
}

type ShowEmployeeResponse = Success | Unauthorized | ServiceError

type EmployeeEditResponse = Success | Unauthorized

type EmployeeEditProps = {
  data: EmployeeEdit,
  idUnit: any,
  idEmployee: any
}

type EmployeeCreateProps = {
  data: NewEmployee,
  unitId: any
}

type EmployeeCreateResponse = Success | Unauthorized | Forbidden | UnprocessableContent

class FuncionarioService {

  static async createEmployee({data,unitId}:EmployeeCreateProps):Promise<EmployeeCreateResponse>{
    try{
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post(
        `/client/${unitId}/employees`,
        data,
        config
      )

      return response.data;
    }catch(error: any){
      throw new Error("Can not create Employee.")
    }
  }

  static async editEmployee(input: EmployeeEditProps ): Promise<EmployeeEditResponse> {

    const { data, idEmployee, idUnit } = input

    try {

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.put(
        `/client/${idUnit}/employees/${idEmployee}`,
        data,
        config
      );

      return response.data
    } catch (error: any) {
      throw new Error("Can not edit Employee.")
    }
  }

  static async showEmployee(input: ShowEmployeeProps): Promise<ShowEmployeeResponse> {
    try {
      const { idEmployee, idUnit } = input

      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get(
        `/client/${idUnit}/employees/${idEmployee}`,
        config
      )
      switch (response.status) {
        case 200:
          return { data: response.data.data, success: true, message: "ok" } as Success;
        case 401:
          return { ...response.data, success: false, message: "Unauthorized" } as Unauthorized;
        default:
          return { success: false, message: "ServiceError" } as ServiceError
      }
    } catch (error: any) {
      throw new Error("Can not show Employee.")
    }
  }



  static async put(data: TypeTeacher, idSchool: string, idTeacher: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.put<ClienteCreateResponse>(
        `/client/${idSchool}/employees/${idTeacher}`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
  

  static async post(data: UserEmployee2 | TypeTeacher, idSchool: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<ClienteCreateResponse>(
        `/client/${idSchool}/employees`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }

  static async postTeacher(data: any | undefined, idSchool: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const response = await api.post<ClienteCreateResponse>(
        `/client/${idSchool}/employees`,
        data,
        config
      );

      return response.data;
    } catch (error: any) {
      const errorResponse = error.response.data;
      if (errorResponse.errors) {
        const errorMessages = [];

        for (const key in errorResponse.errors) {
          errorMessages.push(errorResponse.errors[key] + "\n");
        }
        alert(errorMessages);
      } else {
        alert(errorResponse.message);
      }
    }
  }
}


export default FuncionarioService;
