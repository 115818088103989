import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const StoreContext = createContext([{}, () => {}])

export const StoreProvider: any = (props: any) => {
    const [state, setState] = useState({});

    useEffect(() => {
        const haveToken = localStorage.getItem('token');
        if(haveToken === null){
            setState(false)
        } else {
            setState(haveToken)
        }
    },[])

    return(
        <StoreContext.Provider value={[state, setState]}>
            {props.children}
        </StoreContext.Provider>
    )
}

export const useAuth = () => {
    const [auth, setAuth] = useContext(StoreContext)
    return [auth, setAuth]
}