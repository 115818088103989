import { ChangeEvent, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Controllers } from "../../../../Components/Form/Controllers";
import DividerHook from "../../../../Components/Form/DividerHook";
import { TextArea } from "../../../../Components/TextAreaHook";
import {
  EnumStepNewResource,
  ResourceContext,
} from "../../../../Contexts/Form/ContextResource";
import {
  IAnswerResource,
  IPedagogicalResource,
} from "../../../../reducers/newResource/reducer";
import { Form } from "../../ClassRegistration/styles";
import { ButtonSavePOP, Line } from "../styles";
import Guidelines from "./Guidelines";
import ScreenTemplate from "./ScreenTemplate";
import { ContextPOP } from "../../../../Contexts/FormPOP/ContextResourceFormPOP";

export default function Form4(props: any) {
  const navigate = useNavigate();
  const { handleStep, setPedagogicalResource, resource } =
    useContext(ResourceContext);

  const { informations, setInformations } =
    useContext(ContextPOP)


  // const [informations, setInformations] = useState<IPedagogicalResource>(
  //   resource.pedagogical_guideline
  // );

  function handleTip(event: ChangeEvent<HTMLTextAreaElement>) {
    setInformations({
      ...informations,
      tips: event.currentTarget.value,
    });
  }

  function handleContentReference(event: ChangeEvent<HTMLTextAreaElement>) {
    setInformations({
      ...informations,
      content_reference: event.currentTarget.value,
    });
  }

  function handleSimilarReference(event: ChangeEvent<HTMLTextAreaElement>) {
    setInformations({
      ...informations,
      similar_reference: event.currentTarget.value,
    });
  }

  function handleGuideline(event: string, guidelineSelected: number) {
    const guideline = [...informations.guideline];
    guideline[guidelineSelected] = { description: event };
    setInformations({
      ...informations,
      guideline,
    });
  }

  function addGuideline() {
    const guideline = [...informations.guideline];
    guideline.push({ description: "" });
    setInformations({
      ...informations,
      guideline,
    });
  }

  function addAnswer() {
    const answer_key = [...informations.answer_key];
    answer_key.push({ description: "", image: undefined });
    setInformations({
      ...informations,
      answer_key,
    });
  }

  function handleAnswer(answer: IAnswerResource, answerSelected: number) {
    const answer_key = [...informations.answer_key];
    answer_key[answerSelected] = answer;
    setInformations({
      ...informations,
      answer_key,
    });
  }

  function deleteAnswer(answerSelected: number) {
    const answer_key = [...informations.answer_key];
    answer_key.splice(answerSelected, 1);
    if (answer_key.length === 0) addAnswer();
    setInformations({
      ...informations,
      answer_key,
    });
  }

  function handleNext() {
    setPedagogicalResource(informations);
    handleStep(EnumStepNewResource.TUTORIAL);
  }

  return (
    <Form>
      <DividerHook marginTop="20px">
        <TextArea
          label="Fica a Dica"
          flex="fx100"
          required={false}
          height="110px"
          type="type1"
          value={informations.tips}
          onChange={handleTip}
        />
      </DividerHook>
      <Line />
      <Guidelines
        guidelanes={informations.guideline}
        addGuidelanes={addGuideline}
        handleGuidelanes={handleGuideline}
        label="Orientações"
      />
      <DividerHook>
        <TextArea
          label="Referências Similares"
          flex="fx100"
          required={false}
          height="80px"
          type="type1"
          value={informations.similar_reference}
          onChange={handleSimilarReference}
        />
      </DividerHook>
      <DividerHook>
        <TextArea
          label="Referências de Conteúdo"
          flex="fx100"
          required={false}
          height="80px"
          type="type1"
          value={informations.content_reference}
          onChange={handleContentReference}
        />
      </DividerHook>
      <Line />
      <ScreenTemplate
        deleteAnswer={deleteAnswer}
        answers={informations.answer_key}
        addAnswer={addAnswer}
        handleAnswer={handleAnswer}
        label="Gabarito"
      />
      <Line />
      <div>
        <ButtonSavePOP
          onClick={() => {
          }}
        >
          Salvar
        </ButtonSavePOP>
      </div>
      <Controllers
        enableNext={true}
        enablePrev={true}
        functionCancel={() => navigate("/")}
        functionNext={handleNext}
        functionPrev={() => handleStep(EnumStepNewResource.TAGS)}
      />
    </Form>
  );
}
