import { useContext } from "react";
import { SuperBox } from "../../../Components/SuperBox";
import {
  RegistrationClassProvider,
  RegistrationContext,
} from "../../../Contexts/Form/RegistrationClassContext";
import { AcademicYear, AgeRange, EducationStage } from "../../../Utils/Types";
import { Form1 } from "./Form1";
import { Form2 } from "./Form2";
import { Resume } from "./Resume";
import { Container, Main } from "./styles";
import ReviewImports from "../../../Components/ReviewImports";

interface ClassProps {
  edit: boolean;
}

export interface ClassFields {
  year: AcademicYear | null;
  step: EducationStage | null;
  phase: any | null;
  ageRange: AgeRange | null;
  modality: string[]; //modality: string | null;
  name: string;
}
function FactoryClassRegistration() {
  const { title, switchForm, handleSwitchForm, edit, listClasses, setListClasses } =
    useContext(RegistrationContext);

  function nextForm1() {
    if (edit) {
      handleSwitchForm(3);
    } else {
      handleSwitchForm(3);
    }
  }

  function backForm3() {
    if (edit) {
      handleSwitchForm(1);
    } else {
      handleSwitchForm(1);
    }
  }

  function stepForm1() {
    handleSwitchForm(1);
  }

  function stepForm3() {


    
    handleSwitchForm(3);
  }

  function stepForm4(){
    handleSwitchForm(4)
  }
  return (
    <SuperBox switch={true} page={3} path={`${title} \u2022 Turma`}>
      <Container>
        <div className="box">
          <Main>
            {switchForm === 1 && <Form1 next={nextForm1} nextReviewImport={stepForm4}/>}
            {switchForm === 2 && <Form2 next={stepForm3} prev={stepForm1} />}
            {switchForm === 3 && <Resume prev={backForm3} />}
            {switchForm === 4 && <ReviewImports type="class" itemsArray={listClasses} />}
          </Main>
        </div>
      </Container>
    </SuperBox>
  );
}
export function ClassRegistration({ edit }: ClassProps) {
  return (
    <RegistrationClassProvider edit={edit}>
      <FactoryClassRegistration />
    </RegistrationClassProvider>
  );
}
