import { useQuery } from "react-query";
import CollectionService, { ICollection } from "../../../../../Services/Collection.service";
import { Queries } from "../../../../../types/queries";


/**
 * 
 * @returns Lista Coleções
 */
export function useListSignatures() {
  return useQuery<ICollection[]>(
    Queries.LIST_COLLECTION,
    () => CollectionService.listCollection(),
    {
      cacheTime: 5,
      retry: false,
    }
  );
}
