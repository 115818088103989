import styled, { keyframes } from 'styled-components';

export const ContainerImportTags = styled.div`

display: flex;
align-items: center;
justify-content: flex-end;
width: 100%;
height: 50px;
margin-top: 20px;
`


export const DivControlers = styled.div`

display: flex;
align-items: center;
justify-content: flex-end;
gap: 1rem;
width: 100%;
height: 50px;

button{

    transition: transform 0.3s ease;
    :hover{
        cursor: pointer;
        transform: scale(1.1);
    }
}

.cancel{
    width: 110px;
    height: 40px;
    background-color: #BCBCBC;
    border: none;
    border-radius: 10px;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
}

.save{
    width: 110px;
    height: 40px;
    background-color: #088A62;
    border: none;
    border-radius: 10px;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
}

`


export const Table = styled.div`

>p{
width: 100%;
text-align: center;
padding: 10px 0px;
}
div{
    display: flex;
    flex-direction: row;
}
table{
    width: 100%;
    border-spacing: 0px;
    border: 1px solid rgba(100, 100, 100, 1);
    border-radius: 15px;
    thead{
        th{
            font-size: 16px;
            font-weight: 400;
            padding: 10px;
        }
        tr{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
        }

        td{
            text-align: center;
            color: #646464;
            font-size: 14px;
            width: 100%;
        }
    }
    tbody{
        tr{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            td{
                text-align: center;
                color: #646464;
                font-size: 14px;
                width: 100%;
                
            }
        }
    }
    td{
        padding: 5px;
        font-size: 14px;
        color: #818181;
        border-top: 0.5px solid #646464;
        border-left: 0.5px solid #646464;
        &:first-child{
            border-left: none;
        }
        &:last-child{
            button{
                color: #027294;
                background-color: transparent;
                border: none;
                cursor: pointer;
                &:last-child{
                    color: red;
                    margin-left: 5px;
                }
            }
        }
    }
}
.button{
    cursor: pointer;
    margin: 0px 5px;
}
.approved:hover {
    color: #027294;
}
.reproved:hover {
    color: red;
}
`

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const SpinnerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: "";
    border: 2px solid #f3f3f3;  // Ajuste esses valores para mudar o tamanho do spinner
    border-top: 2px solid #3498db;
    border-radius: 50%;
    width: 15px;   // Ajuste esses valores para mudar o tamanho do spinner
    height: 15px;
    margin: 2.5px 24.5px;
    animation: ${spin} 1s linear infinite;
  }
`;

export const TableActionOptions = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DividerNew = styled.div`
    display: flex;
    margin-top: 30px;
    align-items: center;

        button{
            display: flex;
            background-color: transparent;
            color: #FE8F2E;
            font-size: 16px;
            font-weight: 700;
            align-items: center;
            border: none;
            gap: 10px;
            text-transform: uppercase;
            cursor: pointer;

            .icon-bnt:hover{
                cursor: pointer;
                transform: scale(1.1);
            }
        }
`