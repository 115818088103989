import {
  Dispatch,
  SetStateAction,
  createContext,
  useReducer,
  useState,
} from "react";
import { ICategoriesCollection } from "../../Services/Collection.service";
import {
  IAuthorshipResource,
  IPedagogicalResource,
  IResourceCollection,
  IResourceState,
  ITagsResource,
  TFieldsBNCCEF,
  TFieldsBNCCEI,
  TFieldsBNCCEM,
  initialStateResource,
  resourceReducer,
} from "../../reducers/newResource/reducer";
import {
  addAuthorShipAction,
  addBNCCBasicComponent,
  addBNCCEfFields,
  addBNCCEiFields,
  addBNCCEmFields,
  addCategoryCollectionAction,
  addContentDescritorsAction,
  addIntelectualRightsAction,
  handleFieldResourceAction,
  handleImgAction,
  handleLicenseAction,
  handleParentalRatingAction,
  handleResourceAction,
  removeAuthorShipAction,
  removeBNCCBasicComponent,
  removeBNCCEfFields,
  removeBNCCEiFields,
  removeBNCCEmFields,
  removeCategoryCollectionAction,
  removeContentDescritorsAction,
  removeIntelectualRightsAction,
  setBNCCEfFields,
  setBNCCEiFields,
  setBNCCEmFields,
  setFieldsArrayAction,
  setPedagogicalAction,
  setStoryBoardAction,
  setTagsAction,
} from "../../reducers/newResource/actions";
import { Tags } from "../../Pages/Registration/ResourcesRegistration/Form3/Form3";
import { func } from "prop-types";

export const OptionInappropriateContentResource = [
  "Violência",
  "Violência Extrema",
  "Conteúdo Sexual",
  "Nudez",
  "Sexo",
  "Sexo Explícito",
  "Drogas",
  "Drogas Lícitas",
  "Drogas Ilícitas",
  "Linguagem Imprópria",
  "Atos Criminosos",
  "Conteúdo Impactante.",
];

export const OptionParentalRatingResource: Record<string, string> = {
  L: "Livre",
  "10": "10 anos",
  "12": "12 anos",
  "14": "14 anos",
  "16": "16 anos",
  "18": "18 anos",
};

export const OptionFormartResource: Record<string, string> = {
  html5: "Página multimídia web(html5)",
  app: "Aplicativo(app)",
  pdf: "Texto/imagem portável(pdf)",
  jpg: "Imagem(jpg)",
  png: "Imagem(png)",
  mp3: "Som/áudio(mp3)",
  mp4: "Vídeo(mp4)",
  odt: "Documento de texto do LibreOffice Writer(odt)",
  ods: "Planilhas do LibreOffice Calc(ods)",
  odp: "Apresentação multimídia do LibreOffice Impress(odp)",
  haxe: "Haxe",
  flash: "Flash",
};

export const OptionLanguageResource: Record<string, string> = {
  pt_BR: "Português - BR (Portuguese - BR)",
  en_US: "Inglês - EUA (English - US)",
};

export const enum EnumStepNewResource {
  INFORMACAO,
  BNCC,
  TAGS,
  POP,
  TUTORIAL,
  DOCUMENTS,
  REVIEW
}

interface IResourceContext {
  resource: IResourceState;
  filterBNCC: string;
  stepActual: EnumStepNewResource;
  stepForm1: number;
  fieldsRequireds: string[];

  addFieldBNCCEiResource: (value: string, key: TFieldsBNCCEI) => void;
  removeFieldBNCCEiResource: (value: string, key: TFieldsBNCCEI) => void;
  addFieldBNCCEmResource: (value: string, key: TFieldsBNCCEM) => void;
  removeFieldBNCCEmResource: (value: string, key: TFieldsBNCCEM) => void;
  addBNCCBasicComponentResource: (value: number) => void;
  removeBNCCBasicComponentResource: (value: number) => void;
  addFieldBNCCEfResource: (value: string, key: TFieldsBNCCEF) => void;
  removeFieldBNCCEfResource: (value: string, key: TFieldsBNCCEF) => void;
  handleParentalRatingResource: (e: string) => void;
  addIntelectualRightsResource: (name: string) => void;
  removeIntelectualRightsResource: (name: string) => void;
  handleLicensaResource: (e: string) => void;
  handleThumbnailsResource: (thumbnails: File[]) => void;
  addFieldRequired: (field: string) => void;
  removeFieldRequired: (field: string) => void;
  handleStepForm1: (step: number) => void;
  handleStep: (step: EnumStepNewResource) => void;
  setStoryBoard: (v: string) => void
  handleFieldResource: (
    field: keyof IResourceState,
    value: string | boolean | number
  ) => void;
  addCategoryCollectionSelects: (category: IResourceCollection) => void;
  removeCategoryCollectionSelects: (category: IResourceCollection) => void;
  addAuthorShip: (author: string, key: keyof IAuthorshipResource) => void;
  removeAuthorShip: (author: string, key: keyof IAuthorshipResource) => void;
  addContentDescritors: (descritor: string) => void;
  removeContentDescritors: (descritor: string) => void;

  setBNCCEiFieldResource: (values: string[], key: TFieldsBNCCEI) => void;
  setBNCCEfFieldResource: (values: string[], key: TFieldsBNCCEF) => void;
  setBNCCEmFieldResource: (values: string[], key: TFieldsBNCCEM) => void;

  setPedagogicalResource: (pedagogical: IPedagogicalResource) => void;
  setTagsResource: (tag: ITagsResource) => void;
  handleFilterBNCC: (filter: string) => void;
  idResource: string;
  setIdResource: Dispatch<SetStateAction<string>>;
  tagsListed: Tags[];
  setTagsListed: Dispatch<SetStateAction<Tags[]>>;
  content: string
  setContent: Dispatch<SetStateAction<string>>

  setFieldsArrayResource: (
    values: string[],
    key:
      | "target_audience"
      | "plataforms"
      | "collection"
      | "equivalent_resources"
  ) => void;
  handleResource: (resource: any) => void;
}

export const ResourceContext = createContext<IResourceContext>(
  {} as IResourceContext
);

interface IResourceContextProviderProps {
  children: React.ReactNode;
}

export function ResourceContextProvider({
  children,
}: IResourceContextProviderProps) {
  const [stepActual, setStepActual] = useState(EnumStepNewResource.INFORMACAO);
  const [stepForm1, setStepForm1] = useState(0);
  const [fieldsRequireds, setFieldsRequireds] = useState<string[]>([]);
  const [filterBNCC, setFilterBNCC] = useState("");

  const [idResource, setIdResource] = useState("");

  const [tagsListed, setTagsListed] = useState<Tags[]>([]);
  const [content, setContent] = useState(``)


  const [resourceState, dispatch] = useReducer(
    resourceReducer,
    initialStateResource,
    (initialState) => {
      return initialState;
    }
  );

  function setStoryBoard(v: string){
    dispatch(setStoryBoardAction(v));
  }

  function handleResource(resource: any) {
    console.log("resource", resource);
    dispatch(handleResourceAction(resource));
  }

  function handleFilterBNCC(filter: string) {
    setFilterBNCC(filter);
  }

  function addFieldRequired(field: string) {
    setFieldsRequireds([...fieldsRequireds, field]);
  }
  function removeFieldRequired(field: string) {
    setFieldsRequireds(fieldsRequireds.filter((item) => item !== field));
  }

  function handleStepForm1(step: number) {
    setStepForm1(step);
  }

  function handleStep(step: EnumStepNewResource) {
    setStepActual(step);
  }

  function handleFieldResource(
    field: keyof IResourceState,
    value: string | boolean | number
  ) {
    dispatch(handleFieldResourceAction(value, field));
  }

  function removeCategoryCollectionSelects(category: IResourceCollection) {
    dispatch(removeCategoryCollectionAction(category));
  }

  function addCategoryCollectionSelects(category: IResourceCollection) {
    dispatch(addCategoryCollectionAction(category));
  }

  function addAuthorShip(author: string, key: keyof IAuthorshipResource) {
    dispatch(addAuthorShipAction(author, key));
  }

  function removeAuthorShip(author: string, key: keyof IAuthorshipResource) {
    dispatch(removeAuthorShipAction(author, key));
  }

  function addContentDescritors(descritor: string) {
    dispatch(addContentDescritorsAction(descritor));
  }

  function removeContentDescritors(descritor: string) {
    dispatch(removeContentDescritorsAction(descritor));
  }

  function handleThumbnailsResource(thumbs: File[]) {
    dispatch(handleImgAction(thumbs));
  }

  // function deleteImgResource() {
  //   dispatch(handleImgAction(undefined));
  // }

  function handleLicensaResource(e: string) {
    dispatch(handleLicenseAction(e));
  }

  function addIntelectualRightsResource(name: string) {
    dispatch(addIntelectualRightsAction(name));
  }

  function removeIntelectualRightsResource(name: string) {
    dispatch(removeIntelectualRightsAction(name));
  }

  function handleParentalRatingResource(e: string) {
    dispatch(handleParentalRatingAction(e));
  }

  /*
    Funções para adicionar ou remover algum elemento de BNCC
  */
  function addBNCCEfFieldResource(value: string, key: TFieldsBNCCEF) {
    dispatch(addBNCCEfFields(value, key));
  }

  function removeBNCCEfFieldResource(value: string, key: TFieldsBNCCEF) {
    dispatch(removeBNCCEfFields(value, key));
  }

  function addBNCCEiFieldResource(value: string, key: TFieldsBNCCEI) {
    dispatch(addBNCCEiFields(value, key));
  }

  function removeBNCCEiFieldResource(value: string, key: TFieldsBNCCEI) {
    dispatch(removeBNCCEiFields(value, key));
  }

  function addBNCCEmFieldResource(value: string, key: TFieldsBNCCEM) {
    dispatch(addBNCCEmFields(value, key));
  }

  function removeBNCCEmFieldResource(value: string, key: TFieldsBNCCEM) {
    dispatch(removeBNCCEmFields(value, key));
  }

  function addBNCCBasicComponentResource(value: number) {
    dispatch(addBNCCBasicComponent(value));
  }

  function removeBNCCBasicComponentResource(value: number) {
    dispatch(removeBNCCBasicComponent(value));
  }

  /*
    Funções para setar um array em algum campo escolar do BNCC
  */
  function setBNCCEiFieldResource(values: string[], key: TFieldsBNCCEI) {
    dispatch(setBNCCEiFields(values, key));
  }

  function setBNCCEfFieldResource(values: string[], key: TFieldsBNCCEF) {
    dispatch(setBNCCEfFields(values, key));
  }

  function setBNCCEmFieldResource(values: string[], key: TFieldsBNCCEM) {
    dispatch(setBNCCEmFields(values, key));
  }

  /*Funções para setar as informações pedagógicas*/
  function setPedagogicalResource(pedagogical: IPedagogicalResource) {
    dispatch(setPedagogicalAction(pedagogical));
  }

  function setTagsResource(tag: ITagsResource) {
    dispatch(setTagsAction(tag));
  }

  function setFieldsArrayResource(
    values: string[],
    key:
      | "target_audience"
      | "plataforms"
      | "collection"
      | "equivalent_resources"
  ) {
    dispatch(setFieldsArrayAction(values, key));
  }
  return (
    <ResourceContext.Provider
      value={{
        content,
        setContent,
        setStoryBoard,
        handleResource,
        setTagsListed,
        tagsListed,
        handleFilterBNCC,
        filterBNCC,
        stepActual,
        stepForm1,
        addIntelectualRightsResource,
        removeIntelectualRightsResource,
        handleStepForm1,
        handleStep,
        fieldsRequireds,
        addFieldRequired,
        removeFieldRequired,
        resource: resourceState,
        addBNCCBasicComponentResource,
        removeBNCCBasicComponentResource,
        addFieldBNCCEfResource: addBNCCEfFieldResource,
        removeFieldBNCCEfResource: removeBNCCEfFieldResource,
        addFieldBNCCEiResource: addBNCCEiFieldResource,
        removeFieldBNCCEiResource: removeBNCCEiFieldResource,
        addFieldBNCCEmResource: addBNCCEmFieldResource,
        removeFieldBNCCEmResource: removeBNCCEmFieldResource,
        handleLicensaResource,
        handleParentalRatingResource,
        handleThumbnailsResource,
        handleFieldResource,
        addCategoryCollectionSelects,
        removeCategoryCollectionSelects,
        addAuthorShip,
        removeAuthorShip,
        addContentDescritors,
        removeContentDescritors,
        setBNCCEfFieldResource,
        setBNCCEiFieldResource,
        setBNCCEmFieldResource,
        setPedagogicalResource,
        setTagsResource,
        setFieldsArrayResource,
        idResource,
        setIdResource,
      }}
    >
      {children}
    </ResourceContext.Provider>
  );
}
