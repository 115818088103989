import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import { ObjectView, StudentData } from '../NewFormStudent'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import EtapaAcademicaService from '../../../../../Services/EtapaAcademica.service'
import StudentService from '../../../../../Services/Student.service'
import { Class, Student } from '../../../../../Utils/Types'
import ModalNew from '../../../../../Components/Modals/ModalNew/ModalNew'
import TurmaService from '../../../../../Services/Turma.service'

interface Props {
    data: any
    setSwitchForm: Dispatch<SetStateAction<number>>
    unit: any
    idClassRoom: string
    object: ObjectView
    idStudent: string
}


const ReviewStudent = ({ data, setSwitchForm, unit, idClassRoom, object, idStudent }: Props) => {

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)

    function renderStage() {

        return (
            <tr>
                <td>
                    <span>Nível de ensino:</span>
                    {object.nameStage}
                </td>
            </tr>
        )
    }

    function renderClass() {
        return (
            <tr>
                <td>
                    <span>Turma:</span>
                    {object.nameClass}
                </td>
            </tr>
        )
    }

    function renderSpecialNeeds() {

    }


    function deleteStudent() {
        StudentService.delete({ educationInstitution: unit.identifier, student: idStudent }).then((res) => {
            navigate("/unidade/usuario/list")
        })
    }


    async function SaveStudent() {

        StudentService.post({ data: data, idUnit: unit.identifier }).then((resCreate) => {
            if (resCreate?.success) {
                console.log(resCreate.data)
                const data = new FormData()
                data.append("students[]", resCreate.data.id)
                TurmaService.classAddStudent({ idInstitution: unit.identifier, classID: object.identifierClass, students: data }).then((res) => {
                    if (res?.success) {
                        setIsOpen(true)
                    }
                })
            }
        })
    }


    return (
        <>
            <ModalNew
                isOpen={isOpen}
                type='success'
                text='Usuário cadastrado!'
                onRequestClose={() => navigate("/unidade/usuario/list")}
            />
            <BoxResume label={`Verifique se os dados estão corretos.`}>
                <tr>
                    <td>
                        <b>Estudante</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Nome:</span>
                        {data?.name}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>CPF:</span>
                        {data.cpf ? data.cpf : "Não informado." }
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Data de Nascimento:</span>
                        {data.birthDate?.toLocaleString().substring(0, 9)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Matrícula:</span>
                        {data?.enrollment}
                    </td>
                </tr>
                {data.year_id ?
                    renderStage()
                    : null
                }
                {
                    idClassRoom ?
                        renderClass()
                        : null
                }
            </BoxResume>
            <br />
            <Controllers
                enableNext={true}
                save={true}
                enablePrev={true}
                functionCancel={() => navigate("/unidade/usuario/list")}
                functionSave={() => SaveStudent()}
                functionPrev={() => setSwitchForm(1)}
            />
        </>
    )
}

export default ReviewStudent
