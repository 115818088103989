import { useEffect, useState } from "react"
import PackageService from "../../../../Services/Package.service"
import { ContainerCategorie, ContainerCategories, ContainerLevelOne } from "./styles"
interface TSubCategory {
    children: TSubCategory[]
    description: string | null
    identifier: string
    level: number
    name: string
    path: string
}

export function Expanded(props: any) {

    const [categoriesArray, setCategoriesArray] = useState<any[]>([])

    useEffect(() => {
        PackageService.listCategoriesByIdPakage(props.package?.identifier).then((response) => {
            setCategoriesArray(response)
        })
    }, [])


    function Childrens({ info }: { info: TSubCategory }) {
        return <div className="cont-sub">
            <ContainerLevelOne>
                <p>{info.name}</p>
            </ContainerLevelOne>

            {info.children.length ?
                <div className="div-level">
                    {info.children.map((cat) => <Childrens info={cat} />)}
                </div>
                : null}
        </div>
    }

    return (
        <ContainerCategories>
            {categoriesArray.map((category: any) =>
                <ContainerCategorie>
                    <div className="container-title">
                        <p>{category.name}</p>
                    </div>

                    <div className="div-level">
                        {category.subcategories.map((sub: TSubCategory) => {
                            return (
                                <Childrens info={sub} />
                            )
                        })}
                    </div>
                </ContainerCategorie>
            )}
        </ContainerCategories>
    )
}