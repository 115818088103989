import { ResumeRequest } from "./styles";

export function RequestResume(props: any) {
    return (
        <ResumeRequest>
            <header>
                <p>Fulano de tal</p>
            </header>

            <section>
                <div className="section-1">
                    <p>Origem</p>
                    <div className="area">
                        <tr>
                            <tr>
                                <td>Ano Letivo:</td>
                            </tr>
                            <tr>
                                <td>Unidade Educacional:</td>
                            </tr>
                            <tr>
                                <td>Etapa:</td>
                            </tr>
                            <tr>
                                <td>Nome da Turma:</td>
                            </tr>
                        </tr>
                    </div>

                </div>

                <div className="section-1">
                    <p>Destino</p>
                    <div className="area">
                        <tr>
                            <tr>
                                <td>Ano Letivo:</td>
                            </tr>
                            <tr>
                                <td>Unidade Educacional:</td>
                            </tr>
                            <tr>
                                <td>Etapa:</td>
                            </tr>
                            <tr>
                                <td>Nome da Turma:</td>
                            </tr>
                        </tr>
                    </div>
                </div>

                <div className="section-2">
                    <div className="box-1">
                        <p>Data da Solicitação</p>
                        <div className="fx30">
                            02/20/2004
                        </div>
                    </div>

                    <div className="box-2">
                        <p>Solicitante</p>
                        <div className="fx70">
                            kervens louis
                        </div>
                    </div>
                </div>

                <div className="section-3">
                    <p>Justificativa</p>
                    <div className="area">
                        Justificativa inserida aqui na transferência deste estudante.
                    </div>
                </div>

                <div className="section-2">
                    <div className="box-1">
                        <p>Reenvios</p>
                        <div className="fx30">
                            08/10/2004
                        </div>
                    </div>

                    <div className="box-2">
                        <p>Solicitante</p>
                        <div className="fx70">
                            Junior 
                        </div>
                    </div>
                </div>

            </section>

            <footer>
                    <button onClick={props.onBack}>Ok</button>
            </footer>
        </ResumeRequest>
    )
}