import React from "react";
import { Container } from "./styles";

export default function DividerHook(props: any) {
    return (
        <Container
            $alignsItems={props.alignItems}
            $marginTop={props.marginTop}
            $justifyContent={props.justifyContent}
            $flexDirection={props.flexD}
        >
            {props.children}
        </Container>
    )
}