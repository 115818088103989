import styled from "styled-components";

export const DivFooter = styled.section`
display: flex;
align-items: center;
justify-content: center;

width: 100%;
height: 60px;
border-top: 1px solid black;

.numbersDiv{
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}

.numbersActive{
    background-color: #027294;
    width: 20%;
    height: 100%;
    width: 30px;
    height: 30px;
    border-radius: 500px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
}
.numbersInactive{
    width: 30px;
    height: 30px;
    border-radius: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    :hover{
        cursor: pointer;
    }
}

.arrow{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    :hover{
        cursor: pointer;
    }
}

`