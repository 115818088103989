import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Nunito', sans-serif;
  }
:root{
  --yellow: #FE8F2E;
  --blue: #027294;
  --blue-light: #039bc9;
  --green: #088A62;
}
`;
