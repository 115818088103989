import { api } from "./api";
import { AxiosError } from "axios";
import {
  CreateSuccess,
  Forbidden,
  Unauthorized,
  UnprocessableContent,
} from "../Utils/Responses";

type PlanoServiceEdit = {
  max_students: number;
  max_employees: number;
  max_guardians_per_student?: number;
  max_education_institutions: number;
  packages: any[];
  collections: any[];
};

type PlanoEditResponse =
  | CreateSuccess
  | Unauthorized
  | Forbidden
  | UnprocessableContent;

type PlanoGetReponse = CreateSuccess | Unauthorized;

class PlanoService {
  static async put(data: PlanoServiceEdit, idClient: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.put<PlanoEditResponse>(
        `/admin/clients/${idClient}/plan`,
        data,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }

  static async get(idClient: string) {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };

      const response = await api.get<PlanoGetReponse>(
        `/admin/clients/${idClient}/plan`,
        config
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data.message);
      } else {
        alert(error);
      }
    }
  }
}

export default PlanoService;
