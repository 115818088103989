import { Container } from './styles'

export function TableUniqueRemove(props: any) {
  return (
    <Container>
      <p>{props.label}</p>
      <table>
        <tbody>
          <tr>
            <td>Plano de Teste 1 para adicionar clique</td>
            <td>
              <button>Remover</button>
            </td>
          </tr>
          <tr>
            <td>Plano de Teste 1 para adicionar clique</td>
            <td>
              <button>Remover</button>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  )
}
