import { CheckCircle, XIcon } from 'lucide-react'
import { Container, Controlls } from '../ModalForm/styles'
import { EnumRoles } from '../../../Utils/Types'

export function ModalTags(props: any) {
  return (
    <>
      {props.isOpen && (
        <Container>
          <p>{props.title} <button className='hvr-pulse' onClick={() => props.onRequestClose()}> <XIcon size={30} /> </button></p>
          <div>
            {props.children}
            {props.type === "tags" && (
              <Controlls>
                <button className='hvr-grow' onClick={() => props.onRequestClose()}>Cancelar</button>

                <button className='hvr-grow' onClick={() => props.onSave()}>{props.user === EnumRoles.MASTER
                  ? <>Cadastrar</>
                  : <>Sugerir</>}</button>
              </Controlls>
            )}

            {props.type === "editTags" && (
              <Controlls>
                <button className='hvr-grow' onClick={props.onRequestClose}>Cancelar</button>
                <button className='hvr-grow' onClick={props.editFunction}>Salvar</button>
              </Controlls>
            )}

            {props.type === "createCategory" && (
              <Controlls>
                <button className='hvr-grow' onClick={props.onRequestClose}>Cancelar</button>
                <button className='hvr-grow' onClick={props.saveCategory}>Salvar</button>
              </Controlls>
            )}

            {props.type === "approvDisaprovTag" && (
              <Controlls>
                <button className='hvr-grow' onClick={props.onRequestClose}>Cancelar</button>
                <button className='hvr-grow' style={{ backgroundColor: "red" }} onClick={props.approvetag}>Reprovar</button>
                <button className='hvr-grow' onClick={props.disaproveTag}>Aprovar</button>
              </Controlls>
            )}

            {props.type === "EditCategory" && (
              <Controlls>
                <button className='hvr-grow' onClick={props.onRequestClose}>Cancelar</button>
                <button className='hvr-grow' onClick={props.editFunction}>Salvar</button>
              </Controlls>
            )}

          </div>
        </Container>
      )}
    </>
  )
}
