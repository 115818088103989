import { IBNCCThematicUnit } from "../../../../../../../types/bncc";
import { IResource } from "../../../../../../../types/types";
import { KnowledgeObject, ListItem, TitleThematicUnit } from "./style";

interface ThematicUnitItemProps {
  resource: IResource;
  thematicUnit: IBNCCThematicUnit;
}

export function ThematicUnit({
  resource,
  thematicUnit,
}: ThematicUnitItemProps) {
  console.log(resource);
  const knowledgeOjects = thematicUnit.knowledge_objects.filter(
    (knowledgeObject) =>
      resource.bncc?.ef.knowledge_objects?.includes(knowledgeObject)
  );
  const hasKNowledgeObjectsComponents = Boolean(
    knowledgeOjects && knowledgeOjects.length > 0
  );
  return (
    <ListItem>
      {" "}
      <p>Unidades temáticas:</p>
      <TitleThematicUnit> - {thematicUnit.name}</TitleThematicUnit>
      {hasKNowledgeObjectsComponents
        ? knowledgeOjects.map((knowledgeObject) => (
            <KnowledgeObject key={knowledgeObject}>
              {knowledgeObject}
            </KnowledgeObject>
          ))
        : null}
    </ListItem>
  );
}
