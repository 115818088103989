import { Container, Label } from "./styles";

export function BoxResume(props: any){
    return(
        <>
        {props.label && <Label>{props.label}</Label>}
        <Container>
            <tbody>
                {props.children}
            </tbody>
        </Container>
        </>
    )
}