import styled from "styled-components";
interface BackgroundProps {
    $back?: string
}

export const ContainerMain = styled.div`
display: flex;
width: 100%;
height: 100%;
background-color: #D4D4DE;
`

export const ContainerNameLevel1 = styled.div`
display: flex;
width: 35%;
height: 100%;
justify-content: center;
align-items: center;
`

export const ContainerLevel2 = styled.div`
display: flex;
width: 100%;
height: 100%;
background-color: white;
align-items: flex-end;
`
export const Level2 = styled.div<BackgroundProps>`
display: flex;
width: 100%;
height: 50%;
background-color: rgba(212, 212, 222, 1);

.container-name-level-one{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    width: 90%;
    height: 100%;
    /* background-color: #e9e9ee; */
    background-color: ${({ $back }) => $back || "#e9e9ee"};
}
.container-input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14%;
    height: 100%;
    background-color: white;
}
`

