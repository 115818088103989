import { Trash2 } from "lucide-react";
import { Table } from "../../../../../Components/Form/ImportExcel/styles";
import { IAuthorshipResource } from "../../../../../reducers/newResource/reducer";

interface TableAuthorProps {
  authors: IAuthorshipResource;
  deleteAction: (author: string, key: keyof IAuthorshipResource) => void;
}
export function TableAuthor({ authors, deleteAction }: TableAuthorProps) {
  const authorsTable = [
    ...authors.content_creator.map((author) => {
      return { name: author, key: "content_creator", label: "Conteudista" };
    }),
    ...authors.proofreader.map((author) => {
      return { name: author, key: "proofreader", label: "Revisor(a)" };
    }),
    ...authors.graphic_designer.map((author) => {
      return {
        name: author,
        key: "graphic_designer",
        label: "Designer Gráfico",
      };
    }),
    ...authors.screenwriter.map((author) => {
      return { name: author, key: "screenwriter", label: "Roteirista" };
    }),
    ...authors.illustrator.map((author) => {
      return { name: author, key: "illustrator", label: "Ilustrador(a)" };
    }),
    ...authors.programmer.map((author) => {
      return { name: author, key: "programmer", label: "Programador(a)" };
    }),
  ];

  function deleteRow(author: { name: string; key: string; label: string }) {
    deleteAction(author.name, author.key as keyof IAuthorshipResource);
  }

  return authorsTable.length > 0 ? (
    <Table>
      <p>Autores</p>
      <table>
        <thead>
          <td>Area</td>
          <td>Nome</td>
          <td />
        </thead>
        <tbody>
          {authorsTable.map((author) => {
            return (
              <tr key={author.name}>
                <td>{author.label}</td>
                <td>{author.name}</td>
                <td>
                  <button
                    title="Excluir"
                    type="button"
                    onClick={() => deleteRow(author)}
                  >
                    <Trash2 size={16} className="hvr-grow" />
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </Table>
  ) : null;
}
