import React, { useState, useEffect } from "react";
import { CirclePicker, ColorResult } from "react-color";
import { StyledColor } from "./styles";

interface ColorPickerProps {
  defaultColor?: string;
  onChange: (color: string) => void;
}

export const ColorPicker: React.FC<ColorPickerProps> = ({
  defaultColor = "#000000",
  onChange,
}) => {
  const [color, setColor] = useState<string>(defaultColor);
  const [showPicker, setShowPicker] = useState<boolean>(false);

  const handleClick = () => {
    setShowPicker((prevShowPicker) => !prevShowPicker);
  };

  const handleChange = (newColor: ColorResult) => {
    setColor(newColor.hex);
    onChange(newColor.hex);
  };

  useEffect(() => {
    setShowPicker(false);
  }, [color]);

  return (
    <div>
      <StyledColor
        color={color}
        onClick={handleClick}
      />
      {showPicker && (
        <CirclePicker color={color} onChangeComplete={handleChange} circleSpacing={10} styles={{default: {card: {margin: 10}}}}/>
      )}
    </div>
  );
};
