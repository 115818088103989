import {useQuery} from "react-query";
import {Queries} from "../../types/queries";
import TurmaService from "../../Services/Turma.service";

export function useDetailsClass(educationalId: string, classId: string) {
    return useQuery(
       [ Queries.DETAILS_CLASS, educationalId, classId ],
        () =>
            TurmaService.showClass({idInstitution: educationalId, classID: classId}).then((response) => {
                if (response.success) {
                    return response.data;
                }
                throw new Error("Can not get class details.");
            }),
        {
            retry: true,
            cacheTime: 5 * 60 * 1000,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        }
    );
}
