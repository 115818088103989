import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Student } from '../../../../../../Utils/Types'
import { Controllers } from '../../../../../../Components/Form/Controllers'
import EtapaAcademicaService from '../../../../../../Services/EtapaAcademica.service'
import { BoxResume } from '../../../../../../Components/Form/BoxResume'
import StudentService from '../../../../../../Services/Student.service'
import { useNavigate } from 'react-router-dom'
import ModalNew from '../../../../../../Components/Modals/ModalNew/ModalNew'
import { ModalShort } from '../../../../../../Components/Modals/ModalShort'


interface Props {
    data: Student
    setSwitchForm: Dispatch<SetStateAction<number>>
    unit: any
}

const ReviewStudentEdit = ({ data, setSwitchForm, unit }: Props) => {

    const [showStage, setShowStage] = useState<any>()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {

        EtapaAcademicaService.show({ identifier: data?.education_stage_id }).then((response) => {
            if (response.success) {
                setShowStage(response.data)
            }
        })
    }, [data])


    function renderStage() {

        return (
            <tr>
                <td>
                    <span>Nível de ensino:</span>
                    {showStage?.name}
                </td>
            </tr>
        )
    }

    function SaveStudent() {
        StudentService.put({ educationInstitution: unit.identifier, student: data })
        // alert("Usuário alterado")
        // window.location.reload()
        setIsOpen(true)
    }


    return (
        <>
            <ModalShort
                isOpen={isOpen}
                type='success'
                text='Usuário alterado!'
                onRequestClose={()=> window.location.reload()}
            />
            <BoxResume label={`Verifique se os dados estão corretos.`}>
                <tr>
                    <td>
                        <b>Estudante</b>
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Nome:</span>
                        {data?.name}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>CPF:</span>
                        {data?.cpf}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Data de Nascimento:</span>
                        {data.birthDate?.toLocaleString().substring(0, 9)}
                    </td>
                </tr>
                <tr>
                    <td>
                        <span>Matrícula:</span>
                        {data?.enrollment}
                    </td>
                </tr>
                {data.education_stage_id ?
                    renderStage()
                    : null
                }
            </BoxResume>
            <br />
            <Controllers
                enableNext={true}
                save={true}
                enablePrev={true}
                functionCancel={() => window.location.reload()}
                functionSave={() => SaveStudent()}
                functionPrev={() => setSwitchForm(1)}
            />
        </>
    )
}

export default ReviewStudentEdit
