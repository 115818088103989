import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Divider } from '../../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../../Components/Wizard'
import { Form } from '../../../../Registration/UserRegistration/styles'
import { Input } from '../../../../../Components/Form/Input'
import { Controllers } from '../../../../../Components/Form/Controllers'

const FormEmployeeEdit = () => {
    const [stepForm, setStepForm] = useState(1)
    const navigate = useNavigate()

    const [name, setName] = useState()
    const [cpf, setCpf] = useState()
    const [date, setDate] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [confirmEmail, setConfirmEmail] = useState("")


    function nextStep() {
        if (name === "") {
            alert("Preencha o nome.")
        } else if (cpf === "") {
            alert("Preencha o CPF")
        } else if (date === "") {
            alert("Preencha a data.")
        } else if (phone === "") {
            alert("Preencha  o telefone.")
        } else if (email === "") {
            alert("Preencha o email.")
        } else if (confirmEmail === "") {
            alert("Confirme seu email.")
        } else {
            setStepForm(2)
        }
    }
    return (
        <>
            <Divider>
                <Wizard>
                    <WizardStep
                        text={`Responsável`}
                        active={stepForm === 1}
                    />
                    <WizardStep text="Revisar" active={stepForm === 2} />
                </Wizard>
            </Divider>
            {stepForm === 1 && (
                <Form onSubmit={() => { }}>
                    <Divider>
                        <Input
                            label="Nome"
                            type="text"
                            flex="fx100"
                            required
                            value={name}
                            onChange={setName}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="CPF"
                            required
                            type="cpf"
                            flex="fx33"
                            value={cpf}
                            onChange={setCpf}
                        />
                        <Input
                            label="Data de Nascimento"
                            required
                            type="date"
                            flex="fx33"
                            value={date}
                            onChange={setDate}
                        />
                        <Input
                            label="Telefone"
                            required
                            type="tel2"
                            flex="fx33"
                            value={phone}
                            onChange={setPhone}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={email}
                            onChange={setEmail}
                        />
                        <Input
                            label="Confirmar E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={confirmEmail}
                            onChange={setConfirmEmail}
                        />
                    </Divider>
                    <Controllers
                        functionNext={() => { nextStep() }}
                        functionPrev={() => {
                        }}
                        functionCancel={() => { window.location.reload() }}
                        enableNext={true}
                        enablePrev={true}
                    />
                </Form>
            )}
        </>
    )

}

export default FormEmployeeEdit
