import { FileText, SearchIcon, Trash } from 'lucide-react'
import { useState } from 'react'
import { ModalForm } from '../../Modals/ModalForm'
import { Actions, Container, List, Search, Show } from './styles'

export function MultiSelect(props: any) {
  const [modal, setModal] = useState(false)

  return (
    <Container>
      <label htmlFor="">
        {props.label}
      </label>
      <ModalForm isOpen={modal} onRequestClose={() => setModal(false)}>
        <Search>
          <input type="text" />
          <button className="hvr-grow">
            <SearchIcon size={16} />
          </button>
        </Search>
        <List>
          <thead>
            <td colSpan={2}>{props.names}</td>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="checkbox" name="" id="" />
              </td>
              <td> Coleção 01</td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="" id="" />
              </td>
              <td> Coleção 01</td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="" id="" />
              </td>
              <td> Coleção 01</td>
            </tr>
            <tr>
              <td>
                <input type="checkbox" name="" id="" />
              </td>
              <td> Coleção 01</td>
            </tr>
          </tbody>
        </List>
      </ModalForm>
      <Show>
      <p title='Coleção 01, Coleção 01, Coleção 01, Coleção 01, Coleção 01, Coleção 01'>Coleção 01, Coleção 01, Coleção 01, Coleção 01, Coleção 01, Coleção 01 </p>
      </Show>
      <Actions>
        <button className="delete hvr-pop" title='Limpar'>
          <Trash size={12} />
        </button>
        <button className="showList hvr-pop" title='Listagem' onClick={() => setModal(true)}>
          <FileText size={12} />
        </button>
      </Actions>
    </Container>
  )
}
