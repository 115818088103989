import { number } from "zod";
import { ContainerReactSelect, Error, Label, Seletor } from "./styles";

export function Select(props: any) {
  const linhasLabel = props.label ? props.label.split("\\n") : []
  return (
    <ContainerReactSelect className={props.flex}>
      <Label $error={props.error} htmlFor={props.inputId}>
        {linhasLabel.map((linha: string, index: number) => (
          <p key={index}>
          {linha}
          <br />
          </p>))}
      </Label>
      <Seletor
        $error={props.error}
        id={props.inputId}
        onChange={(e) => props.onChange(e.target.value)}
        disabled={props.disabled}
        value={props.value}
      >
        {props.children}
      </Seletor>
      <Error $error={props.error}>{props.error}</Error>
    </ContainerReactSelect>
  );
}
