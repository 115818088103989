import React, { useEffect, useRef, useState } from "react";
import { DivListCategory, DivShowSeach, DivTag, Form } from "./styles";
import { ArrowDown, ArrowUp, Edit, Trash2Icon } from "lucide-react";
import { PlusCircle } from "lucide-react";
import { ModalTags } from "../Modals/ModalTags";
import { ModalShort } from "../Modals/ModalShort";
import { Divider } from "../Form/Divider";
import TagService from "../../Services/Tag.service";
import { CategoryEdit, EnumRoles, Tag, TagListed, } from "../../Utils/Types";
import { ModalRef } from "../Modals";
import { ModalListNew } from "../Modals/ModalListNew/ModalListNew";
import ListTags from "./ListTags";
import { SpinnerIcon } from "../../Pages/Registration/TagRegistration/styles";
import { Tags } from "../../Pages/Registration/ResourcesRegistration/Form3/Form3";

import * as zod from "zod";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Input } from "../Form/InputHook";

interface InputTagProps extends React.InputHTMLAttributes<HTMLInputElement> {
    text: string,
    selectedOption: any,
    table: any,
    setTable: any,
    color?: string,
    isResource?: boolean,
    user: any
    addTagList?: (tag: Tags) => void
    removeTagList?: (id: string) => void
    idCategory?: string
}

export default function InputTag({
    text,
    selectedOption,
    table,
    setTable,
    color,
    user,
    isResource,
    addTagList,
    removeTagList,
    idCategory,
    ...props
}: InputTagProps) {

    const [isTrue, setIsTrue] = useState(false)
    const [categorie, setCategorie] = useState<any>([])
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenSucess, setIsOpenSucess] = useState(false);
    const [title, setTitle] = useState("")
    const modalRef = useRef<ModalRef>(null);
    const [isLoading, setIsLoading] = useState(true)
    const [inputTag, setInputTag] = useState("")

    const [isEdit, setIsEdit] = useState(false)
    const [idTag, setIdTag] = useState("")
    const [isEditCategory, setIsEditCategory] = useState(false)

    const [editNameTag, setEditNameTag] = useState("")
    const [editDescriptionTag, setEditDescriptionTag] = useState("")
    const [message, setMessage] = useState("");

    const [nameEditCategory, setNameEditCategory] = useState("")
    const [justificationEditCategory, setJustificationEditCategory] = useState("")

    const [modalIsError, setModalIsError] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [modalDeleteCategory, setModalDeleteCategory] = useState(false)
    const [forDelete, setForDelete] = useState<any>("")

    const [informacoes, setInformacoes] = useState<Tag>({
        category: text,
        name: "",
        justification: "",
        idCategory: selectedOption
    });

    //Objeto de validação do formulário
    const formValidation = zod.object({
        name: zod.string()
            .min(1, "O nome da tag não pode ser vazia.")
            .max(10, "O nome da tag precisa de no máximo 10 caracteres.")
            .regex(/^[a-zA-Z0-9\s-]*$/, "O nome da tag só pode conter letras, números, espaços ou hifens.")
            .refine((value) => {
                if (!isEdit) {
                    const tagExists = tagsFiltered.some((tag: any) => tag.name.toLowerCase() === value.toLowerCase());
                    return !tagExists;
                }
                return true
            }, { message: "Essa tag já está cadastrada" }),
        justification: zod.string().optional(),
    })

    type FormData = zod.infer<typeof formValidation>;

    const formRef = useRef<HTMLFormElement>(null)
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        trigger
    } = useForm<FormData>({
        resolver: zodResolver(formValidation),
        defaultValues: {
            name: isEdit ? editNameTag : "",
            justification: isEdit ? editDescriptionTag : "",
        },
    });

    useEffect(() => {
        if (isEdit) {
            setValue("name", editNameTag);
        } else {
            setValue("name", "");
            setValue("justification", "");
        }
    }, [isEdit, setValue, editNameTag, editDescriptionTag]);

    const clearErrorField = (field: keyof FormData) => {
        if (errors[field]) {
            trigger(field);
        }
    };

    const onSubmit: SubmitHandler<FormData> = async (data) => {
        informacoes.name = data.name
        informacoes.justification = data.justification

        if (isEdit) {
            TagService.updateTag({
                name: informacoes.name,
                identifier: idTag,
                tagCategory: selectedOption
            }).then((response) => {
                if (response.success) {
                    setMessage("Tag editada!");
                    setIsEdit(false)
                    setIsOpenSucess(true)
                }
            })
        } else {
            if (user === EnumRoles.MASTER) {
                TagService.tagRegistration({ tag: informacoes })
                    .then((response) => {
                        setMessage("Tag cadastrada!");
                        setIsOpen(false)
                        setIsOpenSucess(true)
                    });
            } else {
                TagService.tagSugestion({
                    name: informacoes.name,
                    justification: informacoes.justification,
                    tag_category_id: selectedOption
                }).then((response) => {
                    setMessage("Tag sugerida!");
                    setIsOpen(false)
                    setIsOpenSucess(true)
                });
            }

        }
    }

    useEffect(() => {
        TagService.showDataTag({ identifier: idTag, tagCategory: selectedOption }).then((response) => {
            if (response.success) {
                setEditNameTag(response.data.name)
                setEditDescriptionTag(response.data.description)
            } else {
                throw new Error(response?.message);
            }
        }).catch((e) => { });
    }, [idTag, selectedOption])

    useEffect(() => {
        TagService.showCategorytag({ id: selectedOption }).then((response) => {
            if (response.success) {
                setNameEditCategory(response.data.name)
                setJustificationEditCategory(response.data.description)
            }
        })

    }, [selectedOption])

    useEffect(() => {
        setIsLoading(true)
        if (user === EnumRoles.MASTER) {
            setTitle("Cadastro de tags")
        } else {
            setTitle("Sugestão de tags")
        }
        TagService.showCategory({ id: selectedOption }).then((response) => {
            if (response.success) {
                setCategorie(response.data)
                setIsLoading(false)
            }
        })
    }, [selectedOption])

    const [tagsFiltered, setTagsFiltered] = useState<any>([])
    useEffect(() => {
        const filteredTags = categorie.filter((item: any) =>
            item.name.toLocaleLowerCase().includes(inputTag.toLocaleLowerCase()))
        setTagsFiltered(filteredTags)
    }, [inputTag, categorie])

    const [selectedItem, setSelectedItem] = useState(null);
    const handleItemClick = (id: any, tag: TagListed) => {
        setSelectedItem(id === selectedItem ? null : id);
        if (tag) {
            addTagList && addTagList({ ...tag, category: {name: text, identifier: idCategory ? idCategory : "" }});
        } else {
           
        }
    };

    const saveEditCategory = () => {
        const data: CategoryEdit = {
            name: nameEditCategory,
            description: justificationEditCategory
        }
        if (data.name !== "") {
            TagService.updateCategory({
                data: data,
                idCategory: selectedOption
            }).then((response) => {
                if (response.success) {
                    setMessage("Categoria editada!")
                    setIsEditCategory(false)
                    setIsOpenSucess(true)
                }
            })
        } else {
            alert("Nome da categoria vazia!")
        }
    }

    const deleteCategory = (idCategory: string) => {
        TagService.deleteCategory({ identifier: idCategory }).then((response) => {
            if (response.success) {
                window.location.reload();
            }
        })
    }

    const deleteTag = (idTag: string) => {
        TagService.deleteTag({
            identifier: idTag,
            tagCategory: selectedOption
        }).then((response) => {
            if (response.success) {
                window.location.reload();
            }
        })
    }

    return (
        <>
            <ModalListNew modalRef={modalRef} title={`Tags`} >
                <ListTags
                    categories={categorie}
                    isLoading={isLoading}
                />
            </ModalListNew>
            <ModalShort
                isOpen={isOpenSucess}
                type="success"
                text={message}
                onRequestClose={() => {
                    setIsOpenSucess(false)
                    window.location.reload();
                }}
            />
            <ModalShort
                isOpen={modalIsError}
                type="infoError"
                text={message}
                onConfirm={() => {
                    setModalIsError(false)
                    setIsOpen(true)
                }}
            />
            <ModalShort
                isOpen={modalDelete}
                type="delete"
                text={`Tem certeza que deseja excluir essa tag: ${forDelete.name} ?`}
                onRequestClose={() => {
                    setModalDelete(false)
                }}
                onConfirm={() => {
                    setModalDelete(false)
                    deleteTag(forDelete.identifier);
                }}
            />
            <ModalShort
                isOpen={modalDeleteCategory}
                type="delete"
                text="Tem certeza que deseja excluir essa categoria de tag?"
                onRequestClose={() => {
                    setModalDeleteCategory(false)
                }}
                onConfirm={() => {
                    setModalDeleteCategory(false)
                    deleteCategory(forDelete);
                }}
            />

            <DivTag color={color}>
                <div className="div-tag" onClick={() => setIsTrue(!isTrue)}>
                    <p className="p-text">{text}</p>
                    <button onClick={() => setIsTrue(!isTrue)}>
                        {isTrue ?
                            <ArrowUp />
                            :
                            <ArrowDown />
                        }
                    </button>
                </div>

                {isTrue === true && user === EnumRoles.MASTER && (
                    <div className="icon-action">
                        <button onClick={() => {
                            setIsEditCategory(true)
                        }}>
                            <Edit size={16} />
                        </button>
                        <button onClick={() => { setForDelete(selectedOption); setModalDeleteCategory(true) }}>
                            <Trash2Icon size={16} style={{ color: "red" }} />
                        </button>
                    </div>
                )}

                <>
                    <ModalTags
                        title="Editar Categoria"
                        type="EditCategory"
                        text="Categoria editado!"
                        isOpen={isEditCategory}
                        onRequestClose={() => { setIsEditCategory(false) }}
                        editFunction={() => { saveEditCategory() }}
                    >
                        <Form onSubmit={() => { }}>
                            <Input
                                required={true}
                                label="Nome da Categoria"
                                type="text"
                                value={nameEditCategory}
                                onChange={(event: any) => setNameEditCategory(event.target.value)}
                            />
                            <>
                                <p>Descrição</p>
                                <textarea
                                    name="justification"
                                    value={justificationEditCategory}
                                    onChange={(event) => setJustificationEditCategory(event.target.value)}
                                />
                            </>
                        </Form>
                    </ModalTags>
                </>
            </DivTag>

            {isTrue && (
                <>
                    <ModalTags
                        user={user}
                        isOpen={isOpen}
                        title={title}
                        type="tags"
                        onRequestClose={() => { setIsOpen(false) }}
                        onSave={() => {
                            formRef.current?.requestSubmit()
                        }}
                    >
                        <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                            <Divider>
                                <Input
                                    label="Categoria"
                                    flex="fx50"
                                    name="informacoes"
                                    type="text"
                                    required={false}
                                    disabled={true}
                                    value={informacoes.category}
                                    onChange={() => { }}
                                />
                                <Input
                                    label="Nome da Tag"
                                    flex="fx50"
                                    required={true}
                                    error={errors.name}
                                    type="text"
                                    {...register("name", {
                                        onChange: () => clearErrorField("name"),
                                    })}
                                />
                            </Divider>
                            {user === EnumRoles.MASTER ?
                                <>
                                </>
                                :
                                <>
                                    <p>Justificativa</p>
                                    <textarea
                                        {...register("justification", {
                                            onChange: () => clearErrorField("justification"),
                                        })}
                                    />
                                </>
                            }
                        </Form>
                    </ModalTags>

                    <DivShowSeach>
                        <Input
                            flex="fx90"
                            label="Nome"
                            type="text"
                            value={inputTag}
                            required={false}
                            onChange={(e) => setInputTag(e.target.value)}
                        />
                        <button title={title} onClick={() => {
                            setIsOpen(!isOpen); setValue("name", inputTag); 
                        }}>
                            <PlusCircle id="plusIcon" color="#fe8f2e" />
                        </button>
                    </DivShowSeach>
                    <br />
                    {isResource ?
                        <DivListCategory>
                            {isLoading ? <SpinnerIcon />
                                :
                                tagsFiltered.map((item: TagListed, index: number) =>
                                    <li
                                        onClick={() => {
                                            handleItemClick(index, item)
                                        }}
                                        style={{
                                            backgroundColor: selectedItem === index ? 'lightblue' : 'transparent',
                                            cursor: 'pointer',
                                            margin: '4px',
                                        }}
                                    >
                                        {item.name}
                                    </li>
                                )
                            }
                        </DivListCategory>
                        :
                        <DivListCategory>
                            <>
                                <ModalTags
                                    title="Editar Tag"
                                    type="editTags"
                                    text={message}
                                    isOpen={isEdit}
                                    onRequestClose={() => {
                                        setIsEdit(false)
                                    }}
                                    editFunction={() => {
                                        formRef.current?.requestSubmit()
                                    }}
                                >
                                    <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                                        <Divider>
                                            <Input
                                                required={false}
                                                label="Categoria"
                                                flex="fx50"
                                                type="text"
                                                disabled={true}
                                                value={text}
                                            />
                                            <Input
                                                label="Nome da Tag"
                                                flex="fx50"
                                                type="text"
                                                required={true}
                                                error={errors.name}
                                                {...register("name", {
                                                    onChange: () => clearErrorField("name"),
                                                })}
                                            />
                                        </Divider>
                                        {/* <>
                                            <p>Justificativa</p>
                                            <textarea
                                                {...register("justification", {
                                                    onChange: () => clearErrorField("justification"),
                                                })}
                                            />
                                        </> */}
                                    </Form>
                                </ModalTags>

                                {isLoading ? <SpinnerIcon />
                                    :
                                    tagsFiltered.map((item: TagListed) =>
                                        <ul key={item.identifier}>
                                            <li>{item.name}</li>
                                            {user === EnumRoles.MASTER && (
                                                <li className="li-action">
                                                    <Edit size={14} onClick={() => {
                                                        setIsEdit(true)
                                                        setIdTag(item.identifier)
                                                    }} />
                                                    <Trash2Icon size={14} style={{ color: "red" }}
                                                        onClick={() => {
                                                            setModalDelete(true);
                                                            setForDelete(item)
                                                        }} />
                                                </li>
                                            )}
                                        </ul>
                                    )
                                }
                            </>
                        </DivListCategory>
                    }
                </>
            )}
        </>
    )
}