import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Upload } from 'lucide-react'
import { ButtonImport, ContainerImportEmployees } from './styles'
import { NewEmployee } from '../../../../Utils/Types'
import { read, utils } from 'xlsx'
import FuncionarioService from '../../../../Services/Funcionario.service'


interface ImportEmployeeProps {
    listEmployee: NewEmployee[],
    setListEmployee: Dispatch<SetStateAction<NewEmployee[]>>
}

const ImportEmployee = ({ listEmployee, setListEmployee }: ImportEmployeeProps) => {

    const [pres, setPres] = useState<NewEmployee[]>([{
        birth_date: "",
        cpf: "",
        email: "",
        name: "",
        phone: "",
        role_id: ""
    }])

    useEffect(() => {
        setPres([])
    }, [])

    async function handleImport(e: any) {
        if (!e.target.files) return
        const f = await e.target.files[0].arrayBuffer()
        const wb = read(f)
        const data = utils.sheet_to_json<NewEmployee>(wb.Sheets[wb.SheetNames[0]])
        setPres([...pres, ...data])
        setListEmployee([...pres, ...data])
    }

    // function addEmployees(){
    //     Promise.all(listEmployee.map((employee)=>{

    //         let birthDate = employee.birth_date.toString();
    //         let formatedDate = employee.birth_date.toString().substring(1, birthDate.length - 1);

    //         const data:NewEmployee = {
    //             birth_date: formatedDate,
    //             cpf: employee.cpf,
    //             email: employee.email,
    //             name: employee.name,
    //             phone: employee.phone,
    //             role_id: employee.role_id,
    //         }

    //         FuncionarioService.createEmployee({data: data, unitId: employee.idUnit})

    //         return;
    //     })).then(()=>{
    //         alert("Usuários cadastrados!")
    //         window.location.reload();
    //     })
    // }

    // useEffect(()=>{
    //     addEmployees();
    // },[listEmployee])

    return (
        <ContainerImportEmployees>
            <ButtonImport>
                <label htmlFor="uploadid">
                    <Upload className="hvr-icon" size={16} />
                    Importar Funcionários
                </label>
                <input
                    type="file"
                    name=""
                    id="uploadid"
                    onChange={(e) => handleImport(e)}
                />
            </ButtonImport>
        </ContainerImportEmployees>
    )
}

export default ImportEmployee
