import { useQuery } from "react-query";
import { Queries } from "../../types/queries";
import BNCCService from "../../Services/BNCC.service";

export function useListBNCCYears(stage_id?: string, phase?: string) {

    console.log("Teste no hook", stage_id, phase)

    return useQuery(
        Queries.LIST_BNCC_YEARS,
        () =>
            BNCCService.listBNCCYears(stage_id,phase).then((res) => {
                console.log("Teste no service do hook", stage_id, phase)

                if (res?.success) {
                    return res.data;
                }
                throw new Error(res?.message);
            }),
        {

            retry: true,
            cacheTime: 5 * 60 * 1000,
            refetchInterval: false,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        }
    )
}