import { Edit, ToggleLeft } from 'lucide-react';
import React, { Dispatch, SetStateAction } from 'react'
import { ListDefault } from '../../../../Components/ListDefault';
import { SearchTable } from '../../../../Components/SearchTable';
import { Link } from 'react-router-dom';
import { LinkView } from './style';
import { Input } from '../../../../Components/Form/Input';

interface Props {
    pages: any,
    setSwitchPageListEmployee: any,
    metaTo: any,
    metaTotal: any,
    employees: any,
    setSelected: any,
    setSwitchScreen: any,
    setTypeProfile: any,
    onEditEmployee: any,
    inputSearchView: string,
    setInputSearchView: Dispatch<SetStateAction<string>>
}

const ListManagerEmployee = ({
    employees,
    metaTo,
    metaTotal,
    pages,
    setSelected,
    setSwitchPageListEmployee,
    setSwitchScreen,
    setTypeProfile,
    onEditEmployee,
    inputSearchView,
    setInputSearchView
}: Props) => {
    return (
        <ListDefault
            onBack={() => {
                window.history.back();
            }}
            pages={pages}
            changeGet={(v: any) => setSwitchPageListEmployee(v)}
            to={metaTo}
            total={metaTotal}
        >
            <thead>
                <tr>
                    <td colSpan={6}>
                        <Input
                            flex="fx30"
                            type="search"
                            placeHolderTeacher="Pesquise por um gestor"
                            color="#039bc9"
                            value={inputSearchView}
                            onChange={setInputSearchView}
                        />
                    </td>
                </tr>
                <tr>
                    <td className="wd25">Nome</td>
                    <td className="wd15">Cargo/Função</td>
                    <td className="wd20">Unidade(s) de Ensino</td>
                    <td className="wd20">Email</td>
                    <td className="wd10">Telefone</td>
                    <td className="wd10">Ação</td>
                </tr>
            </thead>
            <tbody>
                {employees
                    ?.filter(
                        (option: any) =>
                            option.user.roles[0].slug === "management-employee"
                    )
                    .map((option: any) => {
                        return (
                            <tr>
                                <td style={{ width: "20px" }}>
                                    <LinkView
                                        onClick={() => {
                                            setSelected(option);
                                            setSwitchScreen(2);
                                            setTypeProfile(3);

                                        }}
                                    >
                                        {option?.user.name}
                                    </LinkView>
                                </td>
                                <td>
                                    {option.user.roles[0]
                                        ? option.user.roles[0].name
                                        : "Não definido"}
                                </td>
                                <td>
                                    {option.educationInstitution
                                        ? option.educationInstitution.name
                                        : "Não definido"}
                                </td>
                                <td>{option.user.email}</td>
                                <td>{option.user.phone}</td>
                                <td>
                                    <button
                                        className="hvr-grow"
                                        onClick={() => {
                                            onEditEmployee(option)
                                            setSwitchScreen(3)
                                        }}
                                    >
                                        <Edit />
                                    </button>
                                    <button
                                        className="hvr-grow"
                                        style={{ color: "green", marginLeft: "10px" }}
                                    >
                                        <ToggleLeft />
                                    </button>
                                </td>
                            </tr>
                        );
                    })}
            </tbody>
        </ListDefault>
    )
}

export default ListManagerEmployee
