import styled from "styled-components";

export const InputSearch = styled.div`
  height: 45px;
  border: 1px solid #646464;
  border-radius: 15px;
  padding: 0px 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  :hover {
    cursor: pointer;
  }
  input {
    width: 100%;
    border: none;
    height: 100%;
    outline: none;
    font-size: 14px;
  }
`;
export const ContainerIcon = styled.div`    
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    gap: 10px;
    input {
      flex: 1;
    }
    .icon {
        width: 30px;
        height: 30px;
        :hover {
        cursor: pointer;
        }
    }
    `;
export const SearchIcon = styled(InputSearch)`
  flex: 1;
  .icon{
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`;
export const Container = styled.section`
  display: flex;

  .checks {
    padding: 0;
  }

  .messager {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
    color: #027294;
  }

  .container-header-responsible {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  #addIcon {
    width: 30px;
    height: 30px;
  }
  .btnNewResponsible {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #f37d15;
    width: 100px;
    font-size: 25px;
    opacity: 1;
    p {
      margin-left: 3px;
    }
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }
  .btnSearchResponsible {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    color: #0b9236;
    width: 130px;
    font-size: 25px;
    opacity: 1;
    p {
      margin-left: 3px;
    }
    :hover {
      cursor: pointer;
      opacity: 0.7;
    }
  }

  > div.box {
    width: 100%;
  }
`;

export const Sitemap = styled.div`
  display: flex;
  gap: 5px;
  font-size: 14px;
  color: #646464;
  width: 100%;
  padding-bottom: 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
`;

export const Main = styled.main`
  max-width: 800px;
  width: 100%;
`;

export const Form = styled.form`
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  > div {
    width: 100%;
  }
  margin-top: 10px;
`;

export const Title = styled.p`
  background-color: #027294;
  color: #fff;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
  font-size: 20px;
`;
