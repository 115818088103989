import React, { useEffect, useState } from 'react'
import { Divider } from '../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../Components/Wizard'
import { Form } from '../styles'
import { Select } from '../../../../Components/Form/Select'
import { Input } from '../../../../Components/Form/Input'
import { Controllers } from '../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import Review, { GuardianData } from './Review/Review'
import ReviewGuardianRegistration from './Review/Review'
import StudentService from '../../../../Services/Student.service'
import { EnumEmployees } from '../../../../Utils/Types'
import { DivShowStudents } from './style'
import { Trash2 } from 'lucide-react'

const FormGuardian = (props: any) => {

    const [stepForm, setStepForm] = useState(1)
    const navigate = useNavigate()
    const unit = JSON.parse(sessionStorage.getItem("locationData")!);
    const [name, setName] = useState<string>("")
    const [cpf, setCpf] = useState<string>("")
    const [date, setDate] = useState<string>("")
    const [phone, setPhone] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [confirmEmail, setConfirmEmail] = useState<string>("")
    const [studentsArray, setStudentsArray] = useState<string[]>([])
    const [listStudents, setListStudents] = useState<any[]>([])
    const [listStudentsSelecteds, setListStudentsSelecteds] = useState<any[]>([])

    useEffect(() => {
        switch (props.user) {
            case EnumEmployees.GUARDIAN:
                StudentService.list({ unitId: unit.identifier }).then((response) => {
                    if (response.success) {
                        setListStudents(response.data)
                    }
                }
                )
                break;

            default:
                return
                break;
        }

    }, [])
    function formatarData(inputData: string) {
        // Verifica se a entrada tem a quantidade correta de caracteres
        if (inputData.length !== 8) {
            return "Formato de data inválido";
        }

        // Extrai o dia, mês e ano da string de entrada
        const dia = inputData.slice(0, 2);
        const mes = inputData.slice(2, 4);
        const ano = inputData.slice(4);

        // Retorna a data formatada
        return `${dia}/${mes}/${ano}`;
    }

    const dataGuardianSave: GuardianData = {
        name: name,
        cpf: cpf,
        birth_date: formatarData(date),
        email: email,
        phone: phone,
        students: studentsArray
    }

    function nextStep() {
        if (name === "") {
            alert("Preencha o nome.")
        } else if (cpf === "") {
            alert("Preencha o CPF")
        } else if (date === "") {
            alert("Preencha a data.")
        } else if (phone === "") {
            alert("Preencha  o telefone.")
        } else if (email === "") {
            alert("Preencha o email.")
        } else if (confirmEmail === "") {
            alert("Confirme seu email.")
        }else if(studentsArray.length === 0){
            alert("Selecione os estudantes.")
        }else{
            setStepForm(2)
        }
    }

    function handleIdStudents(e: any) {
        setStudentsArray((state) => {
            return [...state, e];
        })


        StudentService.show({ educationInstitution: unit.identifier, studentIdentifier: e }).then((response) => {
            if (response.success) {
                setListStudentsSelecteds((state) => {
                    return [...state, response.data]
                })
            }
        })
    }

    function removeStudentsSelecteds(idStudent: any) {
        const index = listStudentsSelecteds.findIndex((item) => item.identifier === idStudent);
        if (index === -1) {
            return
        }
        const uptatedStudents = [...listStudentsSelecteds.slice(0, index), ...listStudentsSelecteds.slice(index + 1)];
        setListStudentsSelecteds(uptatedStudents)
    }



    return (
        <>
            <Divider>
                <Wizard>
                    <WizardStep
                        text={`Responsável`}
                        active={stepForm === 1}
                    />
                    <WizardStep text="Revisar" active={stepForm === 2} />
                </Wizard>
            </Divider>
            {stepForm === 1 && (

                <Form onSubmit={() => { }}>
                    <Divider>
                        <Select
                            flex="fx40"
                            label="Aluno(s)"
                            value={null}
                            onChange={handleIdStudents}
                        >
                            <option value="">Selecione um estudante</option>
                            {listStudents.map((item: any, index: number) => {
                                return (
                                    <option
                                        value={item.identifier}
                                        key={index}
                                    >
                                        {item.name}
                                    </option>
                                )
                            })}
                        </Select>
                    </Divider>
                    <Divider>
                        <Input
                            label="Nome"
                            type="text"
                            flex="fx100"
                            required
                            value={name}
                            onChange={setName}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="CPF"
                            required
                            type="cpf"
                            flex="fx33"
                            value={cpf}
                            onChange={setCpf}
                        />
                        <Input
                            label="Data de Nascimento"
                            required
                            type="date"
                            flex="fx33"
                            value={date}
                            onChange={setDate}
                        />
                        <Input
                            label="Telefone"
                            required
                            type="tel2"
                            flex="fx33"
                            value={phone}
                            onChange={setPhone}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={email}
                            onChange={setEmail}
                        />
                        <Input
                            label="Confirmar E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={confirmEmail}
                            onChange={setConfirmEmail}
                        />
                    </Divider>
                    {listStudentsSelecteds.length > 0
                        ?
                        <Divider>
                            <DivShowStudents>
                                <h3>Estudantes selecionados</h3>

                                {listStudentsSelecteds.map((students, index) => {
                                    return (
                                        <ul key={index}>
                                            <li>{students.name}</li>
                                            <Trash2
                                                color='red'
                                                size={16}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => removeStudentsSelecteds(students.identifier)}
                                            />
                                        </ul>
                                    )
                                })}
                            </DivShowStudents>
                        </Divider>
                        :
                        <></>
                    }
                    <Controllers
                        functionNext={() => { nextStep() }}
                        functionPrev={() => {
                        }}
                        functionCancel={() => { navigate("/unidade/usuario/list") }}
                        enableNext={true}
                        enablePrev={false}
                    />
                </Form>

            )}
            {stepForm === 2 && (
                <ReviewGuardianRegistration
                    stepForm={stepForm}
                    setStepForm={setStepForm}
                    dataGuardian={dataGuardianSave}
                    studentsSelecteds={listStudentsSelecteds}
                    unit={unit}
                />
            )}
        </>
    )
}

export default FormGuardian