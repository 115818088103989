import styled from "styled-components";

export const Div = styled.div`

display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 250px;
margin-bottom: 20px;

.addImage{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d9d9d9;
    width: 50%;
    height: 100%;
    &label{
        color: black;
    }
    :hover{
        cursor: pointer;
    }
}
.desc{
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
    padding: 15px;
}

.items{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 30px;
}

.icon{  
    margin-left: 5px;
    :hover{
        cursor: pointer;
    }
}

`