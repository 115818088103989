import styled from 'styled-components';
export const Container = styled.div`
width: 100%;
display: flex;
>div.content{
    flex: 1;
    padding: 0px 30px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
`;