import styled from 'styled-components';
export const Container = styled.div`
position: relative;
width: 250px;
float: right;

input{
    height: 30px;
    border-radius: 10px;
    border: 1px solid #ccc;
    width: 100%;
    padding: 0px 30px 0px 5px;
}
button{
    width: 30px;
    height: 30px;
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #027294;
    cursor: pointer;
}
`;