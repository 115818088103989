import { useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { Input } from "../../../../Components/Form/Input";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { Form, Title } from "../styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TechService from "../../../../Services/Tech.service";
import { Select } from "../../../../Components/Form/Select";
import RoleService from "../../../../Services/Role.service";
import { EnumRoles } from "../../../../Utils/Types";
import { formatarCpf, formatarFone, nextStepForm } from "../../../../Utils/Functions";

export function FormTech(props: any) {
  const [stepForm, setStepForm] = useState<number>(1);
  const [modalSave, setModalSave] = useState(false);
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [responsible, setResponsible] = useState("");
  const navigate = useNavigate();
  const [roles, setRoles] = useState<any[]>([])
  const [levelSelected, setLevelSelected] = useState("");
  const user = JSON.parse(String(localStorage.getItem('roles')))


  useEffect(() => {

    RoleService.get().then((response) => {
      if (response?.success) {
        setRoles(response.data)
      }
    })

  }, [])

  function verification() {
    if (
      name === "" ||
      phone === "" ||
      email === "" ||
      confirmEmail === ""
    ) {
      alert("Preencha todos os campos obrigatórios");
      return;
    }
    if (email !== confirmEmail) {
      alert("Os emails não coincidem");
      return;
    }
    if (levelSelected === "") {
      alert("Selecione um nível.")
      return;
    }
    nextStepForm(setStepForm, 2)
  }

  async function saveTech() {
    const data = {
      name: name,
      company: company,
      role: "Técnico",
      phone: phone,
      responsible_name: responsible,
      login: email,
      email: email,
      cpf: cpf,
      role_id: levelSelected,
    };
    TechService.create(data).then((response) => {
      if (response?.success) {
        setModalSave(true);
        setStepForm(2);
      }
    });
  }

  function backToScreenPreview() {
    navigate(-1);
  }

  const [showRole, setShowRole] = useState<any>([])

  useEffect(() => {

    RoleService.showRole({ identifier: levelSelected }).then((response) => {
      if (response?.success) {
        setShowRole(response.data)
      }
    })

  }, [levelSelected])


  function renderPhoneFormated(phone: any) {
    const newPhone = formatarFone(phone)

    return (
      <td>
        <span>Telefone:</span>
        {newPhone}
      </td>
    )
  }

  function renderCpfFormated(cpf: any){
    const newCPF = formatarCpf(cpf);

    return (
      <td>
        <span>CPF:</span>
        {newCPF}
      </td>
    )
  }

  return (
    <>
      {stepForm === 1 && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Title>Técnico</Title>
          <Input
            label="Nome"
            type="text"
            onChange={(e: any) => setName(e)}
            value={name}
            required
          />
          <Divider>
            <Input
              flex="fx33"
              type="cpf"
              label="CPF"
              onChange={(e: any) => setCpf(e)}
              value={cpf}
            />
            <Input
              flex="fx33"
              type="tel"
              label="Telefone"
              onChange={(e: any) => setPhone(e)}
              value={phone}
              required
            />
            <Input
              flex="fx33"
              label="Cargo"
              type="text"
              onChange={(e: any) => setRole(e)}
              value={role}
            />
          </Divider>
          <Divider>
            <Input
              flex="fx50"
              type="text"
              label="Empresa"
              onChange={(e: any) => setCompany(e)}
              value={company}
            />
            {
              user === EnumRoles.MASTER ? (
                <Input
                  flex="fx50"
                  label="Nome do Responsável Atual (Somente para nível Master)"
                  onChange={(e: any) => setResponsible(e)}
                  type="text"
                  value={responsible}
                />
              ) : null
            }
          </Divider>
          <Divider>
            <Input
              flex="fx50"
              type="email"
              label="Email"
              onChange={(e: any) => setEmail(e)}
              value={email}
              required
            />
            <Input
              flex="fx50"
              type="email"
              label="Confirmar Email"
              onChange={(e: any) => setConfirmEmail(e)}
              value={confirmEmail}
              required
            />
          </Divider>
          <Divider>
            <Select
              label="Nível(grau de permissões do usuário)"
              flex="fx40"
              onChange={setLevelSelected}
              value={levelSelected}
            >
              <option value="">Selecione um nível</option>
              {roles
                .filter((item: any) => item.userType === "technician")
                .map((role: any, index: number) => {
                  return (
                    <>
                      <option value={role.slug} key={index}>{role.name}</option>
                    </>
                  )
                })}
            </Select>
          </Divider>
          <Controllers
            functionNext={() => {
              // verification()

              nextStepForm(setStepForm, 2)
            }}
            functionPrev={() => {
              props.onPrev();
            }}
            functionCancel={backToScreenPreview}
            enableNext={true}
            enablePrev={false}
          />
        </Form>
      )}
      {stepForm === 2 && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <ModalShort
            type="success"
            text="Cadastro salvo com sucesso"
            isOpen={modalSave}
            onRequestClose={backToScreenPreview}
            onConfirm={backToScreenPreview}
          />
          <BoxResume label="Verifique se os dados que você cadastrou estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.">
            <tr className="divider">
              <td>
                <span>Técnico</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Nome:</span>
                {name}
              </td>
            </tr>
            <tr>
              {renderCpfFormated(cpf)}
            </tr>
            <tr>
              <td>
                <span>Empresa:</span>
                {company}
              </td>
            </tr>
            <tr>
              <td>
                <span>Cargo:</span>
                {role}
              </td>
            </tr>
            <tr>
              {renderPhoneFormated(phone)}
            </tr>
            <tr>
              <td>
                <span>Responsável:</span>
                {responsible}
              </td>
            </tr>
            <tr>
              <td>
                <span>Email:</span>
                {email}
              </td>
            </tr>
            <tr>
              <td>
                <span>Nível selecionado:</span>
                {showRole.name}
              </td>
            </tr>
          </BoxResume>
          <Controllers
            save={true}
            functionPrev={() => setStepForm(1)}
            functionSave={() => saveTech()}
            functionCancel={backToScreenPreview}
            enableNext={true}
            enablePrev={true}
          />
        </Form>
      )}
    </>
  );
}
