import { useState } from "react";
import { LeftMenu } from "../../Components/LeftMenu";
import { Banner, Container, ListProfile, Main, Sitemap } from "./styles";
import { PlusIcon, Trash2 } from "lucide-react";
import { CustomFunctions } from "./CustomFunctions";
import { ViewCustomFunctions } from "./CustomFunctions/View";
import { ViewSystemProfiles } from "./SystemProfiles/View";
import { ViewClientProfiles } from "./ClientProfiles/View";

export function UsersProfiles() {
    const [isOpenFirs, setIsOpenFirst] = useState(false);
    const [isOpenSecond, setIsOpenSecond] = useState(false);
    const [isOpenThird, setIsOpenThird] = useState(false);

    const [switchScreen, setSwitchScreen] = useState(1);
    const [editViewMode, setEditViewMode] = useState("")

    // Chamar o cadastrar Funções Personalizadas
    const handleCustomFunction = () => {
        setSwitchScreen(2)
        setEditViewMode("register")
    }

    // chamar a visualização do Funções Personalizadas
    const handleViewCustomFunction = () => {
        setEditViewMode("view")
        setSwitchScreen(3)
    }

    // chamar visualização do Perfis de Sistema
    const handleViewSystemProfiles = () => {
        setSwitchScreen(4)
        setEditViewMode("view")
    }

    // chamar visualização do Perfis de Cliente
    const handleViewClientProfiles = () => {
        setSwitchScreen(5)
        setEditViewMode("view")
    }

    // função voltar
    function back() {
        setSwitchScreen(1);
    }


    return (
        <Container>
            <LeftMenu />
            <div className="box">
                <Main>
                    <Sitemap>
                        <p>Perfis</p> <span>&bull;</span> <p>Usuário</p>
                    </Sitemap>

                    {switchScreen === 1 && (
                        <>

                            <section>


                                <Banner className={isOpenFirs ? 'opened' : ''} onClick={() => setIsOpenFirst(!isOpenFirs)}>
                                    <div className="box-banner">
                                        <div className="itens">
                                            <p>Perfis de Sistema</p>
                                            <div onClick={() => setIsOpenFirst(!isOpenFirs)}>
                                                <span className="arrow-icon"></span>
                                            </div>
                                        </div>
                                    </div>
                                </Banner>

                                {isOpenFirs && (
                                    <ListProfile>
                                        <table >
                                            <thead>
                                                <tr>
                                                    <td>Perfil de Usuário</td>
                                                    <td>Nível de Permissão</td>
                                                    <td>Acesso</td>
                                                    <td>Descrição</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td className="wd20">
                                                        <button className="profile" onClick={handleViewSystemProfiles}>Técnico/Administrador</button>
                                                    </td>
                                                    <td className="wd15">Básico</td>
                                                    <td className="wd30">Portal Administrativo, Portal do Professor, Portal do aluno</td>
                                                    <td className="wd30">Educadores que auxiliam no mapeamento do cadastro de recursos.</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </ListProfile>
                                )}

                            </section>


                            <section>


                            <Banner className={isOpenSecond ? 'opened' : ''} onClick={() => setIsOpenSecond(!isOpenSecond)}>
                                <div className="box-banner">
                                    <div className="itens">
                                        <p>Perfis de Cliente</p>
                                        <div onClick={() => { setIsOpenSecond(!isOpenSecond) }}>
                                            <span className="arrow-icon"></span>
                                        </div>
                                    </div>
                                </div>
                            </Banner>

                            {isOpenSecond && (
                                <ListProfile>
                                    <table >
                                        <thead>
                                            <tr>
                                                <td>Perfil de Usuário</td>
                                                <td>Função</td>
                                                <td>Acesso</td>
                                                <td>Descrição</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="wd20">
                                                    <button className="profile" onClick={handleViewClientProfiles}>Funcionário</button>
                                                </td>
                                                <td className="wd15">Gestor de Unidade</td>
                                                <td className="wd30">Portal Administrativo, Portal do Professor</td>
                                                <td className="wd30">Usuário responsável pela contratação do serviço do Aprendiz Digital.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ListProfile>
                            )}

                            </section>


                            <section>


                            <Banner className={isOpenThird ? 'opened' : ''} >
                                <div className="box-banner" onClick={() => setIsOpenThird(!isOpenThird)}>
                                    <div className="itens">
                                        <p>Funções Personalizadas</p>
                                        <div onClick={() => { setIsOpenThird(!isOpenThird) }}>
                                            <span className="arrow-icon"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="box-button">
                                    <button onClick={handleCustomFunction}>Nova <PlusIcon size={10} /></button>
                                </div>
                            </Banner>

                            {isOpenThird && (
                                <ListProfile>
                                    <table >
                                        <thead>
                                            <tr>
                                                <td>Perfil de Usuário</td>
                                                <td>Nível de Permissão</td>
                                                <td>Acesso</td>
                                                <td>Descrição</td>
                                                <td></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="wd15">
                                                    <button className="profile" onClick={() => handleViewCustomFunction()}>Funcionário</button>
                                                </td>
                                                <td className="wd15">Facilitador</td>
                                                <td className="wd33">Portal Administrativo, Portal do Professor, Portal do aluno</td>
                                                <td className="wd34">Educadores que auxiliam no mapeamento do cadastro de recursos.</td>
                                                <td className="wd10">
                                                    <button className="hvr-grow">
                                                        <Trash2 size={23} />
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </ListProfile>
                            )}

                            </section>



                        </>
                    )}


                    {/* Cadastrar nova função  */}
                    {switchScreen === 2 && (
                        <CustomFunctions
                            back={back}
                            editViewMode={editViewMode}
                        />
                    )}

                    {/* Visualizar função */}
                    {switchScreen === 3 && (
                        <ViewCustomFunctions
                            back={back}
                            editViewMode={editViewMode}
                        />
                    )}

                    {/* visualizar perfil de sistema */}
                    {switchScreen === 4 && (
                        <ViewSystemProfiles
                            back={back}
                            editViewMode={editViewMode}
                        />
                    )}

                    {/* visualizar perfil de cliente */}
                    {switchScreen === 5 && (
                        <ViewClientProfiles
                            back={back}
                            editViewMode={editViewMode}
                        />
                    )}



                </Main>
            </div>
        </Container>
    )
}