import { ICategoriesCollection } from "../../Services/Collection.service";
import {
  IAuthorshipResource,
  IPedagogicalResource,
  IResourceCollection,
  ITagsResource,
  TFieldsBNCCEF,
  TFieldsBNCCEI,
  TFieldsBNCCEM,
} from "./reducer";

export enum ActionTypes {
  HANDLE_FIELD_STRING,
  ADD_AUTHORSHIP,
  REMOVE_AUTHORSHIP,
  ADD_CONTENT_DESCRITORS,
  REMOVE_CONTENT_DESCRITORS,
  ADD_CATEGORY_COLLECTION,
  REMOVE_CATEGORY_COLLECTION,
  HANDLE_THUMBNAILS,
  HANDLE_LICENSE,
  ADD_INTELLECTUAL_RIGHTS,
  REMOVE_INTELLECTUAL_RIGHTS,
  HANDLE_PARENTAL_RATING,
  ADD_BNCC_EF_FIELDS,
  REMOVE_BNCC_EF_FIELDS,
  ADD_BNCC_EM_FIELDS,
  REMOVE_BNCC_EM_FIELDS,
  ADD_BNCC_EI_FIELDS,
  REMOVE_BNCC_EI_FIELDS,
  ADD_BNCC_BASIC_COMPONENT,
  REMOVE_BNCC_BASIC_COMPONENT,
  SET_BNCC_EI_FIELDS,
  SET_BNCC_EM_FIELDS,
  SET_BNCC_EF_FIELDS,
  SET_PEDAGOGICAL,
  SET_TAGS,
  SET_FIELDS_ARRAY,
  HANDLE_RESOURCE,
  SET_STORY_BOARD
}

export function setStoryBoardAction(value: string){

  console.log(value)

  return{
    type: ActionTypes.SET_STORY_BOARD,
    payload: value
  }
}

export function handleResourceAction(value: any) {
  console.log(value);
  return {
    type: ActionTypes.HANDLE_RESOURCE,
    payload: value ,
  };
}
export function setTagsAction(value: ITagsResource){
  return{
    type: ActionTypes.SET_TAGS,
    payload: { value },
  }

}

export function setFieldsArrayAction(
  value: string[],
  key: "collection" | "target_audience" | "plataforms" | "equivalent_resources"
) {
  return {
    type: ActionTypes.SET_FIELDS_ARRAY,
    payload: { value, key },
  };
}

export function setPedagogicalAction(value: IPedagogicalResource) {
  return {
    type: ActionTypes.SET_PEDAGOGICAL,
    payload: { value },
  };
}
export function setBNCCEiFields(value: string[], key: TFieldsBNCCEI) {
  return {
    type: ActionTypes.SET_BNCC_EI_FIELDS,
    payload: { value, key },
  };
}

export function setBNCCEmFields(value: string[], key: TFieldsBNCCEM) {
  return {
    type: ActionTypes.SET_BNCC_EM_FIELDS,
    payload: { value, key },
  };
}

export function setBNCCEfFields(value: string[], key: TFieldsBNCCEF) {
  return {
    type: ActionTypes.SET_BNCC_EF_FIELDS,
    payload: { value, key },
  };
}

export function addBNCCBasicComponent(value: number) {
  return {
    type: ActionTypes.ADD_BNCC_BASIC_COMPONENT,
    payload: { value },
  };
}

export function removeBNCCBasicComponent(value: number) {
  return {
    type: ActionTypes.REMOVE_BNCC_BASIC_COMPONENT,
    payload: { value },
  };
}

export function addBNCCEiFields(value: string, key: TFieldsBNCCEI) {
  return {
    type: ActionTypes.ADD_BNCC_EI_FIELDS,
    payload: { value, key },
  };
}

export function removeBNCCEiFields(value: string, key: TFieldsBNCCEI) {
  return {
    type: ActionTypes.REMOVE_BNCC_EI_FIELDS,
    payload: { value, key },
  };
}

export function addBNCCEmFields(value: string, key: TFieldsBNCCEM) {
  return {
    type: ActionTypes.ADD_BNCC_EM_FIELDS,
    payload: { value, key },
  };
}

export function removeBNCCEmFields(value: string, key: TFieldsBNCCEM) {
  return {
    type: ActionTypes.REMOVE_BNCC_EM_FIELDS,
    payload: { value, key },
  };
}

export function addBNCCEfFields(value: string, key: TFieldsBNCCEF) {
  return {
    type: ActionTypes.ADD_BNCC_EF_FIELDS,
    payload: { value, key },
  };
}

export function removeBNCCEfFields(value: string, key: TFieldsBNCCEF) {
  return {
    type: ActionTypes.REMOVE_BNCC_EF_FIELDS,
    payload: { value, key },
  };
}

export function handleParentalRatingAction(value: string) {
  return {
    type: ActionTypes.HANDLE_PARENTAL_RATING,
    payload: { value },
  };
}

export function addIntelectualRightsAction(name: string) {
  return {
    type: ActionTypes.ADD_INTELLECTUAL_RIGHTS,
    payload: { name },
  };
}

export function removeIntelectualRightsAction(name: string) {
  return {
    type: ActionTypes.REMOVE_INTELLECTUAL_RIGHTS,
    payload: { name },
  };
}

export function handleLicenseAction(license: string) {
  return {
    type: ActionTypes.HANDLE_LICENSE,
    payload: { license },
  };
}

export function handleImgAction(thumbnails: File[] ) {
  return {
    type: ActionTypes.HANDLE_THUMBNAILS,
    payload: { thumbnails },
  };
}

export function handleFieldResourceAction(
  value: boolean | string | number,
  field: string
) {
  return {
    type: ActionTypes.HANDLE_FIELD_STRING,
    payload: { value, field },
  };
}

export function addAuthorShipAction(
  author: string,
  key: keyof IAuthorshipResource
) {
  return {
    type: ActionTypes.ADD_AUTHORSHIP,
    payload: { author, key },
  };
}

export function removeAuthorShipAction(
  author: string,
  key: keyof IAuthorshipResource
) {
  return {
    type: ActionTypes.REMOVE_AUTHORSHIP,
    payload: { author, key },
  };
}

export function addContentDescritorsAction(descritor: string) {
  return {
    type: ActionTypes.ADD_CONTENT_DESCRITORS,
    payload: { descritor },
  };
}

export function removeContentDescritorsAction(descritor: string) {
  return {
    type: ActionTypes.REMOVE_CONTENT_DESCRITORS,
    payload: { descritor },
  };
}

export function addCategoryCollectionAction(category: IResourceCollection) {
  return {
    type: ActionTypes.ADD_CATEGORY_COLLECTION,
    payload: { category },
  };
}

export function removeCategoryCollectionAction(
  category: IResourceCollection
) {
  return {
    type: ActionTypes.REMOVE_CATEGORY_COLLECTION,
    payload: { category },
  };
}
