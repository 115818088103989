import { useContext, useEffect } from "react";
import { useListPlataforms } from "../../hooks/useListPlataforms";
import { useListTargetAudience } from "../../hooks/useListTargetAudience";
import { FormContainer, ListItensSelect, Row } from "../../styles";
import { ResourceForm1Context } from "../../../../../../Contexts/Form/ContextResouceForm1";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";
import { Select } from "../../../../../../Components/Form/Select";

function getLabelPlataform(type: string) {
  switch (type) {
    case "operational-system":
      return "Plataforma\nSistema Operacional";
    case "device":
      return "Plataforma\nDispositivos recomendados";
    case "browser":
      return "Plataforma\nNavegadores recomendados";
    default:
      return "Plataforma";
  }
}
export function FormDestination() {
  const {
    setTargetAudienceSelectForm,
    setPlataformSelectForm,
    plataformSelect,

    targetAudienceSelect,
  } = useContext(ResourceForm1Context);

  const { resource } = useContext(ResourceContext);
  const {
    data: listTargetAudience,
    isLoading: loadingTargerAudience,
    isError: errorTargetAudience,
  } = useListTargetAudience();

  const {
    data: listPlataforms,
    isLoading: loadingPlataforms,
    isError: errorPlataforms,
  } = useListPlataforms();

  const typesPlataforms = listPlataforms?.map((plataform: any) => plataform.type).filter(
    (value: any, index: number, self: any) => self.indexOf(value) === index
  )

  useEffect(() => {
    if (listPlataforms) {
      const plataformsSelect = listPlataforms.filter((plataform: any) =>
        resource.plataforms.some((item) => item === plataform.identifier)
      );
      setPlataformSelectForm(plataformsSelect);
    }
    if (listTargetAudience) {
      const targetAudienceSelect = listTargetAudience.filter((target: any) =>
        resource.target_audience.some((item) => item === target.identifier)
      );
      setTargetAudienceSelectForm(targetAudienceSelect);
    }
  }, [listPlataforms, listTargetAudience]);

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  function addTargetAudience(id: string) {
    const targetSelected = listTargetAudience?.find(
      (item: any) => item.identifier === id
    );
    if (targetSelected) {
      setTargetAudienceSelectForm([...targetAudienceSelect, targetSelected]);
    }
  }

  function removeTargetAudience(id: string) {
    setTargetAudienceSelectForm(
      targetAudienceSelect.filter((item) => item.identifier !== id)
    );
  }

  function addPlataform(id: string) {
    const plataformSelected = listPlataforms?.find(
      (item: any) => item.identifier === id
    );
    if (plataformSelected) {
      setPlataformSelectForm([...plataformSelect, plataformSelected]);
    }
  }

  function removePlataform(id: string) {
    setPlataformSelectForm(
      plataformSelect.filter((item) => item.identifier !== id)
    );
  }

  return (
    <FormContainer onSubmit={onSubmit} id="formSimiliraty">
      <Row $align="flex-start">
        <div className="item">
          <Select
            value={""}
            onChange={addTargetAudience}
            flex="fx100"
            label="Público Alvo"
            inputId="targetAudience"
          >
            {loadingTargerAudience ? (
              <option value="">Carregando...</option>
            ) : errorTargetAudience ? (
              <option value="">Erro ao carregar</option>
            ) : (
              <>
                <option value="">Selecione os públicos alvo</option>
                {listTargetAudience
                  ?.filter(
                    (target: any) =>
                      !targetAudienceSelect.some(
                        (select) => select.identifier === target.identifier
                      )
                  )
                  .map((item: any) => (
                    <option key={item.identifier} value={item.identifier}>
                      {item.name}
                    </option>
                  ))}
              </>
            )}
          </Select>
          <ListItensSelect>
            {targetAudienceSelect.length ? (
              targetAudienceSelect.map((item) => (
                <li key={item.identifier}>
                  <span>{item.name}</span>
                  <button onClick={() => removeTargetAudience(item.identifier)}>
                    Remover
                  </button>
                </li>
              ))
            ) : (
              <li></li>
            )}
          </ListItensSelect>
        </div>
      </Row>
      <Row>
        {/* Select para plataformas */}
        {typesPlataforms?.map((type: any) => (
          <div className="item align-start" key={type}>
            <Select
              value={""}
              onChange={addPlataform}
              flex="fx100"
              label={getLabelPlataform(type)}
              inputId="plataform"
            >
              {loadingPlataforms ? (
                <option value="">Carregando...</option>
              ) : errorPlataforms ? (
                <option value="">Erro ao carregar</option>
              ) : (
                <>
                  <option value="">Selecione as plataformas</option>
                  {listPlataforms
                    ?.filter((plataform: any)=>plataform.type===type).filter(
                      (plataform: any) =>
                        !plataformSelect.find(
                          (select) =>
                            select.identifier === plataform.identifier &&
                            select.type === type
                        )
                    )
                    .map((item: any) => (
                      <option key={item.identifier} value={item.identifier}>
                        {item.name}
                      </option>
                    ))}
                </>
              )}
            </Select>
            <ListItensSelect>
              {plataformSelect.filter((plataformSelect)=>plataformSelect.type===type).length ? (
                plataformSelect.filter((plataformSelect)=>plataformSelect.type===type).map((item) => (
                  <li key={item.identifier}>
                    {item.name}
                    <button onClick={() => removePlataform(item.identifier)}>
                      Remover
                    </button>
                  </li>
                ))
              ) : (
                <li></li>
              )}
            </ListItensSelect>
          </div>
        ))}
      
      </Row>
    </FormContainer>
  );
}
