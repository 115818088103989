import styled from 'styled-components'

export const Container = styled.main`
  padding: 20px 30px;
  
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const Header = styled.div``

export const Datas = styled.section`
display: flex;
align-items: flex-start;
justify-content: flex-start;
gap: 20px;
  aside {
    width: 120px;
    div{
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: 50%;
      img{
        width: 100%;
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 3px;
    p{
      font-size: 16px;
      font-weight: 400;
      color: #646464;
    }
    span{
      display: block;
      height: 0.5px;
      width: 100%;
      background-color: #000000;
    }
  }
`
export const EditForm = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  main{
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  span.line{
    height: 1px;
    background-color: #000;
    display: block;
  }
`;

export const ListConsult = styled.div`

`;