import { ChangeEvent, useContext, useRef, useState } from "react";
import { ModalRef } from "../../../../../../Components/Modals";
import { ModalListNew } from "../../../../../../Components/Modals/ModalListNew/ModalListNew";
import { ResourceContext } from "../../../../../../Contexts/Form/ContextResource";
import { useListBNCC } from "../../../hooks/useListBNCC";

import { Line, Row } from "../../../styles";
import { SearchSkills } from "../../components/SearchSkils";
import { Column, ListKnoledge } from "../styles";
import { ItemKnowledgeComponent } from "./ListKnoledge";
import { ListItens } from "../../components/ListIItens";
import { IBNCCCurricularComponent, IBNCCKnowledge, IBNCCYear } from "../../../../../../types/bncc";
import { Input } from "../../../../../../Components/Form/InputHook";
import { useListEF } from "../../../hooks/bncc/useListEF";

export function hasCurricularComponentNoFieldAction(name: string) {
  return name !== "Língua Portuguesa" && name !== "Língua Inglesa";
}

export enum EnumColumnSelected {
  UNIDADE_TEMATICA = 1,
  OBJETO_CONHECIMENTO = 2,
  CAMPOS_ESPECIFICOS = 3,
}

function getLabelUnitTemathic(componentCurricularSerlected: string | null) {
  if (componentCurricularSerlected === "Língua Portuguesa")
    return "Lista Práticas de Leitura";

  return "Lista Unidades temática";
}
function getLabelEspecificColumn(componentCurricularSerlected: string | null) {
  if (componentCurricularSerlected === "Língua Portuguesa")
    return "Lista Campos de atuação";
  if (componentCurricularSerlected === "Língua Inglesa") return "Lista de Eixo";
  return "";
}
export function FormElementarySchool() {
  const { resource, addFieldBNCCEfResource, removeFieldBNCCEfResource, filterBNCC, handleFilterBNCC } =
    useContext(ResourceContext);
  const { bncc: { ef: { curricular_components } } } = resource;
  // const { isLoading, data, isSuccess } = useListBNCC();
  const { data, isSuccess, isLoading } = useListEF()
  const [componentCurricularSerlected, setComponentCurricularSerlected] =
    useState<string | null>(null);

  const [columnSelected, setColumnSelected] =
    useState<EnumColumnSelected | null>(null);

  const title =
    columnSelected === EnumColumnSelected.OBJETO_CONHECIMENTO
      ? "Lista Objetos de conhecimento"
      : columnSelected === EnumColumnSelected.UNIDADE_TEMATICA
        ? getLabelUnitTemathic(componentCurricularSerlected)
        : getLabelEspecificColumn(componentCurricularSerlected);

  const modalRef = useRef<ModalRef>(null);

  function openModal() {
    modalRef.current?.open();
  }

  function closeModal() {
    modalRef.current?.close();
  }

  function handleColumnSelected(column: EnumColumnSelected) {
    setColumnSelected(column);
  }

  function handleComponentCurricularSelected(component: string) {
    setComponentCurricularSerlected(component);
  }

  function handleYear(year: IBNCCYear, select: boolean) {
    if (select) {
      removeFieldBNCCEfResource(year.year_id, "years");
    } else {
      addFieldBNCCEfResource(year.year_id, "years");
    }
  }

  function handleFilterBNCCAll(e: ChangeEvent<HTMLInputElement>) {
    handleFilterBNCC(e.target.value);
  }

  function keyDownFilterBNCCAll(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      const allComponentesCurricular = data?.knowledge_areas.reduce((acc: IBNCCCurricularComponent[], value: any) => {
        return [...acc, ...value.curricular_components]
      }, [])
      allComponentesCurricular?.filter((value: any) => curricular_components.some((compo) => compo === value.name)).forEach((selected: any) => { console.log(selected) })
    }
  }


  return (
    <div>
      {isLoading ? (
        <h2>Carregando ...</h2>
      ) : isSuccess ? (
        <div>
          <Row>
            {data?.phases.map((phase) => (
              <div className="item" key={phase.name}>
                <Column>
                  <span className="title">FASE: {phase.name}</span>
                  <span className="sub-title">ANO:</span>
                  <ul className="row gap mt">
                    {phase.years.map((year) => {
                      const select = resource.bncc.ef.years.some(
                        (yearSelected) => yearSelected === year.year_id
                      );
                      return (
                        <li key={year.year}>
                          <input
                            type="checkbox"
                            checked={select}
                            onChange={() => handleYear(year, select)}
                          />
                          <span>{year.year}</span>
                        </li>
                      );
                    })}
                  </ul>
                </Column>
              </div>
            ))}
          </Row>
          <Line $margin="0.5rem 0" />
          {/* <div>
              <Input
                flex="fx60"
                type="search"
                onChange={handleFilterBNCCAll}
                value={filterBNCC}
                label=""
                required={false}
                onKeyDown={keyDownFilterBNCCAll}
              />
            </div>
            <Line $margin="0.5rem 0" /> */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "1rem",
            }}
          >

            <span className="title title-list">Áreas do conhecimento</span>
            <ListKnoledge>
              {data?.knowledge_areas.map(
                (knowledge_area: any, index: number) => (
                  <ItemKnowledgeComponent
                    handleComponentCurricularSelected={
                      handleComponentCurricularSelected
                    }
                    handleColumnSelected={handleColumnSelected}
                    openModal={openModal}
                    index={index}
                    knowledgeArea={knowledge_area}
                    key={knowledge_area.name}

                  />
                )
              )}
            </ListKnoledge>
          </div>
          <SearchSkills type="ef" />
          <Line $margin="1.5rem 0" />
        </div>
      ) : (
        <h2>Não foi possível solicitar os BNCCs</h2>
      )}
      <ModalListNew modalRef={modalRef} title={title} onSave={() => closeModal()}>
        <ListItens
          columnSelected={columnSelected}
          curricularSelected={componentCurricularSerlected}
        />
      </ModalListNew>
    </div>
  );
}
