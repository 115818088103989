import { useEffect, useState } from 'react'
import { FormDefault } from './FormDefault'
import { FormStudent } from './FormStudent'
import { FormTeacher } from './FormTeacher'
import { Container, Form, Main, Sitemap } from '../../../Registration/ClientRegistration/styles'
import { EnumEmployees } from '../../../../Utils/Types'
import FormGuardianEdit from './FormGuardianEdit'
import FormEmployeeEdit from './FormEmployeesEdit/FormEmployeeEdit'
import NewFormStudentEdit from './NewFormStudentEdit'
import FormTeacherEdit from './NewFormTeacherEdit'
import { ContextEditTeacherProvider } from './Contexts/ContextEditTeacher'

export function EditUser(props: any) {

  const [wasSelected, setWasSelected] = useState(false)
  const [selectedOne, setSelectedOne] = useState<any>('0')
  const [selectedTwo, setSelectedTwo] = useState<any>('0')
  const [name, setName] = useState(props.selected.user?.name)
  const [cpf, setCpf] = useState(props.selected.user?.cpf)
  const [date, setDate] = useState("")
  const [phone, setPhone] = useState(props.selected.user?.phone)
  const [email, setEmail] = useState(props.selected.user?.email)
  const [confirmEmail, setConfirmEmail] = useState(props.selected.user?.confirmEmail)
  const [registration, setRegistration] = useState(props.selected.enrollment)
  const idUser = sessionStorage.getItem('location') || ''
  const [student, setStudent] = useState('')
  const [employeeList, setEmployeeList] = useState<any[]>([])


  useEffect(() => {

    // if (idUser === EnumEmployees.GUARDIAN || idUser === EnumEmployees.MANAGER) {
    //   const yearRes = props.selected.user.birth_date.substring(0, 4)
    //   const mounthRes =
    //     props.selected.user.birth_date.substring(8, 10)
    //   const dayRes =
    //     props.selected.user.birth_date.substring(5, 7)
    //   const dataRes =
    //     mounthRes
    //     +
    //     dayRes
    //     +
    //     yearRes;

    //   return setDate(dataRes);
    // } else if (idUser === EnumEmployees.STUDENT) {
    //   const yearStu = props.selected.birthDate.substring(0, 4)
    //   const mounthStu =
    //     props.selected.birthDate.substring(8, 10)
    //   const dayStu =
    //     props.selected.birthDate.substring(5, 7)
    //   const dataStu = dayStu + mounthStu + yearStu;
    //   return setDate(dataStu);

    // }


    // StudentService.show({educationInstitution:props.stage, student:props.selected})
    // .then((response)=>{
    //   if(response.success){
    //     const guardian = response.data.guardians.map((item: any)=>{
    //         return{
    //           identifier: item.identifier,
    //           ...item.user
    //         }
    //     })
    //     setGuardianStudent(guardian)
    //   }
    // })

  }, [])


  return (
    <Container>
      <div className="box">
        <Main>
          {(idUser === EnumEmployees.MANAGER ||
            idUser === EnumEmployees.MANAGER_EMPLOYEE ||
            idUser === EnumEmployees.DIRECTOR ||
            idUser === EnumEmployees.COORD ||
            idUser === EnumEmployees.ADMIN_EMPLOYEE) && (
              <FormEmployeeEdit
              />
              // <FormDefault
              //   one={idUser}
              //   two={selectedTwo}
              //   name={name}
              //   cpf={cpf}
              //   date={date}
              //   phone={phone}
              //   email={email}
              //   confirmEmail={confirmEmail}
              //   setName={setName}
              //   setCpf={setCpf}
              //   setDate={setDate}
              //   setPhone={setPhone}
              //   setEmail={setEmail}
              //   setConfirmEmail={setConfirmEmail}
              //   employeeList={employeeList}
              //   setEmployeeList={setEmployeeList}
              //   idUser={props.selected.identifier}
              // />
            )}
          {idUser === EnumEmployees.GUARDIAN && (
            <FormGuardianEdit
              unit={props.stage}
              idUserForShow={props.idUserForShow}
              guardian={props.selected}
              idUser={idUser}
            />
            // <FormDefault
            //   one={idUser}
            //   two={selectedTwo}
            //   name={props.selected.user.name}
            //   cpf={props.selected.user.cpf}
            //   date={date}
            //   phone={props.selected.user.phone}
            //   email={props.selected.user.email}
            //   confirmEmail={props.selected.user.email}
            //   setName={setName}
            //   setCpf={setCpf}
            //   setDate={setDate}
            //   setPhone={setPhone}
            //   setEmail={setEmail}
            //   setConfirmEmail={setConfirmEmail}
            //   student={student}
            //   setStudent={setStudent}
            //   idUser={props.selected.identifier}
            // />
          )}
          {idUser === EnumEmployees.TEACHER && (
            <ContextEditTeacherProvider>
              <FormTeacherEdit teacherEdit={props.selected.user}/>
            </ContextEditTeacherProvider>
          )}
          {idUser === EnumEmployees.STUDENT && (
            <NewFormStudentEdit studentSelected={props.selected} />

          )}
        </Main>
      </div>
    </Container>
  )
}
