import styled from 'styled-components';

export const Container = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
`;

export const Next = styled.button`
    height: 40px;
    width: 110px;
    background-color: #BCBCBC;
    border-radius: 10px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    cursor: not-allowed;
    

    &.enable{
        background-color: #088A62;
        cursor: pointer;
    }
`;

export const Prev = styled.button`
    height: 40px;
    width: 110px;
    background-color: #BCBCBC;
    border-radius: 10px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    cursor: not-allowed;

    &.enable{
        background-color: #00CAD1;
        cursor: pointer;
    }
`;

export const Cancel = styled.button`
    height: 40px;
    width: 110px;
    background-color: #BCBCBC;
    border-radius: 10px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
`;