import React, { useContext } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'

const Thumbs = () => {
    const { resource } = useContext(ResourceContext)

    const isThumb = (resource.thumbnails.length > 0)

    return (
        <div style={{marginTop: "20px"}}>
            <tr>
                <td>
                    <span>THUMBNAILS</span>
                </td>
            </tr>
            {
                isThumb ?
            resource.thumbnails.map((thumb, index) => {
                
                return (
                    <tr key={index}>
                        <td>
                            <img src={URL.createObjectURL(thumb)} alt={`imagem ${index + 1}`} />
                        </td>
                    </tr>
                )
            })
            : <p>Não informado.</p>
        }


        </div>
    )
}

export default Thumbs