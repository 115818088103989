import { Medal } from "lucide-react";
import { Container } from "./styles";

export function Ranking(props: any){
    return(
        <Container>
            <div className="head">

                    <Medal size={40}/>
                
                <p>{props.label}</p>
            </div>
            <div className="body">
                <p>Nome 1</p>
                <p>Nome 2</p>
                <p>Nome 3</p>
                <p>Nome 4</p>
                <p>Nome 5</p>
            </div>
        </Container>
    )
}