import { useContext, useEffect, useState } from "react";
import { useCheckTime } from "../../../Components/LoginFunctions/Refresh";
import { SuperBox } from "../../../Components/SuperBox";
import { Wizard } from "../../../Components/Wizard";
import { WizardClick } from "../../../Components/WizardClick";
import { ResourceContextForm1Provider } from "../../../Contexts/Form/ContextResouceForm1";
import {
  EnumStepNewResource,
  ResourceContext,
} from "../../../Contexts/Form/ContextResource";
import { Form1 } from "./Form1";
import Form2 from "./Form2/Form2";
import Form3 from "./Form3/Form3";
import Form4 from "./Form4/Form4";
import { FormTutorial } from "./FormTutorial";
import { Container, Main } from "./styles";
// import { useBlocker } from "react-router-dom";
import { useLocation } from "react-router-dom";
import FormDocuments from "./FormDocuments";
import { ContextResourceFormPOPProvider } from "../../../Contexts/FormPOP/ContextResourceFormPOP";
import ReviewResource from "./ReviewResource";
import { ContextReviewResourcerProvider } from "./ReviewResource/Context/ContextReviewResource";
import ResourceService from "../../../Services/Resouce.service";
import { IResource, TResource } from "../../../types/types";
import { IResourceState, initialStateResource } from "../../../reducers/newResource/reducer";
import { LoadingScreen } from "../../../Components/Loading";

interface IFactory {
  stepActual: EnumStepNewResource;
}

function ResourceFactory({ stepActual }: IFactory) {
  switch (stepActual) {
    case EnumStepNewResource.INFORMACAO:
      return (
        <ResourceContextForm1Provider>
          <Form1 />
        </ResourceContextForm1Provider>
      );
    case EnumStepNewResource.BNCC:
      return (
        <ResourceContextForm1Provider>
          <Form2 />
        </ResourceContextForm1Provider>
      );
    case EnumStepNewResource.TAGS:
      return <Form3 />;
    case EnumStepNewResource.POP:
      return <Form4 />;
    case EnumStepNewResource.TUTORIAL:
      return <FormTutorial />;
    case EnumStepNewResource.DOCUMENTS:
      return <FormDocuments />;
    case EnumStepNewResource.REVIEW:
      return <ContextReviewResourcerProvider>
        <ReviewResource />
      </ContextReviewResourcerProvider>
    default:
      return <Form1 />;
  }
}
function ResourcesRegistrationController() {
  useCheckTime();
  const { stepActual, handleStep, setIdResource, handleResource} =
    useContext(ResourceContext);
  const location = useLocation();
  const paths = location.pathname.split("/");
  const isEdit = paths[paths.length - 1] === "edit";
  const labelPage = isEdit ? "Edição de Recurso" : "Cadastro de Recurso";
  const [isLoading, setIsLoading] = useState(false);

  function transformResorceToResourceState(
    resource: IResource
  ): IResourceState {
    return {
      name: resource.name,
      active: resource.active,
      format: resource.format,
      language: resource.language,
      test: resource.test,
      published: resource.published,
      description: resource.description ?? undefined,
      url: resource.url ?? "",
      //Definir os valores padrões
      thumbnails: [],
      authorship: {
        content_creator: [],
        proofreader: [],
        graphic_designer: [],
        screenwriter: [],
        illustrator: [],
        programmer: [],
      },
      evaluated_content: {
        license: "",
        intellectual_rights: [],
        parental_rating: null,
        content_descritors: [],
      },
      collections: [],
      target_audience: [],
      plataforms: [],

      bncc: {
        basic_competences: [],
        ei: {
          years: [],
          learning_rights: [],
          fields_experience: [],
          skills: [],
        },
        ef: {
          years: [],
          knowledge_areas: [],
          curricular_components: [],
          fields_action: [],
          thematic_units: [],
          knowledge_objects: [],
          skills: [],
        },
        em: {
          years: [],
          knowledge_areas: [],
          specific_competences_port: [],
          fields_action: [],
          skills: [],
        },
      },
      pedagogical_guideline: {
        answer_key: [{ description: "" }],
        tips: "",
        content_reference: "",
        similar_reference: "",
        guideline: [{ description: "" }],
      },
      tags: {
        categorys: [],
        itens: [],
        suggested: null,
      },
    };
  }

  useEffect(() => {
    if (isEdit) {
      setIsLoading(true);
      setIdResource(location.state.identifier);
      handleResource(transformResorceToResourceState(location.state));
      ResourceService.showResource({ identifier: location.state.identifier })
        .then((res) => {
          if (res?.success) {
            handleResource(transformResorceToResourceState(res.data.data));
          }
        })
        .finally(() => setIsLoading(false));
    }else{
      handleResource(initialStateResource)
    }
  }, []);
  // Block navigating elsewhere when data has been entered into the input
  // let blocker = useBlocker(({ currentLocation, nextLocation }) => {
  //   const currentPath = currentLocation.pathname.split("/");
  //   const pathNameCorrect = currentPath.length >=2 && currentPath[1] === "recursos" && currentPath[2] === "registration";
  //   return value && currentLocation.pathname !== nextLocation.pathname && pathNameCorrect
  // });
  return (
    <LoadingScreen isLoading={isLoading}>
      <SuperBox switch={true} path={labelPage}>
        <Main $maxWidth="1000px">
          <Container>
            <Wizard className="center">
              <WizardClick
                button={true}
                color="#027294"
                text="Informações básicas
              "
                active={stepActual === EnumStepNewResource.INFORMACAO}
                onClick={() => {
                  handleStep(EnumStepNewResource.INFORMACAO);
                }}
              />
              <WizardClick
                button={true}
                text="BNCC"
                active={stepActual === EnumStepNewResource.BNCC}
                color="#027294"
                onClick={() => {
                  handleStep(EnumStepNewResource.BNCC);
                }}
              />
              <WizardClick
                button={true}
                color="#027294"
                text="Tags"
                active={stepActual === EnumStepNewResource.TAGS}
                onClick={() => {
                  handleStep(EnumStepNewResource.TAGS);
                }}
              />
              <WizardClick
                button={true}
                color="#027294"
                text="POP"
                active={stepActual === EnumStepNewResource.POP}
                onClick={() => {
                  handleStep(EnumStepNewResource.POP);
                }}
              />
              <WizardClick
                button={true}
                color="#027294"
                text="Anexos"
                active={stepActual === EnumStepNewResource.TUTORIAL}
                onClick={() => {
                  handleStep(EnumStepNewResource.TUTORIAL);
                }}
              />
              <WizardClick
                button={true}
                color="#027294"
                text="Documentos"
                active={stepActual === EnumStepNewResource.DOCUMENTS}
                onClick={() => {
                  handleStep(EnumStepNewResource.DOCUMENTS);
                }}
              />
              <WizardClick
                button={true}
                color="#027294"
                text="Revisar"
                active={stepActual === EnumStepNewResource.REVIEW}
                onClick={() => {
                  handleStep(EnumStepNewResource.REVIEW);
                }}
              />
            </Wizard>
            <div className="box">

              <ResourceFactory stepActual={stepActual} />

            </div>
          </Container>
        </Main>
        {/* <ModalShort
        type="delete"
        text="Tem certeza que deseja sair (as alterações não salvas serão perdidas)?"
        isOpen={blocker.state === "blocked"}
        onRequestClose={() => blocker.reset && blocker.reset()}
        onConfirm={() => {
          blocker.proceed && blocker.proceed();
        }}
      /> */}
      </SuperBox>
    </LoadingScreen>
  );
}

export default function ResourcesRegistration() {
  return <ResourcesRegistrationController />;
}
