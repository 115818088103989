import { Button } from './../../../Registration/UserRegistration/FormTeacher/ImportExcel/styles';
import styled from 'styled-components'

export const Container = styled.main`
  padding: 20px 30px;
  
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .render-list
    ul {
        align-items: center; 
        li {
          list-style: none;
      }
    }
`
export const Header = styled.div``

export const Datas = styled.section`
display: flex;
align-items: flex-start;
justify-content: flex-start;
gap: 20px;
  aside {
    width: 120px;
    div{
      width: 120px;
      height: 120px;
      overflow: hidden;
      border-radius: 50%;
      img{
        width: 100%;
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    gap: 3px;
    p{
      font-size: 16px;
      font-weight: 400;
      color: #646464;
      display: flex;
      gap: 5px;
      align-items: center;
      justify-content: flex-start;
    }
    span{
      display: block;
      height: 0.5px;
      width: 100%;
      background-color: #000000;
    }
    a{

    }
    button.callList{
      background-color: var(--yellow);
      color: #fff;
      width: 18px;
      height: 18px;
      border: none;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    
  }
`
