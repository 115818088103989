import React, { useState } from "react";
import { ContScreen } from "./style";

interface ScreenProps {
  quantityScreen: number;
  selectScreen: number;
}
export default function Screen({ quantityScreen, selectScreen }: ScreenProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "0.5rem",
      }}
    >
      {Array.from(Array(quantityScreen).keys()).map((item, index) => {
        return (
          <ContScreen $selected={selectScreen === index}>
            <label>Tela {item + 1}</label>
          </ContScreen>
        );
      })}
    </div>
  );
}
