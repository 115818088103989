import React, { Dispatch, SetStateAction } from 'react'
import { Box, Table } from '../ImportExcel/styles'
import { Trash, Trash2 } from 'lucide-react'
import { Class } from '../../../../../Utils/Types'

interface TableClassProps {
    unitName: string,
    classesSelecteds?: Class[],
    setClassesSelectes: Dispatch<SetStateAction<Class[]>>
}


const TableClass = ({ classesSelecteds, unitName, setClassesSelectes }: TableClassProps) => {

    const removeClass = (indexRemove: number) => {
        const updatedClasses: any = classesSelecteds?.filter((_, index) => index !== indexRemove);
        setClassesSelectes(updatedClasses)
    }

    return (
        <Box>
            <br />
            <Table>
                <p>ADICIONAR TURMAS</p>
                <table>
                    <thead>
                        <td>Unidade Educacional</td>
                        <td>Turma(s) selecionada(s)</td>
                        <td>{" "}</td>
                    </thead>
                    <tbody>
                        {classesSelecteds?.map((item,index: number) => {
                            return (
                                <>
                                    <tr>
                                        <td>{unitName}</td>
                                        <td>
                                            <p>{item.name}</p>
                                        </td>
                                        <td>
                                            <Trash2 color='red' size={17} style={{margin: '5px', cursor:"pointer"}}
                                                onClick={()=>{
                                                    removeClass(index)
                                                }}
                                            />
                                        </td>
                                    </tr >
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </Table>
        </Box>
    )
}

export default TableClass
