export function maskCpf(v: string): string {
  v = v.replace(/\D/g, "");
  v = v.replace(/^(\d{3})(\d)/g, "$1.$2");
  v = v.replace(/^(\d{3})\.(\d{3})(\d)/, "$1.$2.$3");
  v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})(\d)/, "$1.$2.$3-$4");
  v = v.replace(/^(\d{3})\.(\d{3})\.(\d{3})\/(\d{2})(\d)/, "$1.$2.$3-$4");
  return v.substring(0, 14);
}

export function maskDate(v: string): string {
  v = v.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
  v = v.replace(/^(\d{2})(\d)/g, "$1/$2"); // Adiciona a primeira barra
  v = v.replace(/^(\d{2})\/(\d{2})(\d)/, "$1/$2/$3"); // Adiciona a segunda barra
  v = v.replace(/^(\d{2})\/(\d{2})\/(\d{4})/, "$1/$2/$3|"); // Adiciona a barra e a barra vertical (pipe) no final
  return v.substring(0, 10); // Limita o tamanho máximo à máscara
}

export function maskPhoneNumber(v: string): string {
  v = v.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
  v = v.replace(/(\d{2})(\d)/, "($1) $2"); // Adiciona os parênteses e espaço para o código de área
  v = v.replace(/(\d{5})(\d)/, "$1-$2"); // Adiciona o hífen para separar os primeiros cinco dígitos
  v = v.replace(/(\d{4})(\d)/, "$1$2"); // Adiciona os últimos quatro dígitos
  return v.substring(0, 15); // Limita o tamanho máximo à máscara
}
