import React, { Component, useRef, useState } from "react";
import { DivScreens } from "../Guidelines/style";
import { ArrowLeft, ArrowRight, Info, PlusCircle, Trash2 } from "lucide-react";
import Screen from "../Guidelines/Screen";
import { TextArea } from "../../../../../Components/TextAreaHook";
import DividerHook from "../../../../../Components/Form/DividerHook";
import { Label } from "../../../../../Components/TextAreaHook/styles";
import { Div } from "./style";
import Template from "./Template";
import { IAnswerResource } from "../../../../../reducers/newResource/reducer";

interface ScreemProps {
  answers: IAnswerResource[];
  handleAnswer: (answer: IAnswerResource, numberSelect: number) => void;
  addAnswer: () => void;
  deleteAnswer: (id: number) => void;
  label: string;
}

export default function ScreenTemplate({
  answers,
  handleAnswer,
  addAnswer,
  label,
  deleteAnswer,
}: ScreemProps) {
  const [answerSelected, setAnswerSelected] = useState(0);

  function proxAnswer() {
    if (answerSelected < answers.length - 1) {
      setAnswerSelected(answerSelected + 1);
    }
  }

  function prevAnswer() {
    if (answerSelected > 0) {
      setAnswerSelected(answerSelected - 1);
    }
  }

  function handleAnswerDescription(event: string) {
    const answer = [...answers];
    answer[answerSelected] = { ...answer[answerSelected], description: event };
    handleAnswer(answer[answerSelected], answerSelected);
  }

  function handleAnswerImage(event: File) {
    const answer = [...answers];
    answer[answerSelected] = {
      ...answer[answerSelected],
      image: event,
    };
    handleAnswer(answer[answerSelected], answerSelected);
  }

  function handleDeleteAnswer() {
    deleteAnswer(answerSelected);
    if (answerSelected >= answers.length - 1) setAnswerSelected(answerSelected - 1)
  }

  function handleAddAnswer() {
    addAnswer();
    setAnswerSelected(answers.length);
  }
  return (
    <>
      <DividerHook justifyContent="flex-start">
        <Label>{label}</Label>
        <Info color="#fe8f2e" style={{ marginLeft: "5px" }} />
      </DividerHook>
      <DivScreens>
        <ArrowLeft onClick={prevAnswer} style={{ cursor: 'pointer' }} />
        <div className="add">
          <Screen
            quantityScreen={answers.length}
            selectScreen={answerSelected}
          />
          <PlusCircle onClick={handleAddAnswer} size={20} style={{ margin: "5px", cursor: 'pointer' }} />
        </div>
        <ArrowRight onClick={proxAnswer} style={{ cursor: 'pointer' }} />
      </DivScreens>

      <Template
        answer={answers[answerSelected]}
        handleDescription={handleAnswerDescription}
        handleImage={handleAnswerImage}
        onDelete={handleDeleteAnswer}
      />
    </>
  );
}
