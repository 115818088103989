import { Fragment, useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { ContainerResume, Controller } from "../styles";
import { api } from "../../../../Services/api";
import { Controllers } from "../../../../Components/Form/Controllers";
import { ModalShort } from "../../../../Components/Modals/ModalShort";

interface ResumeProps {
    studentsSelected: any[]
    studentFiltereds: any
    classSelected: any
    stageSelected: any
    unitSelected: any
    back?: any
}

export function Resume({ studentsSelected, studentFiltereds, unitSelected, stageSelected, classSelected, back }: ResumeProps) {
    const [descriptionTransfer, setDescriptionTransfer] = useState("");
    const [error, setError] = useState("")
    const [ModalShortSalve, setModalShortSalve] = useState(false);

    // verificar motivo da transferência
    function isError() {
        if (descriptionTransfer === "") {
            setError("#ff0000")
        }
    }

    // modal salvar
    function closeModal() {
        setModalShortSalve(false);
        window.location.reload();
    }

    // função pra salvar
    function addTransfer() {

        const data = {
            students: studentsSelected.map((student) => student.name),
            classroom_origin: "",
            classroom_destiny: classSelected.identifier,
            reason: descriptionTransfer,
        };

        if (descriptionTransfer !== "") {
            api.post(`/client/transfers/students`, data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            }).then(response => {
               setModalShortSalve(true)
            });
        } else {
            isError()
        }

    };


    return (
        <ContainerResume>

            {ModalShortSalve && (
                <ModalShort
                    type="success"
                    text={"TRANSFERÊNCIA REALIZADA. Sua solicitação foi enviada para análise."}
                    isOpen={ModalShortSalve}
                    onRequestClose={closeModal}
                    onConfirm={closeModal}
                />
            )}

            <BoxResume
                label="Verifique se os dados da tranferência estão corretos.">

                {studentsSelected.map((student: any, index: number) => {
                    const selectedUnit = studentFiltereds.find((unit: any) => unit.data.includes(student));
                    return (
                        <Fragment key={index}>
                            <tr>
                                <td >
                                    <b>Nome: </b>
                                    <p>{student.name}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Transferir de:</b>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Unidade: </b>
                                    <p>{selectedUnit.name}</p>
                                </td>
                                <td>
                                    <b>Turma: </b>
                                    <p>{student.classroom.length > 0 ? student.classroom : "Sem turma."}</p>
                                </td>
                            </tr>
                            <br />
                        </Fragment>
                    );
                })}

                <b>Transferir para:</b>
                <tr>
                    <td>
                        <b>Unidade: </b>
                        {unitSelected.name}
                    </td>
                </tr>
                <tr>
                    <td>
                        <b>Turma: </b>
                        {classSelected.name}
                    </td>
                </tr>
                <br />
                <tr>
                    <td>
                        <p style={{ color: error }}>Motivo da transferência:</p>
                    </td>
                    <textarea
                        style={{ border: `1px solid ${error}` }}
                        value={descriptionTransfer}
                        onChange={(e: any) => setDescriptionTransfer(e.target.value)}
                    />
                </tr>
            </BoxResume>

            <Controller>
                <Controllers
                    functionCancel={() => window.location.reload()}
                    functionSave={() => addTransfer()}
                    functionPrev={() => back()}
                    enableNext={true}
                    enablePrev={true}
                    save={true}
                />
            </Controller>

        </ContainerResume>

    )
}