import React from 'react'
import { ContainerReactSelect, Label } from '../Select/styles'
import Select from 'react-select';
import { ContainerIcon } from '../../../Pages/Registration/ResourcesRegistration/styles';


interface Props {
    flex: string
    label: string
    placeholder: string
    options?: any[]
    onChange?: (e: any) => void
    noOptionsMessage?: string
    getOptionLabel?: (e: any) => string
    getOptionValue?: (e: any) => string
    value?: string
    children?: React.ReactNode
    isLoading?: boolean
    required?: boolean
    disable?: boolean
}

const ReactSelect = ({ flex, label, placeholder, options, onChange, noOptionsMessage, getOptionLabel, value, getOptionValue, children, isLoading, required, disable }: Props) => {

    const linhasLabel = label ? label.split("\\n") : []

    return (
        <>
            <ContainerReactSelect className={flex}>
                <>
                    {label && (
                        <Label>
                            {label}
                            {required === true && (<>*</>)}
                        </Label>
                    )}
                    <Select
                        placeholder={placeholder}
                        options={options}
                        onChange={onChange}
                        noOptionsMessage={(obj) => noOptionsMessage}
                        getOptionLabel={getOptionLabel}
                        getOptionValue={getOptionValue}
                        value={value}
                        isLoading={isLoading}
                        isDisabled={disable}
                    />
                </>
            </ContainerReactSelect>
        </>
    )
}

export default ReactSelect