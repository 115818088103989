import React, { useEffect, useState } from 'react'
import { Divider } from '../../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../../Components/Wizard'
import { useNavigate } from 'react-router-dom'
import { Select } from '../../../../../Components/Form/Select'
import { Input } from '../../../../../Components/Form/Input'
import { DivShowStudents } from '../../../../Registration/UserRegistration/FormGuardian/style'
import { Trash2 } from 'lucide-react'
import { Controllers } from '../../../../../Components/Form/Controllers'
import { Form } from '../../../../Registration/UserRegistration/styles'
import GuardianService from '../../../../../Services/Guardians.service'
import { formDate, formDate2 } from '../../../../../Utils/Functions'
import StudentService from '../../../../../Services/Student.service'
import { EnumEmployees } from '../../../../../Utils/Types'
import ReviewGuardianEdit, { GuardianData } from './ReviewGuardianEdit'

interface Props {
    unit: any,
    idUserForShow: string,
    guardian: any,
    idUser: any
}

const FormGuardianEdit = ({ idUserForShow, unit, guardian, idUser }: Props) => {

    const [stepForm, setStepForm] = useState(1)
    const navigate = useNavigate()
    const [studentsArray, setStudentsArray] = useState<string[]>([])
    const [listStudents, setListStudents] = useState<any[]>([])
    const [guardianData, setGuardianData] = useState<any>()

    const [name, setName] = useState(guardian.user.name)
    const [cpf, setCpf] = useState(guardian.user.cpf)
    const [date, setDate] = useState(formDate(guardian.user.birth_date))
    const [phone, setPhone] = useState(guardian.user.phone)
    const [email, setEmail] = useState(guardian.user.email)
    const [confirmEmail, setConfirmEmail] = useState("")

    const [listStudentsSelecteds, setListStudentsSelecteds] = useState<any[]>([])
    useEffect(() => {
        switch (idUser) {
            case EnumEmployees.GUARDIAN:
                StudentService.list({ unitId: unit.identifier }).then((response) => {
                    if (response.success) {
                        setListStudents(response.data)
                    }
                }
                )

                GuardianService.showGuardian({ idGuardian: guardian.identifier, idUnit: unit.identifier }).then((response) => {
                    if (response.success) {
                        setGuardianData(response.data)
                    }
                })
                break;

            default:
                return
                break;
        }

    }, [])






    function nextStep() {
        if (name === "") {
            alert("Preencha o nome.")
        } else if (cpf === "") {
            alert("Preencha o CPF")
        } else if (date === "") {
            alert("Preencha a data.")
        } else if (phone === "") {
            alert("Preencha  o telefone.")
        } else if (email === "") {
            alert("Preencha o email.")
        } else if (confirmEmail === "") {
            alert("Confirme seu email.")
        }else if(studentsArray.length === 0){
            alert("Selecione os estudantes.")
        }else {
            setStepForm(2)
        }
    }

    function handleIdStudents(e: any) {
        setStudentsArray((state) => {
            return [...state, e];
        })


        StudentService.show({ educationInstitution: unit.identifier, studentIdentifier: e }).then((response) => {
            if (response.success) {
                setListStudentsSelecteds((state) => {
                    return [...state, response.data]
                })
            }
        })
    }

    function removeStudentsSelecteds(idStudent: any) {
        const index = listStudentsSelecteds.findIndex((item) => item.identifier === idStudent);
        if (index === -1) {
            return
        }
        const uptatedStudents = [...listStudentsSelecteds.slice(0, index), ...listStudentsSelecteds.slice(index + 1)];
        setListStudentsSelecteds(uptatedStudents)
    }

    const dataGuardian:GuardianData = {
        identifier: guardian.identifier,
        birth_date: date,
        cpf: cpf,
        email: email,
        name: name,
        phone: phone,
        students: studentsArray
    }


    return (
        <>
            <Divider>
                <Wizard>
                    <WizardStep
                        text={`Responsável`}
                        active={stepForm === 1}
                    />
                    <WizardStep text="Revisar" active={stepForm === 2} />
                </Wizard>
            </Divider>
            {stepForm === 1 && (
                <Form onSubmit={() => { }}>
                    <Divider>
                        <Select
                            flex="fx40"
                            label="Aluno(s)"
                            value={null}
                            onChange={handleIdStudents}
                        >
                            <option value="">Selecione um estudante</option>
                            {listStudents.map((item: any, index: number) => {
                                return (
                                    <option
                                        value={item.identifier}
                                        key={index}
                                    >
                                        {item.name}
                                    </option>
                                )
                            })}
                        </Select>
                    </Divider>
                    <Divider>
                        <Input
                            label="Nome"
                            type="text"
                            flex="fx100"
                            required
                            value={name}
                            onChange={setName}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="CPF"
                            required
                            type="cpf"
                            flex="fx33"
                            value={cpf}
                            onChange={setCpf}
                        />
                        <Input
                            label="Data de Nascimento"
                            required
                            type="date"
                            flex="fx33"
                            value={date}
                            onChange={setDate}
                        />
                        <Input
                            label="Telefone"
                            required
                            type="tel2"
                            flex="fx33"
                            value={phone}
                            onChange={setPhone}
                        />
                    </Divider>
                    <Divider>
                        <Input
                            label="E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={email}
                            onChange={setEmail}
                        />
                        <Input
                            label="Confirmar E-mail"
                            required
                            type="email"
                            flex="fx50"
                            value={confirmEmail}
                            onChange={setConfirmEmail}
                        />
                    </Divider>
                    {listStudentsSelecteds.length > 0
                        ?
                        <Divider>
                            <DivShowStudents>
                                <h3>Estudantes selecionados</h3>

                                {listStudentsSelecteds.map((students, index) => {
                                    return (
                                        <ul key={index}>
                                            <li>{students.name}</li>
                                            <Trash2
                                                color='red'
                                                size={16}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => removeStudentsSelecteds(students.identifier)}
                                            />
                                        </ul>
                                    )
                                })}
                            </DivShowStudents>
                        </Divider>
                        :
                        <></>
                    }
                    <Controllers
                        functionNext={() => { nextStep() }}
                        functionPrev={() => {
                        }}
                        functionCancel={() => { window.location.reload() }}
                        enableNext={true}
                        enablePrev={true}
                    />
                </Form>
            )}

            {stepForm === 2 && (
                <ReviewGuardianEdit
                    dataGuardian={dataGuardian}
                    setStepForm={setStepForm}
                    stepForm={stepForm}
                    studentsSelecteds={listStudentsSelecteds}
                    unit={unit}
                />
            )}
        </>
    )
}

export default FormGuardianEdit
