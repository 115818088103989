import { Dispatch, SetStateAction, createContext, useContext, useState } from "react";
import { IPedagogicalResource } from "../../reducers/newResource/reducer";
import { ResourceContext } from "../Form/ContextResource";

interface IContextPOP {
    informations: IPedagogicalResource
    setInformations: Dispatch<SetStateAction<IPedagogicalResource>>
}

export const ContextPOP = createContext<IContextPOP>({} as IContextPOP);

interface Props {
    children: React.ReactNode
}


export function ContextResourceFormPOPProvider({ children }: Props) {

    const { resource } = useContext(ResourceContext)

    const [informations, setInformations] = useState<IPedagogicalResource>(
        resource.pedagogical_guideline
    );

    return (
        <ContextPOP.Provider
            value={{
                informations,
                setInformations
            }}
        >
            {children}
        </ContextPOP.Provider>
    )
}