import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react'
import { ContainerItens, ContainerItensMain, ContainerStyles, TextAreaInput } from './style';
import { ChevronUp } from 'lucide-react';
import Styles from './ContStyles';
import { set } from 'react-hook-form';
import JoditEditor from 'jodit-react';
import { Label, Container } from '../../../../../../Components/Form/Input/styles';

interface Props {
    flex?: string;
    label?: string;
    required?: boolean;
    setOnSave: Dispatch<SetStateAction<boolean>>
}

const InputDocuments = ({ flex, label, required, setOnSave }: Props) => {

    const [isTrue, setIsTrue] = useState(false)
    const [fontSize, setFontSize] = useState("12px")
    const [fontWeight, setFontWeight] = useState("normal")
    const [fontStyle, setFontStyle] = useState("normal")
    const [textDecoration, setTextDecoration] = useState("none")
    const [textAlign, setTextAlign] = useState("left")
    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const editor = useRef(null);
    const [content, setContent] = useState("")


    const config = {
        readonly: false,
        placeholder: "Digite...",
        language: "pt-br",
        buttons: [
            'source',
            'bold',
            'italic',
            'underline',
            'ul',
            'ol',
            'outdent',
            'indent',
            'font',
            'fontsize',
          ],
    };

    function handleEditorChange(newContent: string) {
        setContent(newContent)
    }

    return (
        <Container className={flex}
            onClick={() => {
                setIsTrue(true)
                setOnSave(true)
            }}
        >
            <Label>
                {label}
                {required ? "*" : ""}
            </Label>
            <ContainerItens
                $border={isTrue ? "" : "1px solid black"}
            >
                {
                    isTrue ? (
                            <JoditEditor
                                ref={editor}
                                value={content}
                                config={config}
                                onChange={handleEditorChange}
                                onBlur={newContent => { setContent(newContent) }}
                            />

                        // <ContainerItensMain>
                        //     <Styles
                        //         fontSize={fontSize}
                        //         setFontSize={setFontSize}
                        //         fontWeight={fontWeight}
                        //         setFontWeight={setFontWeight}
                        //         setFontStyle={setFontStyle}
                        //         setTextDecoration={setTextDecoration}
                        //         setTextAlign={setTextAlign}
                        //         textRef={textAreaRef}
                        //     />
                        //     <TextAreaInput
                        //         $fontSize={fontSize}
                        //         $fontWeight={fontWeight}
                        //         $fontStyle={fontStyle}
                        //         $textDecoration={textDecoration}
                        //         $textAlign={textAlign}
                        //         ref={textAreaRef}
                        //     />
                        // </ContainerItensMain>

                    ) : null
                }
            </ContainerItens>
        </Container>
    )
}

export default InputDocuments
