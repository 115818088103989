import { Step } from "./styles";

interface IWizardStep {
    text: string;
    active?: boolean;
    color?: string;
    center?: boolean;
    button?: boolean;
    onClick?: () => void;
    Icon?: JSX.Element;
  }
  
  export function WizardClick({
    text,
    active = false,
    onClick,
    color = "#027294",
    center = false,
    button = false,
    Icon,
  }: IWizardStep) {
    return (
      <Step
        $button={button}
        $center={center}
        $color={color}
        className={active ? "active" : ""}
        onClick={onClick}
        $active={active}
      >
        <span>
          {Icon && Icon}
          {text}
        </span>
      </Step>
    );
  }
  