import React, { useEffect, useState } from "react";
import { SuperBox } from "../../../Components/SuperBox";
import { Container, Main } from "../UserRegistration/styles";
import { WizardStep } from "../../../Components/Wizard";
import { Divider } from "../../../Components/Form/Divider";
import { useNavigate } from "react-router-dom";
import InputTag from "../../../Components/InputTag";
import { DivControlers, DividerNew, SpinnerIcon, Table, TableActionOptions } from "./styles";
import TagService from "../../../Services/Tag.service";
import { CheckCircle2, Edit2Icon, EyeIcon, XCircle } from "lucide-react";
import { formatedDate } from "../../../Utils/Functions";
import { EnumRoles, Tag } from "../../../Utils/Types";
import ImportTags from "./ImportTags/ImportTags";
import { ModalShort } from "../../../Components/Modals/ModalShort";
import { PlusCircleIcon } from "lucide-react";
import { Input } from "../../../Components/Form/Input";
import { ModalTags } from "../../../Components/Modals/ModalTags";
import { Form } from "../../../Components/InputTag/styles";

const EMPTY_LOADING = ""

export default function TagRegistration(props: any) {


    const [switchForm, setSwitchForm] = useState(1);
    const navigate = useNavigate()

    const [table, setTable] = useState([])
    const [categories, setCategories] = useState<any>([])

    const user = JSON.parse(String(localStorage.getItem('roles')))
    const [isOpenSucess, setIsOpenSucess] = useState(false)
    const [tagSugestions, setTagSugestions] = useState<any>([])

    const [isLoading, setIsLoading] = useState("")
    const [listTags, setListTags] = useState<Tag[]>([])

    const [nameCategory, setNameCategory] = useState("")
    const [justificationCategory, setJustificationCategory] = useState("")

    const [isCreateCategory, setIsCreateCategory] = useState(false)
    const [message, setMessage] = useState("")

    const [isTagSugestion, setIsTagSugestion] = useState(false)
    const [idSugestiontag, setIdSugestiontag] = useState("")
    const [dataTagSugestion, setDataTagSugestion] = useState<any>([])

    const [isOpenSucessConfirm, setIsOpenSucessConfirm] = useState(false)
    const [confirmedTagId, setConfirmedTagId] = useState("");
    const [approv, setApprov] = useState(false)
    const [messageConfirm, setMessageConfirm] = useState("");


    useEffect(() => {


        if (user === EnumRoles.MASTER && listTags.length > 0) {
            listTags.map((tag) =>
                TagService.tagRegistration({ tag: tag })

            )
            updateListTagsSuggestions()
        } else {
            listTags.map((tag) =>
                TagService.tagSugestion({ name: tag.name, tag_category_id: tag.idCategory, justification: tag.justification })
            )
        }

        TagService.listCategory().then((response) => {
            if (response.success) {
                setCategories(response.data)
            }
        })

        updateListTagsSuggestions()

    }, [])

    useEffect(() => {
        TagService.showSugestionTag({ id: idSugestiontag }).then((response) => {
            if (response.success) {
                setDataTagSugestion(response.data)
            }
        })
    }, [idSugestiontag])


    const updateListTagsSuggestions = () => {
        TagService.listTagsSugestions().then((response) => {
            if (response.success) {
                setTagSugestions(response.data)
            }
        })
    }

    const handleModifyStatusTag = (id: string, approved: boolean) => {
        setIsLoading(id)
        TagService.approveTag({ id, approved })
            .then((response) => {
                if (response.success) {
                    updateListTagsSuggestions()
                    window.location.reload();
                } else {
                    alert(response.message)
                }
            })
            .finally(() => {
                setIsLoading(EMPTY_LOADING)
            })
    }

    const handleApproveTag = (id: string) => {
        handleModifyStatusTag(id, true)

        setIsTagSugestion(false)
        setIsOpenSucessConfirm(true)
        setMessageConfirm("DESEJA REALMENTE APROVAR A SUGESTÃO DE TAG?")
    }

    const handleReproveTag = (id: string) => {
        handleModifyStatusTag(id, false)

        setIsTagSugestion(false)
        setIsOpenSucessConfirm(true)
        setMessageConfirm("DESEJA REALMENTE REPROVAR A SUGESTÃO DE TAG?")
    }

    const saveCategory = () => {
        TagService.addCategory({
            name: nameCategory,
            description: justificationCategory,

        }).then((response) => {
            if (response.success) {
                setMessage("Categoria cadastrada!")
                setIsCreateCategory(false)
                setIsOpenSucess(true)
            }
        })
    }

    return (
        <>
            <ModalShort
                isOpen={isOpenSucess}
                type="success"
                text={message}
                onRequestClose={() => {
                    setIsOpenSucess(false)
                    window.location.reload();
                }}
            />
            <ModalShort
                isOpen={isOpenSucessConfirm}
                type="confirmSuccess"
                text={messageConfirm}
                onRequestClose={() => setIsOpenSucessConfirm(false)}
                onConfirm={() => {
                    if (confirmedTagId && approv === true) {
                        handleApproveTag(confirmedTagId)
                    } else {
                        handleReproveTag(confirmedTagId)
                    }

                    setConfirmedTagId("")
                    setMessageConfirm("")
                    setIsOpenSucessConfirm(false)
                }}
            />
            <SuperBox
                switch={true}
                path={`Cadastro de Tags`}
            >
                <Container>
                    <div className="box">
                        <Main>
                            <Divider>
                                {switchForm === 1 ? (
                                    <WizardStep
                                        text="CADASTRAR TAGS"
                                        active
                                    />
                                ) : (
                                    <WizardStep
                                        text="CADASTRAR TAGS"
                                    />
                                )}

                            </Divider>
                            <br />
                            {switchForm === 1 && (
                                <>
                                    <Divider>
                                        <label>CATEGORIAS DE TAGS</label>
                                    </Divider>
                                    <br />
                                    {categories.map((item: any, index: number) =>
                                        <div key={index}>
                                            <InputTag key={item.identifier}
                                                text={item.name}
                                                value={item.name}
                                                selectedOption={item.identifier}
                                                table={table}
                                                setTable={setTable}
                                                color="#9DDBF0"
                                                user={user}
                                            />
                                        </div>
                                    )}

                                    {user === EnumRoles.MASTER && (
                                        <DividerNew>
                                            <button className="bnt-new-category"
                                                onClick={() => setIsCreateCategory(true)}
                                            >
                                                <PlusCircleIcon className="icon-bnt" size={20} /> Criar Nova Categoria
                                            </button>
                                        </DividerNew>
                                    )}

                                    <ImportTags
                                        listTags={listTags}
                                        setListTags={setListTags}
                                        resetTags={updateListTagsSuggestions}
                                    />

                                    <br />
                                    <Table>
                                        <table>
                                            <thead>

                                                <tr>
                                                    <th colSpan={4}>TAG(S) SUGERIDAS</th>
                                                </tr>

                                                <tr>
                                                    <td>Categoria</td>
                                                    <td>Nome</td>
                                                    <td>Data</td>
                                                    <td>Status</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tagSugestions.map((item: any) => {
                                                    return (
                                                        <tr>
                                                            <td>{item.tagCategory.name}</td>
                                                            <td>{item.name}</td>
                                                            <td>{formatedDate(new Date(item.createdAt))}</td>
                                                            <td>

                                                                {user === EnumRoles.MASTER ? isLoading === item.identifier ?
                                                                    (
                                                                        <SpinnerIcon />
                                                                    )
                                                                    :
                                                                    (<TableActionOptions>
                                                                        <CheckCircle2 className="button approved"
                                                                            onClick={() => {
                                                                                setConfirmedTagId(item.identifier)
                                                                                setIsOpenSucessConfirm(true)
                                                                                setApprov(true)
                                                                            }}
                                                                            size={19} />
                                                                        <XCircle className="button reproved"
                                                                            onClick={() => {
                                                                                setConfirmedTagId(item.identifier)
                                                                                setIsOpenSucessConfirm(true)
                                                                                setApprov(false)
                                                                            }}
                                                                            size={19} />
                                                                        <EyeIcon className="button view" size={19}
                                                                            onClick={() => {
                                                                                setIsTagSugestion(true)
                                                                                setIdSugestiontag(item.identifier)
                                                                            }} />
                                                                    </TableActionOptions>) : "Em análise"
                                                                }

                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                }
                                            </tbody>
                                        </table>
                                    </Table>
                                    <br />

                                    <ModalTags
                                        title="Sugestão de Nova Tag"
                                        type="approvDisaprovTag"
                                        isOpen={isTagSugestion}
                                        onRequestClose={() => {
                                            setIsTagSugestion(false)
                                        }}
                                        approvetag={() => {


                                            setConfirmedTagId(idSugestiontag)
                                            setIsOpenSucessConfirm(true)
                                            setApprov(true)
                                            setIsTagSugestion(false)
                                        }}
                                        disaproveTag={() => {
                                            setConfirmedTagId(idSugestiontag)
                                            setIsOpenSucessConfirm(true)
                                            setApprov(true)
                                            setIsTagSugestion(false)
                                        }}
                                    >
                                        <Form onSubmit={() => { }}>
                                            <Divider>
                                                <Input
                                                    label="Categoria"
                                                    flex="fx50"
                                                    type="text"
                                                    disable={true}
                                                    value={dataTagSugestion?.tagCategory?.name}
                                                />
                                                <Input
                                                    label="Nome da Tag"
                                                    flex="fx50"
                                                    type="text"
                                                    disable={true}
                                                    value={dataTagSugestion.name}
                                                />
                                            </Divider>
                                            <>
                                                <p>Justificativa</p>
                                                <textarea
                                                    value={dataTagSugestion.justification}
                                                />
                                            </>
                                        </Form>
                                    </ModalTags>

                                    {/* <DivControlers>
                                        <button className="cancel">
                                            Cancelar
                                        </button>
                                        <button className="save">
                                            Salvar
                                        </button>
                                    </DivControlers> */}


                                    <ModalTags
                                        title="Criar Categoria"
                                        type="createCategory"
                                        isOpen={isCreateCategory}
                                        onRequestClose={() => {
                                            setIsCreateCategory(false)
                                        }}
                                        saveCategory={() => {
                                            saveCategory()
                                        }}
                                    >
                                        <Form onSubmit={() => { }}>
                                            <Input
                                                label="Nome da Categoria"
                                                type="text"
                                                value={nameCategory}
                                                onChange={(value: any) => setNameCategory(value)}
                                            />
                                            <>
                                                <p>Descrição</p>
                                                <textarea
                                                    name="justification"
                                                    value={justificationCategory}
                                                    onChange={(event: any) => setJustificationCategory(event.target.value)}
                                                />
                                            </>
                                        </Form>
                                    </ModalTags>
                                </>
                            )}

                        </Main>
                    </div>
                </Container>
            </SuperBox>
        </>
    )
}