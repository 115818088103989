import styled from "styled-components";


export const DivScreens = styled.div`

display: flex;
align-items: center;
justify-content: flex-start;
height: 30px;

border-bottom: 1px solid #606060;

.add{
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

`
