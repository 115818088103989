import styled from 'styled-components';

export const Container = styled.div`

`;

export const Tables = styled.div`
display: flex;

table{
    border-spacing: 0px;
    border: 1px solid #818181;
    flex: 1;
    thead{
        td{
            border-bottom: 1px solid #818181;
            padding: 5px;
        }
    }
    tbody{
        tr{
            td{
                border-bottom: 1px solid #646464;
            }
            &:last-child{
                td{
                    border-bottom: none;
                }
            }
        }
        td{
            padding: 8px;
            font-size: 16px;
            color: #646464;
            button{
                background-color: #F8F8FA;
                color: #646464;
                border: 0.5px solid #646464;
                border-radius: 5px;
                padding: 5px;
                cursor: pointer;
            }
        }
    }

    &:first-child{
        border-radius: 10px 0px 0px 10px;
    }
    &:last-child{
        border-radius: 0px 10px 10px 0px;
    }
}
`;