import { Dispatch, SetStateAction, createContext, useState } from "react";
import { Unit } from "../../../../../Utils/Types";

export interface UnitReport{
    id: string
    name: string
}

interface IContextUserGeneral {
   handleUnits: (units: UnitReport[]) => void
   units: UnitReport[]
}

export const ContextUserGeneral = createContext({} as IContextUserGeneral)

interface Props {
    children: React.ReactNode
}

export function ContextUserGeneralProvider({ children }: Props) {

    const [units, setUnits] = useState<UnitReport[]>([])

    function handleUnits(units: UnitReport[]){
        setUnits(units)
    }

    return (
        <ContextUserGeneral.Provider
            value={{
                handleUnits,
                units
            }}
        >
            {children}
        </ContextUserGeneral.Provider>
    )

}