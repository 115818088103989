import { Navigation } from './Navigation'
import { Container, InfoUser, More } from './styles'
import { Settings, LogOut } from 'lucide-react'
import { useState } from 'react'
import { ModalShort } from '../Modals/ModalShort'
import { useNavigate } from 'react-router-dom'
import { api } from '../../Services/api'
import { useEffect } from 'react'

export function LeftMenu(props: any) {
  const [switchExit, setSwitchExit] = useState(false)
  const [clients, setClients] = useState<any>([])
  useEffect(() => {
    async function fetchMe() {
      await api
        .post(
          '/auth/me',
          {},
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        )
        .then((response) => {
          localStorage.setItem('user', JSON.stringify(response.data.data))
        })
    }

    async function fetchUsers() {
      api.get('/admin/clients?limit=100', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
      }).then(response => {
        setClients(response.data.data);
      }
      )

    }


    fetchMe()
    if(JSON.parse(String(localStorage.getItem('roles'))) === 'Master'){
      fetchUsers()
    }
  }, [])

  function handleExit() {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = '/login'
  }

  return (
    <Container>
      <ModalShort
        isOpen={switchExit}
        on
        text="Tem certeza que deseja sair?"
        type="delete"
        onRequestClose={() => setSwitchExit(false)}
        onConfirm={() => {
          setSwitchExit(false)
          handleExit()
        }}
      />
      <InfoUser>
        <figure>
          <img
            src="https://tm.ibxk.com.br/2017/06/22/22100428046161.jpg"
            alt=""
          />
        </figure>
        <div>
          <p>
            {JSON.parse(localStorage.getItem('user') || '{}').name}{' '}
          </p>
        </div>
      </InfoUser>
      <Navigation
        clients={clients}
        switchT={props.switchT}
        switch={props.switch}
        page={props.page}
      />
      <More>
        <a href="#dgfs">
          <Settings size={16} />
          Preferências
        </a>
        <a
          href="#dgfs"
          onClick={(e) => {
            e.preventDefault()
            setSwitchExit(true)
          }}
        >
          <LogOut size={16} />
          Sair
        </a>
      </More>
    </Container>
  )
}
