import { FiLink } from "react-icons/fi";

import Modal, { ModalRef } from "..";
import { RefObject } from "react";
import {
  ButtonGreen,
  Container,
  ContainerContent,
  ContainerList,
  HeaderContainer,
} from "./styles";
import { X } from "lucide-react";

interface ShareProps {
  modalRef: RefObject<ModalRef>;
  title: string;
  children?: React.ReactNode;
  onSave?: () => void;
}

export const ModalListNew = ({
  modalRef,
  title,
  onSave,
  children,
}: ShareProps) => {
  function onClose() {
    modalRef.current?.close();
  }
  return (
    <Modal ref={modalRef}>
      <Container>
        <HeaderContainer>
          <span className="title">{title}</span>
          <X onClick={onClose} className="icon-close" />
        </HeaderContainer>
        <ContainerContent>
          <ContainerList>{children}</ContainerList>
          <ButtonGreen onClick={onSave} type="button">
            Ok
          </ButtonGreen>
        </ContainerContent>
      </Container>
    </Modal>
  );
};
