import { useEffect, useState } from 'react'
import { CallRegister } from '../../../../Components/CallRegister'
import { ProfileDatas } from '../../../../Components/ProfileDatas'
import { Sitemap } from '../styles'
import { Container, Datas, Header } from './styles'
import { api } from '../../../../Services/api'
import { maskPhoneNumber } from '../../../../Utils/Masks'

export function UserProfile(props: any) {
  const [user, setUser] = useState<any>();
  useEffect(() => {
    if (props.type === 1) {
      try {
        api.get(`/admin/technicians/${props.selected.identifier}`, {
          headers: {
            Authorization: `bearer ${localStorage.getItem('token')}`
          }
        }).then(response => {
          setUser(response.data.data)
        }).catch(error => {
          alert(error)
        })
      } catch (error) {
        alert(error)
      }
    }
    if (props.type === 8) {
      try {
        api.get(`/admin/education-institutions/${props.selected.identifier}/students`, {
          headers: {
            Authorization: `bearer ${localStorage.getItem('token')}`
          }
        }).then(response => {
          setUser(response.data.data)
        }).catch(error => {
          alert(error)
        })
      }
      catch (error) {
        alert(error)
      }
    }
  }, [props.selected, props.type])


  return (
    <>
      {props.type === 1 && (
        <Container>
          <ProfileDatas
            page="Técnico"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              <p>Nível: {user?.user.roles[0].name}</p>
              <span></span>
              <p>Nome: {props.selected.user.name}</p>
              <p>Empresa: {props.selected.company}</p>
              <p>Cargo: {props.selected.role ? props.selected.role : "Não informado"}</p>
              <p>E-mail: {props.selected.user.email}</p>
              <p>Telefone: {props.selected.user.phone ? maskPhoneNumber(props.selected.user.phone) : "Não informado"}</p>
              <span></span>
              <p>Permissões: {user?.user.roles.map((role: any) => role.name).join(', ')}</p>
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 2 && (
        <Container>
          <ProfileDatas
            page="Perfil: Gestão de Contrato"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 3 && (
        <Container>
          <ProfileDatas
            page="Perfil: Gestão de Unidade"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <p>Unidade Educacional: {props.selected.educationInstitution.name}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 4 && (
        <Container>
          <ProfileDatas
            page="Direção"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <p>Unidade Educacional: {props.selected.educationInstitution.name}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 5 && (
        <Container>
          <ProfileDatas
            page="Coordenação"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <p>Unidade Educacional: {props.selected.educationInstitution.name}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 6 && (
        <Container>
          <ProfileDatas
            page="Admnistrativo"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <p>Unidade Educacional: {props.selected.educationInstitution.name}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 7 && (
        <Container>
          <ProfileDatas
            page="Professor"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              {
                props.selected && (
                  <>
                    <p>Cliente: {JSON.parse(localStorage.getItem('client')!).socialName}</p>
                    <p>Unidade Educacional: {props.selected.educationInstitution.name}</p>
                    <span></span>
                    <p>Nome: {props.selected.user.name}</p>
                    <p>Data de Nascimento: {props.selected.user.birth_date ? props.selected.user.birth_date.split('T')[0].split('-').reverse().join('/') : 'Não informado'}</p>
                    <p>E-mail: {props.selected.user.email ?? 'Não informado'}</p>
                    <p>Telefone: {props.selected.user.phone ?? 'Não informado'}</p>
                  </>
                )
              }
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 8 && (
        <Container>
          <ProfileDatas
            page="Estudante"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              <p>Cliente: Arka Digital S/A</p>
              <p>Unidade Educacional: Unidade 1</p>
              <p>Turma: A15</p>
              <span></span>
              <p>Matrícula: 644652</p>
              <p>Nome: Nome do Usuário</p>
              <p>Data de Nascimento: 99/99/9999</p>
              <p>E-mail: arkadigital@brasil.com</p>
              <p>Telefone: (99) 99999-9999</p>
              <span></span>
              <p>Necessidades Especiais: Nenhuma</p>
              <p>Idade equivalente: 00</p>
              <p>Nível de Ensino: Fundamental</p>
              <p>Etapa: 2</p>
              <p>Fase: 7</p>
              <p>Ano/Faixa etária: 14</p>
              <span></span>
              <p>Responsável: Nome do responsável</p>
            </main>
          </Datas>
        </Container>
      )}
      {props.type === 9 && (
        <Container>
          <ProfileDatas
            page="Responsável"
            onBack={() => props.back()}
            onEdit={() => props.edit()}
            labelSingle="Usuário"
            labelPlural="Usuários"
          />
          <Datas>
            <main>
              <p>Nome: Nome do Usuário</p>
              <p>Data de Nascimento: 99/99/9999</p>
              <p>E-mail: arkadigital@brasil.com</p>
              <p>Telefone: (99) 99999-9999</p>
              <span></span>
              <p>Estudantes: Nome do estudante</p>
            </main>
          </Datas>
        </Container>
      )}
    </>
  )
}
