import styled from 'styled-components';

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;
  `;

export const TreeItemWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    .action-icons {
      display: flex;
      gap: 5px;
      align-items: center;
      svg {
        cursor: pointer;
        transition: color 0.2s;
        
        &:hover {
          color: #027294;
        }
      }
    }
`;

export const TreeItemContainer = styled.div<{ level: number }>`
    padding: ${props => 10 + (props.level * 5)}px;
    border: 1px solid #ccc;
    width: ${props => 400 + (props.level * 20)}px;
    min-height: 35px;
    margin-left: ${props => 0 + (props.level * 30)}px;
    margin-top: 5px;
    border-radius: 15px;
    cursor: pointer;
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    opacity: 0.8; 
    background-color: ${props => props.level === 0 ? "#027294" : props.level === 1 ? '#D4D4DE' : 'rgba(212, 212, 222, 0.6)'};

    &:hover {
      background-color: #f0f0f0;
    }

    > div {
      margin-left: ${props => props.level * 20}px;
      position: relative;
    }

      .arrow-icon {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
        border-top: 2px solid black;
        border-right: 2px solid black;
        transform: rotate(45deg);
        transition: transform 0.3s ease;
      }
    
      
    .item-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .item-name {
        margin-right: auto;
      }

    }
    
    &.opened .arrow-icon {
      transform: rotate(-45deg);
    }

    &.opened {
      height: auto;
    }
  `;

export const Item = styled.div`
    padding: 10px;
    border-bottom: 1px solid #ccc;
    &:last-child {
      border-bottom: none;
    }
  `;


export const ContainerNewCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 80%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`

export const ContainerNewSubCategory = styled.div`
margin-top: 25px;
margin-left: 15%;
border: 1px solid #000;
border-radius: 10px;
width: 80%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}
`