import React, { createContext, useState } from "react";


interface IContextReviewResource{
    stepReview: number
    handleStepReview: (n: number) => void
}

export const ContextResourceReview = createContext<IContextReviewResource>({} as IContextReviewResource)



interface Props {
    children: React.ReactNode
}


export function ContextReviewResourcerProvider({ children }: Props) {

    const [stepReview, setStepReview] = useState<number>(1)

    function handleStepReview(n: number){
        setStepReview(n)
    }

    return(
        <ContextResourceReview.Provider
            value={{
                handleStepReview,
                stepReview
            }}
        >
            {children}
        </ContextResourceReview.Provider>
    )
}