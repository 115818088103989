import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  `;


export const ContainerNew = styled.div`
margin-top: 25px;
margin-left: 25%;
border: 1px solid #000;
border-radius: 10px;
width: 50%;
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
background-color: #F8F8FA;  


header {
  background-color: #FE8F2E;
  color: #F8F8FA;
  text-align: center;
  border-radius: 10px 10px 0px 0px;
  height: 82px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-transform: uppercase;
}

section {
  padding: 20px 0px;
  width: 90%;

}

input{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  
}

.flex-label {
  display: flex;
  justify-content: space-between;
}

.flex-inputs {
  display: flex;
  justify-content: space-between;
}


textarea{
  height: 40px;
  border-radius: 15px;
  border: 1px solid black;
  width: 100%;
  padding-left: 10px;
  padding-top:10px;
  height: 100px;
}

label{
  font-size: 14px;
  line-height: 19px;
  color: #646464;
}

.space{
  margin-top: 10px;
}

footer {
  height: 90px;
  display: flex;
  align-items: center;
  position: relative;
  left: 40%;
  gap: 10px;
  width: 420px;
  > button {
    height: 40px;
    width: 110px;
    border-radius: 5px;
    border: none;
    background: #088A62;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:first-child{
      background: #838383;
    }
  }
}

`


export const Main = styled.main`
  width: 100%;
  margin: 0 auto;
`;


export const Upload = styled.div`
    display: flex;
    margin-bottom: 20px; 

>label{
    margin-right: 10px;
    background-color: #646464;
    display: flex;
    height: 39px;
    width: 160px;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    overflow: hidden;

    p{
        text-align: center;
        font-size: 11px;
    }
}

>input{
    display: none;
    }

    .text-type{
        height: 39px;
        width: 160px;

        p{
            font-size: 14px;
        }
    }
`;

export const Radio = styled.div`
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #646464;
    margin-top: 25px;

    .radio-inputs {
        display: flex;
        
        label{
            align-items: center;
            margin-right: auto;

            input{
                margin-right: 5px;
                vertical-align: middle;
                width: 17px;
            }
        }
    }
`;