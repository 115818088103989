import { FileText, Search } from "lucide-react";
import ReactInputMask from "react-input-mask";
import { Container, Label, Text } from "./styles";
import { FieldError, UseFormRegister } from "react-hook-form";
import { forwardRef } from "react";
import { ErrorLabel } from "./ErrorLabel";
import { maskCpf, maskDate, maskPhoneNumber } from "../../../Utils/Masks";
import { ContainerIcon, InputSearch } from "../../../Pages/Registration/UserRegistration/styles";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type:
    | "cpf"
    | "cnpj"
    | "cep"
    | "date"
    | "tel"
    | "email"
    | "text"
    | "consult"
    | "quantity"
    | "search"
    | "tel2";
  onClickConsult?: () => void;
  flex?: string;
  required: boolean;
  label: string;
  error?: FieldError | undefined;
  children?: React.ReactNode;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ label, required, type, flex, error, children, ...props }, ref) => {
    const isText =
      !(type === "cpf") &&
      !(type === "cnpj") &&
      !(type === "cep") &&
      !(type === "date") &&
      !(type === "tel") &&
      !(type === "email") &&
      !(type === "consult") &&
      !(type === "quantity") &&
      !(type === "search") &&
      !(type === "tel2");
    return (
      <Container error={!!error} className={flex}>
        <Label error={!!error} htmlFor={props.id}>
          {label}
          {required ? "*" : ""}
        </Label>
        {type === "cpf" && (
          <Text
            error={!!error}
            ref={ref}
            // onChange={(re) => onChange(re.target.value)}
            {...props}
            onChange={(e) => {
              props.onChange && props.onChange(e);
              e.target.value = `${maskCpf(e.target.value)}`;
            }}
          />
        )}
        {type === "cnpj" && (
          <ReactInputMask mask={"99.999.999/9999-99"} {...props} />
        )}
        {type === "cep" && <ReactInputMask mask={"99999-999"} {...props} />}
        {type === "date" && (
          <Text
            error={!!error}
            ref={ref}
            {...props}
            onChange={(e) => {
              props.onChange && props.onChange(e);
              e.target.value = `${maskDate(e.target.value)}`;
            }}
          />
        )}
        {type === "tel" && (
          <Text
            error={!!error}
            ref={ref}
            {...props}
            onChange={(e) => {
              props.onChange && props.onChange(e);
              e.target.value = `${maskPhoneNumber(e.target.value)}`;
            }}
          />
        )}
        {type === "tel2" && (
          <Text
            error={!!error}
            type="number"
            // value={value}
            // onChange={(re) => {
            //   let val = re.target.value.slice(0, 11);
            //   onChange(val);
            // }}
            {...props}
            style={{ appearance: "textfield" }}
          />
        )}
        {type === "email" && (
          <Text
            error={!!error}
            ref={ref}
            type="email"
            // id={inputId}
            // value={value}
            // onChange={(re) => onChange(re.target.value)  Line 43:7:  Expected an assignment or function call and instead saw an expression  @typescript-eslint/no-unused-expressions}
            {...props}
          />
        )}
        {type === "search" && (
        <InputSearch>
          <input
            type="text"
            required={required ? true : false}
            {...props}
          />
          <Search color={props.color || "#000000"} />
        </InputSearch>
      )}
        {type === "quantity" && (
          <Text
            error={!!error}
            ref={ref}
            type="number"
            // id={inputId}
            // value={value}
            // onChange={(re) => onChange(re.target.value)}
            {...props}
          />
        )}
        {(type === "text" || isText) &&
          (children ? (
            <ContainerIcon>
              <Text error={!!error} ref={ref} {...props} />
              {children}
            </ContainerIcon>
          ) : (
            <Text
              error={!!error}
              ref={ref}
              // onChange={(re) => onChange(re.target.value)}
              {...props}
            />
          ))}
        <ErrorLabel error={error} />
        {/* {type === "consult" && onClickConsult && (
          <div className="consult">
            <Text
              required={required ? true : false}
              id={inputId}
              value={value}
              onChange={(re) => onChange(re.target.value)}
            />
            <button
              onClick={() => onClickConsult()}
              className="hvr-pop"
              title="Consultar"
            >
              <FileText sizwdwadawdwe={12} />
            </button>
          </div>
        )} */}
      </Container>
    );
  }
);
