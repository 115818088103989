import styled from "styled-components";

export const Container = styled.section`
    background: #0E3048;
    width: 172px;
    min-height: 100vh;
    height: auto;
    padding: 20px 0px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: width 0.3s ease-in-out;

    &:hover {
        width: 300px; // Altere para o valor desejado
    }

    select {
        width: 100%;
        background-color: #0E3048;
    }
`;

export const InfoUser = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
gap: 10px;
    figure{
        width: 115px;
        height: 115px;
        overflow: hidden;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img{
        height: 100%;        
    }
    div{

    }
    p{
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
    }
`;

export const More = styled.div`
display: flex;
flex-direction: column;
gap: 1px;
    a{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        background-color: #027294;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        padding: 10px;
        text-decoration: none;
        transition: 0.3s;
        &:hover{
            background-color: #027CA1;
        }  
    }
`;