import React, { useContext, useEffect, useState } from 'react'
import { Class } from '../../../Utils/Types'
import { Form } from '../../../Pages/Registration/ClassRegistration/styles'
import { Divider } from '../../Form/Divider'
import { Select } from '../../Form/Select'
import { RegistrationContext } from '../../../Contexts/Form/RegistrationClassContext'
import { BoxResume } from '../../Form/BoxResume'
import { Controllers } from '../../Form/Controllers'
import EtapaAcademicaService from '../../../Services/EtapaAcademica.service'
import FasesService from '../../../Services/Fases.service'
import AnoLetivoService from '../../../Services/AnoLetivo.service'
import TurmaService from '../../../Services/Turma.service'
import { ModalShort } from '../../Modals/ModalShort'
import { useNavigate } from 'react-router-dom'

interface ReviewImportClassProps {
    arrayClass: Class[]
}

interface ShowStageProps {
    stageId: string;
}

const ShowStage = ({ stageId }: ShowStageProps) => {
    const [nameStage, setNameStage] = useState("")
    useEffect(() => {

        EtapaAcademicaService.show({ identifier: stageId }).then((response) => {
            if (response.success) {
                setNameStage(response.data.name)
            }
        })

    }, [])

    return (
        <p>{nameStage}</p>
    )

}

interface ShowFaseProps {
    educationInstitution: string,
    identifier: string
}

const ShowFase = ({ educationInstitution, identifier }: ShowFaseProps) => {

    const [faseName, setFaseName] = useState("")
    useEffect(() => {

        FasesService.show({ educationInstitution: educationInstitution, identifier: identifier }).then((response) => {
            if (response.success) {
                setFaseName(response.data.description)
            }
        })
    }, [])

    return (
        <p>{faseName}</p>
    )
}

interface ShowAgeRangeProps {
    identifier: string
}

const ShowAgeRange = ({ identifier }: ShowAgeRangeProps) => {

    const [ageRangeName, setAgeRangeName] = useState("")

    useEffect(() => {
        AnoLetivoService.show({ identifier: identifier }).then((response) => {
            if (response.success) {
                setAgeRangeName(response.data.description)
            }
        })
    }, [])

    return (
        <p>{ageRangeName}</p>
    )
}


const ReviewImportClass = ({ arrayClass }: ReviewImportClassProps) => {
    const unit = JSON.parse(sessionStorage.getItem("locationData")!);
    const { setListClasses, listClasses } = useContext(RegistrationContext)
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false)

    async function importClasses() {
        await Promise.all(listClasses.map((classe) =>
            TurmaService.create({
                name: classe.name,
                academic_year: classe.academic_year,
                academic_stage_id: classe.academic_stage_id,
                age_range_id: classe.age_range_id,
                initials: classe.initials,
                modality: [classe.modality], // modality: [classe.modality]
                phase_id: classe.phase_id
            })
        )).then(() => {
            setIsOpen(true)
        })

    }


    return (
        <Form>
            <ModalShort
                type="success"
                text="Turmas Importadas e Cadastradas!"
                onRequestClose={() => {
                    navigate("/turma/list")
                }}
                isOpen={isOpen}
            />
            {arrayClass.length > 0 ?
                <>
                    <Divider>
                        <p>Verifique se os dados que você cadastrou estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.</p>
                    </Divider>
                    {listClasses.map((classe, index: number) =>
                        <div key={index}>
                            <BoxResume>
                                <tr>
                                    <td>
                                        <span>TURMA:</span>
                                        <p>{classe.name}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Ano letivo: </span>
                                        <p>{classe.academic_year ? classe.academic_year : "Não informado"}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Modalidade: </span>
                                        <p>{classe.modality ? classe.modality : "Não informado"}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Etapa:</span>
                                        {classe.academic_stage_id
                                            ? <ShowStage stageId={classe.academic_stage_id} />
                                            : "Não informado"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Fase:</span>
                                        {classe.phase_id && unit.identifier
                                            ?
                                            <ShowFase
                                                educationInstitution={unit.identifier}
                                                identifier={classe.phase_id}
                                            />
                                            : "Não informado"
                                        }
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Ano/Faixa etária:</span>
                                        {classe.age_range_id
                                            ? <ShowAgeRange identifier={classe.age_range_id} />
                                            : "Não informado"
                                        }
                                    </td>
                                </tr>
                            </BoxResume>
                        </div>
                    )}
                </>
                : "Nenhuma turma informada"
            }
            <Controllers
                enablePrev={true}
                enableNext={true}
                save={true}
                functionCancel={() => window.location.reload()}
                functionSave={() => { importClasses() }}
                functionPrev={() => { }}
            />
        </Form>
    )
}

export default ReviewImportClass
