import { createContext, useState } from "react";
import { IPlataform } from "../../Services/Plataform.service";
import { ITargetAudience } from "../../Services/TargetAudience.service";
import { TItemSelect } from "../../types/types";


interface IResourceForm1Context {
  targetAudienceSelect: ITargetAudience[];
  plataformSelect: IPlataform[];
  resourceOrigin: TItemSelect | undefined;
  resourceEquivalent: TItemSelect[];
  setTargetAudienceSelectForm: (target: ITargetAudience[]) => void;
  setPlataformSelectForm: (plataform: IPlataform[]) => void;
  setResourceOriginForm: (resource: TItemSelect) => void;
  setResourceEquivalentForm: (resource: TItemSelect[]) => void;
}

export const ResourceForm1Context = createContext<IResourceForm1Context>(
  {} as IResourceForm1Context
);

interface IResourceContextProviderProps {
  children: React.ReactNode;
}

export function ResourceContextForm1Provider({
  children,
}: IResourceContextProviderProps) {
  const [targetAudienceSelect, setTargetAudienceSelect] = useState<
    ITargetAudience[]
  >([]);
  const [plataformSelect, setPlataformSelect] = useState<IPlataform[]>([]);
  const [resourceOrigin, setResourceOrigin] = useState<TItemSelect>();
  const [resourceEquivalent, setResourceEquivalent] = useState<TItemSelect[]>([]);

  function setTargetAudienceSelectForm(target: ITargetAudience[]) {
    setTargetAudienceSelect(target);
  }

  function setPlataformSelectForm(plataform: IPlataform[]) {
    setPlataformSelect(plataform);
  }

  function setResourceOriginForm(resource: TItemSelect) {
    setResourceOrigin(resource);
  }

  function setResourceEquivalentForm(resource: TItemSelect[]) {
    setResourceEquivalent(resource);
  }
  return (
    <ResourceForm1Context.Provider
      value={{
        targetAudienceSelect,
        plataformSelect,
        resourceEquivalent,
        resourceOrigin,
        setTargetAudienceSelectForm,
        setPlataformSelectForm,
        setResourceOriginForm,
        setResourceEquivalentForm
      }}
    >
      {children}
    </ResourceForm1Context.Provider>
  );
}
