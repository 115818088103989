import styled from "styled-components";

export const DivItens = styled.div`
display: flex;
justify-content: flex-start;
align-items: center;
height: 40px;
width: auto;
background-color: #088a62;


`

export const DivSelected = styled.div`
display: flex;
justify-content: space-around;
padding: 5px;
align-items: center;
width: 100%;
height: 70%;

.bar{
    display: flex;
    width: 2px;
    background-color: white;
    height: 100%;
}
p{
    color: white;
    margin-right: 5px;
}
`