import { Search } from "lucide-react";
import { useState } from "react";
import { Container } from "./styles";

export function SearchTable(props: any){
    const [value, setValue] = useState("");

    return(
        <Container>
            <input type="text" onChange={(e: any) => setValue(e.target.value)} value={value} />
            <button className="hvr-icon-pop" type="submit"> <Search className="hvr-pop" /> </button>
        </Container>
    )
}