import { useEffect, useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { Form } from "../styles";
import { api } from "../../../../Services/api";
import { useLocation, useNavigate } from "react-router-dom";
import { EnumEmployees, TypeTeacher } from "../../../../Utils/Types";
import RoleService from "../../../../Services/Role.service";
import { Wizard, WizardStep } from "../../../../Components/Wizard";
import {
  getNameEmployeeByType,
  getTitleEmployee,
  removeCaracteresInString,
  transformStringDateInDate,
} from "../../../../Utils/Functions";
import FuncionarioService from "../../../../Services/Funcionario.service";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { Input } from "../../../../Components/Form/InputHook";

import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import { maskCpf, maskPhoneNumber } from "../../../../Utils/Masks";
import ManagerService from "../../../../Services/Manager.service";

//Objeto de validação do formulário
const newEmployeeFormValidationSchema = zod
  .object({
    name: zod.string().min(10, "Nome precisa de no mínimo 10 caracteres"),
    cpf: zod.string().regex(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "CPF inválido"),
    birth_date: zod.string().regex(/^\d{2}\/\d{2}\/\d{4}$/, "Data inválida"),
    phone: zod.string().min(10, "Telefone inválido"),
    email: zod.string().email("E-mail inválido"),
    confirmEmail: zod.string(),
  })
  .refine((data) => data.email === data.confirmEmail, {
    path: ["confirmEmail"],
    message: "Os emails não coincidem",
  });

type FormData = zod.infer<typeof newEmployeeFormValidationSchema>;
interface StateProps {
  educationInstitution: any;
  user: TypeTeacher & { active: boolean; roles: any[] };
  identifier: string;
}

interface FormEmployeesProps {
  typeEmployee:
  | EnumEmployees.COORD
  | EnumEmployees.DIRECTOR
  | EnumEmployees.MANAGER
  | EnumEmployees.MANAGER_EMPLOYEE
  | EnumEmployees.ADMIN_EMPLOYEE
  | EnumEmployees.GUARDIAN;
}
export function FormEmployee({ typeEmployee }: FormEmployeesProps) {
  //Verificado se existe algum usuário pela navagação(se estiver entra no modo de edição)
  const location = useLocation();
  const edit: StateProps = location.state;
  //Configurando o react-hook-form
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(newEmployeeFormValidationSchema),
    defaultValues: {
      name: edit ? edit.user.name : "",
      cpf: edit ? ( edit.user.cpf !== null && edit.user.cpf !== ""  ? maskCpf(edit.user.cpf) : "Não informado") : "",
      birth_date: edit
        ? new Date(edit.user.birth_date).toLocaleDateString()
        : "",
      phone: edit ? (edit.user.phone !== null && edit.user.phone !== "" ? maskPhoneNumber(edit.user.phone) :  "Não informado") : "",//edit ? maskPhoneNumber(edit.user.phone) : "",
      email: edit ? edit.user.email : "",
      confirmEmail: edit ? edit.user.email : "",
    },
  });
  const formValues = watch();
  const [stepForm, setStepForm] = useState(1);
  const [modalSave, setModalSave] = useState(false);
  const [role, setRole] = useState<any>([]);
  const navigate = useNavigate();
  const id = JSON.parse(sessionStorage.getItem("locationData")!);

  //Mensagens que se alteram conforme modo da pagina
  const title = edit ? "editou" : "cadastrou";
  const messageSucess = edit
    ? "Edição realizada com sucesso"
    : "Cadastro salvo com sucesso";

  const onSubmmit: SubmitHandler<FormData> = async (data) => {
    const dataEmployee = {
      name: data.name,
      email: data.email,
      birth_date: transformStringDateInDate(data.birth_date),
      cpf: removeCaracteresInString(data.cpf),
      role_id: role[0].slug,
      phone: removeCaracteresInString(data.phone),
    };

    if (edit) {

      if (typeEmployee === EnumEmployees.MANAGER) {
        ManagerService.updateManager(dataEmployee, id.identifier, edit.identifier).then((res) => {
          if (res?.success) {
            setModalSave(true);
            setStepForm(2);
          }
        })
      } else {
        FuncionarioService.put(dataEmployee, id.identifier, edit.identifier).then(
          (res) => {
            if (res?.success) {
              setModalSave(true);
              setStepForm(2);
            }
          }
        );
      }
    } else if (typeEmployee === EnumEmployees.MANAGER) {
      ManagerService.postManager({ data: dataEmployee, identifier: id.identifier }).then((response) => {
        if (response.success) {
          setModalSave(true)
          setStepForm(2);
        }
      })
    } else {
      FuncionarioService.post(dataEmployee, id.identifier).then((res) => {
        if (res?.success) {
          setModalSave(true);
          setStepForm(2);
        }
      });
    }
  };

  const nextStep = async () => {
    const validate = await trigger();
    if (validate) {
      setStepForm(2);
    }
  };

  useEffect(() => {
    if (!edit) {
      const codRole = getNameEmployeeByType(typeEmployee);
      RoleService.get().then((res) => {
        if (res?.success) {
          setRole(res.data.filter((item: any) => item.slug === codRole));
        }
      });
    } else {
      setRole([edit.user.roles[0]]);
    }
  }, []);

  const clearErrorField = (field: keyof FormData) => {
    if (errors[field]) {
      trigger(field);
    }
  };

  return (
    <>
      <Divider>
        <Wizard>
          <WizardStep
            text={getTitleEmployee(typeEmployee)}
            active={stepForm === 1}
          />
          <WizardStep text="Revisar" active={stepForm === 2} />
        </Wizard>
      </Divider>
      {stepForm === 1 && (
        <Form onSubmit={(e) => e.preventDefault()}>
          <Divider>
            <Input
              required
              label="Nome"
              flex="fx100"
              type="text"
              error={errors.name}
              {...register("name", {
                onChange: () => clearErrorField("name"),
              })}
            />
          </Divider>
          <Divider>
            <Input
              error={errors.cpf}
              label="CPF"
              required
              flex="fx33"
              type="cpf"
              {...register("cpf", {
                onChange: () => clearErrorField("cpf"),
              })}
            />

            <Input
              error={errors.birth_date}
              label="Data de Nascimento"
              required
              flex="fx33"
              type="date"
              {...register("birth_date", {
                onChange: () => clearErrorField("birth_date"),
              })}
            />

            <Input
              error={errors.phone}
              label="Telefone"
              required
              flex="fx33"
              type="tel"
              {...register("phone", {
                onChange: () => clearErrorField("phone"),
              })}
            />
          </Divider>
          <Divider>
            <Input
              error={errors.email}
              flex="fx50"
              type="email"
              label="Email"
              required
              {...register("email", {
                onChange: () => clearErrorField("email"),
              })}
            />
            <Input
              error={errors.confirmEmail}
              required={true}
              flex="fx50"
              type="email"
              label="Confirmar Email"
              {...register("confirmEmail", {
                onChange: () => clearErrorField("confirmEmail"),
              })}
            />
          </Divider>
          <Controllers
            functionNext={nextStep}
            functionPrev={() => {
              navigate(-1);
            }}
            functionCancel={() => navigate(-1)}
            enableNext={true}
            enablePrev={true}
          />
        </Form>
      )}

      {stepForm === 2 && (
        <Form>
          <ModalShort
            type="success"
            text={messageSucess}
            isOpen={modalSave}
            onRequestClose={() => navigate("/unidade/usuario/list")}
            onConfirm={() => {
              navigate("/usuario");
            }}
          />
          <BoxResume
            label={`Verifique se os dados que você ${title} estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.`}
          >
            <tr className="divider">
              <td>
                <span>{getTitleEmployee(typeEmployee)}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Nome:</span>
                <p>{formValues.name}</p>
              </td>
            </tr>
            <tr>
              <td>
                <span>CPF:</span>
                <p>{formValues.cpf}</p>
              </td>
            </tr>
            <tr>
              <td>
                <span>Data de Nascimento:</span>
                <p>{formValues.birth_date}</p>
              </td>
            </tr>
            <tr>
              <td>
                <span>Telefone:</span>
                <p>{formValues.phone}</p>
              </td>
            </tr>
            <tr>
              <td>
                <span>Email:</span>
                <p>{formValues.email}</p>
              </td>
            </tr>
          </BoxResume>
          <Controllers
            save={true}
            functionPrev={() => setStepForm(1)}
            functionSave={handleSubmit(onSubmmit)}
            functionCancel={() => navigate(-1)}
            enableNext={true}
            enablePrev={true}
          />
        </Form>
      )}
    </>
  );
}
