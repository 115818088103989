import { zodResolver } from "@hookform/resolvers/zod";
import { CheckCircle2 } from "lucide-react";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import * as zod from "zod";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Input } from "../../../../Components/Form/InputHook";
import { Select } from "../../../../Components/Form/Select";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { WizardStepNew } from "../../../../Components/WizardStepNew";
import { ResourceForm1Context } from "../../../../Contexts/Form/ContextResouceForm1";
import {
  EnumStepNewResource,
  ResourceContext,
} from "../../../../Contexts/Form/ContextResource";
import CollectionService, {
  ICategoriesCollection,
} from "../../../../Services/Collection.service";
import { Queries } from "../../../../types/queries";
import { useCreateResource } from "../hooks/useCreateResource";
import { useListSignatures } from "../hooks/useListSignatures";
import { DivNoSub, DividerNew, FormContainer, Line, OptionsCategories } from "../styles";
import { FormAuthor } from "./Forms/FormAuthor";
import { FormDestination } from "./Forms/FormDestination";
import { FormFormat } from "./Forms/FormFormat";
import { FormSimilarity } from "./Forms/FormSimilarity";
import SubcategoryComponent from "./SubcategoryComponent";
import { TableCollection } from "./Tables/TableCollections";
import FormThumbnails from "./Forms/FormThumbnails";
import ComponentSubcategory from "./ComponentSubcategory";
import { useUpdateResource } from "../hooks/useUpdateResource";

const stepResource = [
  "Autoria",
  "Formato",
  "Thumbnails",
  "Similaridade",
  "Destinação",
  // "Jurídico",
];

function FactoryForms({ stepActual }: { stepActual: number }) {
  switch (stepActual) {
    case 0:
      return <FormAuthor />;
    case 1:
      return <FormFormat />;
    case 2:
      return <FormThumbnails />;
    case 3:
      return <FormSimilarity />;
    case 4:
      return <FormDestination />;
    default:
      return null;
  }
}

const fieldsSchema = zod.object({
  nameResource: zod.string().min(2, "Nome precisa de no mínimo 2 caracteres"),
});

type FormData = zod.infer<typeof fieldsSchema>;

export function Form1() {
  const {
    plataformSelect,
    targetAudienceSelect,
    resourceEquivalent,
    resourceOrigin,
  } = useContext(ResourceForm1Context);
  const {
    resource,
    addFieldRequired,
    handleFieldResource,
    handleStep,
    setFieldsArrayResource,
    idResource
  } = useContext(ResourceContext);

  const { collections } = resource;
  const {
    register,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(fieldsSchema),
    defaultValues: {
      nameResource: resource.name,
    },
  });
  const formValues = watch();
  const navigate = useNavigate();
  const mutateCreate = useCreateResource();

  const [messageVisible, setMessageVisible] = useState(false);
  const [collectionIdSelect, setCollectionIdSelect] = useState<string | null>(
    null
  );
  const [url, setUrl] = useState<string | undefined>("")

  const [categoryCollectionSelect, setCategoryCollectionSelect] = useState<
    string | null
  >(null);

  useEffect(() => {
    handleFieldResource("name", formValues.nameResource);
  }, [formValues.nameResource]);
  const { isSuccess, data, isLoading } = useListSignatures();

  const listCategoryByCollection = useQuery(
    [Queries.LIST_CATEGORIES_COLLECTION, collectionIdSelect],
    () =>
      CollectionService.listCategoriesByIdCollection(collectionIdSelect ?? ""),
    {
      enabled: !!collectionIdSelect,
      cacheTime: 5,
    }
  );

  const categorySelectLevel = listCategoryByCollection.data?.find(
    (category) => category.identifier === categoryCollectionSelect
  );

  function handleIdCollection(id: string) {
    setCollectionIdSelect(id);
    setCategoryCollectionSelect(null);
  }

  function handleUrlTyped(url: string) {
    setUrl(url)
  }

  function handleIdCategoryCollection(
    category: ICategoriesCollection,
    idCollection: string
  ) {
    /* Se a categoria selecionada for a mesma que já está selecionada,
    então deseleciona ela */
    if (category.identifier === categoryCollectionSelect) {
      setCategoryCollectionSelect(null);
      return;
    }

    /* Se a categoria selecionada tiver subcategorias, então seleciona ela */
    setCategoryCollectionSelect(category.identifier);
    // } else {
    //   if (
    //     collections.find(
    //       (categoryCollection) =>
    //         categoryCollection.identifier === category.identifier
    //     )
    //   ) {
    //     removeCategoryCollectionSelects(category);
    //   } else {
    //     addCategoryCollectionSelects({
    //       ...category,
    //       collection_id: idCollection,
    //     });
    //   }
  }

  async function
    nextStep() {
    let required = false;
    if (!resource.format) {
      addFieldRequired("format");
      required = true;
    }
    if (!resource.language) {
      addFieldRequired("language");
      required = true;
    }

    handleFieldResource("origin_resource", resourceOrigin?.value ?? "");
    const listIdsEquivalentResources = resourceEquivalent.map(
      (resource) => resource.value
    );
    setFieldsArrayResource(listIdsEquivalentResources, "equivalent_resources");

    const listIdsTargetAudience = targetAudienceSelect.map(
      (target) => target.identifier
    );
    const listIdsPlataforms = plataformSelect.map(
      (plataform) => plataform.identifier
    );
    setFieldsArrayResource(listIdsTargetAudience, "target_audience");
    setFieldsArrayResource(listIdsPlataforms, "plataforms");

    //Verificação para passar de formulário
    const validate = await trigger("nameResource");
    if (validate) {
      handleFieldResource("name", formValues.nameResource);
    } else {
      return;
    }
    if (resource.collections.length && !resource.collectionDefault) {
      setMessageVisible(true);
      return;
    }
    if (resource.url === "") {
      alert("Preencha a URL.")
      required = true;
    }
    if (resource.collections.length === 0) {
      alert("Selecione ao menos uma coleção.")
      required = true;
    }
    // console.log(resource);
    // return;

    if (required) return;
    // mutateCreate.mutate(resource);
    handleStep(EnumStepNewResource.BNCC);
    mutateCreate.mutate({ ...resource, target_audience: listIdsTargetAudience, plataforms: listIdsPlataforms });
  }

  const updateResource = useUpdateResource();

  function update() {
    handleStep(EnumStepNewResource.BNCC);
    updateResource.mutate(resource);
    
  }

  function cancel() {
    navigate(-1);
  }

  function onSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  const clearErrorField = (field: keyof FormData) => {
    if (errors[field]) {
      trigger(field);
    }
  };

  function closeMessage() {
    setMessageVisible(false);
  }

  function handleURL(e: ChangeEvent<HTMLInputElement>) {
    handleFieldResource("url", e.target.value)
  }

  return (
    <FormContainer onSubmit={onSubmit}>
      {/* Input para o nome do recurso */}
      <Input
        label="Nome do recurso"
        required={true}
        type="text"
        placeholder="Digite o nome do recurso"
        error={errors["nameResource"]}
        {...register("nameResource", {
          onChange: () => clearErrorField("nameResource"),
        })}
      />

      <Line />

      {/* Input Select para visualização das coleções */}
      <Select
        value={collectionIdSelect}
        onChange={handleIdCollection}
        flex="fx80"
        label="Selecione uma Coleção"
        inputId="anoletivoid"
      >
        {isLoading ? (
          <option value="">Carregando ...</option>
        ) : (
          <>
            <option value="">Selecione uma coleção</option>
            {isSuccess
              ? data.map((collection) => (
                <option
                  key={collection.identifier}
                  value={collection.identifier}
                >
                  {collection.name}
                </option>
              ))
              : null}
          </>
        )}
      </Select>

      {/* Listagem das categorias se uma coleção estiver selecionada*/}
      {listCategoryByCollection.isSuccess ? (
        <OptionsCategories $flex={categorySelectLevel ? "row" : "row-reverse"}>
          {categorySelectLevel ? (
            <>
              <span
                onClick={() =>
                  handleIdCategoryCollection(
                    categorySelectLevel,
                    collectionIdSelect ?? ""
                  )
                }
                className={`option selected `}
                key={categorySelectLevel.identifier}
              >
                {categorySelectLevel.name}
              </span>
              {categorySelectLevel.subcategories.length > 0 ? (
                <ComponentSubcategory
                  collectionId={collectionIdSelect!}
                  category={categorySelectLevel}
                />
              ) : (
                <DivNoSub>NÃO EXISTE SUBCATEGORIA...</DivNoSub>
              )}
            </>
          ) : (
            listCategoryByCollection.data.map((categories) => {
              const selected = collections.some(
                (collection) => collection.category_id === categories.identifier
              );
              const className = `option ${selected ? "selected" : null}`;

              return (
                <span
                  onClick={() =>
                    handleIdCategoryCollection(
                      categories,
                      collectionIdSelect ?? ""
                    )
                  }
                  className={className}
                  key={categories.identifier}
                >
                  {categories.name}
                  {selected ? <CheckCircle2 className="icon" /> : null}
                </span>
              );
            })
          )}
        </OptionsCategories>
      ) : null}

      {/* Tabela das categorias selecionadas */}
      <TableCollection />

      <Input
        label="URL"
        type="text"
        required={false}
        value={resource.url}
        onChange={handleURL}
      />

      {stepResource.map((step, key) => (
        <>
          <DividerNew>
            <WizardStepNew center={true} key={step} text={step} />
          </DividerNew>
          <FactoryForms stepActual={key} />
        </>
      ))}

      <Line />
      <Controllers
        functionNext={idResource === "" ? nextStep : update}
        functionCancel={cancel}
        enableNext={true}
        enablePrev={false}
      />
      <ModalShort
        type="infoError"
        text="Selecione uma coleção principal"
        isOpen={messageVisible}
        onConfirm={() => closeMessage()}
      />
    </FormContainer>
  );
}
