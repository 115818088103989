import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
`;
interface StepProps {
  $color: string;
  $center: boolean;
  $button: boolean;
}
export const Step = styled.div<StepProps>`
  ${({ $center }) =>
    $center
      ? css`
          padding: 0.5rem 1rem;

          justify-content: start;
        `
      : css`
          padding: 1rem;
          flex: 1;
        `}

  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #838383;
  border-radius: 10px 10px 0px 0px;
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  height: 50px;
  /* TXT Botao Bold */

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  cursor: ${({ $button }) => ($button ? "pointer" : "default")};
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }

  .icon {
    color: white;
  }
  &.active {
    background-color: ${({ $color }) => $color};
  }
`;
