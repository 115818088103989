import React, { useEffect, useState } from 'react'
import { Divider } from '../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../Components/Wizard'
import { Form } from '../styles'
import { Input } from '../../../../Components/Form/Input'
import { Controllers } from '../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import { SelectClassAdd } from '../../../../Components/SelectClassAdd'
import TurmaService from '../../../../Services/Turma.service'
import TableClass from './TableClass/TableClass'
import ReviewDataTeacher, { TypeTeacherNew } from './Review'
import { Class } from '../../../../Utils/Types'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ContainerSelectSearch } from './styles'

type Classe = {
  identifier: string,
  name: string,
  academicYear: any,
  ageRange: any,
  educationStage?: any,
  phase?: any,
  initials?: any,
  modality?: any,

}


const FormTeacher = () => {

  const unit = JSON.parse(sessionStorage.getItem("locationData")!);
  const navigate = useNavigate();
  const [stepForm, setStepForm] = useState(1)
  const [listClasses, setListClasses] = useState<Classe[]>([])
  const [listClassesSelecteds, setListClassesSelecteds] = useState<string[]>([])
  const [name, setName] = useState("")
  const [cpf, setCpf] = useState("")
  const [date, setDate] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [confirmEmail, setConfirmEmail] = useState("")

  useEffect(() => {


    TurmaService.list({ idState: unit.identifier }).then((response) => {
      if (response.success) {
        setListClasses(response.data)
      }
    })

  }, [])

  const [classShowArray, setClassShowArray] = useState<Class[]>([])

  const addClass = (e: any) => {
    setListClassesSelecteds((state) => {
      return [...state, e]
    })
  }

  const addClasses = (e: any) => {
    setListClassesSelecteds((state) => {
      return [...state, ...e]
    })
  }

  useEffect(() => {

    
      listClassesSelecteds.filter((classe) => !classShowArray.some((showClass) => showClass.identifier === classe))
      .forEach((classe) => {
        TurmaService.showClass({ classID: classe, idInstitution: unit.identifier }).then((response) => {
          if (response.success) {
            setClassShowArray((state) => {
              return [...state, response.data]
            })
          }
        })
      })
  

  }, [listClassesSelecteds])

  const formatDate = (stringDate: string) => {
    const day = stringDate.slice(0, 2);
    const month = stringDate.slice(2, 4);
    const year = stringDate.slice(4);
    return `${day}/${month}/${year}`
  }

  const dataTeacher: TypeTeacherNew = {
    name: name,
    cpf: cpf,
    birth_date: formatDate(date),
    phone: phone,
    email: email,
    role_id: "teacher-employee"
  }

  function nextStep() {
    if (dataTeacher.name === "") {
      alert("Preencha o nome.")
    } else if (dataTeacher.name.length < 10) {
      alert("O nome precisa de pelo menos 10 caracteres.")
    } else if (dataTeacher.birth_date === "//") {
      alert("Preencha a data de nascimento.")
    } else if (dataTeacher.cpf === "") {
      alert("Preencha o cpf.")
    } else if (dataTeacher.email === "") {
      alert("Preencha o email.")
    } else if (confirmEmail === "") {
      alert("Confirme seu email")
    } else if (dataTeacher.email !== confirmEmail) {
      alert("Os emails não correspondem")
    } else if (dataTeacher.phone === "") {
      alert("Preencha seu telefone.")
    } else if (listClassesSelecteds.length === 0) {
      alert("Selecione as turmas.")
    }
    else {
      setStepForm(2)
    }
  }

  function selectAll() {
    addClasses(listClasses.map((classe) => classe.identifier))
  }

  console.log(listClassesSelecteds)

  return (
    <>
      <Divider>
        <Wizard>
          <WizardStep
            text={`PROFESSOR`}
            active={stepForm === 1}
          />
          <WizardStep text="Revisar" active={stepForm === 2} />
        </Wizard>
      </Divider>
      {stepForm === 1 && (

        <Form onSubmit={() => { }}>
          <Divider>
            <Input
              label="Nome"
              flex="fx100"
              value={name}
              onChange={setName}
              type="text"
              required
            />
          </Divider>
          <Divider>
            <Input
              label="CPF"
              flex="fx33"
              value={cpf}
              onChange={setCpf}
              type="cpf"
              required
            />
            <Input
              label="Data de nascimento"
              flex="fx33"
              value={date}
              onChange={setDate}
              type="date"
              required
            />
            <Input
              label="Telefone"
              flex="fx33"
              value={phone}
              onChange={setPhone}
              type="tel"
              required
            />
          </Divider>
          <Divider>
            <Input
              label="E-mail"
              flex="fx50"
              value={email}
              onChange={setEmail}
              type="email"
              required
            />
            <Input
              label="Confirmar Email"
              flex="fx50"
              value={confirmEmail}
              onChange={setConfirmEmail}
              type="email"
            />
          </Divider>
          <ContainerSelectSearch>
            <Autocomplete
              disablePortal
              options={[...listClasses, { name: "Selecionar todos", identifier: "-1" }]}
              renderInput={(params) => <TextField {...params} label="Turmas" />}
              getOptionLabel={(option) => option.name}
              onChange={(event, newValue) => {
                if (newValue?.identifier === "-1") {
                  return selectAll();
                }
                return addClass(newValue?.identifier)
              }}
              className='select-search'
            />
          </ContainerSelectSearch>
          {/**/}
          <TableClass
            unitName={unit.name}
            classesSelecteds={classShowArray}
            setClassesSelectes={setClassShowArray}
          />
          <Controllers
            enablePrev={false}
            enableNext={true}
            functionCancel={() => navigate(-1)}
            functionNext={() => nextStep()}
          />
        </Form>
      )}
      {stepForm === 2 && (
        <ReviewDataTeacher
          setStepForm={setStepForm}
          unit={unit}
          dataTeacher={dataTeacher}
          listClassesSelecteds={classShowArray}
        />
      )}
    </>
  )
}

export default FormTeacher
