import { FileDown, FileText, TableIcon } from "lucide-react";
import { Container, Datas, PrintTable } from "./styles";
import * as XLSX from "xlsx";
import { ClientData } from "..";
import { Dispatch, SetStateAction, useRef } from "react";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";

interface ClientProps {
    clientsFiltereds: ClientData[];
    setClientSelected: Dispatch<SetStateAction<ClientData[]>>;
    clientSelected: ClientData[];
}

export function OutputComponent({ clientSelected, clientsFiltereds, setClientSelected }: ClientProps) {

    // selecionar todos
    const handleSelectAll = () => {
        if (clientSelected.length === clientsFiltereds.length) {
            setClientSelected([]);
        } else {
            setClientSelected(clientsFiltereds);
        }
    };

    // calcular a quantidade de usuários
    function calculateUsers(client: any) {
        const totalManagers = client.manager ? client.manager.length : 0;
        const totalEmployees = client.plan ? client.plan.maxEmployees : 0;
        const totalStudents = client.plan ? client.plan.maxStudents : 0;

        return totalManagers + totalEmployees + totalStudents;
    }

    // formatar numero de telefone
    const telefone = (client: any) => {
        return client?.phone.length === 11
            ? `(${client?.phone.substring(0, 2)}) ${client?.phone.substring(2, 7)}-${client?.phone.substring(7, 11)}`
            : `(${client?.phone.substring(0, 2)}) ${client?.phone.substring(2, 6)}-${client?.phone.substring(6, 10)}`;
    };


    // Salvando no exel
    const handleSaveExcel = () => {
        if (clientSelected.length !== 0) {

            const tableData = [
                ["Razão Social", "CNPJ", "E-mail", "Telefone", "Rua", "Complemento",
                    "Bairro", "Cidade", "CEP", "Coleções Cadastradas", "Pacotes Cadastrados",
                    "Gestor de Contrato", "Número de Usuários Docentes", "Número de Usuários Estudantes",
                    "Número de Unidades Educacionais", "Número total de Usuários", "Total de turmas", "Total de Recursos disponíveis"]
            ];

            clientSelected.forEach(client => {
                const row = [
                    client.socialName,
                    client.cnpj,
                    client.email,
                    String(telefone(client)),
                    client.address.address,
                    client.address.complement,
                    client.address.neighborhood,
                    `${client.address.city} - ${client.address.state}`,
                    client.address.zipCode,
                    client.plan.collections.map((collection: any) => collection.name).join(', '),
                    client.plan.packages.map((packagee: any) => packagee.name).join(', '),
                    client.manager.map((manager: any) => manager.user.name).join(', '),
                    String(client.plan.maxEmployees),
                    String(client.plan.maxStudents),
                    String(client.plan.maxEducationInstitutions),
                    String(calculateUsers(client)),
                    "Indiponível",
                    "Indiponível"
                ];
                tableData.push(row);
            });

            const workbook = XLSX.utils.book_new();
            const worksheet = XLSX.utils.aoa_to_sheet(tableData);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Dados do(s) Cliente(s)");
            XLSX.writeFile(workbook, "dados_cliente.xlsx");
        } else {

        }
    };

    // Imprimir
    const componentRef = useRef<any>(null);
    const handlePrintTableData = () => {
        window.print();
    };

    // PDF
    const handleSavePDF = () => {
        if (clientSelected.length !== 0) {
            const input = componentRef.current;

            const pdf = new jsPDF("p", "pt", "a4");
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const contentWidth = input.offsetWidth;
            const newWidth = contentWidth * 1.5;

            pdf.html(input, {
                callback: () => {
                    pdf.save("relatorio_cliente.pdf");
                },
                html2canvas: { scale: 0.70 },
                x: 0,
                y: 0,
                width: newWidth
            });
        }
    };

    return (
        <>
            <Container>
                <div className="select-all">
                    <input
                        type="checkbox"
                        name="client"
                        checked={clientSelected.length === clientsFiltereds.length}
                        onChange={handleSelectAll}
                    />
                    <p>selecionar todos</p>
                </div>

                <div className="num-itens">
                    <p>ítens selecionados</p>
                    <div className="number">{clientSelected.length}</div>
                </div>
                <div className="vertical-line"></div>

                <footer>
                    <ReactToPrint
                        content={() => componentRef.current}
                        trigger={() => (
                            <button><FileDown size={14} />Imprimir</button>
                        )}
                    />
                    <button onClick={handleSavePDF}><FileText size={14} />Salvar em PDF</button>
                    <button onClick={handleSaveExcel}><TableIcon size={14} />Salvar em Excel</button>
                </footer>
            </Container>


            <PrintTable>
                <Datas ref={componentRef}>
                    <h1>Relatório do(s) Cliente(s)</h1>

                    {clientSelected.map((client: any, index) => {
                        return (
                            <>
                                <p><b>Cliente:</b> {client?.socialName}</p>
                                <div className="div-main" key={index}>
                                    <aside>
                                        <div>
                                            {/* <img
                                                src={"https://portal.arkadigital.dev.br/" + client.logo}
                                                alt=""
                                            /> */}
                                        </div>
                                    </aside>
                                    <main>
                                        <p>CNPJ: {client?.cnpj}</p>
                                        <p>E-mail: {client?.email}</p>
                                        <p>Telefone: {telefone(client)}</p>
                                        <span></span>
                                        <p>Rua: {client?.address.address}</p>
                                        <p>Complemento: {client?.address.complement}</p>
                                        <p>Bairro: {client?.address.neighborhood}</p>
                                        <p>Cidade: {client?.address.city} - {client?.address.state}</p>
                                        <p>CEP: {client?.address.zipCode}</p>
                                        <span></span>
                                        <b>Coleções Cadastradas:</b>
                                        {client?.plan.collections.map((collection: any, index: number) => (
                                            <p key={index}>• {collection.name}</p>
                                        ))}
                                        <b>Pacotes Cadastrados:</b>
                                        {client?.plan.packages.map((packagee: any, index: number) => (
                                            <p key={index}>• {packagee.name}</p>
                                        ))}
                                        <b>Gestor de Contrato:</b>
                                        {
                                            client.manager.map((manager: any, index: number) => (
                                                <p key={index}>• {manager.user.name}</p>
                                            ))
                                        }
                                        <span></span>
                                        <p>Número de Usuários Docentes: {client?.plan.maxEmployees}</p>
                                        <p>Número de Usuários Estudantes: {client?.plan.maxStudents}</p>
                                        <p>
                                            Número de Unidades Educacionais:{" "}
                                            {client?.plan.maxEducationInstitutions}
                                        </p>
                                        <p>Número total de Usuários: {calculateUsers(client)}</p>
                                        <span></span>
                                        <p>Total de turmas: Indisponível </p>
                                        <p>Total de Recursos disponíveis: Indisponível</p>
                                    </main>
                                </div>
                            </>
                        )
                    })}
                </Datas>
            </PrintTable>
        </>
    )
}