import styled from "styled-components";

export const DivShowStudents = styled.div`
display: flex;
justify-content: center;
align-items: flex-start;
flex-direction: column;
width: 70%;
height: auto;
gap: 0.2rem;
padding: 0.2rem;

ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    li{
        list-style: none;
    }
}


`