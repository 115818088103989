import { Dispatch, SetStateAction, createContext, useState } from "react"

interface Props {
    children: React.ReactNode
}

export type TeacherEdit = {
    identifier: string
    active?: boolean
    birth_date: string
    cpf: string
    email: string
    login?: string
    name: string
    phone: string
}


interface IContextEditTeacher {
    step: number
    handleStep: (int: number) => void
    teacher: TeacherEdit
    setTeacher: Dispatch<SetStateAction<TeacherEdit>>
}

export const ContextEditTeacher = createContext<IContextEditTeacher>({} as IContextEditTeacher)



export function ContextEditTeacherProvider({ children }: Props) {
    const [teacher, setTeacher] = useState<TeacherEdit>({} as TeacherEdit)
    const [step, setStep] = useState<number>(1)

    function handleStep(int: number) {
        setStep(int)
    }


    return (
        <ContextEditTeacher.Provider
            value={{
                handleStep,
                step,
                setTeacher,
                teacher
            }}
        >
            {children}
        </ContextEditTeacher.Provider>
    )

}