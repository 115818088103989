import { Box, Button, Container, Controls, Table } from './styles'

import { Upload, PlusCircle, Save, Edit, Trash2 } from 'lucide-react'
import { useEffect, useState } from 'react'
import { ModalShort } from '../../Modals/ModalShort'
import { read, utils } from 'xlsx'

export interface Unidade {
  name: string,
  address: string,
  number: string,
  complement?: string,
  neighborhood: string,
  zip_code: string,
  city: string,
  state: string,
  phone: string,
  email: string,
}


export function ImportExcelUnidade(props: any) {
  const [modalDelete, setModalDelete] = useState(false)
  const [pres, setPres] = useState<Unidade[]>([
    {
      name: "",
      address: "",
      city: "",
      email: "",
      neighborhood: "",
      number: "",
      phone: "",
      state: "",
      zip_code: "",
    },
  ])



  async function handleImport(e: any) {
    if (!e.target.files) return
    const f = await e.target.files[0].arrayBuffer()
    const wb = read(f)
    const data = utils.sheet_to_json<Unidade>(wb.Sheets[wb.SheetNames[0]])
    setPres([...pres, ...data])
    props.setUnidadeList([...pres, ...data])
  }


  useEffect(() => {
    setPres([])
  }, [])
  const [forEdit, setForEdit] = useState<any>('')

  function isDuplicate(unit: Unidade, unidadeList: any) {
    return unidadeList.some((existingUnit: any) =>
      existingUnit.name === unit.name &&
      existingUnit.phone === unit.phone &&
      existingUnit.zip_code === unit.zip_code &&
      existingUnit.city === unit.city &&
      existingUnit.state === unit.state &&
      existingUnit.neighborhood === unit.neighborhood &&
      existingUnit.address === unit.address &&
      existingUnit.number === unit.number
    );
  }

  function handleAddLine(unit: Unidade) {
    if (
      unit.name === '' ||
      unit.phone === '' ||
      unit.zip_code === '' ||
      unit.city === '' ||
      unit.state === '' ||
      unit.neighborhood === '' ||
      unit.address === '' ||
      unit.number === ''
    ) {
      alert('Preencha todos os campos obrigatórios');
    } else if (isDuplicate(unit, props.unidadeList)) {
      alert('Já existe uma unidade com essas informações');
    } else {
      props.setUnidadeList([...props.unidadeList, unit]);
      props.clearFields();
    }
  }

  const [forDelete, setForDelete] = useState<any>()

  function handleDeleteLine(indexDelete: number) {
    let newItems = props.unidadeList.filter((item: any, index: any) => index !== indexDelete)
    props.setUnidadeList(newItems)
  }
  return (
    <Box>
      <ModalShort
        type="delete"
        text="Tem certeza que deseja excluir este item?"
        isOpen={modalDelete}
        onRequestClose={() => setModalDelete(false)}
        onConfirm={() => {
          setModalDelete(false)
          handleDeleteLine(forDelete)
        }}
      />
      <Container>
        <Button className="hvr-icon-up">
          <label htmlFor="uploadid">
            {props.label} <Upload className="hvr-icon" size={16} />
          </label>
          <input
            type="file"
            name=""
            id="uploadid"
            onChange={(e) => handleImport(e)}
          />
        </Button>
        <Controls>

          <button
            type="button"
            className="hvr-icon-pop"
            onClick={() => handleAddLine(props.sendData)}
          >
            <PlusCircle className="hvr-icon" size={16} /> ADICIONAR UNIDADE À LISTA
          </button>
        </Controls>
      </Container>
      <Table>
        <p>{props.tableName}</p>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Telefone</th>
              <th>E-mail</th>
              <th>CEP</th>
              <th>Cidade</th>
              <th>Estado</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {props.unidadeList.map((plan: any, index: any) => {
              return (
                <tr key={index}>
                  <td align='center'>{plan.name ? plan.name : "-"}</td>
                  <td align='center'>{plan.phone ? plan.phone : "-"}</td>
                  <td align='center'>{plan.email ? plan.email : "-"}</td>
                  <td align='center'>{plan.zip_code ? plan.zip_code : "-"}</td>
                  <td align='center'>{plan.city ? plan.city : "-"}</td>
                  <td align='center'>{plan.state ? plan.state : "-"}</td>
                  {plan.name === '' ? (
                    <td></td>
                  ) : (
                    <td>
                      <button
                        title="Editar"
                        onClick={() => {
                          props.toEdit(plan)
                          setForEdit(index.toString())
                        }}
                      >
                        <Edit size={16} className="hvr-grow" />
                      </button>
                      <button
                        title="Excluir"
                        onClick={() => {
                          handleDeleteLine(index)
                        }}
                      >
                        <Trash2 size={16} className="hvr-grow" />
                      </button>
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </table>
      </Table>
    </Box>
  )
}
