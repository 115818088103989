import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
padding: 5px 30px;
}
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;
    footer{
        margin: 20px 0px 20px 0px;
    }
`;

export const Abas = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    button{
        height: 40px;
        width: 100%;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background-color: #027294;
        border: none;
        border-radius: 10px;
    }
`
export const Controls = styled.div`
    display: flex;
    justify-content: flex-end;
    button{
        display: flex;
        padding-top: 20px;
        font-size: 16px;
        text-transform: uppercase;
        background-color: transparent;
        border: none;
        align-items: center;
        gap: 5px;
        font-weight: 700;
        color: #FE8F2E;
        cursor: pointer;
        &:last-child{
            color: #FE8F2E;
        }
        .search-icon {
            display: flex;
            justify-content: center; 
            align-items: center;
            width: 40px;
            height: 40px;
            text-align: center;
            border-radius: 15px;
            color: #fff;
            background: #FE8F2E;
        }
    }

`;

export const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 50px;
`;


export const Table = styled.div`
    border: 1px solid #646464;
    border-radius: 15px;

    >p{
    width: 100%;
    text-align: center;
    padding: 10px 0px;
    }
    
    table{
        width: 100%;
        border-spacing: 0px;
        thead{
            tr{
                td{
                    text-align: center;
                }
                .header-cell{
                    justify-content: center;
                }
            }
        }
    tbody{
        tr{
            td{
                padding: 0;
                height: auto;
                text-align: center;
                min-height: 25px;
            }
        }
    }
    td{
        padding: 5px;
        font-size: 14px;
        color: #646464;
        color: black;
        border-top: 1px solid #646464;
        border-left: 1px solid #646464;
            &:first-child{
                display: flex;
                border-left: none;
                align-items: center;
                input{
                    align-items: center;
                    margin-left: 10px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }
    }
}
`;

export const Transfer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;
        button{
            height: 40px;
            width: 110px;
            border-radius: 10px;
            border: none;
            background-color: #088A62;
            color: #fff;
            cursor: pointer;
            //cursor: not-allowed;
            text-transform: uppercase;

            &.enable{
                background-color: #088A62;
                cursor: pointer;
        }
    }
`;

export const ContainerNew = styled.div`
    margin-top: 30px;
    .text{
        text-transform: uppercase;
        color: #818181;
        size: 24px;
    }
    section{
        margin-top: 20px;
    }
`;

export const DividerNew = styled.div`
    margin-top: 10px;
`;

export const ContainerResume = styled.div`

    textarea{
    height: 40px;
    border-radius: 15px;
    border: 1px solid black;
    width: 100%;
    padding-left: 10px;
    padding-top:10px;
    height: 100px;
    }

`;

export const Controller = styled.div`
    margin-top: 20px;
   
`;