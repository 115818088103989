import { PlusCircle, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BoxResume } from "../../../Components/Form/BoxResume";
import { Controllers } from "../../../Components/Form/Controllers";
import { Divider } from "../../../Components/Form/Divider";
import { Input } from "../../../Components/Form/Input";
import { Select } from "../../../Components/Form/Select";
import { ModalShort } from "../../../Components/Modals/ModalShort";
import { SuperBox } from "../../../Components/SuperBox";
import { WizardStep } from "../../../Components/Wizard";
import FuncionarioService from "../../../Services/Funcionario.service";
import RoleService from "../../../Services/Role.service";
import TurmaService from "../../../Services/Turma.service";
import UnidadeService from "../../../Services/Unidade.service";
import { formatarCpf, formatarFone, getNameEmployee } from "../../../Utils/Functions";
import { Class, EnumEmployees, EnumEmployeesName, NewEmployee, TypeRoles, TypeTeacher, UserEmployee, UserEmployee2 } from "../../../Utils/Types";
import { useListRoles } from "../../../hooks/list/useListRoles";
import { Form } from "../ClientRegistration/styles";
import { Table } from "../UserRegistration/FormTeacher/ImportExcel/styles";
import { Container, Main } from "../UserRegistration/styles";
import { TableExcel } from "./TableExcel";
import { Controls } from "./styles";
import ImportEmployee from "./ImportEmployee/ImportEmployee";
const id = JSON.parse(sessionStorage.getItem("locationData")!);

export interface OptionSelect {
    name: string;
    id: string
}
interface UnitSelected {
    unit: any,
    classrooms: Class[]
}
export function EmployeeRegistration(props: any) {

    const [switchForm, setSwitchForm] = useState(1);
    //const [employeeSelected, setEmployeeSelected] = useState("");

    const [units, setUnits] = useState<any>([{}]);
    const [unitSelected, setUnitSelected] = useState("")
    console.log(unitSelected)
    const [classes, setClasses] = useState<any>([])
    const [classSelected, setClassSelected] = useState<any[]>([])
    const [perfilsSelecteds, setPerfilsSelecteds] = useState("")
    const [modalSave, setModalSave] = useState(false);
    const navigate = useNavigate()
    const [unitsRegistragion, setUnitsRegistragion] = useState<any[]>([])
    const [name, setName] = useState("");
    const [cpf, setCpf] = useState("");
    const [date, setDate] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [confirmEmail, setConfirmEmail] = useState("");
    const [selectedUnits, setSelectedUnits] = useState<UnitSelected[]>([])

    function formatarData(inputData: any) {
        if (inputData.length !== 8) {
            return "Formato de data inválido";
        }
        const dia = inputData.slice(0, 2);
        const mes = inputData.slice(2, 4);
        const ano = inputData.slice(4);
        return `${dia}/${mes}/${ano}`;
    }
    function formatarData2(inputData: any) {
        if (inputData.length !== 8) {
            return "Formato de data inválido";
        }
        const dia = inputData.slice(0, 2);
        const mes = inputData.slice(2, 4);
        const ano = inputData.slice(4);
        return `${dia}-${mes}-${ano}`;
    }


    const formatedDate = formatarData(date);
    const formatedCpf = formatarCpf(cpf);
    const formatedPhone = formatarFone(phone);

    const [title, setTitle] = useState("");
    const messageSucess = "Cadastro salvo com sucesso";
    const [role, setRole] = useState<any>([]);
    const { data, isSuccess, isLoading } = useListRoles()

    const roles: OptionSelect[] = (isSuccess && data?.success && data.data) ?
        data.data.filter((role: any) => role.userType === TypeRoles.EMPLOYEE)
            .map((role: any) => {
                return {
                    id: role.slug,
                    name: role.name
                }
            }) : []

    const [selectedOne, setSelectedOne] = useState("");
    const [perfilArray, setPerfilArray] = useState<{ unidade: string, perfil: string }[]>([]);

    useEffect(() => {
        RoleService.get().then((res) => {
            if (res?.success) {
                switch (selectedOne) {
                    case EnumEmployees.TEACHER:
                        setRole(
                            res.data.filter((item: any) => item.slug === EnumEmployeesName.TEACHER));
                        break;
                    case EnumEmployees.MANAGER:
                        setRole(
                            res.data.filter((item: any) => item.slug === EnumEmployeesName.MANAGER));
                        break;
                    case EnumEmployees.MANAGER_EMPLOYEE:
                        setRole(
                            res.data.filter(
                                (item: any) => item.slug === EnumEmployeesName.MANAGER_EMPLOYEE));
                        break;
                    case EnumEmployees.DIRECTOR:
                        setRole(
                            res.data.filter((item: any) => item.slug === EnumEmployeesName.DIRECTOR));
                        break;
                    case EnumEmployees.COORD:
                        setRole(
                            res.data.filter((item: any) => item.slug === EnumEmployeesName.COORD));
                        break;
                    case EnumEmployees.ADMIN_EMPLOYEE:
                        setRole(
                            res.data.filter((item: any) => item.slug === EnumEmployeesName.ADMIN_EMPLOYEE));
                        break;
                }
            }
        });

        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnitsRegistragion(response.data);
                setUnits(response.data);
            }
        })

    }, [])



    useEffect(() => {

        switch (selectedOne) {
            case "6":
                setTitle("Professor")
                break
            case "3":
                setTitle("Diretor")
                break
            case "1":
                setTitle("Gerente")
                break
            case "2":
                setTitle("Funcionário Gerente")
                break
            case "4":
                setTitle("Coordenador")
                break
            case "5":
                setTitle("Funcionário Administrador")
                break
        }

        // UnidadeService.showUnit({ idUnit: unitSelected }).then((response) => {
        //     if (response.success) {
        //         setUnitShow(response.data)
        //     }
        // })

        UnidadeService.list().then((response) => {
            if (response.success) {
                setUnits(response.data)
            }
        })

        if (unitSelected !== "") {
            TurmaService.list({ idState: unitSelected }).then((response) => {
                if (response.success) {
                    setClasses(response.data)
                }
            })
        }

        // if (classSelected !== "") {
        //     TurmaService.showClass({ idInstitution: unitSelected, classID: classSelected }).then((response) => {
        //         if (response.success) {
        //             setClassShow(response.data)

        //         }
        //     })
        // }

    }, [unitSelected, classSelected, selectedOne])


    function saveEmployee() {
        console.log(unitSelected)
        if (perfilsSelecteds === "teacher-employee") {
            console.log(unitSelected)
            const data: TypeTeacher = {
                name: name,
                email: email,
                birth_date: formatarData2(date),
                cpf: cpf,
                role_id: "teacher-employee",
                phone: phone
            }

            FuncionarioService.postTeacher(data, unitSelected).then((response) => {
                if (response?.success) {
                    setModalSave(true);
                }
            })

        } else {

            console.log(role.perfil)

            Promise.all(perfilArray.map((role) => {


                const data: TypeTeacher = {
                    name: name,
                    email: email,
                    birth_date: formatedDate,
                    cpf: cpf,
                    role_id: role.perfil,
                    phone: phone
                }

                FuncionarioService.post(data, role.unidade).then((response) => {
                    if (response?.success) {
                        setModalSave(true);
                    }
                })
            }))

        }
    }

    function verification() {
        if (selectedOne === "" && perfilArray.length === 0) {
            alert("Selecione um perfil.")
        } else if (selectedOne == "6") {
            alert("Selecione uma turma.")
        } else if (unitSelected === "" && perfilArray.length === 0) {
            alert("Selecione uma unidade.")
        } else if (name === "") {
            alert("Preencha o nome.")
        } else if (cpf === "") {
            alert("Preencha o cpf.")
        } else if (date === "") {
            alert("Preencha da data de nascimento.")
        } else if (phone === "") {
            alert("Preencha o Telefone.")
        } else if (email === "") {
            alert("Preencha o email.")
        } else if (confirmEmail === "") {
            alert("Confirme seu email")
        } else {
            setSwitchForm(2)
        }
    }

    const handlePerfilArrayChange = (e: any, position: number) => {
        perfilArray[position] = {
            ...perfilArray[position],
            perfil: e,
        }
        setPerfilArray([...perfilArray]);
        setPerfilsSelecteds(e)
        console.log(e)

    };

    const handleUnitArrayChange = (e: any, position: number) => {
        perfilArray[position] = {
            ...perfilArray[position],
            unidade: e
        }
        setPerfilArray([...perfilArray]);
        setUnitSelected(e)
    };

    function addPerfil() {
        if (perfilArray.length < 2) {
            setPerfilArray((state: any) => {
                return [...state, {
                    unidade: "",
                    perfil: ""
                }]
            })
            return null
        }
    }

    function removePerfil(position: number) {
        if (perfilArray.length < 2) {
            alert("Não é possível excluir")
            return
        } else if (position === -1) {
            return
        }
        const index = perfilArray.filter((item, index) => index !== position);
        setPerfilArray(index)
    }

    const rolesFilter = roles
    const idTeacher = roles.find((role) => role.id == "teacher-employee")?.id ?? -1

    // controls estático de perfil e unidade
    const [handleRedirect, setHandleRedirect] = useState(false)
    const [effectExecuted, setEffectExecuted] = useState(false);
    useEffect(() => {
        if (!effectExecuted) {
            addPerfil();
            setEffectExecuted(true);
        }
    }, [effectExecuted]);


    const [listImportEmployee, setListImportEmployee] = useState<NewEmployee[]>([])



    return (
        <SuperBox
            switch={true}
            path={`Cadastro Funcionário`}
        >
            <Container>
                <div className="box">
                    <Main>
                        <Divider>
                            {switchForm === 1 ? (
                                <WizardStep text="Funcionário" active />
                            ) : (
                                <WizardStep text="Funcionário" />
                            )}
                            {switchForm === 2 ? (
                                <WizardStep text="Revisar" active />
                            ) : (
                                <WizardStep text="Revisar" />
                            )}
                        </Divider>
                        <br />
                        {switchForm === 1 && (
                            <>
                                <Form onSubmit={() => { }}>
                                    <Divider>
                                        <Input
                                            flex="fx100"
                                            label="Nome"
                                            type="text"
                                            value={name}
                                            onChange={setName}
                                        />
                                    </Divider>
                                    <Divider>
                                        <Input
                                            flex="fx33"
                                            label="CPF"
                                            type="cpf"
                                            value={cpf}
                                            onChange={setCpf}
                                        />
                                        <Input
                                            flex="fx33"
                                            label="Data de Nascimento"
                                            type="date"
                                            value={date}
                                            onChange={setDate}
                                        />
                                        <Input
                                            flex="fx33"
                                            label="Telefone"
                                            type="tel"
                                            value={phone}
                                            onChange={setPhone}
                                        />
                                    </Divider>
                                    <Divider>
                                        <Input
                                            flex="fx50"
                                            label="E-mail"
                                            type="email"
                                            value={email}
                                            onChange={setEmail}
                                        />
                                        <Input
                                            flex="fx50"
                                            label="Confirmar E-mail"
                                            type="email"
                                            value={confirmEmail}
                                            onChange={setConfirmEmail}
                                        />
                                    </Divider>

                                    <Controls>
                                        <div>
                                            {handleRedirect && addPerfil()}
                                        </div>
                                    </Controls>

                                    {
                                        perfilArray.map((perfil, index: number) => {
                                            return (
                                                <>
                                                    <hr />
                                                    <Divider key={perfil.perfil}>
                                                        <Select
                                                            flex="fx30"
                                                            label="Perfil"
                                                            value={perfil.perfil}
                                                            onChange={(e: string) => handlePerfilArrayChange(e, index)}
                                                        >
                                                            <option value="">Selecione</option>
                                                            {rolesFilter.map((perfil) => {
                                                                return (
                                                                    <option key={perfil.id} value={perfil.id}>{perfil.name}</option>
                                                                )
                                                            })}
                                                        </Select>

                                                        {perfil.perfil !== idTeacher && (
                                                            <Select
                                                                flex="fx70"
                                                                label="Unidade Educacional"
                                                                value={perfilArray[index].unidade}
                                                                onChange={(e: string) => handleUnitArrayChange(e, index)}
                                                            >
                                                                <option value="">Selecione uma unidade</option>
                                                                {units.map((unit: any) => {
                                                                    return (
                                                                        <option value={unit.identifier} key={unit.identifier}>{unit.name}</option>
                                                                    )
                                                                })}
                                                            </Select>
                                                        )}

                                                        <button
                                                            className="buttonEmployeeDelete"
                                                            onClick={() => removePerfil(index)}
                                                        >
                                                            <Trash2 size={18} color="red" />
                                                        </button>
                                                    </Divider>
                                                </>
                                            )
                                        })
                                    }

                                    <Controls>
                                        {perfilArray.length < 2 && (
                                            <button onClick={addPerfil}>
                                                Perfil adicional <PlusCircle size={16} />
                                            </button>
                                        )}
                                    </Controls>
                                    <hr />
                                    <ImportEmployee
                                        listEmployee={listImportEmployee}
                                        setListEmployee={setListImportEmployee}
                                    />

                                    {perfilsSelecteds === idTeacher && (
                                        <TableExcel
                                            tableName="Adicionar Turmas"
                                            units={units}
                                            unitSelected={unitSelected}
                                            setUnitSelected={setUnitSelected}
                                            listClass={classes}
                                            classSelected={classSelected}
                                            setClassSelected={setClassSelected}
                                            selectedUnits={selectedUnits}
                                            setSelectedUnits={setSelectedUnits}
                                        />
                                    )}

                                    <br />
                                    <Controllers
                                        functionNext={() => {
                                            verification()
                                        }}
                                        functionCancel={() => {
                                            navigate('/employees/listEmployees')
                                        }}
                                        enableNext={true}

                                    />
                                </Form>
                            </>
                        )}

                        {switchForm === 2 && (
                            <>
                                <Form onSubmit={() => { }}>
                                    <BoxResume label={`Verifique se os dados estão corretos.`}>
                                        <ModalShort
                                            type="success"
                                            text={messageSucess}
                                            isOpen={modalSave}
                                            onRequestClose={() => navigate("/employees/listEmployees")}
                                            onConfirm={() => {
                                                navigate("/employees/listEmployees");
                                            }}
                                        />

                                        <>
                                            {/* <tr>
                                                    <td>
                                                        <span>Perfil:</span>
                                                        {title}
                                                    </td>
                                                </tr> */}
                                            <tr>
                                                <td>
                                                    <span>Nome:</span>
                                                    {name}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>CPF:</span>
                                                    {formatedCpf}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Data de Nascimento:</span>
                                                    {formatedDate}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Telefone:</span>
                                                    {formatedPhone}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Email:</span>
                                                    {email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Perfil:</span>
                                                    {perfilArray.map((perfil, index) => (
                                                        <span key={index}>
                                                            {roles.find(role => role.id === perfil.perfil)?.name}
                                                            {index !== perfilArray.length - 1 && ', '}
                                                        </span>
                                                    ))}
                                                </td>
                                            </tr>

                                        </>
                                    </BoxResume>
                                    {perfilsSelecteds === idTeacher && (
                                        <>
                                            <Table>
                                                <p>Turmas</p>
                                                <table>
                                                    <thead>
                                                        <td>Unidade(s) Educacional(is)</td>
                                                        <td>Turma(s)</td>
                                                    </thead>
                                                    <tbody>
                                                        {selectedUnits.map((item) => {
                                                            return (
                                                                <tr>
                                                                    <td className="td">{item.unit.label}</td>
                                                                    <td className="td">
                                                                        {item.classrooms.map((classe) => {
                                                                            return (
                                                                                <p>
                                                                                    {classe.name}
                                                                                </p>
                                                                            )
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </Table>
                                        </>
                                    )}




                                    <Controllers
                                        functionCancel={() => {
                                            navigate('/employees/listEmployees')
                                        }}
                                        functionSave={() => {
                                            saveEmployee();
                                        }}
                                        functionPrev={() => {
                                            setSwitchForm(1)
                                        }}
                                        enableNext={true}
                                        enablePrev={true}
                                        save={true}
                                    />
                                </Form >
                            </>
                        )}
                    </Main >
                </div >
            </Container >
        </SuperBox >
    )
}