import { useState } from "react";
import { BoxResume } from "../../../../Components/Form/BoxResume";
import { Controllers } from "../../../../Components/Form/Controllers";
import { Divider } from "../../../../Components/Form/Divider";
import { Input } from "../../../../Components/Form/Input";
import { ModalShort } from "../../../../Components/Modals/ModalShort";
import { Form} from "./styles";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Wizard, WizardStep } from "../../../../Components/Wizard";
import TechService from "../../../../Services/Tech.service";
import RoleService from "../../../../Services/Role.service";
import { maskCpf, maskPhoneNumber } from "../../../../Utils/Masks";
import { Select } from "../../../../Components/Form/Select";

export function FormTechEdit(props: any) {
  const [stepForm, setStepForm] = useState(1);
  const [modalSave, setModalSave] = useState(false);
  const [name, setName] = useState("");
  const [cpf, setCpf] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [roleId, setRoleId] = useState<string>("");
  const [roles, setRoles] = useState<any[]>([])

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [responsible, setResponsible] = useState("");
  const navigate = useNavigate();


  useEffect(() => {
    TechService.getById(props.selected.identifier).then((response) => {
      if (response?.success) {
        console.log(response.data.data.user.roles)
        
      }
    });
    RoleService.get().then((response)=>{
      if(response?.success){
        setRoles(response.data)
      }
    })
  }, []);

  const [dataRole, setDataRole] = useState<any>([])

  useEffect(()=>{

    RoleService.showRole({identifier: roleId}).then((response)=>{
      if(response?.success){
        setDataRole(response.data)
      }
    })


  },[roleId])

  useEffect(() => {

    


    if (props.techSelect) {
      setName(props.techSelect.user.name);
      setCpf(props.techSelect.user.cpf);
      setCompany(props.techSelect.company);
      setRole(props.techSelect.role === null ? "Não informado": props.techSelect.role);
      setPhone(props.techSelect.user.phone === null ? "Não informado" : props.techSelect.user.phone );
      setEmail(props.techSelect.user.email);
      setResponsible(props.techSelect.responsible_name);
      setRoleId(props.techSelect.user.roles ? props.techSelect.user.roles[0].slug : "")
    }
  }, [props.techSelect]);

  async function saveTech() {
    TechService.put(
      {
        name: name,
        company: company,
        role: role,
        phone: phone,
        responsible_name: responsible,
        login: email,
        email: email,
        cpf: cpf,
        role_id: roleId!!,
      },
      props.selected.identifier
    ).then((response) => {
      if (response?.success) {
        setModalSave(true);
      }
    });
  }

  console.log(props.techSelect)


  return (
    <>
      {stepForm === 1 && (
        <Form onSubmit={() => {}}>
          <Divider>
            <Wizard>
              <WizardStep text="Técnico" active />
              <WizardStep text="Revisar" active={false} />
            </Wizard>
          </Divider>
          <Input
            label="Nome"
            onChange={(e: any) => setName(e)}
            value={name}
            type="text"
            required
          />
          <Divider>
            <Input
              flex="fx33"
              type="cpf"
              label="CPF"
              onChange={(e: any) => setCpf(e)}
              value={cpf}
            />
            <Input
              flex="fx33"
              label="Empresa"
              onChange={(e: any) => setCompany(e)}
              value={company}
              type="text"
            />
            <Input
              flex="fx33"
              label="Cargo"
              onChange={(e: any) => setRole(e)}
              value={role}
              type="text"

            />
          </Divider>
          <Divider>
            <Input
              flex="fx50"
              type={props.techSelect.user.phone === null ? "text":"tel"}
              label="Telefone"
              onChange={(e: any) => setPhone(e)}
              value={phone}
              required
            />
            <Input
              flex="fx50"
              label="Nome do Responsável Atual (Somente para nível Master)"
              onChange={(e: any) => setResponsible(e)}
              type="text"

              value={responsible}
            />
          </Divider>
          <Divider>
            <Input
              flex="fx50"
              type="email"
              label="Email"
              onChange={(e: any) => setEmail(e)}
              value={email}
              required
            />
            <Input
              flex="fx50"
              type="email"
              label="Confirmar Email"
              onChange={(e: any) => setConfirmEmail(e)}
              value={confirmEmail}
              required
            />
          </Divider>
          <Divider>
            <Select
              label="Nível(grau de permissões do usuário)"
              flex="fx40"
              value={roleId}
              onChange={setRoleId}
            >
              {roles
              .filter((role: any)=> role.userType === "technician")
              .map((role: any)=>{
                return(
                  <>
                  <option value={role.slug}>{role.name}</option>
                  </>
                )
              })
              }
            </Select>
          </Divider>
          <Controllers
            functionNext={() => {
              if (
                name === "" ||
                phone === "" ||
                email === "" ||
                confirmEmail === ""
              ) {
                alert("Preencha todos os campos obrigatórios");
                return;
              }
              if (email !== confirmEmail) {
                alert("Os emails não coincidem");
                return;
              }

              setStepForm(2);
            }}
            functionCancel={props.back}
            enableNext={true}
            enablePrev={false}
          />
        </Form>
      )}
      {stepForm === 2 && (
        <Form>
          <Divider>
            <Wizard>
              <WizardStep text="Técnico" active={false} />
              <WizardStep text="Revisar" active />
            </Wizard>
          </Divider>
          <ModalShort
            type="success"
            text="Edição realizada com sucesso"
            isOpen={modalSave}
            onRequestClose={() => navigate("/tech/list")}
            onConfirm={() => {
              navigate("/tech/list");
            }}
          />
          <BoxResume label="Verifique se os dados que você editou estão corretos. Se estiver tudo certo clique no botão SALVAR para prosseguir.">
            <tr className="divider">
              <td>
                <span>Técnico</span>
              </td>
            </tr>
            <tr>
              <td>
                <span>Nome:</span>
                {name}
              </td>
            </tr>
            <tr>
              <td>
                <span>CPF:</span>
                {maskCpf(cpf)} 
              </td>
            </tr>
            <tr>
              <td>
                <span>Empresa:</span>
                {company}
              </td>
            </tr>
            <tr>
              <td>
                <span>Cargo:</span>
                {role}
              </td>
            </tr>
            <tr>
              <td>
                <span>Telefone:</span>
                {maskPhoneNumber(phone)}
              </td>
            </tr>
            <tr>
              <td>
                <span>Responsável:</span>
                {responsible}
              </td>
            </tr>
            <tr>
              <td>
                <span>Email:</span>
                {email}
              </td>
            </tr>
            <tr>
              <td>
                <span>Nível:</span>
                {dataRole.name}
              </td>
            </tr>
          </BoxResume>
          <Controllers
            save={true}
            functionPrev={() => setStepForm(1)}
            functionSave={() => saveTech()}
            functionCancel={() => setStepForm(1)}
            enableNext={true}
            enablePrev={true}
          />
        </Form>
      )}
    </>
  );
}
