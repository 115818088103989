import { useContext, useEffect, useState } from "react";
import { ShimmerCard } from "../../../../../Components/Shimmers";
import { ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import { useListBNCC } from "../../hooks/useListBNCC";
import {
  EnumColumnSelected,
  hasCurricularComponentNoFieldAction,
} from "../Subforms/FormElementarySchool";
import { ListItem, RowItem } from "../Subforms/styles";
import { IBNCCFieldAction, IBNCCThematicUnit } from "../../../../../types/bncc";
import { useListEF } from "../../hooks/bncc/useListEF";

interface ListProps {
  columnSelected: EnumColumnSelected | null;
  curricularSelected: string | null;
}

export function getCodKnowledge(materia: string) {
  if (
    materia === "Língua Portuguesa" ||
    materia === "Arte" ||
    materia === "Educação Física" ||
    materia === "Língua Inglesa"
  )
    return 3;
  if (materia === "Matemática") return 4;
  if (materia === "Ciências") return 0;
  if (materia === "Geografia" || materia === "História") return 2;
  if (materia === "Ensino Religioso") return 1;
  return 0;
}
export function getCodComponentCurricular(materia: string) {
  if (
    materia === "Matemática" ||
    materia === "Língua Portuguesa" ||
    materia === "Ciências" ||
    materia === "Geografia" ||
    materia === "Ensino Religioso"
  )
    return 0;
  if (materia === "História" || materia === "Arte") return 1;
  if (materia === "Educação Física") return 2;
  if (materia === "Língua Inglesa") return 3;

  return 0;
}

function LoadingBox() {
  const unit = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div>
      {unit.map((item) => (
        <ShimmerCard key={item} $height="25px" />
      ))}
    </div>
  );
}

export function ListItens({ columnSelected, curricularSelected }: ListProps) {
  const { resource, addFieldBNCCEfResource, removeFieldBNCCEfResource } =
    useContext(ResourceContext);
  const { bncc } = resource;
  const { data, isSuccess } = useListEF();

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState<string[] | undefined>([]);

  console.log(dataList)

  const areaConhecimento = getCodKnowledge(curricularSelected ?? "");
  const componenteCurricular = getCodComponentCurricular(
    curricularSelected ?? ""
  );

  useEffect(() => {
    setIsLoading(true);
    //Calcular a listagem de itens se já existir dados na query
    if (isSuccess && data) {
      if (columnSelected === EnumColumnSelected.CAMPOS_ESPECIFICOS) {
        setDataList(
          data.knowledge_areas[areaConhecimento].curricular_components[
            componenteCurricular
          ].
          fields_action?.map((item: any)=> item.name)
          // thematic_units.map((item: any) => item.name)
        );
      }
      //Calculo se for um objeto de conhecimento
      else if (columnSelected === EnumColumnSelected.OBJETO_CONHECIMENTO) {
        //Verifica se ja foi escolhida alguma unidade tematica para filtragem
        const noEmptyThematicUnits = data.knowledge_areas[
          areaConhecimento
        ].curricular_components[
          componenteCurricular
        ].thematic_units?.some((item: any) =>
          bncc.ef.thematic_units.some((obj) => obj === item.name)
        );

        //Se nenhum unidade tematica foi escolhida, retorna todos os objetos de conhecimento
        if (!noEmptyThematicUnits) {
          setDataList(
            data.knowledge_areas[
              areaConhecimento
            ].curricular_components[
              componenteCurricular
            ].thematic_units.reduce((acc: string[], item: IBNCCThematicUnit) => {
              const itensFilteres = item.knowledge_objects.filter(
                (item: any) => !acc.some((obj) => obj === item)
              );

              return [...acc, ...itensFilteres];
            }, [])
          );
        }
        //Senão retorna apenas os objetos de conhecimento que pertencem as unidades temática escolhida
        else {
          setDataList(
            data.knowledge_areas[
              areaConhecimento
            ].curricular_components[
              componenteCurricular
            ].thematic_units.reduce((acc: string[], item: any) => {
              if (
                bncc.ef.thematic_units.some((obj: any) => obj === item.name)
              ) {
                const itensFilteres = item.knowledge_objects.filter(
                  (item: any) => !acc.some((obj) => obj === item)
                );

                return [...acc, ...itensFilteres];
              }

              return acc;
            }, [])
          );
        }
      } else {
        if (hasCurricularComponentNoFieldAction(curricularSelected ?? "")) {

          setDataList(
            data.knowledge_areas[
              areaConhecimento
            ].curricular_components[
              componenteCurricular
            ].
            // fields_action?.map((item: any)=> item.name)
            thematic_units.map((item: any) => item.name)
          );
        } else {
          const noEmptyFieldsSelected = data.knowledge_areas[
            areaConhecimento
          ].curricular_components[componenteCurricular].fields_action?.
          reduce((acc: IBNCCThematicUnit[], item) => [...acc, ...item.thematic_units], []).some(
            (item: any) => bncc.ef.fields_action.some((obj) => obj === item.name)
          );

          if (noEmptyFieldsSelected) {
            setDataList(
              data.knowledge_areas[
                areaConhecimento
              ].curricular_components[componenteCurricular].thematic_units
                .reduce((acc: IBNCCThematicUnit[], value: any) => {
                  if (
                    bncc.ef.fields_action.some(
                      (select) => select === value.name
                    )
                  ) {
                    return [
                      ...acc,
                      ...value.thematicUnits.filter(
                        (item: any) => !acc.some((accI) => accI.name === item.name)
                      ),
                    ];
                  }
                  return acc;
                }, [])
                .map((value: any) => value.name)
            );
          } else {
            setDataList(
              data.knowledge_areas[
                areaConhecimento
              ].curricular_components[componenteCurricular].fields_action?.
              reduce((acc: IBNCCThematicUnit[], value: IBNCCFieldAction) => {
                  return [
                    ...acc,
                    ...value.thematic_units.filter(
                      (item: any) => !acc.some((accI) => accI.name === item.name)
                    ),
                  ];
                }, [])
                .map((value: any) => value.name)
            );
          }
        }
      }
    }
    setIsLoading(false);
  }, []);

  const fieldHandle =
    columnSelected === EnumColumnSelected.OBJETO_CONHECIMENTO
      ? "knowledge_objects"
      : columnSelected === EnumColumnSelected.UNIDADE_TEMATICA
      ? "thematic_units"
      : "fields_action";

  if (!columnSelected || !curricularSelected) return null;

  return (
    <ListItem>
      {isLoading ? (
        <LoadingBox />
      ) : (
        isSuccess &&
        data &&
        dataList?.map((item, index) => {
          const selected =
            columnSelected === EnumColumnSelected.OBJETO_CONHECIMENTO
              ? bncc.ef.knowledge_objects.some((obj: any) => obj === item)
              : columnSelected === EnumColumnSelected.UNIDADE_TEMATICA
              ? bncc.ef.thematic_units.some((obj: any) => obj === item)
              : bncc.ef.fields_action.some((obj: any) => obj === item);

          function handleItem() {
            console.log("Chegou")
            if (selected) {
              removeFieldBNCCEfResource(item, fieldHandle);
            } else {
              addFieldBNCCEfResource(item, fieldHandle);
            }
          }
          const classname = selected ? "select" : "";
          return (
            <RowItem
              onClick={handleItem}
              key={`${item}_${index}`}
              className={classname}
            >
              {item}
            </RowItem>
          );
        })
      )}
    </ListItem>
  );
}
