import React, { useEffect, useState } from 'react'
import { Divider } from '../../../../Components/Form/Divider'
import { Wizard, WizardStep } from '../../../../Components/Wizard'
import { Form } from '../../ClientRegistration/styles'
import { Input } from '../../../../Components/Form/Input'
import { DivFlex } from '../FormStudent/styles'
import EspecialNeedComponent from './EspecialNeedComponent/EspecialNeedComponent'
import { Line } from '../../ResourcesRegistration/styles'
import ComponentResponsible from '../FormStudent/ComponentResponsible/ComponentResponsible'
import Select from 'react-select';
import EtapaAcademicaService from '../../../../Services/EtapaAcademica.service'
import { Class, Student } from '../../../../Utils/Types'
import TurmaService from '../../../../Services/Turma.service'
import { Controllers } from '../../../../Components/Form/Controllers'
import { useNavigate } from 'react-router-dom'
import ReviewStudent from './ReviewStudent/ReviewStudent'
import ReactSelect from '../../../../Components/Form/ReactSelect/ReactSelect'
import StudentImport from './StudentImport'
import { useListBNCCYears } from '../../../../hooks/ListClass/useListYears'
import { useListStage } from '../../../../hooks/ListClass/useListStage'
import { useQueryClient } from 'react-query'
import { Queries } from '../../../../types/queries'
import { ClassStudentView, StageStudentView } from '../../../../types/StudentsTypes/typeStudents'
import StudentService from '../../../../Services/Student.service'

export type SpecialNeedType = {
    identifier: string,
    description: string
}

export type StudentData = {
    name: string
    enrollment: string,
    birth_date: string | null
    email?: string
    cpf?: string,
    phone?: string,
    gender?: string,
    special_need_id?: string[] | undefined
    other_special_needs?: string
    equivalent_age?: number
    education_stage_id: string
}

export type ObjectView = {
    identifierStage: string
    nameStage: string
    identifierClass: string,
    nameClass: string
}


const NewFormStudent = () => {

    const navigate = useNavigate()

    const [switchForm, setSwitchForm] = useState(1)
    //dados do estudante
    const [name, setName] = useState("")
    const [enrollment, setEnrollment] = useState("")
    const [cpf, setCpf] = useState("")
    const [date, setDate] = useState("")
    const [objectView, setObjectView] = useState<ObjectView>({ identifierClass: "", identifierStage: "", nameClass: "", nameStage: "" })
    const [otherNeeds, setOtherNeeds] = useState("")
    const [equivalentAge, setEquivalentAge] = useState<number>(0)
    const [idStudent, setIdStudent] = useState("")

    const { data: listAcademicYears, isLoading: loadingAcademicYears } = useListBNCCYears();

    const [specialNeeds, setSpecialNeeds] = useState<string[]>([])


    //dados de requisição
    const unit = JSON.parse(sessionStorage.getItem('locationData')!)
    const [classes, setClasses] = useState<Class[]>([])



    //dados do responável
    const [nameResponsible, setNameResponsible] = useState<string>("")
    const [phoneResponsible, setPhoneResponsible] = useState<string>("")
    const [emailResponsible, setEmailResponsible] = useState<string>("")
    const [confirmEmailResponsible, setConfirmEmailResponsible] = useState<string>("")


    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);

    const queryClient = useQueryClient();


    const handleChange1 = () => {
        setCheck1(true)
        if (check1 === true) {
            setCheck1(false)
        }
        setCheck2(false)
    }
    const handleChange2 = () => {
        setCheck1(false)
        setCheck2(true)
        if (check2 === true) {
            setCheck2(false)
        }
    }



    useEffect(() => {
        // EtapaAcademicaService.get().then((response) => {
        //     if (response?.success) {
        //         setStages(response.data)
        //     }
        // })

        TurmaService.list({ idState: unit.identifier }).then((response) => {
            if (response?.success) {
                setClasses(response.data)
            }
        })
        queryClient.invalidateQueries(Queries.LIST_BNCC_YEARS)


    }, [])



    const classesOrdened = classes.slice().sort((a, b) => a.name.localeCompare(b.name))

    function formatarData(input: any) {
        // Verifica se a string de entrada possui o formato esperado
        if (input.length !== 8 || isNaN(input)) {
            return null;
        }

        // Extrai os componentes da data (dia, mês, ano)
        var dia = input.substring(0, 2);
        var mes = input.substring(2, 4);
        var ano = input.substring(4, 8);

        // Cria a string formatada no novo formato
        var dataFormatada = ano + "/" + mes + "/" + dia;

        return dataFormatada;
    }
    const dateForVerification = formatarData(date)

    function returnAgeStudent(input: any) {
        const age = new Date(new Date().getTime() - new Date(input).getTime()).getUTCFullYear() - 1970

        return age;

    }

    const age = returnAgeStudent(dateForVerification)



    function handleStageId(e: any) {
        let stage: StageStudentView = e;
        console.log(stage)
        setObjectView({ ...objectView, identifierStage: stage.identifier, nameStage: stage.year })

    }

    function handleIdClassRoom(e: any) {
        let classroomStudent: ClassStudentView = e
        console.log(classroomStudent)
        setObjectView({ ...objectView, identifierClass: classroomStudent.identifier, nameClass: classroomStudent.name })
    }




    const dataStudent = {
        enrollment: enrollment,
        cpf: cpf,
        birthDate: dateForVerification,
        name: name,
        year_id: objectView.identifierStage,
        birth_date: dateForVerification,
        other_special_needs: otherNeeds,
        special_need_id: ["6b654f9e-2ea9-438d-8a02-6dfeeb4a4561"]
    }

    function save() {
        StudentService.post({ data: dataStudent, idUnit: unit.identifier }).then((res) => {
            if (res.success) {
                setSwitchForm(2)
                console.log(res.data)
                setIdStudent(res.data.id)
            }
        })
    }

    function deleteStudent() {
        if(idStudent === ""){
            navigate("/unidade/usuario/list")
        }else{
            StudentService.delete({ educationInstitution: unit.identifier, student: idStudent }).then((res) => {
                navigate("/unidade/usuario/list")
            })
        }
    }

    function verification() {
        console.log(dataStudent)
        if (dataStudent.name === "") {
            alert("Preencha o nome.")
        } else if (dataStudent.birthDate === null) {
            alert("Preencha a data de nascimento.")
        } else if (objectView.identifierStage === "") {
            alert("Selecione uma etapa.")
        } else if (dataStudent.cpf === "") {
            alert("Preencha o cpf.")
        } else if (check1 === false && check2 === false) {
            alert("Diga se possui uma necessidade especial.")
        } else if (objectView.identifierClass === "") {
            alert("Selecione uma turma.")
        } else {
            // save()
            setSwitchForm(2)
        }
    }




    return (
        <>
            <Divider>
                <Wizard>
                    <WizardStep text="Estudante" active={switchForm === 1 ? true : false} />
                    <WizardStep text="Revisar" active={switchForm === 2 ? true : false} />
                </Wizard>
            </Divider>
            {switchForm === 1 && (

                <Form onSubmit={() => { }}>
                    <Divider>
                        <Input
                            type="text"
                            label="Nome"
                            flex="fx100"
                            value={name}
                            onChange={setName}
                            required
                        />
                    </Divider>
                    <Divider>
                        <Input
                            type="cpf"
                            label="CPF"
                            flex="fx33"
                            value={cpf}
                            onChange={setCpf}
                            required
                        />
                        <Input
                            type="date"
                            label="Data de nascimento"
                            flex="fx33"
                            value={date}
                            onChange={setDate}
                            required
                        />
                        <Input
                            type="text"
                            label="Matrícula"
                            flex="fx33"
                            value={enrollment}
                            onChange={setEnrollment}
                            required
                        />
                    </Divider>
                    <Divider>
                        <DivFlex>
                            <p>Possui alguma necessidade especial?</p>
                            <input
                                type="checkbox"
                                checked={check1}
                                onChange={handleChange1}
                                className='checks'
                            />
                            <label>Sim</label>
                            <input
                                type="checkbox"
                                checked={check2}
                                onChange={handleChange2}
                                className='checks'
                            />
                            <label>Não</label>
                        </DivFlex>
                    </Divider>
                    {check1
                        ?
                        <EspecialNeedComponent
                            // hearingImpairment={hearingImpairment}
                            setSpecialNeeds={setSpecialNeeds}
                            // visualImpairment={visualImpairment}
                            specialNeeds={specialNeeds}
                            otherNeeds={otherNeeds}
                            setOtherNeeds={setOtherNeeds}
                            equivalentAge={equivalentAge}
                            setEquivalentAge={setEquivalentAge}
                        />
                        :
                        <></>
                    }
                    <Divider>
                        <ReactSelect
                            flex='fx50'
                            options={listAcademicYears}
                            getOptionLabel={(e: any) => `${e.year} ${e.identifier.substring(0, 2) === "EM" ? "(Ensino Médio)" : " "}`}
                            label='Ano'
                            noOptionsMessage='Não encontrado'
                            placeholder='Selecione um ano...'
                            onChange={(e: any) => handleStageId(e)}
                            isLoading={loadingAcademicYears}
                            required={true}
                        />
                        <ReactSelect
                            flex='fx50'
                            options={classesOrdened}
                            placeholder='Selecione uma turma...'
                            label='Turmas'
                            noOptionsMessage='Não há turmas cadastradas'
                            getOptionLabel={(e: any) => e.name}
                            onChange={(e) => handleIdClassRoom(e)}
                            required={true}
                        />
                    </Divider>
                    <ComponentResponsible
                        idade={age}
                        confirmEmail={confirmEmailResponsible}
                        email={emailResponsible}
                        name={nameResponsible}
                        phone={phoneResponsible}
                        setConfirmEmail={setConfirmEmailResponsible}
                        setEmail={setEmailResponsible}
                        setName={setNameResponsible}
                        setPhone={setPhoneResponsible}
                    />
                    <StudentImport
                        unitId={unit.identifier}
                    />
                    <br />
                    <Controllers
                        enableNext={true}
                        enablePrev={false}
                        functionCancel={() => navigate("/unidade/usuario/list")}
                        functionNext={() => verification()}
                    />

                </Form>

            )}
            {switchForm === 2 && (
                <ReviewStudent
                    setSwitchForm={setSwitchForm}
                    data={dataStudent}
                    unit={unit}
                    idClassRoom={objectView.identifierClass}
                    idStudent={idStudent}
                    object={objectView}
                />
            )}
        </>
    )
}

export default NewFormStudent