import React, { useContext } from 'react'
import { ResourceContext } from '../../../../../../Contexts/Form/ContextResource'

const ReviewEI = () => {

    const { resource } = useContext(ResourceContext)
    const { bncc } = resource

    return (
        <div>
            <tr>
                <td>
                    <span>EDUCAÇÃO INFANTIL</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>
                        Anos: {" "} {bncc.ei.years.length > 0 ? bncc.ei.years.join(", ") : "Não informado."}
                    </span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Direitos de aprendizagem: {" "}{bncc.ei.learning_rights.length > 0 ? bncc.ei.learning_rights.join(", ") : "Não informado."}</span>
                </td>
            </tr>
            <tr>
                <td>
                    <span>Habilidades:</span>
                </td>
            </tr>
            {bncc.ei.skills.length > 0 ?
                bncc.ei.skills.map((skill) => {
                    return (
                        <tr><td><span>- {skill}</span></td></tr>
                    )
                })
                : "Não informado."
            }
            <tr>
                <td>
                    <span>Campos de experiência:</span>
                </td>
            </tr>
            {bncc.ei.fields_experience.length > 0 ?
                bncc.ei.fields_experience.map((field) => {
                    return (
                        <tr><td><span>- {field}</span></td></tr>
                    )
                })
                : "Não informado."
            }
        </div>
    )
}

export default ReviewEI