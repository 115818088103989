import {
  BrowserRouter,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { useAuth } from "./Contexts/store";
import { Login } from "./Pages/Login";
import { Home } from "./Pages/Home";
import { ErrorAuth } from "./Pages/Redirects/ErrorAuth";
import { RecoverPass } from "./Pages/RecoverPass";
import ListEmployees from "./Pages/List/ListEmployees";
import { EmployeeRegistration } from "./Pages/Registration/EmployeeRegistragion";
import { ListTech } from "./Pages/List/ListTech";
import { TechRegistration } from "./Pages/Registration/TechRegistration";
import { ListClient } from "./Pages/List/ListClient";
import { ClientRegistration } from "./Pages/Registration/ClientRegistration";
import { ListUnit } from "./Pages/List/ListUnit";
import { UnitRegistration } from "./Pages/Registration/UnitRegistration";
import { ListUser } from "./Pages/List/ListUser";
import { UserRegistration } from "./Pages/Registration/UserRegistration";
import TagRegistration from "./Pages/Registration/TagRegistration";
import { ListClass } from "./Pages/List/ListClass";
import { ClassRegistration } from "./Pages/Registration/ClassRegistration";
import { StudentTransfer } from "./Pages/Transfer/StudentTransfer";
import { ClassTransfer } from "./Pages/Transfer/ClassTransfer";
import { RequestTransfer } from "./Pages/Transfer/RequestTransfer";
import { ListCollections } from "./Pages/List/ListCollections";
import CollectionRegistration from "./Pages/Registration/CollectionRegistration";
import { ListPackages } from "./Pages/List/ListPackages";
import { ListResource } from "./Pages/List/ListResource";
import ResourcesRegistration from "./Pages/Registration/ResourcesRegistration";
import { ReportClient } from "./Pages/Reports/ReportClient";
import ReportUsers from "./Pages/Reports/ReportUsers";
import { UsersProfiles } from "./Pages/UsersProfiles";



export function DefaultRoutes() {
  const [auth] = useAuth();

  const routes = [
    { path: "/", element: auth === false ? <Login /> : <Home /> },
    { path: "/login", element: <Login /> },
    {
      path: "/recuperar-senha",
      element: auth === false ? <ErrorAuth /> : <RecoverPass />,
    },

    {
      path: "/employees/listEmployees",
      element: auth === false ? <ErrorAuth /> : <ListEmployees />,
    },
    {
      path: "/employees/registration",
      element: auth === false ? <ErrorAuth /> : <EmployeeRegistration />,
    },

    {
      path: "/tech/list",
      element: auth === false ? <ErrorAuth /> : <ListTech />,
    },
    {
      path: "/tech/registration",
      element: auth === false ? <ErrorAuth /> : <TechRegistration />,
    },

    {
      path: "/clientes/list",
      element: auth === false ? <ErrorAuth /> : <ListClient />,
    },
    {
      path: "/clientes/registration",
      element: auth === false ? <ErrorAuth /> : <ClientRegistration />,
    },

    {
      path: "/unidade/list",
      element: auth === false ? <ErrorAuth /> : <ListUnit />,
    },
    {
      path: "/unidade/registration",
      element: auth === false ? <ErrorAuth /> : <UnitRegistration />,
    },
    {
      path: "/unidade/edit",
      element: auth === false ? <ErrorAuth /> : <UnitRegistration />,
    },

    {
      path: "unidade/usuario/list",
      element: auth === false ? <ErrorAuth /> : <ListUser />,
    },
    {
      path: "unidade/usuario/registration",
      element: auth === false ? <ErrorAuth /> : <UserRegistration />,
    },
    {
      path: "unidade/usuario/edit",
      element: auth === false ? <ErrorAuth /> : <UserRegistration />,
    },

    {
      path: "/tags/registration",
      element: auth === false ? <ErrorAuth /> : <TagRegistration />,
    },

    {
      path: "/turma/list",
      element: auth === false ? <ErrorAuth /> : <ListClass />,
    },
    {
      path: "/turma/registration",
      element:
        auth === false ? <ErrorAuth /> : <ClassRegistration edit={false} />,
    },
    {
      path: "/turma/edit",
      element:
        auth === false ? <ErrorAuth /> : <ClassRegistration edit={true} />,
    },

    {
      path: "/transferencia/estudante",
      element: auth === false ? <ErrorAuth /> : <StudentTransfer />,
    },
    {
      path: "/transferencia/turma",
      element: auth === false ? <ErrorAuth /> : <ClassTransfer />,
    },
    {
      path: "/transferencia/solicitacoes",
      element: auth === false ? <ErrorAuth /> : <RequestTransfer />,
    },

    {
      path: "/collection/list",
      element: auth === false ? <ErrorAuth /> : <ListCollections />,
    },
    {
      path: "/collection/registration",
      element: auth === false ? <ErrorAuth /> : <CollectionRegistration />,
    },

    {
      path: "/pacotes",
      element: auth === false ? <ErrorAuth /> : <ListPackages />,
    },

    {
      path: "/recursos/list",
      element: auth === false ? <ErrorAuth /> : <ListResource />,
    },
    {
      path: "/recursos/registration",
      element: auth === false ? <ErrorAuth /> : <ResourcesRegistration />,
    },
    {
      path: "/recursos/edit",
      element: auth === false ? <ErrorAuth /> : <ResourcesRegistration />,
    },
    {
      path: "report/client",
      element: auth === false ? <ErrorAuth /> : <ReportClient />,
    },
    {
      path: "report/users",
      element: auth === false ? <ErrorAuth /> :
          <ReportUsers />
      ,
    },
    {
      path: "/users/profiles",
      element: auth === false ? <ErrorAuth /> :
          <UsersProfiles />
    }
  ];
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}
