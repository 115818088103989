import { type } from "os"
import { OptionSelect } from "../Pages/Registration/EmployeeRegistragion"

export enum RolesId {
  MASTER = "36304fd4-192b-429b-9eec-faa8dad5b9ae"
}


export enum EnumEmployees {
  MANAGER = "1",
  MANAGER_EMPLOYEE = "2",
  DIRECTOR = "3",
  COORD = "4",
  ADMIN_EMPLOYEE = "5",
  TEACHER = "6",
  STUDENT = "7",
  GUARDIAN = "8",
  EMPLOYEE = "9"
}

export enum TypeRoles {
  EMPLOYEE = "employee",
  TECHICIAN = "technician",
  MANAGER = "manager"
}

export interface UnitSelected {
  unit: any,
  classrooms: Class[]
}

export enum EnumEmployeesName {
  DIRECTOR = "director-employee",
  COORD = "coordination-employee",
  TEACHER = "teacher-employee",
  TECH = "basic-technician",
  MANAGER_EMPLOYEE = "management-employee",
  MANAGER = "client-manager",
  ADMIN_EMPLOYEE = "administrative-employee",
  GUARDIAN = "responsible",
}

export enum EnumRoles {
  MASTER = "Master",
}

export type Role = {
  identifier?: string,
  description?: string,
  name?: string,
  slug?: string,
  userType?: string
}

export type UserEmployee = {
  name?: string,
  login?: string,
  phone?: string,
  email?: string,
  cpf?: string,
  birth_date?: string,
  active?: boolean,
  roles?: Role[]
}
export type UserEmployee2 = {
  name?: string,
  login?: string,
  phone?: string,
  email?: string,
  cpf?: string,
  birth_date?: string,
  active?: boolean,
  role_id?: string
  slug?: string
}

export type UnitEmployee = {
  identifier?: string,
  name?: string,
  classrooms?: any[]
}

export type Employee = {
  identifier: string,
  user: UserEmployee,
  educationInstitution: UnitEmployee
}

export type NewEmployee = {
  idUnit?: string
  name: string;
  email: string;
  birth_date: string | Date;
  cpf: string;
  phone: string;
  address?: TypeAddress;
  role_id: string[] | string;
}

export type Class = {
  identifier?: any
  name: any
  academic_year: number | null
  initials: string
  modality: string
  phase_id: string
  academic_stage_id: string
  age_range_id: string
}


export type Unit = {
  identifier?: any,
  name: any,
  address: string,
  number: number,
  complement: string,
  neighborhood: string
  zip_code: string
  city: string
  state: string
  phone: string
  email: string
}

export type TagTab = {
  category: string,
  name: string
}

export type Tag = {
  id?: string;
  name: string;
  justification?: string;
  category?: string,
  idCategory?: string

}

export type Client = {
  identifier?: string,
  socialName: string,
  cnpj: string,
  phone: string,
  email: string,
  logo: Blob,
  active: boolean,
  address?: Address,
}

export type TypeAddress = {
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  zip_code: string;
  city: string;
  state: string;
};

//Tipos para unidade de ensino
export type TypeSchool = {
  address: TypeAddress;
  email: string;
  identifier: string;
  name: string;
  phone: string;
};

//Create Teacher
export type TypeTech = {
  identifier: string;
  company?: string;
  role: string;
  responsible_name?: string;
  user: {
    name: string;
    email: string;
    phone: string;
    cpf?: string;
    birth_date: Date;
    active: boolean;
    address?: TypeAddress;
    role: any[];
  };
};

export type EmployeeEdit = {
  name: string;
  email: string;
  birth_date: Date | string;
  cpf: string;
  phone: string;
  address?: TypeAddress;
  role_id: string;
}

//Create Teacher
export type TypeTeacher = {
  name: string;
  email: string;
  birth_date: Date | string;
  cpf: string;
  phone: string;
  address?: TypeAddress;
  role_id?: string;
  slug?: string
};

//Create responsible
export type Guardian = {
  identifier?: string;
  students: string[];
  name: string;
  email: string;
  cpf: string;
  birth_date: string;
  phone: string;
  address?: Address;
};
type Address = {
  address: string;
  number: string;
  complement: string;
  neighborhood: string;
  zip_code: string;
  city: string;
  state: string;
};

//Create Student

export type Student = {
  identifier?: string;
  enrollment?: string;
  name?: string;
  email?: string;
  birthDate?: string | Date | null | React.ReactNode;
  cpf?: string;
  phone?: string;
  gender?: string;
  special_need_id?: string[] | any; 
  other_special_needs?: string | null | undefined; 
  equivalent_age?: number | null | undefined;
  education_stage_id?: string;
  birth_date?: string | null | undefined
  educationStage?: EducationStage
};


export type AcademicYear = {
  identifier: string;
  year: string;
  actived: boolean;
};

export type AgeRange = {
  description: string;
  identifier: string;
  name?: string
  year?: string
};

export type EducationStage = {
  description: string;
  identifier: string;
  name: string;
};

export type TypeClass = {
  academicYear: AcademicYear;
  ageRange: AgeRange;
  description: string;
  identifier: string;
  educationStage: EducationStage;
  name: string;
  initials: string | null;
  modality: string[] ;
  phase: any;
};

export type TypeAcademicYear = {
  identifier: string;
  year: string;
  actived: boolean;
};

export type TypeEducationStages = {
  identifier: string;
  name: string;
  description: string;
};

export type TypePhase = {
  identifier: string;
  description: string;
};

export type TypeAgeRange = {
  identifier: string;
  description: string;
};

export type Package = {
  identifier?: string,
  name: string
  description: string,
  color: string,
  category?: string
}

export type TagListed = {
  identifier: string,
  name: string,
  created_at: string,
  updated_at: string
}

export type tagEdit = {
  name: string,
  justification?: string,
}

export type CategoryEdit = {
  name: string,
  description: string | null,
}


