import { useContext, useEffect } from "react";
import { Select } from "../../../../../Components/Form/Select";
import { Label } from "../../../../../Components/Form/Select/styles";
import { ResourceContext } from "../../../../../Contexts/Form/ContextResource";
import { useListResource } from "../../hooks/useListResource";
import { FormContainer, Line, ListItensSelect, Row } from "../../styles";
import { ResourceForm1Context } from "../../../../../Contexts/Form/ContextResouceForm1";
import SelectWithSearch from "../../../../../Components/Form/SelectWithSearch/SelectWithSearch";

export function FormSimilarity() {
  const { data: listResource, isLoading, isError } = useListResource();
  const { resource } = useContext(ResourceContext);
  const {
    resourceEquivalent,
    resourceOrigin,
    setResourceOriginForm,
    setResourceEquivalentForm,
  } = useContext(ResourceForm1Context);

  function addResourceEquivalent(id: string) {
    const resourceSelected = listResource?.find(
      (item) => item.identifier === id
    );
    if (resourceSelected) {
      setResourceEquivalentForm([
        ...resourceEquivalent,
        {
          value: resourceSelected.identifier,
          name: resourceSelected.name,
        },
      ]);
    }
  }

  function removeResourceEquivalent(id: string) {
    const resourceSelected = resourceEquivalent.filter(
      (item) => item.value !== id
    );
    setResourceEquivalentForm(resourceSelected);
  }

  function handleResourceOrigin(id: string) {
    const resourceSelected = listResource?.find(
      (item) => item.identifier === id
    );
    if (resourceSelected) {
      setResourceOriginForm({
        value: resourceSelected.identifier,
        name: resourceSelected.name,
      });
    }
  }

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
  }

  useEffect(() => {
    if (resource.origin_resource) {
      const resourceOrigin = listResource?.find(
        (item) => item.identifier === resource.origin_resource
      );
      setResourceOriginForm({
        value: resourceOrigin!.identifier,
        name: resourceOrigin!.name,
      });
    }
    if (resource.equivalent_resources) {
      const list = resource.equivalent_resources.map((item) => {
        const resourceEquivalent = listResource?.find(
          (resource) => resource.identifier === item
        );
        return {
          value: resourceEquivalent!.identifier,
          name: resourceEquivalent!.name,
        };
      });
      setResourceEquivalentForm(list);
    }
  }, []);


  const filteredResources = listResource?.filter((resource) => !resourceEquivalent.some((selected) => selected.value === resource.identifier))

  return (
    <FormContainer onSubmit={onSubmit} id="formSimiliraty">
      <Row $align="flex-start">
        <div className="item">

          {/* <SelectWithSearch
            options={filteredResources}
            onChange={addResourceEquivalent}
            value={resource.equivalent_resources}
            flex="fx100"
            label="Recursos Equivalentes"
            inputId="resourceEquivalent"
            place="Selecione os recursos"
            noOptionMessage="Não há recursos disponíveis"
          /> */}
          <Select
            value={""}
            onChange={addResourceEquivalent}
            flex="fx100"
            label="Recursos Equivalentes"
            inputId="resourceEquivalent"
          >
            {isLoading ? (
              <option value="">Carregando ...</option>
            ) : isError ? (
              <option value="">Erro ao carregar</option>
            ) : (
              <>
                <option value="">Selecione os recursos</option>
                {isError
                  ? null
                  : listResource
                      ?.filter(
                        (resource) =>
                          !resourceEquivalent.some(
                            (selected) => selected.value === resource.identifier
                          )
                      )
                      .map((resource: any) => (
                        <option
                          key={resource.identifier}
                          value={resource.identifier}
                        >
                          {resource.name}
                        </option>
                      ))}
              </>
            )}
          </Select>
        </div>

        <div className="item no-gap">
          <Label>Lista Recursos Equivalentes Selecionados</Label>
          <ListItensSelect>
            {resourceEquivalent.length ? (
              resourceEquivalent.map((item) => (
                <li key={item.value}>
                  <span>{item.name}</span>
                  <button
                    type="button"
                    onClick={() => removeResourceEquivalent(item.value)}
                  >
                    Remover
                  </button>
                </li>
              ))
            ) : (
              <li></li>
            )}
          </ListItensSelect>
        </div>
      </Row>

      <Line />
      {/* <SelectWithSearch
        options={filteredResources}
        onChange={handleResourceOrigin}
        value={resourceOrigin?.value}
        flex="fx80"
        label="Recursos de origem"
        inputId="resourceEquivalent"
        place="Selecione os recursos" 
        noOptionMessage="Não há recursos disponíveis"
      /> */}
      <Select
        value={resourceOrigin?.value}
        onChange={handleResourceOrigin}
        flex="fx80"
        label="Recursos de Origem"
        inputId="resourceOrigin"
      >
        {isLoading ? (
          <option value="">Carregando ...</option>
        ) : isError ? (
          <option value="">Erro ao carregar</option>
        ) : (
          <>
            <option value="">Selecione um recurso</option>
            {isError
              ? null
              : listResource?.map((resource: any) => (
                <option key={resource.identifier} value={resource.identifier}>
                  {resource.name}
                </option>
              ))}
          </>
        )}
      </Select>
    </FormContainer>
  );
}
