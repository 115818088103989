import { useEffect, useState } from "react";
import { SuperBox } from "../../../Components/SuperBox";
import { Form1 } from "./Form1";
import { Form2 } from "./Form2";
import {
  Container,
  Main,
  Sitemap,
  Abas,
  Form,
  ClientName,
  Radio,
  MoreStudent,
} from "./styles";
import { useLocation } from "react-router-dom";

export function UnitRegistration() {
  const [switchForm, setSwitchForm] = useState(1);
  const [modalSuccess, setModalSuccess] = useState(false);
  const [nameValue, setNameValue] = useState("");
  const [telValue, setTelValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [cepValue, setCepValue] = useState("");
  const [bairroValue, setBairroValue] = useState("");
  const [complementoValue, setComplementoValue] = useState("");
  const [cidadeValue, setCidadeValue] = useState("");
  const [ruaValue, setRuaValue] = useState("");
  const [ufValue, setUfValue] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [unidadeList, setUnidadeList] = useState<any[]>([]);
  const location = useLocation();
  const edit = location.state;
  const title = edit ? "Edição" : "Cadastro";

  useEffect(() => {
    if (edit) {
      setNameValue(edit.name ?? "Não informado");
      setTelValue(edit.phone ?? "Não informado");
      setEmailValue(edit.email ?? "Não informado");
      setCepValue(edit.address?.zipCode ?? "00000000");
      setBairroValue(edit.address?.neighborhood ?? "Não informado");
      setComplementoValue(edit.address?.complement ?? "Não informado");
      setCidadeValue(edit.address?.city ?? "Não informado");
      setRuaValue(edit.address?.address ?? "Não informado");
      setUfValue(edit.address?.state ?? "Não informado");
      setNumberValue(edit.address?.number ?? "000");
    }
  }, []);
  return (
    <SuperBox
      switch={true}
      page={2}
      path={`${title} \u2022 Unidade Educacional`}
    >
      <Container>
        <div className="box">
          <Main>
            <ClientName>
              {localStorage.getItem("client")
                ? JSON.parse(localStorage.getItem("client") || "").socialName
                : ""}
            </ClientName>
            {switchForm === 1 && (
              <Form1
                edit={edit}
                next={() => setSwitchForm(2)}
                nameValue={nameValue}
                setNameValue={setNameValue}
                telValue={telValue}
                setTelValue={setTelValue}
                emailValue={emailValue}
                setEmailValue={setEmailValue}
                cepValue={cepValue}
                setCepValue={setCepValue}
                bairroValue={bairroValue}
                setBairroValue={setBairroValue}
                complementoValue={complementoValue}
                setComplementoValue={setComplementoValue}
                cidadeValue={cidadeValue}
                setCidadeValue={setCidadeValue}
                ruaValue={ruaValue}
                setRuaValue={setRuaValue}
                ufValue={ufValue}
                setUfValue={setUfValue}
                numberValue={numberValue}
                setNumberValue={setNumberValue}
                unidadeList={unidadeList}
                setUnidadeList={setUnidadeList}
              />
            )}
            {switchForm === 2 && (
              <Form2
                edit={edit}
                next={() => setSwitchForm(2)}
                prev={() => setSwitchForm(1)}
                unidadeList={unidadeList}
                setUnidadeList={setUnidadeList}
                nameValue={nameValue}
                telValue={telValue}
                emailValue={emailValue}
                cepValue={cepValue}
                bairroValue={bairroValue}
                complementoValue={complementoValue}
                cidadeValue={cidadeValue}
                ruaValue={ruaValue}
                ufValue={ufValue}
                numberValue={numberValue}
              />
            )}
          </Main>
        </div>
      </Container>
    </SuperBox>
  );
}
