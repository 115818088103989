import styled from 'styled-components';

export const Container = styled.section`
display: flex;

>div.box{
width: 100%;
padding: 5px 30px;
}
`;

export const Sitemap = styled.div`
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #646464;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(0,0,0,0.5);
`;

export const Main = styled.main`
    width: 100%;
    margin: 0 auto;

    .tooltip {
        position: relative;
        display: inline-block;
    }

    .tooltip::after {
        content: attr(title);
        text-align: justify;
        position: absolute;
        visibility: hidden;
        opacity: 0;
        background-color: #F8F8FA;
        color: #646464;
        padding: 5px;
        border-radius: 5px;
        border: solid 1px;
        bottom: 125%;
        left: 50%;
        transform: translateX(-50%);
        transition: opacity 0.3s;
    }

    .tooltip:hover::after {
        visibility: visible;
        opacity: 1;
    }

`;

export const TreeItemContainer = styled.div`
width: 200px;

    .arrow-icon {
       display: inline-block;
       width: 10px;
       height: 10px;
       margin-right: 10px;
       border-top: 2px solid black;
       border-right: 2px solid black;
       transform: rotate(45deg);
       transition: transform 0.3s ease;
     }

     .item-content {
        display: flex;
        width: 100%;
        height: 20px;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        //background-color: #646464;
        .item-name {
        //margin-right: auto;
      }
    }

     &.opened .arrow-icon {
      transform: rotate(-45deg);
    }

    &.opened {
      height: auto;
    }

    .div-item {
        //display: flex;
        flex: 1;
        width: 150px;
        flex-direction: column;
        padding: 4px, 6px, 4px, 6px;
        border-radius: 7px;
        background-color: #F8F8FA;

        button {
            color: #646464;
            &:last-child{
            color: #646464;
        }
        } 
        
    }
`;

export const Abas = styled.div`
    padding-top: 20px;
    padding-bottom: 20px;
    button{
        height: 50px;
        width: 50%;
        font-size: 20px;
        text-transform: uppercase;
        color: #fff;
        background-color: #646464;
        border: none;
        border-radius: 15px;
        cursor: pointer;
        &:hover{
            background-color: #606060;
        }
        &.active{
            background-color: #027294;
            &:hover{
            background-color: #097999;
        }
        }
    }
`;