import React, { useContext } from 'react'
import { ContainerBNCCReview } from './styles'
import { ResourceContext } from '../../../../../Contexts/Form/ContextResource'
import { BoxResume } from '../../../../../Components/Form/BoxResume'
import ReviewEI from './EI/ReviewEI'
import ReviewEF from './EF/ReviewEF'

const BNCC = () => {

    const { resource } = useContext(ResourceContext)
    console.log(resource)


    return (
        <ContainerBNCCReview>
            <h2>BNCC</h2>
            <BoxResume>
                <tr>
                    <td>
                        <span>Compentências básicas: {" "} {resource.bncc.basic_competences.join(", ")}</span>
                    </td>
                </tr>
                <br />
                <ReviewEI />
                <ReviewEF/>
            </BoxResume>
        </ContainerBNCCReview>
    )
}

export default BNCC